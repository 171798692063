import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/solid';
import { SearchIcon } from '@heroicons/react/outline';

type SearchBarProps = {
  placeholder?: string;
  onChange: (text: string) => void;
  styleType?: string;
  queryString?: string;
};

const SearchBar = ({
  onChange,
  placeholder,
  styleType,
  queryString: initialQueryString,
}: SearchBarProps) => {
  const [isActive, setActive] = useState(false);
  const [queryString, setQueryString] = useState(initialQueryString || '');

  const clearSearch = (e: any) => {
    e.stopPropagation();
    setQueryString('');
    onChange('');
  };

  const onTextChange = (e: any) => {
    const newQueryString = e.target.value;
    setQueryString(newQueryString);
    onChange(newQueryString);
  };

  useEffect(() => {
    setQueryString(initialQueryString || '');
  }, [initialQueryString]);

  return (
    <div
      className={classNames(
        `flex relative flex-auto min-w-min items-center border border-solid bg-white text-neutral-gray-500 rounded-full box-border${
          isActive
            ? ' border-black text-black'
            : ' text-black border-neutral-300'
        }`,
        styleType === 'borderless' ? 'border-none' : '',
      )}
    >
      <div className="pl-4 pr-3">
        <SearchIcon className="h-5 w-5" />
      </div>
      <input
        className="w-full h-5 mt-2.5 mb-2.5 mr-1 ml-0 p-0 border-0 font-sans text-sm focus:outline-none text-black placeholder:text-neutral-gray-500 bg-transparent"
        data-cy="search-input"
        type="text"
        value={queryString}
        placeholder={placeholder || 'Search...'}
        onChange={onTextChange}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      />
      {queryString && (
        <div
          className="cursor-pointer pr-3"
          onClick={clearSearch}
          data-cy="clear-search"
        >
          <XIcon className="h-3 w-3" />
          {/*TODO: test how this icon looks */}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
