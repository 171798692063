import { useActions } from '@frontend/howl-web-app/overmind';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';

export const CreatorGlobalLinkURLCheckWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { setGlobalLinkUrlAndOpenModal } = useActions().link;
  const router = useRouter();

  useEffect(() => {
    const handleHashLink = () => {
      // Called when browser url includes #link=...
      const hash = window.location.hash;

      if (hash.includes('#link=')) {
        const link = hash.replace('#link=', '');
        setGlobalLinkUrlAndOpenModal(link);
      }
    };
    handleHashLink();
    if (router.query.link) {
      setGlobalLinkUrlAndOpenModal(router.query.link as string);
    }
    window.addEventListener('hashchange', handleHashLink);
    return () => {
      window.removeEventListener('hashchange', handleHashLink);
    };
  }, []);
  return children;
};
