import React, { useEffect, useState } from 'react';
import { ImageWithFallback } from './ImageWithFallback';

interface ImageWithFallbackProps {
  /** Image Source */
  src?: string | null;

  /**Quality number from 0 to 100. 100 being best quality.*/
  quality?: number;

  /** Fallback source if image is not available from server */
  fallBack?: string;

  /** Classes to add to img component */
  className?: string;

  //** Override default preset here.  List of presets https://github.com/hellonarrativ/image-proxy */
  preset?:
    | 'default'
    | 'fit'
    | 'fill'
    | 'enlarge'
    | 'sharp'
    | 'blurry'
    | 'strip'
    | 'limit';
}

const bypassList = [
  'static-bam-x-com.s3.amazonaws.com',
  'static.planethowl.com',
];

export const ImageProxy = (props: ImageWithFallbackProps): JSX.Element => {
  const { src, fallBack, preset = 'default', ...rest } = props;
  const [, setImgSrc] = useState<any>(src);
  const fallBackSrc = fallBack;

  useEffect(() => {
    setImgSrc(src);

    // Remove Proxy for S3 images.
    bypassList.forEach((url) => {
      if (src?.indexOf(url) !== -1) {
        return setImgSrc(src);
      } else {
        return setImgSrc(
          `${process.env['NEXT_PUBLIC_IMAGE_PROXY_URL']}/image/${preset}/plain/${src}`,
        );
      }
    });

    return () => {
      setImgSrc(null);
    };
  }, [src]);

  // if (errorCount === 0 && imgSrc) {
  //   return (
  //     <>
  //       {!isLoaded && (
  //         <div className="w-full h-full animate-pulse bg-neutral-gray-200"></div>
  //       )}
  //       <Image
  //         loader={contentfulImageLoader}
  //         className={props.className}
  //         height={height}
  //         width={width}
  //         src={imgSrc}
  //         onLoad={() => setIsloaded(true)}
  //         onError={(e) => {
  //           setErrorCount(errorCount + 1);
  //         }}
  //       />
  //       )
  //     </>
  //   );
  // } else {
  return <ImageWithFallback src={src} fallBack={fallBackSrc} {...rest} />;
  // }
};

ImageProxy.filePath = 'libs/shared/ui-kit/src/lib/image/ImageWithFallback.tsx';
