import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PropTypes from 'prop-types';

import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  DuplicateIcon,
  ExternalLinkIcon,
  GiftIcon,
  PhotographIcon,
  PencilAltIcon,
} from '@heroicons/react/outline';
import {
  ShoppingCartIcon,
  UserAddIcon,
  UserGroupIcon,
} from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { DollarSign } from 'react-feather';

export const icons: any = {
  dot: '/images/icons/dot-v2.svg',
  dollarSign: DollarSign,
  live: '/images/icons/live-v2.svg',
  file: '/images/icons/filecsv-v2.svg',
  search: '/images/icons/search.svg',
  x: '/images/icons/x.svg',
  gear: '/images/icons/icon-gear.svg',
  creditCard: '/images/icons/icon-credit-card.svg',
  wrench: '/images/icons/icon-wrench.svg',
  stripeLogo: '/images/icons/icon-logo-stripe.svg',
  blackStripeLogo: '/images/icons/black-stripe-logo.svg',
  check: '/images/icons/icon-checkmark-v2.svg',
  alert: '/images/icons/icon-alert.svg',
  strategies: '/images/icons/strategies.svg',
  campaigns: '/images/icons/campaigns.svg',
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  caretUp: '/images/icons/caret-up.svg',
  caretDown: '/images/icons/caret-down.svg',
  graph: '/images/icons/graph-v2.svg',
  circleX: '/images/icons/circle-x.svg',
  circleXV2: '/images/icons/circle-x-v2.svg',
  more: '/images/icons/more.svg',
  plus: '/images/icons/plus-v2.svg',
  linkIn: '/images/icons/link-in.svg',
  arrowUp: '/images/icons/arrow-up.svg',
  arrowDown: '/images/icons/arrow-down.svg',
  arrowTrendingUp: '/images/icons/arrow-trending-up.svg',
  bookmark: '/images/icons/bookmark.svg',
  bookmarkFilled: '/images/icons/bookmark-filled.svg',
  youtubeLogo: '/images/icons/icon-logo-youtube.svg',
  home: '/images/icons/home.svg',
  orders: '/images/icons/orders.svg',
  barGraph: '/images/icons/bar-graph.svg',
  lightningBolt: '/images/icons/lightning-bolt.svg',
  shoppingBag: '/images/icons/shopping-bag.svg',
  shoppingCart: ShoppingCartIcon,
  instagramLogo: '/images/icons/icon-logo-instagram.svg',
  copy: DuplicateIcon,
  arrowRight: '/images/icons/arrow-right.svg',
  columns: '/images/icons/columns.svg',
  filledAlert: '/images/icons/icon-alert-filled.svg',
  kebab: '/images/icons/icon-kebab.svg',
  linkOut: ExternalLinkIcon,
  cashV2: '/images/icons/cash-v2.svg',
  purse: '/images/icons/icon-purse.svg',
  pencil: '/images/icons/icon-pencil.svg',
  pencilSquare: PencilAltIcon,
  docs: '/images/icons/docs.svg',
  keywords: '/images/icons/keywords.svg',
  collections: '/images/icons/collections.svg',
  heroProducts: '/images/icons/hero-products.svg',
  newProducts: '/images/icons/new-product.svg',
  salesTag: '/images/icons/sale-tag.svg',
  circleCheck: CheckCircleIcon,
  document: '/images/icons/document.svg',
  eye: '/images/icons/public-eye.svg',
  email: '/images/icons/email.svg',
  editor: '/images/icons/edit.svg',
  createModule: 'images/icons/create-module.svg',
  whiteCheckmark: '/images/icons/checkmark-white.svg',
  checkmark: '/images/icons/checkmark.svg',
  calendar: '/images/icons/calendar.svg',
  calendarAdded: '/images/icons/calendar-added.svg',
  info: '/images/icons/dynamic-info.svg',
  infoTatooine: '/images/icons/info-tatooine.svg',
  samples: '/images/icons/samples.svg',
  payments: '/images/icons/payments.svg',
  globe: '/images/icons/globe.svg',
  cardView: '/images/icons/card-view.svg',
  listView: '/images/icons/list-view.svg',
  wordBalloon: '/images/icons/icon-word-balloon.svg',
  thumbLike: '/images/icons/thumb-like.svg',
  thumbLike2: '/images/icons/thumb-like2.svg',
  thumbDislike: '/images/icons/thumb-dislike.svg',
  trash: 'images/icons/trash-can.svg',
  profile: '/images/icons/profile.svg',
  note: '/images/icons/note.svg',
  ios: '/images/icons/ios.svg',
  gift: GiftIcon,
  instagramFilled: '/images/socials/instagram-filled.svg',
  youtubeFilled: '/images/socials/youtube-filled.svg',
  twitterFilled: '/images/socials/twitter-filled.svg',
  twitchFilled: '/images/socials/twitch-filled.svg',
  tiktokFilled: '/images/socials/tiktok-filled.svg',
  facebookFilled: '/images/socials/facebook-filled.svg',
  userAdd: UserAddIcon,
  users: UserGroupIcon,
  add: '/images/icons/add.svg',
  subtract: '/images/icons/subtract.svg',
  target: '/images/icons/target.svg',
  photograph: PhotographIcon,
  sortDescending: '/images/icons/sort-descending.svg',
  sortAscending: '/images/icons/sort-ascending.svg',
  export: '/images/icons/export.svg',
  upload: '/images/icons/upload.svg',
  playFilled: '/images/icons/play-filled.svg',
  education: '/images/icons/education.svg',
};

export const Icon = (props: any) => {
  const {
    iconName,
    size,
    color,
    image,
    onClick,
    className,
    colorClassName,
    pointerEvents,
  } = props;

  const iconSrc = icons[iconName];

  if (!iconSrc) {
    return null;
  }
  if (typeof iconSrc !== 'string') {
    const component = React.createElement(iconSrc, {
      className: classNames(className, colorClassName, {
        'pointer-events-none': !pointerEvents,
      }),
      style: {
        height: `${size}px`,
        width: `${size}px`,
        color: !colorClassName && (color || 'inherit'),
      },
    });
    return component;
  }
  return (
    <div
      className={classNames(
        'flex icon-container justify-center items-center',
        className,
        colorClassName,
        {
          'pointer-events-none': !pointerEvents,
        },
      )}
      style={{
        height: `${size}px`,
        width: `${size}px`,
        color: !colorClassName && (color || 'inherit'),
      }}
    >
      {image ? (
        <img src={iconSrc} alt={''} />
      ) : (
        <ReactSVG src={iconSrc} onClick={onClick} />
      )}
      {/* <img src={iconSrc} alt={''} /> */}
    </div>
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  iconName: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
  image: PropTypes.bool,
  colorClassName: PropTypes.string,
  pointerEvents: PropTypes.bool,
};

Icon.defaultProps = {
  color: null,
  size: 16,
  onClick: () => null,
  pointerEvents: false,
};
