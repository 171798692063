import React, { FC, MouseEventHandler } from 'react';

import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  DuplicateIcon,
  ExternalLinkIcon,
  GiftIcon,
  PhotographIcon,
  PencilAltIcon,
  XCircleIcon,
  ShoppingCartIcon as SolidShoppingCartIcon,
  CashIcon,
  DotsCircleHorizontalIcon,
  ExclamationCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';

import {
  ShoppingCartIcon,
  UserAddIcon,
  UserGroupIcon,
} from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/solid';

export const Icons: Record<string, any> = {
  dot: '/images/icons/dot-v2.svg',
  live: '/images/icons/live-v2.svg',
  file: '/images/icons/filecsv-v2.svg',
  search: '/images/icons/search.svg',
  x: '/images/icons/x.svg',
  gear: '/images/icons/icon-gear.svg',
  creditCard: '/images/icons/icon-credit-card.svg',
  wrench: '/images/icons/icon-wrench.svg',
  stripeLogo: '/images/icons/icon-logo-stripe.svg',
  blackStripeLogo: '/images/icons/black-stripe-logo.svg',
  check: '/images/icons/icon-checkmark-v2.svg',
  alert: '/images/icons/icon-alert.svg',
  strategies: '/images/icons/strategies.svg',
  campaigns: '/images/icons/campaigns.svg',
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  caretUp: '/images/icons/caret-up.svg',
  caretDown: '/images/icons/caret-down.svg',
  graph: '/images/icons/graph-v2.svg',
  circleX: '/images/icons/circle-x.svg',
  circleXV2: '/images/icons/circle-x-v2.svg',
  circleXV3: XCircleIcon,
  more: '/images/icons/more.svg',
  plus: '/images/icons/plus-v2.svg',
  linkIn: '/images/icons/link-in.svg',
  arrowUp: '/images/icons/arrow-up.svg',
  arrowUpRight: '/images/icons/arrow-up-right.svg',
  arrowDown: '/images/icons/arrow-down.svg',
  bookmark: '/images/icons/bookmark.svg',
  bookmarkV2: '/images/icons/bookmark-v2.svg',
  bookmarkFilled: '/images/icons/bookmark-filled.svg',
  youtubeLogo: '/images/icons/icon-logo-youtube.svg',
  home: '/images/icons/home.svg',
  orders: '/images/icons/orders.svg',
  barGraph: '/images/icons/bar-graph.svg',
  lightningBolt: '/images/icons/lightning-bolt.svg',
  shoppingBag: '/images/icons/shopping-bag.svg',
  shoppingCart: ShoppingCartIcon,
  solidShoppingCart: SolidShoppingCartIcon,
  cash: CashIcon,
  instagramLogo: '/images/icons/icon-logo-instagram.svg',
  copy: DuplicateIcon,
  arrowRight: '/images/icons/arrow-right.svg',
  columns: '/images/icons/columns.svg',
  filledAlert: '/images/icons/icon-alert-filled.svg',
  kebab: '/images/icons/icon-kebab.svg',
  linkOut: ExternalLinkIcon,
  purse: '/images/icons/icon-purse.svg',
  pencil: '/images/icons/icon-pencil.svg',
  pencilSquare: PencilAltIcon,
  docs: '/images/icons/docs.svg',
  keywords: '/images/icons/keywords.svg',
  collections: '/images/icons/collections.svg',
  heroProducts: '/images/icons/hero-products.svg',
  newProducts: '/images/icons/new-product.svg',
  salesTag: '/images/icons/sale-tag.svg',
  circleCheck: CheckCircleIcon,
  document: '/images/icons/document.svg',
  email: '/images/icons/email.svg',
  eye: '/images/icons/public-eye.svg',
  eyeUnfilled: '/images/icons/public-eye-unfilled.svg',
  editor: '/images/icons/edit.svg',
  createModule: 'images/icons/create-module.svg',
  calendarAdded: '/images/icons/calendar-added.svg',
  info: '/images/icons/dynamic-info.svg',
  infoTatooine: '/images/icons/info-tatooine.svg',
  samples: '/images/icons/samples.svg',
  payments: '/images/icons/payments.svg',
  globe: '/images/icons/globe.svg',
  cardView: '/images/icons/card-view.svg',
  listView: '/images/icons/list-view.svg',
  wordBalloon: '/images/icons/icon-word-balloon.svg',
  thumbLike: '/images/icons/thumb-like.svg',
  thumbLike2: '/images/icons/thumb-like2.svg',
  thumbDislike: '/images/icons/thumb-dislike.svg',
  trash: 'images/icons/trash-can.svg',
  plainTrash: 'images/icons/plain-trash.svg',
  profile: '/images/icons/profile.svg',
  note: '/images/icons/note.svg',
  ios: '/images/icons/ios.svg',
  gift: GiftIcon,
  instagram: '/images/socials/instagram.svg',
  youtube: '/images/socials/youtube.svg',
  twitter: '/images/socials/twitter.svg',
  twitch: '/images/socials/twitch.svg',
  tiktok: '/images/socials/tiktok.svg',
  substack: '/images/socials/substack.svg',
  other: '/images/socials/other.svg',
  newsletter: '/images/socials/newsletter.svg',
  site: '/images/socials/site.svg',
  facebook: '/images/socials/facebook.svg',
  userAdd: UserAddIcon,
  users: UserGroupIcon,
  add: '/images/icons/add.svg',
  subtract: '/images/icons/subtract.svg',
  target: '/images/icons/target.svg',
  photograph: PhotographIcon,
  sortDescending: '/images/icons/sort-descending.svg',
  sortAscending: '/images/icons/sort-ascending.svg',
  export: '/images/icons/export.svg',
  upload: '/images/icons/upload.svg',
  newTag: '/images/icons/new-tag.svg',
  homeUnfilled: '/images/icons/home-unfilled.svg', // unused - consider removing
  rocket: '/images/icons/rocket.svg',
  dynamicLink: '/images/icons/icon-dynamic-link.svg',
  exclusiveLink: '/images/icons/icon-exclusive-link.svg',
  addToStorefront: '/images/icons/icon-add-to-storefront.svg',
  copyLinks: '/images/icons/icon-copy-links.svg',
  copyNameAndLinks: '/images/icons/icon-copy-name-and-links.svg',
  download: '/images/icons/download.svg',
  multiDocument: '/images/icons/multi-document.svg',
  megaphone: '/images/icons/megaphone.svg',
  priceTag: '/images/icons/price-tag.svg',
  ellipsisCircle: DotsCircleHorizontalIcon,
  ssoEmail: '/images/icons/sso-email.svg',
  ssoGoogle: '/images/icons/sso-google.svg',
  ssoFacebook: '/images/icons/sso-facebook.svg',
  ssoInstagram: '/images/icons/sso-instagram.svg',
  ssoTikTok: '/images/icons/sso-tiktok.svg',
  ssoOkta: '/images/icons/sso-okta.svg',
  miscChart: '/images/icons/misc-chart.svg',
  exclamationCircle: ExclamationCircleIcon,
  share: '/images/icons/share.svg',
  bell: '/images/icons/bell.svg',
  globeV2: '/images/icons/globe-v2.svg',
  portalHome: '/images/icons/portal/home.svg',
  handbag: '/images/icons/portal/handbag.svg',
  link: '/images/icons/portal/link.svg',
  portalShoppingCart: '/images/icons/portal/shopping-cart.svg',
  portalCreditCard: '/images/icons/portal/credit-card.svg',
  chartBarVertical: '/images/icons/portal/chart-bar-vertical.svg',
  menu: '/images/icons/portal/menu.svg',
  userCircle: '/images/icons/portal/user-circle.png',
  portalInfo: '/images/icons/portal/info.svg',
  purpleCoins: '/images/icons/purple-coins.svg',
  purpleCoinsPulse: '/images/icons/purple-coins-pulse.svg',
  purpleLock: '/images/icons/purple-lock.svg',
  xSmallBold: '/images/icons/X-small-bold.svg',
  lockClosed: LockClosedIcon,
};

export type IconProps = {
  /** Icon name from defined entries */
  iconName: keyof typeof Icons;

  /** Height and width of icon in pixels */
  size?: number;

  /** Color that icon should render as */
  color?: string;

  /** If Icon should render as an image or an SVG */
  image?: boolean;

  /** Click handler for when button is clicked. */
  onClick?: MouseEventHandler;

  /** Additional classes that append to the icon container. */
  className?: string;

  /** Additional color classes that append to the icon container and prevent default color. */
  colorClassName?: string;

  /** If Icon can become target or pointer events or not */
  pointerEvents?: boolean;

  /** Override default stroke width */
  strokeWidth?: number;

  /** Override default data-cy element value */
  dataCy?: string;
};

export const Icon: FC<IconProps> & { filePath: string } = ({
  iconName,
  size = 16,
  color = '',
  image = false,
  onClick = () => null,
  className = '',
  colorClassName = '',
  pointerEvents = false,
  strokeWidth = null,
  dataCy = null,
}) => {
  const iconSrc = Icons[iconName];

  if (!iconSrc) {
    return null;
  }

  if (typeof iconSrc !== 'string') {
    const component = React.createElement(iconSrc, {
      className: classNames(className, colorClassName, {
        'pointer-events-none': !pointerEvents,
      }),
      style: {
        height: `${size}px`,
        width: `${size}px`,
        color: !colorClassName && (color || 'inherit'),
        strokeWidth: strokeWidth,
      },
      onClick: onClick,
    });
    return component;
  }

  return (
    <div
      className={classNames(
        'flex icon-container justify-center items-center',
        className,
        colorClassName,
        {
          'pointer-events-none': !pointerEvents,
        },
      )}
      data-cy={dataCy ? dataCy : `icon-${iconName}`}
      style={{
        height: `${size}px`,
        width: `${size}px`,
        color: colorClassName ? '' : color ? color : 'inherit',
      }}
    >
      {image ? (
        <img src={iconSrc} alt={''} />
      ) : (
        <ReactSVG src={iconSrc} onClick={onClick} />
      )}
    </div>
  );
};

Icon.filePath = 'libs/shared/ui-kit/src/lib/icon/icon.tsx';
Icon.displayName = 'Icon';
