import moment from 'moment';
import { pickBy } from 'lodash';
import { Context } from '..';

export const onInitializeOvermind = () => {
  // context.state.date.dateTime = new Date();
  // Default to month to date
  // context.state.date.defaultEndDate = context.actions.date.getDateTime();
  // context.state.date.defaultStartDate = context.actions.date.getFirstOfMonth();
  // context.state.date.currentMonth = context.actions.date.getMonthFromDateTime({
  //   dateObj: context.state.date.dateTime,
  // });
};

export const setDateTime = (context: Context, dateTime: Date) => {
  context.state.date.dateTime = dateTime;
};

export const getPresetDateRanges = (
  context: Context,
  useReducedPresets?: boolean,
) => {
  const today = context.actions.date.getCurrentDateTime();
  const yesterday = context.actions.date.offsetFromDate({
    originalDate: today,
    number: -1,
    unit: 'day',
  });

  const firstOfMonth = context.actions.date.getFirstOfMonth();

  const datePresets = {
    today: {
      label: 'Today',
      startDate: today,
      endDate: today,
      reducedPreset: true,
    },
    yesterday: {
      label: 'Yesterday',
      startDate: yesterday,
      endDate: yesterday,
      reducedPreset: true,
    },
    sevenDay: {
      label: 'Last 7 Days',
      startDate: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -6,
        unit: 'day',
      }),
      endDate: today,
      reducedPreset: true,
    },
    fourteenDay: {
      label: 'Last 14 Days',
      startDate: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -13,
        unit: 'day',
      }),
      endDate: today,
      reducedPreset: true,
    },
    thirtyDay: {
      label: 'Last 30 Days',
      startDate: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -29,
        unit: 'day',
      }),
      endDate: today,
      reducedPreset: true,
    },
    thisWeek: {
      label: 'This Week',
      startDate: context.actions.date.getFirstOfWeek(),
      endDate: today,
      reducedPreset: true,
    },
    lastWeek: {
      label: 'Last Week',
      startDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.getFirstOfWeek(),
        number: -7,
        unit: 'day',
      }),
      endDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.getFirstOfWeek(),
        number: -1,
        unit: 'day',
      }),
      reducedPreset: true,
    },
    thisMonth: {
      label: 'This Month',
      startDate: firstOfMonth,
      endDate: today,
      selected: true,
      reducedPreset: true,
    },
    lastMonth: {
      label: 'Last Month',
      startDate: context.actions.date.offsetFromDate({
        originalDate: firstOfMonth,
        number: -1,
        unit: 'month',
      }),
      endDate: context.actions.date.offsetFromDate({
        originalDate: firstOfMonth,
        number: -1,
        unit: 'day',
      }),
      reducedPreset: true,
    },
    oneDay: {
      label: '24 hours',
      startDate: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -1,
        unit: 'day',
      }),
      endDate: today,
    },
    ninetyDay: {
      label: 'Last 90 Days',
      startDate: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -89,
        unit: 'day',
      }),
      endDate: today,
    },
    last2Months: {
      label: 'Last 2 Months',
      startDate: context.actions.date.offsetFromDate({
        originalDate: firstOfMonth,
        number: -1,
        unit: 'month',
      }),
      endDate: today,
    },
    halfYear: {
      label: 'Last 6 Months',
      startDate: context.actions.date.offsetFromDate({
        originalDate: firstOfMonth,
        number: -5,
        unit: 'month',
      }),
      endDate: today,
    },
    thisYear: {
      label: 'Year to Date',
      startDate: context.actions.date.getStartOf({
        date: today,
        unit: 'year',
      }),
      endDate: today,
    },
    multiYear: {
      label: 'Year over Year',
      startDate: moment(new Date(2016, 0, 1)),
      endDate: today,
    },
    previousHalfYear: {
      label: 'Previous Year Last 6 Months',
      startDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.offsetFromDate({
          originalDate: firstOfMonth,
          number: -5,
          unit: 'month',
        }),
        number: -1,
        unit: 'year',
      }),
      endDate: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -1,
        unit: 'year',
      }),
    },
    previous12Months: {
      label: 'Previous 12 Months',
      startDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.getFirstOfMonth(),
        number: -2,
        unit: 'year',
      }),
      endDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.getEndOfMonth(),
        number: -1,
        unit: 'year',
      }),
    },
    previousYearFullMonth: {
      label: 'Previous Year Month Extended', // Extended the end date through month end
      startDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.getFirstOfMonth(),
        number: -1,
        unit: 'year',
      }),
      endDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.getEndOfMonth(),
        number: -1,
        unit: 'year',
      }),
    },
    previousYear: {
      label: 'Previous Year to Date',
      startDate: context.actions.date.offsetFromDate({
        originalDate: context.actions.date.getStartOf({
          date: today,
          unit: 'year',
        }),
        number: -1,
        unit: 'year',
      }),
      endDate: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -1,
        unit: 'year',
      }),
    },
  };

  return pickBy(datePresets, (preset: any) =>
    useReducedPresets ? preset.reducedPreset : true,
  );
};

export const getCurrentDateTime = () => {
  return moment();
};

export const getDateTime = (context: Context) => {
  return moment(context.state.date.dateTime.toISOString());
};

export const getStartOfDay = (context: Context, date?: moment.Moment) => {
  date = date ? date.clone() : context.actions.date.getDateTime();
  return date.startOf('day');
};

export const getEndOfDay = (context: Context, date?: moment.Moment) => {
  date = date ? date.clone() : context.actions.date.getDateTime();
  return date.endOf('day');
};

export const getFirstOfWeek = (context: Context) => {
  return context.actions.date.getDateTime().startOf('week');
};

export const getEndOfWeek = (context: Context) => {
  return context.actions.date.getDateTime().endOf('week').startOf('day');
};

export const getFirstOfMonth = (context: Context, date?: moment.Moment) => {
  date = date ? date.clone() : context.actions.date.getDateTime();
  return date.startOf('month');
};

export const getLastMonthStart = (context: Context) => {
  const lastMonthDate = context.actions.date.offsetFromDate({
    originalDate: context.actions.date.getDateTime(),
    number: -1,
    unit: 'month',
  });
  return context.actions.date.getFirstOfMonth(lastMonthDate);
};

export const getEndOfMonth = (context: Context, date?: moment.Moment) => {
  date = date ? date.clone() : context.actions.date.getDateTime();
  return date.endOf('month');
};

export const getStartOf = (
  context: Context,
  { date, unit }: { date?: moment.Moment; unit: any },
) => {
  // unit: day|week|month|year
  date = date ? date.clone() : context.actions.date.getDateTime();
  return date.startOf(unit);
};

export const getMonthLabel = (
  context: Context,
  date?: moment.Moment | string,
) => {
  // Ex: JAN, FEB, SEP
  return context.actions.date
    .getMonthFromDateTime({ dateObj: date, monthFormat: 'MMM' })
    .toUpperCase();
};

export const toIso8601UtcDatetime = (
  context: Context,
  dateObj: moment.Moment,
) => {
  return moment(dateObj).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};

export const formatDate = (
  context: Context,
  {
    dateObj = null,
    fmt = '',
    isUtc,
  }: { dateObj?: moment.MomentInput; fmt?: string; isUtc?: any },
) => {
  fmt = fmt || 'YYYY-MM-DD';
  const date = moment(dateObj || new Date());
  return isUtc ? date.utc().format(fmt) : date.format(fmt);
};

export const formatMonthYear = (
  context: Context,
  dateObj: moment.MomentInput | string,
) => {
  return moment(dateObj).format('MMMM YYYY');
};

export const formatDisplayDate = (
  context: Context,
  dateObj: moment.MomentInput | string,
) => {
  return moment(dateObj).format('MMMM D, YYYY');
};

export const formatMonthDay = (
  context: Context,
  dateObj: moment.MomentInput | string,
) => {
  return moment(dateObj).format('M/D/YY');
};

export const formatDisplayMonth = (
  context: Context,
  dateObj: moment.MomentInput | string,
) => {
  return moment(dateObj).format('MMMM');
};

export const formatYear = (
  context: Context,
  dateObj: moment.MomentInput | string,
) => {
  return moment(dateObj).format('YYYY');
};

export const formatYearMonth = (
  context: Context,
  dateObj: moment.MomentInput | string,
) => {
  return moment(dateObj).format('YYYY-MM');
};

export const formatTime = (
  context: Context,
  dateObj: moment.MomentInput | string,
) => {
  return moment(dateObj).format('h:mm a');
};

export const localizeDate = (context: Context, dateStr: string) => {
  // Make sure date is localized if dateStr is a year-month format instead of utc
  if (!dateStr.match(/Z$/)) {
    const date = new Date(dateStr);
    return date.getTime() + date.getTimezoneOffset() * 60000;
  }
  return moment.utc(dateStr).toDate().getTime();
};

export const dateFromStr = (context: Context, dateStr: string) => {
  return dateStr.match(/Z$/) ? moment.utc(dateStr) : moment(dateStr);
};

export const formatDateRange = (
  context: Context,
  dateObj?: { startDate: Date; endDate: Date },
) => {
  const start = moment(dateObj?.startDate).format('YYYY-MM-DD');
  const end = moment(dateObj?.endDate).format('YYYY-MM-DD');
  return { startDate: start, endDate: end };
};

export const subtractDates = (
  context: Context,
  { d1, d2, unit = '' }: { d1: any; d2: any; unit?: any },
) => {
  // Returns a float or momentObj
  const momentObj = moment.duration(
    Math.abs(
      (moment(d1) as unknown as number) - (moment(d2) as unknown as number),
    ),
  );

  if (unit === 'hour') {
    return momentObj.asHours() + 1;
  }
  if (unit === 'day') {
    return momentObj.asDays() + 1;
  }
  if (unit === 'month') {
    return momentObj.asMonths() + 1;
  }
  if (unit === 'year') {
    return momentObj.asYears() + 1;
  }
  return momentObj;
};

//  Removes absolute value function
export const subtractDatesV2 = (
  context: Context,
  { d1, d2, unit = '' }: { d1: any; d2: any; unit?: any },
) => {
  // Returns a float or momentObj
  const momentObj = moment.duration(
    (moment(d1) as unknown as number) - (moment(d2) as unknown as number),
  );

  if (unit === 'hour') {
    return momentObj.asHours() + 1;
  }
  if (unit === 'day') {
    return momentObj.asDays() + 1;
  }
  if (unit === 'month') {
    return momentObj.asMonths() + 1;
  }
  if (unit === 'year') {
    return momentObj.asYears() + 1;
  }
  return momentObj;
};

export const offsetFromDate = (
  context: Context,
  {
    originalDate,
    number,
    unit,
    isUtc,
  }: { originalDate: any; number: any; unit?: any; isUtc?: boolean },
): moment.Moment => {
  // number can be positive or negative
  unit = unit || 'day';
  const dateObj = isUtc ? moment(originalDate).utc() : moment(originalDate);
  return dateObj.add(number, unit);
};

export const getMonthDiff = (
  context: Context,
  { d1, d2 }: { d1: any; d2: any },
) => {
  return moment(d1).diff(moment(d2), 'month');
};

export const compareTimeWithDate = (
  context: Context,
  { d1, d2 }: { d1: any; d2: any },
) => {
  if (moment(d1).isSame(d2, 'hour') || moment(d1).isAfter(d2, 'hour')) return 0;
  return 1;
};

// Sort dates in chronological order
export const compareDates = (
  context: Context,
  { d1, d2 }: { d1: any; d2: any },
) => {
  if (context.actions.date.dateIsSame({ d1, d2, unit: 'day' })) return 0;
  if (moment(d1).isBefore(d2, 'day')) return -1;
  return 1;
};

export const compareHours = (
  context: Context,
  { d1, d2 }: { d1: any; d2: any },
) => {
  if (context.actions.date.dateIsSame({ d1, d2, unit: 'hour' })) {
    if (
      context.actions.date.dateIsSame({ d1, d2, unit: 'minute' }) ||
      moment(d1).isBefore(d2, 'minute')
    ) {
      return 0;
    }
  }
  if (moment(d1).isBefore(d2, 'hour')) return -1;
  return 1;
};

export const dateIsSame = (
  context: Context,
  {
    d1,
    d2,
    unit = 'day',
    utc = true,
  }: {
    d1: any;
    d2: any;
    unit: 'year' | 'month' | 'day' | 'hour' | 'minute';
    utc?: boolean;
  },
) => {
  // Note: Moment.isSame() is not a reliable function, so we're using a custom comparison function
  // https://momentjs.com/docs/#/query/is-same/
  const dateOne = utc ? moment.utc(d1) : moment(d1);
  const dateTwo = utc ? moment.utc(d2) : moment(d2);
  if (dateOne.year() === dateTwo.year()) {
    if (unit === 'year') return true;
    if (dateOne.month() === dateTwo.month()) {
      if (unit === 'month') return true;
      if (dateOne.date() === dateTwo.date()) {
        if (unit === 'day') return true;
        if (dateOne.hour() === dateTwo.hour()) {
          if (unit === 'hour') return true;
          if (dateOne.minute() === dateTwo.minute()) {
            return true;
          }
        }
      }
    }
  }

  return false;
};

export const compareDateWithToday = (
  context: Context,
  d1: moment.MomentInput,
) => {
  return context.actions.date.compareDates({
    d1,
    d2: context.actions.date.localizeDate(new Date().toISOString()),
  });
};

export const getMonthFromDateTime = (
  context: Context,
  { dateObj, monthFormat = null }: { dateObj: any; monthFormat?: any },
) => {
  monthFormat = monthFormat || 'MMMM';
  return moment(dateObj).format(monthFormat);
};

export const getDaysInMonth = (
  context: Context,
  dateObj: moment.MomentInput,
) => {
  return moment(dateObj).daysInMonth();
};

export const dayElapsed = (context: Context, forProjection?: boolean) => {
  let dayElapsed;
  if (forProjection) {
    // Project using 'seconds'
    const today = context.actions.date.getCurrentDateTime();
    const start = today.clone().startOf('day');
    const timeElapsed = moment.duration(today.diff(start));
    const secondsElapsed = timeElapsed.asSeconds();
    const totalSecondsInDay = 86400;
    dayElapsed = secondsElapsed / totalSecondsInDay;
  } else {
    const today = moment().utc();
    const hour = today.format('HH') as unknown as number;
    const totalHours = 24;

    dayElapsed = hour / totalHours;
  }
  return dayElapsed;
};

export const monthElapsed = (context: Context, forProjection?: boolean) => {
  let monthElapsed;
  if (forProjection) {
    // Project using 'hours'
    const today = context.actions.date.getCurrentDateTime();
    const start = today.clone().startOf('month');
    const timeElapsed = moment.duration(today.diff(start));
    const hoursElapsed = timeElapsed.asHours();
    monthElapsed = hoursElapsed / (today.daysInMonth() * 24);
  } else {
    const today = moment().utc();
    const date = today.date();
    const daysInMonth = today.daysInMonth();

    monthElapsed = date / daysInMonth;
  }
  return monthElapsed;
};

export const yearElapsed = (context: Context, forProjection: boolean) => {
  let yearElapsed;
  if (forProjection) {
    // Project using 'days'
    const today = context.actions.date.getCurrentDateTime();
    const start = today.clone().startOf('year');
    const timeElapsed = moment.duration(today.diff(start));
    const daysElapsed = timeElapsed.asDays();
    yearElapsed = daysElapsed / context.actions.date.getDaysInYear();
  } else {
    const today = moment().utc();
    const daysElapsed = today.dayOfYear();

    yearElapsed = daysElapsed / context.actions.date.getDaysInYear();
  }
  return yearElapsed;
};

export const getDaysInYear = (context: Context) => {
  const today = context.actions.date.getCurrentDateTime().utc();
  const year = today.year();
  // Find if current year is leap year
  return year % 100 === 0
    ? year % 400 === 0
      ? 366
      : 365
    : year % 4 === 0
    ? 366
    : 365;
};

export const timeFrom = (
  context: Context,
  {
    date,
    measurement,
    floating,
  }: {
    date?: moment.Moment | Date | string;
    measurement?: any;
    floating?: any;
  },
) => {
  let original = date;
  const now = moment();
  if (date instanceof Date) {
    original = moment(date);
  } else if (typeof date === 'string') {
    original = context.actions.date.dateFromStr(date);
  }

  return moment(original).diff(now, measurement, floating);
};

export const timeFromNow = (
  context: Context,
  {
    d1,
    stripSuffix = false,
  }: { d1: string | moment.Moment | Date; stripSuffix: boolean },
) => {
  return moment(d1).fromNow(stripSuffix);
};
