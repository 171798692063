import { FC } from 'react';
import classNames from 'classnames';

export type SliderProps = {
  /** Clicker handler for when input is clicked. */
  onClick: (value: any) => void;

  /** Boolean value */
  selected?: boolean;

  /** Additional class styling to be added to the button */
  className?: string;

  /** If the button is disabled or not. */
  disabled?: boolean;
};

export const Slider: FC<SliderProps> & { filePath: string } = ({
  onClick,
  selected,
  className,
  disabled,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        'slider-btn border-0 inline-block',
        {
          'cursor-not-allowed': disabled,
        },
        className,
      )}
      onClick={(e) => {
        e.preventDefault();
        if (!disabled) {
          onClick(e);
        }
      }}
    >
      <div
        data-cy="slider-toggle"
        className={classNames(
          'slider-toggle inline-block rounded-full  relative',
          {
            'bg-neutral-gray-400': !selected,
            'bg-violet-300 bg-opacity-40': selected,
          },
        )}
        style={{ width: '28px', height: '11px' }}
      >
        <span
          className={`absolute rounded-full ${
            selected ? 'bg-violet-500' : 'bg-white'
          }`}
          style={{
            top: '-2px',
            right: selected ? 0 : '15px',
            width: '15px',
            height: '15px',
            transition: 'background-color .2s, right .2s',
            boxShadow: '0 1px 2px #777',
          }}
        ></span>
      </div>
    </button>
  );
};

Slider.filePath = 'libs/shared/ui-kit/src/lib/slider/slider.tsx';
Slider.displayName = 'Slider';

export default Slider;
