import React from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';

export interface LinkProps<T extends string = never> {
  /** Whether the link goes to a destination in our dashboard */
  external?: boolean;

  /** The child node to be displayed */
  children: React.ReactNode;

  /** The destination to navigate to */
  href: string;

  /** Whether the destination should open in a new tab */
  targetBlank?: string;

  /** Disables linking functionality when true */
  disabled?: boolean;

  legacyBehavior?: boolean;

  /** Custom styling */
  className?: string;

  passHref?: boolean;

  title?: string;

  onClick?: () => void;

  /** Data props to attach to links */
  dataPath?: string;
  dataCy?: string;
  dataRef?: string;
}

export const Link = (props: LinkProps) => {
  const {
    external,
    children,
    href,
    targetBlank = '_blank',
    disabled,
    legacyBehavior,
    className: linkClassName,
    passHref,
    title,
    onClick,
    dataPath,
    dataCy,
    dataRef,
  } = props;

  const className = 'cursor-pointer hover:underline text-violet-300';

  return disabled ? (
    children
  ) : external ? (
    <a
      className={classNames(className, linkClassName)}
      href={href}
      target={targetBlank}
      rel="noreferrer"
      title={title}
    >
      {children}
    </a>
  ) : (
    <NextLink
      href={href}
      className={classNames(className, linkClassName)}
      legacyBehavior={legacyBehavior}
      passHref={passHref}
      title={title}
      onClick={onClick}
      data-cy={dataCy || 'link'}
      data-path={dataPath}
      data-ref={dataRef}
    >
      {children}
    </NextLink>
  );
};

Link.defaultProps = {
  external: false,
};

Link.filePath = 'libs/shared/ui-kit/src/lib/date-picker/link.tsx';
Link.displayName = 'Link';
