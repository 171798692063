import { useAppState } from '@frontend/howl-web-app/overmind';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import React from 'react';

export const AccountMerchNavItems = (props: { onNavClick: () => any }) => {
  const flags = useFlags();
  const { sessionData } = useAppState().session;
  const org = sessionData.orgId;
  return (
    <>
      <Link
        href={`/merchant/${org}/account-settings/billing-and-payments/invoices`}
        legacyBehavior
      >
        <li
          data-cy="nav-item-invoices"
          onClick={() => props.onNavClick()}
          className="list-none h-10 flex items-center text-sm hover:bg-ice-10 focus:bg-ice-100 transition px-4 cursor-pointer"
        >
          Billing
        </li>
      </Link>

      <Link
        href={`/merchant/${org}/account-settings/general-settings`}
        legacyBehavior
      >
        <li
          data-cy="nav-item-account-settings"
          onClick={() => props.onNavClick()}
          className="list-none h-10 flex items-center text-sm hover:bg-ice-10 focus:bg-ice-100 transition px-4 cursor-pointer"
        >
          Account Settings
        </li>
      </Link>
    </>
  );
};
