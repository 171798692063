export type TargetListsResponse = {
  target_list_id: number;
  target_list_name: string;
  target_list_scope: string;
  target_list_scope_merch_id: number;
  target_list_type: string;
};

type ListManagement = {
  showEditor: boolean;
  updatingList: boolean;
  targetListId: number | null;
  targetListType: string | null;
  targetListName: string | null;
  data: TargetListsResponse[];
  snackBarTxt: string | null;
  targetLists: {
    publisher: TargetListsResponse[];
    brand: TargetListsResponse[];
    sku: TargetListsResponse[];
  };
  error: string | null;
  logosLoading: boolean;
  openRows: any;
};

export const state: ListManagement = {
  showEditor: false,
  updatingList: false,
  targetListId: null,
  targetListType: null,
  targetListName: null,
  data: [],
  snackBarTxt: null,
  targetLists: {
    publisher: [],
    brand: [],
    sku: [],
  },
  error: null,
  logosLoading: false,
  openRows: {},
};
