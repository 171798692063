import { Context } from '..';

type queryBrandsProps = {
  merchId: string;
  query: string;
};

type Product = {
  merchant_product_id: number;
};

export const fetchMerchants = async (context: Context) => {
  if (!context.state.productForm.merchants.length) {
    const url = '/api/v0/merchants/';
    try {
      const resp = await context.effects.api.get(url);
      context.state.productForm.merchants = resp.data.data;
    } catch (e: any) {
      context.state.productForm.error = e?.message;
    }
  }
};

export const queryBrands = async (
  context: Context,
  { merchId, query }: queryBrandsProps,
) => {
  const url = `/api/v0/merchant_products/${merchId}/brands/`;
  try {
    const resp = await context.effects.api.get(url, {
      limit: 20,
      query,
    });

    const newBrands = { ...context.state.productForm.brands };
    newBrands[merchId] = resp.data;
    context.state.productForm.brands = newBrands;
  } catch (e: any) {
    context.state.productForm.error = e?.message;
  }
};

export const createProduct = async (context: Context, product: Product) => {
  const url = '/api/v0/merchant_products/';
  const resp = await context.effects.api.post(url, product);

  if (resp.data.error) {
    throw resp.data.error;
  }

  return resp.data.data[0];
};

export const editProduct = async (context: Context, product: Product) => {
  const url = `/api/v0/merchant_products/${product.merchant_product_id}`;
  const resp = await context.effects.api.put(url, product);

  if (resp.data.error) {
    throw resp.data.error;
  }

  return resp.data.data[0];
};
