import cloneDeep from 'lodash/cloneDeep';
import { timeFormat, timeParse } from 'd3';
import { Context } from '..';
import moment from 'moment';
import { sortBy } from 'lodash';

export const aggregateStats = (
  context: Context,
  {
    allStats,
    xTicks,
    merchantPerformance = false,
  }: {
    allStats: any;
    xTicks: any;
    merchantPerformance?: any;
  },
) => {
  /*
   * Create buckets that represent the time period between each set of 2 ticks.
   * As we iterate through the stats, we place them in the bucket they belong
   * in by comparing dates. Then, aggregate each bucket of stats together.
   */
  const aggregateStats: any = [];

  allStats.forEach((obj: any) => {
    const dateBuckets = xTicks.map((tick: any) => {
      return {
        date: tick,
        stats: [],
      };
    });

    const stats = obj.data;
    let bucketIndex = 0;

    //  First date in stats could be a partial date interval (e.g. half a week) and start before the first tick,
    //  causing the aggregation to fail. Adjust date of first stat to first tick if this is the case
    if (moment(stats[0].event_date) < moment(dateBuckets[0].date)) {
      stats[0].event_date = moment(dateBuckets[0].date).format('YYYY-MM-DD');
    }

    for (let i = 0; i < stats.length; i++) {
      const currentDate = dateBuckets[bucketIndex].date;
      const nextDate = dateBuckets[bucketIndex + 1]
        ? dateBuckets[bucketIndex + 1].date
        : null;
      const currentDateComparison = context.actions.date.compareDates({
        d1: currentDate,
        d2: stats[i].event_date,
      });
      const nextDateComparison = context.actions.date.compareDates({
        d1: nextDate,
        d2: stats[i].event_date,
      });

      if (currentDateComparison <= 0 && (nextDateComparison > 0 || !nextDate)) {
        dateBuckets[bucketIndex].stats.push(stats[i]);
      } else {
        bucketIndex++;
        i--;
      }
    }
    aggregateStats.push(
      context.actions.graph.aggregateByDate({
        buckets: dateBuckets,
        merchantPerformance,
      }),
    );
  });

  return allStats.map((obj: any, i: any) => {
    obj.data = aggregateStats[i];
    return obj;
  });
};

export const aggregateByDate = (
  context: Context,
  { buckets, merchantPerformance }: { buckets: any; merchantPerformance: any },
) => {
  const formatDate = timeFormat('%Y-%m-%d');
  const statArray = buckets.map((bucket: any) => {
    if (!bucket.stats.length) return null;

    const { stats } = bucket;
    const aggregateStat = (key: any) =>
      stats.reduce((agg: any, stat: any) => {
        return (agg += parseFloat(stat[key]));
      }, 0);
    if (merchantPerformance) {
      const revenueKey =
        stats[0] && stats[0].attributed_revenue
          ? 'attributed_revenue'
          : stats[0].total_attributed_revenue
          ? 'total_attributed_revenue'
          : 'revenue';

      const spendKey =
        stats[0] &&
        Object.prototype.hasOwnProperty.call(stats[0], 'merchant_spend')
          ? 'merchant_spend'
          : 'spend';

      return {
        event_date: formatDate(bucket.date),
        [revenueKey]: aggregateStat(revenueKey),
        [spendKey]: aggregateStat([spendKey]),
        clicks: aggregateStat('clicks'),
        rpc: aggregateStat('revenue') / aggregateStat('clicks'),
        roi_actual: parseFloat(
          String(aggregateStat('roi_actual') / stats.length),
        ).toFixed(1),
        roi: parseFloat(aggregateStat('roi')).toFixed(1),
        originalDate: stats[0].originalDate,
        share_of_voice: aggregateStat('share_of_voice'),
        new_link_count: aggregateStat('new_link_count'),
      };
    }
    return {
      pub_id: stats[0].pub_id,
      event_date: formatDate(bucket.date),
      clicks: aggregateStat('clicks'),
      publisher_earnings_usd: aggregateStat('publisher_earnings_usd'),
      attributed_revenue_usd: aggregateStat('attributed_revenue_usd'),
    };
  });
  return statArray.filter((data: any) => data);
};

export const getGranularities = (
  context: Context,
  dateRange: { date_from?: string; date_to?: string },
) => {
  const granularityOptions = {
    daily: { label: 'Daily', value: 'daily', disabled: false },
    weekly: { label: 'Weekly', value: 'weekly', disabled: false },
    monthly: { label: 'Monthly', value: 'monthly', disabled: false },
    yearly: { label: 'Yearly', value: 'yearly', disabled: false },
  };

  const numDays = moment(dateRange.date_to).diff(
    moment(dateRange.date_from),
    'days',
  );

  if (numDays < 365) granularityOptions.yearly.disabled = true;
  if (numDays < 60) granularityOptions.monthly.disabled = true;
  if (numDays < 14) granularityOptions.weekly.disabled = true;

  return Object.values(granularityOptions);
};

export const getGranularityShorthand = (
  context: Context,
  granularity: string,
) => {
  return granularity === 'daily' ? 'days' : `${granularity.slice(0, -2)}s`;
};

export const getTicks = (
  context: Context,
  {
    granularity,
    dateFrom,
    dateTo,
    skipDateParsing,
  }: {
    granularity: any;
    dateFrom: any;
    dateTo: any;
    skipDateParsing?: boolean;
  },
) => {
  /*
   * By determining the x-axis ticks in megatable-graph,
   * we can more clearly aggregate the stats
   */
  const xTicks = [dateFrom];
  const offsetUnit =
    granularity === 'daily' ? 'days' : `${granularity.slice(0, -2)}s`;
  const parseDate = timeParse('%Y-%m-%d');

  let date = context.actions.date.offsetFromDate({
    originalDate: dateFrom,
    number: 1,
    unit: offsetUnit,
  });

  // Continue to add ticks until we've exceeded our date range
  while (context.actions.date.compareDates({ d1: date, d2: dateTo }) <= 0) {
    const adjustedDate = context.actions.graph.getGranularityStartDate({
      dateObject: cloneDeep(date),
      granularity,
    });
    xTicks.push(adjustedDate);
    date = context.actions.date.offsetFromDate({
      originalDate: date,
      number: 1,
      unit: offsetUnit,
    });
  }

  return xTicks.map((date) => (skipDateParsing ? date : parseDate(date)));
};

export const getGranularityStartDate = (
  context: Context,
  { dateObject, granularity }: { dateObject: any; granularity: any },
) => {
  // weekly => week, monthly => month, yearly => year
  const timeUnit = granularity.slice(0, -2);
  return context.actions.date.formatDate({
    dateObj: dateObject.startOf(timeUnit).startOf('day'),
  });
};

export const processStats = (
  context: Context,
  {
    stats,
    ticks,
    selectedGranularity,
    dateRange,
    option,
  }: {
    stats: any;
    ticks: any;
    selectedGranularity: any;
    dateRange: any;
    option: any;
  },
) => {
  // Backend returns all monthly stats rounded down to the 1st, replace with correct date
  if (['monthly', 'yearly'].includes(selectedGranularity)) {
    const formattedDate = dateRange.date_from;
    const truncatedDateFrom = formattedDate.slice(0, 7);
    const truncatedStatDate = stats[0].event_date.slice(0, 7);

    if (truncatedDateFrom === truncatedStatDate) {
      // Ex: 2019-04-01 becomes 2019-04-08
      stats[0].event_date = formattedDate;
    }
  }

  let processedStats = [{ data: stats, color: option.color }];
  if (['weekly', 'yearly'].includes(selectedGranularity)) {
    processedStats = context.actions.graph.aggregateStats({
      allStats: processedStats,
      xTicks: ticks,
      merchantPerformance: true,
    });
  }
  return processedStats;
};

export const processEditGraphStats = (
  context: Context,
  {
    respData,
    ticks,
    selectedGranularity,
    dateRange,
  }: { respData: any; ticks: any; selectedGranularity: any; dateRange: any },
) => {
  let processedStats = respData.map((obj: any) => {
    // fill in zero'd out rows
    if ('daily' === selectedGranularity) {
      const processedData = [...obj.data];
      obj.data.forEach(
        (
          dayData: {
            event_date: string;
            originalDate: string;
            [k: string]: any;
          },
          idx: number,
        ) => {
          if (idx > 0) {
            let dayDiff = moment(dayData.event_date).diff(
              moment(obj.data[idx - 1].event_date),
              'days',
            );

            while (dayDiff > 1) {
              const newDateData = {
                event_date: moment(obj.data[idx - 1].event_date)
                  .add(dayDiff - 1, 'd')
                  .format('YYYY-MM-DD'),
              } as {
                event_date: string;
                originalDate: string;
                [k: string]: any;
              };

              Object.keys(dayData).forEach((key: string) => {
                if (key !== 'event_date' && key !== 'originalDate')
                  newDateData[key as any] = 0;
              });

              processedData.splice(idx, 0, newDateData);
              dayDiff -= 1;
            }
          }
        },
      );
      obj.data = sortBy(processedData, 'event_date');
      return obj;
    }

    if (['monthly', 'yearly'].includes(selectedGranularity)) {
      const formattedDate = dateRange.date_from;
      const truncatedDateFrom = formattedDate.slice(0, 7);
      const truncatedStatDate = obj.data[0].event_date.slice(0, 7);

      if (truncatedDateFrom === truncatedStatDate) {
        // Ex: 2019-04-01 becomes 2019-04-08
        obj.data[0].event_date = formattedDate;
      }
    }
    return obj;
  });

  if (['weekly', 'yearly'].includes(selectedGranularity)) {
    processedStats = context.actions.graph.aggregateStats({
      allStats: processedStats,
      xTicks: ticks,
      merchantPerformance: true,
    });
  }

  return processedStats;
};
