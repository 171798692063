import React from 'react';
import { useAppState } from '@frontend/howl-web-app/overmind';
import { AccountCreatorNavItems } from '../AccountCreatorNavItems';
import { AccountMerchNavItems } from '../AccountMerchNavItems';

export const NavItems = ({
  close,
  setToggleAccountSwitcher,
  displayAlertIcon,
}: {
  close: any;
  setToggleAccountSwitcher: any;
  displayAlertIcon: boolean;
}) => {
  const { sessionData } = useAppState().session;

  return (
    <>
      {sessionData.userRole === 'publishers' && (
        <AccountCreatorNavItems
          onNavClick={() => {
            setToggleAccountSwitcher(false);
            close();
          }}
          showAlertIcon={displayAlertIcon}
        />
      )}
      {sessionData.userRole === 'merchants' && (
        <AccountMerchNavItems
          onNavClick={() => {
            setToggleAccountSwitcher(false);
            close();
          }}
        />
      )}
    </>
  );
};
