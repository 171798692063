import moment from 'moment';
import mockGiftInfo from './gift-info.json';
import mockFlatFeeDetails from './flat-fee-info.json';
import mockSephoraEvent from './sephora-savings-event.json';
import { pipe, debounce } from 'overmind';

import { Context } from '..';
import {
  brandMocks,
  campaignMocks,
  productMocks,
  Campaign,
  reservedMocks,
  feedbackStatusMocks,
  SephoraMock,
} from './state';

export const fetchMockSephoraEventData = (): SephoraMock => {
  return mockSephoraEvent;
};

export const fetchFavoriteBrands = async (
  { effects }: Context,
  {
    publisherId,
    pageParam = 1,
    version = 0,
    strategyTargetingVersion = 0,
  }: {
    publisherId: number;
    pageParam: number;
    version?: number;
    strategyTargetingVersion?: number;
  },
) => {
  const params = {
    favorite: 'true',
    page: pageParam ? pageParam : 1,
    sortDirection: 'desc',
    per_page: 150,
    include_favorites: true,
    version: version,
    viewable_strategies_api_version: strategyTargetingVersion,
  };

  try {
    const response = await effects.api.get(
      `/api/v0/publishers/${publisherId}/brands/`,
      params,
    );
    const brands = response.data.data[0].brands;
    const totalItems = response.data.data[0].total_items;
    return {
      brands,
      totalItems,
    };
  } catch (e: any) {
    throw new Error(e.message);
  }
};

// fetch and update state methods
export const fetchBrands = async (
  { effects }: Context,
  {
    publisherId,
    searchQuery,
    sortField = 'evergreen_rate',
    sortDirection = 'desc',
    showMocks,
    merchId,
    page = 1,
    perPage = 10,
    version = 0,
    strategyTargetingVersion = 0,
    categoryIds = null,
    merchIds = null,
  }: {
    publisherId: number;
    sortField?: string;
    sortDirection?: string;
    searchQuery?: string;
    showMocks?: boolean;
    merchId?: number;
    page?: number;
    perPage?: number;
    version?: number;
    strategyTargetingVersion?: number;
    categoryIds?: number[] | null;
    merchIds?: string | null;
  },
) => {
  const params = {
    ...(searchQuery ? { search_query: searchQuery } : {}),
    ...(merchId ? { merch_ids: merchId } : {}),
    ...(categoryIds ? { category_ids: categoryIds.toString() } : {}),
    ...(merchIds ? { merch_ids: merchIds.toString() } : {}),
    direction: sortDirection,
    order_by: sortField,
    page,
    per_page: perPage,
    include_favorites: true,
    version: version,
    viewable_strategies_api_version: strategyTargetingVersion,
  };

  if (showMocks) {
    return {
      totalItems: 10,
      brands: brandMocks,
    };
  } else {
    try {
      const response = await effects.api.get(
        `/api/v0/publishers/${publisherId}/brands/`,
        params,
      );

      const brands = response.data.data[0].brands;
      brands.forEach((brand: any) => {
        if (brand.merch_id === 1234) {
          brand.posting_description =
            'Sephora posts orders and commissions when customer orders are shipped. Typically, this takes 2-3 days; in sale periods this may take up to 7 days.';
        } else if (brand.merch_id === 1019) {
          brand.posting_description =
            'Target posts orders and commissions on a 2-3 day delay.';
        }
      });
      const totalItems = response.data.data[0].total_items;
      return {
        totalItems,
        brands,
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }
};

export const fetchReserved = async (
  { effects, state }: Context,
  {
    publisherId,
    showMocks,
    dateTo,
    dateFrom,
  }: {
    publisherId: number;
    showMocks?: boolean;
    dateTo?: string;
    dateFrom?: string;
  },
) => {
  const colorConfig = [
    '#5F45FF',
    '#C6BDFF',
    '#DB52C3',
    '#E3CBAC',
    '#C9EC57',
    '#FFB2AD',
    '#4D7590',
    '#2300A8',
  ];

  if (showMocks) {
    const formattedCampaigns = reservedMocks.map((campaign, index) => {
      const color = colorConfig[index % colorConfig.length];
      return {
        ...campaign,
        color,
      };
    });

    state.creatorCampaignCenter.reservedCampaigns = formattedCampaigns.slice(
      0,
      4,
    );
  } else {
    try {
      const params = {
        date_from: dateFrom,
        date_to: dateTo,
      };
      state.creatorCampaignCenter.isLoading = true;
      const res = await effects.api.get(
        `/api/v0/publishers/${publisherId}/campaigns/reserved/`,
        params,
      );

      const formattedCampaigns = res.data.data[0].reserved_campaigns.map(
        (campaign: any, index: any) => {
          const color = colorConfig[index % colorConfig.length];
          return {
            ...campaign,
            color,
          };
        },
      );

      state.creatorCampaignCenter.reservedCampaigns = formattedCampaigns;
      state.creatorCampaignCenter.isLoading = false;
    } catch {
      state.creatorCampaignCenter.reservedCampaigns = [];
      state.creatorCampaignCenter.isLoading = false;
    }
  }
};

// How strategies/strategy id's are passed and used here could be modified once strategies are also retrieved and stored via Overmind
/**
 * @param {string} strategyIdList - Keeping this method's signature the same as the ecomm's side,
 * but the BE endpoint only supports a single strategy_id, so will only parse the first int if possible, otherwise discard
 */
export const fetchBrandProducts = async (
  { effects, state, actions }: Context,
  {
    publisherId,
    merchantId,
    searchQuery,
    page,
    perPage,
    showMocks,
    inStockOnly = true,
    saleOnly = false,
    giftsOnly = false,
    strategyIdList,
    groupingVersion = -1,
  }: {
    publisherId: number;
    merchantId: number;
    searchQuery?: string;
    page: number;
    perPage: number;
    showMocks?: boolean;
    inStockOnly?: boolean;
    saleOnly?: boolean;
    giftsOnly?: boolean;
    strategyIdList?: string;
    groupingVersion?: number;
  },
) => {
  let strategyId = null;
  if (strategyIdList) {
    try {
      strategyId = parseInt(strategyIdList, 10);
    } finally {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    }
  }

  const endpoint = `/api/v2/publishers/${publisherId}/brands/${merchantId}/products/`;
  strategyId = null;

  const payload = {
    ...(strategyId
      ? { strategy_id: strategyId }
      : strategyIdList
      ? { strategy_ids: strategyIdList }
      : {}),
    search_query: searchQuery ? searchQuery : null,
    page: page,
    per_page: perPage,
    in_stock_only: inStockOnly,
    sale_only: saleOnly,
    ...(giftsOnly ? { gift_only: giftsOnly } : {}),
    grouping_version: groupingVersion,
    // return_query: true,
  };
  if (showMocks) {
    state.creatorCampaignCenter.products = productMocks;
    state.creatorCampaignCenter.productsTotalItems = productMocks.length;
    return {
      products: productMocks,
      productsTotalItems: productMocks.length,
    };
  } else {
    try {
      state.creatorCampaignCenter.campaignProductsLoading = true;
      const res = await effects.api.get(endpoint, payload);
      const response = res.data.data[0];
      state.creatorCampaignCenter.products = response.products;
      state.creatorCampaignCenter.productsTotalItems = response.total_items;
      state.creatorCampaignCenter.campaignProductsLoading = false;
      return {
        products: response.products,
        productsTotalItems: response.total_items,
      };
    } catch (e: any) {
      state.creatorCampaignCenter.products = [];
      state.creatorCampaignCenter.campaignProductsLoading = false;
      throw new Error(e.message);
    }
  }
};

export const fetchGifts = async (
  { effects, state }: Context,
  {
    publisherId,
    merchantId,
    strategyId,
    showMocks,
  }: {
    publisherId: number;
    merchantId: number;
    strategyId: string;
    showMocks?: boolean;
  },
) => {
  try {
    state.creatorCampaignCenter.isLoading = true;
    const res = showMocks
      ? { data: mockGiftInfo }
      : await effects.api.get(
          `/api/v0/publishers/${publisherId}/brands/${merchantId}/campaigns/${strategyId}/gifts/`,
        );
    const response = res.data.data[0];
    state.creatorCampaignCenter.giftInfo = response;
    state.creatorCampaignCenter.isLoading = false;
    return response;
  } catch (e: any) {
    state.creatorCampaignCenter.giftInfo = null;
    state.creatorCampaignCenter.isLoading = false;
    throw new Error(e.message);
  }
};

export const clearGiftInfo = ({ state }: Context) => {
  state.creatorCampaignCenter.giftInfo = null;
};

export const fetchFlatFeeDetails = async (
  { effects, state }: Context,
  {
    publisherId,
    merchantId,
    strategyId,
    showMocks,
    sortField,
  }: {
    publisherId: number;
    merchantId: number;
    strategyId: string;
    showMocks?: boolean;
    sortField: string;
  },
) => {
  try {
    state.creatorCampaignCenter.isLoading = true;
    const res = showMocks
      ? { data: mockFlatFeeDetails }
      : await effects.api.get(
          `/api/v0/publishers/${publisherId}/brands/${merchantId}/campaigns/${strategyId}/flat_fee_details/`,
          {
            order_by: sortField,
            // direction: sortDirection
          },
        );
    const response = res.data.data[0];
    state.creatorCampaignCenter.flatFeeDetails = response;
    state.creatorCampaignCenter.isLoading = false;
  } catch (e: any) {
    state.creatorCampaignCenter.flatFeeDetails = null;
    state.creatorCampaignCenter.isLoading = false;
    throw new Error(e.message);
  }
};
export const fetchParticipatingCreators = async (
  { effects, state }: Context,
  { strategyId }: { strategyId: number },
) => {
  try {
    state.creatorCampaignCenter.isLoading = true;
    const res = await effects.api.get(
      `/api/v0/publishers/${state.organization.selectedOrgId}/campaigns/${strategyId}/participating_creators/`,
    );

    const response = res.data.data[0];
    state.creatorCampaignCenter.participatingCreators = response.creators;
    state.creatorCampaignCenter.totalParticipatingCreators =
      response.total_participating_creators;
    state.creatorCampaignCenter.isLoading = false;
    return null;
  } catch (e: any) {
    state.creatorCampaignCenter.participatingCreators = null;
    state.creatorCampaignCenter.totalParticipatingCreators = null;
    state.creatorCampaignCenter.isLoading = false;
    return new Error(e.message);
  }
};

export const fetchPartcipatingCreatorsBrandTotal = async (
  { effects, state }: Context,
  { merchId, featuredPubIds }: { merchId: number; featuredPubIds: string },
) => {
  try {
    state.creatorCampaignCenter.isLoading = true;
    const params = {
      featured_pub_ids: featuredPubIds ? featuredPubIds : null,
    };

    const res = await effects.api.get(
      `/api/v0/publishers/${state.organization.selectedOrgId}/brands/${merchId}/participating_creators/`,
      params,
    );

    const response = res.data.data[0];
    state.creatorCampaignCenter.creatorLogos = response.creator_logos;
    state.creatorCampaignCenter.totalParticipatingCreatorsBrandTotal =
      response.total_participating_creators;
    state.creatorCampaignCenter.featuredCreators = response.featured_creators;
    state.creatorCampaignCenter.isLoading = false;
    return null;
  } catch (e: any) {
    state.creatorCampaignCenter.creatorLogos = null;
    state.creatorCampaignCenter.totalParticipatingCreatorsBrandTotal = null;
    state.creatorCampaignCenter.featuredCreators = null;
    state.creatorCampaignCenter.isLoading = false;
    return new Error();
  }
};

export const fetchGmvPerformance = async (
  { effects, state, actions }: Context,
  { strategyId }: { strategyId: string },
) => {
  try {
    const res = await effects.api.get(
      `/api/v0/publishers/${state.organization.selectedOrgId}/performance/?fields=attributed_revenue&flat_fee_strategy_id=${strategyId}`,
    );
    const response = res.data.data[0];
    const stats = response.stats.length ? response.stats[0] : null;
    state.creatorCampaignCenter.gmvDetails = stats;
  } catch (e: any) {
    state.creatorCampaignCenter.gmvDetails = null;
    actions.ui.triggerSnackbar({
      content: 'Could not retrieve GMV Goal Progress.',
    });
  }
};

export const handleError = ({ actions }: Context, error: any) => {
  actions.ui.triggerSnackbar({
    content: 'There was an error loading data',
    color: 'systemError',
  });
  console.log(error.message);
};

export const clearBrandProducts = ({ state }: Context) => {
  state.creatorCampaignCenter.products = [];
};

export const fetchCampaign = async (
  { effects, state }: Context,
  {
    publisherId,
    merchId,
    strategyIds,
    showMocks,
    allowEvergreen = true,
    version = 0,
    strategyTargetingVersion = 0,
  }: {
    publisherId: number;
    sortField?: string;
    sortDirection?: string;
    merchId?: number;
    strategyIds?: number[];
    searchQuery?: string;
    showMocks?: boolean;
    page?: number;
    perPage?: number;
    allowEvergreen?: boolean;
    version?: number;
    strategyTargetingVersion?: number;
  },
) => {
  const params = {
    ...(merchId ? { merch_id: merchId } : {}),
    ...(strategyIds ? { strategy_ids: strategyIds.toString() } : {}),
    allow_evergreen: allowEvergreen,
    version: version,
    viewable_strategies_api_version: strategyTargetingVersion,
  };

  if (showMocks) {
    state.creatorCampaignCenter.selectedCampaign = campaignMocks[0];
  } else {
    try {
      state.creatorCampaignCenter.isLoading = true;
      const response = await effects.api.get(
        `/api/v0/publishers/${publisherId}/campaigns/`,
        params,
      );
      const campaigns = response.data.data[0].campaigns;
      return campaigns[0] ? campaigns[0] : null;
    } catch (e: any) {
      throw new Error(e);
    }
  }
};

export const clearSelectedCampaign = ({ state }: Context) => {
  state.creatorCampaignCenter.selectedCampaign = null;
};

export const fetchCampaigns = pipe(
  debounce(500),
  async (
    { effects }: Context,
    {
      publisherId,
      merchId,
      strategyIds,
      searchQuery,
      sortField = 'strategy_rate',
      sortDirection = 'desc',
      pageParam = 1,
      perPage = 10,
      allowEvergreen = true,
      badgeFilter,
      categoryIds = null,
      version = 0,
      strategyTargetingVersion = 0,
    }: {
      publisherId: number;
      sortField?: string;
      sortDirection?: string;
      merchId?: number;
      strategyIds?: number[];
      searchQuery?: string;
      pageParam?: number;
      perPage?: number;
      allowEvergreen?: boolean;
      badgeFilter?: any;
      categoryIds?: number[] | null;
      version?: number;
      strategyTargetingVersion?: number;
    },
  ) => {
    const badgeFilterParams = badgeFilter
      ? {
          gifting_available: badgeFilter.value === 'gifting_available',

          on_sale: badgeFilter.value === 'on_sale',

          product_launch: badgeFilter.value === 'product_launch',

          flat_fee: badgeFilter.value === 'flat_fee',

          cpc: badgeFilter.value === 'cpc',

          filter_favorited_brands: badgeFilter.value === 'favorited_brands',
        }
      : {};

    const params = {
      ...(searchQuery ? { search_query: searchQuery } : {}),
      ...(merchId ? { merch_id: merchId } : {}),
      ...(strategyIds ? { strategy_ids: strategyIds.toString() } : {}),
      ...(categoryIds ? { category_ids: categoryIds.toString() } : {}),
      ...badgeFilterParams,
      direction: sortDirection,
      order_by:
        sortField === 'flat_fee_total_range_min' && sortDirection === 'desc'
          ? 'flat_fee_total_range_max'
          : sortField,
      page: pageParam,
      per_page: perPage,
      allow_evergreen: allowEvergreen,
      version: version,
      viewable_strategies_api_version: strategyTargetingVersion,
    };

    try {
      const response = await effects.api.get(
        `/api/v0/publishers/${publisherId}/campaigns/`,
        params,
      );
      const campaigns: Campaign[] = response.data.data[0].campaigns;
      const totalItems = response.data.data[0].total_items;

      return {
        campaigns: campaigns,
        total_items: totalItems,
        nextCursor: pageParam + 1,
      };
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
);

export const fetchMyFlatFees = async (
  { effects, state }: Context,
  {
    publisherId,
    merchId,
    strategyIds,
    searchQuery,
    sortField = 'strategy_rate',
    sortDirection = 'desc',
    showMocks,
    page = 1,
    perPage = 10,
    allowEvergreen = true,
    badgeFilter,
    version = 0,
    strategyTargetingVersion = 0,
  }: {
    publisherId: number;
    sortField?: string;
    sortDirection?: string;
    merchId?: number;
    strategyIds?: number[];
    searchQuery?: string;
    showMocks?: boolean;
    page?: number;
    perPage?: number;
    allowEvergreen?: boolean;
    badgeFilter?: any;
    version?: number;
    strategyTargetingVersion?: number;
  },
) => {
  const badgeFilterParams = badgeFilter?.length
    ? {
        gifting_available: !!badgeFilter.find(
          (filter: any) => filter.value === 'gifting_available',
        ),
        on_sale: !!badgeFilter.find(
          (filter: any) => filter.value === 'on_sale',
        ),
        product_launch: !!badgeFilter.find(
          (filter: any) => filter.value === 'product_launch',
        ),
        flat_fee: !!badgeFilter.find(
          (filter: any) => filter.value === 'flat_fee',
        ),
      }
    : {};

  const params = {
    ...(searchQuery ? { search_query: searchQuery } : {}),
    ...(merchId ? { merch_id: merchId } : {}),
    ...(strategyIds ? { strategy_ids: strategyIds.toString() } : {}),
    ...badgeFilterParams,
    direction: sortDirection,
    order_by:
      sortField === 'flat_fee_total_range_min' && sortDirection === 'desc'
        ? 'flat_fee_total_range_max'
        : sortField,
    page,
    per_page: perPage,
    allow_evergreen: allowEvergreen,
    version: version,
    viewable_strategies_api_version: strategyTargetingVersion,
  };
  if (showMocks) {
    state.creatorCampaignCenter.myFlatFees = campaignMocks;
    return null;
  } else {
    try {
      state.creatorCampaignCenter.myFlatFeesIsLoading = true;

      const response = await effects.api.get(
        `/api/v0/publishers/${publisherId}/campaigns/`,
        params,
      );
      const campaigns: Campaign[] = response.data.data[0].campaigns;
      const totalItems = response.data.data[0].total_items;

      state.creatorCampaignCenter.myFlatFees = campaigns;
      state.creatorCampaignCenter.myFlatFeesTotalItems = totalItems;

      state.creatorCampaignCenter.myFlatFeesIsLoading = false;
      return {
        campaigns: campaigns,
        total_items: totalItems,
      };
    } catch (e: any) {
      state.creatorCampaignCenter.myFlatFees = [];
      state.creatorCampaignCenter.myFlatFeesIsLoading = false;
      state.creatorCampaignCenter.error = e.message;
      throw new Error(e.message);
    }
  }
};

export const fetchFeaturedCampaignData = async (
  { effects }: Context,
  { publisherId }: { publisherId: number },
) => {
  try {
    const response = await effects.api.get(
      `/api/v0/publishers/${publisherId}/modules/3/`,
    );

    const moduleWithStrategy = response.data.data[0].data.modules.find(
      (mod: any) => {
        if (mod.strategies.length && mod.strategies[0]) {
          return true;
        } else {
          return false;
        }
      },
    );

    // strategy is found by modules[0].strategies[0]
    const strategy = moduleWithStrategy
      ? moduleWithStrategy.strategies[0]
      : null;

    // products are found by doing, modules[0].strategies[0].products
    const products =
      strategy && strategy.products.length ? strategy.products : null;

    return {
      products: products,
      strategy: strategy,
    };
  } catch (e: any) {
    console.warn(e);
    throw new Error(e);
  }
};

export const reserveCampaign = async (
  { effects, actions }: Context,
  {
    strategyId,
    pubId,
  }: {
    strategyId: number;
    pubId: number;
  },
) => {
  try {
    const response = await effects.api.post(
      `/api/v0/publishers/${pubId}/campaigns/${strategyId}/reserve/`,
    );

    const reservedStatus = response.data.data[0].current_status;
    return reservedStatus;
  } catch (error) {
    actions.ui.triggerSnackbar({
      content: 'Could not reserve campaign. Please try again.',
    });
    return error;
  }
};

export const unreserveCampaign = async (
  { effects, actions }: Context,
  {
    strategyId,
    pubId,
  }: {
    strategyId: number;
    pubId: number;
  },
) => {
  try {
    const response = await effects.api.post(
      `/api/v0/publishers/${pubId}/campaigns/${strategyId}/unreserve/`,
    );
    const reservedStatus = response.data.data[0].current_status;
    return reservedStatus;
  } catch (error) {
    actions.ui.triggerSnackbar({
      content: 'Could not reserve campaign. Please try again.',
    });
    return error;
  }
};

export const applyToCampaign = async (
  { effects, actions }: Context,
  {
    strategyId,
    pubId,
    params,
  }: {
    strategyId: number;
    pubId: number;
    params?: any;
  },
) => {
  try {
    const response = await effects.api.post(
      `/api/v0/publishers/${pubId}/campaigns/${strategyId}/flat_fee_apply/`,
      params,
    );
    const appliedStatus = response.data.data[0].campaign_opt_in;

    return appliedStatus;
  } catch (error) {
    actions.ui.triggerSnackbar({
      content: 'Could not apply to campaign. Please try again.',
    });
    return error;
  }
};

export const fetchFeedbackStatus = async (
  { effects, state }: Context,
  {
    publisherId,
    campaignId,
    showMocks,
  }: {
    publisherId: number;
    campaignId: number;
    showMocks: boolean;
  },
) => {
  if (showMocks) {
    state.creatorCampaignCenter.feedbackStatus = feedbackStatusMocks;
  } else {
    try {
      const response = await effects.api.get(
        `/api/v0/publishers/${publisherId}/campaigns/${campaignId}/feedback/`,
      );
      const feedbackStatus = response.data.data[0];
      state.creatorCampaignCenter.feedbackStatus = feedbackStatus;
    } catch {
      state.creatorCampaignCenter.feedbackStatus = null;
    }
  }
};

export const updateFeedbackStatus = async (
  { effects, state }: Context,
  {
    publisherId,
    campaignId,
    response,
    reason,
    showMocks,
    notes,
  }: {
    publisherId: number;
    campaignId: number;
    response: number;
    reason: number | null;
    showMocks: boolean;
    notes: string | null;
  },
) => {
  const params = {
    feedback_status: response,
    feedback_reason: reason,
    notes,
  };

  if (showMocks) {
    state.creatorCampaignCenter.feedbackStatus = { ...feedbackStatusMocks };
  } else {
    try {
      const response = await effects.api.put(
        `/api/v0/publishers/${publisherId}/campaigns/${campaignId}/feedback/`,
        params,
      );
      const feedbackStatus = response.data.data[0];
      state.creatorCampaignCenter.feedbackStatus = feedbackStatus;
    } catch {
      state.creatorCampaignCenter.feedbackStatus = null;
    }
  }
};

export const clearCampaignCenterModal = ({ state }: Context) => {
  state.creatorCampaignCenter.modal = {
    type: null,
    isVisible: false,
  };
};

export const fetchTrendingProducts = async (
  { effects, state }: Context,
  {
    merchantId,
    publisherId,
    productIds,
  }: { merchantId: number; publisherId: number; productIds?: string },
) => {
  try {
    const resp = await effects.api.get(
      `/api/v1/publishers/${publisherId}/brands/${merchantId}/best_seller_products/`,
      {
        merchant_product_ids: productIds,
      },
    );
    return resp.data.data[0].best_selling_products;
  } catch (e: any) {
    throw new Error(e);
  }
};

// helper methods
export const formatCampaignDateInfo = (
  context: Context,
  campaign: Campaign,
) => {
  const dateInfo = { duration: '', daysText: '' };
  const strategyStart = moment.utc(campaign.strategy_start_date);
  const strategyEnd = moment.utc(campaign.strategy_end_date);

  if (campaign.strategy_start_date && campaign.strategy_end_date) {
    const startDateFormat =
      strategyStart.year() !== strategyEnd.year() ? 'MMM D, YYYY' : 'MMM D';
    dateInfo.duration = `${strategyStart.format(
      startDateFormat,
    )} - ${strategyEnd.format('MMM D, YYYY')}`;
    const today = moment().utc();
    if (strategyStart > today) {
      dateInfo.daysText = `Starts in ${strategyStart
        .startOf('day')
        .diff(today.startOf('day'), 'days')} days`;
    } else if (strategyEnd < today) {
      dateInfo.daysText = 'Completed';
    } else {
      dateInfo.daysText = `${strategyEnd
        .startOf('day')
        .diff(today.startOf('day'), 'days')} Days Left`;
    }
  } else if (campaign.strategy_start_date) {
    dateInfo.duration = `${strategyStart.format('MMM D, YYYY')} - Present`;
    dateInfo.daysText = '';
  }
  return dateInfo;
};
