import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import {
  formatCurrencyThousands,
  formatNumberWithCommas,
  useMediaQuery,
} from '@frontend/shared-utils';
import { api } from '@frontend/howl-web-app/trpc';

export const EarningsFooter = () => {
  const [totalEarnings, setTotalEarnings] = useState<number | null>(null);
  const [digits, setDigits] = useState<string[]>(['']);
  const [earningsSummary, setEarningsSummary] = useState<string>('');
  const { data, isFetching, isError } =
    api.creatorPlatformEarnings.getCreatorPlatformEarnings.useQuery(undefined, {
      cacheTime: 60000,
      staleTime: 60000,
      refetchInterval: 60000,
    });

  useEffect(() => {
    if (data && data.length) {
      const newTotal = Math.round(parseInt(data[0].platformEarnings));
      if (newTotal !== totalEarnings) {
        // in prod, earnings will be with 8 or 9 digits
        const initDigits =
          newTotal < 1e8
            ? ['0', '0', ',', '0', '0', '0', ',', '0', '0', '0']
            : ['0', '0', '0', ',', '0', '0', '0', ',', '0', '0', '0'];
        setDigits(initDigits);
        setTimeout(() => performQuery(newTotal), 50);
      }
    }
  }, [data]);

  const performQuery = (newTotal: number) => {
    // check if the new value differs from the old and call update()
    setTotalEarnings(newTotal);
    const roundedTotal = Math.floor(newTotal / 1e6) * 1e6;
    setEarningsSummary(
      formatCurrencyThousands(roundedTotal.toString(), 'M', '', 0),
    );
    setDigits(formatNumberWithCommas(newTotal).split(''));
  };

  const isMobile = useMediaQuery('(max-width: 640px)');

  return (
    totalEarnings && (
      <div className="border-t border-neutral-gray-200 py-2 px-2 text-sm text-center sm:py-4 sm:px-6 sm:text-base">
        <div className="flex flex-row justify-between items-center">
          <span className={'flex flex-row space-x-0.5 text-sm px-2'}>
            <span className={'text-violet-300 sm:text-black'}>$</span>
            {isMobile ? (
              <div className="text-violet-300">{earningsSummary}</div>
            ) : (
              !!digits.length &&
              digits.map((digit: string, index) => {
                return isNaN(parseInt(digit)) ? (
                  <div className="text-sm">{digit}</div>
                ) : (
                  <CountUp
                    start={0}
                    end={parseInt(digit)}
                    prefix=""
                    suffix=""
                    duration={4 / (index + 2)}
                    preserveValue={false}
                    useEasing={false}
                    delay={0}
                  >
                    {({
                      countUpRef,
                      start,
                    }: {
                      countUpRef: any;
                      start: any;
                    }) => (
                      <div className="bg-primary-10 rounded-sm tabular-nums text-sm w-[18px] h-[22px]">
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                );
              })
            )}
            <span className="ml-1 text-sm"> paid to creators</span>
          </span>
          <p className="text-sm">Powered by NerdJoy&trade;</p>
        </div>
      </div>
    )
  );
};
