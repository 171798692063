import { Client } from 'urql';
import { GetAllCategoriesDocument } from '@frontend/shared-graphql';

const transformCategories = (categories: any[]) => {
  const transformed = Object.assign(
    {},
    ...categories.map((category) => ({
      [category.category_id]: category.name,
    })),
  );
  return transformed;
};

export const getCategoryMap = (urqlClient: Client, setCategories: any) => {
  urqlClient
    .query(GetAllCategoriesDocument, {})
    .toPromise()
    .then((result) => {
      if (result.data && !result.error) {
        const categories = result.data?.getCategories?.data?.[0]?.categories;
        if (categories) {
          setCategories(transformCategories(categories));
          return;
        }
      }
      setCategories(null);
    });
  return null;
};
