import React, { FC, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';

type Color = 'base' | 'red' | 'green' | 'systemError' | 'black';

export type SnackbarProps = {
  /** Text/content to display inside the Snackbar. */
  content: ReactNode;

  /** Length in milliseconds the Snackbar will stay visible. */
  duration?: number;

  /** Additional classes that append to the Snackbar container element. */
  className?: string;

  /** Color & style/type intention of Snackbar display. */
  color?: Color;

  /** Click handler for when Snackbar has been closed. */
  onClose?: () => void;

  /** Flag to indicate a relative Snackbar instead of the default fixed position. */
  relative?: boolean;
};

const colorClasses: Record<Color, { container: string; closeIcon: string }> = {
  base: {
    container: 'border-[#555555] bg-[#555555] text-white',
    closeIcon: 'text-white',
  },
  red: {
    container:
      'border-l-4 border-[#B81818] text-[#B81818] shadow-full bg-white',
    closeIcon: 'text-black',
  },
  systemError: {
    container:
      'border-l-4 border-[#B81818] text-white bg-[#B81818] shadow-full bg-white',
    closeIcon: 'text-white',
  },
  green: {
    container:
      'border-l-4 border-[#107C3C] shadow-full bg-white text-[#107C3C]',
    closeIcon: 'text-black',
  },
  black: {
    container: 'bg-black',
    closeIcon: 'text-white',
  },
};

/** The Snackbar component presents a notice / status of UI processes initiated by the user. */
export const Snackbar: FC<SnackbarProps> & { filePath: string } = ({
  content,
  duration = 5000,
  className,
  color = 'base',
  onClose,
  relative,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const closeSnackbar = () => {
    if (!isVisible) return;

    setIsVisible(false);
    if (typeof onClose !== 'undefined') {
      // match the fade-out delay
      setTimeout(onClose, 500);
    }
  };

  const handleCloseClick = () => {
    closeSnackbar();
  };

  useEffect(() => {
    setTimeout(() => {
      closeSnackbar();
    }, duration);
  }, []);

  return (
    <Transition
      show={isVisible}
      enter="transition-opacity ease-linear duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      data-cy="snackbar"
      className={classNames('z-50', {
        'fixed sm:left-2/4 transform sm:-translate-x-2/4 bottom-2 sm:bottom-4 w-full sm:w-auto ':
          !relative,
      })}
    >
      <div
        className={classNames(
          'mx-4 sm:mx-auto transform z-40 opacity-1 relative flex items-center justify-between my-0 rounded-md p-4 text-sm shadow-elevation-high',
          colorClasses[color].container,
          className,
        )}
      >
        {content}
        <XIcon
          className={classNames(
            'cursor-pointer h-4 w-4 ml-4',
            colorClasses[color].closeIcon,
          )}
          onClick={handleCloseClick}
        />
      </div>
    </Transition>
  );
};

Snackbar.displayName = 'Snackbar';
Snackbar.filePath = 'libs/shared/ui-kit/src/lib/snackbar/snackbar.tsx';
