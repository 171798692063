type State = {
  limit: any;
  paginationOptions: any;
  page: any;
  indexStart: any;
  indexEnd: any;
  productsByMerch: any;
  cartDataByMerch: any;
  productTotals: any;
};

export const state: State = {
  limit: 500, // The size of API responses, perPage is for the display
  paginationOptions: [500, 100, 50],
  page: null,
  indexStart: null,
  indexEnd: null,
  productsByMerch: {},
  cartDataByMerch: {},
  productTotals: {},
};
