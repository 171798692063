import React, { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ModalWrapper from '../../base/modals/modal-wrapper';
import Button from '../../ui/button';
import { useEffects, useAppState } from '@frontend/howl-web-app/overmind';

const AgreeMerchantTermsModal = () => {
  const [showAgreeTerms, setShowAgreeTerms] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');
  const [termsId, setTermsId] = useState(null);

  const { sessionData } = useAppState().session;
  const userRole = sessionData?.userRole || '';
  const isAdmin = sessionData?.isAdmin || false;
  const email = sessionData?.email || '';
  const api = useEffects().api;
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  const apiEndpoint = process.env.NEXT_PUBLIC_MERCHANT_API_ENDPOINT;

  const { merchantTermsConditions } = useFlags();

  useEffect(() => {
    if (userRole) {
      // We don't want to show the modal to internal users attached to merchant account
      const internalUserEmail =
        email.includes('@narrativ.com') ||
        email.includes('@planethowl.com') ||
        email.includes('@bam-x.com');
      if (
        userRole === 'merchants' &&
        !isAdmin &&
        merchantTermsConditions &&
        !internalUserEmail
      ) {
        api
          .get(
            `${baseUrl}${apiEndpoint}${sessionData?.orgId}/terms_conditions/`,
          )
          .then((resp: any) => {
            const respData = resp.data.data[0];
            if (!respData.accepted_latest_terms) {
              setShowAgreeTerms(true);
              setTermsUrl(respData.latest_terms.link);
              setTermsId(respData.latest_terms.id);
            }
          });
      }
    }
  }, [userRole, isAdmin]);

  const acceptTerms = () => {
    const orgId = sessionData?.orgId || null;

    const acceptTermsConditionsRoute = `${baseUrl}${apiEndpoint}${orgId}/terms_conditions/`;
    const payload = { terms_id: termsId };

    api.post(acceptTermsConditionsRoute, payload).then(() => {
      setShowAgreeTerms(false);
    });
  };

  if (!showAgreeTerms) {
    return null;
  }

  return (
    <ModalWrapper showX={true} closeModal={() => setShowAgreeTerms(false)}>
      <div
        data-cy="agree-merchant-terms-modal"
        className="my-6 px-8 md:px-12 md:py-4 max-w-lg"
      >
        <span className="text-black text-[32px] sm:text-[42px] font-semibold font-serif">
          Terms & Conditions
        </span>
        <div className="mt-6 sm:mt-12">
          <div className="text-base">
            <span>
              By clicking Accept, you agree to and accept Howl's current{' '}
              <a
                href={termsUrl}
                download
                className="text-violet-300 underline"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              .
            </span>
          </div>
          <div className="mt-6 sm:mt-10 space-y-4">
            <Button
              label="Accept"
              kind="primary"
              onClick={acceptTerms}
              size="large"
              className="w-full justify-center"
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AgreeMerchantTermsModal;
