import axios from 'axios';
import jwt_decode from 'jwt-decode';

export type ClientOptions = {
  httpUrl: string;
  getJWT: () => string | null;
  refreshJWT?: () => Promise<string | null>;
  clearJWT?: () => Promise<string | null>;
  logout: () => Promise<string | null>;
};

const isTokenExpired = (token: string) => {
  // exp is in seconds. Refresh within 30 seconds of expiring
  const expirationMins =
    (jwt_decode<{ exp: number }>(token).exp - Date.now() / 1000) / 60;
  return expirationMins < 5;
};

const refreshingToken: any = null;

export const responseErrorHandler = async ({
  response,
  clearJWT,
  refreshJWT,
  logout,
}: {
  response: any;
  clearJWT?: any;
  refreshJWT?: any;
  logout: any;
}) => {
  const resp = response.response;
  let error = resp;

  if (!resp) {
    console.error('Full network error: ', response);
    error = 'Network error'; // E.g. If backend was not up, or CORS issue
  } else if (
    ([401, 403].includes(resp.status) && resp?.data?.error?.code !== 4309) ||
    (resp.status === 400 && resp.data.error === 'invalid_grant')
  ) {
    error = resp.data && resp.data.error;
    if (clearJWT) {
      console.log('logout #5');
      // await clearJWT();
      await logout();
    }
  } else {
    error = resp.data && resp.data.error;
  }

  return Promise.reject(error);
};

export const createClient = ({
  httpUrl: url,
  getJWT,
  refreshJWT,
  clearJWT,
  logout,
}: ClientOptions) => {
  const httpClient = axios.create({
    baseURL: url,
    transformRequest: [
      (data) => {
        return data instanceof FormData || data instanceof File
          ? data
          : JSON.stringify(data);
      },
    ],
  });

  httpClient.defaults.headers.post['Content-Type'] =
    'application/json;charset=UTF-8';
  httpClient.defaults.headers.put['Content-Type'] =
    'application/json;charset=UTF-8';

  httpClient.interceptors.request.use(
    async (options) => {
      const token = await getJWT();

      try {
        if (token) {
          const tokenSize = new Blob([token]).size;
          if (tokenSize > 4000) {
            console.warn(
              'The following network request may fail due to a large token size. Token size: ',
              tokenSize,
              'Request URL: ',
              options?.url,
            );
          }
        }
      } catch (ex) {
        console.info(
          'exception occurred when calculation JWT token size: ',
          ex,
        );
      }

      if (token) {
        options.headers.Authorization = `Bearer ${token}`;
      } else if (options.headers.Authorization) {
        delete options.headers.Authorization;
      }

      return options;
    },
    async (error) => {
      return Promise.reject(error);
    },
  );

  httpClient.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (errorResponse) => {
      return responseErrorHandler({
        response: errorResponse,
        refreshJWT,
        clearJWT,
        logout,
      });
    },
  );

  return { httpClient };
};

export const phylloClient = () => {
  const username = process.env['NEXT_PHYLLO_CLIENT_ID'];
  const password = process.env['NEXT_PHYLLO_API_KEY'];
  const token = Buffer.from(`${username}:${password}`).toString('base64');

  // timeout is set to 20 seconds to match BE standard
  // Phyllo avg response is between 5-12 seconds
  const client = axios.create({
    baseURL: process.env['NEXT_PHYLLO_URL'],
    headers: {
      Authorization: `Basic ${token}`,
    },
    timeout: 20000,
  });
  return { client };
};
