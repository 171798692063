import { Context } from '..';
import moment from 'moment';
import { Breadcrumb, KpiData } from './state';

export const resetState = ({ state }: Context) => {
  state.ecommCampaignPerformance = {
    ...state.ecommCampaignPerformance,
    overviewData: {},
    kpiData: null,
    topFiveData: { partners: [], stories: [] },
    loading: {
      topFive: { partners: false, stories: false },
      kpiModule: false,
      graph: false,
      reportDownload: false,
    },
    rateTiers: [],
    dateFilterRange: null,
  };
};

//<editor-fold desc="Setters">
export const setMerchantId = ({ state }: Context, merchantId: string) => {
  state.ecommCampaignPerformance.merchantId = merchantId;
};

export const setStrategies = ({ state }: Context, strategies: string) => {
  state.ecommCampaignPerformance.strategies = strategies;
};

export const setIo = ({ state }: Context, io: string) => {
  state.ecommCampaignPerformance.io = io;
};

export const setOverviewData = ({ state }: Context, overviewData: any) => {
  state.ecommCampaignPerformance.overviewData = overviewData;
};

export const setStrategyDateRange = ({ state }: Context, dateRange: any) => {
  state.ecommCampaignPerformance.strategyDateRange = dateRange;
};

export const setDateFilterRange = (
  { state }: Context,
  dateRange: { date_from: string; date_to: string },
) => {
  state.ecommCampaignPerformance.dateFilterRange = dateRange;
};

export const setBreadcrumb = ({ state }: Context, breadcrumb: Breadcrumb[]) => {
  state.ecommCampaignPerformance.breadcrumb = breadcrumb;
};

export const setSelectedGranularity = (
  { state }: Context,
  selectedGranularity: { label: string; value: string },
) => {
  state.ecommCampaignPerformance.selectedGranularity = selectedGranularity;
};

export const setStrategyIsEvergreen = (
  { state }: Context,
  isEvergreen: boolean,
) => {
  state.ecommCampaignPerformance.strategyIsEvergreen = isEvergreen;
};

export const setIsFlatFeeStrategy = (
  { state }: Context,
  isFlatFee: boolean,
) => {
  state.ecommCampaignPerformance.isFlatFeeStrategy = isFlatFee;
};
//</editor-fold>

//<editor-fold desc="Fetchers">
export const getIoData = async ({ actions, effects, state }: Context) => {
  const { merchantId, io } = state.ecommCampaignPerformance;
  const ioEndpoint = `/api/v0/merchants/${merchantId}/strategy_groups/${io}/strategy_group_by_id/`;
  await effects.api.get(ioEndpoint).then((resp: any) => {
    const data = resp.data.data[0];

    const viewDateRange = {
      date_from: moment.utc(data.strategy_group_datetime_start),
      date_to: data.strategy_group_datetime_end
        ? moment.utc(data.strategy_group_datetime_end)
        : moment(),
    };

    state.ecommCampaignPerformance.strategyDateRange = viewDateRange;

    const initialDateFilterRange =
      actions.ecommCampaignPerformance.getStrategyDateFilterRange({
        strategy: viewDateRange,
      });

    state.ecommCampaignPerformance.dateFilterRange = {
      date_from: initialDateFilterRange.startDate.format('YYYY-MM-DD'),
      date_to: initialDateFilterRange.endDate.format('YYYY-MM-DD'),
    };

    state.ecommCampaignPerformance.overviewData = {
      datetime_start: data.strategy_group_datetime_start,
      datetime_end: data.strategy_group_datetime_end,
      name: data.strategy_group_name,
      strategy_group_id: data.strategy_group_id,
    };
    state.ecommCampaignPerformance.breadcrumb = [
      {
        label: 'Your IO Performance',
        href: `/merchant/${merchantId}/analytics/ios`,
      },
      {
        label: data.strategy_group_name,
      },
    ];
  });
};

export const getStrategyData = async ({ actions, effects, state }: Context) => {
  const { merchantId, strategies } = state.ecommCampaignPerformance;
  const strategyIds = strategies ? strategies.split(',') : [];

  const strategyPromises = strategyIds.map((stratId) => {
    const endpoint = `/api/v0/merchants/${merchantId}/strategies/${stratId}/overview/`;
    return effects.api.get(endpoint).then((resp: any) => {
      return resp.data.data[0];
    });
  });

  await Promise.all(strategyPromises).then((results) => {
    const strategies = results.map((res) => res.stats);

    state.ecommCampaignPerformance.isFlatFeeStrategy =
      strategies.length === 1 && strategies[0].strategy_type === 'flat_fee';
    state.ecommCampaignPerformance.strategyIsEvergreen =
      strategies.length === 1 && strategies[0].is_evergreen;

    const viewDateRange = {
      date_from: moment.min(
        ...strategies.map((s) =>
          state.ecommCampaignPerformance.strategyIsEvergreen
            ? actions.date.getFirstOfMonth(moment())
            : moment.utc(s.datetime_start),
        ),
      ),
      date_to: moment.max(
        ...strategies.map((s) =>
          s.datetime_end ? moment.utc(s.datetime_end) : moment(),
        ),
      ),
    };

    state.ecommCampaignPerformance.strategyDateRange = viewDateRange;

    const initialDateFilterRange =
      actions.ecommCampaignPerformance.getStrategyDateFilterRange({
        strategy: viewDateRange,
      });

    state.ecommCampaignPerformance.dateFilterRange = {
      date_from: initialDateFilterRange.startDate.format('YYYY-MM-DD'),
      date_to: initialDateFilterRange.endDate.format('YYYY-MM-DD'),
    };

    if (strategies.length === 1) {
      const newOverviewData = strategies[0];
      if (newOverviewData.is_draft) {
        newOverviewData.strategy_status = 'draft';
      }
      state.ecommCampaignPerformance.overviewData = strategies[0];
    } else {
      state.ecommCampaignPerformance.overviewData = {
        name: strategies.map((s) => s.name),
      };
    }

    state.ecommCampaignPerformance.breadcrumb = [
      {
        label: 'Your Campaign Performance',
        href: `/merchant/${merchantId}/analytics/campaigns`,
      },
      {
        label:
          strategies.length === 1
            ? strategies[0].name
            : `Campaigns: ${results.map((r) => r.strategy_id).join(', ')}`,
      },
    ];
  });
};

export const getRateTierData = async ({ effects, state }: Context) => {
  const { merchantId, strategies } = state.ecommCampaignPerformance;

  await effects.api
    .get(`/api/v0/merchants/${merchantId}/strategies/${strategies}/rate_tiers/`)
    .then((resp: any) => {
      state.ecommCampaignPerformance.rateTiers = resp.data.data[0].rate_tiers;
    })
    .catch((err: Error) => {
      console.error(err);
    });
};

export const getFlatFeeKpiData = async ({
  actions,
  effects,
  state,
}: Context) => {
  state.ecommCampaignPerformance.loading.kpiModule = true;
  const { merchantId } = state.ecommCampaignPerformance;

  const requests = [
    await effects.api.get(
      `/api/v0/merchants/${merchantId}/performance/`,
      actions.ecommCampaignPerformance.getGenericKPIParams(),
    ),
    await effects.api.get(
      `/api/v0/merchants/${merchantId}/target_lists/publisher/target_list_size/`,
      {
        strategy_id: state.ecommCampaignPerformance.strategies,
      },
    ),
    // TODO: This can be removed and replaced with the new rate tier GET
    await effects.api.get(
      `/api/v0/merchants/${merchantId}/strategies/${state.ecommCampaignPerformance.strategies}/rate_tiers/`,
    ),
  ];

  await Promise.all(requests)
    .then(([kpiResp, partnersResp, rtResp]) => {
      actions.ecommCampaignPerformance.processKpiData({
        kpiData: kpiResp.data.data[0].stats,
        listData: partnersResp.data.data[0].target_list_sizes,
        rtData: rtResp.data.data[0].rate_tiers,
      });
    })
    .catch((err: Error) => {
      console.error(err);
    })
    .finally(() => (state.ecommCampaignPerformance.loading.kpiModule = false));
};
export const getTopFiveData = async (
  { actions, effects, state }: Context,
  { tableType, columns }: { tableType: 'stories' | 'partners'; columns: any },
) => {
  state.ecommCampaignPerformance.loading.topFive[tableType] = true;

  const { merchantId, isFlatFeeStrategy } = state.ecommCampaignPerformance;

  const promises = [
    await effects.api.get(
      `/api/v0/merchants/${merchantId}/performance/`,
      actions.ecommCampaignPerformance.getGenericTopFiveParams({
        tableType,
        columns,
      }),
    ),
  ];

  if (isFlatFeeStrategy) {
    promises.push(
      await effects.api.get(
        `/api/v0/merchants/${merchantId}/strategies/${state.ecommCampaignPerformance.strategies}/rate_tiers/`,
      ),
    );
  }

  await Promise.all(promises)
    .then(([topFiveResp, rateTiersResp]) =>
      actions.ecommCampaignPerformance.processTopFiveData({
        topFiveData: topFiveResp,
        rateTierData: rateTiersResp,
        tableType,
      }),
    )
    .catch((err: Error) => console.error(err))
    .finally(() => {
      state.ecommCampaignPerformance.loading.topFive[tableType] = false;
    });
};

export const getPerformanceReport = async (
  { actions, effects, state }: Context,
  {
    columns,
    tableParams,
    totalParams,
  }: { columns: any; tableParams: any; totalParams: any },
) => {
  state.ecommCampaignPerformance.loading.reportDownload = true;

  const { merchantId } = state.ecommCampaignPerformance;
  const csvEndpoint = `/api/v0/merchants/${merchantId}/performance/csv/`;

  const promises = [
    effects.api.get(csvEndpoint, tableParams),
    effects.api.get(csvEndpoint, totalParams),
  ];
  await Promise.all(promises)
    .then(([tableResp, totalResp]) => {
      let csvContent = 'data:text/csv;charset=utf-8,';
      const tableReportData = actions.ecommAnalytics.formatPerformanceReport({
        reportData: tableResp.data,
        totalsData: totalResp.data,
        columns,
      });
      csvContent += tableReportData ? encodeURIComponent(tableReportData) : '';
      window.open(csvContent);
    })
    .catch((err: Error) => {
      console.error(err);
    })
    .finally(() => {
      state.ecommCampaignPerformance.loading.reportDownload = false;
    });
};
//</editor-fold>

//<editor-fold desc="Misc">
export const getStrategyDateFilterRange = (
  { actions, state }: Context,
  { strategy }: { strategy: any },
) => {
  // If campaign is upcoming, use today as start date rather than future date
  const dateFrom = state.ecommCampaignPerformance.strategyIsEvergreen
    ? actions.date.getFirstOfMonth(moment())
    : strategy.date_from > moment.utc()
    ? moment.utc()
    : moment.utc(strategy.date_from);

  // If end_date is in the past (completed campaign) or start_date is in the future (upcoming) and strategy has end_date
  //   then use strategy datetime_end (range would be start_date -> end_date)
  // If the above is true & strategy has no end date
  //   use strategy datetime_start (range would be start_date -> start_date)
  // Else use today's date (range would be start_date -> today_date)
  const dateTo =
    strategy.date_from > moment.utc() && strategy.date_to === null
      ? strategy.date_from
      : strategy.date_to > moment.utc() || strategy.date_to === null
      ? moment.utc()
      : strategy.date_to;

  return { startDate: dateFrom, endDate: dateTo };
};

export const getGenericKPIParams = ({ actions, state }: Context) => {
  const { strategies, dateFilterRange } = state.ecommCampaignPerformance;
  const { date_from, date_to } = dateFilterRange;
  return {
    fields:
      'total_spend,total_spend_change,flat_fees,flat_fee_budget,flat_fee_commission,attributed_revenue,attributed_revenue_change,attributed_orders,flat_fee_content_goal_count,flat_fee_content_approved_count,flat_fee_gmv_goal,rate_tier_id',
    group_by: 'merch_id,rate_tier_id',
    flat_fee_strategy_id: strategies,
    strategy_id: strategies,
    date_from: actions.date
      .getFirstOfMonth(moment(date_from))
      .format('YYYY-MM-DD'),
    date_to: actions.date
      .offsetFromDate({
        originalDate: date_to,
        number: 1,
        unit: 'day',
      })
      .format('YYYY-MM-DD'),
    compare_from: actions.date
      .offsetFromDate({
        originalDate: date_from,
        number: -1,
        unit: 'month',
      })
      .format('YYYY-MM-DD'),
    compare_to: actions.date
      .offsetFromDate({
        originalDate: date_to,
        number: -1,
        unit: 'month',
      })
      .format('YYYY-MM-DD'),
  };
};

// TODO: Can we simplify this with the other table params?
export const getGenericTopFiveParams = (
  { actions, state }: Context,
  { tableType, columns }: { tableType: 'stories' | 'partners'; columns: any },
) => {
  const { dateFilterRange, io, strategies, isFlatFeeStrategy } =
    state.ecommCampaignPerformance;

  const groupFields =
    tableType === 'stories' ? 'pub_id,edit_id,merch_id' : 'pub_id,merch_id';
  const fields = columns.map((col: any) => col.dataKey);
  if (tableType === 'stories') {
    fields.push('edit_url');
  } else {
    fields.push('flat_fee_content_goal_count');
    fields.push('pub_id');
  }
  const params: {
    fields: string;
    group_by: string;
    order_by: string;
    page: number;
    per_page: number;
    date_from: string;
    date_to: string;
    strategy_group_id?: string;
    strategy_id?: string;
    flat_fee_strategy_id?: string;
  } = {
    fields: fields.join(','),
    group_by: groupFields,
    order_by: '-attributed_revenue',
    page: 1,
    per_page: 5,
    date_from: actions.date
      .getFirstOfMonth(moment(dateFilterRange?.date_from))
      .format('YYYY-MM-DD'),
    date_to: actions.date
      .offsetFromDate({
        originalDate: dateFilterRange?.date_to,
        number: 1,
        unit: 'day',
      })
      .format('YYYY-MM-DD'),
  };

  if (isFlatFeeStrategy) {
    params.fields = `${params.fields},rate_tier_id`;
    params.group_by = `${params.group_by},rate_tier_id`;
  }

  if (io) {
    params.strategy_group_id = io;
  } else if (strategies) {
    if (isFlatFeeStrategy) {
      params.flat_fee_strategy_id = strategies;
      params.strategy_id = strategies;
    } else {
      params.strategy_id = strategies;
    }
  }

  return params;
};

export const processKpiData = (
  { state }: Context,
  {
    kpiData,
    listData,
    rtData,
  }: {
    kpiData: KpiData[];
    listData: {
      target_list_id: number;
      target_list_size: number;
      rate_tier_id: number;
    }[];
    rtData: any;
  },
) => {
  // Default stat state
  const defaultKpi: KpiData = {
    total_spend: 0.0,
    total_spend_change: 0,
    flat_fee_budget: 0.0,
    flat_fees: 0.0,
    flat_fee_commission: 0.0,
    attributed_revenue: 0.0,
    attributed_revenue_change: 0,
    flat_fee_content_goal_count: 0,
    flat_fee_content_approved_count: 0,
    flat_fee_gmv_goal: 0.0,
    attributed_orders: 0,
    partners_count: 0,
  };

  // Get total # of partners targeted by the strategy
  const totalPartners: number = listData.reduce(
    (acc: number, curr: any) => acc + curr.target_list_size,
    0,
  );

  // Consolidate FF GMV Goals from RT response data
  const gmvGoals: { [rate_tier_id: string]: string } = {};
  rtData
    .filter((rt: any) => !rt.rate_tier_is_default)
    .forEach((rt: any) => {
      gmvGoals[rt.rate_tier_id] = rt.flat_fee_gmv_goal;
    });

  // Calculate GMV Goal by looping through RTs & multiplying # of partners targeted * GMV goal of the RT
  defaultKpi.flat_fee_gmv_goal = Object.keys(gmvGoals).reduce(
    (acc: number, curr: string) => {
      if (!gmvGoals[curr]) return acc;
      return (acc +=
        parseFloat(gmvGoals[curr]) *
        (listData.find((list: any) => {
          return list.rate_tier_id === parseFloat(curr);
        })?.target_list_size || 0));
    },
    0,
  );

  // Manually sum values that don't require custom logic like GMV Goal
  kpiData.forEach((rt: any) => {
    Object.keys(rt).forEach((key: any) => {
      if (['flat_fee_gmv_goal'].includes(key)) {
        return;
      } else if (['flat_fee_budget', 'total_spend'].includes(key)) {
        // Budget/Spend shouldn't be summed, they're strategy-level fields
        defaultKpi[key as keyof KpiData] = parseFloat(rt[key as keyof KpiData]);
      } else {
        defaultKpi[key as keyof KpiData] += parseFloat(
          rt[key as keyof KpiData],
        );
      }
    });
  });

  state.ecommCampaignPerformance.kpiData = {
    ...defaultKpi,
    partners_count: totalPartners,
  };
};

export const processTopFiveData = (
  { state }: Context,
  {
    topFiveData,
    rateTierData,
    tableType,
  }: { topFiveData: any; rateTierData: any; tableType: 'stories' | 'partners' },
) => {
  // We only have RT data when querying for FF strategies, not normal strategies or IOs
  if (rateTierData !== undefined) {
    const rateTiers = rateTierData.data.data[0].rate_tiers;
    state.ecommCampaignPerformance.topFiveData[tableType] =
      topFiveData.data.data[0].stats.map((row: any) => {
        const matchedRt = rateTiers.find((rt: any) => {
          return rt.rate_tier_id === row.rate_tier_id;
        });
        row.flat_fee_gmv_goal = matchedRt?.flat_fee_gmv_goal;
        return row;
      });
  } else {
    state.ecommCampaignPerformance.topFiveData[tableType] =
      topFiveData.data.data[0].stats;
  }
};
//</editor-fold>
