import { Context } from '..';
import { Product } from './state';

export const fetchNewsFeedCampaigns = async ({
  effects,
  state,
  actions,
}: Context) => {
  state.ecommNewsFeed.topCampaigns = null;

  const dates = actions.date.getPresetDateRanges();

  return await effects.api
    .get(`/api/v0/merchants/${state.organization.selectedOrgId}/performance/`, {
      date_from: dates.sevenDay.startDate.format('YYYY-MM-DD'),
      date_to: actions.date.getCurrentDateTime().format('YYYY-MM-DD'),
      page: 1,
      per_page: 2,
      fields:
        'strategy_id,strategy_name,attributed_revenue,clicks,order_conversion_rate,strategy_thumbnail_image_url',
      group_by: 'strategy_id',
      order_by: '-attributed_revenue',
    })
    .then((response: any) => {
      state.ecommNewsFeed.topCampaigns = response.data.data[0].stats;
    });
};

export const fetchNewsFeedPartners = async ({
  effects,
  state,
  actions,
}: Context) => {
  state.ecommNewsFeed.topPartners = null;

  const dates = actions.date.getPresetDateRanges();

  return await effects.api
    .get(`/api/v0/merchants/${state.organization.selectedOrgId}/performance/`, {
      date_from: dates.sevenDay.startDate.format('YYYY-MM-DD'),
      date_to: actions.date.getCurrentDateTime().format('YYYY-MM-DD'),
      page: 1,
      per_page: 2,
      fields:
        'pub_name,pub_id,attributed_revenue,clicks,order_conversion_rate,pub_logo_url',
      group_by: 'pub_id',
      order_by: '-attributed_revenue',
    })
    .then((response: any) => {
      state.ecommNewsFeed.topPartners = response.data.data[0].stats;
    });
};

export const fetchNewsFeedProducts = async ({
  effects,
  state,
  actions,
}: Context) => {
  state.ecommNewsFeed.topProducts = null;

  const dates = actions.date.getPresetDateRanges();

  return await effects.api
    .get(
      `/api/v0/merchants/${state.organization.selectedOrgId}/strategies/products_performance/`,
      {
        date_from: dates.sevenDay.startDate.format('YYYY-MM-DD'),
        date_to: actions.date.getCurrentDateTime().format('YYYY-MM-DD'),
        order_by: 'attributed_revenue',
        direction: 'desc',
        page: 1,
        per_page: 2,
      },
    )
    .then(async (response: any) => {
      const topProducts = response.data.data[0].stats;

      // get product images
      await Promise.all(
        topProducts.map(async (product: Product, index: number) => {
          if (product.merchant_product_id) {
            const productImage = await effects.api
              .get(
                `/api/v0/merchant_products/${product.merchant_product_id}/brand/${state.organization.selectedOrgId}/`,
              )
              .then((response: any) => {
                return response.data.data[0].item_data.image_url;
              });
            if (productImage) {
              // inject product image url into results
              topProducts[index] = {
                ...topProducts[index],
                product_image_url: productImage,
              };
            }
          }
        }),
      );
      state.ecommNewsFeed.topProducts = topProducts;
    });
};

export const fetchNewsFeedInterests = async ({ effects, state }: Context) => {
  state.ecommNewsFeed.creatorInterests = null;

  return await effects.api
    .get(
      `/api/v0/merchants/${state.organization.selectedOrgId}/creator_interest/`,
    )
    .then((resp: any) => {
      state.ecommNewsFeed.creatorInterests = resp.data.data[0].feed_items;
    });
};
