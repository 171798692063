import * as React from 'react';
import { Tooltip as MantineTooltip } from '@mantine/core';
import { MantineColor, MantineNumberSize } from '@mantine/styles';

export interface TooltipProps<T extends string = never> {
  /** Additional classes that append to the button container. */
  className?: string;

  /** Additional classes that append to specific button parent/child components. */
  classNames?: Partial<Record<T, string>>;

  /** E2e targeting tag. */
  dataCy?: string;

  /** Tooltip content */
  label: React.ReactNode;

  /** Tooltip opened state for controlled variant */
  opened?: boolean;

  /** Any color from theme.colors, defaults to gray in light color scheme and dark in dark colors scheme */
  color?: MantineColor;

  /** Radius from theme.radius, or number to set border-radius in px */
  radius?: MantineNumberSize;

  /** True to disable tooltip */
  disabled?: boolean;

  /** True to show arrow on tooltip */
  withArrow?: boolean;

  /** Arrow size in px */
  arrowSize?: number;

  /** Tooltip width in px or auto */
  width?: number | 'auto';

  /** Allow multiline tooltip content */
  wrapLines?: boolean;

  /** Allow pointer events on tooltip, warning: this may break some animations */
  allowPointerEvents?: boolean;

  /** Get tooltip ref */
  tooltipRef?: React.ForwardedRef<HTMLDivElement>;

  /** Tooltip id to bind aria-describedby */
  tooltipId?: string;

  /** useEffect dependencies to force update tooltip position */
  positionDependencies?: any[];

  /** Denotes tooltip positioning */
  position?: 'top' | 'left' | 'bottom' | 'right' | undefined;

  /** Whether to render the target element in a Portal */
  withinPortal?: boolean;

  /** Any react node that should trigger tooltip */
  children: React.ReactNode;
}

export function Tooltip({
  dataCy = 'tooltip',
  className,
  classNames,
  label,
  opened,
  color,
  radius,
  disabled,
  withArrow,
  arrowSize,
  width,
  wrapLines,
  allowPointerEvents,
  tooltipRef,
  tooltipId,
  position,
  positionDependencies,
  withinPortal,
  children,
}: TooltipProps) {
  return (
    <MantineTooltip
      data-cy={dataCy}
      label={label}
      opened={opened}
      color={color}
      radius={radius as any}
      disabled={disabled}
      withArrow={withArrow}
      arrowSize={arrowSize}
      width={width}
      wrapLines={wrapLines}
      allowPointerEvents={allowPointerEvents}
      tooltipRef={tooltipRef}
      tooltipId={tooltipId}
      position={position}
      positionDependencies={positionDependencies}
      withinPortal={withinPortal}
      className={className}
      classNames={{
        root: '',
        arrow: '',
        body: 'cy-tooltip-body', // for targeting in Cypress
        ...classNames,
      }}
      transition="fade"
      transitionDuration={200}
    >
      {children}
    </MantineTooltip>
  );
}

Tooltip.filePath = 'libs/shared/ui-kit/src/lib/tooltip/tooltip.tsx';
Tooltip.displayName = 'Tooltip';

export default Tooltip;
