import React, { useEffect, useState } from 'react';
import {
  useActions,
  useAppState,
  useEffects,
} from '@frontend/howl-web-app/overmind';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { getSession } from 'next-auth/react';
import { useFullStoryAnalytics } from '@frontend/shared-utils';

export function routeSelectedOrg<T>(
  WrappedComponent: React.FunctionComponent<T>,
): React.FunctionComponent<T> {
  const WithRouteSelectedOrg = (props: T) => {
    const { setSelectedOrg, setOrgs } = useActions().organization;
    const { sessionData } = useAppState().session;
    const [hasPermission, setPermission] = useState(false);
    const fullstory = useFullStoryAnalytics();
    const router = useRouter();

    const { get } = useEffects().api;

    const isAdminPath = router.pathname.includes('/admin');

    const {
      data: publisherResult,
      isLoading: isPublisherLoading,
      fetchStatus: fetchPublisherStatus,
    } = useQuery({
      queryKey: ['publishers', router.query['publisherId']],
      queryFn: async () => {
        const response = await get('/api/v0/publishers/', {
          publishers: router.query['publisherId'],
          with_categories: true,
        });
        return response.data.data[0];
      },
      staleTime: Infinity,
      enabled: !!router.query['publisherId'],
      onError: (err) => {
        console.log('err', err);
        fullstory.event('Error Loading Publisher', {
          error: err,
          publisherId: router.query['publisherId'],
        });
      },
    });

    useEffect(() => {
      const routeToOrg = async () => {
        const session = await getSession();
        setPermission(false);
        // Route to first publisher if not in URL.
        if (
          router.asPath.includes('/publisher') &&
          !router.asPath.includes('/merchant') &&
          !router.asPath.includes('/admin') &&
          !router.query.publisherId
        ) {
          const publisherPermissions =
            session?.user?.bamx_perms?.publishers &&
            Object.keys(session?.user?.bamx_perms?.publishers);
          const lastUsedOrg =
            sessionData.isAdmin && sessionData.orgId
              ? sessionData.orgId
              : publisherPermissions[0];
          const path = router.asPath.split('/publisher');
          router.push(`/publisher/${lastUsedOrg}${path[1]}`);
        }

        if (session?.user.bamx_perms.admin) {
          return setPermission(true);
        }

        if (router.query.publisherId && session?.user.bamx_perms) {
          if (!session?.user?.bamx_perms?.publishers) {
            router.replace('/');
          } else {
            const publisherPermissions = Object.keys(
              session.user.bamx_perms.publishers,
            );

            if (
              publisherPermissions.includes(router.query.publisherId as string)
            ) {
              setPermission(true);
            } else {
              const hash = window.location.hash;
              router.replace({
                pathname: `${router.pathname}${hash}`,
                query: {
                  ...router.query,
                  publisherId: publisherPermissions[0],
                },
              });
            }
          }
        }

        if (router.query.merchantId && session?.user.bamx_perms) {
          if (!session?.user.bamx_perms.merchants) {
            router.replace('/');
          } else {
            const merchantPermissions = Object.keys(
              session.user.bamx_perms.merchants,
            );

            if (
              merchantPermissions.includes(router.query.merchantId as string)
            ) {
              setPermission(true);
            }
          }
        }
      };

      routeToOrg();
    }, [router.query]);

    useEffect(() => {
      if (isAdminPath) {
        setSelectedOrg({ selectedOrg: { pub_id: 0, short_name: 'admin' } });
      }
    }, [isAdminPath]);

    useEffect(() => {
      if (publisherResult) {
        setSelectedOrg({ selectedOrg: publisherResult });
      }
    }, [publisherResult]);

    const {
      data: merchantResult,
      isLoading: isMerchantLoading,
      fetchStatus: fetchMerchantStatus,
    } = useQuery({
      queryKey: ['merchants', router.query['merchantId']],
      queryFn: async () => {
        const routerId = router.query['merchantId'] as string;
        const response = await get('/api/v0/merchants/', {
          merchants:
            sessionData.accessList
              ?.filter((item) => item.type === 'merchants')
              .map((item) => item.orgId)
              .join(',') || routerId,
        });

        const orgs = response.data.data;
        setOrgs(orgs);
        return (
          orgs.find((org: any) => org.merch_id === parseInt(routerId)) ||
          orgs[0]
        );
      },
      staleTime: Infinity,
      enabled: !!router.query['merchantId'],
    });

    useEffect(() => {
      if (merchantResult) {
        setSelectedOrg({ selectedOrg: merchantResult });
      }
    }, [merchantResult]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const loading =
      (isMerchantLoading && fetchMerchantStatus !== 'idle') ||
      (isPublisherLoading && fetchPublisherStatus !== 'idle');

    if (hasPermission) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <WrappedComponent {...props} isLoading={loading} />;
    } else {
      return <div></div>;
    }
  };

  WithRouteSelectedOrg.displayName = `routeSelectedOrg()`;

  return WithRouteSelectedOrg;
}
