import { Context } from '..';

const setLoading = (context: Context, isLoading: boolean) => {
  context.state.smartLink.loading = isLoading;
};

export const clearData = (context: Context) => {
  context.state.smartLink.summary = null;
  context.state.smartLink.enabledMatches = [];
  context.state.smartLink.suggestedMatches = [];
  context.state.smartLink.historicalMatches = [];
  context.state.smartLink.groupedMerchantProducts = [];
  context.state.smartLink.smartlinkId = '';
};

export const resolveSmartlinkId = async (context: Context, linkId: string) => {
  /* linkId can be either auction_id or bam_link_id.
   * The >> bitwise operator shifts ID in binary representation
   * 32 bits to the right, discarding bits shifted off.
   * A bam_link_id should remain the same once shifted,
   * while auction_id changes.
   */
  const linkIdNum = Number(linkId);
  if (linkIdNum >> 0 != linkIdNum || isNaN(linkIdNum)) {
    try {
      const endpoint = `/api/v0/product_match/bam_links/?auction_id=${linkId}`;
      const resp = await context.effects.api.get(endpoint);
      context.state.smartLink.smartlinkId = resp.data.data[0].bam_link_id;
    } catch (err: any) {
      context.state.smartLink.error = err.message;
    }
  } else {
    context.state.smartLink.smartlinkId = linkId;
  }
};

export const querySummary = async (
  context: Context,
  smartlinkId: number | string,
) => {
  const endpoint = `api/v1/product_match/bam_links/${smartlinkId}/summary/`;
  try {
    setLoading(context, true);
    context.state.smartLink.error = null;
    const resp = await context.effects.api.get(endpoint);
    context.state.smartLink.summary = resp.data.data[0];
  } catch (err: any) {
    context.state.smartLink.error = err.message;
  }
  setLoading(context, false);
};

export const queryMatches = async (
  context: Context,
  smartlinkId: number | string,
) => {
  const enabledMatchesEndpoint = `api/v1/product_match/bam_links/${smartlinkId}/matches/enabled/`;
  const suggestedMatchesEndpoint = `api/v1/product_match/bam_links/${smartlinkId}/matches/suggested/`;
  const historicalMatchesEndpoint = `api/v1/product_match/bam_links/${smartlinkId}/matches/historical/`;
  const matchEndpoints = [
    enabledMatchesEndpoint,
    suggestedMatchesEndpoint,
    historicalMatchesEndpoint,
  ] as any[];
  try {
    const [enabledMatches, suggestedMatches, historicalMatches] =
      await matchEndpoints.reduce(async (acc, endpoint) => {
        const matches = await acc;
        const resp = await context.effects.api.get(endpoint);
        matches.push(resp.data.data);
        return matches;
      }, []);

    context.state.smartLink.enabledMatches = enabledMatches;
    context.state.smartLink.suggestedMatches = suggestedMatches;
    context.state.smartLink.historicalMatches = historicalMatches;
    context.state.smartLink.isMatchesLoaded = true;
  } catch (err: any) {
    context.state.smartLink.error = err.message;
    context.state.smartLink.isMatchesLoaded = false;
  }
  setLoading(context, false);
};

export const fetchMerchants = async (context: Context) => {
  if (!context.state.smartLink.merchants.length) {
    const url = '/api/v0/merchants/';
    try {
      const resp = await context.effects.api.get(url);
      context.state.smartLink.merchants = resp.data.data;
    } catch (e: any) {
      context.state.smartLink.error = e?.message;
    }
  }
};

type queryMerchantProductProps = {
  productId?: string;
  merchantId?: number;
  queryString?: string;
  merchantIds?: string;
};

export const queryGroupedMerchantProducts = async (
  context: Context,
  {
    productId,
    merchantId,
    queryString,
    merchantIds,
  }: queryMerchantProductProps,
) => {
  let productSearchEndpoint = '';
  if (productId) {
    productSearchEndpoint = `api/v0/product/search/grouped_by_merchant/${productId}/`;

    if (merchantId) {
      productSearchEndpoint = `${productSearchEndpoint}?merch_id=${merchantId}`;
    }
  } else if (queryString) {
    productSearchEndpoint = `api/v0/product/search/grouped_by_merchant/?query_string=${encodeURIComponent(
      queryString,
    )}`;

    if (merchantIds) {
      productSearchEndpoint = `${productSearchEndpoint}&feed_merchant_ids=${merchantIds}`;
    }
  }

  try {
    setLoading(context, true);
    context.state.smartLink.error = null;
    const resp = await context.effects.api.get(productSearchEndpoint);
    const { buckets } = resp.data.aggregations.merchants;
    const groupedMerchantProducts = buckets.map((bucket: any) => {
      const products = bucket.top_product_hits.hits.hits
        .map(({ _source }: any) => _source)
        .filter(({ merchant_product_id }: any) => !!merchant_product_id);
      const merchantInfoProduct = products.find(
        (product: any) =>
          !!product.merchant?.full_name && !!product.merchant?.pricing_model,
      );

      const merchantProductGrouping = {
        merchantId: bucket.key,
        fullName: merchantInfoProduct?.merchant?.full_name,
        pricingModel: merchantInfoProduct?.merchant?.pricing_model,
        products,
      };

      return merchantProductGrouping;
    });
    context.state.smartLink.groupedMerchantProducts = groupedMerchantProducts;
  } catch (err: any) {
    context.state.smartLink.error = err.message;
  }
  setLoading(context, false);
};

type Product = {
  feed_merchant_id: number;
  merchant_product_id: string;
};

export const updateGroupedMerchantProduct = (
  context: Context,
  product: Product,
) => {
  const groupedMerchantProducts =
    context.state.smartLink.groupedMerchantProducts;
  if (groupedMerchantProducts.length) {
    const merchantGroupingIndex = groupedMerchantProducts.findIndex(
      (grouping) => grouping.merchantId === product.feed_merchant_id,
    );

    if (merchantGroupingIndex !== -1) {
      const productIndex = groupedMerchantProducts[
        merchantGroupingIndex
      ].products.findIndex(
        (groupProduct: any) =>
          groupProduct.merchant_product_id === product.merchant_product_id,
      );

      if (productIndex !== -1) {
        context.state.smartLink.groupedMerchantProducts = [
          ...groupedMerchantProducts.slice(0, merchantGroupingIndex),
          {
            ...groupedMerchantProducts[merchantGroupingIndex],
            products: [
              ...groupedMerchantProducts[merchantGroupingIndex].slice(
                0,
                productIndex,
              ),
              product,
              ...groupedMerchantProducts[merchantGroupingIndex].slice(
                productIndex + 1,
              ),
            ],
          },
          ...groupedMerchantProducts.slice(merchantGroupingIndex + 1),
        ];
      }
    }
  }
};

export const setEnabledMatches = (context: Context, enabledMatches: any[]) => {
  context.state.smartLink.enabledMatches = enabledMatches;
};

export const updateEnabledMatch = (context: Context, product: Product) => {
  const matches = context.state.smartLink.enabledMatches;
  const productIndex = matches.findIndex(
    (match) => match.merchant_product_id === product.merchant_product_id,
  );

  if (productIndex !== -1) {
    context.state.smartLink.enabledMatches = [
      ...matches.slice(0, productIndex),
      product,
      ...matches.slice(productIndex + 1),
    ];
  }
};

export const querySmartlinkManagement = (
  context: Context,
  smartlinkId: number,
) => {
  const endpoint = `/api/v1/product_match/bam_links/${smartlinkId}/management/`;
  return context.effects.api.get(endpoint).then((resp: any) => {
    return Promise.resolve(resp.data.data[0]);
  });
};

export const queryAuctionPreview = (context: Context, auctionId: number) => {
  const endpoint = `/api/v1/auction_preview/`;
  const params = { a: auctionId };
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return Promise.resolve(resp.data.data[0].auction);
  });
};

export const updateSmartlinkManagement = (
  context: Context,
  { smartlinkId, data }: { smartlinkId: number; data: any },
) => {
  const endpoint = `/api/v1/product_match/bam_links/${smartlinkId}/management/`;
  return context.effects.api.put(endpoint, data);
  // TODO: add error modal () => onError(),
};

export const getDefaultStockFields = (
  context: Context,
  { isInStock, partialFields }: { isInStock: boolean; partialFields?: any },
) => {
  const fields = partialFields || context.state.smartLink.stockStatusFields;

  return fields.map((field: any) => {
    field.selected = field.value === isInStock;
    return field;
  });
};

export const getDefaultAuctionFields = (
  context: Context,
  participateInAuction: boolean,
) => {
  return context.state.smartLink.auctionStatusFields.map((field) => {
    field.selected = field.value === participateInAuction;
    return field;
  });
};

export const getDate = (
  context: Context,
  {
    currentDate,
    offset,
    unit,
  }: { currentDate: any; offset: any; unit: string },
) => {
  const dateObj = context.actions.date.offsetFromDate({
    originalDate: currentDate,
    number: offset,
    unit,
  });

  return context.actions.date.formatDate({ dateObj, fmt: 'MM/DD/YY' });
};

export const getLabel = (
  context: Context,
  { items, key = 'label' }: { items: any; key?: string },
) => {
  return items.find((item: any) => item.selected)[key];
};

export const lockBamLink = async (
  context: Context,
  { smartlinkId }: { smartlinkId: string },
) => {
  try {
    const endpoint = `/api/v1/product_match/bam_links/${smartlinkId}/match_lock/`;
    const resp = await context.effects.api.put(endpoint);
    const matchLockExpiration = resp.data.data[0].match_lock_expiration;
    const now = context.actions.date.getDateTime();
    const lockTime = Math.round(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Date.parse(matchLockExpiration) - now,
    );
    context.state.smartLink.matchLockExpiration = matchLockExpiration;
    context.state.smartLink.matchLockExpirationInSeconds = lockTime;
    context.state.smartLink.isBamLinkLocked = true;
  } catch (err: any) {
    context.state.smartLink.error = err.code;
    context.state.smartLink.isBamLinkLocked = false;
    context.state.smartLink.isCompleteButtonDisabled = true;
  }
};

export const unlockBamLink = async (
  context: Context,
  { smartlinkId }: { smartlinkId: string },
) => {
  const endpoint = `/api/v1/product_match/bam_links/${smartlinkId}/match_lock/`;
  await context.effects.api.delete(endpoint);
  context.state.smartLink.isBamLinkLocked = false;
};

export const setIgnoreExclusiveMatchRequest = (
  context: Context,
  ignoreExclusiveMatchRequest: boolean,
) => {
  context.state.smartLink.ignoreExclusiveMatchRequest =
    ignoreExclusiveMatchRequest;
};

export const updateBamLink = async (
  context: Context,
  {
    smartlinkId,
    merchantProductIds,
  }: { smartlinkId: string; merchantProductIds?: string[] },
) => {
  const endpoint = `/api/v1/product_match/bam_links/${smartlinkId}/matches/`;
  const data = {
    merchant_product_ids: merchantProductIds,
    ignore_exclusive_match_request:
      context.state.smartLink.ignoreExclusiveMatchRequest,
  };
  await context.effects.api.post(endpoint, data);
  context.state.smartLink.isBamLinkLocked = false;
};
