type State = {
  limit: number;
  paginationOptions: any;
  page: any;
  indexStart: any;
  indexEnd: any;
  merchants: any;
  trendsByMerch: any;
  merchSlugIds: any;
};

export const state: State = {
  limit: 500,
  paginationOptions: [500, 100, 50],
  page: null, // Current page according to backend (perPage 500)
  indexStart: null,
  indexEnd: null,
  merchants: [],
  trendsByMerch: {},
  merchSlugIds: [
    { slug: 'bedbathandbeyond', merch_id: 1100 },
    { slug: 'buybuybaby', merch_id: 1179 },
    { slug: 'dermstore', merch_id: 1005 },
    { slug: 'dvf', merch_id: 1138 },
    { slug: 'forward', merch_id: 1043 },
    { slug: 'kosas', merch_id: 1218 },
    { slug: 'lovelyskin', merch_id: 1215 },
    { slug: 'macys', merch_id: 1023 },
    { slug: 'revolve', merch_id: 1042 },
    { slug: 'saks', merch_id: 1002 },
    { slug: 'ultabeauty', merch_id: 1158 },
    { slug: 'versedskin', merch_id: 1229 },
    { slug: 'violetgrey', merch_id: 1004 },
    { slug: 'yoox', merch_id: 1008 },
  ],
};
