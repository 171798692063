import { cn } from '@frontend/shared-utils';
import React from 'react';

interface LoadingIconProp {
  /** Set Fill color, add width and height any other classes */
  className?: string;
  dark?: boolean;
}

export function LoadingIcon({ className, dark = false }: LoadingIconProp) {
  const fill = dark ? '#000' : '#fff';
  const classNameDefault = 'w-4 h-4 animate-spin';
  return (
    <div className={cn(classNameDefault, className)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path
          fill="#000"
          fillOpacity={0.15}
          fillRule="evenodd"
          d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm12 9a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
          clipRule="evenodd"
        />
        <mask
          id="a"
          viewBox="0 0 24 24"
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'alpha',
          }}
        >
          <path
            fill="#782CE8"
            fillRule="evenodd"
            d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm12 9a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
            clipRule="evenodd"
          />
        </mask>
        <g mask="url(#a)">
          <path fill={fill} d="M-1.5-1.5 12 12 25.5-1.5 12-15-1.5-1.5Z" />
        </g>
      </svg>
    </div>
  );
}
