import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NextRouter, useRouter } from 'next/router';
import { debounce } from 'lodash';
import { setPublisherList, setPublisherMap } from '../../redux/actions';
import { RootState } from '../../redux/store';
import { useEffects } from '@frontend/howl-web-app/overmind';

const noOp = () => {};

const useOutsideClick = (node: any, cb: any, onMount = noOp, deps = []) => {
  const onWindowClick = (e: any) => {
    if (!node.current) return;

    if (!node.current.contains(e.target)) {
      cb(false);
    }
  };

  const scheduleUseOutsideClick = useCallback(
    debounce(async () => {
      window.addEventListener('click', onWindowClick);
    }, 200),
    [],
  );

  useEffect(() => {
    onMount();

    scheduleUseOutsideClick();

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, [...deps]);
};

const useFlagsToRedirect = (flagName: any, flags: any) => {
  const router = useRouter();
  const ldClient = useLDClient();

  useEffect(() => {
    const context = ldClient?.getContext();

    if (context?.key !== 'default' && flagName in flags && !flags[flagName]) {
      router.push('/');
    }
  }, [flags, ldClient]);
};

const mapPublishers = (pubs: any) => {
  return pubs.map((pub: any) => {
    return {
      label: pub.full_name || pub.pub_name,
      name: pub.pub_id.toString(),
      selected: true,
    };
  });
};

const mapPublishersV2 = (pubs: any) => {
  return pubs.map((pub: any) => ({
    label: pub.full_name || pub.pub_name,
    value: pub.pub_id.toString(),
  }));
};

const getPublishersMap = (pubs: any) => {
  const pubMap: any = {};
  pubs.forEach((pub: any) => {
    pubMap[pub.pub_id.toString()] = pub.full_name || pub.pub_name;
  });

  return pubMap;
};

function usePublisherList(
  orgId: any,
  dropdown = false,
  v2 = false,
  getFullPublisherList = false,
) {
  const api = useEffects().api;

  const publishers =
    useSelector((state: RootState) => state.publisherList) || [];
  const publishersMap =
    useSelector((state: RootState) => state.publisherMap) || {};
  const [pubOptions, setPubOptions] = useState(
    publishers.length
      ? v2
        ? mapPublishersV2(publishers)
        : mapPublishers(publishers)
      : [],
  );
  const [pubMap, setPubMap] = useState(publishersMap);
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const fetchPubList = async () => {
      if (router.isReady) {
        const pubListEndpoint = `/api/v0/merchants/${router.query.merchantId}/strategy/type_ahead/`; // this endpoint retrieves the in-network publisher list for the merchant
        const fullPubListEndpoint = `/api/v0/publishers/publisher_info/`; // this endpoint retrieves the full publisher list

        const response = getFullPublisherList
          ? await api.get(fullPubListEndpoint)
          : await api.get(pubListEndpoint, { type: 'publisher' });
        const data = getFullPublisherList
          ? response.data.data[0].publishers
          : response.data.data[0].stats;

        const pubList = data.sort((pub1: any, pub2: any) => {
          const pub1_name = getFullPublisherList
            ? pub1.pub_name
            : pub1.full_name;
          const pub2_name = getFullPublisherList
            ? pub2.pub_name
            : pub2.full_name;

          return pub1_name.toLowerCase() > pub2_name.toLowerCase() ? 1 : -1;
        });

        const pubMap = getPublishersMap(pubList);
        dispatch(setPublisherList(pubList));
        dispatch(setPublisherMap(pubMap));

        setPubOptions(v2 ? mapPublishersV2(pubList) : mapPublishers(pubList));
        setPubMap(pubMap);
      }
    };

    if (!publishers.length) {
      fetchPubList();
    }
  }, [router.isReady]);

  if (dropdown) {
    return [pubOptions, setPubOptions, pubMap];
  }
  return publishers;
}

const useForm = (
  onSubmit: (values?: any) => void = () => {},
  validators = {},
) => {
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = (e: any) => {
    if (e) e.preventDefault();

    const fieldErrors: any = {};
    let isInvalid = false;

    Object.entries(validators).forEach(([field, fieldValidators]) => {
      const fieldValue = values[field];
      (fieldValidators as any).forEach(({ validatorFn, errorMessage }: any) => {
        if (!validatorFn(fieldValue, values)) {
          isInvalid = true;
          if (!fieldErrors[field]) {
            fieldErrors[field] = [errorMessage];
          } else {
            fieldErrors[field].push(errorMessage);
          }
        }
      });
    });

    if (isInvalid) {
      setErrors(fieldErrors);
    } else {
      setErrors({});
      setIsDirty(false);
      onSubmit(values);
    }
  };

  const handleChange = (e: any) => {
    const events = Array.isArray(e) ? e : [e];
    const newValues: any = {};

    events.forEach((obj) => {
      const eventObject = Object.prototype.hasOwnProperty.call(obj, 'target')
        ? { name: obj.target.name, value: obj.target.value }
        : obj;

      newValues[eventObject.name] = eventObject.value;
    });

    if (typeof e.persist !== 'undefined') e.persist();
    setIsDirty(true);
    setValues((val: any) => ({ ...val, ...newValues }));

    //  TODO: Will need to be deprecated, incompatible with UI-Kit Inputs
    return { ...values, ...newValues };
  };

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
    isDirty,
    setIsDirty,
  };
};

const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useEffectDebugger = (
  effectHook: any,
  dependencies: any,
  dependencyNames = [],
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce(
    (accum: any, dependency: any, index: any) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        };
      }

      return accum;
    },
    {},
  );

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};

const useFileImport = (onChange: any, value: any) => {
  const [importError, setImportError] = useState('');
  const [fileDatetimeImported, setFileDatetimeImported] = useState(Date.now());

  const handleImport = (e: any) => {
    e.preventDefault();

    // Reset the datetimeImported value to allow re-upload of files
    setFileDatetimeImported(Date.now());

    const [file] = e.target.files;

    if (file === null || typeof file === 'undefined') {
      setImportError(
        'No file detected, please check that you uploaded the correct file.',
      );
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const text: string | ArrayBuffer = e.target.result;

      if (text === '') {
        setImportError(
          'No content detected, please check the contents of your file.',
        );
        return;
      }

      // Split the rows by newline (default separator in export)
      const rows = typeof text === 'string' ? text.split('\r\n') : [];

      if (rows.length === 0) {
        setImportError(
          'No values detected, please check the format of your file.',
        );
        return;
      }

      onChange([...value, ...rows]);
      setImportError('');
    };

    if (!(file instanceof Blob)) {
      setImportError('Unknown error occurred, please try again.');
      return;
    }
    reader.readAsText(file);
  };

  return { importError, handleImport, fileDatetimeImported };
};

const useRouteChange = (
  router: NextRouter,
  onRouteChange: () => void,
  dependencies: boolean[],
) => {
  const currentNav = useRef(null);
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      currentNav.current = url;
      if (dependencies.every((d) => d === true)) {
        router.events.emit('routeChangeError');
        onRouteChange();
        throw 'Aborted';
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [...dependencies]);

  return () => {
    return router.push(currentNav.current as any);
  };
};

export {
  useFlagsToRedirect,
  useOutsideClick,
  usePublisherList,
  useForm,
  useEffectDebugger,
  useFileImport,
  useRouteChange,
};
