type State = {
  oldLinks: string | string[];
  globalLinkCreationModalOpen: boolean;
  globalLinkCreationModalBypassLinkCreation: boolean;
  previewLinkProduct: {
    value: ProductLinkData;
    options?: {
      link_source?: string;
    };
  } | null;
  previewLinkUrl: {
    value: string;
    options?: { link_source?: string };
  } | null;
};

export type ProductLinkData = {
  imageUrl: string;
  name: string;
  merchant?: string;
  productUrl: string;
  productPrice: string;
};

export const state: State = {
  oldLinks: '',
  globalLinkCreationModalOpen: false,
  globalLinkCreationModalBypassLinkCreation: false,
  previewLinkProduct: null,
  previewLinkUrl: null,
};
