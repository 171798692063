export const getRootDomain = () => {
  if (typeof window === 'undefined') return undefined;

  const bits = window.location.hostname.split('.');

  return bits
    .slice(0)
    .slice(-(bits.length === 4 ? 3 : 2))
    .join('.');
};
