import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDecision } from '@frontend/shared-feature-flags';
import classNames from 'classnames';

export const FlagListItem = ({
  name,
  flagKey,
  isOverridden = false,
}: {
  name?: string;
  flagKey: string;
  isOverridden?: boolean;
}) => {
  const { decision } = useDecision(flagKey);

  const queryClient = useQueryClient();

  const updateOverride = useMutation({
    mutationFn: (newFlagOverride: { flagKey: string; variation: string }) => {
      const storageKey = 'feature-flag-overrides';

      // get the object of current flag overrides from local storage
      const items = localStorage.getItem(storageKey);
      const newFlagOverrides = items ? JSON.parse(items) : {};

      if (
        newFlagOverride.variation === 'delete' &&
        newFlagOverrides[newFlagOverride.flagKey]
      ) {
        delete newFlagOverrides[newFlagOverride.flagKey];
      } else {
        // add this override value in the keymap
        newFlagOverrides[newFlagOverride.flagKey] = newFlagOverride;
      }

      // set the new value in localstorage
      localStorage.setItem(storageKey, JSON.stringify(newFlagOverrides));

      return newFlagOverrides;
    },
    onSuccess: () => {
      // ✅ refetch the feature flag overrides wherever they're being fetched
      queryClient.invalidateQueries({
        queryKey: ['feature-flag-overrides'],
      });
    },
  });

  return (
    <div
      className={classNames('flex items-center justify-between gap-3 p-2', {
        'bg-opacity-30': isOverridden,
        'bg-red-700': isOverridden && !decision.enabled,
        'bg-green-700': isOverridden && decision.enabled,
      })}
    >
      <label
        className="relative inline-flex items-center cursor-pointer"
        onClick={() =>
          updateOverride.mutate({
            flagKey,
            variation: decision.enabled ? 'off' : 'on',
          })
        }
      >
        <input
          type="checkbox"
          value=""
          className="sr-only peer"
          checked={decision.enabled}
          readOnly
        />
        <div className="w-9 h-5 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
        <span className="ml-3 text-sm font-medium text-gray-300">
          {name || flagKey}
        </span>
      </label>
      <div className={'flex'}>
        {isOverridden && (
          <div
            className={'cursor-pointer'}
            title={'reset flag to default'}
            onClick={() =>
              updateOverride.mutate({
                flagKey,
                variation: 'delete',
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-gray-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
              />
            </svg>
          </div>
        )}
        <a
          className={'cursor-pointer'}
          title={'go to flag settings'}
          href={`https://app.optimizely.com/v2/projects/23709790223/flags/manage/${flagKey}/rules/development`}
          target={'_blank'}
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 ml-2 text-gray-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};
