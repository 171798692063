import { Transition, Dialog as HeadlessDialog } from '@headlessui/react';
import React, { FC, Fragment } from 'react';
import { Button } from '../button/button';

export type AlertDialogProps = {
  /** Tells the dialog to open */
  isOpen: boolean;

  /** Title for Dialog */
  title: string;

  /** Description for Dialog */
  description: string;

  /** Text to include in submit button. Default is "Submit" */
  submitText?: string;

  /** Callback for closing the dialog */
  onClose: () => void;

  /** Callback for submitting */
  onSubmit: () => void;
};

export const AlertDialog: FC<AlertDialogProps> & { filePath: string } = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  description,
  submitText = 'Submit',
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <HeadlessDialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all z-50">
                <HeadlessDialog.Title
                  as="h3"
                  className="text-base font-medium leading-6 text-gray-900"
                >
                  {title}
                </HeadlessDialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-neutral-gray-800">{description}</p>
                </div>

                <div className="mt-4 flex justify-end">
                  <div className="mr-4">
                    <Button
                      dataCy="dialog-close-button"
                      onClick={onClose}
                      color="black"
                    >
                      Cancel
                    </Button>
                  </div>

                  <Button
                    dataCy="dialog-submit-button"
                    onClick={() => {
                      onSubmit();
                      onClose();
                    }}
                    color={'red'}
                  >
                    {submitText}
                  </Button>
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
};

AlertDialog.filePath = 'libs/shared/ui-kit/src/lib/dialog/alert-dialog.tsx';
AlertDialog.displayName = 'AlertDialog';
