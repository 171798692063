import { ListBlockChildrenResponse } from '@notionhq/client/build/src/api-endpoints';

const colorClassMap: Record<string, string> = {
  gray: 'text-gray-700',
  brown: 'text-brown-700',
  orange: 'text-orange-700',
  yellow: 'text-yellow-700',
  green: 'text-green-700',
  blue: 'text-blue-700',
  purple: 'text-purple-700',
  pink: 'text-pink-700',
  red: 'text-red-700',
};

const maybeStyleContent = (content: any, annotations: any) => {
  if (!annotations) return content;

  if (annotations.bold) content = <strong>{content}</strong>;
  if (annotations.code) content = <code>{content}</code>;
  if (annotations.italic) content = <em>{content}</em>;
  if (annotations.strikethrough) content = <s>{content}</s>;
  if (annotations.underline) content = <u>{content}</u>;
  if (annotations.color !== 'default')
    content = (
      <span className={colorClassMap[annotations.color]}>{content}</span>
    );

  return content;
};

const maybeLinkifyContent = (content: any, link: any) => {
  if (!link) return content;

  return (
    <a href={link.url || link} target={'_blank'} rel="noopener noreferrer">
      {content}
    </a>
  );
};

const maybeModifyContent = (content: any, annotations: any, link: any) => {
  content = maybeStyleContent(content, annotations);
  content = maybeLinkifyContent(content, link);

  return content;
};

const Paragraph = ({ block }: { block: any }) => {
  if (!block.paragraph?.rich_text.length) return null;

  return (
    <p>
      {block.paragraph.rich_text.map((item: any, index: number) => (
        <span key={index}>
          {maybeModifyContent(
            item.text?.content || item.plain_text,
            item.annotations,
            item.text?.link || item.href,
          )}
        </span>
      ))}
    </p>
  );
};

const RichTextContent = (rich_text: any) => {
  if (!rich_text.item.text) return null;

  return (
    <>
      {maybeModifyContent(
        rich_text.item.text.content,
        rich_text.item.annotations,
        rich_text.item.text.link,
      )}
    </>
  );
};

const Heading1 = ({ block }: { block: any }) => {
  if (!block.heading_1?.rich_text.length) return null;

  return (
    <h1>
      {block.heading_1.rich_text.map((item: any, index: number) => (
        <RichTextContent item={item} key={index} />
      ))}
    </h1>
  );
};
const Heading2 = ({ block }: { block: any }) => {
  if (!block.heading_2?.rich_text.length) return null;

  return (
    <h2>
      {block.heading_2.rich_text.map((item: any, index: number) => (
        <RichTextContent item={item} key={index} />
      ))}
    </h2>
  );
};
const Heading3 = ({ block }: { block: any }) => {
  if (!block.heading_3?.rich_text.length) return null;

  return (
    <h3>
      {block.heading_3.rich_text.map((item: any, index: number) => (
        <RichTextContent item={item} key={index} />
      ))}
    </h3>
  );
};
const BulletItem = ({ block }: { block: any }) => {
  if (!block.bulleted_list_item?.rich_text.length) return null;

  return (
    <li>
      {block.bulleted_list_item.rich_text.map((item: any, index: number) => (
        <RichTextContent item={item} key={index} />
      ))}
    </li>
  );
};
const Toggle = ({ block }: { block: any }) => {
  if (!block.toggle?.rich_text.length) return null;

  // TODO: fetch children of toggle
  return (
    <p>
      {block.toggle.rich_text.map((item: any, index: number) => (
        <RichTextContent item={item} key={index} />
      ))}
    </p>
  );
};

export const NotionContent = ({
  response,
}: {
  response: ListBlockChildrenResponse;
}) => {
  if (!response || !response.results.length) return null;

  // force any type since it's too unioned
  return (
    <div className={'markdown'}>
      {response.results.map((item: any, index: number) => {
        if (item.type === 'paragraph') {
          return <Paragraph block={item} key={index} />;
        }
        if (item.type === 'heading_1') {
          return <Heading1 block={item} key={index} />;
        }
        if (item.type === 'heading_2') {
          return <Heading2 block={item} key={index} />;
        }
        if (item.type === 'heading_3') {
          return <Heading3 block={item} key={index} />;
        }
        if (item.type === 'bulleted_list_item') {
          return <BulletItem block={item} key={index} />;
        }
        if (item.type === 'toggle') {
          return <Toggle block={item} key={index} />;
        }
        if (item.type === 'image' && item.image.external?.url) {
          return <img src={item.image.external?.url} alt={''} key={index} />;
        }

        return null;
      })}
    </div>
  );
};
