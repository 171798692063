import { formatValue } from '@frontend/shared-utils';
import { EarningsChange, StatItem } from './state';
import { snakeCase } from 'lodash';

export const getAllTimeEarnings = (
  earnings: StatItem[],
  hasFlatFees: boolean,
) => {
  const val = earnings.reduce((accum, value) => {
    const increment =
      value[hasFlatFees ? 'total_earnings_usd' : 'publisher_earnings_usd'];
    return (accum +=
      typeof increment === 'string' ? parseFloat(increment) : increment);
  }, 0);

  return formatValue(val, 'currency', 'publisher_total_earnings');
};

export const getDailyEarnings = (
  earnings: StatItem[],
  hasFlatFees: boolean,
) => {
  const val =
    earnings[earnings.length - 1][
      hasFlatFees ? 'total_earnings_usd' : 'publisher_earnings_usd'
    ];
  return formatValue(val, 'currency', 'publisher_total_earnings');
};

export const calculateChange = (
  newStat: any,
  oldStat: any,
  isCurrency = true,
): EarningsChange => {
  const invalid: EarningsChange = { amount: null, unit: 'invalid' };

  if (oldStat === undefined || oldStat === null) return invalid;

  const tolerance = Number.EPSILON;
  const newStatIsZero = Math.abs(newStat - 0) < tolerance;
  const oldStatIsZero = Math.abs(oldStat - 0) < tolerance;
  const absoluteUnit = isCurrency ? 'absolute-currency' : 'absolute';

  if (oldStatIsZero) {
    if (newStatIsZero) {
      return invalid;
    } else {
      return { amount: newStat, unit: absoluteUnit };
    }
  } else if (newStatIsZero) {
    return { amount: 0 - oldStat, unit: absoluteUnit };
  }

  return {
    amount: Math.round(((newStat - oldStat) / oldStat) * 100),
    unit: 'percentage',
  };
};

export const getDailyChange = (earnings: StatItem[], hasFlatFees: boolean) => {
  const today =
    earnings[earnings.length - 1][
      hasFlatFees ? 'total_earnings_usd' : 'publisher_earnings_usd'
    ];
  let yesterday;
  if (earnings.length >= 2) {
    yesterday =
      earnings[earnings.length - 2][
        hasFlatFees ? 'total_earnings_usd' : 'publisher_earnings_usd'
      ];
  }

  const dailyChange = calculateChange(today, yesterday);
  return dailyChange;
};

export const getMonthlyEarnings = (
  earnings: StatItem[],
  hasFlatFees: boolean,
) => {
  let thisMonth = 0;
  const currentMonth = new Date().getMonth() + 1; // JS 0-indexes dates

  earnings.forEach((stat) => {
    const month = parseInt(stat.date_interval.split('-')[1]);

    if (month === currentMonth) {
      thisMonth +=
        stat[hasFlatFees ? 'total_earnings_usd' : 'publisher_earnings_usd'];
    }
  });

  return {
    raw: thisMonth,
    pretty: formatValue(thisMonth, 'currency', 'publisher_earnings_usd'),
  };
};

export const getMonthlyChange = (
  earnings: StatItem[],
  monthElapsed: any,
  hasFlatFees: boolean,
) => {
  const thisMonth =
    earnings[earnings.length - 1][
      hasFlatFees ? 'total_earnings_usd' : 'publisher_earnings_usd'
    ];
  let lastMonth;
  if (earnings.length >= 2) {
    lastMonth =
      earnings[earnings.length - 2][
        hasFlatFees ? 'total_earnings_usd' : 'publisher_earnings_usd'
      ];
  }

  const projected = parseFloat((thisMonth / monthElapsed).toFixed(2));
  const percentChange = calculateChange(projected, lastMonth);

  return percentChange;
};
export const getUrlAnalyticsParams = (params: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const returnObj: any = {};
  Object.keys(params).forEach((key: string) => {
    const val = urlParams.get(snakeCase(key));
    if (key === 'page' || key === 'perPage') {
      returnObj[key] = val && parseInt(val);
    } else {
      returnObj[key] = val;
    }
  });

  const otherParamKeys = [
    'columns_display',
    'exclude_strategies_without_activity',
    'campaign_status',
    'pricing_model',
  ];
  otherParamKeys.forEach((key) => {
    if (urlParams.get(key)) returnObj[key] = urlParams.get(key);
  });

  return returnObj;
};

export const setUrlAnalyticsParams = (params: any) => {
  const urlParams = new URL(window.location.href);
  Object.keys(params).forEach((key: string) => {
    const formatKey = snakeCase(key);
    if (urlParams.searchParams.has(formatKey)) {
      urlParams.searchParams.delete(formatKey);
    }
  });
  Object.keys(params).forEach((key: any) => {
    const formatKey = snakeCase(key);
    if (params[key] == null) return;
    urlParams.searchParams.set(formatKey, params[key]);
  });
  return urlParams;
};

export const renderBudgetRemaining = ({ cellData, rowData }: any) => {
  const isCpc =
    rowData.strategy_payment_model === 1 ||
    (!rowData.strategy_payment_model && rowData.strategy_pricing_model === 1);

  if (isCpc && !rowData.budget_amount) {
    return 'Uncapped';
  } else if (!isCpc) {
    return '-';
  } else if (!cellData) {
    return '-';
  } else {
    return cellData;
  }
};
