type State = {
  defaultPaymentMethod: any;
  setupIntentSecret: string | null;
  merchant: any;
  loadingDefaultPaymentMethod: boolean;
  loadingSetupIntentSecret: boolean;
};

export const state: State = {
  defaultPaymentMethod: null,
  setupIntentSecret: null,
  merchant: null,
  loadingDefaultPaymentMethod: false,
  loadingSetupIntentSecret: false,
};
