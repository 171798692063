export type Rule = {
  target_rule_id: string;
  target_list_type: string;
  target_rule_operator: string;
  target_rule_link_date?: string;
  target_rule_supplier_type?: string | null;
  target_rule_supplier_link_type?: string | null;
  target_rule_link_creation_datetime?: string | null;
  target_list_items?: any[] | null;
  target_list?: any[] | null;
  target_count?: number | null;
};

type Strategy = {
  strategy_id: number | null;
  target_expression_id: number | null;
  strategy_is_evergreen?: boolean;
  strategy_pricing_model?: string;
  strategy_pricing_model_value?: number;
  strategy_payment_model?: string;
  strategy_payment_model_value?: number;
  strategy_bid?: number;
  strategy_roi_goal?: number;
  strategy_publisher_roi_target?: number;
  strategy_publisher_epc?: number;
  strategy_cogs?: number;
  epp_fee_rate?: number;
  cpc_fee_rate?: number;
};

export type FlatFee = {
  flat_fee_id?: string | number;
  post_count: number;
  amount: number;
  datetime_start: string;
};

export type RateTier = {
  rate_tier_id: string | number;
  rate_tier_name: string;
  strategy_bid: string | null;
  strategy_roi_goal: string | null;
  strategy_publisher_epc: string | null;
  strategy_publisher_roi_target: string | null;
  strategy_cogs?: number;
  rate_tier_description: string;
  rate_tier_is_enabled: boolean;
  rate_tier_is_default: boolean;
  publisher_list_id: number;
  publisher_type: number | string;
  flat_fees: any[];
  pricingModel?: string | number | null;
  paymentModel?: string | number | null;
  pricingModelValue?: string | number;
  paymentModelValue?: string | number;
  partnerGrouping?: string;
  partnerValue?: number;
};

export type TargetingPageState = {
  targetExpression: any[] | [][];
  targetRules: { [k: number]: Rule };
};

type State = {
  merchant: {
    merchantId: number | null;
    merchantFeeRateEPP: number | null;
    merchantFeeRateCPC: number | null;
  };
  strategy: Strategy;
  formStrategyId: number | null;
  targeting: TargetingPageState;
  rateTiers: any;
  isFlatFeeEnabled: boolean;
  strategyEditId: number | null;
  strategyGroupEditId: number | null;
};

export const state: State = {
  merchant: {
    merchantId: null,
    merchantFeeRateEPP: null,
    merchantFeeRateCPC: null,
  },
  strategy: {
    strategy_id: null,
    target_expression_id: null,
  },
  formStrategyId: null,
  targeting: {
    targetExpression: [[]],
    targetRules: {},
  },
  isFlatFeeEnabled: false,
  rateTiers: {},
  strategyEditId: null,
  strategyGroupEditId: null,
};
