import { Context } from '..';
import { Overmind, pipe, waitUntil } from 'overmind';
import {
  pickBy,
  pick,
  capitalize,
  cloneDeep,
  isEqual,
  uniq,
  sortBy,
} from 'lodash';
import { AnalyticsParams, AnalyticsState } from './state';
import moment from 'moment';
import { objectify } from 'radash';
import { buildQueryFilter, formatCellValue } from '@frontend/shared-utils';

const graphLabelFields: any = {
  posts: 'edit_name',
  recommendations: 'product_name',
  creators: 'pub_name',
  products: 'brand_product_name',
  ios: 'strategy_group_type',
};

const graphKeyFields: any = {
  posts: 'edit_id',
  recommendations: 'auction_id',
  creators: 'pub_id',
  products: 'merchant_product_id',
  ios: 'strategy_group_type',
};

const graphColors = [
  'stroke-nebula-300',
  'stroke-aurora-300',
  'stroke-ice-300',
  'stroke-quasar-300',
  'stroke-tatooine-300',
];
const textColors = [
  'text-nebula-300',
  'text-aurora-300',
  'text-ice-300',
  'text-quasar-300',
  'text-tatooine-300',
];
const checkboxColors = ['green', 'purple', 'blue', 'red', 'yellow'];

const defaultParams: AnalyticsParams = {
  date_from: moment().startOf('month').format('YYYY-MM-DD'),
  date_to: moment().format('YYYY-MM-DD'),
  compare_from: moment()
    .startOf('month')
    .subtract(1, 'years')
    .format('YYYY-MM-DD'),
  compare_to: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  order_by: null,
  direction: 'desc',
  page: 1,
  per_page: 50,
  pub_ids: null,
  granularity: 'daily',
  networks: 'narrativ',
  group_by: null,
  columns: null,
  strategies: null,
  strategy_groups: null,
  edit_id: null,
  breadcrumb: null,
  search_query: null,
  display_ios: true,
  strategy_group_status: null,
  strategy_status: null,
  strategy_group_type: null,
  strategy_types: null,
};

const STRATEGY_GROUP_TYPES = {
  '1': 'vendor_funded',
  '2': 'marketplace',
  '3': 'direct_deal',
};

const getDefaultParams = (view?: string) => {
  const params = defaultParams;
  if (view === 'overview') {
    params.date_from = moment()
      .subtract(11, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    params.compare_from = moment()
      .subtract(23, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    params.compare_to = moment()
      .subtract(1, 'years')
      .endOf('month')
      .format('YYYY-MM-DD');
  }
  return defaultParams;
};

const genericDefaults: any = {
  overview: {
    fields: [
      'event_date',
      'clicks',
      'order_conversion_rate',
      'attributed_revenue',
      'merchant_spend',
      'new_link_count',
      'roi',
      'attributed_orders',
    ],
    graph_fields: [
      'event_date',
      'clicks',
      'attributed_revenue',
      'merchant_spend',
      'new_link_count',
      'roi',
    ],
    group_by: 'merch_id',
    order_by: 'event_date',
    granularity: 'monthly',
  },
  creators: {
    fields: [
      'pub_id',
      'pub_name',
      'attributed_revenue',
      'merchant_spend',
      'roi',
      'impressions',
      'clicks',
      'click_through_rate',
      'attributed_orders',
      'order_conversion_rate',
      'consolidated_spend',
      'consolidated_roi',
      'attributed_product_count',
    ],
    group_by: 'pub_id',
    order_by: '-attributed_revenue',
    graph_fields: [
      'event_date',
      'clicks',
      'attributed_revenue',
      'merchant_spend',
      'new_link_count',
      'roi',
    ],
  },
  categories: {
    fields: [
      'product_category',
      'attributed_revenue',
      'merchant_spend',
      'roi',
      'impressions',
      'clicks',
      'click_through_rate',
      'attributed_orders',
      'order_conversion_rate',
      'consolidated_spend',
      'consolidated_roi',
      'attributed_product_count',
    ],
    group_by: 'product_category',
    order_by: '-attributed_revenue',
  },
  posts: {
    fields: [
      'edit_id',
      'edit_name',
      'edit_url',
      'attributed_revenue',
      'merchant_spend',
      'roi',
      'clicks',
      'order_conversion_rate',
      'story_first_activity_date',
      'cost_per_click',
      'attributed_orders',
      'effective_commission_rate',
      'impressions',
      'click_through_rate',
    ],
    group_by: 'edit_id,pub_id',
    order_by: '-attributed_revenue',
    graph_fields: [
      'event_date',
      'clicks',
      'attributed_revenue',
      'merchant_spend',
      'new_link_count',
      'roi',
    ],
  },
  recommendations: {
    fields: [
      'product_name',
      'bam_link_merchant_product_name',
      'edit_name',
      'edit_id',
      'pub_name',
      'impressions',
      'clicks',
      'click_through_rate',
      'order_conversion_rate',
      'attributed_revenue',
      'merchant_spend',
      'roi',
      'cost_per_click',
      'attributed_orders',
      'effective_commission_rate',
      'auction_id',
    ],
    group_by: 'edit_id,auction_id,merchant_product_id,pub_id',
    order_by: '-clicks',
    graph_fields: [
      'event_date',
      'clicks',
      'attributed_revenue',
      'merchant_spend',
      'new_link_count',
      'roi',
    ],
  },
  'campaigns-overview': {
    fields: [
      'attributed_revenue',
      'merchant_spend',
      'consolidated_spend',
      'flat_fees',
      'effective_commission_rate',
      'clicks',
      'impressions',
      'attributed_orders',
      'roi',
    ],
    group_by: 'strategy_group_id,strategy_id',
    order_by: '-attributed_revenue',
  },
  ios: {
    fields: [
      'strategy_group_type',
      'attributed_revenue',
      'merchant_spend',
      'strategy_group_type_budget',
      'roi',
      'effective_commission_rate',
      'clicks',
      'attributed_orders',
    ],
    group_by: 'strategy_group_type',
    order_by: '-attributed_revenue',
    graph_fields: [
      'event_date',
      'clicks',
      'attributed_revenue',
      'merchant_spend',
      'roi',
      'rpc',
      'publishers',
    ],
  },
};
const genericApiFieldMap: any = {
  revenue: 'attributed_revenue',
  spend: 'merchant_spend',
};

const groupingPriorities: any = {
  strategy_group_id: 1,
  strategy_id: 2,
  pub_id: 3,
};

const defaultGrouping: {
  [category: string]: { label: string; value: string };
} = {
  'campaigns-overview': {
    label: 'IO',
    value: 'strategy_group_id',
  },
};

export const getColors = (
  context: Context,
  selectedRows: { rowId: string },
) => {
  const colors: any = { strokeColors: {}, textColors: {}, checkboxColors: {} };
  const defaultColors: any = {
    strokeColors: { default: graphColors[2] },
    textColors: { default: textColors[2] },
    checkboxColors: { default: checkboxColors[2] },
  };

  if (!Object.keys(selectedRows).length) {
    return defaultColors;
  }

  sortBy(Object.values(selectedRows), 'selectOrder')
    .filter((r) => !!r)
    .forEach((r: any) => {
      colors.strokeColors[r.rowId] = `${graphColors[r.selectOrder]}`;
      colors.textColors[r.rowId] = `${textColors[r.selectOrder]}`;
      colors.checkboxColors[r.rowId] = `${checkboxColors[r.selectOrder]}`;
    });

  return colors;
};

const formatReportValue = ({ value, column }: { value: any; column: any }) => {
  // If column has custom report formatting enabled, use that for reporting
  // If column has formatting set up for normal table display, use that for reporting
  // Otherwise, return the column value
  const formattedColumnValue = column?.reportFormatting
    ? column.reportFormatting(value)
    : column?.format != null
    ? formatCellValue(value, column?.format?.type, column?.format?.options)
    : value;

  // Wrap value in quotes to preserve comma-delimited values
  if (formattedColumnValue.includes(',')) {
    return `"${formattedColumnValue}"`;
  }
  return formattedColumnValue;
};

export const onInitializeOvermind: (
  context: Context,
  instance: Overmind<Context>,
) => void = (context: Context) => {
  const params = {
    ...context.state.ecommAnalytics.analyticsView.params,
    date_from: moment().startOf('month').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
    compare_from: moment()
      .startOf('month')
      .subtract(1, 'years')
      .format('YYYY-MM-DD'),
    compare_to: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  };

  context.state.ecommAnalytics.analyticsView = {
    ...context.state.ecommAnalytics.analyticsView,
    params,
  };
};

export const resetState = ({ state }: Context, view?: string) => {
  const {
    date_from,
    date_to,
    compare_from,
    compare_to,
    order_by,
    direction,
    page,
    per_page,
    pub_ids,
    edit_id,
    granularity,
    networks,
    group_by,
    strategies,
    strategy_groups,
    product_brand,
    product_category,
    merchant_product_categories,
    breadcrumb,
    search_query,
    display_ios,
    strategy_group_status,
    strategy_group_type,
    strategy_status,
    strategy_types,
  }: AnalyticsParams = getDefaultParams(view);
  state.ecommAnalytics.analyticsView.tableIsLoading = true;
  state.ecommAnalytics.analyticsView.graphIsLoading = true;

  (state.ecommAnalytics.analyticsView.params as any).order_by = order_by;
  (state.ecommAnalytics.analyticsView.params as any).direction = direction;
  (state.ecommAnalytics.analyticsView.params as any).page = page;
  (state.ecommAnalytics.analyticsView.params as any).per_page = per_page;
  (state.ecommAnalytics.analyticsView.params as any).pub_ids = pub_ids;
  (state.ecommAnalytics.analyticsView.params as any).edit_id = edit_id;
  (state.ecommAnalytics.analyticsView.params as any).granularity = granularity;
  (state.ecommAnalytics.analyticsView.params as any).networks = networks;
  (state.ecommAnalytics.analyticsView.params as any).group_by = group_by;
  (state.ecommAnalytics.analyticsView.params as any).columns = null;
  (state.ecommAnalytics.analyticsView.params as any).strategies = strategies;
  state.ecommAnalytics.analyticsView.selectedStrategies = [];
  (state.ecommAnalytics.analyticsView.params as any).strategy_groups =
    strategy_groups;
  (state.ecommAnalytics.analyticsView.params as any).breadcrumb = breadcrumb;
  (state.ecommAnalytics.analyticsView.params as any).search_query =
    search_query;
  (state.ecommAnalytics.analyticsView.params as any).display_ios = display_ios;
  (state.ecommAnalytics.analyticsView.params as any).strategy_group_status =
    strategy_group_status;
  (state.ecommAnalytics.analyticsView.params as any).strategy_group_type =
    strategy_group_type;
  (state.ecommAnalytics.analyticsView.params as any).strategy_status =
    strategy_status;
  (state.ecommAnalytics.analyticsView.params as any).strategy_types =
    strategy_types;

  state.ecommAnalytics.analyticsView.selectedStrategyGroups = [];
  state.ecommAnalytics.analyticsView.tableData = [];
  state.ecommAnalytics.analyticsView.tableInfo = {
    total_items: 0,
    total_pages: 0,
  };
  state.ecommAnalytics.analyticsView.totalsData = {};
  state.ecommAnalytics.analyticsView.graphData = [];
  state.ecommAnalytics.analyticsView.graphTicks = [];
  state.ecommAnalytics.analyticsView.graphRows = [];
  state.ecommAnalytics.analyticsView.rechartsGraphData = [];
  state.ecommAnalytics.analyticsView.selectedColumns = [];
  state.ecommAnalytics.analyticsView.selectedPubs = [];
  state.ecommAnalytics.analyticsView.selectedRows = {};
  state.ecommAnalytics.analyticsView.selectedGranularity = {
    label: 'Daily',
    value: 'daily',
  };
  state.ecommAnalytics.analyticsView.chartType = 'clicks';
  state.ecommAnalytics.analyticsView.selectedColors = {
    strokeColors: [],
    textColors: [],
    checkboxColors: [],
  };
  state.ecommAnalytics.analyticsView.compareYoy = false;
  (state.ecommAnalytics.analyticsView.params as any).product_brand =
    product_brand;
  (state.ecommAnalytics.analyticsView.params as any).product_category =
    product_category;
  (
    state.ecommAnalytics.analyticsView.params as any
  ).merchant_product_categories = merchant_product_categories;

  state.ecommAnalytics.analyticsView.subRowData = {};
  state.ecommAnalytics.analyticsView.hasOneProductCategory = false;
  state.ecommAnalytics.analyticsView.searchQuery = null;
  state.ecommAnalytics.analyticsView.selectedObjective = [];
  state.ecommAnalytics.analyticsView.crumbData = {};
  state.ecommAnalytics.analyticsView.selectedStatusFilter = [
    { label: 'Live', value: '1' },
  ];
  state.ecommAnalytics.analyticsView.selectedStrategyStatus = [
    { label: 'Live', value: '1' },
  ];
  state.ecommAnalytics.analyticsView.selectedStrategyGroupTypes = [];
  state.ecommAnalytics.analyticsView.selectedObjective = [];
};

export const setState = ({ state }: Context, newState: AnalyticsState) => {
  const updatedState = { ...state.ecommAnalytics.analyticsView, ...newState };
  state.ecommAnalytics.analyticsView = updatedState;
};

export const setParams = ({ state }: Context, params: AnalyticsParams) => {
  const newParams = { ...state.ecommAnalytics.analyticsView.params, ...params };
  state.ecommAnalytics.analyticsView.params = newParams;
  state.ecommAnalytics.analyticsView.selectedPubs = newParams.pub_ids
    ? newParams.pub_ids.split(',').map((value) => ({ value, label: '' }))
    : [];
  state.ecommAnalytics.analyticsView.selectedGranularity = {
    label: capitalize(newParams.granularity),
    value: newParams.granularity as any,
  };
  state.ecommAnalytics.analyticsView.selectedGroupBy = {
    value: newParams.group_by as any,
  };
  const group = newParams.group_by?.split(',');
  if (
    group?.length &&
    !isEqual(
      group,
      state.ecommAnalytics.analyticsView.newSelectedGroupBy?.map(
        (grp) => grp?.value,
      ),
    )
  ) {
    state.ecommAnalytics.analyticsView.newSelectedGroupBy = group.map((grp) => {
      return { value: grp, label: '' };
    });
  }
  if (typeof newParams.page === 'string') {
    (state.ecommAnalytics.analyticsView.params as any).page = parseInt(
      newParams.page,
    );
  }
  if (typeof newParams.per_page === 'string') {
    (state.ecommAnalytics.analyticsView.params as any).per_page = parseInt(
      newParams.per_page,
    );
  }
  if (newParams.columns) {
    state.ecommAnalytics.analyticsView.selectedColumns = newParams.columns
      .split(',')
      .map((value) => ({ value }));
  }

  if (newParams.strategy_group_type) {
    state.ecommAnalytics.analyticsView.selectedStrategyGroupTypes =
      newParams.strategy_group_type
        .split(',')
        .map((value) => ({ label: '', value }));
  }

  if (
    newParams.strategy_groups &&
    state.ecommAnalytics.merchantStrategyGroups.length
  ) {
    const strategyGroupMap = objectify(
      state.ecommAnalytics.merchantStrategyGroups,
      (sg) => sg.strategy_group_id,
    );
    (state.ecommAnalytics.analyticsView.params as any).strategy_groups =
      newParams.strategy_groups;
    const selectedStrategyGroups = newParams.strategy_groups
      .split(',')
      .map((sgId) => ({
        label: strategyGroupMap[sgId]?.strategy_group_name,
        value: strategyGroupMap[sgId]?.strategy_group_id,
      }));

    state.ecommAnalytics.analyticsView.selectedStrategyGroups =
      selectedStrategyGroups;
  }

  if (newParams.strategies && state.ecommAnalytics.merchantStrategies.length) {
    (state.ecommAnalytics.analyticsView.params as any).strategies =
      newParams.strategies;
    const strategyMap = objectify(
      state.ecommAnalytics.merchantStrategies,
      (sg) => sg.strategy_id,
    );
    const selectedStrategies = newParams.strategies.split(',').map((sId) => ({
      label: strategyMap[sId].strategy_name,
      value: strategyMap[sId].strategy_id,
    }));

    state.ecommAnalytics.analyticsView.selectedStrategies = selectedStrategies;
  }
};

export const getTableParams = ({ state }: Context, total?: boolean) => {
  const category = state.ecommAnalytics.analyticsView.category as string;
  const params = { ...state.ecommAnalytics.analyticsView.params };
  const { compareYoy } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  const fields = [
    'date_from',
    'date_to',
    'pub_ids',
    'strategies',
    'strategy_groups',
  ];

  if (!total) {
    if (category === 'overview') {
      fields.push('granularity');
      params.granularity = 'monthly';
    }

    if (category === 'creators') {
      fields.push('networks');
    }

    if (['products', 'secondary-attribution'].includes(category)) {
      fields.push('group_by');
    }
    fields.push('order_by', 'direction', 'page', 'per_page');
  } else {
    if (!['creators', 'products'].includes(category)) {
      fields.push('group_by');
    }
  }

  if (category === 'product-clicked') {
    fields.push('compare_from', 'compare_to', 'merchant_product_categories');
  }

  // Used to filter on IO Type dropdown
  if (['campaigns-overview', 'campaigns', 'ios'].includes(category)) {
    fields.push('strategy_group_type');
  }

  if (['campaigns', 'ios'].includes(category)) {
    fields.push('strategy_types');
  }

  if (category === 'ios') {
    fields.push('strategy_group_status');
  }

  if (category === 'campaigns') {
    fields.push('strategy_status');
  }

  if (['ios', 'campaigns'].includes(category) && compareYoy) {
    fields.push('compare_from', 'compare_to');
  }

  const tableParams = pickBy(
    params,
    (value, key) => fields.includes(key) && !!value,
  );

  if (['campaigns', 'ios'].includes(category) && params?.search_query) {
    tableParams['filters'] = buildQueryFilter([
      {
        field: category === 'ios' ? 'strategy_group_name' : 'strategy_name',
        operation: 'contains',
        value: params.search_query,
      },
    ]);
  }

  // Secondary Attribution API defines group_by as dim, need to manually override it
  if (category === 'secondary-attribution') {
    tableParams['dim'] = tableParams['group_by'];
    delete tableParams['group_by'];
  }

  if (category === 'campaigns') {
    // Rename IO Filter for legacy endpoint
    tableParams['strategy_group_ids'] = tableParams['strategy_groups'];
    delete tableParams['strategy_groups'];
    // Rename status filter
    tableParams['status'] = tableParams['strategy_status'];
    delete tableParams['strategy_status'];
  }

  if (category === 'ios') {
    // Rename status param to hit endpoint
    tableParams['status'] = tableParams['strategy_group_status'];
    // Rename type param && coerce to enum value
    tableParams['strategy_group_types'] =
      Object.keys(STRATEGY_GROUP_TYPES).find(
        (key: string) =>
          STRATEGY_GROUP_TYPES[key as '1' | '2' | '3'] ===
          tableParams['strategy_group_type'],
      )?.[0] || null;
    delete tableParams['strategy_group_status'];
    delete tableParams['strategy_group_type'];
  }

  if (['ios', 'campaigns'].includes(category) && compareYoy) {
    tableParams['include_yoy'] = true;
  }

  if (allSites) {
    const { accessList } = state.auth;
    tableParams['merch_ids'] = accessList
      ?.filter((org) => org.type === 'merchants')
      ?.map((org) => org.orgId)
      ?.join(',') as string;
  }

  return tableParams;
};

const notStats = [
  'edit_id',
  'edit_name',
  'edit_url',
  'event_date',
  'pub_id',
  'pub_name',
  'story_first_activity_date',
  'strategy_group_id',
  'strategy_group_name',
  'strategy_group_type',
  'strategy_id',
  'strategy_name',
  'strategy_type',
  'auction_id',
  'bam_link_merchant_product_name',
  'product_brand',
  'product_name',
  'product_category',
];

type CampaignOverviewGroup = 'strategy_group_id' | 'strategy_id' | 'pub_id';
const campaignOverviewGroups: { [key in CampaignOverviewGroup]: string[] } = {
  strategy_group_id: [
    'strategy_group_id',
    'strategy_group_name',
    'strategy_group_type',
  ],
  strategy_id: ['strategy_id', 'strategy_name', 'strategy_type'],
  pub_id: ['pub_id', 'pub_name'],
};

export const getGenericTableParams = (
  { state }: Context,
  {
    total,
    report,
    ignorePagination,
  }: { total?: boolean; report?: boolean; ignorePagination?: boolean },
) => {
  const {
    compareYoy,
    category = 'overview',
    params,
  } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;

  const query: any = {};
  const defaults = (genericDefaults as any)[category];

  query['group_by'] = total
    ? allSites
      ? null
      : 'merch_id'
    : defaults.group_by;

  let fields = [...defaults.fields].map((col: string) => {
    return genericApiFieldMap[col] || col;
  });

  // Need to make sure we're getting strategy_type && strategy_id when querying strategy_name
  if (fields.includes('strategy_name')) {
    if (!fields.includes('strategy_id')) fields.push('strategy_id');
    if (!fields.includes('strategy_type')) fields.push('strategy_type');
  }
  if (fields.includes('strategy_group_name')) {
    if (!fields.includes('strategy_group_id')) fields.push('strategy_group_id');
    if (!fields.includes('strategy_group_type'))
      fields.push('strategy_group_type');
  }
  if (fields.includes('pub_name')) {
    if (!fields.includes('pub_id')) fields.push('pub_id');
  }
  if (fields.includes('roi')) {
    if (!fields.includes('effective_commission_rate'))
      fields.push('effective_commission_rate');
  }

  if (fields.includes('edit_name')) {
    if (!fields.includes('pub_id')) fields.push('pub_id');
    if (!fields.includes('pub_name')) fields.push('pub_name');
    if (!fields.includes('edit_url')) fields.push('edit_url');
  }

  if (fields.includes('product_name')) {
    if (!fields.includes('product_brand')) fields.push('product_brand');
  }
  if (total) {
    fields = fields.filter((x: any) => !notStats.includes(x));
  } else {
    if (category === 'creators') {
      fields.push('pub_id');
      fields.push('product_category_count');
      fields = uniq(fields);
    }
    if (category === 'categories') {
      fields.push('category_publisher_count');
    }
    if (category === 'posts') {
      fields.push('edit_id');
    }
    if (category === 'recommendations') {
      fields.push('auction_id');
    }
  }

  if (compareYoy) {
    fields.forEach((x: any) => {
      if (
        !notStats.includes(x) &&
        !x.endsWith('_change') &&
        !x.endsWith('_before')
      ) {
        fields.push(`${x}_before`);
        fields.push(`${x}_change`);
      }
    });
  }

  fields = uniq(fields);

  query['fields'] = fields.join(',');

  if (params?.date_from) {
    query['date_from'] = params.date_from;
  }
  if (params?.date_to) {
    query['date_to'] = params.date_to;
  }
  if (params?.auction_id) {
    query['auction_id'] = params.auction_id;
  }
  if (params?.pub_ids) {
    query['pub_id'] = params.pub_ids;
  }
  if (params?.edit_id) {
    query['edit_id'] = params.edit_id;
  }

  if (params?.strategies) {
    query['strategy_id'] = params.strategies;
  }

  if (params?.strategy_groups) {
    query['strategy_group_id'] = params.strategy_groups;
  }

  if (params?.strategy_group_type) {
    query['strategy_group_type'] = params.strategy_group_type;
  }

  if (params?.product_brand) {
    query['product_brand'] = params.product_brand;
  }
  if (params?.product_category) {
    query['product_category'] = params.product_category;
  }

  if (compareYoy) {
    query['compare_from'] =
      params?.compare_from ||
      moment(params?.date_from).subtract(1, 'years').format('YYYY-MM-DD');
    query['compare_to'] =
      params?.compare_to ||
      moment(params?.date_to).subtract(1, 'years').format('YYYY-MM-DD');
  }

  if (allSites) {
    const { accessList } = state.auth;
    query['merch_id'] = accessList
      ?.filter((org) => org.type === 'merchants')
      ?.map((org) => org.orgId)
      ?.join(',');
  }

  if (!total) {
    if (defaults.granularity) {
      query['granularity'] = defaults.granularity;
    }
    if (params?.order_by) {
      let orderBy = params.order_by;
      orderBy = genericApiFieldMap[orderBy] || orderBy;
      if (fields.indexOf(orderBy) !== -1) {
        if (params?.direction === 'desc') {
          orderBy = '-' + orderBy;
        }
        query['order_by'] = orderBy;
      }
    } else {
      query['order_by'] = defaults.order_by;
    }
    if (params?.group_by) {
      // Need to manually handle grouping by date, performance API expects it as part of the granularity param and not group_by
      const splitGroups = params.group_by.split(',');
      if (splitGroups.includes('event_date')) {
        const filteredGroups = splitGroups.filter(
          (group) => group !== 'event_date',
        );
        // If only grouping by event_date, API needs to group by merch_id in addition to the granularity
        query['group_by'] =
          filteredGroups.length > 0 ? filteredGroups.join(',') : 'merch_id';
        query['granularity'] = 'daily';
        query['fields'] += ',event_date';
      } else {
        query['group_by'] = params.group_by;
      }

      // We want to make sure we group in the correct order when doing multi-dimensional grouping
      if (category === 'campaigns-overview') {
        query['group_by'] = query['group_by']
          .split(',')
          .sort(
            (a: any, b: any) =>
              groupingPriorities?.[a] > groupingPriorities?.[b],
          )
          .join(',');

        // Dynamically add fields to request based on selected grouping
        query['group_by']
          .split(',')
          .forEach((groupField: CampaignOverviewGroup) => {
            query['fields'] += `,${campaignOverviewGroups[groupField].join(
              ',',
            )}`;
          });

        // FF data can't be grouped by just pub_id, need to manually add a merch_id grouping
        if (query['group_by'] === 'pub_id') {
          query['group_by'] = `${query['group_by']},merch_id`;
        }
      }
    }
    if (params?.page && !ignorePagination) {
      query['page'] = params.page.toString();
    }
    if (params?.per_page && !ignorePagination) {
      query['per_page'] = params.per_page.toString();
    }
    if (!report) query['need_page_info'] = '1'; // Possible optimization: only ask for this once
  }

  // No grouping for overview analytics with allsites enabled
  if (category === 'overview' && allSites) {
    query['group_by'] = null;
  }
  return query;
};

export const getStrategyLists = async (
  { state, effects }: Context,
  merchantId: string,
) => {
  const params: { merch_ids?: string } = {};

  const { allSites } = state.organization;
  if (allSites) {
    const { accessList } = state.auth;
    params['merch_ids'] = accessList
      ?.filter((org) => org.type === 'merchants')
      ?.map((org) => org.orgId)
      ?.join(',');
  }

  const endpoint = allSites
    ? '/api/v0/merchants/strategies/'
    : `/api/v0/merchants/${merchantId}/strategies/`;

  return await effects.api.get(endpoint, params).then((resp: any) => {
    return Promise.resolve({
      strategies: resp.data.data[0].strategies,
      strategyGroups: resp.data.data[0].strategy_groups,
    });
  });
};

export const setStrategyLists = (
  { state }: Context,
  {
    strategies,
    strategyGroups,
  }: {
    strategies: { label: string; value: any }[];
    strategyGroups: { label: string; value: any }[];
  },
) => {
  state.ecommAnalytics.merchantStrategies = strategies;
  state.ecommAnalytics.merchantStrategyGroups = strategyGroups;
};

export const setStrategyOptions = (
  { state }: Context,
  options: { label: string; value: any }[],
) => {
  state.ecommAnalytics.analyticsView.strategyOptions = options;
};

export const formatStrategyOptions = (
  context: Context,
  strategies: { strategy_name: string; strategy_id: number }[],
) => {
  return strategies.map((strategy) => ({
    label: strategy.strategy_name,
    value: strategy.strategy_id,
  }));
};

export const setSubRowsData = async (
  { state }: Context,
  { rowId, subRowData }: { rowId: string; subRowData: any },
) => {
  return (state.ecommAnalytics.analyticsView.subRowData[rowId] = subRowData);
};

export const getSubRowData = async (
  { state, actions, effects }: Context,
  rowId: string,
) => {
  const { category, merchantId } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  if (!category || !merchantId) return;

  if (state.ecommAnalytics.analyticsView.subRowData[rowId]) {
    return;
  }

  if (category === 'creators') {
    const params = actions.ecommAnalytics.getGenericTableParams({
      total: false,
    });
    params.group_by += ',product_category';
    params.fields += ',product_category';
    params.pub_id = rowId;
    const endpoint = allSites
      ? '/api/v0/merchants/performance/'
      : `/api/v0/merchants/${merchantId}/performance/`;
    const result = await effects.api.get(endpoint, params);

    const subRowData = sortBy(result.data.data[0].stats, 'product_category');

    return actions.ecommAnalytics.setSubRowsData({ rowId, subRowData });
  }

  return null;
};

export const setBulkSubRowsData = async (
  { state }: Context,
  {
    rowIds,
    subRowsById,
  }: { rowIds: string[]; subRowsById: { [rowId: string]: any } },
) => {
  return rowIds.forEach((rowId: string) => {
    state.ecommAnalytics.analyticsView.subRowData[rowId] = subRowsById[rowId];
  });
};

export const getBulkSubRowData = async (
  { state, actions, effects }: Context,
  rowIds: string[],
) => {
  const { category, merchantId } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;

  if (!category || !merchantId) return;

  const endpoint = allSites
    ? '/api/v0/merchants/performance/'
    : `/api/v0/merchants/${merchantId}/performance/`;

  if (category === 'creators') {
    const params = actions.ecommAnalytics.getGenericTableParams({
      total: false,
      ignorePagination: true,
    });
    params.group_by += ',product_category';
    params.fields += ',product_category';
    params.pub_id = rowIds.join(',');
    const result = await effects.api.get(endpoint, params);

    const subRowData = result.data.data[0].stats;

    const subRowsById: { [rowId: string]: any } = {};
    rowIds.forEach((rowId: string) => {
      subRowsById[rowId] = sortBy(
        subRowData.filter((subRow: any) => subRow.pub_id === rowId),
        'pub_id',
      );
    });

    return actions.ecommAnalytics.setBulkSubRowsData({ rowIds, subRowsById });
  }

  if (category === 'categories') {
    const params = actions.ecommAnalytics.getGenericTableParams({
      total: false,
      ignorePagination: true,
    });
    params.group_by += ',pub_id';
    params.fields += ',pub_id,pub_name';
    const result = await effects.api.get(endpoint, params);

    const subRowData = result.data.data[0].stats;

    const subRowsById: { [rowId: string]: any } = {};
    rowIds.forEach((rowId: string) => {
      subRowsById[rowId] = sortBy(
        subRowData.filter((subRow: any) => subRow.product_category === rowId),
        'product_category',
      );
    });

    return actions.ecommAnalytics.setBulkSubRowsData({ rowIds, subRowsById });
  }
};

export const getTableData = pipe(
  waitUntil((state: any) => state.ecommAnalytics.analyticsView.merchantId),
  async ({ state, actions, effects }: Context) => {
    const { category, merchantId } = state.ecommAnalytics.analyticsView;
    const { display_ios }: any = state.ecommAnalytics.analyticsView.params;
    const { allSites } = state.organization;

    if (!category) return;

    state.ecommAnalytics.analyticsView.tableIsLoading = true;
    state.ecommAnalytics.analyticsView.graphIsLoading = true;
    const endpoints: any = {
      products: `/api/v0/merchants/${merchantId}/strategies/products_performance/`,
      'product-clicked': `/api/v0/merchants/${merchantId}/partner_performance/`,
      'secondary-attribution': `/api/v0/merchants/${merchantId}/secondary_attribution/`,
      ios: `/api/v0/merchants/${merchantId}/campaigns/overview/`,
      campaigns: `/api/v0/merchants/${merchantId}/strategies/overview/`,
    };
    const allSitesEndpoints: any = {
      ios: '/api/v0/merchants/campaigns/overview/',
      campaigns: '/api/v0/merchants/strategies/overview/',
      products: `/api/v0/merchants/strategies/products_performance/`,
      'product-clicked': `/api/v0/merchants/partner_performance/`,
      'secondary-attribution': `/api/v0/merchants/secondary_attribution/`,
    };
    const genericApi =
      !endpoints[category] || (category === 'ios' && !display_ios);

    try {
      let endpoint;
      let params;
      if (genericApi) {
        endpoint = allSites
          ? `/api/v0/merchants/performance/`
          : `/api/v0/merchants/${merchantId}/performance/`;
        params = actions.ecommAnalytics.getGenericTableParams({
          total: false,
        });
      } else {
        endpoint = allSites ? allSitesEndpoints[category] : endpoints[category];
        params = actions.ecommAnalytics.getTableParams(false);
      }
      const result: any = await effects.api.get(endpoint, params);

      // Product-clicked API response has a slightly different shape, need to override from rows -> stats for consistency
      if (category === 'product-clicked') {
        result.data.data[0].stats = result.data.data[0].rows;
      }

      if (category === 'product-clicked') {
        state.ecommAnalytics.analyticsView.totalsData = result.data.data[0];
      }

      if (category === 'ios' && display_ios) {
        result.data.data[0].stats = result.data.data[0].strategy_groups;
      }
      return result.data.data[0];
    } catch (error) {
      console.error(error);
    }
  },
);

export const setTableData = (
  { state, actions }: Context,
  data: { stats: any; page_info: any },
) => {
  const { category, params } = state.ecommAnalytics.analyticsView;
  const { getColors } = actions.ecommAnalytics;
  state.ecommAnalytics.analyticsView.tableData = data.stats.map(
    (rowData: any) => ({
      ...rowData,
      merchantId: state.ecommAnalytics.analyticsView.merchantId,
      selectedPubs: state.ecommAnalytics.analyticsView.selectedPubs,
    }),
  );

  if (data.page_info) {
    state.ecommAnalytics.analyticsView.tableInfo = data.page_info;
  }

  const selectedRows: any = {};

  if (category === 'overview') {
    data.stats.slice(0, 2).forEach((rowData: any) => {
      const rowId = graphKeyFields[category];

      selectedRows[rowData[rowId]] = {
        rowId: rowData[rowId],
        rowData,
      };
    });
  } else {
    const selectedRowCount = category === 'ios' ? 3 : 2;
    data.stats
      .slice(0, selectedRowCount)
      .forEach((rowData: any, idx: number) => {
        const rowId =
          category !== 'products'
            ? graphKeyFields[category as any]
            : params?.group_by;

        if (!rowData[rowId]) return;

        return (selectedRows[rowData[rowId]] = {
          rowId: rowData[rowId],
          rowData,
          selectOrder: idx,
        });
      });
  }

  if (category === 'creators' || category === 'categories') {
    state.ecommAnalytics.analyticsView.subRowData = {};
  }

  state.ecommAnalytics.analyticsView.selectedRows = selectedRows;
  state.ecommAnalytics.analyticsView.selectedColors = getColors(selectedRows);
  state.ecommAnalytics.analyticsView.tableIsLoading = false;
};

export const getTotalData = pipe(
  waitUntil((state: any) => state.ecommAnalytics.analyticsView.merchantId),
  async ({ state, actions, effects }: Context) => {
    const { category, merchantId } = state.ecommAnalytics.analyticsView;
    const { display_ios }: any = state.ecommAnalytics.analyticsView.params;
    const { allSites } = state.organization;
    if (!category) return;

    const endpoints: any = {
      products: `/api/v0/merchants/${merchantId}/strategies/aggregated_products_performance/`,
      'secondary-attribution': `/api/v0/merchants/${merchantId}/aggregated_secondary_attribution/`,
      ios: `/api/v0/merchants/${merchantId}/campaigns/aggregated_overview/`,
      campaigns: `/api/v0/merchants/${merchantId}/strategies/aggregated_overview/`,
    };
    const allSitesEndpoints: any = {
      ios: '/api/v0/merchants/campaigns/aggregated_overview/',
      campaigns: '/api/v0/merchants/strategies/aggregated_overview/',
      products: `/api/v0/merchants/strategies/aggregated_products_performance/`,
      'secondary-attribution': `/api/v0/merchants/aggregated_secondary_attribution/`,
    };
    const genericApi =
      !endpoints[category] || (category === 'ios' && !display_ios);
    // Totals data is included in stats request for product clicked, so we want to do an early return
    if (category === 'product-clicked') return;

    const getStats = (
      category:
        | ''
        | 'overview'
        | 'posts'
        | 'recommendations'
        | 'creators'
        | 'products'
        | 'product-clicked'
        | 'categories'
        | 'secondary-attribution'
        | 'campaigns-overview'
        | 'ios'
        | 'campaigns',
      result: any,
    ) => {
      const stats: any = {
        overview: result.data.data[0].stats,
        posts: result.data.data[0].stats,
        recommendations: result.data.data[0].stats,
        creators: result.data.data[0].stats,
        products: result.data.data[0].stats,
        'product-clicked': [],
        categories: result.data.data[0].stats,
        'secondary-attribution': result.data.data[0].stats[0],
        'campaigns-overview': result.data.data[0].stats,
        ios: result.data.data[0].stats,
        campaigns: result.data.data[0].stats,
      };

      return stats[category];
    };

    try {
      let endpoint;
      let params;
      if (genericApi) {
        endpoint = allSites
          ? `/api/v0/merchants/performance/`
          : `/api/v0/merchants/${merchantId}/performance/`;
        params = actions.ecommAnalytics.getGenericTableParams({ total: true });
      } else {
        endpoint = allSites ? allSitesEndpoints[category] : endpoints[category];
        params = actions.ecommAnalytics.getTableParams(true);
      }
      const result = await effects.api.get(endpoint, params);

      let stats = getStats(category, result);
      if (genericApi) {
        stats = stats[0];
      }

      state.ecommAnalytics.analyticsView.totalsData = stats;
    } catch (error) {
      console.error(error);
    }
  },
);

export const getGraphParams = ({ state, actions }: Context) => {
  const { category, params } = state.ecommAnalytics.analyticsView;
  const { getWeeklyRange } = actions.ecommAnalytics;
  const { formatDate } = actions.date;
  const { allSites } = state.organization;

  const fields = [
    'date_from',
    'date_to',
    'edit_id',
    'networks',
    'pub_ids',
    'strategies',
    'strategy_groups',
    'granularity',
  ];

  if (category === 'recommendations') {
    fields.push('auction_id');
  }

  if (category === 'products') {
    fields.push('product_ids');
  }

  const newParams = pick(state.ecommAnalytics.analyticsView.params, fields);

  if (params?.granularity === 'weekly') {
    const weeklyDates = getWeeklyRange({
      yearFrom: moment(params?.date_from).year(),
      yearTo: moment(params?.date_to).year(),
      weekFrom: moment(params?.date_from).week(),
      weekTo: moment(params?.date_to).week(),
    });
    newParams.date_from = formatDate({
      dateObj: weeklyDates.dateFrom,
      fmt: 'YYYY-MM-DD',
    });
    newParams.date_to = formatDate({
      dateObj: weeklyDates.dateTo,
      fmt: 'YYYY-MM-DD',
    });
  } else {
    newParams.date_from = params?.date_from;
    newParams.date_to = params?.date_to;
  }

  if (allSites) {
    const { accessList } = state.auth;
    newParams['merch_ids'] = accessList
      ?.filter((org) => org.type === 'merchants')
      ?.map((org) => org.orgId)
      ?.join(',');
  }

  return newParams;
};

export const getGenericGraphParams = ({ state, actions }: Context) => {
  const { category, params } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  const { getWeeklyRange } = actions.ecommAnalytics;
  const { formatDate } = actions.date;
  const query: any = {};
  const defaults = genericDefaults[category || 'overview'];

  query['group_by'] = allSites ? null : 'merch_id';
  query['granularity'] = params?.granularity || 'daily';
  query['order_by'] = 'event_date';

  let fields = defaults.graph_fields ? defaults.graph_fields : defaults.fields;
  if (params?.columns) {
    fields = fields.map((name: any) => {
      return genericApiFieldMap[name] || name;
    });
  }
  query['fields'] = fields.join(',');

  if (params?.date_from) {
    query['date_from'] = params.date_from;
  }
  if (params?.date_to) {
    query['date_to'] = params.date_to;
  }
  if (params?.auction_id) {
    query['auction_id'] = params.auction_id;
  }
  if (params?.pub_ids) {
    query['pub_id'] = params.pub_ids;
  }

  if (params?.granularity === 'weekly') {
    const weeklyDates = getWeeklyRange({
      yearFrom: moment(params?.date_from).year(),
      yearTo: moment(params?.date_to).year(),
      weekFrom: moment(params?.date_from).week(),
      weekTo: moment(params?.date_to).week(),
    });
    query['date_from'] = formatDate({
      dateObj: weeklyDates.dateFrom,
      fmt: 'YYYY-MM-DD',
    });
    query['date_to'] = formatDate({
      dateObj: weeklyDates.dateTo,
      fmt: 'YYYY-MM-DD',
    });
  } else {
    if (params?.date_from) {
      query['date_from'] = params.date_from;
    }
    if (params?.date_to) {
      query['date_to'] = params.date_to;
    }
  }

  if (params?.strategies) {
    query['strategy_id'] = params.strategies;
  }

  if (params?.strategy_groups) {
    query['strategy_group_id'] = params.strategy_groups;
  }

  if (params?.product_brand) {
    query['product_brand'] = params.product_brand;
  }
  if (params?.product_category) {
    query['product_category'] = params.product_category;
  }

  if (allSites) {
    const { accessList } = state.auth;
    query['merch_id'] = accessList
      ?.filter((org) => org.type === 'merchants')
      ?.map((org) => org.orgId)
      ?.join(',');
  }

  return query;
};

export const getYoyDateRange = ({ state, actions }: Context) => {
  const { granularity, date_from, date_to }: any =
    state.ecommAnalytics.analyticsView.params;
  const { offsetFromDate, formatDate } = actions.date;

  let dateFrom = null;
  let dateTo = null;

  if (granularity === 'weekly') {
    const weekFrom = moment(date_from).week();
    const weekTo = moment(date_to).week();
    const yearFrom = moment(date_from).year() - 1;
    const yearTo = moment(date_to).year() - 1;

    const weeklyDates = actions.ecommAnalytics.getWeeklyRange({
      weekFrom,
      weekTo,
      yearFrom,
      yearTo,
    });
    dateFrom = weeklyDates.dateFrom;
    dateTo = weeklyDates.dateTo;
  } else {
    dateFrom = offsetFromDate({
      originalDate: date_from,
      number: -1,
      unit: 'year',
    });

    dateTo = offsetFromDate({
      originalDate: date_to,
      number: -1,
      unit: 'year',
    });
  }

  return {
    dateFrom: formatDate({ dateObj: dateFrom, fmt: 'YYYY-MM-DD' }),
    dateTo: formatDate({ dateObj: dateTo, fmt: 'YYYY-MM-DD' }),
  };
};

export const getWeeklyRange = (
  context: Context,
  {
    weekFrom,
    weekTo,
    yearFrom,
    yearTo,
  }: { weekFrom: number; weekTo: number; yearFrom: number; yearTo: number },
) => {
  return {
    dateFrom: moment()
      .year(yearFrom)
      .week(weekFrom)
      .weekday(1)
      .startOf('day')
      .toString(),
    dateTo: moment()
      .year(yearTo)
      .week(weekTo)
      .weekday(1)
      .startOf('day')
      .toString(),
  };
};

export const getBarGraphData = pipe(
  waitUntil((state: any) => state.ecommAnalytics.analyticsView.merchantId),
  async ({ state, actions, effects }: Context) => {
    const { merchantId, graphYoY } = state.ecommAnalytics.analyticsView;
    const { allSites } = state.organization;
    const { offsetFromDate } = actions.date;

    const endpoint = allSites
      ? '/api/v0/merchants/performance/'
      : `/api/v0/merchants/${merchantId}/performance/`;
    const params = actions.ecommAnalytics.getGenericGraphParams();

    state.ecommAnalytics.analyticsView.graphParams = {
      date_from: params.date_from,
      date_to: params.date_to,
    };

    const rows = Object.values(STRATEGY_GROUP_TYPES).map(
      (groupType: string) => {
        return {
          ...params,
          strategy_group_type: groupType,
          date_from: params.date_from,
          date_to: params.date_to,
        };
      },
    );

    if (graphYoY) {
      const clonedRows = cloneDeep(rows).map((row) => {
        return {
          ...row,
          date_from: offsetFromDate({
            originalDate: row.date_from,
            unit: 'year',
            number: -1,
          }).format('YYYY-MM-DD'),
          date_to: offsetFromDate({
            originalDate: row.date_to,
            unit: 'year',
            number: -1,
          }).format('YYYY-MM-DD'),
        };
      });
      rows.push(...clonedRows);
    }

    const promises = rows.map((row: any) => effects.api.get(endpoint, row));
    const graphResp = await Promise.all(promises);

    state.ecommAnalytics.analyticsView.graphData = graphResp.map(
      (resp: any) => {
        return resp.data.data[0].stats;
      },
    );

    const ticks = actions.graph.getTicks({
      granularity: params.granularity,
      dateFrom: params.date_from,
      dateTo: params.date_to,
      skipDateParsing: true,
    });

    state.ecommAnalytics.analyticsView.graphTicks = ticks;

    const [graphedRows, rechartsGraphData] =
      actions.ecommAnalytics.processBarGraphData();

    state.ecommAnalytics.analyticsView.graphRows = graphedRows;
    state.ecommAnalytics.analyticsView.rechartsGraphData = rechartsGraphData;

    state.ecommAnalytics.analyticsView.graphIsLoading = false;
  },
);

export const processBarGraphData = ({ state, actions }: Context) => {
  const { graphData, graphTicks, chartType, selectedRows, graphYoY } =
    state.ecommAnalytics.analyticsView;

  const { offsetFromDate } = actions.date;

  const rows = Object.keys(selectedRows);
  const processedData = graphTicks?.map((tick) => {
    const entry: any = { event_date: tick };
    graphData?.forEach((datum: any, idx: number) => {
      const isYoY = graphYoY && idx >= rows.length;
      const baseRow = isYoY ? idx - rows.length : idx;
      const rowName = isYoY ? `${rows[baseRow]}Prev` : rows[baseRow];
      const relevantData = datum.find((x: any) => {
        if (isYoY) {
          return (
            x.event_date ===
            offsetFromDate({
              originalDate: tick,
              number: -1,
              unit: 'year',
            }).format('YYYY-MM-DD')
          );
        } else {
          return x.event_date === tick;
        }
      });
      entry[rowName] = parseFloat(relevantData?.[chartType as string]) || 0;
    });
    return entry;
  });

  const graphRows = rows.map((row) => {
    return {
      name: row,
    };
  });

  return [graphRows, processedData];
};

export const getGraphData = pipe(
  waitUntil((state: any) => state.ecommAnalytics.analyticsView.merchantId),
  async (
    { state, actions, effects }: Context,
    { rowIdField, noLoader }: { rowIdField: string; noLoader?: boolean },
  ) => {
    const { category, merchantId, compareYoy } =
      state.ecommAnalytics.analyticsView;

    const { allSites } = state.organization;

    if (!category) return;

    const { offsetFromDate, formatDate } = actions.date;
    const { granularity, group_by }: any =
      state.ecommAnalytics.analyticsView.params;
    state.ecommAnalytics.analyticsView.graphIsLoading = !noLoader;

    const endpoints: any = {
      products: `/api/v0/merchants/${merchantId}/strategies/products_interval_performance/`,
    };
    const allSitesEndpoints: any = {
      products: `/api/v0/merchants/strategies/products_interval_performance/`,
    };
    const genericApi = !endpoints[category];
    const selectedRowEntries: any = Object.entries(
      state.ecommAnalytics.analyticsView.selectedRows,
    );

    let graphLabelField: any;
    let graphKeyField: any;

    if (category === 'products') {
      graphLabelField = group_by;
      graphKeyField = group_by;
    } else {
      graphLabelField = graphLabelFields[category];
      graphKeyField = graphKeyFields[category];
    }

    try {
      let endpoint: string;
      let graphParams: any;
      if (genericApi) {
        endpoint = allSites
          ? `/api/v0/merchants/performance/`
          : `/api/v0/merchants/${merchantId}/performance/`;
        graphParams = actions.ecommAnalytics.getGenericGraphParams();
      } else {
        endpoint = allSites ? allSitesEndpoints[category] : endpoints[category];
        graphParams = actions.ecommAnalytics.getGraphParams();
      }

      const hasSelectedRows: boolean =
        Object.keys(selectedRowEntries).length > 0;
      const hasYoy = category === 'overview' && compareYoy;

      state.ecommAnalytics.analyticsView.graphParams = {
        date_from: graphParams.date_from,
        date_to: graphParams.date_to,
      };
      const graphs = hasSelectedRows
        ? selectedRowEntries.map(([, value]: any) => {
            const rowParams = { ...graphParams };
            if (
              state.ecommAnalytics.analyticsView.category === 'recommendations'
            ) {
              rowParams['auction_id'] = value.rowData.auction_id;
            }

            if (state.ecommAnalytics.analyticsView.category === 'products') {
              rowParams[group_by] = value.rowData[group_by];
            }

            if (state.ecommAnalytics.analyticsView.category === 'creators') {
              if (genericApi) rowParams['pub_id'] = value.rowData.pub_id;
              else rowParams['pub_ids'] = value.rowData.pub_id;
            }

            if (state.ecommAnalytics.analyticsView.category === 'posts') {
              rowParams['edit_id'] = value.rowData.edit_id;
            }

            return rowParams;
          })
        : [graphParams];
      const promises = graphs.map((g: any) => effects.api.get(endpoint, g));
      const graphResp = await Promise.all(promises);

      const graphData = graphResp.map((resp: any, index) => {
        const isYoyData = hasYoy && index === graphResp.length - 1;
        const stats = resp.data.data[0].stats.map((d: any) => {
          const newData = cloneDeep(d);
          if (isYoyData) {
            newData.event_date = formatDate({
              dateObj: offsetFromDate({
                originalDate: d.event_date,
                number: 1,
                unit: 'year',
              }),
              fmt: 'YYYY-MM-DD',
            });
          }
          newData.originalDate = d.event_date;
          return newData;
        });
        const selectedColors: any =
          state.ecommAnalytics.analyticsView.selectedColors;
        const graphInfo = hasSelectedRows
          ? selectedRowEntries[isYoyData ? 0 : index][1].rowData
          : null;
        return {
          data: stats,
          name: isYoyData
            ? formatDate({
                dateObj: offsetFromDate({
                  originalDate: graphInfo.event_date,
                  number: -1,
                  unit: 'year',
                }),
                fmt: 'YYYY',
              })
            : hasSelectedRows
            ? category === 'overview'
              ? moment(graphInfo.event_date).format('YYYY')
              : graphInfo[graphLabelField]
            : 'Total',
          strokeColor: isYoyData
            ? graphColors[1]
            : selectedColors.strokeColors[
                hasSelectedRows ? graphInfo[graphKeyField] : 'default'
              ],
          textColor: isYoyData
            ? textColors[1]
            : selectedColors.textColors[
                hasSelectedRows ? graphInfo[graphKeyField] : 'default'
              ],
          rowId: hasSelectedRows ? graphInfo[rowIdField] : 'total',
        };
      });

      const ticks = actions.graph.getTicks({
        granularity,
        dateFrom: graphParams.date_from,
        dateTo: graphParams.date_to,
      });

      const formattedGraphData = actions.graph.processEditGraphStats({
        respData: graphData,
        ticks,
        selectedGranularity: granularity,
        dateRange: {
          date_from: graphParams.date_from,
          date_to: graphParams.date_to,
        },
      });

      state.ecommAnalytics.analyticsView.graphTicks = ticks;
      state.ecommAnalytics.analyticsView.graphData = formattedGraphData;

      const [graphedRows, rechartsGraphData] =
        actions.ecommAnalytics.processRechartsData();

      state.ecommAnalytics.analyticsView.graphRows = graphedRows;
      state.ecommAnalytics.analyticsView.rechartsGraphData = rechartsGraphData;

      state.ecommAnalytics.analyticsView.graphIsLoading = false;
    } catch (error) {
      console.error(error);
    }
  },
);

export const getMerchantCategories = pipe(
  waitUntil((state: any) => state.ecommAnalytics.analyticsView.merchantId),
  async ({ state, actions, effects }: Context) => {
    const { merchantId, compareYoy, params } =
      state.ecommAnalytics.analyticsView;
    const { allSites } = state.organization;

    let earliestDate = params?.date_from;
    if (compareYoy && params?.compare_from && params.date_from) {
      if (params.compare_from < params.date_from) {
        earliestDate = params?.compare_from;
      }
    }

    const newParams: {
      date_from?: string;
      date_to?: string;
      group_by: string;
      fields: string;
      merch_id?: string;
    } = {
      date_from: earliestDate,
      date_to: params?.date_to,
      group_by: 'product_category',
      fields: 'product_category_count,product_category',
    };

    if (allSites) {
      const { accessList } = state.auth;
      newParams['merch_id'] = accessList
        ?.filter((org) => org.type === 'merchants')
        ?.map((org) => org.orgId)
        ?.join(',');
    }

    const endpoint = allSites
      ? '/api/v0/merchants/performance/'
      : `/api/v0/merchants/${merchantId}/performance/`;
    const result = await effects.api.get(endpoint, newParams);

    state.ecommAnalytics.analyticsView.merchantCategories =
      result.data.data[0].stats.map((category: any) => {
        let formattedLabel = category.product_category;
        if (!formattedLabel) {
          formattedLabel = 'Category Not Specified In Feed';
        } else {
          formattedLabel = category.product_category
            .split(' ')
            .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))
            .join(' ');
        }
        return { label: formattedLabel, value: category };
      });
  },
);

export const getMerchantBrands = pipe(
  waitUntil((state: any) => state.ecommAnalytics.analyticsView.merchantId),
  async ({ state, effects }: Context) => {
    const { merchantId } = state.ecommAnalytics.analyticsView;
    const { allSites } = state.organization;
    try {
      const endpoint = allSites
        ? '/api/v0/merchants/autocomplete/product_brand/'
        : `/api/v0/merchants/${merchantId}/autocomplete/product_brand/`;
      const results = await effects.api.get(endpoint);
      state.ecommAnalytics.analyticsView.merchantBrands =
        results.data.data[0].product_brand.map((brand: string) => {
          const formattedLabel = brand
            .split(' ')
            .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))
            .join(' ');
          return { label: formattedLabel, value: brand };
        });
    } catch (err) {
      console.error(err);
    }
  },
);

export const onRowSelect = (
  { state, actions }: Context,
  row: { rowData: any; rowId: number },
) => {
  const newSelectedRows = {
    ...state.ecommAnalytics.analyticsView.selectedRows,
  };
  const { getColors } = actions.ecommAnalytics;
  const rowKey = row.rowId;

  if (newSelectedRows[rowKey]) {
    const deletedOrder = newSelectedRows[rowKey].selectOrder;
    delete newSelectedRows[rowKey];
    Object.keys(newSelectedRows).forEach((rowKey) => {
      if (newSelectedRows[rowKey].selectOrder > deletedOrder) {
        newSelectedRows[rowKey].selectOrder -= 1;
      }
    });
  } else {
    newSelectedRows[rowKey] = {
      ...row,
      selectOrder: Object.keys(state.ecommAnalytics.analyticsView.selectedRows)
        .length,
    };
  }

  const colors: any = getColors(newSelectedRows);

  state.ecommAnalytics.analyticsView.selectedColors = colors;
  state.ecommAnalytics.analyticsView.selectedRows = newSelectedRows;
};

export const onAllRowSelect = ({ state }: Context, tmp: boolean) => {
  const { selectedRows, category } = state.ecommAnalytics.analyticsView;
  if (Object.keys(selectedRows).length > 0) {
    // Deselect All
    state.ecommAnalytics.analyticsView.selectedRows = {};
  } else {
    // Select all
    const rowId = graphKeyFields[category as any];
    const newRows: any = {};
    state.ecommAnalytics.analyticsView.tableData.forEach(
      (tableRow: any, idx: number) => {
        newRows[tableRow[rowId]] = {
          rowId: tableRow[rowId],
          rowData: tableRow,
          selectOrder: idx,
        };
      },
    );
    state.ecommAnalytics.analyticsView.selectedRows = newRows;
  }
};

export const onPubsSelect = ({ state }: Context, pubs: any) => {
  state.ecommAnalytics.analyticsView.selectedPubs = pubs;
  state.ecommAnalytics.analyticsView.filteredPubName =
    pubs.length === 1 ? pubs[0].label : '';
  (state.ecommAnalytics.analyticsView.params as any).pub_ids = pubs.length
    ? pubs.map((pub: { value: string }) => pub.value).join(',')
    : null;
};

export const onStrategiesSelect = (
  { state, actions }: Context,
  strategies: { label: string; value: any }[],
) => {
  const { merchantStrategyGroups } = state.ecommAnalytics;
  const { selectedStrategyGroups } = state.ecommAnalytics.analyticsView;
  const { onStrategyGroupsSelect } = actions.ecommAnalytics;

  const strategyIds = strategies.map((s) => s.value);

  state.ecommAnalytics.analyticsView.selectedStrategies = strategies;
  (state.ecommAnalytics.analyticsView.params as any).strategies =
    strategies.length ? strategyIds.join(',') : null;

  const strategyGroupMap = objectify(
    merchantStrategyGroups,
    (sg) => sg.strategy_group_id,
  );
  const newSelectedStrategyGroups: any = [];

  selectedStrategyGroups?.forEach((strategyGroup: any) => {
    const strategyGroupStrategies =
      strategyGroupMap[strategyGroup.value].strategies;
    if (
      strategyGroupStrategies.every((strategy: any) => {
        return strategyIds.includes(strategy.strategy_id);
      })
    ) {
      newSelectedStrategyGroups.push(strategyGroupMap[strategyGroup.value]);
    }
  });

  onStrategyGroupsSelect(
    newSelectedStrategyGroups.map((sg: any) => ({
      label: sg.strategy_group_name,
      value: sg.strategy_group_id,
    })),
  );
};

export const onStrategyGroupsSelect = (
  { state, actions }: Context,
  strategyGroups: { label: string; value: any }[],
) => {
  const { merchantStrategyGroups, merchantStrategies } = state.ecommAnalytics;
  const { formatStrategyOptions, setStrategyOptions, onStrategiesSelect } =
    actions.ecommAnalytics;

  if (
    isEqual(
      strategyGroups,
      state.ecommAnalytics.analyticsView.selectedStrategyGroups,
    )
  ) {
    return;
  }
  state.ecommAnalytics.analyticsView.selectedStrategyGroups = strategyGroups;
  (state.ecommAnalytics.analyticsView.params as any).strategy_groups =
    strategyGroups.length ? strategyGroups.map((s) => s.value).join(',') : '';

  const strategyGroupMap = objectify(
    merchantStrategyGroups,
    (sg) => sg.strategy_group_id,
  );

  let selectedStrategies: any[] = [];

  if (strategyGroups.length === 0) {
    setStrategyOptions(formatStrategyOptions(merchantStrategies));
  } else {
    strategyGroups.forEach((selectedStrategyGroup) => {
      selectedStrategies = selectedStrategies.concat(
        strategyGroupMap[selectedStrategyGroup.value].strategies,
      );
    });

    const formattedStrategies = formatStrategyOptions(selectedStrategies);
    setStrategyOptions(formattedStrategies);
    onStrategiesSelect(formattedStrategies);
  }
};

export const onColumnsSelect = (
  { state }: Context,
  columns: { label: string; value: string }[],
) => {
  (state.ecommAnalytics.analyticsView.params as any).columns = columns.length
    ? columns.map((col: { value: string }) => col.value).join(',')
    : null;

  state.ecommAnalytics.analyticsView.subRowData = {};
  state.ecommAnalytics.analyticsView.selectedColumns = [...columns];
};

export const onGranularitySelect = ({ state }: Context, granularity: any) => {
  (state.ecommAnalytics.analyticsView.params as any).granularity =
    granularity.value;
  state.ecommAnalytics.analyticsView.selectedGranularity = granularity;
};

export const onCategorySelect = (
  { state }: Context,
  categories: { label: string; value: string }[],
) => {
  (
    state.ecommAnalytics.analyticsView.params as any
  ).merchant_product_categories = categories.length
    ? categories.map((cat: { value: string }) => cat.value).join('|')
    : null;
  (state.ecommAnalytics.analyticsView.params as any).product_category =
    categories.length
      ? categories.map((cat: { value: string }) => cat.value).join(',')
      : null;
  state.ecommAnalytics.analyticsView.selectedMerchantCategories = [
    ...categories,
  ];
};

export const onBrandSelect = (
  { state }: Context,
  brands: { label: string; value: string }[],
) => {
  (state.ecommAnalytics.analyticsView.params as any).product_brand =
    brands.length
      ? brands.map((x: { value: string }) => x.value).join(',')
      : null;
  state.ecommAnalytics.analyticsView.selectedMerchantBrands = [...brands];
};

export const onTableSort = (
  { state }: Context,
  { order, field }: { order: 'desc' | 'asc'; field: string },
) => {
  (state.ecommAnalytics.analyticsView.params as any).order_by = field;
  (state.ecommAnalytics.analyticsView.params as any).direction = order;
};

export const onChartTypeSelect = ({ state }: Context, chartType: string) => {
  state.ecommAnalytics.analyticsView.chartType = chartType;
};

export const onGroupBySelect = ({ state }: Context, groupBy: any) => {
  (state.ecommAnalytics.analyticsView.params as any).group_by = groupBy.value;
  state.ecommAnalytics.analyticsView.selectedGroupBy = groupBy;
};

export const toggleGraph = ({ state }: Context) => {
  state.ecommAnalytics.analyticsView.showGraph =
    !state.ecommAnalytics.analyticsView.showGraph;
};

export const onStrategyGroupTypeSelect = (
  { state }: Context,
  strategyGroupTypes: { label: string; value: string }[],
) => {
  state.ecommAnalytics.analyticsView.selectedStrategyGroupTypes =
    strategyGroupTypes;
  (state.ecommAnalytics.analyticsView.params as any).strategy_group_type =
    strategyGroupTypes?.map((type: any) => type.value).join(',');
};

export const onNewGroupBySelect = ({ state }: Context, newGroupBy: any) => {
  // If deselecting all grouping dimensions, revert to a default value rather than have no grouping
  if (newGroupBy?.length === 0) {
    const category = state.ecommAnalytics.analyticsView.category as string;
    state.ecommAnalytics.analyticsView.newSelectedGroupBy = [
      defaultGrouping[category],
    ];
  } else {
    state.ecommAnalytics.analyticsView.newSelectedGroupBy = newGroupBy;
  }
};

export const getPerformanceReport = async (
  { actions, state, effects }: Context,
  { columns, ignorePagination }: { columns: any; ignorePagination?: boolean },
) => {
  state.ecommAnalytics.analyticsView.reportIsDownloading = true;

  const { merchantId } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  const csvEndpoint = allSites
    ? '/api/v0/merchants/performance/csv/'
    : `/api/v0/merchants/${merchantId}/performance/csv/`;
  const params = actions.ecommAnalytics.getGenericTableParams({
    total: false,
    report: true,
    ignorePagination,
  });

  const labels = params['fields'].split(',').map((field: string) => {
    return (
      columns.find((col: any) => {
        return col.dataKey === field;
      })?.label || field
    );
  });
  params['header'] = labels.join(',');
  params['totals'] = 'true';

  await effects.api
    .get(csvEndpoint, params, { responseType: 'blob' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'download.csv';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err: Error) => {
      console.error(err);
    })
    .finally(() => {
      state.ecommAnalytics.analyticsView.reportIsDownloading = false;
    });
};

// TODO: Need to standardize a type/interface for Column
export const formatPerformanceReport = (
  context: Context,
  {
    reportData,
    totalsData,
    columns,
  }: { reportData: string; totalsData?: string; columns: any },
) => {
  // Early exit
  if (!reportData || reportData.length === 0) return;

  const rows = reportData.trim().split('\r\n');

  // Grab headers
  const colHeaders = rows[0].split(',');

  // Format Column Header Labels using column defintions
  const formattedColHeaders = colHeaders
    .map((header) => {
      return (
        columns.find((col: any) => {
          return col.dataKey === header;
        })?.label || null
      );
    })
    .filter((header) => header !== null);

  // Grab data rows & format cells using column definitions
  const dataRows = rows.slice(1).map((row) => {
    const splitRow = row.split(',');
    return splitRow
      .map((col, idx) => {
        // Finds the associated column definition that we're looking at
        const column = columns.find(
          (colDef: any) => colDef.dataKey === colHeaders[idx],
        );

        // Early return if no column definition (not shown in UI)
        if (!column) return null;

        return formatReportValue({ value: col, column });
      })
      .filter((c) => c !== null);
  });

  // Start CSV data with formatted Column Headers
  let retData = `${formattedColHeaders.join(',')}\r\n`;

  // If there's totals data included, put that in before data
  if (totalsData != null) {
    const totalsRows = totalsData.trim().split('\r\n');
    const nonTotaledHeaders =
      formattedColHeaders.length - totalsRows[1].split(',').length;
    const formattedTotals = new Array(nonTotaledHeaders).fill('Total');
    totalsRows[1].split(',').forEach((total, idx) => {
      const column = columns.find(
        (colDef: any) => colDef.dataKey === totalsRows[0].split(',')[idx],
      );
      formattedTotals.push(formatReportValue({ value: total, column }));
    });
    retData += `${formattedTotals.join(',')}\r\n`;
  }

  // Add formatted data rows
  dataRows.forEach((row) => {
    retData += `${row.join(',')}\r\n`;
  });

  return retData;
};

export const getOverviewGraphData = async ({
  state,
  actions,
  effects,
}: Context) => {
  const { merchantId, compareYoy, params } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  if (!merchantId) return;

  try {
    const endpoint = allSites
      ? '/api/v0/merchants/performance/'
      : `/api/v0/merchants/${merchantId}/performance/`;
    const overviewParams = actions.ecommAnalytics.getGenericGraphParams();
    state.ecommAnalytics.analyticsView.graphIsLoading = true;

    const graphParams = {
      date_from: params?.date_from,
      date_to: params?.date_to,
    };

    const graphs = [overviewParams];

    if (compareYoy) {
      const yoyParams = cloneDeep(overviewParams) as AnalyticsParams;
      yoyParams.date_from = params?.compare_from;
      yoyParams.date_to = params?.compare_to;
      graphs.push(yoyParams);

      const graphDayLength = moment(params?.date_to).diff(
        moment(params?.date_from),
        'days',
      );

      const compareDayLength = moment(params?.compare_to).diff(
        moment(params?.compare_from),
        'days',
      );

      if (compareDayLength > graphDayLength) {
        graphParams.date_to = moment(params?.date_from)
          .add(compareDayLength, 'd')
          .format('YYYY-MM-DD');
      }
    }

    state.ecommAnalytics.analyticsView.graphParams = graphParams;

    const promises = graphs.map((g) => effects.api.get(endpoint, g));
    const graphResp = await Promise.all(promises);

    const graphData =
      actions.ecommAnalytics.processOverviewGraphResponse(graphResp);

    const ticks = actions.graph.getTicks({
      granularity: params?.granularity,
      dateFrom: graphParams.date_from,
      dateTo: graphParams.date_to,
    });

    const formattedGraphData = actions.graph.processEditGraphStats({
      respData: graphData,
      ticks,
      selectedGranularity: params?.granularity,
      dateRange: {
        date_from: graphParams.date_from,
        date_to: graphParams.date_to,
      },
    });

    state.ecommAnalytics.analyticsView.graphTicks = ticks;
    state.ecommAnalytics.analyticsView.graphData = formattedGraphData;

    const [graphedRows, rechartsGraphData] =
      actions.ecommAnalytics.processRechartsData();

    state.ecommAnalytics.analyticsView.graphRows = graphedRows;
    state.ecommAnalytics.analyticsView.rechartsGraphData = rechartsGraphData;
    state.ecommAnalytics.analyticsView.graphIsLoading = false;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const setCompareYoy = ({ state }: Context, compareYoy: boolean) => {
  state.ecommAnalytics.analyticsView.compareYoy = compareYoy;
};

export const toggleCompareYoy = ({ state }: Context) => {
  const { params } = state.ecommAnalytics.analyticsView;

  state.ecommAnalytics.analyticsView.compareYoy =
    !state.ecommAnalytics.analyticsView.compareYoy;
  state.ecommAnalytics.analyticsView.tableIsLoading = true;

  (state.ecommAnalytics.analyticsView.params as any).compare_from = moment(
    params?.date_from,
  )
    .subtract(1, 'years')
    .format('YYYY-MM-DD');

  (state.ecommAnalytics.analyticsView.params as any).compare_to = moment(
    params?.date_to,
  )
    .subtract(1, 'years')
    .format('YYYY-MM-DD');

  state.ecommAnalytics.analyticsView.subRowData = {};
};

export const processOverviewGraphResponse = (
  { state, actions }: Context,
  graphResp: { data: { data: any[] } }[],
) => {
  const { params, compareYoy } = state.ecommAnalytics.analyticsView;
  const { getGranularityShorthand } = actions.graph;

  const granularityShorthand = getGranularityShorthand(
    params?.granularity as any,
  );
  const firstDates = graphResp.map(
    (resp) => resp.data.data[0]?.stats[0]?.event_date,
  );

  let dateDifference: any;

  if (compareYoy) {
    dateDifference = compareYoy
      ? moment.duration(moment(firstDates[0]).diff(moment(firstDates[1])))
      : null;

    if (params?.granularity === 'yearly') {
      dateDifference = dateDifference?.asYears();
    } else if (params?.granularity === 'monthly') {
      dateDifference = dateDifference?.asMonths();
    } else if (params?.granularity === 'weekly') {
      dateDifference = dateDifference?.asWeeks();
    } else {
      dateDifference = dateDifference?.asDays();
    }
  }

  return graphResp.map((resp: any, index: number) => {
    const isYoyData = compareYoy && index === 1;

    const stats = resp.data.data[0].stats.map((d: any) => {
      const newData = cloneDeep(d);

      if (isYoyData) {
        newData.event_date = moment(d.event_date)
          .add(dateDifference, granularityShorthand)
          .format('YYYY-MM-DD');
      }

      newData.originalDate = d.event_date;
      return newData;
    });

    return {
      data: stats,
      strokeColor: isYoyData ? graphColors[1] : graphColors[0],
      textColor: isYoyData ? textColors[1] : graphColors[0],
      name: isYoyData ? 'Compared Total' : 'Total',
    };
  });
};

export const getPubByID = async (
  { effects }: Context,
  pubId: number | string,
) => {
  return await effects.api
    .get(`/api/v0/publishers/${pubId}/`)
    .then((resp: any) => Promise.resolve(resp.data.data[0]))
    .catch((err: Error) => console.error(err));
};

export const getEditById = async (
  { effects }: Context,
  editId: number | string,
) => {
  return await effects.api
    .get(`/api/v0/publishers/edit_info/`, { edit_ids: editId })
    .then((resp: any) => Promise.resolve(resp.data.data[0].edits[0]))
    .catch((err: Error) => console.error(err));
};

export const getCampaignById = async (
  { state, effects }: Context,
  strategyId: number | string,
) => {
  const { merchantId } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  const endpoint = allSites
    ? `/api/v0/merchants/strategies/${strategyId}/strategy_by_id/`
    : `/api/v0/merchants/${merchantId}/strategies/${strategyId}/strategy_by_id/`;
  return await effects.api
    .get(endpoint)
    .then((resp: any) => Promise.resolve(resp.data.data[0]))
    .catch((err: Error) => console.error(err));
};

export const getIoById = async (
  { state, effects }: Context,
  strategyGroupId: number | string,
) => {
  const { merchantId } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  const endpoint = allSites
    ? `/api/v0/merchants/strategy_groups/${strategyGroupId}/strategy_group_by_id/`
    : `/api/v0/merchants/${merchantId}/strategy_groups/${strategyGroupId}/strategy_group_by_id/`;
  return await effects.api
    .get(endpoint)
    .then((resp: any) => Promise.resolve(resp.data.data[0]))
    .catch((err: Error) => console.error(err));
};

export const setFilteredPubName = ({ state }: Context, name: string) => {
  state.ecommAnalytics.analyticsView.filteredPubName = name;
};

export const setCrumbData = (
  { state }: Context,
  { category, name }: { category: string; name: string },
) => {
  state.ecommAnalytics.analyticsView.crumbData[category] = name;
};

type GraphDatum = {
  year?: string | number;
  month?: string | number;
  date?: string | number;
  event_date: string;
  totalsData?: any;
  dayNumber?: number;
};

export const processRechartsData = (
  { state }: Context,
  args?: {
    graphDataProp?: any[];
    categoryProp?: string;
    chartTypeProp?: string;
  },
) => {
  const {
    graphData: graphDataState,
    category: categoryState,
    chartType: chartTypeState,
  } = state.ecommAnalytics.analyticsView;

  const graphData = args?.graphDataProp || graphDataState;
  const category = args?.categoryProp || categoryState;
  const chartType = args?.chartTypeProp || chartTypeState;

  if (!graphData || !graphData?.length) {
    // state might have been updated while this method was being called
    return [[], []];
  }

  // If a selected row has no data for the day, API will return no object for that date rather than an empty object
  // Because of this, we want to take the largest date range from the graphed rows and use that for looping
  let largestIdx = { length: 0, idx: 0 };

  // Format graphedRow data
  const graphedRows = graphData.map((row: any, idx: number) => {
    if (row.data.length > largestIdx.length)
      largestIdx = { length: row.data.length, idx };
    return {
      name: row.name,
      strokeColor: row.strokeColor,
      textColor: row.textColor,
      rowId: row.rowId,
    };
  });

  // Process data into correct shape for Recharts
  const rechartsGraphData = graphData[largestIdx.idx]?.data.map(
    (datum: any, idx: number) => {
      const eventDate = moment(datum.event_date);
      const obj: GraphDatum = {
        year: eventDate.year(),
        month: eventDate.month() + 1, // 0-indexed months
        date: eventDate.date(),
        event_date: datum.event_date,
        dayNumber: idx + 1, // we want to 1-index this data
      };
      graphData.forEach((rowObject: any) => {
        const dataIndex = rowObject.data.findIndex((rowDatum: any) => {
          return rowDatum.event_date === datum.event_date;
        });
        obj[(rowObject.rowId || rowObject.name) as keyof GraphDatum] =
          rowObject.data?.[dataIndex] && dataIndex !== -1
            ? parseFloat(rowObject.data[dataIndex][chartType as string])
            : null;

        // Overview graph shows all stats in tooltip, need to pass them all rather than just the graphed data
        if (category === 'overview') {
          obj[
            `${rowObject.name || rowObject.rowId}_stats` as keyof GraphDatum
          ] = rowObject.data?.[idx];
        }
      });
      return obj;
    },
  );

  return [graphedRows, rechartsGraphData];
};

export const setRechartsGraphData = (
  { state }: Context,
  { graphData, graphRows }: { graphData: any[]; graphRows?: any[] },
) => {
  state.ecommAnalytics.analyticsView.rechartsGraphData = graphData;

  if (graphRows) {
    state.ecommAnalytics.analyticsView.graphRows = graphRows;
  }
};

export const setSelectedStatusFilter = (
  { state }: Context,
  statusFilter: { label: string; value: string }[],
) => {
  (state.ecommAnalytics.analyticsView.params as any).strategy_group_status =
    statusFilter.map((stat: any) => stat.value).join(',');
  state.ecommAnalytics.analyticsView.selectedStatusFilter = statusFilter;
};

export const getTopBarInfo = async ({ actions, effects, state }: Context) => {
  const { merchantId } = state.ecommAnalytics.analyticsView;
  const { allSites } = state.organization;
  const topBarEndpoint = allSites
    ? '/api/v0/merchants/strategies/top_bar/'
    : `/api/v0/merchants/${merchantId}/strategies/top_bar/`;

  const params: any = {
    date_from: actions.date.getFirstOfMonth(moment()).format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  };
  const resp = await effects.api.get(topBarEndpoint, params);
  state.ecommAnalytics.analyticsView.topBarData =
    resp.data.data[0].top_bar_info;
};

export const setDisplayIos = ({ state }: Context, displayIos: boolean) => {
  // Toggle display IO State
  (state.ecommAnalytics.analyticsView.params as any).display_ios = displayIos;

  // Reset pagination
  (state.ecommAnalytics.analyticsView.params as any).page = 1;

  // Reset filter states that aren't available on IO Type view
  (state.ecommAnalytics.analyticsView.params as any).strategies = null;
  (state.ecommAnalytics.analyticsView.params as any).strategy_groups = null;
  (state.ecommAnalytics.analyticsView.params as any).strategy_group_status =
    null;
  (state.ecommAnalytics.analyticsView.params as any).pub_ids = null;
};

export const setGraphYoY = ({ state }: Context, graphYoY: boolean) => {
  state.ecommAnalytics.analyticsView.graphYoY = graphYoY;
};

export const setSearchQuery = ({ state }: Context, searchQuery: string) => {
  state.ecommAnalytics.analyticsView.searchQuery = searchQuery;
  (state.ecommAnalytics.analyticsView.params as any).search_query = searchQuery;
};

export const setSelectedObjective = (
  { state }: Context,
  objective: { label: string; value: string }[],
) => {
  state.ecommAnalytics.analyticsView.selectedObjective = objective;
  (state.ecommAnalytics.analyticsView.params as any).strategy_types = objective
    .map((obj) => obj.value)
    .join(',');
};

export const setSelectedStrategyStatus = (
  { state }: Context,
  status: { label: string; value: string }[],
) => {
  (state.ecommAnalytics.analyticsView.params as any).strategy_status = status
    .map((stat: any) => stat.value)
    .join(',');
  state.ecommAnalytics.analyticsView.selectedStrategyStatus = status;
};
