import { Context } from '..';

export const resetState = ({ state }: Context) => {
  state.ecommPartnerDiscovery = {
    partners: [],
    search: '',
    order_by: 'creator_tier_type',
    direction: 'desc',
    page: 1,
    per_page: 20,
    totalItems: null,
    categoryFilter: [],
    platformFilter: [],
    gmvFilter: 'all',
    reachFilter: 'all',
    partnerTypeFilter: [], // TODO: this should default to creator only?
    tierFilter: 'all',
    showAddPartners: null,
    showAddRemovePartner: null,
    lists: null,
    allCategories: [],
    loading: false,
    segmentFilter: [],
    listsLoading: false,
    initialCategorySet: false,
    skipNextCall: false,
    enrichmentsLoading: false,
    platformModalData: {
      showModal: false,
      platformData: {
        pub_id: null,
        platforms: [],
      },
    },
  };
};

export const getPartners = async (
  { effects, state }: Context,
  {
    merchId,
  }: {
    merchId: string;
  },
) => {
  if (state.ecommPartnerDiscovery.skipNextCall) {
    state.ecommPartnerDiscovery.skipNextCall = false;
    return;
  }

  state.ecommPartnerDiscovery.loading = true;

  const tierParams = {
    low: 'LOW',
    medium: 'MEDIUM',
    high: 'HIGH',
  };

  const params = {
    partner_name_contains: state.ecommPartnerDiscovery.search,
    order_by: state.ecommPartnerDiscovery.order_by,
    direction: state.ecommPartnerDiscovery.direction,
    page: state.ecommPartnerDiscovery.page,
    per_page: state.ecommPartnerDiscovery.per_page,
    categories:
      state.ecommPartnerDiscovery.categoryFilter.length > 0
        ? state.ecommPartnerDiscovery.categoryFilter.join('||')
        : null,
    partner_type: [0, 2].includes(
      state.ecommPartnerDiscovery.partnerTypeFilter.length,
    )
      ? null
      : state.ecommPartnerDiscovery.partnerTypeFilter.join(','),
    segment_dimensions:
      state.ecommPartnerDiscovery.segmentFilter.length > 0
        ? state.ecommPartnerDiscovery.segmentFilter.join(',')
        : null,
    show_top_categories: state.ecommPartnerDiscovery.initialCategorySet
      ? null
      : true,
    platforms:
      state.ecommPartnerDiscovery.platformFilter.length > 0
        ? state.ecommPartnerDiscovery.platformFilter.join(',')
        : null,
    creator_tier_types:
      state.ecommPartnerDiscovery.tierFilter === 'all'
        ? null
        : tierParams[state.ecommPartnerDiscovery.tierFilter],
  };
  const respData = await effects.api.get(
    `/api/v0/merchants/${merchId}/partner_discovery/`,
    params,
  );

  const categories = respData.data.data[0].categories;

  state.ecommPartnerDiscovery.allCategories =
    respData.data.data[0].all_categories;

  if (!state.ecommPartnerDiscovery.initialCategorySet) {
    state.ecommPartnerDiscovery = {
      ...state.ecommPartnerDiscovery,
      initialCategorySet: true,
      skipNextCall: categories.length > 0 ? true : false,
      categoryFilter: categories,
    };
  }

  const pageInfo = respData.data.data[0].page_info;

  state.ecommPartnerDiscovery.partners = respData.data.data[0].partners;
  state.ecommPartnerDiscovery.totalItems = pageInfo.total_items;

  state.ecommPartnerDiscovery.loading = false;
  state.ecommPartnerDiscovery.enrichmentsLoading = true;
};

export const getPartnerEnrichments = async (
  { effects, state }: Context,
  merchId: string,
) => {
  const pub_ids = state.ecommPartnerDiscovery.partners.map(
    (partner) => partner.pub_id,
  );

  if (pub_ids.length === 0) return;

  // TODO: Once min/max rate is fully deprecated on the backend, remove the legacy code and replace with a single column net_rate
  await effects.api
    .get(`/api/v0/merchants/${merchId}/partner_discovery_enrichments/`, {
      pub_ids: pub_ids.join(','),
    })
    .then((resp: any) => {
      const enrichments = resp.data.data[0].enrichments;

      state.ecommPartnerDiscovery.partners =
        state.ecommPartnerDiscovery.partners.map((partner) => {
          const partner_enrichments = enrichments[partner.pub_id];
          partner.min_commission_rate = partner_enrichments.rate;
          partner.max_commission_rate = partner_enrichments.rate;
          partner.socials = partner_enrichments.socials;
          partner.pub_category_lists = partner_enrichments.interests;
          if (!partner.pub_logo_url) {
            partner.pub_logo_url = partner_enrichments.logo_url;
          }
          return partner;
        });
    })
    .catch((err: Error) => {
      console.error(err);
    })
    .finally(() => {
      state.ecommPartnerDiscovery.enrichmentsLoading = false;
    });
};

export const getMerchantCategories = async (
  { effects, state }: Context,
  merchId: string,
) => {
  const params = {
    include_unspecified: true,
  };
  const respData = await effects.api.get(
    `/api/v0/merchants/${merchId}/categories/top/`,
    params,
  );

  const categories = respData.data.data[0].categories;

  state.ecommPartnerDiscovery.allCategories = categories.all;

  // if empty category filter on initial load, use top categories if there are any
  if (
    state.ecommPartnerDiscovery.categoryFilter.length === 0 &&
    categories.top &&
    categories.top.length > 0
  ) {
    state.ecommPartnerDiscovery.categoryFilter = categories.top;
  }
};

export const setSearch = ({ state }: Context, search: string) => {
  state.ecommPartnerDiscovery.search = search;
  state.ecommPartnerDiscovery.page = 1;
};

export const setOrderBy = ({ state }: Context, orderBy: string | null) => {
  state.ecommPartnerDiscovery.order_by = orderBy;
};

export const setDirection = (
  { state }: Context,
  direction: 'asc' | 'desc' | null,
) => {
  state.ecommPartnerDiscovery.direction = direction;
};

export const setPage = ({ state }: Context, page: number) => {
  state.ecommPartnerDiscovery.page = page;
};

export const setPerPage = ({ state }: Context, perPage: number) => {
  state.ecommPartnerDiscovery.per_page = perPage;
};

export const setTotalItems = ({ state }: Context, totalItems: number) => {
  state.ecommPartnerDiscovery.totalItems = totalItems;
};

export const setCategoryFilter = (
  { state }: Context,
  categoryFilter: string[],
) => {
  state.ecommPartnerDiscovery.categoryFilter =
    categoryFilter && categoryFilter.length > 0 ? categoryFilter : [];
  state.ecommPartnerDiscovery.page = 1;
};

export const setPartnerTypeFilter = (
  { state }: Context,
  partnerTypeFilter: string[],
) => {
  state.ecommPartnerDiscovery.partnerTypeFilter = partnerTypeFilter;
  state.ecommPartnerDiscovery.page = 1;
};

export const setPlatformFilter = ({ state }: Context, platforms: string[]) => {
  state.ecommPartnerDiscovery.platformFilter = platforms;
  state.ecommPartnerDiscovery.page = 1;
};

export const setReachFilter = (
  { state }: Context,
  reach: 'all' | 'low' | 'medium' | 'high' | 'veryHigh',
) => {
  state.ecommPartnerDiscovery.reachFilter = reach;
  state.ecommPartnerDiscovery.page = 1;
};

export const setGmvFilter = (
  { state }: Context,
  gmv: 'all' | 'low' | 'medium' | 'high' | 'veryHigh',
) => {
  state.ecommPartnerDiscovery.gmvFilter = gmv;
  state.ecommPartnerDiscovery.page = 1;
};

export const setShowAddPartners = async (
  { effects, state }: Context,
  partnerIds: number[] | null,
) => {
  state.ecommPartnerDiscovery.listsLoading = true;
  state.ecommPartnerDiscovery.showAddPartners = partnerIds;

  if (partnerIds && partnerIds?.length > 0) {
    const params = {
      merch_id: state.organization.selectedOrgId,
      scopes: 'account_scope',
      pub_id: partnerIds[0],
    };
    const respData = await effects.api.get(
      `/api/v0/merchants/target_lists/publisher/target_list_info/`,
      params,
    );
    state.ecommPartnerDiscovery.lists = respData.data.data[0].target_list_info;
  }
  state.ecommPartnerDiscovery.listsLoading = false;
};

export const setShowAddRemovePartner = async (
  { effects, state }: Context,
  partnerId: number | null,
) => {
  state.ecommPartnerDiscovery.listsLoading = true;
  state.ecommPartnerDiscovery.showAddRemovePartner = partnerId;

  if (partnerId) {
    const params = {
      merch_id: state.organization.selectedOrgId,
      scopes: 'account_scope',
      pub_id: partnerId,
    };
    const respData = await effects.api.get(
      `/api/v0/merchants/target_lists/publisher/target_list_info/`,
      params,
    );
    const targetListInfo = respData.data.data[0].target_list_info;
    targetListInfo.sort((a: any, b: any) => {
      return a.target_list_contains_element > b.target_list_contains_element
        ? -1
        : 1;
    });
    state.ecommPartnerDiscovery.lists = targetListInfo;
  }
  state.ecommPartnerDiscovery.listsLoading = false;
};

export const addPartnerToList = (
  { state, effects }: Context,
  {
    listId,
    partnerIds,
  }: {
    listId: number;
    partnerIds: number[];
  },
) => {
  const endpoint = `/api/v0/merchants/${state.organization.selectedOrgId}/target_lists/publisher/${listId}/update_target_list/`;

  return effects.api.put(endpoint, {
    target_list: {
      target_list_items: partnerIds,
      target_list_scope: 'account_scope',
      target_list_append: true,
    },
  });
};

export const removePartnerFromList = (
  { state, effects }: Context,
  {
    listId,
    partnerIds,
  }: {
    listId: number;
    partnerIds: number[];
  },
) => {
  const endpoint = `/api/v0/merchants/${state.organization.selectedOrgId}/target_lists/publisher/${listId}/update_target_list/`;

  return effects.api.put(endpoint, {
    target_list: {
      target_list_items: partnerIds,
      target_list_scope: 'account_scope',
      target_list_remove: true,
    },
  });
};

export const setLoading = ({ state }: Context, loading: boolean) => {
  state.ecommPartnerDiscovery.loading = loading;
};

export const setSegmentFilter = (
  { state }: Context,
  segmentFilter: string[],
) => {
  state.ecommPartnerDiscovery.segmentFilter = segmentFilter;
  state.ecommPartnerDiscovery.page = 1;
};

export const onRowSelect = (
  { state }: Context,
  row: { rowData: any; rowId: number },
) => {
  const newSelectedRows = {
    ...state.ecommPartnerDiscovery.selectedRows,
  };
  const rowKey = row.rowId;

  if (newSelectedRows[rowKey]) {
    delete newSelectedRows[rowKey];
  } else {
    newSelectedRows[rowKey] = row;
  }

  state.ecommPartnerDiscovery.selectedRows = newSelectedRows;
};

export const setSelectedRows = (
  { state }: Context,
  selectedRows: { pub_id?: boolean },
) => {
  state.ecommPartnerDiscovery.selectedRows = selectedRows;
};

export const setPlatformModalData = (
  { state }: Context,
  data: {
    showModal: boolean;
    platformData: { pub_id: number | null; platforms: any[] | null };
  },
) => {
  state.ecommPartnerDiscovery.platformModalData = data;
};

export const setInitialCategorySet = (
  { state }: Context,
  initialCategorySet: boolean,
) => {
  state.ecommPartnerDiscovery.initialCategorySet = initialCategorySet;
};

export const setSkipNextCall = ({ state }: Context, skipNextCall: boolean) => {
  state.ecommPartnerDiscovery.skipNextCall = skipNextCall;
};

export const setTierFilter = (
  { state }: Context,
  tier: 'all' | 'low' | 'medium' | 'high',
) => {
  state.ecommPartnerDiscovery.tierFilter = tier;
};
