import { useAppState, PermissionRoles } from '@frontend/howl-web-app/overmind';

type PermissionProps = {
  roles: PermissionRoles[];
  children: any;
};

export const Permission = ({ roles, children }: PermissionProps) => {
  const { role } = useAppState().account;
  const { sessionData } = useAppState().session;

  return (role && roles.includes(role)) || sessionData.isAdmin
    ? children
    : null;
};
