import classNames from 'classnames';

export enum View {
  SignUp = 0,
  EmailSignUp = 1,
  ExistingAccount = 2,
  PwEntry = 3,
  CompletedEmailSignUp = 4,
  AutoAccepted = 5,
}

export const getTextInputField = (
  inputLength: any,
  form: any,
  label: string,
  id: string,
  type: string,
) => {
  return (
    <>
      <div
        className={'w-full lg:mr-2 text-black focus:border-black cursor-text'}
      >
        <div
          className={classNames(
            'text-xs text-gray-500 -mb-7 mt-3 select-none px-4',
            {
              'hidden mt-1': inputLength === 0,
            },
          )}
        >
          {label}
        </div>
        <input
          type={type}
          id={id}
          name={id}
          placeholder={label}
          data-cy={id}
          className={classNames(
            'flex items-center justify-between w-full text-sm py-5 px-4 placeholder-gray-500 bg-deep-space-10 focus:bg-white outline-none border-deep-space-10 border rounded-xl focus:border-violet-300',
            {
              'pt-7 pb-3': inputLength > 0,
            },
            {
              '!border-[#B81818] !focus:border-[#B81818]':
                form.getInputProps(id).error,
            },
          )}
          {...form.getInputProps(id)}
          onChange={(event) => {
            form.getInputProps(id).onChange(event.target.value);
          }}
          onBlur={(event) => {
            // Trim whitespace on email inputs to prevent confusing validation errors
            if (id !== 'email') return;
            form.getInputProps(id).onChange(event.target.value.trim());
          }}
        />
      </div>
      {form.getInputProps(id).error && id !== 'primaryPlatformUrl' ? (
        <div className="text-quasar-400 mt-1 py-1 w-full flex items-center rounded-lg text-sm">
          {form.getInputProps(id).error}
        </div>
      ) : null}
    </>
  );
};

export const listItem = (text: string) => {
  if (text.length === 0) {
    return;
  }
  return (
    <div
      className={
        'lg:text-xl md:text-xl text-base mb-6 text-violet-500 inline-flex'
      }
    >
      <div className={'my-auto'}>
        <div
          className={
            'inline-block w-[22px] h-[22px] rotate-45 lg:-mt-1 lg:ml-4 ml-2 mr-1.5 align-middle'
          }
        >
          <div
            className={' w-6 h-6 bg-violet-300 rounded-[11px] top-0 left-0'}
          />
          <div
            className={
              'absolute w-[3px] h-3.5 bg-white top-[3.8px] left-[12px]'
            }
          />
          <div
            className={'absolute w-2 h-[3px] bg-white top-[15px] left-[6.9px]'}
          />
        </div>
      </div>
      <div className={'lg:ml-4 ml-2'}>{text}</div>
    </div>
  );
};
