import { isEmpty } from 'radash';
import { Context } from '..';
import { GaParams } from './state';

export const onInitializeOvermind = (context: Context) => {
  context.state.analytics.ga = (window as any).ga;
};

export const setUp = (
  context: Context,
  { isRelease, user }: { isRelease: any; user: any },
) => {
  // Opt-out of all analytics and tracking when not in release
  if (!isRelease) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[`ga-disable-UA-${context.state.analytics.propertyID}`] = true;
    return;
  }

  if (!user) return;

  context.state.analytics.ga('set', { '&uid': user.userId });
};

export const trackEvent = (
  context: Context,
  {
    category,
    action,
    label,
    params,
    type = 'event',
  }: {
    category: string;
    action: string;
    label: string;
    params?: any;
    type?: string;
  },
) => {
  if (isEmpty(context.state.analytics.ga)) {
    return;
  }
  params = context.actions.analytics.buildEventParams(params);
  const gaParams: GaParams = {};

  Object.keys(params).forEach((key) => {
    if (key in context.state.analytics.dimensionMap) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gaParams[context.state.analytics.dimensionMap[key]] = params[key]
        ? params[key].toString()
        : null;
    }
  });

  if (type === 'pageview') {
    context.state.analytics.ga('send', 'pageview', category, gaParams);
  } else {
    context.state.analytics.ga(
      'send',
      'event',
      category,
      action,
      label,
      gaParams,
    );
  }
};

export const buildEventParams = (context: Context, params: GaParams) => {
  params = params || {};
  if (context.state.session.sessionData) {
    Object.assign(params, {
      timestamp: new Date().toISOString(),
      is_admin: context.state.session.sessionData.isAdmin ? 'true' : 'false',
      org_id: context.state.session.sessionData.orgId,
      publisher_type: context.state.session.sessionData.publisher_type,
      user_role: context.state.session.sessionData.userRole,
      user_id: context.state.session.sessionData.userId,
    });
  }

  return params;
};
