import { Context } from '..';

export const queryFeedDifferenceCounts = (
  context: Context,
  merchantId: string,
) => {
  const endpoint = `/api/v0/merchant_feed/${merchantId}/difference_counts/`;
  return context.effects.api.get(endpoint).then((resp: any) => {
    const stats = resp.data.data.length ? resp.data.data[0] : [];
    return { stats: stats[merchantId] };
  });
};

export const queryFeedDiferencesByField = (
  context: Context,
  { merchantId, fieldName }: { merchantId: string; fieldName: string },
) => {
  const endpoint = `/api/v0/merchant_feed/${merchantId}/${fieldName}/differences/`;
  return context.effects.api.get(endpoint).then((resp: any) => {
    const stats = resp.data.data.length ? resp.data.data[0] : [];
    return { stats: stats[fieldName] };
  });
};

export const queryMerchantInfo = (context: Context) => {
  return context.effects.api.get(`/api/v0/merchants/`).then((resp: any) => {
    const info = resp.data.data.length ? resp.data.data : [];
    return { merchants: info };
  });
};
