export function useClass(...options: any) {
  const classes: string[] = [];

  options.forEach((option: any) => {
    if (!option) return;

    if (typeof option === 'string') {
      return classes.push(option);
    }

    Object.keys(option).forEach((key) => {
      if (option[key]) classes.push(key);
    });

    return;
  });

  return classes.join(' ');
}
