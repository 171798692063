import React from 'react';
import Link from 'next/link';

type Props = {
  isOperational: boolean;
  statusText: string;
  statusId: string;
  link?: string;
  showSemiOperational?: boolean;
};

const StatusItem = ({
  isOperational,
  statusText,
  statusId,
  link,
  showSemiOperational,
}: Props) => {
  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    return link ? (
      <Link href={link} legacyBehavior>
        <a
          data-cy={`status-item-${statusId}`}
          aria-label={`${statusText} ${
            isOperational ? 'is operational' : 'is not operational'
          }`}
          className="text-gray-300 flex pb-3"
        >
          {children}
        </a>
      </Link>
    ) : (
      <div
        data-cy={`status-item-${statusId}`}
        aria-label={`${statusText} ${
          isOperational ? 'is operational' : 'is not operational'
        }`}
        className="text-gray-300 flex pb-3"
      >
        {children}
      </div>
    );
  };

  return (
    <Wrapper>
      <div
        data-cy="status-indicator"
        className={`${
          isOperational
            ? 'bg-green-600 border-green-800'
            : showSemiOperational
            ? 'bg-yellow-600 border-yellow-800'
            : 'bg-red-600 border-red-800'
        } rounded-full`}
        style={{ borderWidth: '3px', width: '13px', height: '13px' }}
      />
      <div className="pl-2">{statusText}</div>
    </Wrapper>
  );
};

export default StatusItem;
