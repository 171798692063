import Head from 'next/head';
import classNames from 'classnames';

import { useActions, useAppState } from '@frontend/howl-web-app/overmind';
import { NavSidebar } from '../../base/navigation/sidebar/nav-sidebar';
import NavHeader from '../../base/navigation/header/nav-header';
import { HelpWidget } from '@frontend/shared-ui-kit/help-widget';
import { Snackbar } from '@frontend/shared-ui-kit/snackbar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { VerifyEmailBanner } from '@frontend/howl-web-app/feature-main';
import { FeatureFlagWidget } from '@frontend/shared-ui-kit/feature-flag-widget';
import { isEmpty } from 'radash';

export const DefaultLayout = ({ children }: any) => {
  const { sidebarOpen, snackbar, snackbarIter, helpWidgetVisible } =
    useAppState().ui;
  const { clearSnackbar } = useActions().ui;
  const flags = useFlags();

  const { selectedOrgType, selectedOrg } = useAppState().organization;
  const displayName = !isEmpty(selectedOrg)
    ? selectedOrg.full_name
      ? `${selectedOrg.full_name}`
      : `${selectedOrg.short_name}`
    : '';

  return (
    <>
      <Head>
        <title>{children.type.title || 'Howl'}</title>
      </Head>

      {/* {flags && flags['zendesk'] && <HelpWidget />} */}
      {flags && flags['zendesk'] && helpWidgetVisible ? (
        <HelpWidget name={displayName} />
      ) : null}

      <FeatureFlagWidget />
      <div
        className={classNames(
          'flex flex-col bg-neutral-gray-10 pb-safe h-full',
          `${sidebarOpen ? 'overflow-hidden' : ''}`,
        )}
      >
        <NavHeader />

        <div className={'flex flex-row flex-1 relative w-full h-full'}>
          <NavSidebar />
          <div
            className={classNames(
              'flex-1 flex flex-col items-end shadow-3xl shadow-inner transition-all pt-16 w-full h-full',
            )}
          >
            <div
              id="page-container"
              className={classNames(
                'relative sm:bg-neutral-gray-10 sm:pb-0 w-full transition-all h-full',
                {
                  'md:w-[calc(100%-16rem)] delay-150': sidebarOpen,
                },
              )}
            >
              <VerifyEmailBanner />
              {children}
            </div>
          </div>

          {snackbar && (
            <Snackbar
              {...snackbar}
              key={snackbarIter}
              onClose={() => clearSnackbar()}
            />
          )}
        </div>
      </div>
    </>
  );
};
