import React from 'react';
import { useActions, useAppState, useEffects } from '../index';

// this whole HOC is a cludge to pass our hooks to class components until they're rewritten as functional components
export function withOvermind<T>(
  WrappedComponent: React.FunctionComponent<T> | React.ComponentClass<T>,
): React.FunctionComponent<T> {
  const WithOvermind = (props: T) => {
    const state = useAppState();
    const actions = useActions();
    const effects = useEffects();

    return (
      <WrappedComponent overmind={{ state, actions, effects }} {...props} />
    );
  };

  WithOvermind.displayName = `withOvermind()`;

  return WithOvermind;
}
