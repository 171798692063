import { Session } from 'next-auth';
import Router from 'next/router';

export const getAuthenticatedStartingUrlPath = (sessionData: any) => {
  const userRole = sessionData.userRole.replace(/s$/, '');
  const orgId = sessionData.orgId ? `${sessionData.orgId}/` : '';
  const orgPath = `${userRole}/${orgId}`;

  return getDefaultPath(userRole, orgPath);
};

export const getNextAuthStartingUrlPath = (session: Session) => {
  const orgs = Object.keys(session?.user?.bamx_perms);
  let orgId;
  if (orgs.length) {
    const firstOrg = Object.keys(session?.user?.bamx_perms[orgs[0]]);
    orgId = firstOrg[0];
  } else {
    orgId = null;
  }
  const orgPath = `${session?.user?.role}/${orgId}/`;
  return getDefaultPath(session?.user?.role, orgPath);
};

export const programaticNavigation = (url: any) => {
  return Router.push(url);
};

const getDefaultPath = (userRole: string, orgPath: string) => {
  let path;

  switch (userRole) {
    case 'publisher':
      path = `/${orgPath}dashboard`;
      break;
    case 'merchant':
      path = `/${orgPath}desk`;
      break;
    case 'admin':
      path = '/admin/creator-workspace/content-manager';
      break;
    case 'product_match':
      path = '/admin/smartlinks/editor';
      break;
    default:
      path = `/`;
  }
  return path;
};
