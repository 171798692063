import { Context } from '..';
import { v4 as uuid } from 'uuid';

import { Rule } from './state';
import { without } from 'lodash';

const getTargetListTypeField = (type: string) => {
  const listTypeField: { [k: string]: string } = {
    sku: 'target_rule_sku_list_id',
    brand: 'target_rule_brand_list_id',
    google_category: 'target_rule_google_category_list_id',
    merchant_category: 'target_rule_merchant_category_list_id',
    publisher: 'target_rule_publisher_list_id',
    supplier_type: 'target_rule_supplier_type',
    sale_threshold: 'target_rule_sale_threshold',
  };

  return listTypeField[type];
};

type CommissionOverride = {
  roi: number | null;
  cogs: number | null;
};

const getTargetTypeByField = (field: string) => {
  const listFieldType: { [k: string]: string } = {
    target_rule_sku_list_id: 'sku',
    target_rule_brand_list_id: 'brand',
    target_rule_google_category_list_id: 'google_category',
    target_rule_merchant_category_list_id: 'merchant_category',
    target_rule_publisher_list_id: 'publisher',
    target_rule_supplier_type: 'supplier_type',
    target_rule_sale_threshold: 'sale_threshold',
  };

  return listFieldType[field];
};

export const setMerchant = ({ state }: Context, merchantId: number) => {
  state.ecommCartExclusions.merchantId = merchantId;
};

export const resetState = ({ state }: Context) => {
  state.ecommCartExclusions = {
    merchantId: state.ecommCartExclusions.merchantId,
    merchantFeeRate: null,
    strategy_id: null,
    targetExpressionId: null,
    targeting: {
      targetExpression: [[[]]],
      targetRules: {},
      targetExpressionRateOverride: [],
    },
  };
};

export const getTargetExpressionById = async (
  { state, effects }: Context,
  expressionId: string,
) => {
  const endpoint = `/api/v0/merchants/${state.ecommCartExclusions.merchantId}/cart_exclusion/${expressionId}/target_expression_by_id/`;

  try {
    const response = await effects.api.get(endpoint);
    return response.data.data[0];
  } catch (error) {
    console.error(error);
  }
};

export const getTargetListById = async (
  { state, effects }: Context,
  {
    targetListType,
    targetListId,
  }: { targetListType: string; targetListId: any },
) => {
  const endpoint = `/api/v0/merchants/${state.ecommCartExclusions.merchantId}/target_lists/${targetListType}/${targetListId}/target_list_by_id/`;

  //  Arbitrarily large per_page value to avoid paging for now.
  //  SKU dropdown will need rework before paging is functional
  try {
    const response = await effects.api.get(endpoint, { per_page: 50000 });
    return response.data.data[0];
  } catch (error) {
    console.error(error);
  }
};

export const getCartExclusion = async (
  { state, effects, actions }: Context,
  exclusionId?: string | null,
) => {
  const endpoint = `/api/v0/merchants/${
    state.ecommCartExclusions.merchantId
  }/cart_exclusion/${
    exclusionId ? exclusionId + '/target_expression_by_id/' : ''
  }`;

  try {
    const response = await effects.api.get(endpoint);

    const targetExpression = response.data.data[0].target_expression;
    // const targetList = response.data.data[0].target_list;
    const targetRules = response.data.data[0].target_rules;
    const targetExpressionRateOverride =
      response.data.data[0].target_expression_rate_override;

    const { formatTargetRule } = actions.ecommCartExclusions;

    // expressionFormat is the 3-level nested array with references to the targeting rules
    const expressionFormat = JSON.parse(targetExpression) || [];
    if (!expressionFormat || expressionFormat.length === 0) {
      expressionFormat.push([[]]);
    }

    state.ecommCartExclusions.targeting.targetExpression = expressionFormat;
    state.ecommCartExclusions.targeting.targetExpressionRateOverride =
      targetExpressionRateOverride;
    const existingExclusionId = response.data.data[0].target_expression_id;

    // targetRules is an array of:
    // target_rule_brand_list_id: null
    // target_rule_google_category_list_id: null
    // target_rule_id: 132918
    // target_rule_link_creation_datetime: null
    // target_rule_merchant_category_list_id: null
    // target_rule_operator: "_in"
    // target_rule_publisher_list_id: 122969
    // target_rule_sku_list_id: null
    // target_rule_supplier_link_type: null
    // target_rule_supplier_type: null

    return Promise.all(
      targetRules.map((rule: any) => formatTargetRule(rule)),
      // this map returns an array of these:
      // {
      //   target_rule_id: rule.target_rule_id,
      //   target_list_type: targetListType,
      //   target_rule_operator: rule.target_rule_operator,
      //   target_list_items: targetListItems,
      //   target_list: targetList,
      //   target_list_id: targetListId,
      //   target_count: targetCount,
      // };
    ).then((targetRuleValues) => {
      const exclusionValues: any = {};

      targetRuleValues.forEach((rule: any) => {
        if (rule) {
          exclusionValues[rule.target_rule_id] = rule;
        }
      });

      const commissionOverrides: CommissionOverride[] =
        targetExpressionRateOverride
          ? targetExpressionRateOverride.map((override: any) => {
              // if override is null, map roi and cogs values to null
              return override ? override : { roi: null, cogs: null };
            })
          : expressionFormat.map(() => {
              // if targetExpressionRateOverride is null, this is a legacy cart exclusion
              // map all the cart exclusions to null roi and cogs values
              return { roi: null, cogs: null };
            });

      return {
        existingExclusionId,
        exclusionValues,
        targetExpressionRateOverride: commissionOverrides,
      };
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const saveCommissionOverride = async (
  { state, actions, effects }: Context,
  {
    targetValues,
    commissionValues,
    exclusionId,
  }: {
    targetValues: any;
    commissionValues: any;
    exclusionId: string;
  },
) => {
  const targetExpressionRateOverrideMap: any = [];
  Object.values(commissionValues).map((val: any) => {
    if (val && val['cogs'] == null) {
      targetExpressionRateOverrideMap.push(null);
    } else if (val) {
      targetExpressionRateOverrideMap.push(val);
    }
  });
  const targetRulesData = await Promise.all(
    Object.entries(targetValues).map(
      async ([targetRuleId, listValues]: any[]) => {
        const { target_list_type, target_list_id, target_rule_operator } =
          listValues;

        const targetPayload = target_list_id;

        return {
          targetRuleId,
          targetPayload,
          targetOperator: target_rule_operator,
          targetListType: target_list_type,
        };
      },
    ),
  );
  const { formatTargetRulesPayload } = actions.ecommCartExclusions;
  const { api } = effects;

  const createEndpoint = `/api/v0/merchants/${state.ecommCartExclusions.merchantId}/cart_exclusion/create_target_expression/`;
  const updateEndpoint = `/api/v0/merchants/${state.ecommCartExclusions.merchantId}/cart_exclusion/${exclusionId}/update_target_expression/`;

  const targetRulesMap: any = {};
  targetRulesData.forEach(
    ({ targetPayload, targetRuleId, targetOperator, targetListType }: any) => {
      const retVal = {
        [getTargetListTypeField(targetListType)]: [
          'link_date',
          'supplier_link_type',
          'supplier_type',
        ].includes(targetListType)
          ? targetPayload
          : targetListType === 'sale_threshold'
          ? parseFloat(targetPayload) / 100
          : parseInt(targetPayload),
        target_rule_operator: targetOperator,
      };

      targetRulesMap[targetRuleId] = retVal;

      return retVal;
    },
  );

  const payload: {
    target_expression: {
      strategy_id?: number;
      target_rules: any;
      target_expression_rate_override: any;
    };
  } = {
    target_expression: {
      target_rules: formatTargetRulesPayload(targetRulesMap),
      target_expression_rate_override: targetExpressionRateOverrideMap,
    },
  };

  return exclusionId
    ? Promise.resolve(api.put(updateEndpoint, payload))
    : Promise.resolve(api.post(createEndpoint, payload));
};

export const addCartExclusion = ({ state }: Context) => {
  const { targetExpression } = state.ecommCartExclusions.targeting;
  state.ecommCartExclusions.targeting.targetExpression = [
    ...targetExpression,
    [[[`new-${uuid()}`]]],
  ];
};

export const addTargetingGroup = ({ state }: Context, exclusionIdx: number) => {
  const { targetExpression } = state.ecommCartExclusions.targeting;
  state.ecommCartExclusions.targeting.targetExpression[exclusionIdx] = [
    ...targetExpression[exclusionIdx],
    [],
  ];
};

export const removeTargetingGroup = (
  { state }: Context,
  {
    exclusionIdx,
    targetGroupIndex,
  }: { exclusionIdx: number; targetGroupIndex: number },
) => {
  const { targetExpression } = state.ecommCartExclusions.targeting;

  const newList = [...targetExpression[exclusionIdx]];
  newList.splice(targetGroupIndex, 1);

  state.ecommCartExclusions.targeting.targetExpression[exclusionIdx] = newList;
};

export const removeExclusion = ({ state }: Context, exclusionIdx: number) => {
  const { targetExpression, targetExpressionRateOverride } =
    state.ecommCartExclusions.targeting;

  const newExclusions = [...targetExpression];
  newExclusions.splice(exclusionIdx, 1);

  let newRateOverrides: any[] = [];
  if (targetExpressionRateOverride) {
    newRateOverrides = [...targetExpressionRateOverride];
    newRateOverrides.splice(exclusionIdx, 1);
  }

  state.ecommCartExclusions.targeting.targetExpression = newExclusions;
  state.ecommCartExclusions.targeting.targetExpressionRateOverride =
    newRateOverrides;
};

export const addTargetingRule = (
  { state }: Context,
  {
    exclusionIdx,
    targetGroup,
    targetId,
  }: { exclusionIdx: number; targetGroup: number; targetId: string },
) => {
  const { targetExpression } = state.ecommCartExclusions.targeting;

  const newList = targetExpression[exclusionIdx]
    ? [...targetExpression[exclusionIdx]]
    : [];

  if (!newList[targetGroup]) {
    newList[targetGroup] = [];
  }
  newList[targetGroup].push(targetId);
  state.ecommCartExclusions.targeting.targetExpression[exclusionIdx] = newList;
};

export const addCommissionRateOverride = (
  { state }: Context,
  { targetId }: { targetId: string },
) => {
  const { targetExpressionRateOverride } = state.ecommCartExclusions.targeting;

  const newList = targetExpressionRateOverride
    ? [...targetExpressionRateOverride]
    : [];
  newList.push(targetId);

  state.ecommCartExclusions.targeting.targetExpressionRateOverride = newList;
};

export const removeTargetingRule = (
  { state }: Context,
  {
    exclusionIdx,
    targetGroup,
    targetId,
  }: { exclusionIdx: number; targetGroup: number; targetId: string },
) => {
  const { targetExpression } = state.ecommCartExclusions.targeting;

  const newList = without(
    [...targetExpression[exclusionIdx][targetGroup]],
    targetId,
  );

  state.ecommCartExclusions.targeting.targetExpression[exclusionIdx][
    targetGroup
  ] = newList;
};

export const removeTargetingRuleTargetingExample = (
  { state }: Context,
  { targetGroup, targetId }: { targetGroup: number; targetId: string },
) => {
  const { targetExpression } = state.ecommStrategyCreation.targeting;

  const newList = without([...targetExpression[targetGroup]], targetId);

  state.ecommStrategyCreation.targeting.targetExpression[targetGroup] = newList;
};

export const formatTargetRule = async ({ actions }: Context, rule: Rule) => {
  // rule is an object like: {
  // target_rule_brand_list_id: null
  // target_rule_google_category_list_id: null
  // target_rule_id: 132918
  // target_rule_link_creation_datetime: null
  // target_rule_merchant_category_list_id: null
  // target_rule_operator: "_in"
  // target_rule_publisher_list_id: 122969
  // target_rule_sku_list_id: null
  // target_rule_supplier_link_type: null
  // target_rule_supplier_type: null
  // }

  const typeIdPair = Object.entries(rule).filter(([listType, listId]) => {
    return (
      !['target_rule_operator', 'target_rule_id'].includes(listType) &&
      listId !== null
    );
  })[0];

  if (!typeIdPair) {
    return null;
  }

  const targetListType = getTargetTypeByField(typeIdPair[0]);
  const targetListId = typeIdPair[1];

  if (targetListType === 'supplier_type') {
    return {
      target_rule_id: rule.target_rule_id,
      target_list_type: targetListType,
      target_rule_operator: rule.target_rule_operator,
      target_list_items: null,
      target_list: null,
      target_list_id: targetListId,
      target_count: 0,
    };
  } else if (targetListType === 'sale_threshold') {
    return {
      target_rule_id: rule.target_rule_id,
      target_list_type: targetListType,
      target_rule_operator: rule.target_rule_operator,
      target_list_items: null,
      target_list: null,
      target_list_id: ((targetListId as number) || 0) * 100,
      target_count: 0,
    };
  } else {
    return actions.ecommCartExclusions
      .getTargetListById({ targetListType, targetListId })
      .then((targetData: any) => {
        const targetList = targetData.target_list;
        const targetCount = targetData.total_items;

        const targetListItems = targetList.map((item: any) => {
          return item[`target_${targetListType}`];
        });

        return {
          target_rule_id: rule.target_rule_id,
          target_list_type: targetListType,
          target_rule_operator: rule.target_rule_operator,
          target_list_items: targetListItems,
          target_list: targetList,
          target_list_id: targetListId,
          target_count: targetCount,
        };
      });
  }
};

export const formatTargetRulesPayload = (
  { state }: Context,
  targetRulesMap: { [k: string]: any },
) => {
  const { targetExpression } = state.ecommCartExclusions.targeting;
  let payload: any = [];

  targetExpression.forEach((exclusion: any, exclusionIdx: number) => {
    payload.push([]);
    exclusion.forEach((group: any, idx: number) => {
      if (group.length) {
        payload[exclusionIdx].push([]);
        group.forEach((ruleId: string) => {
          payload[exclusionIdx][idx].push(targetRulesMap[ruleId]);
        });
      }
    });
  });

  payload = payload.filter((exclusion: any[]) => exclusion.length);

  return payload;
};

export const getMerchantById = async (
  { effects }: Context,
  merchantId: number,
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/`;

  const merchData = await effects.api.get(endpoint);
  return merchData.data.data[0];
};

export const setMerchantFeeRate = (
  { state }: Context,
  feeRate: number | null,
) => {
  state.ecommCartExclusions.merchantFeeRate = feeRate;
};
