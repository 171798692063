import { Context } from '..';
import { sortAlphabetical } from '@frontend/shared-utils';

const httpUrl =
  typeof window !== 'undefined' &&
  (window.location.hostname === process.env['NEXT_PUBLIC_DEMO_HOSTNAME'] ||
    (!process.env['NEXT_PUBLIC_VERCEL_ENV'] &&
      process.env['NODE_ENV'] === 'production'))
    ? process.env['NEXT_PUBLIC_DEMO_API_URL']
    : process.env['NEXT_PUBLIC_HOST'];

const baseEndpoint = `${httpUrl}${process.env['NEXT_PUBLIC_API_BASE_URL']}`;
const adminEndpoint = `${baseEndpoint}${process.env['NEXT_PUBLIC_ADMIN_API_ENDPOINT']}`;

const base = {
  clicks: {
    label: 'Clicks',
    name: 'clicks',
    selected: true,
  },
  pacing_towards: {
    label: 'Pacing Towards',
    name: 'pacing_towards',
    type: 'currency',
    selected: true,
  },
  average_roi: {
    label: 'ROI',
    name: 'average_roi',
    type: 'roi',
    selected: true,
  },
  short_name: {
    label: 'Merchants',
    name: 'short_name',
    type: 'string',
    selected: true,
  },
  // TODO: Remove this once budget overhaul project is rolled out with no risk of revert
  // 'daily_budget_amount': {
  // 	label: 'Daily Budget', name: 'daily_budget_amount', type: 'currency', selected: true,
  // },
  monthly_budget_amount: {
    label: 'Monthly Budget',
    name: 'monthly_budget_amount',
    type: 'currency',
    selected: true,
  },
  average_cpc: {
    label: 'CPC',
    name: 'average_cpc',
    type: 'currency',
    selected: true,
  },
  merchants_revenue: {
    label: 'Revenue',
    name: 'merchants_revenue',
    type: 'currency',
    selected: true,
  },
  mtd_spend: {
    label: 'MTD Spend',
    name: 'mtd_spend',
    type: 'currency',
    selected: true,
  },
};
const columns = [
  base.short_name,
  {
    label: 'Target ROI',
    name: 'target_roi',
    type: 'roi',
    selected: true,
  },
  {
    label: 'Actual ROI',
    name: 'reconciled_roi',
    type: 'roi',
    selected: true,
  },
  base.monthly_budget_amount,
  { ...base.pacing_towards, label: 'Spend Pacing' },
  base.mtd_spend,
  {
    label: 'Current Spend',
    name: 'spend',
    type: 'currency',
    selected: true,
  },
  {
    label: 'GMV',
    name: 'revenue',
    type: 'currency',
    selected: true,
  },
  {
    label: 'Take Rate',
    name: 'take_rate',
    type: 'percent',
    selected: true,
  },
  {
    label: 'Earnings',
    name: 'earnings',
    type: 'currency',
    selected: true,
  },
  base.clicks,
  { ...base.average_cpc, label: 'Avg CPC' },
];

// Queries endpoint for pub/merch overview data
export const getOverviewStats = (
  context: Context,
  { statType, params }: { statType: any; params: any },
) => {
  const suffixes = {
    'merchant-overview': 'merchants',
    'publisher-overview': 'publishers',
    'merch-ed-overview': 'merch_ed',
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const endpoint = `${adminEndpoint}stats/${suffixes[statType]}/`;
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    if (statType !== 'merch-ed-overview') {
      return context.actions.bid.processOverviewStats({
        data: resp.data.data,
        statType,
        params,
      });
    }
    return context.actions.bid.processMerchEdStats({
      data: resp.data.data[0],
      params,
    });
  });
};

// Parse and normalize the data for calculations
export const processOverviewStats = (
  context: Context,
  { data, statType, params }: { data: any; statType: any; params: any },
) => {
  const monthElapsed = context.actions.date.monthElapsed();

  const statTemplate = {
    clicks: 0,
    impressions: 0,
    spend: 0,
    mtd_spend: 0,
    roi_goal: 0,
    cpc_goal: 0,
    monthly_budget_amount: 0,
    daily_budget_amount: 0,
    percent_month_remaining: 0,
    merchants_revenue: 0,
    merchant_spend: 0,
    publisher_revenue: 0,
  };

  const processedData = data.map((stat: any) => {
    const normalizedStat: any = { ...statTemplate };
    Object.keys(normalizedStat).forEach(
      (key) => (normalizedStat[key] = parseFloat(stat[key]) || 0),
    );

    if (statType === 'merchant-overview') {
      // Merchant fields
      normalizedStat.percent_month_remaining = (1 - monthElapsed) * 100;
      normalizedStat.merchants_revenue = parseFloat(stat.revenue) || 0;
      normalizedStat.merchant_spend = stat.spend;
    } else {
      // Publisher fields
      normalizedStat.publisher_revenue = parseFloat(stat.revenue) || 0;
      normalizedStat.merchants_revenue =
        parseFloat(stat.merchants_revenue) || 0;
    }
    return {
      ...stat,
      ...normalizedStat,
    };
  });

  return [
    context.actions.bid.calculateTotals({
      data: processedData,
      statType,
      params,
    }),
    context.actions.bid.calculateOverviewStats({
      data: processedData,
      statType,
      params,
    }),
  ];
};

export const processMerchEdStats = (
  context: Context,
  { data, params }: { data: any; params: any },
) => {
  const { stats } = data;

  const totalsData = stats[0];

  columns.map((col: any) => col.name);

  const merchData = stats[1].map((stat: any) => {
    const parsedStat: any = {};
    columns.forEach((key: any) => {
      parsedStat[key] =
        key !== 'short_name' ? parseFloat(stat[key]) : stat[key];
    });
    const {
      merch_id,
      budget_id,
      is_active,
      is_reconciled,
      merchant_roi_goals,
      guardrail_lower_bound,
      guardrail_upper_bound,
    } = stat;
    return {
      ...parsedStat,
      merch_id,
      budget_id,
      is_active,
      is_reconciled,
      merchant_roi_goals,
      guardrail_lower_bound: parseFloat(guardrail_lower_bound),
      guardrail_upper_bound: parseFloat(guardrail_upper_bound),
    };
  });

  return [
    context.actions.bid.sortOverviewStats({ data: [totalsData], params }),
    context.actions.bid.sortOverviewStats({ data: merchData, params }),
    data.page_info.total_items,
  ];
};

export const getMerchants = (context: Context) => {
  return context.effects.api
    .get('/api/v0/merchants/')
    .then((resp: any) => {
      const merchants = resp.data.data.filter((merchant: any) => {
        return merchant.show_in_dashboard;
      });

      return sortAlphabetical(merchants, 'short_name');
    })
    .catch(() => []);
};

export const calculateTotals = (
  context: Context,
  { data, statType, params }: { data: any; statType: any; params: any },
) => {
  const totalSummable: any = {};
  data.forEach((row: any) => {
    Object.keys(row).forEach((key) => {
      if (!totalSummable[key]) {
        totalSummable[key] = parseFloat(row[key]);
      } else {
        totalSummable[key] += parseFloat(row[key]);
      }
    });
  });

  totalSummable.percent_month_remaining = data[0].percent_month_remaining;
  totalSummable.short_name = 'Totals';

  return context.actions.bid.calculateOverviewStats({
    data: [totalSummable],
    statType,
    params,
  });
};

// Calculates statistics not included in the network request
export const calculateOverviewStats = (
  context: Context,
  { data, statType, params }: { data: any; statType: any; params: any },
) => {
  const calculatedStats = data.map((stat: any) => {
    const calcStat = { ...stat };
    const numerators: any = {
      'merchant-overview': calcStat.spend,
      'publisher-overview': calcStat.publisher_revenue,
    };

    calcStat.pacing_towards = context.actions.bid.projectValue(
      numerators[statType],
    );
    calcStat.average_cpc = calcStat.clicks
      ? numerators[statType] / calcStat.clicks
      : 0;
    calcStat.cpc_variance = calcStat.cpc_goal
      ? calcStat.cpc - calcStat.cpc_goal / calcStat.cpc_goal
      : 0;
    calcStat.click_through_rate = calcStat.impressions
      ? (calcStat.clicks / calcStat.impressions) * 100
      : 0;
    calcStat.average_roi = calcStat.spend
      ? calcStat.merchants_revenue / calcStat.spend
      : 0;
    calcStat.roi_variance =
      calcStat.roi_goal && calcStat.average_roi
        ? (calcStat.average_roi - calcStat.roi_goal) / calcStat.roi_goal
        : 0;

    calcStat.percent_spend_remaining = calcStat.monthly_budget_amount
      ? ((calcStat.monthly_budget_amount - calcStat.mtd_spend) /
          calcStat.monthly_budget_amount) *
        100
      : 0;

    return calcStat;
  });

  return context.actions.bid.sortOverviewStats({
    data: calculatedStats,
    params,
  });
};

export const sortOverviewStats = (
  context: Context,
  {
    data,
    params,
    sortedColumn = '',
  }: { data: any; params: any; sortedColumn?: any },
) => {
  let direction: any;

  if (sortedColumn) {
    if (params.order_by === sortedColumn) {
      direction = params.direction === 'desc' ? 'asc' : 'desc';
    } else {
      direction = 'desc';
    }
  } else {
    sortedColumn = params.order_by;
    direction = params.direction;
  }

  data.sort((a: any, b: any) => {
    if (a[sortedColumn] <= b[sortedColumn]) {
      return direction === 'desc' ? 1 : -1;
    }
    return direction === 'desc' ? -1 : 1;
  });

  return {
    data,
    params: { ...params, order_by: sortedColumn, direction },
  };
};

export const projectValue = (context: Context, currentValue: number) => {
  const monthElapsed = context.actions.date.monthElapsed();

  if (!currentValue) return 0;

  // Round up to the 2nd decimal value
  const projectedValue = Math.round((currentValue / monthElapsed) * 100) / 100;
  return parseFloat(projectedValue.toFixed(2));
};

// Only monthly budget is used in the new budget system. To prevent bad data in the DB causing issues if we have to
// roll back the budget changes, update daily budget with the monthly value when updating the budget
export const updateBudget = (
  context: Context,
  {
    merchId,
    value,
    budgetId,
    date,
  }: { merchId: any; value: any; budgetId: any; date: any },
) => {
  const yearMonth = context.actions.date.formatYearMonth(date); // YYYY-MM

  const dailyEndpoint = `${baseEndpoint}merchants/${merchId}/`;
  const monthlyEndpoint = `${baseEndpoint}merchants/${merchId}/budgets/${
    budgetId ? `${budgetId}/` : ''
  }`;

  const dailyParams = {
    daily_budget_amount: value,
  };
  const monthlyParams = {
    budget_amount: value,
    year_month: yearMonth,
  };

  if (!budgetId) {
    return context.effects.api.post(monthlyEndpoint, monthlyParams);
  }

  // Update daily budget with monthly value in case we need to roll back to using daily io
  context.effects.api.put(dailyEndpoint, dailyParams);

  // Update monthly budget
  return context.effects.api.put(monthlyEndpoint, monthlyParams);
};

export const updateMerchRecon = (
  context: Context,
  {
    merchIds,
    reconAction,
    guardrailLowerBound,
    guardrailUpperBound,
  }: {
    merchIds: any;
    reconAction: any;
    guardrailLowerBound: any;
    guardrailUpperBound: any;
  },
) => {
  const endpoint = `${baseEndpoint}update_merchant_reconciliation/`;
  const params = {
    is_reconciled: reconAction,
    merch_ids: merchIds,
    guardrail_lower_bound: null,
    guardrail_upper_bound: null,
  };

  if (reconAction) {
    params.guardrail_lower_bound = guardrailLowerBound;
    params.guardrail_upper_bound = guardrailUpperBound;
  }
  return context.effects.api.put(endpoint, params);
};
