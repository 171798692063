import { Button } from '@frontend/shared-ui-kit/v2/button/button';
import { Typography } from '@frontend/shared-ui-kit/v2/typography';
import {
  InformationCircleIcon,
  LinkIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useClickOutside, useClipboard, useOs } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useQuery } from '@tanstack/react-query';
import {
  useActions,
  useAppState,
  useEffects,
} from '@frontend/howl-web-app/overmind';
import {
  SanitizedHTMLWrapper,
  cn,
  useDebounce,
  useFullStoryAnalytics,
} from '@frontend/shared-utils';
import { useToast } from '@frontend/shared-ui-kit/v2/toaster';
import { Input } from '@frontend/shared-ui-kit/v2/input';
import { CheckIcon, DuplicateIcon } from '@heroicons/react/outline';
import { ImageWithFallback } from '@frontend/shared-ui-kit/image';
import { Badge } from '@frontend/shared-ui-kit/v2/badge';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Switch } from '@frontend/shared-ui-kit/v2/switch';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@frontend/shared-ui-kit/v2/popover';
import { useRouter } from 'next/router';
import { DialogContent, DialogFooter } from '@frontend/shared-ui-kit/v2/dialog';
import { ChevronLeft } from 'react-feather';

interface ProductData {
  store_page_url: string;
  item_data: {
    name: string;
    image_url: string;
  };
  merch_name: string | null;
  price?: number | string;
  bamx_item_id?: number;
  howl_link_url?: string;
  link_source?: string;
}

interface LinkMonData {
  monetization_status: 'in-network' | 'out-of-network';
  recommended_products: any[];
  exclusions: string;
  exact_match_product: any;
}

interface RecentLinksData {
  bam_link_id: number;
  bam_link_url: string;
  auction_id: number;
  edit_id: number;
  link_source: number;
  merch_id?: any;
  pub_id: number;
  uid_created: number;
  user_image_url?: any;
  user_product_name?: any;
  user_brand_name?: any;
  user_link_description?: any;
  exclusive_match_requested: number;
  datetime_link_created: string;
  edit_name: string;
  edit_channel?: any;
  datetime_edit_created: string;
  store_page_url: string;
  feed_merchant_id: number;
  image_url: string;
  merchant_product_id: number;
  product_brand: string;
  product_name: string;
  auction_url: string;
  encoded_auction_url: string;
  howl_link_url: string;
}

const isUrl = (link: string) => {
  try {
    new URL(link);
  } catch (e) {
    return false;
  }
  return true;
};

const LinkMonitizationStatus = ({
  status,
}: {
  status: 'in-network' | 'out-of-network';
}) => {
  let badge;
  switch (status) {
    case 'out-of-network':
      badge = (
        <Badge className="" variant={'critical'}>
          <span className="hidden sm:block">Not Monetizable</span>
        </Badge>
      );
      break;
    case 'in-network': {
      badge = (
        <Badge className="" variant={'success'}>
          <span className="hidden sm:block">Monetizable</span>
        </Badge>
      );
      break;
    }
    default:
      break;
  }
  return badge;
};

interface LinkSuccessProps {
  linkInfo: { howl_link_url: string };
  productInfo?: ProductData;
  linkCreated?: boolean;
  setLinkCreated: any;
  setUrlLinkCreated: any;
}

const LinkSuccess: React.FC<LinkSuccessProps> = ({
  linkInfo,
  productInfo,
  linkCreated,
  setLinkCreated,
  setUrlLinkCreated,
}) => {
  const clipboard = useClipboard();
  const { selectedOrgId } = useAppState().organization;
  const { toast } = useToast();
  const { openGlobalLinkCreationModal } = useActions().link;
  const { globalLinkCreationModalBypassLinkCreation } = useAppState().link;

  useEffect(() => {
    //Copy to clipboard on first render
    clipboard.copy(linkInfo.howl_link_url);
    toast({
      description: `Share the product with your audience.`,
      title: 'Howl Link Copied',
      variant: 'success',
    });
  }, []);

  const handleShare = async () => {
    // feature detecting navigator.canShare() also implies
    // the same for the navigator.share()
    clipboard.copy(linkInfo.howl_link_url);
    toast({
      description: `Share the product with your audience.`,
      title: 'Howl Link Copied',
      variant: 'success',
    });
    try {
      await navigator.share({
        url: linkInfo.howl_link_url,
      });
    } catch {
      console.log(`Your browser doesn't support the Web Share API.`);
    }
  };

  return (
    <div className="flex flex-col items-center sm:min-w-[380px] relative">
      {!globalLinkCreationModalBypassLinkCreation && (
        <Button
          variant={'secondary'}
          size="icon"
          className="absolute top-0 left-2"
          onClick={() => {
            setLinkCreated(null);
            setUrlLinkCreated(null);
          }}
        >
          <ChevronLeft className="w-4 h-4" />
        </Button>
      )}
      <div className="bg-primary-400 rounded-full p-2 flex flex-row gap-2 items-center justify-center relative w-min border-[8px] border-primary-10 mb-4">
        <svg
          className="shrink-0 relative overflow-visible"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1424_34085)">
            <path
              d="M9.50016 16.1701L5.33016 12.0001L3.91016 13.4101L9.50016 19.0001L21.5002 7.00009L20.0902 5.59009L9.50016 16.1701Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1424_34085">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <Typography variant="headline-large" className="mb-2">
        Success! Link Created
      </Typography>
      <Typography variant="body" className="text-text-secondary">
        Now it’s time to share it out.
      </Typography>
      <div className="px-4 py-5 rounded-lg shadow-elevation-low mt-6 w-full">
        {productInfo && (
          <div className="w-full flex justify-between items-center flex-col">
            <a
              className="flex space-x-3 items-center justify-center hover:text-text-link focus:text-text-link w-full"
              href={productInfo.store_page_url}
              target="_blank"
            >
              <img
                className="w-16 h-16 object-contain rounded-full overflow-hidden"
                src={productInfo.item_data.image_url}
              />
              <div className="flex flex-col flex-1">
                <Typography
                  variant="body-500"
                  className="line-clamp-1 text-ellipsis"
                >
                  {productInfo.item_data.name}
                </Typography>
                <div className="flex items-center space-x-1 text-text-secondary">
                  <Typography variant="caption" className="">
                    {productInfo.price && `$${productInfo.price}`}
                  </Typography>
                  {!!productInfo.price && productInfo.merch_name && (
                    <span>·</span>
                  )}
                  {productInfo.merch_name && (
                    <Typography
                      variant="caption"
                      className="line-clamp-1 text-ellipsis"
                    >
                      Sold by {productInfo.merch_name}
                    </Typography>
                  )}
                </div>
              </div>
            </a>
          </div>
        )}
        <div className="w-full flex flex-col">
          <div className="block py-4 w-full relative">
            <Input
              id="copy-link-input"
              className="pl-4 rounded-full bg-neutral-gray-100 opacity-80 focus-visible:ring-transparent  focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-none"
              readOnly
              onClick={(e) => (e.target as HTMLInputElement).select()}
              value={linkInfo.howl_link_url}
            />
            <button
              onClick={() => {
                handleShare();
              }}
              className="absolute top-5 right-1 w-8 h-8 bg-violet-300 rounded-full flex items-center justify-center"
            >
              {clipboard.copied ? (
                <CheckIcon
                  className={
                    'w-4 h-4 scale-150 p-px stroke-white items-center align-middle stroke-[2]'
                  }
                />
              ) : (
                <DuplicateIcon
                  className={
                    'w-4 h-4 scale-[1.25] p-px stroke-white items-center align-middle stroke-[1.5px]'
                  }
                />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-row flex-nowrap justify-between space-x-4 w-full mt-6">
        <div className="flex space-x-4 justify-between w-full">
          <Button
            variant="secondary"
            className="whitespace-nowrap w-full"
            asChild
            size="large"
            onClick={() => {
              openGlobalLinkCreationModal(false);
            }}
          >
            <Link href={`/publisher/${selectedOrgId}/links/my-links`}>
              Go To My Links
            </Link>
          </Button>
          <Button
            size="large"
            onClick={() => handleShare()}
            className="whitespace-nowrap w-full"
            data-ref="easy-link-copy-link"
          >
            {clipboard.copied ? 'Copied!' : 'Copy Link'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const CreatorGlobalLinkCreationModal = () => {
  const os = useOs();
  const isMobile = os === 'ios' || os === 'android';
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchTerm: string = useDebounce(searchQuery, 1000);
  const [isFocus, setIsFocus] = useState(false);
  const { selectedOrgId } = useAppState().organization;
  const { previewLinkProduct, previewLinkUrl } = useAppState().link;
  const { get } = useEffects().api;
  const { getLinkMonStatuses } = useActions().link;
  const { autoGenerateEditV2, createLinks } = useActions().link;
  const [isMakingLink, setIsMakingLink] = useState(false);
  const clickOutsideRef = useClickOutside(() => setIsFocus(false));
  const [linkCreated, setLinkCreated] = useState<{
    productInfo: ProductData;
    linkInfo: any;
  } | null>(null);
  const [dynamic, setDynamic] = useState(false);
  const {
    globalLinkCreationModalOpen,
    globalLinkCreationModalBypassLinkCreation,
  } = useAppState().link;

  const [urlLinkCreated, setUrlLinkCreated] = useState<{
    url: string;
    linkInfo: any;
  } | null>(null);

  const clipboard = useClipboard({ timeout: 5000 });
  const { toast } = useToast();
  const [parent, enableAnimations] = useAutoAnimate(/* optional config */);
  const [outerParent, enableLinkSuccessAnimation] = useAutoAnimate({
    easing: 'linear',
  });
  const router = useRouter();
  const { getLinks } = useActions().link;
  const fullstory = useFullStoryAnalytics();

  const { data, fetchStatus, isInitialLoading } = useQuery({
    queryKey: ['product-search', debouncedSearchTerm],
    queryFn: async () => {
      const endpoint = `/api/v0/publishers/${selectedOrgId}/easy_link_creation/?page=1&per_page=4&is_in_stock=true&images_only=true&search_query=${debouncedSearchTerm}&is_fuzzy=true&order_by=datetime_created&syntax_highlighting=true&active_merchants_only=true&no_manual_products=true`;
      const response = await get(endpoint);
      return response.data.data[0];
    },
    enabled: false,
  });

  useEffect(() => {
    if (!linkCreated) {
      enableLinkSuccessAnimation(false);
    } else {
      enableLinkSuccessAnimation(true);
    }
  }, [linkCreated]);

  const addProtocolToUrl = (url: string): string => {
    const protocolRegex = /^https?:\/\//i;
    const wwwRegex = /^www\./i;
    if (!protocolRegex.test(url) && !wwwRegex.test(url)) {
      return `https://www.${url}`;
    } else if (!protocolRegex.test(url) && wwwRegex.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const {
    data: linkMonData,
    isInitialLoading: isLinkMonLoading,
    isError,
  } = useQuery({
    queryKey: ['link-mon-statuses', debouncedSearchTerm],
    queryFn: async () => {
      const response: LinkMonData | { error: boolean } =
        await getLinkMonStatuses({
          pubId: selectedOrgId,
          link: addProtocolToUrl(debouncedSearchTerm),
          version: 3,
        });

      if ('error' in response && response.error) {
        throw Error('Error getting link monetization status');
      }

      return response as LinkMonData;
    },
    refetchOnMount: false,
    enabled:
      debouncedSearchTerm.length > 3 &&
      isUrl(addProtocolToUrl(debouncedSearchTerm)) &&
      !globalLinkCreationModalBypassLinkCreation,
  });

  useEffect(() => {
    if (linkMonData && linkMonData.monetization_status === 'out-of-network') {
      fullstory?.event('Out of Network Link Pasted', {
        status: linkMonData.monetization_status,
        link: debouncedSearchTerm,
      });
    }
  }, [linkMonData]);

  useEffect(() => {
    if (isError) {
      toast({
        description: `We couldn't find your link. Please try again later.`,
        title: 'Error',
        variant: 'error',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (globalLinkCreationModalBypassLinkCreation && previewLinkProduct) {
      handClickMakeLink({
        store_page_url: previewLinkProduct?.value?.productUrl,
        item_data: {
          name: previewLinkProduct?.value?.name,
          image_url: previewLinkProduct?.value?.imageUrl,
        },
        merch_name: previewLinkProduct?.value?.merchant
          ? previewLinkProduct?.value?.merchant
          : null,
        price: previewLinkProduct?.value?.productPrice,
        link_source: previewLinkProduct?.options?.link_source,
      });
    }

    if (globalLinkCreationModalBypassLinkCreation && previewLinkUrl) {
      handleClickMakeUrlLink(previewLinkUrl);
    }
  }, [globalLinkCreationModalBypassLinkCreation]);

  // const { data: topProducts, isInitialLoading: isTopProductsLoading } =
  //   useQuery({
  //     queryKey: ['top-products'],
  //     queryFn: async (): Promise<ProductData[] | null> => {
  //       const response = await get(
  //         `/api/v0/publishers/${selectedOrgId}/default_product_suggestions/10/`,
  //       );

  //       const products = response.data.data[0]?.products;
  //       const uniqueProducts = unique<ProductData, string>(products, (f) => {
  //         if (f.merch_name) {
  //           return f.merch_name;
  //         } else {
  //           return f.item_data.name;
  //         }
  //       });
  //       return uniqueProducts && uniqueProducts.length
  //         ? uniqueProducts.slice(0, 6)
  //         : null;
  //     },
  //     enabled: false,
  //   });

  // const { data: recentLinks, isInitialLoading: isRecentLinksLoading } =
  //   useQuery({
  //     queryKey: ['recent-links'],
  //     queryFn: async (): Promise<RecentLinksData[] | null> => {
  //       const params = {
  //         page: 1,
  //         per_page: 8,
  //         source: 'all',
  //         is_fuzzy: true,
  //         order_by: 'datetime_link_created',
  //         direction: 'desc',
  //       };
  //       const response = await getLinks({
  //         publisherId: selectedOrgId,
  //         params: params,
  //       });
  //       const links = response.data.data[0].links;
  //       return links;
  //     },
  //     enabled: !debouncedSearchTerm.length,
  //   });

  const handleClickMakeUrlLink = async (link: {
    value: string;
    options?: { link_source?: string };
  }) => {
    if (selectedOrgId) {
      setIsMakingLink(true);
      try {
        const post = await autoGenerateEditV2({
          publisherId: selectedOrgId,
          editCreationType: 'bulk_skip', // bulk_skip is okay because of full-story
        });
        const params = {
          edit_id: post.edit_id,
          urls: [link.value],
          exclusive_match_requested: !dynamic,
          from_howl: true,
          link_source: link.options?.link_source
            ? link.options.link_source
            : 'easy_link_creation',
        };
        const res = await createLinks({ publisherId: selectedOrgId, params });
        const data = res.data.data[0];
        const keys = Object.keys(data);
        setUrlLinkCreated({ url: link.value, linkInfo: data[keys[0]] });
        if (!globalLinkCreationModalBypassLinkCreation) {
          fullstory?.event('Link Creation Modal: Link Created from Url', {
            auction_id: data[keys[0]].auction_id,
            pub_id: selectedOrgId,
          });
        }
        setIsMakingLink(false);
        setSearchQuery('');
      } catch {
        setIsMakingLink(false);
        toast({
          description: `We couldn't create the link.  Please try again later.`,
          title: 'Error',
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (previewLinkUrl) {
      setSearchQuery(previewLinkUrl.value);
    }
  }, [previewLinkUrl]);

  const handClickMakeLink = async (product: ProductData) => {
    if (selectedOrgId) {
      setIsMakingLink(true);
      try {
        const post = await autoGenerateEditV2({
          publisherId: selectedOrgId,
          editCreationType: 'bulk_skip', // bulk_skip is okay because of full-story
        });
        const params = {
          edit_id: post.edit_id,
          urls: [product.store_page_url],
          exclusive_match_requested: !dynamic,
          from_howl: true,
          link_source: product.link_source ? product.link_source : null,
        };
        const res = await createLinks({ publisherId: selectedOrgId, params });
        const data = res.data.data[0];
        const keys = Object.keys(data);

        setLinkCreated({ productInfo: product, linkInfo: data[keys[0]] });

        fullstory?.event(
          'Link Creation Modal: Link Created from Product Card',
          {
            auction_id: data[keys[0]].auction_id,
            pub_id: selectedOrgId,
          },
        );
        setIsMakingLink(false);
        setSearchQuery('');
      } catch {
        setIsMakingLink(false);
        toast({
          description: `We couldn't create a link for ${product.store_page_url}.`,
          title: 'Error',
          variant: 'error',
        });
      }
    }
  };

  const handleCopyLink = (link: string) => {
    clipboard.copy(link);
    toast({
      description: `${link} Share the product with your audience.`,
      title: 'Howl Link Copied!',
      variant: 'success',
      action:
        navigator.canShare && navigator.canShare() ? (
          <Button
            variant="tertiary"
            className="w-32 mt-4"
            size="medium"
            onClick={async () => {
              try {
                await navigator.share({
                  url: link,
                });
              } catch (e: any) {
                if (e.toString().includes('AbortError')) {
                  alert('Hello world aborted :(');
                }
              }
            }}
          >
            Share
          </Button>
        ) : undefined,
    });
  };

  const dynamicLinksToggle = (
    <>
      <div className="flex items-center">
        <Typography className="mr-2 text-neutral-gray-800" variant="body">
          Dynamic Link
        </Typography>
        <Popover>
          <PopoverTrigger>
            <InformationCircleIcon className="w-5 h-5 text-violet-400 mr-4" />
          </PopoverTrigger>
          <PopoverContent>
            Dynamic Links maximize your earnings by sending the user to the
            brand retailer with the highest earning potential and has the
            product in-stock.
          </PopoverContent>
        </Popover>

        <Switch
          disabled={isMakingLink}
          data-ref="dynamic-link-toggle-switch"
          checked={dynamic}
          onCheckedChange={() => setDynamic(!dynamic)}
        />
      </div>
    </>
  );

  return (
    <>
      <DialogContent
        className={cn(
          'md:w-min h-[100dvh] sm:h-auto sm:p-0 p-0',
          { 'sm:translate-y-[-50%]': linkCreated || urlLinkCreated },
          {
            'sm:translate-y-[-90%]':
              !linkCreated &&
              !urlLinkCreated &&
              !globalLinkCreationModalBypassLinkCreation,
          },
        )}
        onInteractOutside={(e) => {
          if (isMakingLink) {
            e.preventDefault();
          }
        }}
        onEscapeKeyDown={(e) => {
          if (isMakingLink) {
            e.preventDefault();
          }
        }}
        disableClose={isMakingLink}
      >
        <div className="h-[95vh] sm:h-auto sm:p-6 p-4 relative">
          <div ref={outerParent}>
            {linkCreated && (
              <LinkSuccess
                linkInfo={linkCreated.linkInfo}
                productInfo={linkCreated.productInfo}
                linkCreated={linkCreated !== null}
                setLinkCreated={setLinkCreated}
                setUrlLinkCreated={setUrlLinkCreated}
              />
            )}
            {urlLinkCreated && (
              <LinkSuccess
                linkInfo={urlLinkCreated.linkInfo}
                linkCreated={linkCreated !== null}
                setLinkCreated={setLinkCreated}
                setUrlLinkCreated={setUrlLinkCreated}
              />
            )}
          </div>

          {!linkCreated &&
            !urlLinkCreated &&
            globalLinkCreationModalBypassLinkCreation && (
              <>
                <div
                  ref={clickOutsideRef}
                  className={cn(
                    'w-full sm:w-[380px] min-w-[380px] mx-auto h-[420px]',
                    {},
                  )}
                >
                  <div className="w-full h-full backdrop-blur-sm absolute top-0 left-0 flex justify-center items-center bg-white/10 z-10">
                    <img
                      src="/images/icons/spinner.svg"
                      className="animate-spin "
                    />
                  </div>
                </div>
              </>
            )}

          {!linkCreated &&
            !urlLinkCreated &&
            !globalLinkCreationModalBypassLinkCreation && (
              <>
                <div className="flex flex-col items-center mb-6 min-w-fit">
                  <Typography
                    variant="headline-large"
                    className="text-left sm:text-center w-full"
                  >
                    Make a Link & Earn
                  </Typography>
                  {!previewLinkProduct && (
                    <Typography
                      variant="body"
                      className="text-left sm:text-center text-text-secondary sm:max-w-md mt-2"
                    >
                      Drop a link from any product on the internet and we'll
                      turn it into a Howl Commerce Link.
                    </Typography>
                  )}
                </div>
                <div
                  ref={clickOutsideRef}
                  className={cn(
                    'w-full sm:w-[600px] lg:w-[685px] mx-auto transition-all h-20',
                    {},
                  )}
                >
                  <div
                    className={cn(
                      'relative w-full rounded-2xl border border-border-strong shadow-elevation-high transition overflow-hidden',
                      {
                        'focus-within:border-primary-100':
                          (!linkMonData && !isError) || isLinkMonLoading,
                        'border-border-error': isError,
                      },
                    )}
                  >
                    {!previewLinkProduct && (
                      <div className="relative py-6 ">
                        <input
                          onFocus={() => setIsFocus(true)}
                          data-ref="easy-link-input"
                          type="url"
                          className=" w-full pr-10 pl-12 outline-none disabled:cursor-wait disabled:bg-transparent"
                          placeholder="Paste your link here . . ."
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                          }}
                          disabled={isMakingLink}
                          value={searchQuery}
                        />
                        <div className="absolute inset-y-0 top-0 left-5 flex items-center pr-3">
                          <LinkIcon className="w-5 h-5 stroke-2 stroke-primary-400" />
                        </div>
                        {searchQuery && !!searchQuery.length && (
                          <button
                            className="absolute inset-y-0 top-0 right-5 flex items-center z-10 bg-white"
                            onClick={() => setSearchQuery('')}
                            disabled={isMakingLink}
                          >
                            <XIcon className="w-5 h-5 stroke-2" />
                          </button>
                        )}
                      </div>
                    )}

                    {/* Search Results */}
                    <div className="z-10 relative">
                      {linkMonData || isLinkMonLoading ? (
                        <hr className="flex-grow border-t border-neutral-opacity-100" />
                      ) : null}
                      <div className="bg-white relative overflow-hidden">
                        {isMakingLink && (
                          <div className="w-full h-full backdrop-blur-sm absolute top-0 left-0 flex justify-center items-center bg-white/10 z-10">
                            <img
                              src="/images/icons/spinner.svg"
                              className="animate-spin "
                            />
                          </div>
                        )}

                        <div
                          ref={parent}
                          className="overflow-y-auto overflow-x-hidden max-h-96"
                        >
                          {/* Zero State Top Product Results */}
                          {/* {isFocus &&
                    topProducts &&
                    !data &&
                    !linkMonData &&
                    !isInitialLoading && (
                      <>
                        <div className="flex space-x-3 items-center justify-center mt-2">
                          <Typography
                            variant="body"
                            className="line-clamp-1 text-ellipsis"
                          >
                            Recommended Products
                          </Typography>
                        </div>

                        {topProducts.map((product) => (
                          <div
                            className="h-14 w-full flex justify-between py-3 pl-5 pr-2 items-center"
                            key={product.bamx_item_id}
                          >
                            <a
                              className="flex space-x-3 items-center justify-center hover:text-text-link focus:text-text-link overflow-clip"
                              href={product.store_page_url}
                              target="_blank"
                            >
                              <ImageWithFallback
                                className="w-6 h-6 object-contain rounded-sm stroke-black"
                                src={product.item_data.image_url}
                              />
                              <div className="flex flex-col flex-auto overflow-clip">
                                <Typography
                                  variant="caption-500"
                                  className="line-clamp-1 text-ellipsis"
                                >
                                  {product.item_data.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className="text-text-tertiary line-clamp-1 text-ellipsis"
                                >
                                  Sold by {product.merch_name}
                                </Typography>
                              </div>
                            </a>
                            <div>
                              <Button
                                variant="tertiary"
                                onClick={() =>
                                  handleClickMakeUrlLink(product.store_page_url)
                                }
                              >
                                Link
                              </Button>
                            </div>
                          </div>
                        ))}
                      </>
                    )} */}

                          {/* Zero State Recent Links */}
                          {/* {isFocus &&
                    recentLinks &&
                    recentLinks.length &&
                    !data &&
                    !linkMonData &&
                    !isInitialLoading && (
                      <>
                        <div className="flex space-x-3 items-center justify-center mt-2">
                          <Typography
                            variant="body"
                            className="line-clamp-1 text-ellipsis"
                          >
                            Recent Links
                          </Typography>
                        </div>

                        {recentLinks.map((link) => (
                          <div
                            className="h-14 w-full flex justify-between py-3 pl-5 pr-2 items-center"
                            key={link.bam_link_id}
                          >
                            <a
                              className="flex space-x-3 items-center justify-center hover:text-text-link focus:text-text-link overflow-clip"
                              href={link.store_page_url}
                              target="_blank"
                            >
                              <ImageWithFallback
                                className="w-6 h-6 object-contain rounded-sm stroke-black"
                                src={link.image_url}
                              />
                              <div className="flex flex-col flex-auto overflow-clip">
                                <Typography
                                  variant="caption-500"
                                  className="line-clamp-1 text-ellipsis"
                                >
                                  {link.product_name
                                    ? link.product_name
                                    : link.howl_link_url}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className="text-text-tertiary line-clamp-1 text-ellipsis"
                                >
                                  {link.edit_name}
                                </Typography>
                              </div>
                            </a>
                            <div>
                              <Button
                                variant="tertiary"
                                className="whitespace-nowrap"
                                onClick={() =>
                                  handleCopyLink(link.howl_link_url)
                                }
                              >
                                Copy Link
                              </Button>
                            </div>
                          </div>
                        ))}
                      </>
                    )} */}

                          <>
                            {/* Preview Product Link from Product Make Link Clicks} */}
                            {previewLinkProduct && (
                              <div className="w-full flex flex-col sm:flex-row sm:items-center justify-between sm:space-x-4 pt-4 pb-3 px-5">
                                <a
                                  className="flex space-x-3 items-center justify-center hover:text-text-link focus:text-text-link overflow-clip flex-initial"
                                  href={previewLinkProduct?.value?.productUrl}
                                  target="_blank"
                                >
                                  <ImageWithFallback
                                    className="w-12 h-12 object-contain rounded-sm stroke-black"
                                    src={previewLinkProduct?.value?.imageUrl}
                                    loggingInfo={{
                                      component:
                                        'libs/howl-web-app/feature-main/src/creator/global-link-creation/CreatorGlobalLinkCreationModal.tsx',
                                      productData: previewLinkProduct,
                                    }}
                                  />
                                  <div className="flex flex-col flex-auto overflow-clip">
                                    <Typography
                                      variant="caption-500"
                                      className="line-clamp-1 text-ellipsis"
                                    >
                                      {previewLinkProduct?.value?.name}
                                    </Typography>
                                    <div className="flex items-center flex-nowrap space-x-2">
                                      <Typography
                                        variant="caption"
                                        className="text-text-secondary line-clamp-1 text-ellipsis break-all"
                                      >
                                        {previewLinkProduct?.value
                                          ?.productPrice ? (
                                          <span>
                                            $
                                            {
                                              previewLinkProduct?.value
                                                ?.productPrice
                                            }{' '}
                                            ·{' '}
                                          </span>
                                        ) : null}
                                        {previewLinkProduct?.value?.productUrl}
                                      </Typography>
                                    </div>
                                  </div>
                                </a>
                                <div className="flex-none flex justify-end">
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      handClickMakeLink({
                                        store_page_url:
                                          previewLinkProduct?.value?.productUrl,
                                        item_data: {
                                          name: previewLinkProduct?.value?.name,
                                          image_url:
                                            previewLinkProduct?.value?.imageUrl,
                                        },
                                        merch_name: previewLinkProduct?.value
                                          .merchant
                                          ? previewLinkProduct?.value?.merchant
                                          : null,
                                        price:
                                          previewLinkProduct?.value
                                            ?.productPrice,
                                        link_source:
                                          previewLinkProduct?.options
                                            ?.link_source,
                                      })
                                    }
                                  >
                                    Create Link
                                  </Button>
                                </div>
                              </div>
                            )}

                            {/* Link Monitization Result for URL */}
                            {linkMonData && (
                              <div>
                                <div className="h-14 w-full flex justify-between py-3 pl-5 pr-2 items-center">
                                  <a
                                    className="flex space-x-3 items-center justify-center hover:text-text-link focus:text-text-link overflow-clip"
                                    href={addProtocolToUrl(debouncedSearchTerm)}
                                    target="_blank"
                                  >
                                    <LinkMonitizationStatus
                                      status={linkMonData.monetization_status}
                                    />
                                    {linkMonData.exact_match_product
                                      ?.additional_matches &&
                                      linkMonData.exact_match_product
                                        ?.additional_matches[0]?.product && (
                                        <ImageWithFallback
                                          className="w-6 h-6 object-contain rounded-sm stroke-black"
                                          src={
                                            linkMonData.exact_match_product
                                              ?.additional_matches[0].product
                                              ?.item_data?.image_url
                                          }
                                          loggingInfo={{
                                            component:
                                              'libs/howl-web-app/feature-main/src/creator/global-link-creation/CreatorGlobalLinkCreationModal.tsx',
                                            productData:
                                              linkMonData.exact_match_product
                                                ?.additional_matches[0].product,
                                          }}
                                        />
                                      )}
                                    <div className="flex flex-col flex-auto overflow-clip">
                                      {linkMonData.exact_match_product
                                        ?.additional_matches &&
                                      linkMonData.exact_match_product
                                        ?.additional_matches[0]?.product ? (
                                        <Typography
                                          variant="caption-500"
                                          className="line-clamp-1 text-ellipsis break-all"
                                        >
                                          {
                                            linkMonData.exact_match_product
                                              ?.additional_matches[0]?.product
                                              ?.item_data?.name
                                          }
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="caption-500"
                                          className="line-clamp-1 text-ellipsis break-all"
                                        >
                                          {addProtocolToUrl(
                                            debouncedSearchTerm,
                                          )}
                                        </Typography>
                                      )}
                                      {/* {linkMonData.exact_match_product && (
                                      <Typography
                                        variant="caption"
                                        className="text-text-secondary line-clamp-1 text-ellipsis"
                                      >
                                        Sold by{' '}
                                        {
                                          linkMonData.exact_match_product
                                            .product?.merch_name
                                        }
                                      </Typography>
                                    )} */}
                                    </div>
                                  </a>
                                  <div>
                                    <Button
                                      disabled={
                                        linkMonData.monetization_status ===
                                        'out-of-network'
                                      }
                                      variant="tertiary"
                                      onClick={() => {
                                        if (
                                          linkMonData.exact_match_product
                                            ?.additional_matches[0]?.product
                                        ) {
                                          handClickMakeLink({
                                            store_page_url: debouncedSearchTerm,
                                            item_data: {
                                              name: linkMonData
                                                .exact_match_product
                                                ?.additional_matches[0]?.product
                                                .item_data.name,
                                              image_url:
                                                linkMonData.exact_match_product
                                                  ?.additional_matches[0]
                                                  ?.product.item_data.image_url,
                                            },
                                            merch_name:
                                              linkMonData.exact_match_product
                                                ?.additional_matches[0]?.product
                                                .merch_name,
                                            link_source: 'easy_link_creation',
                                          });
                                        } else {
                                          handleClickMakeUrlLink({
                                            value:
                                              addProtocolToUrl(
                                                debouncedSearchTerm,
                                              ),
                                          });
                                        }
                                      }}
                                      data-ref="easy-link-make-link-button"
                                    >
                                      Link
                                    </Button>
                                  </div>
                                </div>
                                {linkMonData.exclusions ? (
                                  <div className="w-full flex justify-star space-x-4 py-2 pl-5 pr-2 items-center">
                                    <Typography
                                      variant="caption"
                                      className="text-text-secondary"
                                    >
                                      Exclusions may apply
                                    </Typography>
                                    <Popover>
                                      <PopoverTrigger>
                                        <InformationCircleIcon className="w-5 h-5 text-violet-400 mr-4" />
                                      </PopoverTrigger>
                                      <PopoverContent>
                                        <SanitizedHTMLWrapper
                                          html={linkMonData.exclusions}
                                        />
                                      </PopoverContent>
                                    </Popover>
                                  </div>
                                ) : null}
                              </div>
                            )}

                            {linkMonData &&
                              !isInitialLoading &&
                              !!linkMonData?.recommended_products &&
                              !!linkMonData?.recommended_products.length && (
                                <>
                                  <div className="flex space-x-3 items-center justify-center">
                                    <div className="flex-grow border-t border-neutral-opacity-100"></div>
                                    <span className="flex-shrink mx-4 text-neutral-opacity-600">
                                      Monetizable Options
                                    </span>
                                    <div className="flex-grow border-t border-neutral-opacity-100"></div>
                                  </div>
                                  {linkMonData.recommended_products
                                    .slice(0, 8)
                                    .map(
                                      (
                                        recommendation: {
                                          merchant: string;
                                          productName: string;
                                          imageUrl: string;
                                          productUrl: string;
                                          bamId: string;
                                        },
                                        idx,
                                      ) => (
                                        <div
                                          className="h-14 w-full flex justify-start space-x-2 py-3 pl-5 pr-2 items-center"
                                          key={idx}
                                        >
                                          <LinkMonitizationStatus
                                            status={'in-network'}
                                          />
                                          <a
                                            className="flex space-x-3 items-center justify-start hover:text-text-link focus:text-text-link flex-auto"
                                            href={recommendation.productUrl}
                                            target="_blank"
                                          >
                                            <ImageWithFallback
                                              className="w-6 h-6 object-contain rounded-sm stroke-black"
                                              src={recommendation.imageUrl}
                                              loggingInfo={{
                                                component:
                                                  'libs/howl-web-app/feature-main/src/creator/global-link-creation/CreatorGlobalLinkCreationModal.tsx',
                                                productData: recommendation,
                                              }}
                                            />
                                            <div className="flex flex-col">
                                              <Typography
                                                variant="caption-500"
                                                className="line-clamp-1 text-ellipsis"
                                              >
                                                {recommendation.productName}
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                className="text-text-secondary line-clamp-1 text-ellipsis"
                                              >
                                                Sold by{' '}
                                                {recommendation.merchant}
                                              </Typography>
                                            </div>
                                          </a>
                                          <div className="self-end">
                                            <Button
                                              data-ref="easy-link-make-link-button"
                                              variant="tertiary"
                                              onClick={() =>
                                                handClickMakeLink({
                                                  store_page_url:
                                                    recommendation.productUrl,
                                                  item_data: {
                                                    name: recommendation.productName,
                                                    image_url:
                                                      recommendation.imageUrl,
                                                  },
                                                  merch_name:
                                                    recommendation.merchant,
                                                })
                                              }
                                            >
                                              Link
                                            </Button>
                                          </div>
                                        </div>
                                      ),
                                    )}
                                </>
                              )}

                            {/* TODO Get product data */}
                            {isLinkMonLoading
                              ? [1, 2, 3].map((val) => (
                                  <div
                                    className="h-14 w-full flex justify-between py-3 pl-5 pr-2 items-center"
                                    key={val}
                                  >
                                    <div className="flex space-x-3 items-center justify-center">
                                      <div className="w-6 h-6 object-contain rounded-sm bg-neutral-gray-100 animate-pulse"></div>
                                      <div className="flex flex-col">
                                        <div className="w-24 h-4 mb-1 bg-neutral-gray-100 animate-pulse" />
                                        <div className="w-16 h-3 bg-neutral-gray-100 animate-pulse" />
                                      </div>
                                    </div>
                                    <div>
                                      <div className="w-24 h-6 object-contain rounded-full bg-neutral-gray-100 animate-pulse"></div>
                                    </div>
                                  </div>
                                ))
                              : null}
                            {/* {data &&
                        data.products.map((product: ProductData) => (
                          <div
                            className="h-14 w-full flex justify-between py-3 pl-5 pr-2 items-center"
                            key={product.bamx_item_id}
                          >
                            <a
                              className="flex space-x-3 items-center justify-center hover:text-text-link focus:text-text-link"
                              href={product.store_page_url}
                              target="_blank"
                            >
                              <ImageWithFallback
                                className="w-6 h-6 object-contain rounded-sm stroke-black"
                                src={product.item_data.image_url}
                              />
                              <div className="flex flex-col">
                                <Typography
                                  variant="caption-500"
                                  className="line-clamp-1 text-ellipsis"
                                >
                                  {product.item_data.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className="text-text-tertiary line-clamp-1 text-ellipsis"
                                >
                                  Sold by {product.merch_name}
                                </Typography>
                              </div>
                            </a>
                            <div>
                              {product.howl_link_url ? (
                                <Button
                                  variant="tertiary"
                                  className="whitespace-nowrap"
                                  onClick={() =>
                                    handleCopyLink(product.howl_link_url)
                                  }
                                >
                                  Copy Link
                                </Button>
                              ) : (
                                <Button
                                  variant="tertiary"
                                  onClick={() => handClickMakeLink(product)}
                                >
                                  Link
                                </Button>
                              )}
                            </div>
                          </div>
                        ))} */}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
        {!linkCreated &&
        !urlLinkCreated &&
        !globalLinkCreationModalBypassLinkCreation ? (
          <DialogFooter>
            {/* Other ways to link */}
            <div
              className={cn(
                'transition absolute bottom-6 left-0 right-0 -mb-6 sm:relative w-full p-[inherit] overflow-hidden sm:rounded-b-2xl',
              )}
            >
              {isMobile ? (
                <div className="flex justify-center w-full">
                  {dynamicLinksToggle}
                </div>
              ) : null}
              <div className="w-full bg-black/5 border-t border-border-subtle mt-4 pt-4 pb-4 px-6 flex flex-row justify-between items-center">
                {!isMobile ? dynamicLinksToggle : null}
                {!isMobile ? (
                  <Link
                    href="https://chrome.google.com/webstore/detail/howl/flkpnckidibackbdhjofgclecmfdodae"
                    passHref
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      data-ref="easy-link-get-chrome-ex"
                      variant="tertiary"
                      className="w-full"
                    >
                      Get Chrome Extension
                    </Button>
                  </Link>
                ) : (
                  <Link
                    className="w-full"
                    href="https://apps.apple.com/us/app/howl-creator-app/id1620523882"
                    passHref
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="tertiary"
                      className="w-full"
                      data-ref="easy-link-get-ios"
                    >
                      Get Mobile App
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </DialogFooter>
        ) : null}
      </DialogContent>
    </>
  );
};
