type EcommMerchantStats = {
  merchant_stats: any;
  unfundedSpend: number | 0;
  isLoading: boolean;
};

export const state: EcommMerchantStats = {
  merchant_stats: {},
  unfundedSpend: 0,
  isLoading: false,
};
