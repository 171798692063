import { Context } from '..';

export const maybeLoadSubscriptionDetails = (context: Context) => {
  if (context.state.session.sessionData.userRole == 'merchants') return;
  const pubId = context.state.session.sessionData.orgId as string;
  if (!pubId) return;
  const params = {
    return_url: window.location.href.replace('all-plans', 'your-plan'),
  };
  getManageSubscriptionLink(context, { publisherId: pubId, params: params })
    .then((resp: any) => {
      setManageSubscriptionLink(
        context,
        resp.data.data[0].manage_subscriptions_link,
      );
    })
    .catch(() => {
      console.warn('Unable to get stripe link.');
    });

  getPubSubscriptionTiers(context, pubId)
    .then((resp: any) => {
      setSubTiers(context, resp.data.data[0]);
    })
    .catch(() => {
      console.warn('Unable to get subscription tiers.');
    });
};

export const getPubSubscriptionPlan = (
  context: Context,
  publisherId: string,
) => {
  return context.effects.api.get(
    `/api/v0/billing/subscription/${publisherId}/`,
  );
};

export const getPubSubscriptionTiers = (
  context: Context,
  publisherId: string,
) => {
  return context.effects.api.get(
    `/api/v0/billing/subscription_tiers/${publisherId}/`,
  );
};

export const getManageSubscriptionLink = (
  context: Context,
  { publisherId, params }: { publisherId: string; params: object },
) => {
  return context.effects.api.get(
    `/api/v0/billing/subscription/manage/${publisherId}/`,
    params,
  );
};

export const setManageSubscriptionLink = (context: Context, value: string) => {
  context.state.subscriptions.manageSubLink = value;
};

export const setSubTiers = (context: Context, value: any) => {
  context.state.subscriptions.subTiers = value;
};

export const updateSubscriptionHistory = (
  context: Context,
  { publisherId, params }: { publisherId: string; params: object },
) => {
  return context.effects.api.put(
    `/api/v0/billing/subscription/feedback/${publisherId}/`,
    {},
    params,
  );
};
