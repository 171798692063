import React, { useEffect, useState } from 'react';
import { useAppState, useEffects } from '@frontend/howl-web-app/overmind';
import { useRouter } from 'next/router';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@frontend/shared-ui-kit/tabs';
import { PublisherSelection } from './publisher-selection';
import { MerchantSelection } from './merchant-selection';
import classNames from 'classnames';

export const OrgList = ({ close }: { close: () => any }) => {
  const lastTabPropertyName = 'lastAdminDropdownTab';

  const { sessionData } = useAppState().session;
  const router = useRouter();
  const [lastTab, setLastTab] = useState(
    localStorage.getItem(lastTabPropertyName) || 'publishers',
  );

  useEffect(() => {
    // TODO: reset the infiniteQuery
  }, [sessionData.orgId]);

  const { getAuthenticatedStartingUrlPath } = useEffects().auth;

  const toggleAdmin = async () => {
    await router.push(
      getAuthenticatedStartingUrlPath({
        userRole: 'admin',
        orgId: 0,
      }),
    );
    close();
  };

  const rememberTab = (tab: string) => {
    localStorage.setItem(lastTabPropertyName, tab);
    setLastTab(tab);
  };

  return (
    <div className="relative text-sm overflow-x-clip">
      <Tabs defaultValue={lastTab} className="w-full">
        <TabsList className="w-full">
          <div
            onClick={toggleAdmin}
            className={classNames(
              'inline-flex items-center justify-center whitespace-nowrap transition-all px-3 py-2 cursor-pointer',
              'rounded-t-lg data-[state=inactive]:hover:bg-opacity-50 data-[state=active]:relative top-px data-[state=active]:bg-white data-[state=active]:text-deep-space-500 z-10 data-[state=active]:border-t-violet-300',
            )}
          >
            Admin
          </div>
          <TabsTrigger
            value="publishers"
            className={'data-[state=active]:shadow-blue-500/50'}
            onClick={() => rememberTab('publishers')}
          >
            Partners
          </TabsTrigger>
          <TabsTrigger
            value="merchants"
            className={'data-[state=active]:shadow-green-500/50'}
            onClick={() => rememberTab('merchants')}
          >
            Brands
          </TabsTrigger>
        </TabsList>
        <TabsContent value="publishers">
          <PublisherSelection close={close} />
        </TabsContent>
        <TabsContent value="merchants">
          <MerchantSelection close={close} />
        </TabsContent>
      </Tabs>
    </div>
  );
};
