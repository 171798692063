type State = {
  expirationPresets: any[];
  channelFields: any[];
  stockStatusFields: any[];
  auctionStatusFields: any[];
  auctionConfigFormat: any;
  priceRegex: any;
  smartlinkId: string;
  summary: any;
  enabledMatches: any[];
  suggestedMatches: any[];
  historicalMatches: any[];
  merchants: any[];
  groupedMerchantProducts: any[];
  error: any;
  loading: boolean;
  isMatchesLoaded: boolean;
  isBamLinkLocked: boolean;
  isCompleteButtonDisabled: boolean;
  ignoreExclusiveMatchRequest: boolean;
  matchLockExpiration: any;
  matchLockExpirationInSeconds: number;
};

export const state: State = {
  error: null,
  loading: false,
  isMatchesLoaded: false,
  isBamLinkLocked: false,
  isCompleteButtonDisabled: false,
  ignoreExclusiveMatchRequest: false,
  matchLockExpiration: null,
  matchLockExpirationInSeconds: 0,
  smartlinkId: '',
  summary: null,
  enabledMatches: [],
  suggestedMatches: [],
  historicalMatches: [],
  merchants: [],
  groupedMerchantProducts: [],
  expirationPresets: [],
  channelFields: [
    { label: 'Site', selected: false },
    { label: 'Newsletter', selected: false },
    { label: 'Facebook', selected: false },
    { label: 'Instagram', selected: false },
    { label: 'YouTube', selected: false },
    { label: 'Other', selected: false },
  ],
  stockStatusFields: [
    { label: 'Feed status', value: null, selected: false },
    { label: 'In stock', value: true, selected: false },
    { label: 'Out of stock', value: false, selected: false },
  ],
  auctionStatusFields: [
    { label: 'Exclusive', value: true, selected: false },
    { label: 'Active', value: null, selected: false },
    { label: 'Disabled', value: false, selected: false },
  ],
  auctionConfigFormat: {
    is_in_stock: {
      value: null,
      ttl_expiration: null,
    },
    participate_in_auction: {
      value: null,
      ttl_expiration: null,
    },
    sale_price: {
      value: null,
      ttl_expiration: null,
    },
  },
  priceRegex: /^[0-9]\d{0,2}(?:,?\d{3})*(?:\.\d{2})?$/,
};
