import React from 'react';
import classNames from 'classnames';

export type Loader = {
  className?: string;
  imageShape?: 'circle' | 'square';
  color?: string;
  orientation?: 'vertical' | 'horizontal';
  textCount?: number;
  imageSize?: string;
  headlineSize?: string;
  textSize?: string;
  lineLength?: string;
  textClassName?: string;
  border?: boolean;
  children?: React.ReactNode;
  bgColor?: string;
};

export const SkeletonLoader = ({
  className,
  imageShape,
  color = 'bg-neutral-opacity-100',
  orientation = 'vertical',
  imageSize = 'h-20 w-20',
  textCount = 0,
  headlineSize,
  textSize = 'text-sm',
  lineLength,
  textClassName,
  border,
  children,
  bgColor = 'bg-white',
}: Loader) => {
  function getText() {
    const lines = [];
    for (let i = 0; i < textCount; i++) {
      lines.push(
        <div
          key={i}
          className={classNames(
            'rounded',
            color,
            textSize,
            lineLength,
            textClassName,
          )}
        >
          &nbsp;
        </div>,
      );
    }
    return lines;
  }
  return (
    <div
      className={classNames(
        'rounded-md my-30 h-full w-full',
        className,
        bgColor,
        {
          'border border-gray-200': border,
        },
      )}
    >
      <div
        className={classNames('flex animate-pulse h-full w-full items-center', {
          'flex-col': orientation === 'vertical',
        })}
      >
        {imageShape && (
          <div
            className={classNames('top-0 left-0', color, imageSize, {
              'rounded-full': imageShape === 'circle',
              rounded: imageShape === 'square',
            })}
          />
        )}
        {!!textCount && (
          <div
            className={classNames(
              'flex flex-1 flex-col justify-evenly py-1 mx-2',
              {
                'h-full': orientation === 'horizontal',
              },
            )}
          >
            {headlineSize ? (
              <div
                className={classNames(
                  'rounded',
                  color,
                  headlineSize,
                  lineLength,
                )}
              >
                &nbsp;
              </div>
            ) : null}
            {getText()}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
