import { Context } from '..';
import moment from 'moment';
import {
  DateRange,
  DateRangeValue,
} from '@frontend/howl-web-app/shared/typings';

export const setSelectedSegment = (
  { state }: Context,
  selectedSegment: { label: string; value: string },
) => {
  state.ecommDesk.yourPartners.selectedSegment = selectedSegment;
};

export const setPartners = ({ state }: Context, partners: any[]) => {
  state.ecommDesk.yourPartners.partners = partners;
};

export const setShowAddPartners = async (
  { effects, state }: Context,
  partnerIds: number[] | null,
) => {
  state.ecommDesk.yourPartners.listsLoading = true;
  state.ecommDesk.yourPartners.showAddPartners = partnerIds;

  if (partnerIds && partnerIds?.length > 0) {
    const params = {
      merch_id: state.organization.selectedOrgId,
      scopes: 'account_scope',
      pub_id: partnerIds[0],
    };
    const respData = await effects.api.get(
      `/api/v0/merchants/target_lists/publisher/target_list_info/`,
      params,
    );
    state.ecommDesk.yourPartners.lists = respData.data.data[0].target_list_info;
  }
  state.ecommDesk.yourPartners.listsLoading = false;
};

export const addPartnerToList = (
  { state, effects }: Context,
  {
    listId,
    partnerIds,
  }: {
    listId: number;
    partnerIds: number[];
  },
) => {
  const endpoint = `/api/v0/merchants/${state.organization.selectedOrgId}/target_lists/publisher/${listId}/update_target_list/`;

  return effects.api.put(endpoint, {
    target_list: {
      target_list_items: partnerIds,
      target_list_scope: 'account_scope',
      target_list_append: true,
    },
  });
};

export const getPartners = async ({ effects, actions, state }: Context) => {
  state.ecommDesk.yourPartners.partnersLoading = true;

  const dateRange = actions.date.getPresetDateRanges().sevenDay;
  const dateFrom = actions.date.formatDate({ dateObj: dateRange.startDate });
  const dateTo = actions.date.formatDate({ dateObj: dateRange.endDate });
  const params = {
    date_from: dateFrom,
    date_to: dateTo,
    segment: state.ecommDesk.yourPartners.selectedSegment.value.toUpperCase(),
    page: state.ecommDesk.yourPartners.page_info.page,
    per_page: state.ecommDesk.yourPartners.page_info.per_page,
  };
  try {
    const respData = await effects.api.get(
      `/api/v0/merchants/${state.organization.selectedOrgId}/your_partners/`,
      params,
    );
    const partners = respData.data.data[0].partners;
    const totalItems = respData.data.data[0].total;
    state.ecommDesk.yourPartners.partners =
      params.page === 1
        ? partners
        : [...state.ecommDesk.yourPartners.partners, ...partners];
    state.ecommDesk.yourPartners.page_info.totalItems = totalItems;
  } catch (e) {
    console.error(e);
  }

  state.ecommDesk.yourPartners.partnersLoading = false;
};

export const setPagination = (
  { state }: Context,
  { page, per_page }: { page?: number; per_page?: number },
) => {
  if (page) state.ecommDesk.yourPartners.page_info.page = page;
  if (per_page) state.ecommDesk.yourPartners.page_info.per_page = per_page;
};

export const getGraphParams = (
  { actions }: Context,
  { dateRange, noCompare }: { dateRange: any; noCompare?: boolean },
) => {
  const params: {
    date_from: string;
    date_to: string | null;
    compare_from?: string;
    compare_to?: string;
    fields: string;
    granularity: 'hourly' | 'daily' | 'monthly' | 'yearly';
    group_by: string;
    order_by: string;
  } = {
    date_from: dateRange.date_from,
    date_to: dateRange.granularity !== 'hourly' ? dateRange.date_to : null,
    fields: `event_${
      dateRange.granularity === 'hourly' ? 'hour' : 'date'
    },merch_id,merchant_spend,clicks,attributed_revenue,roi,rpc,publishers,flat_fees,flat_fee_budget,bonus_spend`,
    granularity: dateRange.granularity,
    group_by: 'merch_id',
    order_by: `event_${dateRange.granularity === 'hourly' ? 'hour' : 'date'}`,
  };

  if (['daily', 'monthly'].includes(dateRange.granularity) && !noCompare) {
    params.compare_from = actions.date
      .offsetFromDate({
        originalDate: dateRange.date_from,
        number: -1,
        unit: 'year',
      })
      .format('YYYY-MM-DD');
    params.compare_to = actions.date
      .offsetFromDate({
        originalDate: dateRange.date_to,
        number: -1,
        unit: 'year',
      })
      .format('YYYY-MM-DD');
    params.fields = `${params.fields},merchant_spend_before,clicks_before,attributed_revenue_before,rpc_before,publishers_before,flat_fees_before,flat_fee_budget_before,bonus_spend_before`;
  }

  return params;
};

export const getGraphData = (
  { actions, effects, state }: Context,
  {
    merchId,
    dateRange,
    noCompare,
  }: { merchId: any; dateRange: any; noCompare?: boolean },
) => {
  const endpoint = `/api/v0/merchants/${merchId}/performance/`;

  const params = actions.ecommDesk.getGraphParams({ dateRange, noCompare });

  return effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

const slices: { [key in DateRangeValue]: { start: number; end?: number } } = {
  oneDay: { start: 0, end: undefined },
  sevenDay: { start: -7, end: undefined },
  thisMonth: {
    start: moment().date() * -1,
    end: undefined,
  },
  lastMonth: { start: 0, end: moment().subtract(1, 'months').daysInMonth() },
  halfYear: { start: -6, end: undefined },
  thisYear: { start: (moment().month() + 1) * -1, end: undefined },
  multiYear: { start: 0, end: undefined },
};

const defaultStat = {
  attributed_revenue: '0',
  merchant_spend: '0',
  clicks: 0,
  rpc: '0',
  roi: '0',
  publishers: 0,
  flat_fees: '0',
  flat_fee_budget: '0',
  bonus_spend: '0',
  attributed_revenue_before: '0',
  merchant_spend_before: '0',
  clicks_before: 0,
  rpc_before: '0',
  publishers_before: 0,
  flat_fees_before: '0',
  flat_fee_budget_before: '0',
  bonus_spend_before: '0',
};

export const processGraphData = (
  { actions }: Context,
  {
    graphData,
    projections,
    range,
  }: { graphData: any; projections: any; range: DateRange },
) => {
  const { getStartOf, getPresetDateRanges, offsetFromDate, subtractDates } =
    actions.date;
  const sliceValue = slices[range.value];
  const hourlyData = [];
  const isHourly = range.granularity === 'hourly';
  const hasProjections = [
    'sevenDay',
    'thisMonth',
    'halfYear',
    'thisYear',
    'multiYear',
  ].includes(range.value);
  let projectionDate: string;

  if (hasProjections) {
    if (range.value === 'sevenDay' || range.value === 'thisMonth') {
      projectionDate = getStartOf({ date: moment(), unit: 'day' }).format(
        'YYYY-MM-DD',
      );
    } else if (range.value === 'halfYear' || range.value === 'thisYear') {
      projectionDate = getStartOf({ date: moment(), unit: 'month' }).format(
        'YYYY-MM-DD',
      );
    } else if (range.value === 'multiYear') {
      projectionDate = getStartOf({ date: moment(), unit: 'year' }).format(
        'YYYY-MM-DD',
      );
    }
  }

  const dateRange = getPresetDateRanges()[range.value];

  const newData = !isHourly ? [] : graphData;
  if (!isHourly) {
    for (let i = 0; i < graphData.length; i++) {
      // First element should be first date in the range
      if (i === 0) {
        // If first element is not first date in the range, stick an empty stat in front
        if (graphData[i].event_date.split('-').at(-1) !== '01') {
          newData.push({ event_date: dateRange.startDate, ...defaultStat });
        }
        newData.push(graphData[i]);
        continue;
      }

      // If the current index isn't incremented by 1 unit from the previous, there's a
      // missing date event & we need to stick an empty event in there
      const dateDiff = subtractDates({
        d1: graphData[i].event_date,
        d2: graphData[i - 1].event_date,
        unit: range.unit,
      });
      // Date subtraction func adds a 1 to the result, but we're checking to see if the dates are non-sequential
      if (Math.round(dateDiff as number) !== 2) {
        newData.push({
          event_date: offsetFromDate({
            originalDate: graphData[i - 1].event_date,
            number: 1,
            unit: range.unit,
          }).format('YYYY-MM-DD'),
          ...defaultStat,
        });
      }
      newData.push(graphData[i]);
    }
  }

  if (isHourly) {
    const currentUtcTime = moment().utc();
    for (let i = 1; i <= 24; i++) {
      const dateTime = actions.date
        .offsetFromDate({
          originalDate: actions.date.getStartOf({
            date: currentUtcTime,
            unit: 'hour',
          }),
          number: -i,
          unit: 'hour',
          isUtc: true,
        })
        .format('YYYY-MM-DDTHH:mm:ss[Z]');

      const datum = graphData.find((gd: any) => gd.event_hour === dateTime);
      if (datum) {
        hourlyData.unshift(datum);
      } else {
        hourlyData.unshift({
          event_hour: dateTime,
          ...defaultStat,
        });
      }
    }
  }

  const data = isHourly ? hourlyData : newData;

  return data
    .map((datum: any) => {
      return {
        name: isHourly ? datum.event_hour : datum.event_date,
        // Current period
        attributed_revenue: parseFloat(datum.attributed_revenue),
        clicks: parseFloat(datum.clicks),
        merchant_spend: parseFloat(datum.merchant_spend),
        rpc: parseFloat(datum.rpc),
        roi: parseFloat(datum.roi),
        publishers: parseFloat(datum.publishers),
        flat_fees: parseFloat(datum.flat_fees),
        flat_fee_budget: parseFloat(datum.flat_fee_budget),
        bonus_spend: parseFloat(datum.bonus_spend),

        // Previous period
        attributed_revenue_before: parseFloat(
          datum.attributed_revenue_before || 0,
        ),
        clicks_before: parseFloat(datum.clicks_before || 0),
        merchant_spend_before: parseFloat(datum.merchant_spend_before || 0),
        rpc_before: parseFloat(datum.rpc_before || 0),
        publishers_before: parseFloat(datum.publishers_before || 0),
        flat_fees_before: parseFloat(datum.flat_fees_before || 0),
        flat_fee_budget_before: parseFloat(datum.flat_fee_budget_before || 0),
        bonus_spend_before: parseFloat(datum.bonus_spend_before || 0),

        // Projections
        attributed_revenue_projection:
          datum.event_date === projectionDate
            ? parseFloat(
                projections[range.granularity]?.attributed_revenue || 0,
              )
            : 0,
        merchant_spend_projection:
          datum.event_date === projectionDate
            ? parseFloat(projections[range.granularity]?.merchant_spend || 0)
            : 0,

        // Yoy projections
        attributed_revenue_full_projection:
          datum.event_date === projectionDate
            ? parseFloat(
                projections[range.granularity]
                  ?.attributed_revenue_before_full || 0,
              )
            : 0,
        merchant_spend_full_projection:
          datum.event_date === projectionDate
            ? parseFloat(
                projections[range.granularity]?.merchant_spend_before_full || 0,
              )
            : 0,
      };
    })
    .slice(sliceValue.start, sliceValue.end);
};

export const calculateProjections = (
  { actions }: Context,
  {
    daily,
    monthly,
    monthlyYoy,
    yearly,
  }: {
    daily: any;
    monthly: any;
    monthlyYoy: any;
    yearly: any;
  },
) => {
  // Daily
  const dailyProjections = calculateProjection(
    [parseFloat(daily.attributed_revenue), parseFloat(daily.merchant_spend)],
    actions.date.dayElapsed(false),
  );

  // Monthly
  const monthlyProjections = calculateProjection(
    [
      parseFloat(monthly.attributed_revenue),
      parseFloat(monthly.merchant_spend),
    ],
    actions.date.monthElapsed(false),
  );

  // Yearly
  const yearlyProjections = calculateProjection(
    [parseFloat(yearly.attributed_revenue), parseFloat(yearly.merchant_spend)],
    actions.date.yearElapsed(false),
  );

  return {
    daily: {
      attributed_revenue: dailyProjections[0],
      merchant_spend: dailyProjections[1],
    },
    monthly: {
      attributed_revenue: monthlyProjections[0],
      merchant_spend: monthlyProjections[1],
      attributed_revenue_before_full: monthlyYoy
        ? monthlyYoy.attributed_revenue - monthly.attributed_revenue_before || 0
        : 0,
      merchant_spend_before_full: monthlyYoy
        ? monthlyYoy.merchant_spend - monthly.merchant_spend_before || 0
        : 0,
    },
    yearly: {
      attributed_revenue: yearlyProjections[0],
      merchant_spend: yearlyProjections[1],
    },
  };
};

// For stacked-bar display purposes, we want the projection value returned to only represent
// the difference between pacing & value, not the entire pacing value
const calculateProjection = (values: number[], timeElapsed: number) => {
  // pacing = (value * total time units) / elapsed time units
  // elapsed functions from date service return elapsed / total
  // value * (1 / (elapsed / total)) => (value * total) / elapsed = pacing
  return values.map((value) => {
    const pacingValue = Math.round(100 * (value * (1 / timeElapsed))) / 100;
    return (pacingValue - value).toFixed(2);
  });
};
