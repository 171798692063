type EcommPartnerDiscovery = {
  partners: any[];
  search: string;
  order_by: string | null;
  direction: 'asc' | 'desc' | null;
  page: number | null;
  per_page: number | null;
  totalItems: number | null;
  categoryFilter: string[];
  initialCategorySet: boolean;
  platformFilter: string[];
  partnerTypeFilter: string[];
  showAddPartners: number[] | null;
  showAddRemovePartner: number | null;
  lists: any[] | null;
  allCategories: string[];
  loading: boolean;
  segmentFilter: string[];
  gmvFilter: 'all' | 'low' | 'medium' | 'high' | 'veryHigh';
  reachFilter: 'all' | 'low' | 'medium' | 'high' | 'veryHigh';
  tierFilter: 'all' | 'low' | 'medium' | 'high';
  selectedRows?: any;
  listsLoading: boolean;
  enrichmentsLoading: boolean;
  skipNextCall: boolean;
  platformModalData: {
    showModal: boolean;
    platformData: {
      pub_id: number | null;
      platforms: any[] | null;
    };
  };
};

export const state: EcommPartnerDiscovery = {
  partners: [],
  search: '',
  order_by: 'creator_tier_type',
  direction: 'desc',
  page: 1,
  per_page: 20,
  totalItems: null,
  categoryFilter: [],
  initialCategorySet: false,
  platformFilter: [],
  partnerTypeFilter: [],
  segmentFilter: ['NEW_TO_BRAND'],
  gmvFilter: 'all',
  reachFilter: 'all',
  tierFilter: 'all',
  showAddPartners: null,
  showAddRemovePartner: null,
  lists: null,
  allCategories: [],
  loading: false,
  selectedRows: {},
  listsLoading: false,
  enrichmentsLoading: false,
  skipNextCall: false,
  platformModalData: {
    showModal: false,
    platformData: {
      pub_id: null,
      platforms: [],
    },
  },
};
