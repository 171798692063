import { Transition, Dialog as HeadlessDialog } from '@headlessui/react';
import classNames from 'classnames';
import React, { FC, Fragment, ReactNode } from 'react';

export type BasicDialogProps = {
  /** Tells the dialog to open */
  isOpen: boolean;

  /**React node to add inside the dialog */
  children: ReactNode;

  /** Callback for closing the dialog */
  onClose: () => void;

  className?: string;

  backgroundOverlayClass?: string;
};

export const BasicDialog: FC<BasicDialogProps> & { filePath: string } = ({
  isOpen,
  children,
  onClose,
  className = 'p-4',
  backgroundOverlayClass = 'fixed inset-0 bg-black bg-opacity-25 top-16',
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={backgroundOverlayClass} />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto top-0">
          <div
            className={classNames(
              'flex min-h-full items-center justify-center text-center',
              className,
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
};

BasicDialog.filePath = 'libs/shared/ui-kit/src/lib/dialog/basic-dialog.tsx';
BasicDialog.displayName = 'BasicDialog';
