/* eslint @typescript-eslint/no-useless-constructor: 0 */
import React, { Component } from 'react';
import { getClass } from '../../../../ship/utils/component-helpers';
import { Link } from '@frontend/shared-ui-kit/link';

import { Icon } from '@frontend/shared-ui-kit/icon';
import { withOvermind } from '@frontend/howl-web-app/overmind';

class NavItem extends Component {
  isSelected(path) {
    const { currentPath } = this.props;
    const itemPath = this.props.item.path;
    if (path) {
      if (
        (currentPath.includes('/campaign-performance') ||
          (currentPath.includes('/campaign-center') &&
            currentPath.includes('/campaigns/'))) &&
        !currentPath.includes('?io') &&
        path === '/campaign-center/campaigns'
      ) {
        return true;
      } else if (
        (currentPath.includes('/campaign-center/io/') ||
          currentPath.includes('overview?io')) &&
        path === '/campaign-center/io' &&
        !currentPath.includes('/campaigns/')
      ) {
        return true;
      } else {
        return currentPath.split('?')[0].endsWith(path); // subnavItem path (ignore params)
      }
    }
    // On megatable under Insights subnav ie not on merchants table
    const notMerchTable =
      (!currentPath.endsWith('/merchants') || itemPath === '/analytics') &&
      currentPath.indexOf(itemPath) > -1;

    return currentPath.endsWith(itemPath) || notMerchTable;
  }

  subnavCollapsed() {
    return this.props.item.subnav && !this.props.item.subnavExpanded;
  }

  subnavExpanded() {
    if (!this.props.item.subnav) return false;
    return this.props.item.subnavExpanded;
  }

  render() {
    const { item } = this.props;
    const className = item.className || item.path?.replace('/', '');
    let subnav;
    const isStagingPubTestUser =
      this.props.overmind.state.session.sessionData.userId === 233638 &&
      ['staging', 'local'].includes(process.env.NEXT_PUBLIC_APP_ENV);
    const isReleasePubTestUser =
      [2458, 2464].includes(
        this.props.overmind.state.session.sessionData.userId,
      ) && ['release'].includes(process.env.NEXT_PUBLIC_APP_ENV);
    const isPubTestUser = isStagingPubTestUser || isReleasePubTestUser;

    const isProductResearch =
      isPubTestUser && ['/product-research'].includes(item.path);
    const { role } = this.props.overmind.state.account;
    const { allSites } = this.props.overmind.state.organization;
    const checkRole = (navItem) => {
      if (navItem.permissions) {
        if (navItem.permissions.includes(role)) {
          return true;
        } else if (!this.props.flags['rolesAndPermissionsTab']) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    const isLocked = allSites && !item.allSites;

    if (item.subnav) {
      subnav = (
        <ul
          className={getClass(
            'subnav inline-block text-white pb-2 px-0 w-full',
            isLocked && 'opacity-50',
          )}
        >
          {item.subnav
            // Exclude navItem hidden via Launch Darkly
            .filter((navItem) => {
              return (
                !navItem.flag ||
                (this.props.flags.hasOwnProperty(navItem.flag) &&
                  this.props.flags[navItem.flag] === navItem.flagValue)
              );
            })

            // Exclude navItem if customFlag exists and does not evaluate to true
            .filter((navItem) =>
              navItem.hasOwnProperty('customFlag') ? navItem.customFlag : true,
            )
            .filter((navItem) => checkRole(navItem))
            // Exclude navItems under feature toggle
            .filter((item) => {
              return isProductResearch ||
                this.props.overmind.state.session.sessionData.isAdminView
                ? true
                : !item.featureToggle;
            })
            // Exclude mobile-only nav items if on desktop
            .filter(
              (item) =>
                (item.mobileOnly && this.props.isMobile) || !item.mobileOnly,
            )
            // Exclude creator-only nav items if not creator
            .filter(
              (item) =>
                (item.creatorOnly &&
                  this.props.overmind.state.session.sessionData
                    .publisher_type === 2) ||
                !item.creatorOnly,
            )
            .map((subnavItem, index) => {
              const dataPath =
                !subnavItem.path || !subnavItem.path.startsWith('http')
                  ? `${item.path}${subnavItem.path}`
                  : subnavItem.path;

              const subnavClass = subnavItem.path.replace('/', '');
              const newTag = subnavItem.newTag ? (
                <Icon
                  iconName="newTag"
                  size={16}
                  className="inline-flex relative ml-4 top-1"
                />
              ) : null;

              const isExternalDomain = subnavItem.path.startsWith('http');

              let urlPath = isExternalDomain
                ? subnavItem.path
                : `${this.props.orgPath}${item.path}${subnavItem.path}`;

              if (subnavItem.absolutePath) {
                urlPath = `${this.props.orgPath}${subnavItem.path}`;
              }
              const titleOverrides = {
                Creators: 'Partners',
              };

              return (
                <Link
                  key={index}
                  href={urlPath}
                  disabled={isLocked}
                  dataPath={dataPath}
                  dataCy={subnavItem.path}
                  dataRef={subnavItem.path}
                >
                  <li
                    className={getClass(
                      `subnav-item text-white block relative ${subnavClass} flex transition rounded-full`,
                      {
                        'bg-[#373737]': this.isSelected(
                          `${item.path}${subnavItem.path}`,
                        ),
                        'hover:bg-[#373737] cursor-pointer': !isLocked,
                        'cursor-not-allowed': isLocked,
                      },
                    )}
                  >
                    <div
                      className={getClass(
                        'flex text-white appearance-none border-none pb-1.5 px-6 pt-2 m-0 w-full h-full text-left items-center space-x-2',
                        isLocked ? 'cursor-not-allowed' : 'cursor-pointer',
                      )}
                      data-cy={subnavItem.label}
                      target={isExternalDomain ? '_blank' : ''}
                      onClick={() => {
                        if (isLocked) return;
                        this.props.onSubnavItemClicked();
                      }}
                    >
                      <span className="w-[15px]" />
                      <div data-cy="subnav-item-label">
                        {titleOverrides[subnavItem.label]
                          ? titleOverrides[subnavItem.label]
                          : subnavItem.label}
                        {newTag}
                        {subnavItem.beta && (
                          <span className="ml-2 py-[2px] px-[6px] rounded-lg bg-indigo-500 text-xxs text-black font-bold">
                            BETA
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                </Link>
              );
            })}
        </ul>
      );
    }

    const icon = item.icon ? (
      typeof item.icon === 'string' ? (
        <Icon iconName={item.icon} size={15} />
      ) : (
        item.icon
      )
    ) : null;

    const exclamationIcon =
      item.label === 'Payments' &&
      this.props.overmind.state.payment.accountLink &&
      this.props.overmind.actions.payment.showAlertIcon(
        this.props.overmind.state.payment.accountLink,
      ) ? (
        <Icon
          iconName="filledAlert"
          className="ml-2"
          size={15}
          dataCy="sidebar-payment-setup-alert"
        />
      ) : null;

    if (
      !item.flag ||
      (item.flag &&
        this.props.flags.hasOwnProperty(item.flag) &&
        this.props.flags[item.flag] === item.flagValue)
    ) {
      const LinkWrapper = ({ item, children }) => {
        if (!item.subnav || item.isLink) {
          return (
            <Link
              dataCy={item.path}
              dataRef={item.path}
              href={`${this.props.orgPath}${item.path}`}
              disabled={isLocked}
              legacyBehavior
            >
              {children}
            </Link>
          );
        }

        return children;
      };

      const lockIcon = <Icon iconName="lockClosed" size={15} />;

      return (
        <li
          className={getClass(`nav-item w-full inline-block ${className}`)}
          title={isLocked && 'Disabled for All Sites'}
        >
          <LinkWrapper item={item}>
            <a
              className={'text-ice-200 block'}
              data-cy={item.path}
              data-ref={item.path}
            >
              <button
                aria-label={item.label}
                className={getClass(
                  `appearance-none border-none p-0 m-0 w-full rounded-full ${
                    isLocked
                      ? 'cursor-not-allowed'
                      : !item.subnav || item.isLink
                      ? 'cursor-pointer'
                      : 'cursor-default'
                  }`,
                  {
                    'bg-[#373737]': this.isSelected() && !this.subnavExpanded(),
                    'hover:bg-[#373737]':
                      (!item.subnav || item.isLink) && !isLocked,
                  },
                )}
                onClick={() => {
                  if (isLocked) return;
                  this.props.onNavItemClicked(item);
                }}
              >
                <div className="flex items-center h-8 px-6 space-x-2">
                  <div className="float-right">
                    {isLocked ? lockIcon : icon}
                  </div>
                  <span className={getClass(isLocked && 'opacity-50')}>
                    <div className="flex flex-row items-center">
                      <span>{item.label}</span>
                      {exclamationIcon}
                    </div>

                    {item.beta && (
                      <span className="ml-2 py-1 px-2 rounded-xl bg-indigo-500 text-xxs text-black font-bold">
                        BETA
                      </span>
                    )}
                    {item.new === true && (
                      <span className="ml-1.5 text-primary-pink-1000 text-xxs font-bold uppercase">
                        New
                      </span>
                    )}
                  </span>
                </div>
              </button>
            </a>
          </LinkWrapper>

          {subnav}
        </li>
      );
    }

    return null;
  }
}

export default withOvermind(NavItem);
