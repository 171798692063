import { Context } from '..';
import {
  CollectionResponse,
  StorefrontLink,
  StorefrontResponse,
  ModifyLink,
} from './state';
import { cloneDeep } from 'lodash';

export const setCollections = ({ state }: Context, value: any[]) => {
  state.storefront.collections = value;
};

export const _updatePublishedState = ({ state }: Context) => {
  let enabledCollection;
  state.storefront?.collections?.some((collection) => {
    if (collection.is_published && collection.storefront_links?.length) {
      enabledCollection = true;
      return true;
    } else {
      return false;
    }
  });

  if (enabledCollection && !state.storefront?.overview?.is_published) {
    state.storefront.showUnpublished = true;
  } else {
    state.storefront.showUnpublished = false;
  }
};

export const fetchStorefront = async ({ effects, state, actions }: Context) => {
  state.storefront.storefrontId = null;
  state.storefront.overview = null;
  state.storefront.collections = null;

  const result = await effects.api.get(
    `/api/v0/publishers/${state.organization.selectedOrgId}/storefront/`,
  );

  const data: StorefrontResponse = result.data.data[0];

  if (data.storefront) {
    state.storefront.storefrontId = data.storefront.storefront_id;
    state.storefront.overview = { ...data.storefront };

    actions.storefront.setCollections(data.collections);
    if (data.creator_channels && state.storefront.overview) {
      state.storefront.overview.channels = data.creator_channels;
    }
    actions.storefront._updatePublishedState();
  }
  return result;
};

export const fetchCollection = async (
  { effects, state }: Context,
  {
    collectionId,
  }: {
    collectionId: number;
  },
) => {
  const result = await effects.api.get(
    `/api/v0/publishers/${state.organization.selectedOrgId}/collections/${collectionId}/`,
  );

  const data: CollectionResponse = result.data.data[0];

  return data;
};

export const createStorefront = async (
  { effects, state }: Context,
  {
    profilePicUrl,
    title,
    urlSlug,
    bio,
    channels,
    website,
  }: {
    profilePicUrl: string;
    title: string;
    urlSlug: string;
    bio: string;
    channels: any[];
    website: string | null;
  },
) => {
  const params = {
    profile_pic_url: profilePicUrl,
    title: title,
    url_slug: urlSlug,
    bio: bio,
    channels: channels,
    website: website,
    is_published: false,
  };
  const result = await effects.api.post(
    `/api/v0/publishers/${state.organization.selectedOrgId}/storefront/`,
    params,
  );
  return result;
};

export const updateStorefront = async (
  { effects, state }: Context,
  {
    storefrontId,
    profilePicUrl,
    title,
    urlSlug,
    bio,
    channels,
    website,
    is_published,
  }: {
    storefrontId: number;
    profilePicUrl: string;
    title: string;
    urlSlug: string;
    bio: string;
    channels: any[];
    website: string | null;
    is_published?: boolean;
  },
) => {
  const params = {
    profile_pic_url: profilePicUrl,
    title: title,
    url_slug: urlSlug,
    bio: bio,
    channels: channels,
    website: website,
    is_published: is_published,
  };
  const result = await effects.api.put(
    `/api/v0/publishers/${state.organization.selectedOrgId}/storefront/${storefrontId}/`,
    params,
  );
  return result;
};

export const uploadPicture = async (
  { effects, state, actions }: Context,
  {
    file,
    mediaType,
    devMocksFlag = false,
  }: {
    file: any;
    mediaType: 'ProfilePic' | 'ProductImg';
    devMocksFlag?: boolean;
  },
) => {
  let access_url;
  if (devMocksFlag) {
    access_url =
      mediaType === 'ProfilePic'
        ? 'https://static-bam-x-com.s3.amazonaws.com/staging/storefront/profiles/2893-1662656101.236277'
        : 'https://static-bam-x-com.s3.amazonaws.com/staging/storefront/products/1105-1662566953.9015';
  } else {
    const payload = {
      content_type: file.type,
      media_type: mediaType,
    };
    const res = await effects.api.post(
      `/api/v0/publishers/${state.organization.selectedOrgId}/storefront/create_upload/`,
      payload,
    );
    const upload_url = res.data.data[0].url_s3_presigned;
    access_url = res.data.data[0].access_url;
    const options = { headers: { 'Content-Type': file.type } };
    await effects.api.rawRequest('put', upload_url, file, options).catch(() => {
      actions.ui.triggerSnackbar({
        color: 'systemError',
        content: 'Image was not uploaded. Please try again later.',
      });
    });
  }
  const cdnUrl = access_url.replace(
    'https://static-bam-x-com.s3.amazonaws.com',
    'https://static.planethowl.com',
  );
  return cdnUrl;
};

export const setStorefrontPublished = async (
  { effects, state, actions }: Context,
  { storefrontId, published }: { storefrontId: number; published: boolean },
) => {
  const params = {
    is_published: published,
  };
  const result = await effects.api.put(
    `/api/v0/publishers/${state.organization.selectedOrgId}/storefront/${storefrontId}/`,
    params,
  );
  if (state.storefront.overview) {
    state.storefront.overview.is_published = published;
  }
  actions.storefront._updatePublishedState();

  return result;
};

export const createCollectionOverview = async (
  { effects, state }: Context,
  {
    storefrontId,
    title,
    description,
    social_post_url,
    social_post_channel_type,
    isPublished = false,
  }: {
    storefrontId: number;
    title: string;
    description: string;
    social_post_url?: string;
    social_post_channel_type?: string;
    isPublished?: boolean;
  },
) => {
  const params = {
    title: title,
    description: description,
    social_post_url: social_post_url,
    social_post_channel_type: social_post_channel_type,
    is_published: isPublished,
  };
  const result = await effects.api.post(
    `/api/v0/publishers/${state.organization.selectedOrgId}/storefront/${storefrontId}/collections/`,
    params,
  );
  return result;
};

export const updateCollectionOrder = async (
  { effects, state }: Context,
  {
    payload,
  }: {
    payload: {
      collection_ids: string[];
    };
  },
) => {
  return await effects.api.post(
    `/api/v0/publishers/${state.organization.selectedOrgId}/storefront/${state.storefront.storefrontId}/collections/update_ranks/`,
    payload,
  );
};

export const updateCollectionOverview = async (
  { effects, state, actions }: Context,
  {
    collectionId,
    title,
    description,
    social_post_url,
    social_post_channel_type,
    isPublished = false,
  }: {
    collectionId: number;
    title?: string;
    description?: string;
    social_post_url?: string;
    social_post_channel_type?: string;
    isPublished?: boolean;
  },
) => {
  const params = {
    title: title,
    description: description,
    social_post_url: social_post_url,
    social_post_channel_type: social_post_channel_type,
    is_published: isPublished,
  };
  const result = await effects.api.put(
    `/api/v0/publishers/${state.organization.selectedOrgId}/collections/${collectionId}/`,
    params,
  );
  const newState = cloneDeep(state.storefront.collections);

  const collection = newState?.find((i) => i.collection_id === collectionId);
  if (collection) {
    collection.is_published = isPublished;
    state.storefront.collections = newState;
  }
  actions.storefront._updatePublishedState();

  return result;
};

export const updateCollectionLinks = async (
  { effects, state }: Context,
  {
    collectionId,
    links,
  }: {
    collectionId: number;
    links: {
      storefront_link_id: number;
      delete_record?: boolean;
      bam_link_id: number;
    }[];
  },
) => {
  const params = {
    storefront_links: links,
  };

  const result = await effects.api.put(
    `/api/v0/publishers/${state.organization.selectedOrgId}/collections/${collectionId}/storefront_links/`,
    params,
  );
  return result;
};

export const addCollectionLinks = async (
  { effects, state }: Context,
  {
    collectionId,
    links,
  }: {
    collectionId: number;
    links: any[];
  },
) => {
  const params = {
    link_ids: links,
  };

  const result = await effects.api.post(
    `/api/v0/publishers/${state.organization.selectedOrgId}/collections/${collectionId}/links/`,
    params,
  );
  return result;
};

export const editProductDetails = async (
  { state, effects }: Context,
  {
    payload,
    bamLinkId,
  }: {
    payload: {
      user_brand_name?: string;
      user_link_description?: string;
      user_product_name?: string;
      user_image_url?: string;
    };
    bamLinkId: number;
  },
) => {
  const result = await effects.api.put(
    `/api/v2/publishers/${state.organization.selectedOrgId}/smart_links/${bamLinkId}/user_product_details/`,
    payload,
  );
  return result;
};

export const deleteCollection = async (
  { state, effects }: Context,
  { collectionId }: { collectionId: number },
) => {
  const result = await effects.api.delete(
    `/api/v0/publishers/${state.organization.selectedOrgId}/collections/${collectionId}/`,
  );

  return result;
};

export const createExternalLink = async (
  { state, effects }: Context,
  payload: {
    user_brand_name?: string;
    user_link_description?: string;
    user_product_name?: string;
    url?: string;
  },
): Promise<{
  data: {
    data: {
      external_storefront_link: StorefrontLink;
    }[];
  };
}> => {
  const payloadData = {
    ...payload,
    exclusive_match_requested: false,
  };

  return effects.api.post(
    `/api/v0/publishers/${state.organization.selectedOrgId}/external_storefront_links/`,
    payloadData,
  );
};

export const setOldLinks = ({ state }: Context, oldLinks: string) => {
  state.storefront.oldLinks = oldLinks;
};

type BamLink = {
  bam_link_id: number;
};

export const setNewlyCreatedLinks = (
  { state }: Context,
  newLinks: BamLink[] | null,
) => {
  state.storefront.newlyCreatedLinks = newLinks;
};

export const setCollection = (
  { state }: Context,
  collection: CollectionResponse,
) => {
  state.storefront.collection = collection;
};

export const setCollectionLinks = (
  { state }: Context,
  collectionLinks: ModifyLink[],
) => {
  state.storefront.collectionLinks = collectionLinks;
};
