import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '@frontend/howl-web-app/feature-main';

const Button = (props: any) => {
  const {
    onClick,
    kind,
    color,
    size,
    disabled,
    iconStart,
    iconEnd,
    iconOnly,
    iconSize,
    label,
    submit,
    className,
    displayType,
    iconContainerClassName,
    allowPropagation,
    title,
    fontSize,
    cypressSelector,
    preventDefault,
    active,
    iconColorClassName,
  } = props;

  let kindClasses: any = [],
    iconClasses = '';
  switch (kind) {
    case 'primary':
      if (disabled) {
        kindClasses.push('text-neutral-gray-600 bg-neutral-gray-200');
        iconClasses = 'fill-neutral-gray-600';
      } else if (color === 'default') {
        kindClasses.push(
          'text-white hover:bg-hover-primary focus:bg-focus-primary',
        );
        if (active) kindClasses.push(`bg-focus-primary`);
        else kindClasses.push('bg-black');

        iconClasses = 'fill-white';
      } else if (color === 'destructive') {
        kindClasses.push(
          'text-white hover:bg-hover-primary-destructive focus:bg-focus-primary-destructive',
        );

        if (active) kindClasses.push(`bg-focus-primary-destructive`);
        else kindClasses.push('bg-destructive');

        iconClasses = 'fill-white';
      }
      break;
    case 'secondary':
    case 'text':
      if (kind === 'secondary') kindClasses.push('border bg-white');

      if (disabled) {
        kindClasses.push('text-neutral-gray-600 border-neutral-gray-600');
        iconClasses = 'fill-neutral-gray-600';
      } else if (color === 'default') {
        kindClasses.push(
          'text-black border-black hover:bg-hover-secondary focus:bg-focus-secondary',
        );
        if (active) {
          kindClasses.push(`bg-focus-secondary`);
        }
        iconClasses = 'fill-black';
      } else if (color === 'destructive') {
        kindClasses.push(
          'text-destructive border-destructive hover:bg-hover-secondary-destructive focus:bg-focus-secondary-destructive',
        );
        if (active) {
          kindClasses.push(`bg-focus-secondary-destructive`);
        }
        iconClasses = 'fill-destructive';
      }

      if (color === 'destructive' && size === 'none') {
        iconClasses = 'mr-2 -mt-1';
      }

      break;
    case 'link':
      kindClasses = 'underline text-primary-blue-1000';
      break;
    case 'none':
      break;
  }

  const buttonClasses = classNames(
    `base-btn ${
      displayType ? displayType : 'flex flex-row'
    } items-center leading-6 whitespace-nowrap`,
    color,
    className,
    fontSize,
    ...kindClasses,
    {
      'p-0': kind === 'none',
      'py-1 px-4 rounded-full':
        kind !== 'none' && !iconOnly && size === 'small',
      'py-2 px-4 rounded-full':
        kind !== 'none' && !iconOnly && size === 'large',
      'p-2 rounded-lg': iconOnly && size === 'small',
      'p-3 rounded-xl': iconOnly && size === 'large',
      'text-sm': size === 'small' && !fontSize,
      'text-base': size === 'large' && !fontSize,
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    },
  );

  if (iconOnly) {
    return (
      <button
        type={submit ? 'submit' : 'button'}
        onClick={(e) => {
          if (preventDefault) e.preventDefault();
          if (!allowPropagation) {
            e.stopPropagation();
          }
          onClick(e);
        }}
        className={buttonClasses}
        disabled={disabled}
        title={title}
        data-cy={cypressSelector || 'button'}
      >
        <div className={classNames(iconClasses, iconContainerClassName, 'p-0')}>
          <Icon
            size={iconSize}
            iconName={iconOnly}
            colorClassName={iconColorClassName}
          />
          <span className="sr-only">{label}</span>
        </div>
      </button>
    );
  }

  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={(e) => {
        if (preventDefault) e.preventDefault();
        if (!allowPropagation) {
          e.stopPropagation();
        }
        onClick(e);
      }}
      className={buttonClasses}
      disabled={disabled}
      title={title}
      data-cy={cypressSelector || 'button'}
    >
      {iconStart && (
        <div className={classNames(iconClasses, iconContainerClassName)}>
          <Icon size={iconSize} iconName={iconStart} color="inherit" />
        </div>
      )}
      {label}
      {iconEnd && (
        <div
          className={classNames(iconClasses, 'ml-2', iconContainerClassName)}
        >
          <Icon size={iconSize} iconName={iconEnd} />
        </div>
      )}
    </button>
  );
};

// TODO: Refactor this component to TS or replace w/shared-ui-kit button
Button.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'text', 'link', 'none']),
  color: PropTypes.oneOf(['default', 'destructive']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large']),
  disabled: PropTypes.bool,
  iconStart: PropTypes.string,
  iconEnd: PropTypes.string,
  iconOnly: PropTypes.string,
  iconSize: PropTypes.number,
  label: PropTypes.string,
  displayType: PropTypes.string,
  className: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  allowPropagation: PropTypes.bool,
  fontSize: PropTypes.string,
  cypressSelector: PropTypes.string,
  preventDefault: PropTypes.bool,
  active: PropTypes.bool,
  iconColorClassName: PropTypes.string,
};

Button.defaultProps = {
  kind: 'primary',
  color: 'default',
  onClick: () => {},
  size: 'small',
  disabled: false,
  iconStart: '',
  iconEnd: '',
  iconOnly: '',
  iconSize: 16,
  label: 'Click Here',
  allowPropagation: false,
  fontSize: 'text-sm',
  cypressSelector: '',
  preventDefault: false,
  active: false,
  iconColorClassName: '',
};

export default Button;
