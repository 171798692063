/* eslint @typescript-eslint/no-useless-constructor: 0 */
import { datadogRum } from '@datadog/browser-rum';
import React, { ErrorInfo } from 'react';
import {
  EmptyState,
  EmptyStateIcon,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStatePrimaryButton,
  EmptyStateTitle,
  EmptyStateSecondaryButton,
} from '@frontend/shared-ui-kit/v2/empty-state';
import { AlertCircle } from 'react-feather';
import { withRouter } from 'next/router';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log to Datadog here
    // console.log({ error, errorInfo });
    const renderingError: any = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo.componentStack;
    renderingError.cause = error;
    datadogRum.addError(renderingError);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col h-full pb-safe relative">
          {/* Nav Skeleton */}
          <div className="h-14 flex items-center justify-between px-4 sm:px-8 border-b border-subtle">
            <div className="w-48 h-8 bg-black bg-opacity-10 rounded-lg" />
            <div className="w-32 h-8 bg-black bg-opacity-10 rounded-lg" />
          </div>

          <div className="h-full transform relative">
            <EmptyState
              border={false}
              className="-translate-y-1/2 top-1/3 transform relative"
            >
              <EmptyStateContent variant="column" className="space-y-2">
                <EmptyStateIcon>
                  <AlertCircle size={24} />
                </EmptyStateIcon>
                <EmptyStateTitle>This page failed to load.</EmptyStateTitle>
                <EmptyStateDescription>
                  Please wait a few minutes before trying again.
                </EmptyStateDescription>
              </EmptyStateContent>

              <EmptyStateContent
                variant="row"
                className="space-x-4 justify-center"
              >
                <EmptyStateSecondaryButton
                  onClick={() => this.props.router.reload()}
                >
                  Reload
                </EmptyStateSecondaryButton>
                <EmptyStatePrimaryButton
                  onClick={() => this.props.router.back()}
                >
                  Go Back
                </EmptyStatePrimaryButton>
              </EmptyStateContent>
            </EmptyState>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
