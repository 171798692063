import { Context } from '..';

export const getMockData = () => {
  const types = ['Manual', 'Automated'];
  const statuses = ['Live', 'Other'];
  const collections: any = [];
  for (let i = 0; i < Math.floor(Math.random() * 10) + 50; i++) {
    const collection = {
      name: `Collection: ${i}`,
      status: statuses[Math.round(Math.random())],
      type: types[Math.round(Math.random())],
      merchants: Math.round(Math.random() * 100),
      products: Math.round(Math.random() * 100),
    };
    collections.push(collection);
  }
  return collections;
};

export const getCollections = (context: Context) => {
  return context.effects.api.get('/api/v0/collections/');
};

export const getProducts = (context: Context, params: any) => {
  return context.effects.api.get(
    '/api/v0/collections/canonical_products/product_search/',
    params,
  );
};

export const createCollection = (context: Context, params: any) => {
  return context.effects.api.post('/api/v0/collections/', params);
};

export const getMockProducts = () => {
  // TODO remove mock item and use fields in loop below include random prices or null
  const mockItem = {
    canonical_product: {
      canonical_product_names: 'Adidas® Kids 2-Piece Hyacinth Tracksuit Set',
      id: '1205aec3-8434-47b2-b9bf-cb12f6527f2f',
      label: 'canonical_product',
    },
    product_urls: [
      {
        store_page_url: 'www.google.com',
        is_in_stock: false,
        image_url:
          'https://s7d2.scene7.com/is/image/BedBathAndBeyond/190822134441c',
        merchant_product_id: '943824280',
        merchant_id: 1100,
        product_name: 'Adidas® Kids 2-Piece Hyacinth Tracksuit Set',
        brand: 'Adidas',
        id: 'bc8162c3-7227-4f20-8406-dbfcee46cba8',
        label: 'product_url',
        price_usd: '20.99',
        sale_price_usd: '15.99',
      },
    ],
  };
  const mockProducts: any = [mockItem];
  for (let i = 0; i < Math.floor(Math.random() * 10) + 50; i++) {
    const mockProduct = {
      canonical_product: { id: `cpid ${i}` },
      product_urls: [
        {
          product_name: `product ${i}`,
          merchant_product_id: `mpid ${i}`,
          is_in_stock: [true, false][Math.round(Math.random())],
        },
      ],
    };
    mockProducts.push(mockProduct);
  }

  return { data: mockProducts };
};
