import React from 'react';

export const ChromeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M35.3438 19.5114V19.5C35.3438 10.7479 28.2521 3.65625 19.5 3.65625C10.7479 3.65625 3.65625 10.7479 3.65625 19.5V19.5152C3.65625 28.2674 10.7479 35.359 19.5 35.359C28.2521 35.359 35.3438 28.2674 35.3438 19.5152V19.5038V19.5114ZM35.0886 16.6702L35.1114 16.7959L35.0886 16.6702ZM25.7728 24.3064C27.8104 21.1758 27.7723 17.093 25.35 14.2632L31.5352 13.9433C32.3159 15.6343 32.7539 17.5157 32.7539 19.5C32.7539 26.5535 27.2467 32.3197 20.296 32.7311L25.7728 24.3064ZM19.5 24.8435C16.5483 24.8435 14.1604 22.4555 14.1604 19.5038C14.1604 16.5445 16.5483 14.1642 19.5 14.1642C22.4517 14.1642 24.8396 16.5483 24.8396 19.5C24.8396 22.4517 22.4517 24.8435 19.5 24.8435ZM33.5613 12.2104L33.4471 11.9933L33.5613 12.2104ZM19.5 6.24609C24.1198 6.24609 28.1912 8.61123 30.5602 12.1951L20.5207 11.6657C16.8073 11.4486 13.2768 13.5396 12.0542 17.0396L8.67598 11.8447C11.0792 8.45889 15.0325 6.24609 19.5 6.24609ZM3.90381 22.3107C4.89404 27.8332 8.74453 32.3654 13.8671 34.3116C8.74453 32.373 4.89404 27.8408 3.90381 22.3107ZM6.24609 19.5C6.24609 17.371 6.74883 15.3601 7.64004 13.5776L12.1989 22.5431C13.8785 25.8451 17.4319 27.8637 21.111 27.1743L18.2927 32.7006C11.5362 32.0874 6.24609 26.4126 6.24609 19.5ZM18.6126 35.3209C18.7649 35.3285 18.9173 35.3361 19.0696 35.3399C18.9173 35.3323 18.7649 35.3285 18.6126 35.3209Z"
        fill="black"
      />
    </svg>
  );
};
