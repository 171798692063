import { Context } from '..';
import {
  apiThemeToUiThemeMap,
  ContentMeta,
  ContentSelection,
  ContentTypes,
  MetaMatchResults,
} from './state';
import { generateUuid, arrayToMap } from '@frontend/shared-utils';

export const resetContentModule = (context: Context) => {
  const reset = {
    products: {},
    brands: {},
    campaigns: {},
    videos: {},
  };

  context.state.contentModule.selectedContent = { ...reset };
  context.state.contentModule.contentMeta = { ...reset };
};

export const loadContentModuleById = async (
  context: Context,
  {
    contentModuleId,
    contentModuleTypeId,
  }: { contentModuleId: number; contentModuleTypeId: number; flags: any },
) => {
  const result = await context.effects.api.get(
    `/api/v0/modules/${contentModuleTypeId}/${contentModuleId}/`,
  );

  const itemData: any = result.data.data[0].data;
  const typeMap: { from: string; to: ContentTypes; idField: string }[] = [
    { from: 'merchants', to: 'brands', idField: 'merch_id' },
    { from: 'strategies', to: 'campaigns', idField: 'strategy_id' },
    {
      from: 'merchant_products',
      to: 'products',
      idField: 'merchant_product_id',
    },
  ];

  typeMap.forEach((typeItem) => {
    if (itemData[typeItem.from]?.length) {
      const contentMeta: ContentMeta[] = [];

      // we're nesting products under strategies, so add em to merchant_products
      // if (itemData[typeItem.from].products) {
      //   itemData['merchant_products'] = [
      //     ...itemData['merchant_products'],
      //     ...itemData[typeItem.from].products,
      //   ];
      // }

      context.state.contentModule.selectedContentLists[typeItem.to] = itemData[
        typeItem.from
      ].map((item: any) => {
        const newKey = generateUuid();
        const theId = item[typeItem.idField];

        contentMeta.push({ ...item, id: theId + '' });

        // TODO: add to match results under this key;
        return {
          id: theId + '',
          key: newKey,
          description: item.merch_description || undefined,
          recommendation_status: item.recommendation_status || null,
        };
      });

      context.actions.contentModule.setContentMeta({
        items: contentMeta,
        contentType: typeItem.to,
      });
    }
  });

  if (itemData['video']) {
    context.state.contentModule.selectedContent.videos['video_url'] = {
      key: 'video_url',
      id: '1',
      description: itemData.video.video_url,
    };
    context.state.contentModule.selectedContent.videos['thumbnail_url'] = {
      key: 'thumbnail_url',
      id: '1',
      description: itemData.video.thumbnail_url,
    };
  }

  return {
    name: itemData.module_name,
    label: itemData.module_label,
    description: itemData.module_description,
    color: apiThemeToUiThemeMap[itemData.module_color],
    status: itemData.module_status?.toLowerCase(),
  };
};

export const getFirstItemOfContentList = (
  context: Context,
  contentType?: ContentTypes,
) => {
  if (
    !contentType ||
    !context.state.contentModule.selectedContentLists[contentType].length
  )
    return null;

  return context.state.contentModule.selectedContentLists[contentType][0];
};

export const setSelectedContent = (
  context: Context,
  {
    items,
    contentType,
  }: { items: ContentSelection[]; contentType: ContentTypes },
) => {
  // turn into a hashmap for O(1) lookup
  context.state.contentModule.selectedContent[contentType] = Array.isArray(
    items,
  )
    ? arrayToMap(items, 'key')
    : items;
};

export const setContentMeta = (
  context: Context,
  { items, contentType }: { items: ContentMeta[]; contentType: ContentTypes },
) => {
  // turn into a hashmap for O(1) lookup
  context.state.contentModule.contentMeta[contentType] = arrayToMap(
    items,
    'id',
  );
};

export const updateContentMeta = (
  context: Context,
  { items, contentType }: { items: ContentMeta[]; contentType: ContentTypes },
) => {
  // turn into a hashmap for O(1) lookup
  const map = arrayToMap(items, 'id');

  context.state.contentModule.contentMeta[contentType] = {
    ...context.state.contentModule.contentMeta[contentType],
    ...map,
  };
};

export const refreshMatchResults = (context: Context) => {
  const newMatchResults: MetaMatchResults = {};
  const contentMetaMap = context.state.contentModule.contentMeta;
  const keys = Object.keys(
    context.state.contentModule.selectedContentLists,
  ) as ContentTypes[];

  keys.forEach((contentType) => {
    context.state.contentModule.selectedContentLists[contentType].forEach(
      (item) => {
        const meta =
          item.id && contentMetaMap[contentType][item.id]
            ? contentMetaMap[contentType][item.id]
            : null;

        newMatchResults[item.key] = {
          item: meta,
          error: !meta ? 'Error: Invalid ID' : meta.error,
        };
      },
    );
  });

  context.state.contentModule.metaMatchResults = newMatchResults;
};

export const setLoadingMeta = (context: Context, theValue: boolean) => {
  context.state.contentModule.loadingMeta = theValue;
};

export const setCategoryId = (context: Context, theValue: number) => {
  context.state.contentModule.categoryId = theValue;
};

export const getContentModuleTypeById = (
  context: Context,
  theValue: number,
) => {
  const settings = context.state.contentModule.moduleTypeSettings;
  const keys = Object.keys(settings || {});

  let contentModuleType = '';

  keys?.forEach((key) => {
    if (settings && settings[key].id === theValue) contentModuleType = key;
  });

  return contentModuleType;
};

export const fetchSelectableContentData = (
  context: Context,
  {
    categoryId,
    offerId,
    brandId,
    productId,
  }: {
    categoryId: number;
    offerId?: number;
    brandId?: number;
    productId?: number;
  },
) => {
  const payload: {
    category_id: number;
    strategy_id?: number;
    merch_id?: number;
    merchant_product_id?: number;
  } = {
    category_id: categoryId,
  };

  if (offerId) payload.strategy_id = offerId;
  if (brandId) payload.merch_id = brandId;
  if (productId) payload.merchant_product_id = productId;

  return (
    context.effects.api
      .get('/api/v0/modules/validate_selection/', payload)
      //TODO: this is goofy, so many nested datas
      .then((resp: any) => resp.data.data[0])
      .then((item: any) => item?.data || item)
  );
};

export const fetchCategory = (context: Context, theValue: number) => {
  return context.effects.api.get(
    `/api/v0/content_manager/overview/category/${theValue}/`,
  );
};

export const buildContentModule = async (
  context: Context,
  {
    moduleId,
    contentModuleType,
    categoryId,
    status,
    moduleName,
    displayLabel,
    description,
    color,
    selectedContentLists,
    isDraft,
  }: {
    moduleId?: number;
    contentModuleType: number;
    categoryId: number;
    status: 'draft' | 'published';
    moduleName?: string;
    displayLabel?: string;
    description?: string;
    color?: string;
    selectedContentLists: { [key in ContentTypes]: ContentSelection[] };
    isDraft?: boolean;
  },
) => {
  const payload: {
    status?: string;
    category_id?: number;
    merch_data: { merch_id: number; description?: string }[];
    strategy_data: {
      strategy_id: number;
      description?: string;
      recommendation_status?: number;
    }[];
    merchant_product_data: {
      merchant_product_id: number;
      description?: string;
    }[];
    video_data: { video_url?: string; thumbnail_url?: string };
    display_name?: string;
    display_label?: string;
    module_color?: string;
    description?: string;
    is_draft?: boolean;
  } = {
    merch_data: selectedContentLists.brands.map((item) => {
      return {
        merch_id: Number(item.id),
        description: item.description,
      };
    }),
    strategy_data: selectedContentLists.campaigns.map((item) => {
      return {
        strategy_id: Number(item.id),
        description: item.description,
        recommendation_status: item.recommendation_status,
      };
    }),
    merchant_product_data: selectedContentLists.products.map((item) => {
      return {
        merchant_product_id: Number(item.id),
        description: item.description,
      };
    }),
    video_data: {},
  };

  const videoUrl = selectedContentLists.videos.find(
    (item) => item.key === 'video_url',
  )?.description;
  const thumbnailUrl = selectedContentLists.videos.find(
    (item) => item.key === 'thumbnail_url',
  )?.description;
  if (videoUrl && thumbnailUrl) {
    payload.video_data = {
      video_url: videoUrl,
      thumbnail_url: thumbnailUrl,
    };
  }

  if (moduleName) payload.display_name = moduleName;
  if (displayLabel) payload.display_label = displayLabel;
  if (color) payload.module_color = color;
  if (description) payload.description = description;

  if (moduleId && !isDraft) {
    return context.effects.api.put(
      `/api/v0/modules/${contentModuleType}/${moduleId}/`,
      payload,
    );
  }

  payload.category_id = Number(categoryId);

  if (isDraft) {
    payload.is_draft = true;

    return context.effects.api.post(
      `/api/v0/modules/${contentModuleType}/preview/`,
      payload,
    );
  }

  payload.status = status;

  return context.effects.api.post(
    `/api/v0/modules/${contentModuleType}/`,
    payload,
  );
};
