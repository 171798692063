import React, { FC, useEffect, useState } from 'react';
import { FlagList } from './flag-list';
import { useClickOutside } from '@mantine/hooks';
import classNames from 'classnames';
import { ToggleButton } from './toggle-button';
import { useDecision } from '@frontend/shared-feature-flags';
import { useRouter } from 'next/router';

/** The HelpWidget component presents a fixed "need help?" prompt/button for customer service. */
export const FeatureFlagWidget: FC & {
  filePath: string;
} = () => {
  const router = useRouter();

  const [forceVisible, setForceVisible] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const ref = useClickOutside(() => setMenuOpen(false));

  const { decision, ready } = useDecision('feature_flag_widget');

  useEffect(() => {
    if (router?.query['showFlags']) setForceVisible(true);
  }, [router?.query['showFlags']]);

  if (!ready || (!decision.enabled && !forceVisible)) return null;

  return (
    <div
      className={classNames(
        'z-20 flex shrink-0 grow-0 justify-around gap-4 shadow-lg backdrop-blur-lg border-slate-600/60 bg-slate-800/50 fixed right-[2rem] bottom-[0.5rem] min-h-[auto] min-w-[20px] flex-col rounded-lg',
        {
          'border-t border p-2.5': menuOpen,
        },
      )}
      style={{ zIndex: 999999 }}
      ref={ref}
    >
      {menuOpen && <FlagList />}
      {!menuOpen && <ToggleButton onClick={() => setMenuOpen(!menuOpen)} />}
    </div>
  );
};

FeatureFlagWidget.displayName = 'FeatureFlagWidget';
FeatureFlagWidget.filePath =
  'libs/shared/ui-kit/src/lib/feature-flag-widget/widget.tsx';
