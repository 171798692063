import {
  RoleNames,
  useActions,
  useAppState,
} from '@frontend/howl-web-app/overmind';
import { isEmpty, listify } from 'radash';
import React, { useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { UserAvatarIcon } from '../../icon';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Icon } from '@frontend/shared-ui-kit/icon';
import { useClickOutside } from '@mantine/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountSwitcher } from './account-switcher';
import { NavItems } from './nav-items';
import { SignOut } from './sign-out';
import { SwitchAccountButton } from './switch-account-button';
import { useSession } from 'next-auth/react';
import { PortalAccountDropdown } from '../../portal-navigation/PortalAccountDropdown';

export const AccountDropdown = (props: any) => {
  const { sessionData } = useAppState().session;
  const { orgs, allSites, selectedOrg, selectedOrgId } =
    useAppState().organization;
  const { displayAlertIcon } = useAppState().publisher;
  const { setDisplayAlertIcon } = useActions().publisher;
  const { getAccountLink, showAlertIcon } = useActions().payment;
  const { accountLink } = useAppState().payment;
  const { getRole } = useActions().account;
  const router = useRouter();
  const flags = useFlags();
  const [toggleAccountSwitcher, setToggleAccountSwitcher] =
    useState<boolean>(false);
  const ref = useClickOutside(() => setToggleAccountSwitcher(false));
  const [loading, setLoading] = useState<boolean>(false);

  const { data: session } = useSession();
  const email_verified = session?.user?.email_verified;

  useEffect(() => {
    if (
      email_verified &&
      selectedOrgId &&
      flags['stripeAccess'] &&
      selectedOrg.type === 'publishers' &&
      selectedOrg.billing_platform === 1 // Stripe
    ) {
      const role = getRole();

      if (
        role &&
        [RoleNames.ACCOUNT_OWNER, RoleNames.BILLING_ADMIN].includes(role)
      ) {
        getAccountLink(selectedOrgId);
      }
    }
  }, [selectedOrgId, flags['stripeAccess'], selectedOrg]);

  useEffect(() => {
    if (selectedOrgId) {
      setLoading(false);
    }
  }, [selectedOrgId]);

  useEffect(() => {
    if (accountLink) {
      setDisplayAlertIcon(showAlertIcon(accountLink));
    }
  }, [accountLink]);

  if (!sessionData) return null;

  if (!router.query['publisherId'] && !router.query['merchantId']) return null;

  const displayName = !isEmpty(selectedOrg)
    ? selectedOrg.full_name
      ? `${selectedOrg.full_name}`
      : `${selectedOrg.short_name}`
    : '';

  let accounts: any = [];
  if (session) {
    accounts = [
      ...accounts,
      ...listify(
        session.user?.bamx_perms?.publishers,
        (key: any, value: Record<string, any>) => ({
          ...value,
          pub_id: key,
        }),
      ),
      ...listify(
        session.user?.bamx_perms?.merchants,
        (key: any, value: Record<string, any>) => ({
          ...value,
          merch_id: key,
        }),
      ),
    ];
  }

  const showSwitchAccounts =
    (sessionData?.userRole === 'publishers' ||
      sessionData?.userRole === 'merchants') &&
    !sessionData.isAdmin &&
    accounts.length > 1;

  return props.showPortalNavigation ? (
    <PortalAccountDropdown
      displayName={displayName}
      showSwitchAccounts={showSwitchAccounts}
      accounts={accounts}
      toggleAccountSwitcher={toggleAccountSwitcher}
      setToggleAccountSwitcher={setToggleAccountSwitcher}
      displayAlertIcon={displayAlertIcon}
    />
  ) : (
    <Popover>
      {({ open, close }: any) => {
        const onClose = close;
        return (
          <>
            <Popover.Button
              data-cy="nav-account"
              className="text-white text-sm h-10 cursor-pointer hover:bg-deep-space-400 inline-flex items-center rounded-lg px-3 md:mr-6"
            >
              <div>
                <div className="flex items-center">
                  <span className="hidden sm:block">
                    {loading ? null : displayName}
                  </span>
                  <div className="block sm:hidden">
                    <UserAvatarIcon />
                  </div>
                  {allSites && (
                    <Icon iconName="multiDocument" className="ml-2 w-4 h-4" />
                  )}
                </div>
              </div>
              <span
                className={classNames({
                  'hidden sm:inline': sessionData.isAdmin,
                })}
              >
                {open ? (
                  <ChevronUpIcon
                    className={classNames('h-7 w-7 ml-1 stroke-1')}
                  />
                ) : (
                  <ChevronDownIcon
                    className={classNames('h-7 w-7 ml-1 stroke-1')}
                  />
                )}
              </span>
            </Popover.Button>
            <Transition
              show={open}
              className="relative z-50 drop-shadow-lg"
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel
                className="absolute bg-white rounded-lg right-0 top-5 w-72 pt-3 overflow-hidden"
                data-cy="nav-dropdown"
              >
                <div
                  ref={ref}
                  className={classNames(
                    'w-[576px] flex flex-row transform transition',
                    {
                      '-translate-x-72': toggleAccountSwitcher,
                    },
                  )}
                >
                  <div
                    className="divide-y divide-neutral-gray-300 w-1/2"
                    data-cy="nav-dropdown-column-setting"
                  >
                    <p className="font-bold px-4 text-ellipsis text-sm mb-4">
                      {displayName}
                    </p>
                    <div>
                      <NavItems
                        close={onClose}
                        setToggleAccountSwitcher={setToggleAccountSwitcher}
                        displayAlertIcon={displayAlertIcon}
                      />

                      <div>
                        <SignOut />
                      </div>
                    </div>

                    {showSwitchAccounts && (
                      <SwitchAccountButton
                        setToggleAccountSwitcher={setToggleAccountSwitcher}
                      />
                    )}
                  </div>

                  {showSwitchAccounts && (
                    <AccountSwitcher
                      showSwitchAccounts={toggleAccountSwitcher}
                      accounts={accounts}
                      close={onClose}
                      setLoading={setLoading}
                      setToggleAccountSwitcher={setToggleAccountSwitcher}
                    />
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};
