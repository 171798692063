import { Context } from '..';
import { ColumnPreset } from './constants';
import { DateRange, DropdownOption } from './state';

export const setSearchQuery = async ({ state }: Context, search: string) => {
  // TODO: Temporary debug statement for investigating an issue that only surfaces on prod
  if (state.session.sessionData.isAdmin && search) {
    console.info('IO Search Update: ', search);
  }
  state.ecommCampaignCenterIO.searchQuery = search;
};

export const setRaisedRow = ({ state }: Context, rowIndex: number | null) => {
  state.ecommCampaignCenterIO.raisedRow = rowIndex;
};

export const setPage = ({ state }: Context, page: number) => {
  state.ecommCampaignCenterIO.page = page;
};

export const setPerPage = ({ state }: Context, perPage: number) => {
  state.ecommCampaignCenterIO.perPage = perPage;
};

export const setOrderBy = ({ state }: Context, orderBy: string) => {
  state.ecommCampaignCenterIO.orderBy = orderBy;
};

export const setDirection = ({ state }: Context, direction: string) => {
  state.ecommCampaignCenterIO.direction = direction;
};

export const setDateRange = ({ state }: Context, dateRange: DateRange) => {
  state.ecommCampaignCenterIO.dateRange = dateRange;
};

export const setColumnModalOpen = ({ state }: Context, isOpen: boolean) => {
  state.ecommCampaignCenterIO.columnModalOpen = isOpen;
};

export const setSelectedColumns = (
  { state }: Context,
  selected: DropdownOption[],
) => {
  state.ecommCampaignCenterIO.selectedColumns = [...selected];
};

export const setSelectedColumnPreset = (
  { state }: Context,
  selected: ColumnPreset,
) => {
  state.ecommCampaignCenterIO.selectedColumnPreset = selected;
};

export const setStatusFilter = (
  { state }: Context,
  selected: DropdownOption[],
) => {
  state.ecommCampaignCenterIO.statusFilter = [...selected];
};

export const setSelectedPubs = (
  { state }: Context,
  selected: DropdownOption[],
) => {
  state.ecommCampaignCenterIO.selectedPubs = [...selected];
};

export const setSelectedBudgetType = (
  { state }: Context,
  selected: DropdownOption[],
) => {
  state.ecommCampaignCenterIO.selectedBudgetType = [...selected];
};

export const fetchTableData = async (
  { effects, state }: Context,
  {
    merchantId,
    params,
  }: {
    merchantId: string;
    params: any;
  },
) => {
  try {
    state.ecommCampaignCenterIO.loading = true;
    const tableEndpoint = `/api/v0/merchants/${merchantId}/campaigns/overview/`;
    const totalsEndpoint = `/api/v0/merchants/${merchantId}/campaigns/aggregated_overview/`;
    const resp = await Promise.all([
      effects.api.get(tableEndpoint, params),
      effects.api.get(totalsEndpoint, params),
    ]);

    state.ecommCampaignCenterIO.tableData = resp[0].data.data[0];
    state.ecommCampaignCenterIO.totalsData = resp[1].data.data[0];
    state.ecommCampaignCenterIO.loading = false;
  } catch (error) {
    console.log({ error });
    state.ecommCampaignCenterIO.loading = false;
  }
};
