import { Context } from '..';
import { formatValue } from '@frontend/shared-utils';
import { EndpointParams, StrategyDataRow } from './state';

export const getDataFromActiveTab = (
  context: Context,
  { activeTab, params }: { activeTab: any; params: any },
) => {
  return activeTab === 'partners'
    ? context.actions.rate.getRates(params)
    : context.actions.rate.getStrategies(params);
};

export const getStrategies = (context: Context, params: EndpointParams) => {
  const endpoint = `/api/v0/publishers/${context.state.session.sessionData.orgId}/strategies_v2/`;
  const validParams = context.actions.rate.getValidParams({
    params,
    endpointType: 'strategies',
  });
  return context.effects.api.get(endpoint, validParams);
};

export const getRates = (context: Context, params: EndpointParams) => {
  const endpoint = `/api/v0/publishers/${context.state.session.sessionData.orgId}/rates_v2/`;
  const validParams = context.actions.rate.getValidParams({
    params,
    endpointType: 'rates',
  });
  return context.effects.api.get(endpoint, validParams);
};

export const getStrategyProducts = (
  context: Context,
  { params, strategyId }: { params: EndpointParams; strategyId: any },
) => {
  const endpoint = `/api/v0/publishers/${context.state.session.sessionData.orgId}/strategies/
			${strategyId}/products_v2`;

  const validParams = context.actions.rate.getValidParams({
    params,
    endpointType: 'strategy-products',
  });
  return context.effects.api.get(endpoint, validParams);
};

export const updateMerchantPublisherNetwork = (
  context: Context,
  params: any,
) => {
  const endpoint = `/api/v0/publishers/${context.state.session.sessionData.orgId}/update_merchant_publisher_network/`;
  const validParams = context.actions.rate.getValidParams({
    params,
    endpointType: 'merchant-pub-network',
  });
  return context.effects.api.put(endpoint, validParams);
};

export const getValidParams = (
  context: Context,
  { params, endpointType }: { params: any; endpointType: any },
) => {
  const filteredParams: any = Object.keys(params).reduce(
    (acc, curr) =>
      (context.state.rate.endpointParams as any)[endpointType].includes(curr)
        ? { ...acc, [curr]: (params as any)[curr] }
        : acc,
    {},
  );

  if (
    (endpointType === 'strategies' && params.search_type !== 'strategy_name') ||
    (endpointType === 'rates' && params.search_type !== 'merchant_name') ||
    !params.search_query
  ) {
    delete filteredParams.search_type;
    delete filteredParams.search_query;
  }

  // removes order_by discount breaks strategies endpoint
  if (
    endpointType === 'strategies' &&
    (filteredParams.order_by === 'discount' ||
      filteredParams.order_by === 'product_price')
  ) {
    delete filteredParams.order_by;
  }

  return filteredParams;
};

export const getVisibleDataCount = (
  context: Context,
  {
    dataRows,
    isRetailerOverview,
    participatingCount,
  }: {
    dataRows: any;
    isRetailerOverview?: any;
    participatingCount?: any;
  },
) => {
  return dataRows.filter(
    (dataRow: any) =>
      context.actions.rate.isVisible({
        dataRow,
        isRetailerOverview,
      }) &&
      (!participatingCount ||
        (parseFloat(dataRow.clicks) && dataRow.strategy_status !== 'upcoming')),
  ).length;
};

export const getParticipatingDisplayTxt = (
  context: Context,
  {
    strategies,
    isRetailerOverview,
  }: { strategies: any; isRetailerOverview: any },
) => {
  const dataLen = context.actions.rate.getVisibleDataCount({
    dataRows: strategies,
    isRetailerOverview,
    participatingCount: true,
  });
  return dataLen
    ? `Participating in ${dataLen} ${dataLen === 1 ? 'strategy' : 'strategies'}`
    : 'Not participating in any';
};

export const isVisible = (
  context: Context,
  { dataRow, isRetailerOverview }: { dataRow: any; isRetailerOverview?: any },
) => {
  // The row is always visible if it's a partner
  if ('strategies' in dataRow) return true;

  /*
   * Admins can see:
   * 		- Admin-only strategies and public strategies that are targeted to the given pub or targeted to no pubs
   * 		- Retailers that are in-network for the given pub
   *
   * Public users (publishers) can see:
   * 		- Public strategies that are targeted to the given pub or targeted to no pubs
   * 		- Retailers that are in-network for the given pub
   */
  const adminVisible = dataRow.is_admin_only
    ? context.state.session.sessionData.isAdminView
    : true;

  const isTargetedPublisher =
    dataRow.visible_to && dataRow.visible_to.length
      ? dataRow.visible_to.includes(context.state.session.sessionData.orgId)
      : true;

  const isEvergreen = dataRow.strategy_name === 'Evergreen Strategy';

  // Filter strategies that are out of network from overview page
  const inNetwork = !isRetailerOverview || dataRow.merchant_in_network;

  return isEvergreen || (inNetwork && adminVisible && isTargetedPublisher);
};

export const formatStrategyRates = (
  context: Context,
  dataRow: StrategyDataRow,
) => {
  return {
    commission_rate:
      dataRow.pricing_model && dataRow.pricing_model === 1
        ? // Commission rate returned as percent, divide to get decimal
          `${formatValue(dataRow.commission_rate, 'currency')} per click`
        : `${formatValue(
            dataRow.commission_rate,
            'percent',
            'commission_rate',
          )} Commission`,
    evergreen_rate:
      dataRow.pricing_model && dataRow.pricing_model === 1
        ? // Commission rate returned as percent, divide to get decimal
          `${formatValue(dataRow.evergreen_rate, 'currency')} per click`
        : `${formatValue(
            dataRow.evergreen_rate,
            'percent',
            'commission_rate',
          )} Commission`,
    strategy_bid: dataRow.strategy_bid
      ? `${formatValue(dataRow.strategy_bid, 'currency')} per click`
      : null,
  };
};

export const getStrategyBioColumns = (context: Context, strategy: any) => {
  const formattedRates = context.actions.rate.formatStrategyRates(strategy);
  const dateEnd = strategy.strategy_datetime_end
    ? context.actions.date.formatDate({
        dateObj: strategy.strategy_datetime_end,
        fmt: 'MM/DD/YYYY',
        isUtc: true,
      })
    : 'Present';
  return [
    {
      label: 'Strategy Rate',
      value: formattedRates.strategy_bid
        ? formattedRates.strategy_bid
        : formattedRates.commission_rate,
    },
    {
      label: 'Run Dates',
      value: `${context.actions.date.formatDate({
        dateObj: strategy.strategy_datetime_start,
        fmt: 'MM/DD/YYYY',
        isUtc: true,
      })}-${dateEnd}`,
    },
    {
      label: 'Offered By',
      value: strategy.merchant_name,
    },
  ];
};

export const getMockRetailer = (context: Context) => {
  const pricingModel = [1, 2, 4, 5][Math.floor(Math.random() * 4)];
  const publisherEPC = parseFloat(Math.random() + '').toFixed(2);
  const clicks = Math.floor(Math.random() * 10000);
  const publisherTotalEarnings = (parseFloat(publisherEPC) * clicks).toFixed(2);
  const strategies = [];
  const feeTypes = ['marketplace', 'direct_deal'];
  const strategyStatuses = [
    'upcoming',
    'expired',
    'running_and_active',
    'running_and_paused',
  ];

  const strategiesLength = Math.floor(Math.random() * 10 + 100);
  for (let i = 0; i < strategiesLength; i++) {
    const epc = parseFloat(Math.random() + '').toFixed(2);
    const clicks = Math.floor(Math.random() * 10000);

    const startOffset =
      Math.floor(Math.random() * 90) * [1, -1][Math.round(Math.random())];
    const endOffset = startOffset + Math.random() * 90;
    const now = context.actions.date.getDateTime();
    const start = context.actions.date.offsetFromDate({
      originalDate: now,
      number: startOffset,
    });
    const end = context.actions.date.offsetFromDate({
      originalDate: now,
      number: endOffset,
    });
    const currentStrategyStatus =
      startOffset > 0
        ? strategyStatuses[0]
        : context.actions.date.getCurrentDateTime() > end
        ? strategyStatuses[1]
        : strategyStatuses.slice(2)[
            Math.floor(Math.random() * (strategyStatuses.length - 2))
          ];

    strategies.push({
      strategy_id: 1000 + i,
      strategy_name: `Strategy ${i}`,
      merchant_name: 'Moda Operandi',
      commission_rate:
        pricingModel === 2 || pricingModel === 4
          ? String(Math.floor(Math.random() * 100))
          : null,
      strategy_bid:
        pricingModel === 1 ? parseFloat(Math.random() + '').toFixed(2) : null,
      publisher_total_earnings: (parseFloat(publisherEPC) * clicks).toFixed(2),
      publisher_epc: epc,
      clicks,
      visible_to: [Math.round(Math.random() * (1051 - 1049) + 1049)],
      merch_id: Math.floor(Math.random() * 10 + 1000),
      strategy_is_enabled: [true, false][Math.round(Math.random())],
      strategy_fee_type: feeTypes[Math.floor(Math.random() * feeTypes.length)],
      strategy_datetime_start: context.actions.date.toIso8601UtcDatetime(start),
      strategy_datetime_end: context.actions.date.toIso8601UtcDatetime(end),
      strategy_status: currentStrategyStatus,
    });
  }

  return {
    merch_id: 1000,
    slug: 'modaoperandi',
    merchant_name: 'Moda Operandi',
    logo_url:
      'https://static.bam-x.com/assets/logos/merchants/modaoperandi.png',
    marketplace_epc: parseFloat(Math.random() + '').toFixed(2),
    commission_rate:
      pricingModel !== 2 && pricingModel !== 4
        ? String(Math.floor(Math.random() * 100))
        : parseFloat(Math.random() + '').toFixed(2),
    pricing_model: pricingModel,
    publisher_epc: publisherEPC,
    clicks,
    publisher_total_earnings: publisherTotalEarnings,
    mock_strategies: strategies,
    is_in_network: !!Math.floor(Math.random() * 2),
    strategies: {
      num_strategies: Math.floor(Math.random() * 10),
      pricing_model: [[1, 2, 4, 5][Math.floor(Math.random() * 4)]],
      top_commission_rate: Math.floor(Math.random() * 5).toFixed(2),
      top_publisher_commission_rate_v2: Math.floor(Math.random() * 5).toFixed(
        2,
      ),
    },
    bio: `At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
        voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint
        occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia
        animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
        distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit
        quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis
        dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum
        necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
        Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus
        maiores alias consequatur aut perferendis doloribus asperiores repellat.`,
    sale_items_count: String(Math.floor(Math.random() * 100)),
  };
};

export const getMockStrategies = () => {
  return [
    {
      merchant_name: 'Ulta Beauty',
      strategy_name:
        'A campaign // Electronics // Sub electronics // Audio // Sub audio',
      publisher_epc: '0.15',
      commission_rate: 10.0,
      strategy_datetime_start: '2020-08-11T00:00:00Z',
      strategy_datetime_end: '2020-09-30T23:59:59Z',
      clicks: 3000,
      publisher_total_earnings: '90.00',
      merch_id: 1179,
      strategy_id: 1,
    },
  ];
};

export const getMockStrategyProducts = () => {
  return {
    pub_id: 1158,
    stats: [
      {
        product_name: 'SkinCeuticals Daily Moisture, 2 Fl. Oz. | Dermstore',
        image_url:
          'https://images.dermstore.com/catalog/100113/300x300/1453.jpg',
        publisher_total_earnings: '10.00',
        clicks: 1500,
        publisher_epc: '0.20',
        product_price: '25.00',
        sale_price: '15.00',
        is_in_stock: true,
      },
      {
        product_name: 'JBL Flip 5 Waterproof Portable Bluetooth Speaker - Blue',
        image_url:
          'https://images.crutchfieldonline.com/products/2019/24/109/h109FLIP5BL-F.jpg',
        publisher_total_earnings: '10.00',
        clicks: 15,
        publisher_epc: '0.20',
        store_page_url: 'https://bedbathandbeyond.com',
        product_price: '25.00',
        sale_price: '5.00',
        is_in_stock: true,
      },
      {
        product_name: 'Sub-Zero De-Puffing Eye Mask',
        image_url: null,
        publisher_total_earnings: '10.00',
        clicks: 15,
        publisher_epc: '0.20',
        product_price: '25.00',
        sale_price: null,
        is_in_stock: false,
      },
      {
        product_name: 'Tiger Print Wrap Dress',
        image_url:
          'https://www.logolynx.com/images/logolynx/82/828135c8968a8c03ce05b80f93e37824.png',
        publisher_total_earnings: '10.00',
        clicks: 15,
        publisher_epc: '0.20',
        store_page_url: 'https://bedbathandbeyond.com',
        product_price: '25.00',
        sale_price: null,
        is_in_stock: false,
      },
    ],
    page_info: {
      page: 1,
      per_page: 50,
      total_items: 4,
      total_pages: 1,
    },
  };
};

export const getSelectedFeeType = (context: Context, feeTypes: any) => {
  const feeTypesSelected = feeTypes.reduce((acc: any, option: any) => {
    return option.selected ? [...acc, option.value] : acc;
  }, []);
  return feeTypesSelected.toString();
};

export const getSelectedCampaignLabels = (
  context: Context,
  campaignLabels: any,
) => {
  const campaignLabelsSelected = campaignLabels.reduce(
    (acc: any, option: any) => {
      return option.selected ? [...acc, option.value] : acc;
    },
    [],
  );

  return campaignLabels.length === campaignLabelsSelected.length
    ? null
    : campaignLabelsSelected.join(',');
};

export const getTabSortOptions = (
  context: Context,
  {
    sortOptions,
    activeTab,
    strategyOnlySortParams,
    partnerOnlySortParams = [],
  }: {
    sortOptions: any;
    activeTab: any;
    strategyOnlySortParams?: any;
    partnerOnlySortParams?: any;
  },
) => {
  const excludedFromUpcoming = context.state.session.sessionData.isAdminView
    ? []
    : ['clicks', 'publisher_total_earnings', 'publisher_epc'];
  return sortOptions.filter(
    (option: any) =>
      (activeTab.value === 'partners' ||
        !partnerOnlySortParams.includes(option.value)) &&
      (activeTab.value !== 'upcoming strategies' ||
        !excludedFromUpcoming.includes(option.value)) &&
      (['strategies', 'upcoming strategies'].includes(activeTab.value) ||
        !strategyOnlySortParams.includes(option.value)),
  );
};

export const getDateRange = (context: Context, params: any) => {
  // Return current date objects for DatePicker
  return {
    startDate: context.actions.date.dateFromStr(params.date_from),
    endDate: context.actions.date.dateFromStr(params.date_to),
  };
};

export const createDateRangeState = (context: Context, range: any) => {
  const { startDate, endDate } = range;
  const number = -context.actions.date.subtractDates({
    d1: startDate,
    d2: endDate,
    unit: 'day',
  });
  return {
    startDate,
    endDate,
    // Offset ranges for peformance comparison
    previousStartDate: context.actions.date.offsetFromDate({
      originalDate: startDate,
      number,
      unit: 'day',
    }),
    previousEndDate: context.actions.date.offsetFromDate({
      originalDate: endDate,
      number,
      unit: 'day',
    }),
  };
};

export const getDateRanges = (context: Context) => {
  // DatePicker Dropdown Options
  const dateOffsetMap: any = {
    sevenDay: -7,
    thirtyDay: -30,
    ninetyDay: -90,
    thisMonth: -1,
    lastMonth: -2,
    halfYear: -6,
  };
  const presetDateRanges: any = context.actions.date.getPresetDateRanges();

  return [
    'sevenDay',
    'thirtyDay',
    'ninetyDay',
    'thisMonth',
    'lastMonth',
    'halfYear',
  ].map((range) => {
    const unit = ['thisMonth', 'lastMonth', 'halfYear'].includes(range)
      ? 'month'
      : 'day';
    return {
      ...presetDateRanges[range],
      name: range,
      unit,
      // Offset ranges for peformance comparison
      previousLabel: presetDateRanges[range].label
        .replace('Last', 'Previous')
        .replace('This', 'Last'),
      previousStartDate: context.actions.date.offsetFromDate({
        originalDate: presetDateRanges[range].startDate,
        number: dateOffsetMap[range],
        unit,
      }),
      previousEndDate: context.actions.date.offsetFromDate({
        originalDate: presetDateRanges[range].endDate,
        number: dateOffsetMap[range],
        unit,
      }),
    };
  });
};

export const getStrategyStatus = (context: Context, strategy: any) => {
  const statusMap: any = {
    upcoming: 'upcoming',
    expired: 'expired',
    running_and_active: 'active',
    running_and_paused: 'paused',
  };
  return statusMap[strategy.strategy_status];
};

export const optIn = (context: Context, strategy: any) => {
  const payload = {
    publisher_opt_in: {
      pub_id: context.state.session.sessionData.orgId,
      opt_in_status: 'opt_in',
    },
  };

  return context.effects.api.post(
    `/api/v0/merchants/${strategy.strategy_id}/update_pub_opt_in_status/`,
    payload,
  );
};

export const addToCalendar = (
  context: Context,
  { e, strategy }: { e: any; strategy: any },
) => {
  e.preventDefault();

  const strategyName = strategy.strategy_name;
  const startDate = strategy.strategy_datetime_start;
  const endDate = strategy.strategy_datetime_end;

  const dates = {
    calendar: {
      start_date: context.actions.date.formatDate({
        dateObj: startDate,
        fmt: 'YYYYMMDD',
        isUtc: true,
      }),
      end_date:
        endDate !== null // If strategy has no end date, calendar event should be startDate - startDate (all day event)
          ? context.actions.date.formatDate({
              dateObj: context.actions.date.offsetFromDate({
                originalDate: endDate,
                number: 1,
                unit: 'day',
              }),
              fmt: 'YYYYMMDD',
              isUtc: true,
            })
          : context.actions.date.formatDate({
              dateObj: context.actions.date.offsetFromDate({
                originalDate: startDate,
                number: 1,
                unit: 'day',
              }),
              fmt: 'YYYYMMDD',
              isUtc: true,
            }),
    },
    event_title: {
      start_date: context.actions.date.formatDate({
        dateObj: startDate,
        fmt: 'MMM D, YYYY',
        isUtc: true,
      }),
      end_date: context.actions.date.formatDate({
        dateObj: endDate,
        fmt: 'MMM D, YYYY',
        isUtc: true,
      }),
    },
  };

  // Only include start date if strategy has no end date
  const dateString = `${dates.event_title.start_date}${
    endDate !== null ? ` - ${dates.event_title.end_date}` : ''
  }`;

  const strategyProductsUrl =
    `/publisher/${context.state.session.sessionData.orgId}/new-recs/rates/strategy-products/${strategy.merch_id}` +
    `/strategies/${strategy.strategy_id}`;

  const calendarParams = new URLSearchParams({
    action: 'TEMPLATE',
    text: `${strategy.merchant_name} - ${strategyName} (${dateString})`,
    details: `https://dashboard.narrativ.com${strategyProductsUrl}`,
    dates: `${dates.calendar.start_date}/${dates.calendar.end_date}`,
  });
  const googleCalendarEventLink = `https://www.google.com/calendar/render?${calendarParams.toString()}`;
  window.open(googleCalendarEventLink);
};
