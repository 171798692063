import { PermissionRoles } from '../account/state';

export type AccessListItem = {
  type: string;
  label: string;
  orgId: number;
  termsAccepted: boolean;
  isOrgAdmin: boolean;
  isBillingUser: boolean;
  stripeId: string;
  stripeSetupComplete: boolean;
  stripePayoutsEnabled: boolean;
  role: PermissionRoles;
};

export type BamxPerms = {
  admin: boolean;
};

export type MobileLoginStorage = {
  request: {
    username: string;
  };
  response: {
    data: any[];
  };
};

export type Organization = {
  orgId?: number | null;
  type?: string | null;
  orgName?: string | null;
  pub_id?: number | null;
  merch_id?: number | null;
  publisher_type?: string | null;
  instant_pay_access?: string | null;
  billing_platform?: number | null;
  isOrgAdmin?: boolean | null;
  termsAccepted?: boolean | null;
};

type State = {
  reported: boolean;
  destroyed: boolean;
  isLoggedIn: boolean;
  jwt: string | null;
  currentUser: null;
  isAdmin: boolean;
  accessList?: AccessListItem[];
};

export const state: State = {
  reported: false,
  destroyed: false,
  jwt: null,
  currentUser: null,
  isAdmin: false,
  accessList: [],
  get isLoggedIn() {
    return !!(this.jwt && this.currentUser);
    // return !!this.jwt;
  },
};
