import { ModalWrapper } from './modal-wrapper';
import { Icon } from './icon';
import { Button } from '@frontend/shared-ui-kit/button';
import { Input } from '@frontend/shared-ui-kit/input';
import classNames from 'classnames';

interface BulkImageModalProps {
  // Using any so this can be generic
  addImage: (e: any) => void;

  deleteImage: any;

  headerText?: string;

  setShowModal: (arg: boolean) => void;

  imageLayout?: 'horizontal' | 'vertical';

  imageLinks?: { link: string | null; image_url: string[] }[];

  updateImageLinks?: any;

  disabled?: boolean;

  errorMessage?: string;
}

export const BulkImageModal = ({
  addImage,
  deleteImage,
  headerText,
  setShowModal,
  imageLayout = 'vertical',
  imageLinks,
  updateImageLinks,
  disabled,
  errorMessage,
}: BulkImageModalProps) => {
  return (
    <ModalWrapper closeModal={() => setShowModal(false)} showX={true}>
      <div
        data-cy="image-upload-modal-header"
        className="text-xl px-6 py-9 shadow-elevation-low"
      >
        {headerText}
        <div
          data-cy="download-images-button"
          className="flex flex-row mt-5 space-x-2 text-violet-400 text-base items-center cursor-pointer"
          onClick={() => {
            imageLinks?.forEach((contentLink) => {
              contentLink.image_url.forEach((image) => {
                window.open(image, '_blank');
              });
            });
          }}
        >
          <span>Download All</span>
          <Icon iconName="export" />
        </div>
      </div>
      <div className="flex flex-col px-6 pt-7 mb-6">
        <div
          data-cy="image-upload-modal-images"
          className={classNames('flex', {
            'flex-col space-y-5': imageLayout === 'vertical',
            'flex-row space-x-3': imageLayout === 'horizontal',
          })}
        >
          {imageLinks?.map((contentLink, idx) => {
            return contentLink.image_url.map((image) => {
              return (
                <div
                  className="flex flex-row items-center space-x-4"
                  key={`${contentLink.link}-${image}`}
                >
                  <div
                    className="flex flex-row"
                    key={`${contentLink.link}-${image}`}
                  >
                    <img
                      src={image}
                      alt={image}
                      data-cy={`uploaded-image-${contentLink.link}-${image}`}
                      className="w-24 h-24 rounded"
                    />
                    <div className="relative">
                      <Button
                        size="xs"
                        leftIcon={<Icon iconName="x" />}
                        variant="ghost"
                        className="absolute right-0 bg-white bg-opacity-10"
                        onClick={() => deleteImage(image, idx)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  {imageLinks && imageLinks.length > 0 && (
                    <div className="flex flex-col flex-grow">
                      <label
                        htmlFor={`content-input-${contentLink.link}-${image}`}
                        className="text-neutral-gray-800 text-sm"
                      >
                        Content URL:
                      </label>
                      <Input
                        name={`content-input-${contentLink.link}-${image}`}
                        id={`content-input-${contentLink.link}-${image}`}
                        placeholder="Input text"
                        value={contentLink.link}
                        onChange={(val) => {
                          updateImageLinks(val, image, idx);
                        }}
                        debounce={true}
                        debounceDuration={1500}
                        disabled={disabled}
                        onBlur={(val) => updateImageLinks(val, image, idx)}
                      />
                    </div>
                  )}
                </div>
              );
            });
          })}
          <input
            hidden
            type="file"
            id="image-upload-btn"
            name="image-upload-btn"
            onChange={(e) => {
              addImage(e);
            }}
            disabled={disabled}
          />
          <label
            className=" flex flex-col w-24 h-24 items-center justify-center space-y-2 bg-deep-space-10 rounded"
            htmlFor="image-upload-btn"
          >
            <Icon iconName="upload" />
            <span className="text-xs">Upload Image</span>
          </label>
          {errorMessage && (
            <span className="text-system-error">{errorMessage}</span>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
