import React from 'react';
import Card from './card';
import HoverIconTooltip from '../hover-icon-tooltip';

type Props = {
  title: string;
  highlight?: string;
  children: React.ReactNode;
  tooltip?: string;
  footer?: React.ReactNode;
  cypressSelector?: string;
  isBeta?: boolean;
  height: string;
  border?: string;
  margin?: string;
};

const DashboardCard = ({
  title,
  highlight,
  children,
  tooltip,
  footer,
  cypressSelector,
  isBeta,
  height,
  border,
  margin,
}: Props) => (
  <Card
    padding="p-5 sm:p-6 sm:pr-4"
    border={border}
    height={height}
    margin={margin}
    cypressSelector={cypressSelector}
  >
    <header className="pb-2 text-sm">
      <span
        data-cy="dashboard-card-title"
        className="text-neutral-gray-600 uppercase text-xs sm:text-sm tracking-wider"
      >
        {title}
        {isBeta && (
          <span className="ml-2 bg-neutral-gray-500 text-white text-xxs px-2 uppercase tracking-widest leading-4 py-1 rounded">
            BETA
          </span>
        )}
      </span>
      {!highlight && tooltip && (
        <HoverIconTooltip
          background="bg-transparent"
          maxWidthPx={260}
          tooltip={tooltip}
          alignment="top-8 z-30"
        >
          <span className="ml-1 relative -top-1">
            <img
              src="/images/icons/info-circle.svg"
              alt="Info"
              className="max-w-none relative top-2"
              width="16"
              height="16"
            />
          </span>
        </HoverIconTooltip>
      )}
      {highlight && (
        <>
          <span
            data-cy="dashboard-card-highlight"
            className={`ml-2 ${
              highlight.startsWith('-')
                ? 'text-system-error'
                : 'text-nebula-300'
            }`}
          >
            {highlight}
          </span>
          {tooltip && (
            <HoverIconTooltip
              background="bg-transparent"
              maxWidthPx={350}
              tooltip={tooltip}
              alignment="-left-16 -translate-x-2/3 translate-y-2/4 top-0 2xl:top-24 2xl:-translate-y-full"
            >
              <span className="ml-1 relative -top-1">
                <img
                  src="/images/icons/info-circle.svg"
                  alt="Info"
                  className="max-w-none relative top-2"
                  width="16"
                  height="16"
                />
              </span>
            </HoverIconTooltip>
          )}
        </>
      )}
    </header>
    {children}
    {footer && (
      <div
        data-cy="dashboard-card-footer"
        className="pt-2 text-gray-500 text-xs font-system"
      >
        {footer}
      </div>
    )}
  </Card>
);

export default DashboardCard;
