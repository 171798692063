type UserRoleDescription = {
  name: string;
  code: string;
  description: string;
  subName?: string;
};

// BE expects these as the role when updating a user
export enum Roles {
  BILLING_ADMIN = 'billing_admin',
  ACCOUNT_OWNER = 'account_owner',
  TEAM_ADMIN = 'team_admin',
  TEAM_MEMBER = 'team_member',
}

// Display name for roles
export enum RoleDisplayNames {
  BILLING_ADMIN = 'Billing Admin',
  ACCOUNT_OWNER = 'Account Owner',
  TEAM_ADMIN = 'Team Member (Earnings)',
  TEAM_MEMBER = 'Team Member (No Earnings)',
}

// BE returns these as the role for a given user
export enum RoleNames {
  BILLING_ADMIN = 'Billing Admin',
  ACCOUNT_OWNER = 'Account Owner',
  TEAM_ADMIN = 'Team Admin',
  TEAM_MEMBER = 'Team Member',
}

export type PublisherRoles =
  | RoleNames.BILLING_ADMIN
  | RoleNames.ACCOUNT_OWNER
  | RoleNames.TEAM_ADMIN
  | RoleNames.TEAM_MEMBER;

export type PermissionRoles = PublisherRoles | null;

export type BillingUserResponse = {
  uid: number;
  billing_platform: number;
  billing_country: string;
  stripe_billing_country: string;
};

export type Brand = {
  name: string;
  creator_profile_brand_id: number;
  merch_id: number;
  is_future: boolean;
};

export type Channel = {
  channel_type: number;
  channel_url: string;
  creator_profile_channel_id: number;
  is_primary: boolean;
  phyllo_data: PhylloData | null;
};

type PhylloData = {
  account_id: string | null;
  is_connected: boolean | null;
  audience_size: number | null;
  platform_username: string | null;
};

type State = {
  userRoleDescriptions: { [key in Roles]: UserRoleDescription };
  role: PermissionRoles;
  billingUser: BillingUserResponse | null;
  editingChannels: boolean;
  editingBrands: boolean;
  brands: Brand[];
  channels: Channel[];
  inNetworkOrgs: Org[];
  outOfNetworkOrgs: Org[];
};

export type Org = {
  is_in_network: boolean;
  is_active: boolean;
  short_name: string;
  pub_id?: number;
  merch_id?: number;
};

export const state: State = {
  userRoleDescriptions: {
    team_member: {
      name: 'Team Member',
      code: 'team_member',
      description:
        'This is a very limited role ideally for a team member solely responsible for creating and managing links and performing research. This role provides zero visibility into earnings and commission rates.',
      subName: '(No Earnings)',
    },
    team_admin: {
      name: 'Team Member',
      code: 'team_admin',
      description:
        'This role provides full access to the dashboard including analytics. While this role provides complete visibility into earnings,  payments cannot be set up or initiated.',
      subName: '(Earnings)',
    },
    account_owner: {
      name: 'Account Owner',
      code: 'account_owner',
      description:
        'This role provides full control over the account and access to the dashboard, including access to earnings and payments. This user has responsibility for adding, removing, and maintaining team members, with permissions to update roles as needed.',
    },
    billing_admin: {
      name: 'Billing',
      code: 'billing_admin',
      description:
        'This role is ideal for a finance/accounting team member(s) providing complete access to the dashboard. This user has responsibility for billing and payment setup with visibility into earnings.',
    },
  },
  role: null,
  billingUser: null,
  editingChannels: false,
  editingBrands: false,
  brands: null as any,
  channels: null as any,
  inNetworkOrgs: [],
  outOfNetworkOrgs: [],
};
