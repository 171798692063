import { Context, PermissionRoles, RoleNames } from '..';
import { EarningsChange } from '../publisher/state';
import { formatNumberWithCommas } from '@frontend/shared-utils';

export const checkAllSites = (
  context: Context,
  {
    params,
    allowedPublisherRoles,
    isReport,
  }: {
    params: any;
    allowedPublisherRoles?: PermissionRoles[];
    isReport?: boolean;
  },
) => {
  if (context.state.organization.allSites) {
    if (!allowedPublisherRoles)
      allowedPublisherRoles = [
        RoleNames.BILLING_ADMIN,
        RoleNames.ACCOUNT_OWNER,
        RoleNames.TEAM_ADMIN,
      ];

    const additionalOrgs = context.actions.session.getAccessListIds(
      allowedPublisherRoles,
    );

    const role = context.state.session.sessionData.isAdmin
      ? context.state.session.sessionData.allPubs
        ? 'publishers'
        : 'merchants'
      : context.state.session.sessionData.userRole;
    if (additionalOrgs) {
      params[
        isReport
          ? 'additional_orgs'
          : role === 'merchants'
          ? 'additional_merchs'
          : 'additional_pubs'
      ] = additionalOrgs;
    }
  }
  return params;
};

export const getData = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(`/api/v0/orders/${publisherId}/`, params);
};

export const getMerchants = (context: Context, publisherId: string) => {
  return context.effects.api.get(`/api/v0/orders/${publisherId}/merchants/`);
};

// -------------------------------
export const getStatsByDay = (
  context: Context,
  { pubId, params }: { pubId: any; params: any },
) => {
  context.actions.publisherDashboard.checkAllSites({ params });
  const endpoint = `/api/v0/publishers/${pubId}/stats_by_day/`;
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

export const getStatsByMonth = (
  context: Context,
  { pubId, params = {} }: { pubId: any; params: any },
) => {
  context.actions.publisherDashboard.checkAllSites({ params });
  const endpoint = `/api/v0/publishers/${pubId}/stats_by_month/`;
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

export const getStatsByYear = (
  context: Context,
  { pubId, params = {} }: { pubId: any; params: any },
) => {
  context.actions.publisherDashboard.checkAllSites({ params });
  const endpoint = `/api/v0/publishers/${pubId}/stats_by_year/`;
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

export const getMerchantStats = (
  // this endpoint replaces getMerchantStatsBy...Day/Month/Year/Hour
  context: Context,
  {
    merchId,
    granularity,
    dateRange,
    strategyId,
  }: { merchId: any; granularity: string; dateRange: any; strategyId?: string },
) => {
  // granularity can be: 'hourly', 'daily', 'weekly', 'monthly', or 'yearly'
  const isHourly = granularity === 'hourly';
  const endpoint = `/api/v0/merchants/${merchId}/performance/`;

  const params = {
    date_from: dateRange.date_from,
    date_to: !isHourly ? dateRange.date_to : null,
    fields: `event_${
      isHourly ? 'hour' : 'date'
    },merch_id,merchant_spend,clicks,attributed_revenue,rpc,publishers,roi,flat_fees,flat_fee_budget`,
    granularity: granularity,
    group_by: 'merch_id',
    order_by: `event_${isHourly ? 'hour' : 'date'}`,
    flat_fee_strategy_id: strategyId ? strategyId : null,
  };

  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

export const getMerchantTopArticles = (
  context: Context,
  { merchId, params = {} }: { merchId: any; params: any },
) => {
  const endpoint = `/api/v0/merchants/${merchId}/merch_ed/top_articles/`;
  context.actions.publisherDashboard.checkAllSites({ params });
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

export const getMerchantStatsByEdit = (
  context: Context,
  { merchId, params = {} }: { merchId: any; params: any },
) => {
  const endpoint = `/api/v0/merchants/${merchId}/merch_ed/stats_by_edit/`;
  context.actions.publisherDashboard.checkAllSites({ params });
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

export const getLifetimeEarnings = (
  context: Context,
  { pubId, params = {} }: { pubId: any; params: any },
) => {
  context.actions.publisherDashboard.checkAllSites({ params });
  const endpoint = `/api/v0/publishers/${pubId}/earnings_stats/`;
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    return resp.data.data[0].stats;
  });
};

/* Graph helpers */
export const generateDatesForRange = (
  context: Context,
  {
    startDate,
    endDate,
    timeUnit,
    rangeType,
  }: { startDate: any; endDate: any; timeUnit: any; rangeType: any },
) => {
  /*
   * Generate all dates for data points between two dates,
   * incremented by timeUnit (day or month)
   */
  const numDatesMap: any = {
    sevenDay: 7,
    halfYear: 6,
    oneDay: 24,
  };
  let numDates = numDatesMap[rangeType];

  if (!numDates) {
    /*
     * The day of month/month of year are the most accurate indicators for these ranges.
     * moment.duration returns a float based on how much of the day/month has elapsed,
     * and there's no consistent way to round it.
     */
    if (rangeType === 'thisYear') {
      numDates = endDate.month() + 1; // Months are 0-indexed
    } else if (rangeType === 'thisMonth' || rangeType === 'lastMonth') {
      numDates = endDate.date();
    } else {
      const delta = context.actions.date.subtractDates({
        d1: startDate,
        d2: endDate,
        unit: timeUnit,
      }) as number;
      numDates =
        rangeType === 'multiYear' ? Math.floor(delta) : Math.round(delta);
    }
  }

  const dates = [];
  for (let i = 0; i < numDates; i++) {
    const date = context.actions.date.offsetFromDate({
      originalDate: startDate,
      number: i,
      unit: timeUnit,
    });

    if (rangeType === 'oneDay') {
      dates.push(
        context.actions.date.formatDate({
          dateObj: date,
          fmt: 'Y-MM-DDTHH:mm:ss',
        }),
      );
    } else {
      // API date format: 2019-10-01
      dates.push(
        context.actions.date.formatDate({
          dateObj: date,
          fmt: 'Y-MM-DD',
        }),
      );
    }
  }

  return dates;
};

export const formatDateLabels = (
  context: Context,
  { statsWithLabels, timeUnit }: { statsWithLabels: any; timeUnit: any },
) => {
  /*
   * timeUnit: day|month|year
   * For example, if timeUnit was 'day', dateLabel would be:
   *   '30 Sep', '1 Oct', '2', '3', ...
   */
  const updatedStats: any = [];
  // Convert month from integer to text (1 => Jan)
  statsWithLabels.forEach((data: any) => {
    const dateFormat = 'D-MMM-Y'; // 1-jan-2019
    const formattedLabel = context.actions.date.formatDate({
      dateObj: data.dateLabel,
      fmt: dateFormat,
    });

    data.dateLabel = formattedLabel;
  });

  // Omit month or year if it's the same as the previous label's month/year
  statsWithLabels.forEach((data: any, i: number) => {
    const dateParts = data.dateLabel.split('-');
    const previousLabel = i > 0 ? statsWithLabels[i - 1].dateLabel : null;
    const previousParts = previousLabel ? previousLabel.split('-') : null;

    let label;
    let secondLabel;

    if (timeUnit === 'day') {
      if (!previousParts || previousParts[1] !== dateParts[1]) {
        secondLabel = dateParts[1]; // 'jan'
      }
      label = dateParts[0]; // '2'
    } else if (timeUnit === 'month') {
      if (!previousParts || previousParts[2] !== dateParts[2]) {
        secondLabel = dateParts[2]; // '2019'
      }
      label = dateParts[1]; // 'feb'
    } else if (timeUnit === 'year') {
      label = dateParts[2]; // '2019'
    }

    updatedStats.push({
      dateLabel: label,
      secondLabel,
      stats: data.stats,
    });
  });

  return updatedStats;
};

export const formatHourLabels = (
  context: Context,
  { statsWithLabels }: { statsWithLabels: any },
) => {
  const updatedStats: any = [];

  // turn each event_hour into an hour label:
  // ex: "2022-09-12T15:00:00Z" => "15"

  statsWithLabels.forEach((data: any) => {
    const hourFormat = 'HH';
    const formattedLabel = context.actions.date.formatDate({
      dateObj: data.dateLabel,
      fmt: hourFormat,
    });

    data.dateLabel = formattedLabel;

    updatedStats.push({
      dateLabel: data.dateLabel,
      stats: data.stats,
    });
  });

  return updatedStats;
};

export const displayChange = (
  context: Context,
  { change, round = true }: { change: EarningsChange; round?: boolean },
) => {
  if (change.unit === 'invalid') return '-';

  const retValue = round
    ? Math.round(Math.abs(change.amount))
    : Math.abs(change.amount);
  switch (change.unit) {
    case 'absolute-currency':
      return `$${formatNumberWithCommas(retValue)}`; // `$${retValue}`;
    case 'absolute':
      return formatNumberWithCommas(retValue);
    case 'percentage':
      return `${retValue}%`;
  }
};

export const getRanking = (context: Context, change: any) => {
  if (change < 0) {
    return 'poor';
  }
  if (change >= 0 && change <= 5) {
    return 'fair';
  }
  if (change > 5) {
    return 'good';
  }
  // When there's no previous data to compare
  return 'neutral';
};
