import React from 'react';
import { useActions } from '@frontend/howl-web-app/overmind';

export const SignOut = ({ close }: { close?: any }) => {
  const { logout } = useActions().auth;

  return (
    <button
      className="list-none h-10 flex items-center text-sm hover:bg-ice-10 focus:bg-ice-100 transition px-4 cursor-pointer w-full"
      onClick={() => {
        logout(true);

        if (typeof close !== 'undefined') close();
      }}
      data-cy="nav-signout"
    >
      <span>Sign Out</span>
    </button>
  );
};
