import sanitizeHtml from 'sanitize-html';

export const SanitizedHTMLWrapper = ({ html }: { html: string }) => {
  const formatHtml = (html: string) => {
    if (!html) {
      return '';
    }
    const description = html
      .replace(/\n/g, ' <br/>')
      .split(' ')
      .map((string) => {
        let retString = '';
        if (string.startsWith('<br/>') && string.length > 5) {
          retString = '<br/>';
          string = string.substring(5);
        }

        return retString + string;
      })
      .join(' ');

    const sanitizedDescription = sanitizeHtml(description, {
      allowedTags: [
        'br',
        'a',
        'p',
        'li',
        'ul',
        'h3',
        'strong',
        'em',
        'b',
        'ol',
        'h1',
        'h2',
        'u',
      ],
      allowedClasses: {
        a: ['cursor-pointer', 'hover:underline', 'text-violet-300'],
        ul: ['list-disc', 'ml-8'],
        ol: ['list-decimal', 'ml-8'],
        h1: ['font-normal', 'text-[2rem]'],
        h2: ['font-normal', 'text-2xl'],
        h3: ['font-normal', 'text-xl'],
      },
      transformTags: {
        a: sanitizeHtml.simpleTransform('a', {
          class: 'cursor-pointer hover:underline text-violet-300',
          target: '_blank',
        }),
        ul: sanitizeHtml.simpleTransform('ul', {
          class: 'list-disc ml-8',
        }),
        ol: sanitizeHtml.simpleTransform('ol', {
          class: 'list-decimal ml-8',
        }),
        h1: sanitizeHtml.simpleTransform('h1', {
          class: 'font-normal text-[2rem]',
        }),
        h2: sanitizeHtml.simpleTransform('h2', {
          class: 'font-normal text-2xl',
        }),
        h3: sanitizeHtml.simpleTransform('h3', {
          class: 'font-normal text-xl',
        }),
      },
    });

    return sanitizedDescription;
  };
  return <div dangerouslySetInnerHTML={{ __html: formatHtml(html) }} />;
};
