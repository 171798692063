import { ContentSelection, MetaMatchResults } from './state';

export const getCleanId = (selectedContentList: ContentSelection) => {
  return selectedContentList && selectedContentList.id
    ? selectedContentList.id
    : null;
};
export const buildBrandSelectionsFromOfferMatches = ({
  offerItems,
  matchResults,
}: {
  offerItems: ContentSelection[];
  matchResults: MetaMatchResults;
}) => {
  const brandItems: ContentSelection[] = [];

  if (!offerItems) return brandItems;

  offerItems.forEach((item) => {
    const matchData = matchResults[item.key];

    if (matchData && matchData.item) {
      brandItems.push({
        key: '', // as if generated in code (unused in this case)
        id: matchData.item.merch_id + '', // as if entered in by user
      });
    }
  });

  return brandItems;
};

export const contentListItemsAreValid = (
  items: ContentSelection[],
  matchResults: MetaMatchResults,
) => {
  let hasError = false;

  if (!items) return false;

  items.forEach((item) => {
    if (
      !item ||
      !item.key ||
      !item.id ||
      !matchResults ||
      !matchResults[item.key] ||
      matchResults[item.key].error
    )
      hasError = true;
  });

  return !hasError;
};
