import { Context } from '..';
import cloneDeep from 'lodash/cloneDeep';
import { FlatFeeVerificationDetail } from './state';

const getSelectedMonthYear = ({ state, actions }: any) => {
  const selectedMonth = state.flatFeeVerification.selectedDate;
  if (selectedMonth) {
    const splitDate = selectedMonth.value.split('-');
    return {
      year: splitDate[0],
      month: splitDate[1],
    };
  } else {
    const today = actions.date.getCurrentDateTime();
    return {
      year: today.year(),
      month: today.month() + 1, // Months are 0-indexed in moment, API expects jan = 1
    };
  }
};

export const getCampaigns = async ({ actions, effects, state }: Context) => {
  const { year, month } = getSelectedMonthYear({ actions, state });

  const resp = await effects.api.get(
    `/api/v0/merchants/flat_fees/${year}/${month}/overview/`,
    {
      page: state.flatFeeVerification.page,
      per_page: state.flatFeeVerification.per_page,
      order_by: state.flatFeeVerification.order_by,
      direction: state.flatFeeVerification.direction,
      merch_name_contains: state.flatFeeVerification.searchTerm,
    },
  );

  state.flatFeeVerification.totalItems =
    resp.data.data[0].page_info.total_items;
  state.flatFeeVerification.campaigns = resp.data.data[0].flat_fee_campaigns;
};

export const getFlatFeeVerifications = async (
  { actions, effects, state }: Context,
  strategyId: string,
) => {
  const { year, month } = getSelectedMonthYear({ actions, state });
  const respData = await effects.api.get(
    `/api/v0/merchants/flat_fees/${year}/${month}/strategies/${strategyId}/verifications/`,
    {
      ...(state.flatFeeVerification.selectedStatusFilter &&
        state.flatFeeVerification.selectedStatusFilter.value !== 'all' && {
          status: state.flatFeeVerification.selectedStatusFilter.value,
        }),
      order_by: state.flatFeeVerification.order_by,
      direction: state.flatFeeVerification.direction,
    },
  );
  const merch_id = respData.data.data[0].strategy_info.merch_id;
  const respDataStatus = await effects.api.get(
    `/api/v0/merchants/${merch_id}/strategies/${strategyId}/strategy_budget_status/`,
    {},
  );

  state.flatFeeVerification.isOverBudget =
    respDataStatus.data.data[0].is_merchant_strategy_over_flat_fee_budget;

  state.flatFeeVerification.selectedStrategy =
    respData.data.data[0].strategy_info;
  state.flatFeeVerification.flatFeeVerifications =
    respData.data.data[0].flat_fee_verifications.map((ffv: any) => {
      return {
        ...ffv,
        edit_disabled: ffv.verification_status === 'VERIFIED',
      };
    });
};

export const saveFlatFeeVerifications = async (
  { actions, effects, state }: Context,
  strategyId: string,
) => {
  try {
    const { year, month } = getSelectedMonthYear({ actions, state });
    const payload = {
      return_entity: true,
      flat_fee_verification: {
        flat_fees: state.flatFeeVerification.flatFeeVerifications
          .filter((ffv) => ffv.rowIsDirty)
          .map((ffv) => {
            return {
              flat_fee_id: ffv.flat_fee_id,
              pub_id: ffv.pub_id,
              verification_status: ffv.verification_status,
              flat_fee_note: ffv.flat_fee_note,
              content_links: ffv.content_links || [],
            };
          }),
      },
    };

    const resp = await effects.api.post(
      `/api/v0/merchants/flat_fees/${year}/${month}/strategies/${strategyId}/verifications/`,
      payload,
    );
    return resp;
  } catch (e: any) {
    return { error: true, message: e.message };
  }
};

export const setStrategyId = ({ state }: Context, id: string) => {
  state.flatFeeVerification.strategyId = id;
};

export const setStrategyData = ({ state }: Context, strategyData: any) => {
  state.flatFeeVerification.selectedStrategy = strategyData;
};

export const setOrderBy = ({ state }: Context, orderBy: string | null) => {
  state.flatFeeVerification.order_by = orderBy;
};

export const setDirection = (
  { state }: Context,
  direction: 'asc' | 'desc' | null,
) => {
  state.flatFeeVerification.direction = direction;
};

export const setPage = ({ state }: Context, page: number) => {
  state.flatFeeVerification.page = page;
};

export const setPerPage = ({ state }: Context, perPage: number) => {
  state.flatFeeVerification.per_page = perPage;
};

export const setTotalItems = ({ state }: Context, totalItems: number) => {
  state.flatFeeVerification.totalItems = totalItems;
};

export const setSearchTerm = ({ state }: Context, searchTerm: string) => {
  state.flatFeeVerification.searchTerm = searchTerm;
};

export const setFlatFeeVerificationDetail = (
  { state }: Context,
  detail: FlatFeeVerificationDetail,
) => {
  state.flatFeeVerification.flatFeeVerificationDetail = detail;
};

export const uploadImage = async (
  { effects, state }: Context,
  {
    image,
    feeId,
    pubId,
    strategyId,
  }: { image: any; feeId: number; pubId: number; strategyId: string },
) => {
  const feeIdx = state.flatFeeVerification.flatFeeVerifications.findIndex(
    (fee) => fee.flat_fee_id === feeId && fee.pub_id === pubId,
  );

  const filePayload = new FormData();
  filePayload.append('file', image);

  const resp = await effects.api
    .post(
      `/api/v0/flat_fees/${strategyId}/${feeId}/${pubId}/upload/`,
      filePayload,
    )
    .catch((err: Error) => console.error(err));

  const newImgUrl = resp.data.data[0].url;

  // On upload, image won't have a link so we want to add it to the list of images in null link object
  //      If we can't find a null link object, create a new one with the new URL
  //      If we can find null link object, push new URL to the images array
  if (!state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links) {
    state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links = [
      { link: null, image_url: [newImgUrl] },
    ];
  } else if (
    !state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links.find(
      (contentLink: any) => contentLink.link === null,
    )
  ) {
    state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links = [
      { link: null, image_url: [newImgUrl] },
      ...state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links,
    ];
  } else {
    state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links
      .find((contentLink: any) => contentLink.link === null)
      .image_url.push(newImgUrl);
  }

  state.flatFeeVerification.flatFeeVerifications[feeIdx].rowIsDirty = true;
};

export const deleteImage = (
  { state }: Context,
  {
    imageSource,
    linkIndex,
    feeId,
    pubId,
  }: { imageSource: string; linkIndex: number; feeId: number; pubId: number },
) => {
  const feeIdx = state.flatFeeVerification.flatFeeVerifications.findIndex(
    (fee) => fee.flat_fee_id === feeId && fee.pub_id === pubId,
  );

  // Find the specific image being deleted and filter it out from the state
  state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links[
    linkIndex
  ].image_url = state.flatFeeVerification.flatFeeVerifications[
    feeIdx
  ].content_links[linkIndex].image_url.filter(
    (linkImage: string) => linkImage !== imageSource,
  );

  const contentLinks = cloneDeep(
    state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links,
  );
  // If we removed the last image for a given link, remove the link
  if (
    contentLinks[linkIndex].image_url &&
    contentLinks[linkIndex].image_url.length === 0
  ) {
    contentLinks.splice(linkIndex, 1);
    state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links =
      contentLinks;
  }

  // If image removal brings row below required content count
  // and row is marked as verified, switch it to unverified
  const hasCorrectImageCount =
    contentLinks
      .map((contentLink: any) => {
        return contentLink.image_url.length;
      })
      .reduce((prev: number, curr: number) => prev + curr, 0) >=
    state.flatFeeVerification.flatFeeVerifications[feeIdx].post_count;
  if (
    state.flatFeeVerification.flatFeeVerifications[feeIdx]
      .verification_status === 'VERIFIED' &&
    !hasCorrectImageCount
  ) {
    state.flatFeeVerification.flatFeeVerifications[feeIdx].verification_status =
      'UNVERIFIED';
  }

  state.flatFeeVerification.flatFeeVerifications[feeIdx].rowIsDirty = true;
};

export const setStrategyIsDirty = ({ state }: Context, isDirty: boolean) => {
  state.flatFeeVerification.strategyIsDirty = isDirty;
};

export const setSelectedStatusFilter = (
  { state }: Context,
  selectedFilter: any,
) => {
  state.flatFeeVerification.selectedStatusFilter = selectedFilter;
};

export const updateImageLink = (
  { state }: Context,
  {
    linkValue,
    imageSource,
    linkIdx,
    feeId,
    pubId,
  }: {
    linkValue: string;
    imageSource: string;
    linkIdx: number;
    feeId: number;
    pubId: number;
  },
) => {
  // There's an edge case where this gets fired immediately on image upload and overwrites the link value as empty string
  // So in that case we want to catch the empty string and convert it to null
  const castedLinkValue = linkValue !== '' ? linkValue : null;
  const feeIdx = state.flatFeeVerification.flatFeeVerifications.findIndex(
    (fee) => fee.flat_fee_id === feeId && fee.pub_id === pubId,
  );

  const linkObjs = cloneDeep(
    state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links,
  );

  // Check if the link object only has the one image attached
  if (linkObjs[linkIdx].image_url.length === 1) {
    // Delete LinkObject if we're removing its only image
    linkObjs.splice(linkIdx, 1);
  } else {
    // If linkObj has multiple images, remove the edited one
    linkObjs[linkIdx].image_url = linkObjs[linkIdx].image_url.filter(
      (img: string) => img !== imageSource,
    );
  }

  // If linkObject exists for new linkValue;
  const newLinkObjIdx = linkObjs.findIndex(
    (linkObj: { link: string | null; image_url: string[] }) =>
      linkObj.link === castedLinkValue,
  );
  if (newLinkObjIdx >= 0) {
    // Add image to images list for the link
    linkObjs[newLinkObjIdx].image_url.push(imageSource);
  } else {
    linkObjs.push({ link: castedLinkValue, image_url: [imageSource] });
  }

  state.flatFeeVerification.flatFeeVerifications[feeIdx].content_links =
    linkObjs;
  state.flatFeeVerification.flatFeeVerifications[feeIdx].rowIsDirty = true;
};

export const setSelectedDate = (
  { state }: Context,
  { selectedDate }: { selectedDate: any },
) => {
  state.flatFeeVerification.selectedDate = selectedDate;
};

export const setFlatFeeNote = (
  { state }: Context,
  {
    flatFeeNote,
    pubId,
    flatFeeId,
  }: { flatFeeNote: string | null; pubId: string; flatFeeId: string },
) => {
  const feeIdx = state.flatFeeVerification.flatFeeVerifications.findIndex(
    (fee) => fee.flat_fee_id === flatFeeId && fee.pub_id === pubId,
  );

  state.flatFeeVerification.flatFeeVerifications[feeIdx].flat_fee_note =
    flatFeeNote;
  state.flatFeeVerification.flatFeeVerifications[feeIdx].rowIsDirty = true;
};

export const setVerificationStatus = (
  { state }: Context,
  {
    statusValue,
    pubId,
    flatFeeId,
    firstName,
    lastName,
  }: {
    statusValue: string | null;
    pubId: string;
    flatFeeId: string;
    firstName: string | null;
    lastName: string | null;
  },
) => {
  const feeIdx = state.flatFeeVerification.flatFeeVerifications.findIndex(
    (fee) => fee.flat_fee_id === flatFeeId && fee.pub_id === pubId,
  );

  state.flatFeeVerification.flatFeeVerifications[feeIdx].verification_status =
    statusValue;
  state.flatFeeVerification.flatFeeVerifications[feeIdx].first_name = firstName;

  state.flatFeeVerification.flatFeeVerifications[feeIdx].last_name = lastName;
  state.flatFeeVerification.flatFeeVerifications[feeIdx].rowIsDirty = true;
};

export const setImageError = ({ state }: Context, errorMessage: string) => {
  state.flatFeeVerification.imageError = errorMessage;
};
