import React, { useEffect, useState } from 'react';
import Image from 'next/image'; // https://nextjs.org/docs/api-reference/next/image
import { logging } from '@optimizely/optimizely-sdk';
import { useRouter } from 'next/router';
import { datadogLogs } from '@datadog/browser-logs';

import { ImageWithFallback } from './ImageWithFallback';

const DefaultFallback = () => (
  <svg viewBox="0 0 185 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79 71.5C77.067 71.5 75.5 73.067 75.5 75V103C75.5 104.933 77.067 106.5 79 106.5H107C108.933 106.5 110.5 104.933 110.5 103V75C110.5 73.067 108.933 71.5 107 71.5H79ZM74.5 75C74.5 72.5147 76.5147 70.5 79 70.5H107C109.485 70.5 111.5 72.5147 111.5 75V103C111.5 105.485 109.485 107.5 107 107.5H79C76.5147 107.5 74.5 105.485 74.5 103V75Z"
      fill="#BBBBBB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86 79.5C84.6193 79.5 83.5 80.6193 83.5 82C83.5 83.3807 84.6193 84.5 86 84.5C87.3807 84.5 88.5 83.3807 88.5 82C88.5 80.6193 87.3807 79.5 86 79.5ZM82.5 82C82.5 80.067 84.067 78.5 86 78.5C87.933 78.5 89.5 80.067 89.5 82C89.5 83.933 87.933 85.5 86 85.5C84.067 85.5 82.5 83.933 82.5 82Z"
      fill="#BBBBBB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.646 84.6464C100.842 84.4512 101.158 84.4512 101.354 84.6464L111.354 94.6464C111.549 94.8417 111.549 95.1583 111.354 95.3536C111.158 95.5488 110.842 95.5488 110.646 95.3536L101 85.7071L79.3536 107.354C79.1583 107.549 78.8417 107.549 78.6464 107.354C78.4512 107.158 78.4512 106.842 78.6464 106.646L100.646 84.6464Z"
      fill="#BBBBBB"
    />
  </svg>
);

interface NextImageWithFallbackProps {
  /** Image Source */
  src?: string | null;

  /** Alternative description for image */
  alt: string;

  /** Fallback source if image is not available from server */
  fallBack?: string;

  /** Represents the rendered width in pixels. Required, except for statically imported images or images with the fill property */
  width?: number;

  /** Represents the rendered height in pixels. Required, except for statically imported images or images with the fill property */
  height?: number;

  /** A boolean that causes the image to fill the parent element instead of setting width and height */
  fill?: boolean;

  /** Classes to add to img component */
  className?: string;

  sizes?: string;

  /** Dictionary of info to be logged in the event of a failure to load the primary image */
  loggingInfo?: {
    component?: string;
    productData?: any;
  };
}

export const NextImageWithFallback = (
  props: NextImageWithFallbackProps,
): JSX.Element => {
  const { src, alt, fallBack, loggingInfo, ...rest } = props;
  const [imgSrc, setImgSrc] = useState<string | null | undefined>(src);
  const [showDefaultFallback, setShowDefaultFallback] = useState(
    !src && !fallBack,
  );
  const router = useRouter();

  useEffect(() => {
    setImgSrc(src ? src : fallBack);
    return () => {
      setImgSrc(null);
    };
  }, [src]);

  const isRemoteImageDomain = (str: any): boolean => {
    return (
      typeof str === 'string' &&
      (str.includes('planethowl') || str.includes('bam-x'))
    );
  };

  const onError = () => {
    try {
      if (loggingInfo) {
        const logData = {
          name: 'productImageFallback',
          imgSrc: imgSrc,
          url: router.pathname,
          component: loggingInfo?.component,
          productData: loggingInfo?.productData,
        };
        datadogLogs.logger.info('Product Image Fallback', logData);
      }
    } finally {
      if (fallBack) {
        setImgSrc(fallBack);
      } else {
        setShowDefaultFallback(true);
      }
    }
  };

  if (showDefaultFallback) {
    return (
      <div {...rest}>
        <DefaultFallback />
      </div>
    );
  } else if (imgSrc && isRemoteImageDomain(imgSrc) && imgSrc.includes('http')) {
    return (
      <ImageWithFallback
        {...rest}
        alt={alt}
        src={imgSrc}
        loggingInfo={loggingInfo}
      />
    );
  } else {
    return (
      <img
        {...rest}
        alt={alt}
        src={imgSrc ? imgSrc : fallBack}
        onError={onError}
      />
    );
  }
};

NextImageWithFallback.filePath =
  'libs/shared/ui-kit/src/lib/image/NextImageWithFallback.tsx';
NextImageWithFallback.displayName = 'NextImageWithFallback';
