import moment from 'moment';

export interface GoalTier {
  goal_id?: number;
  is_enabled: boolean;
  gmv_goal: string | number;
  tier_reward_usd: string | number;
  tier_reward_label?: string;
  goal_tier_id?: number;
}

export interface GoalCreator {
  pub_id: number;
  goal_id: number;
  is_enabled: boolean;
  goal_creator_id: number;
  datetime_opted_in: string;
  opted_in: boolean;
}

export interface Merchant {
  merch_id: number;
  full_name: string;
  logo_url: string;
}

export interface Bonus {
  merch_id: number;
  is_enabled: boolean;
  is_visible: boolean;
  goal_tiers: GoalTier[];
  datetime_end: string;
  goal_id?: number;
  goal_creator: GoalCreator[];
  description: string;
  datetime_start: string;
  name: string;
  earnings_usd?: string;
  merchant?: Merchant;
  goal_type: string;
  datetime_created: string;
}

export interface CreateBonus {
  datetime_start: string;
  datetime_end: string;
  description: string;
  name: string;
  is_enabled: boolean;
  goal_type: string;
}

export enum GoalType {
  Default = 'DEFAULT',
  NewCreatorBonus = 'NEW_CREATOR_BONUS',
  HowlHolidayBonus = 'HOWL_HOLIDAY_BONUS',
  HowlHot100 = 'HOWL_HOT_100',
}

export enum GoalState {
  // Current time is before goal datetime_start
  Upcoming = 'UPCOMING',
  // Current time is after goal datetime_start and before goal datetime_end
  InProgress = 'IN_PROGRESS',
  // Current time is after goal datetime_end and earnings calculations are pending
  Expired = 'EXPIRED',
  // Current time is after goal datetime_end and earnings calculations are complete (only for platform bonuses)
  Final = 'FINAL',
  // Current time is after endPadding for goal and goal is hidden
  Hidden = 'HIDDEN',
}

export const getBonusStatus = (
  goal: Bonus,
  hideUpcoming = false,
  daysToHide: number,
) => {
  const startDate = new Date(goal.datetime_start);
  const endDate = new Date(goal.datetime_end);
  const hideGoalDate = new Date(goal.datetime_end);
  hideGoalDate.setDate(hideGoalDate.getDate() + daysToHide);

  if (moment().isBefore(startDate)) {
    // If goal hasn't started
    return hideUpcoming ? GoalState.Hidden : GoalState.Upcoming;
  } else if (moment().isAfter(hideGoalDate)) {
    // If goal is expired + daysToHide
    return GoalState.Hidden;
  } else if (moment().isAfter(startDate) && moment().isBefore(endDate)) {
    // If goal has started and hasn't ended
    return GoalState.InProgress;
  } else {
    // Goal has ended
    return GoalState.Expired;
  }
};

export interface customTierIcon {
  icon: string;
}

export const holidayTierIcons: customTierIcon[] = [
  {
    icon: 'snowflake1',
  },
  {
    icon: 'snowflake2',
  },
  {
    icon: 'snowflake3',
  },
  {
    icon: 'snowflake4',
  },
  {
    icon: 'snowflake5',
  },
  {
    icon: 'snowflake6',
  },
];
