import { Context } from '../index';
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from 'urql';
import { retryExchange } from '@urql/exchange-retry';
import { authExchange } from '@urql/exchange-auth';

export const onInitializeOvermind = (context: Context) => {
  // None of these options have to be added, these are the default values.
  const options = {
    initialDelayMs: 1000,
    maxDelayMs: 15000,
    randomDelay: true,
    maxNumberAttempts: 4,
    retryIf: (err: any) =>
      (err && err.networkError) || context.state.api.isRefreshing,
  };

  const client = createClient({
    url: process.env['NEXT_PUBLIC_GRAPHQL_URL'] || '',
    exchanges: [
      dedupExchange,
      cacheExchange,
      retryExchange(options) as any,
      authExchange({
        addAuthToOperation: ({
          authState,
          operation,
        }: {
          authState: { token: string };
          operation: any;
        }) => {
          if (authState && authState.token) {
            // fetchOptions can be a function (See Client API) but you can simplify this based on usage
            const fetchOptions =
              typeof operation.context.fetchOptions === 'function'
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions || {};
            const token = context.state.session.sessionData?.accessToken;

            return {
              ...operation,
              context: {
                ...operation.context,
                fetchOptions: {
                  ...fetchOptions,
                  headers: {
                    ...fetchOptions.headers,
                    Authorization: `Bearer ${token}`,
                  },
                },
              },
            };
          }

          if (!authState || !authState.token) {
            return operation;
          }

          return operation;
        },
        didAuthError: ({ error }) => {
          return error.graphQLErrors.some(
            (e) =>
              e.extensions?.['code'] === 'invalid-jwt' ||
              e.extensions?.['code'] === 'validation-failed',
          );
        },
        willAuthError: ({ authState }) => {
          return (
            (!authState && context.state.session.sessionData?.accessToken) ||
            (authState && !context.state.session.sessionData?.accessToken)
          );
        },
        getAuth: async () => {
          let { currentUser } = window.localStorage;

          currentUser = currentUser ? JSON.parse(currentUser) : null;

          return { token: currentUser?.accessToken };
        },
      }),
      fetchExchange,
    ],
  });

  context.effects.graphql.initialize();
  context.effects.graphql.setClient(client);
};
