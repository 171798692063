import { cloneDeep } from 'lodash';

const initialState = {
  snackbarShow: false,
  snackbarMsg: '',
  articles: {},
  products: {},
  edit: null,
  publisherList: [],
  publisherMap: {},
  strategyTabs: {
    crumb: null,
    crumbPage: null,
    publishers: {},
    stories: {},
    recommendations: {},
  },
  accountLink: {},
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SNACKBAR': {
      return {
        ...state,
        snackbarMsg: action.value || state.snackbarMsg,
        snackbarShow: true,
      };
    }
    case 'SHOW_SNACKBAR': {
      return {
        ...state,
        snackbarShow: true,
      };
    }
    case 'HIDE_SNACKBAR': {
      return {
        ...state,
        snackbarShow: false,
      };
    }
    case 'SET_ARTICLES': {
      const { value, totalItems, statKey, page } = action;
      const newArticles = cloneDeep(state.articles);

      if (newArticles[statKey]) {
        newArticles[statKey][page] = value;
        newArticles[statKey].totalItems = totalItems;
      } else {
        newArticles[statKey] = {
          [page]: value,
          totalItems,
        };
      }

      return {
        ...state,
        articles: newArticles,
      };
    }
    case 'SET_PRODUCTS': {
      const { value, totalItems, statKey, page } = action;
      const newProducts = cloneDeep(state.products);

      if (newProducts[statKey]) {
        newProducts[statKey][page] = value;
        newProducts[statKey].totalItems = totalItems;
      } else {
        newProducts[statKey] = {
          [page]: value,
          totalItems,
        };
      }

      return {
        ...state,
        products: newProducts,
      };
    }
    case 'SET_TAB_STATS': {
      const { value, totalItems, statKey, page, tab } = action;
      const newStats = cloneDeep(state.strategyTabs[tab]);

      if (newStats[statKey]) {
        newStats[statKey][page] = value;
        newStats[statKey].totalItems = totalItems;
      } else {
        newStats[statKey] = {
          [page]: value,
          totalItems,
        };
      }

      return {
        ...state,
        strategyTabs: {
          ...state.strategyTabs,
          [tab]: newStats,
        },
      };
    }
    case 'SET_SEARCH_EDIT': {
      return {
        ...state,
        edit: action.value,
      };
    }
    case 'SET_PUBLISHER_LIST': {
      return {
        ...state,
        publisherList: action.value,
      };
    }
    case 'SET_PUBLISHER_MAP': {
      return {
        ...state,
        publisherMap: action.value,
      };
    }
    case 'SET_STRATEGY_CRUMB': {
      return {
        ...state,
        strategyTabs: {
          ...state.strategyTabs,
          crumb: action.value,
          crumbPage: action.crumbPage,
        },
      };
    }
    case 'SET_ACCOUNT_LINK': {
      return {
        ...state,
        accountLink: action.value,
      };
    }
    default:
      return state;
  }
};

export default rootReducer;
