export type Granularity = {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
};

export type DateRange = {
  date_from: moment.Moment;
  date_to: moment.Moment;
};

export type Breadcrumb = { label: string; href?: string };

export type KpiData = {
  total_spend: string | number;
  total_spend_change: number;
  flat_fee_budget: string | number;
  flat_fees: string | number;
  flat_fee_commission: string | number;
  attributed_revenue: string | number;
  attributed_revenue_change: number;
  flat_fee_content_goal_count: number;
  flat_fee_content_approved_count: number;
  flat_fee_gmv_goal: string | number;
  attributed_orders: number;
  partners_count: number;
  rate_tier_id?: number;
};

type State = {
  merchantId: string | null;
  strategies?: string | null;
  io?: string | null;
  overviewData?: any;
  strategyDateRange?: DateRange | null;
  dateFilterRange?: any;
  breadcrumb?: Breadcrumb[];
  selectedGranularity: Granularity;
  strategyIsEvergreen?: boolean;
  isFlatFeeStrategy?: boolean;
  kpiData?: KpiData | null;
  topFiveData: { partners: any[]; stories: any[] };
  loading: {
    topFive: {
      partners: boolean;
      stories: boolean;
    };
    kpiModule: boolean;
    graph: boolean;
    reportDownload: boolean;
  };
  rateTiers: any;
};

export const defaultCampaignPerformanceState = {
  merchantId: null,
  strategies: null,
  io: null,
  overviewData: {
    datetime_start: '',
    datetime_end: '',
    strategy_status: '',
    paused_on: '',
    name: '',
    strategy_group_id: '',
  },
  strategyDateRange: null,
  dateFilterRange: null,
  breadcrumb: [],
  selectedGranularity: { label: 'Daily', value: 'daily' },
  strategyIsEvergreen: false,
  isFlatFeeStrategy: false,
  kpiData: null,
  topFiveData: {
    partners: [],
    stories: [],
  },
  rateTiers: [],
  loading: {
    topFive: {
      partners: false,
      stories: false,
    },
    kpiModule: false,
    graph: false,
    reportDownload: false,
  },
};

export const state: State = {
  ...defaultCampaignPerformanceState,
};
