import {
  routeSelectedOrg,
  requiresPrivateAuth,
} from '@frontend/howl-web-app/feature-main';
import { LoadingLayout } from './LoadingLayout';
import { useEffect, useState } from 'react';
import { DefaultLayout } from './DefaultLayout';
import { CreatorLayout } from './CreatorLayout';
import { PublisherLayout } from './PublisherLayout';
import { MerchantLayout } from './MerchantLayout';
import { useAppState } from '@frontend/howl-web-app/overmind';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const AuthLayout = requiresPrivateAuth(
  routeSelectedOrg(({ children, isLoading }: any) => {
    const { selectedOrgType, selectedOrg, selectedOrgId } =
      useAppState().organization;
    const router = useRouter();
    const [layoutState, setLayoutState] = useState<string | null>(null);
    // Check Org Type - Creator, Publisher, Merchant
    // return Layout
    useEffect(() => {
      setLayoutState(null);
      if (selectedOrgType === 'publishers') {
        if (selectedOrg.publisher_type === 2) {
          setLayoutState('creatorLayout');
        } else {
          setLayoutState('publisherLayout');
        }
      }

      if (selectedOrgType === 'merchants') {
        setLayoutState('merchantLayout');
      }

      if (selectedOrgType === 'admin') {
        setLayoutState('adminLayout');
      }
    }, [selectedOrgId]);

    if (isLoading || layoutState === null) {
      return <LoadingLayout />;
    }

    if (layoutState === 'adminLayout') {
      return <DefaultLayout>{children}</DefaultLayout>;
    }
    if (layoutState === 'creatorLayout') {
      return <CreatorLayout>{children}</CreatorLayout>;
    }
    if (layoutState === 'publisherLayout') {
      return <PublisherLayout>{children}</PublisherLayout>;
    }
    if (layoutState === 'merchantLayout') {
      return <MerchantLayout>{children}</MerchantLayout>;
    }

    return <></>;
  }),
);
