import React from 'react';
import Link from 'next/link';
import { Button } from '@frontend/shared-ui-kit/button';
import classNames from 'classnames';
import moment from 'moment';
import { Typography } from '@frontend/shared-ui-kit/typography';

interface BannerProps {
  title: string;
  href: string;
  buttonText: string;
  countDownStartDate: string;
  countDownEndDate: string;
  beforeStartDescription: string;
  afterStartDescription: string;
}
export const CountdownBanner = ({
  title,
  href,
  buttonText,
  countDownEndDate,
  countDownStartDate,
  beforeStartDescription,
  afterStartDescription,
}: BannerProps) => {
  const now = moment();

  const beforeDate = moment(countDownStartDate)
    .startOf('day')
    .diff(now, 'days');

  const endDate = moment(countDownEndDate).endOf('day').diff(now, 'days');

  const isStarting = beforeDate > 0 && endDate > 0;
  const started = beforeDate < 0 && endDate > 0;

  let formattedDate;

  if (beforeDate === 0) {
    const hoursLeft = moment(countDownStartDate)
      .startOf('day')
      .diff(now, 'hours');
    formattedDate = `${Math.abs(hoursLeft)} hour${hoursLeft > 1 ? 's' : ''} to`;
  } else if (isStarting) {
    formattedDate = `${Math.abs(beforeDate)} day${
      beforeDate > 1 ? 's' : ''
    } to`;
  } else if (endDate === 0) {
    const hoursLeft = moment(countDownEndDate).endOf('day').diff(now, 'hours');
    formattedDate = `${Math.abs(hoursLeft)} hour${
      hoursLeft !== 1 ? 's' : ''
    } Left!`;
  } else {
    formattedDate = `${endDate} Day${endDate !== 1 ? 's' : ''} Left!`;
  }

  const ended = endDate < 0;
  if (ended) return null;

  return (
    <Link
      href={href}
      className={classNames(
        'bg-white flex flex-col xl:flex-row text-center xl:text-left items-center justify-center mt-8 px-8 py-6 relative z-20 mx-4 sm:mx-12 -mb-4 sm:mb-12 shadow rounded-lg cursor-pointer max-w-[740px] lg:max-w-none md:mx-auto lg:mx-8 lg:mb-8',
      )}
    >
      <div>
        <Typography variant="h1">{formattedDate}</Typography>
        <Typography variant="h3">{title}</Typography>
      </div>

      <span className="text-sm sm:text-base flex-1 mr-3 mb-4 xl:mb-0 xl:pl-6 xl:pr-8">
        {started ? afterStartDescription : beforeStartDescription}
      </span>
      <div className="flex-none">
        <Button color="violet" size="lg">
          {buttonText}
        </Button>
      </div>
    </Link>
  );
};
