import { Context } from '..';

export const setInitialReportParams = (
  { state }: Context,
  value: {
    columns: string[];
    reportType: string;
    endpoint: string;
  },
) => {
  const { columns, reportType, endpoint } = value;

  state.report.params.admin_stats = state.session.sessionData.isAdminView;
  state.report.params.report_params.columns = columns;
  state.report.params.report_params.report_type = reportType;
  state.report.params.report_params.endpoint = endpoint;
};

export const setMerchIds = ({ state }: Context, value: any[]) => {
  state.report.params.merch_ids = value.toString();
};

export const _pollReportReady = (
  { state, effects }: Context,
  pollingData: {
    report_url: string;
    report_key: string;
    org_type: string;
    baseUrl: string;
  },
) => {
  const { report_url, report_key, baseUrl } = pollingData;

  const statusCheckUrl = `${baseUrl}partner_reports/${report_key}/status/`;

  const pollUrl = setInterval(async () => {
    try {
      const response = await effects.api.get(statusCheckUrl, null, {
        headers: { Range: 'bytes=0-0' },
      });
      if (response.status === 200) {
        clearInterval(pollUrl);
        clearTimeout(reportTimeout);
        effects.report.triggerReportDownload({ report_url, report_key });
        state.report.isPolling = false;
      }
    } catch (error: any) {
      state.report.error = {
        showError: true,
        errorMessage:
          error?.message || 'Something went wrong while generating report',
      };
      state.report.isPolling = false;
    }
  }, 5000);

  const reportTimeout = setTimeout(() => {
    clearInterval(pollUrl);
    // TODO: invoke ModalService when implemented
    const error = 'Something went wrong while generating report';
    state.report.error = {
      showError: true,
      errorMessage: error,
    };
    state.report.isPolling = false;
  }, 300000);
};

export const _getSlug = async (
  { state, effects }: Context,
  { baseUrl, additionalSlug }: { baseUrl: string; additionalSlug?: string },
) => {
  try {
    const result = await effects.api.get(baseUrl);
    const slug = result.data.data[0].slug;
    if (additionalSlug) {
      additionalSlug = additionalSlug
        .normalize('NFKD')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '_')
        .replace(/\//g, '-')
        .replace(/[^-\w]+/g, '')
        .replace(/--+/g, '-');
    }
    const reportSlug = additionalSlug ? `${slug}_${additionalSlug}` : slug;
    state.report.params.report_params.slug = reportSlug;
  } catch (error: any) {
    state.report.error = {
      showError: true,
      errorMessage:
        error?.message || 'Something went wrong while generating report',
    };
  }
};

export const _postRequestReport = async (
  { state, effects, actions }: Context,
  {
    baseUrl,
    orgId,
    additionalSlug,
  }: { baseUrl: string; orgId: string; additionalSlug?: string },
) => {
  await actions.report._getSlug({ baseUrl, additionalSlug });

  const payload = state.report.params;
  const postResponse = await effects.api.post(
    `${baseUrl}request_partner_report/`,
    payload,
  );
  const reportKey = postResponse.data.data[0].report_key;
  const reportData = await effects.api.get(
    `${baseUrl}partner_reports/${reportKey}/`,
  );
  const pollingData = {
    report_url: reportData.data.data[0].report_url,
    report_key: reportKey,
    org_type: orgId,
    baseUrl: baseUrl,
  };

  actions.report._pollReportReady(pollingData);
};

export const requestReport = async (
  { state, actions }: Context,
  value: {
    rowCount?: number;
    page?: number;
    perPage?: number;
    dateTo?: string;
    dateFrom?: string;
    reportParams?: any;
    additionalSlug?: string;
    giftingAvailable?: boolean;
    onSale?: boolean;
    productLaunch?: boolean;
    flatFee?: boolean;
    cpc?: boolean;
    additional_orgs?: string;
    direction?: string;
    orderBy?: string;
  },
) => {
  const {
    rowCount,
    page,
    perPage,
    dateTo,
    dateFrom,
    reportParams,
    additionalSlug,
    giftingAvailable,
    onSale,
    productLaunch,
    flatFee,
    cpc,
    additional_orgs,
    direction,
    orderBy,
  } = value;
  const userRole = state.session.sessionData.userRole;
  const orgId = state.session.sessionData.orgId;

  const baseUrl = `/api/v0/${userRole}/${orgId}/`;
  state.report.params.date_to = dateTo;
  state.report.params.date_from = dateFrom;
  state.report.params.page = page;
  state.report.params.per_page = perPage;
  state.report.params.gifting_available = giftingAvailable;
  state.report.params.on_sale = onSale;
  state.report.params.product_launch = productLaunch;
  state.report.params.flat_fee = flatFee;
  state.report.params.cpc = cpc;
  state.report.rowCount = rowCount;
  state.report.isPolling = true;
  state.report.params.additional_orgs = additional_orgs;
  state.report.params.direction = direction;
  state.report.params.order_by = orderBy;

  if (reportParams) {
    state.report.params = { ...state.report.params, ...reportParams };

    if (!reportParams.search_query) {
      delete state.report.params.search_query;
    }
  }

  try {
    await actions.report._postRequestReport({ baseUrl, orgId, additionalSlug });
  } catch (error: any) {
    state.report.error = {
      showError: true,
      errorMessage:
        error?.message || 'Something went wrong while generating report',
    };
    state.report.isPolling = false;
  }
};

export const clearError = ({ state }: Context) => {
  state.report.error = {
    showError: false,
    errorMessage: '',
  };
};

export const setReportColumns = ({ state }: Context, columns: string[]) => {
  state.report.params.report_params.columns = columns;
};
