import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useLocalStorage } from '@mantine/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'radash';

import { useActions, useAppState } from '@frontend/howl-web-app/overmind';

import { GlobalBanner } from '@frontend/shared-ui-kit/v2/banner/global-banner';
import { isObject } from 'lodash';

const VerifyEmailBanner = () => {
  const flags = useFlags();
  const router = useRouter();
  const { data: session } = useSession();
  const email_verified = session?.user?.email_verified;
  const { sessionData } = useAppState().session;
  const { requestVerifyEmail } = useActions().auth;

  const [sentRequest, setSentRequest] = useState<boolean>(false);
  const [hideBanner, setHideBanner] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [sessionHideVerifyEmail, setValue] = useLocalStorage<boolean>({
    key: 'sessionHideVerifyEmail',
    defaultValue: false,
  });

  // explicit false check on email_verified to prevent any issues from erroneously showing this banner if the value doesn't get set
  // don't show ever if the flag is false (undeployed)
  useEffect(() => {
    setHideBanner(
      isEmpty(session) ||
        !isObject(session) ||
        email_verified !== false ||
        sessionHideVerifyEmail ||
        !flags.delayedEmailVerification ||
        !sessionData.email ||
        router.pathname.includes('/onboarding'),
    );
  }, [
    session,
    email_verified,
    sessionHideVerifyEmail,
    flags.delayedEmailVerification,
    sessionData.email,
    router.pathname,
  ]);

  const sendVerifyEmail = () => {
    setSentRequest(true);
    requestVerifyEmail(sessionData.email)
      .then(() => {
        setSentRequest(true);
      })
      .catch((error: any) => {
        setError('Unable to send verification email. Please try again later.');
      });
  };

  const handleClick = () => {
    if (sentRequest) {
      // hide banner
      setValue(true);
    } else {
      sendVerifyEmail();
    }
  };

  const buttonText = sentRequest && !error ? 'Close' : 'Resend';
  const mainText = sentRequest
    ? error
      ? error
      : 'An email has been sent.'
    : 'Your account setup is not yet complete. To finish and set up payments, click on the verification link we sent to your e-mail.';

  return (
    !hideBanner && (
      <div>
        <GlobalBanner
          text={mainText}
          buttonText={buttonText}
          onClick={handleClick}
          variant={'warning'}
        ></GlobalBanner>
      </div>
    )
  );
};

export default VerifyEmailBanner;
