export type GuildBrand = {
  full_name: string;
  logo_url: string;
  merch_id: number;
};

export type OnboardStateResponse = {
  first_name: string;
  last_name: string;
  creator_name: string;
  email: string;
  brand_selections: any[];
  guild_selections: any[];
  social_channels: any[];
};

type CreatorChannels = {
  channel_type: number;
  channel_url: string;
  creator_profile_channel_id: number;
  phyllo_data?: {
    engagement_rate: number;
    audience_size: number;
    us_audience: number;
  };
};

type CreatorOnboardingState = {
  data: OnboardStateResponse | null;
  formState: {
    firstName: string;
    lastName: string;
    creatorName: string;
    email: string;
  };
  creatorChannels: CreatorChannels[];
  selectedGuilds: { [k: string]: boolean };
  selectedBrands: { [k: string]: null | { merch_id: number; name: string } };
  guildOptions: { category_id: number; name: string }[];
  brandOptions: { merch_id: number; full_name: string; logo_url: string }[];
  isLoading: 'basic' | 'channels' | 'guilds' | 'brands' | null;
  isSubmitting: 'basic' | 'channels' | 'guilds' | 'brands' | null;
  // If logged in user already includes email.  This might be empty based on SSO.
  hasEmail: boolean;
  progressTracker: {
    step: number;
    pageOrder: string[];
  };
  onboardingStatus: number | null;
  debugMode: boolean;
  debugRejectedFlow: boolean;
  acquisitionType: string | null;
  referralType: number | null;
};

export const state: CreatorOnboardingState = {
  data: null,
  formState: {
    firstName: '',
    lastName: '',
    creatorName: '',
    email: '',
  },
  debugMode: false,
  debugRejectedFlow: false,
  creatorChannels: [],
  selectedGuilds: {},
  selectedBrands: {},
  guildOptions: [],
  brandOptions: [],
  hasEmail: false,
  isLoading: 'basic',
  isSubmitting: null,
  onboardingStatus: null,
  acquisitionType: null,
  progressTracker: {
    step: 1,
    pageOrder: ['info', 'channels'],
  },
  referralType: null,
};
