'use client';

import debounce from 'lodash/debounce';
import { useEffect, useCallback } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
const noOp = () => {
  // do nothing
};

export function useOutsideClick(
  node: any,
  cb: (param: any) => void,
  onMount: () => void = noOp,
  deps: any[] = [],
) {
  const onWindowClick = (e: MouseEvent) => {
    if (!node.current) return;

    if (!node.current.contains(e.target)) {
      cb(false);
    }
  };

  const scheduleUseOutsideClick = useCallback(
    debounce(async () => {
      window.addEventListener('click', onWindowClick);
    }, 200),
    [],
  );

  useEffect(() => {
    onMount();

    scheduleUseOutsideClick();

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, [...deps]);
}
