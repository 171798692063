import dynamic from 'next/dynamic';
import React, { ReactNode } from 'react';

const NonSSRWrapperDynamic = (props: { children: ReactNode }) => (
  <React.Fragment>{props.children}</React.Fragment>
);

export const NonSSRWrapper = dynamic(
  () => Promise.resolve(NonSSRWrapperDynamic),
  {
    ssr: false,
  },
);
