import { Campaign, GiftInfo } from '../creator-campaign-center/state';
import { Products, feedbackStatus } from '../creator-campaign-center';

type ecommCampaignCenter = {
  selectedCampaign: Campaign | null;
  products: Products;
  productsTotalItems: number;
  isLoading: boolean;
  feedbackStatus: feedbackStatus | null;
  giftInfo: GiftInfo | null;
};

export const state: ecommCampaignCenter = {
  selectedCampaign: null,
  products: [],
  productsTotalItems: 0,
  isLoading: false,
  feedbackStatus: null,
  giftInfo: null,
};
