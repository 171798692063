export const triggerReportDownload = (props: {
  report_key: string;
  report_url: string;
}) => {
  const { navigator } = window;

  // If in Chrome or Safari - download via virtual link click
  const isChromeOrSafari =
    (/Chrome/.test(navigator.userAgent) &&
      /Google Inc/.test(navigator.vendor)) ||
    (/Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor));

  if (isChromeOrSafari) {
    const el = document.createElement('a');
    el.setAttribute('href', props.report_url);
    el.style.display = 'none';
    el.onclick = (e) => {
      e.stopPropagation();
    };

    /*
     * Some Chromium browsers like Chrome on IOS don't support downloads,
     * check if download property is available on element.
     */
    if (el.download !== undefined) {
      el.setAttribute('download', `${props.report_key}.tsv`);
    } else {
      window.open(props.report_url);
    }

    /*
     * Add anchor el to DOM, download property tells the browser that a click
     * should trigger a download from the href. Append to header to avoid reopening
     * the reports dropdown when download is complete
     */
    const body = document.querySelector('body');
    if (body) {
      body.appendChild(el);
      el.click();
      body.removeChild(el);
    }
  } else {
    window.open(props.report_url);
  }
};
