type State = {
  merchants: any[];
  brands: any;
  error: string;
  errorMsg: string;
  successMsg: string;
  defaultMsg: string;
};

export const state: State = {
  merchants: [],
  brands: {},
  error: '',
  errorMsg: 'Please fix errors and try again.',
  successMsg: 'This merchant and item ID combination is good.',
  defaultMsg: "Choose a merchant and item ID to see if it's unique.",
};
