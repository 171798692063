import React, { FC, ReactNode, useEffect, useState } from 'react';
import Script from 'next/script';
import { Button } from '../button';
import { ChevronRight, HelpCircle, Loader, X } from 'react-feather';
import { Transition } from '@headlessui/react';
import { useClickOutside, useMediaQuery } from '@mantine/hooks';
import { Typography } from '../typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type HelpWidgetProps = {
  /** Username */
  name?: any;
};

declare global {
  interface Window {
    zE: any;
  }
}

/** The HelpWidget component presents a fixed "need help?" prompt/button for customer service. */
export const HelpWidget: FC<HelpWidgetProps> & { filePath: string } = ({
  name,
}) => {
  const [showHelpDesk, toggleHelpDesk] = useState(false);
  const ref = useClickOutside(() => toggleHelpDesk(false));
  const isSmBreakPoint = useMediaQuery('(min-width: 640px)');
  const [notificationNum, setNotificationNum] = useState<number | null>();
  const [zendeskReady, setZendeskReady] = useState(false);
  const [zendeskLoading, setZendeskLoading] = useState(false);
  const flags = useFlags();

  const initZendesk = () => {
    setZendeskReady(true);
    if (window && (window as any).zE) {
      // unread Message on listener
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window['zE']('messenger:on', 'unreadMessages', (count: number) => {
        populateUnreadIndicator(count);
      });

      window['zE']('messenger:on', 'close', () => {
        setZendeskLoading(false);
      });
    }
  };

  const openZendeskChat = () => {
    window['zE']('messenger', 'open');
    setZendeskLoading(true);
  };

  const populateUnreadIndicator = (count: number) => {
    if (!count) return resetUnreadIndicator();

    setNotificationNum(count);
  };

  const resetUnreadIndicator = () => {
    setNotificationNum(null);
  };

  return (
    <>
      <Transition
        show={!showHelpDesk}
        appear={true}
        enter="transition duration-75 ease-in-out fixed bottom-8 overflow-visible opacity-100 z-40 transition duration-200 ease-in right-0 mx-6 my-6"
        enterFrom="opacity-0 transform -translate-y-4"
        enterTo="opacity-100 transform translate-y-0"
        leave="transition duration-150 ease-in-out fixed bottom-8 overflow-visible opacity-100 z-40 transition duration-200 ease-in right-0 mx-6 my-6"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-4"
        entered="fixed bottom-8 overflow-visible opacity-100 z-40 transition duration-200 ease-in right-0 mx-6 my-6"
      >
        {isSmBreakPoint ? (
          <div className="relative">
            <Button
              color="violet"
              size="xl"
              leftIcon={<HelpCircle className="w-6 h-6 sm:mr-2" />}
              onClick={() => {
                if (notificationNum) {
                  openZendeskChat();
                }
                toggleHelpDesk(!showHelpDesk);
                // reset unread indicator
                resetUnreadIndicator();
              }}
            >
              <span className="hidden sm:inline">Support</span>
            </Button>
            {notificationNum && (
              <div
                id="unread-indicator"
                className="animate-in slide-in-to-top  absolute -top-2 right-0 inline-flex rounded-full text-xs bg-system-critical text-white h-6 w-6 ml-2 items-center justify-center"
              >
                {notificationNum}
              </div>
            )}
          </div>
        ) : (
          <div className="relative">
            <button
              onClick={() => toggleHelpDesk(!showHelpDesk)}
              className="w-11 h-11 bg-violet-400 focus:bg-violet-500 flex justify-center items-center text-white rounded-full"
            >
              <HelpCircle className="w-6 h-6" />
            </button>
            {notificationNum && (
              <div
                id="unread-indicator"
                className="animate-in slide-in-to-top  absolute -top-2 right-0 inline-flex rounded-full text-xs bg-system-critical text-white h-6 w-6 ml-2 items-center justify-center"
              >
                {notificationNum}
              </div>
            )}
          </div>
        )}
      </Transition>
      <Transition
        show={showHelpDesk}
        enter="sm:mx-8 sm:my-6 fixed z-100 right-0 top-0 bottom-8 sm:top-auto sm:left-auto w-full h-screen sm:w-72 sm:h-600 ease-in-out transition duration-200"
        enterFrom="opacity-0 transform -translate-y-8"
        enterTo="opacity-100 transform translate-y-0"
        leave="sm:mx-8 sm:my-6 fixed z-100 right-0 top-0 bottom-8 sm:top-auto sm:left-auto w-full h-screen sm:w-72 sm:h-600 ease-in-out  transition duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-8"
        entered="fixed z-100 w-full h-screen right-0 top-0 bottom-8 sm:top-auto sm:left-auto sm:w-72 sm:h-600 sm:mx-8 sm:my-6"
      >
        <div
          ref={ref}
          className="w-full h-full flex flex-col bg-white shadow-elevation-high sm:rounded-lg bg-gradient-to-b from-[#A19BC4] from-10% to-80% to-tatooine-10 p-4"
        >
          <div className="flex justify-end">
            <button
              className="w-8 h-8 flex justify-center items-center bg-white rounded-full"
              onClick={() => toggleHelpDesk(false)}
            >
              <X className="w-4 h-4" />
            </button>
          </div>
          <div className="mt-2 flex flex-col h-full">
            <div className="text-center">
              <Typography variant="subtitle" className="text-violet-500">
                Hi
              </Typography>
              <Typography variant="subtitle" className="text-violet-500">
                {name}
              </Typography>
              <p className="text-2xl animate-wiggle mb-2">
                <span role="img" aria-label="hand wave">
                  👋
                </span>
              </p>
              <Typography variant="subtitle" className="text-violet-500">
                How can we help?
              </Typography>
            </div>
            <div className="flex flex-col space-y-2 mt-6">
              <a
                className="text-center w-full py-2 px-4 bg-white rounded-lg text-violet-300 hover:bg-ice-10 focus:bg-ice-10 transition"
                href="https://help.planethowl.com/hc/en-us"
                target="_blank"
                rel="noreferrer"
              >
                Visit Howl Help Center
              </a>
              <a
                className="text-center w-full py-2 px-4 bg-white rounded-lg text-violet-300 hover:bg-ice-10 focus:bg-ice-10 transition"
                href="https://help.planethowl.com/hc/en-us/requests/new"
                target="_blank"
                rel="noreferrer"
              >
                Customer Care
              </a>
            </div>

            {/* List View */}
            <div className="w-full mt-2 py-2 px-4 bg-white rounded-lg h-full grow">
              {flags.zendeskLiveChatButton && zendeskReady && (
                <button
                  className="items-center border-b text-left w-full py-2 text-black focus:text-violet-300 hover:text-violet-300 flex justify-between transition-colors"
                  id="conversation-badge"
                  onClick={() => {
                    if (window && window['zE']) {
                      openZendeskChat();
                    }
                  }}
                >
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      Live Chat Support
                      <span className="text-text-secondary text-sm">
                        Tues/Wed 12pm - 2pm PST<span></span>
                      </span>
                    </div>

                    {notificationNum && (
                      <div
                        id="unread-indicator"
                        className="inline-flex border border-white rounded-full text-xs bg-system-critical text-white h-6 w-6 ml-2 items-center justify-center"
                      >
                        {notificationNum}
                      </div>
                    )}
                    {zendeskLoading && (
                      <Loader className="ml-4 h-4 w-4 animate-spin fill-black" />
                    )}
                  </div>
                  <span>
                    <ChevronRight className="w-4 h-4 stroke-violet-200" />
                  </span>
                </button>
              )}
              <a
                className="items-center border-b text-left w-full py-2 text-black focus:text-violet-300 hover:text-violet-300 flex justify-between transition-colors"
                href="https://calendly.com/howlcommunityevents/new-creator-onboarding"
                target="_blank"
                rel="noreferrer"
              >
                Sign Up for a Webinar
                <span>
                  <ChevronRight className="w-4 h-4 stroke-violet-200" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </Transition>
      <Script
        onReady={initZendesk}
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=b858c28b-b445-4f24-9086-6e320b3d1b31"
      />
    </>
  );
};

HelpWidget.displayName = 'HelpWidget';
HelpWidget.filePath = 'libs/shared/ui-kit/src/lib/help-widget/help-widget.tsx';
