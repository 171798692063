export type Session = {
  authVersion?: any;
  bamxVersion?: any;
  orgId?: any;
  isAdmin?: any;
  isOrgAdmin?: any;
  orgName?: any;
  termsAccepted?: any;
  userId?: any;
  userName?: any;
  userRole?: any;
  accessList?: any[];
  email?: any;
  firstName?: any;
  lastName?: any;
  accessToken?: any;
  refreshToken?: any;
  exp?: any;
  publisher_type?: any;
  instant_pay_access?: any;
  billing_platform?: any;

  /*
   * Used to save user-selected params like date range or columns. e.g.
   * {date_from: '2019-03-23', date_to: '2019-04-06',
   *  article_columns: [], merchant_columns: [], ...}
   */
  dashboardParams?: any;
  isAdminView?: any; // Used to feature toggle while logged as admin
  allSites?: any; // Used to toggle multi-account view
  allPubs?: any; // Used for admin multi-account view
  allAdminOrgs?: any; // Used for admin multi-account view
  merchPricingModel?: any; // Used to hide Category/Brand bidding page from CPA merchants
};

type State = {
  sessionData: Session;
};

export const state: State = {
  sessionData: {
    authVersion: null,
    orgId: null,
    orgName: null,
    isAdmin: null,
    isOrgAdmin: null,
    termsAccepted: null,
    userId: null,
    userName: null,
    userRole: null,
    // accessList: [],
    email: null,
    firstName: null,
    lastName: null,
    accessToken: null,
    refreshToken: null,
    exp: null,
    publisher_type: null,
    instant_pay_access: null,
    billing_platform: null,
    dashboardParams: null,
    isAdminView: null,
    allSites: null,
    allPubs: null,
    allAdminOrgs: null,
    merchPricingModel: null,
  },
};
