/* eslint @typescript-eslint/no-useless-constructor: 0 */
class Columns {
  statColumns: any;
  optionalColumns: any;
  defaultFields: any;
  merchantDefaultFields: any;
  roundedFields: any;
  roundedFieldsLessThanOne: any;
  merchantStatColumns: any;
  productStatColumns: any;
  merchantArticleColumns: any;
  merchantRecommendationColumns: any;
  optionalMerchantArticleColumns: any;
  optionalMerchantRecommendationColumns: any;
  staticColumnsByStatType: any;
  staticFieldsByStatType: any;
  sortDisabledFields: any;
  merchantArticleDisabledFields: any;
  merchantRecommendationDisabledFields: any;
  defaultAscendingFields: any;
  orgAdminOnlyFields: any;
  merchantArticleSearchFields: any;
  merchantProductsSearchFields: any;
  MerchantPerformanceOverviewFields: any;
  Top5Fields: any;
  Top5PubsFields: any;
  top5StoriesFields: any;
  merchantTopPublisherFields: any;
  merchantTopPublisherHiddenFields: any;
  strategyTopPublishersFields: any;
  strategyTopStoriesFields: any;
  strategyTopRecommendationsFields: any;
  strategyTopProductsFields: any;

  constructor() {
    this.statColumns = [
      // Columns on article/smartlink/merchant tables, ordered for the first two
      {
        label: 'Earnings',
        name: 'publisher_total_earnings',
        type: 'currency',
        tooltip: `The Amount You've Earned`,
        tooltipSize: 1.7,
      },
      {
        label: 'Revenue',
        name: 'total_attributed_revenue',
        type: 'currency',
        tooltip: `Revenue driven to the merchant on sales of products you've written about`,
        tooltipSize: 2.8,
      },
      {
        label: 'Clicks',
        name: 'clicks',
      },
      {
        label: 'Conversion Rate',
        name: 'product_conversion_rate',
        type: 'percent',
        tooltip: 'Products sold divided by Clicks',
        tooltipSize: 0.8,
      },
      {
        label: 'AOV',
        name: 'publisher_aov',
        type: 'currency',
        tooltip: 'Average Order Value',
        tooltipSize: 2.6,
      },
    ];
    this.optionalColumns = [
      {
        label: 'Attr. Model',
        name: 'attribution_model',
        type: 'string',
        tooltip: 'Attribution Model',
        tooltipSize: 1,
      },
      {
        label: 'CTR',
        name: 'click_through_rate',
        type: 'percent',
        tooltip: 'Clickthrough Rate',
        tooltipSize: 2.6,
      },
      {
        label: 'Orders',
        name: 'total_attributed_orders',
      },
      {
        label: 'Order Conversion Rate',
        name: 'order_conversion_rate',
        type: 'percent',
        tooltip: 'Orders divided by Clicks',
        tooltipSize: 1,
      },
    ];

    // Columns displayed by default, except the static columns
    this.defaultFields = [
      'total_attributed_revenue',
      'publisher_total_earnings',
      'clicks',
      'publisher_epc',
      'product_conversion_rate',
      'publisher_aov',
    ];
    this.merchantDefaultFields = {
      merchant: [
        'publisher_epc',
        'publisher_total_earnings',
        'clicks',
        'total_attributed_revenue',
        'product_conversion_rate',
        'publisher_aov',
      ],
      merchantArticle: [
        'edit_name',
        'pub_name',
        'clicks',
        'order_conversion_rate',
        'total_attributed_revenue',
      ],
      merchantTopPublisher: [
        'pub_name',
        'merchant_spend',
        'attributed_revenue',
        'roi',
        'impressions',
        'clicks',
        'click_through_rate',
        'order_conversion_rate',
      ],
      merchantRecommendation: [
        'edit_name',
        'pub_name',
        'clicks',
        'order_conversion_rate',
        'total_attributed_revenue',
      ],
    };

    this.roundedFields = [
      'commission_rate',
      'commission_rate_new',
      'merchant_revenue',
      'merchant_spend',
      'product_price',
      'publisher_aov',
      'publisher_effective_cpc',
      'publisher_cpa_revenue',
      'publisher_cpc_revenue',
      'publisher_total_earnings',
      'publisher_earnings_usd',
      'attributed_revenue_usd',
      'total_attributed_revenue',
      'revenue',
      'spend',
      'roi_variance',
      'daily_budget_amount',
      'monthly_budget_amount',
      'percent_spend_remaining',
      'merchants_revenue',
      'pacing_towards',
      'revenue_percent_difference',
      'spend_percent_difference',
      'take_rate_percent_difference',
      'earnings_percent_difference',
      'earnings',
      'mtd_spend',
      'guardrail_upper_bound',
      'guardrail_lower_bound',
      'budget_amount',
      'budget_pacing',
      'daily_budget_usd',
      'monthly_budget_usd',
      'attributed_revenue',
      'revenue_current',
      'spend_current',
    ];

    this.roundedFieldsLessThanOne = ['publisher_total_earnings'];

    // Table-specific columns
    this.merchantStatColumns = [
      {
        label: 'EPC',
        name: 'publisher_epc',
        type: 'currency',
        tooltip: 'Earnings per Click',
        tooltipSize: 2.6,
      },
      {
        label: 'Smartlinks',
        name: 'bam_link_count',
        tooltip: 'Number of Smartlinks',
        tooltipSize: 1.5,
      },
      {
        label: 'RPC',
        name: 'publisher_rpc',
        type: 'currency',
        tooltip: 'Revenue per Click',
        tooltipSize: 2.6,
      },
      { label: 'Views', name: 'bam_link_viewable_impressions' },
      { label: 'Impressions', name: 'bam_link_impressions' },
      { label: 'Clicks (CPA)', name: 'cpa_clicks' },
      { label: 'Clicks (OON)', name: 'oon_clicks' },
      { label: 'EPC (CPA)', name: 'publisher_effective_cpc', type: 'currency' },
      {
        label: 'Earnings (CPA)',
        name: 'publisher_cpa_revenue',
        type: 'currency',
      },
      {
        label: 'Earnings (CPC)',
        name: 'publisher_cpc_revenue',
        type: 'currency',
      },
    ];
    this.productStatColumns = [
      {
        label: 'Price',
        name: 'product_price',
        type: 'currency',
        tooltip:
          'Average price of the product across all checkouts in the time period ' +
          '(individual prices can vary)',
        tooltipSize: 4.5,
      },
      {
        label: 'Merchant',
        name: 'merchant_name',
        type: 'string',
      },
      {
        label: 'Quantity',
        name: 'quantity',
        tooltip: 'Total units sold of the product',
        tooltipSize: 2.5,
      },
      {
        label: 'Revenue',
        name: 'total_attributed_revenue',
        type: 'currency',
        tooltip:
          'Total revenue driven to the merchant by the product ' +
          'bought in the time period',
        tooltipSize: 3,
      },
    ];

    this.merchantArticleColumns = [
      { label: 'Creator', name: 'pub_name', type: 'string' },
      { label: 'Clicks', name: 'clicks' },
      {
        label: 'Conversion Rate',
        name: 'order_conversion_rate',
        type: 'percent',
      },
      { label: 'Revenue', name: 'total_attributed_revenue', type: 'currency' },
    ];

    this.merchantRecommendationColumns = [
      {
        label: 'Recommended Product',
        name: 'recommended_product',
        type: 'string',
      },
      { label: 'Creator', name: 'pub_name', type: 'string' },
      { label: 'Post', name: 'edit_name', type: 'string' },
      { label: 'Clicks', name: 'clicks' },
      {
        label: 'Conversion Rate',
        name: 'order_conversion_rate',
        type: 'percent',
      },
      { label: 'Revenue', name: 'total_attributed_revenue', type: 'currency' },
    ];

    this.optionalMerchantArticleColumns = [
      { label: 'Impressions', name: 'impressions' },
      { label: 'CTR', name: 'click_through_rate', type: 'percent' },
      { label: 'Spend', name: 'merchant_spend', type: 'currency' },
      { label: 'CPC', name: 'cost_per_click', type: 'currency' },
      { label: 'Orders', name: 'total_attributed_orders' },
      { label: 'Products Sold', name: 'total_attributed_product_count' },
    ];

    this.optionalMerchantRecommendationColumns = [
      { label: 'Impressions', name: 'impressions' },
      { label: 'CTR', name: 'click_through_rate', type: 'percent' },
      { label: 'Spend', name: 'merchant_spend', type: 'currency' },
      { label: 'CPC', name: 'cost_per_click', type: 'currency' },
      { label: 'Orders', name: 'total_attributed_orders' },
      { label: 'Products Sold', name: 'total_attributed_product_count' },
    ];

    this.staticColumnsByStatType = {
      article: [
        { label: 'Post', name: 'edit_name', type: 'string' },
        { label: 'Url', name: 'edit_url', type: 'string', exclude: true },
      ],
      merchant: [{ label: 'Merchant', name: 'merchant_name', type: 'string' }],
      product: [
        { label: 'Product Purchased', name: 'product_name', type: 'string' },
      ],
      smartlink: [
        {
          label: 'Link',
          name: 'bam_link_merchant_product_name',
          type: 'string',
        },
      ],
      merchantArticle: [{ label: 'Post', name: 'edit_name', type: 'string' }],
    };
    /*
     * Fields that cannot be de-selected in columns dropdown.
     * Currently also cannot be sorted by.
     */
    this.staticFieldsByStatType = {
      article: ['edit_name', 'edit_url'],
      merchant: ['merchant_name'],
      product: ['product_name'],
      smartlink: ['bam_link_merchant_product_name'],
      merchantArticle: ['edit_name'],
      merchantRecommendation: ['recommended_product', 'edit_name'],
      merchantTopPublisher: ['pub_name'],
    };

    /*
     * Fields where sorting isn't yet supported by the API
     */
    this.sortDisabledFields = ['affiliate_networks', 'edit_name'];

    this.merchantArticleDisabledFields = ['click_through_rate', 'pub_name'];

    this.merchantRecommendationDisabledFields = [
      'pub_name',
      'recommended_product',
    ];
    /*
     * Fields where we sort ascending by default
     */
    this.defaultAscendingFields = [
      'bam_link_merchant_product_name',
      'edit_name',
      'merchant_name',
      'product_name',
      'recommended_product',
    ];
    /*
     * Secondary users cannot see these fields across all tables
     */
    this.orgAdminOnlyFields = [
      'publisher_total_earnings',
      'publisher_epc',
      'commission_rate',
      'publisher_effective_cpc',
      'publisher_cpa_revenue',
      'publisher_cpc_revenue',
    ];

    this.merchantArticleSearchFields = [
      {
        label: 'Winning',
        name: 'is_winning',
        selected: true,
        sortDisabled: true,
      },
      {
        label: 'Publisher',
        name: 'pub_name',
        type: 'string',
        selected: true,
        sortDisabled: true,
      },
      {
        label: 'Article Name',
        name: 'edit_name',
        type: 'string',
        selected: true,
        sortDisabled: true,
      },
      { label: 'Page Views', name: 'impressions', selected: true },
      { label: 'Clicks', name: 'clicks', selected: true },
      {
        label: 'CVR',
        name: 'order_conversion_rate',
        type: 'percent',
        selected: true,
      },
      { label: 'Products', name: 'products_count', selected: true },
      {
        label: 'Revenue',
        name: 'revenue_range',
        type: 'string',
        selected: true,
      },
      // TODO: enable after first release article search
      // {label: 'AOV', name: 'publisher_aov', type: 'currency', selected: false},
      // {label: 'Last Date Updated', name: 'last_updated', selected: false},
    ];

    this.merchantProductsSearchFields = [
      {
        label: 'Brand',
        name: 'brand',
        type: 'string',
        selected: true,
        sortDisabled: true,
      },
      {
        label: 'Product Name',
        name: 'recommended_product',
        type: 'string',
        selected: true,
        sortDisabled: true,
      },
      { label: 'Clicks', name: 'clicks', selected: true },
      {
        label: 'Price',
        name: 'product_price',
        type: 'currency',
        selected: true,
        sortDisabled: true,
      },
      { label: 'Qty Sold', name: 'total_attributed_orders', selected: true },
      {
        label: 'Revenue',
        name: 'total_attributed_revenue',
        type: 'currency',
        selected: true,
      },
      {
        label: 'CVR',
        name: 'order_conversion_rate',
        type: 'percent',
        selected: true,
      },
      { label: 'AOV', name: 'publisher_aov', type: 'currency', selected: true },
    ];

    this.MerchantPerformanceOverviewFields = [
      { label: 'Status', name: 'status', type: 'string', selected: true },
      { label: 'Campaign Name', name: 'name', type: 'string', selected: true },
      {
        label: 'Campaign Label',
        name: 'labels',
        type: 'string',
        selected: true,
      },
      {
        label: 'Bidding Strategy',
        name: 'strategy_type',
        type: 'string',
        selected: false,
      },
      { label: 'ROI Goal', name: 'roi_goal', selected: true },
      { label: 'ROI', name: 'roi_actual', selected: true },
      {
        label: 'Budget',
        name: 'budget_amount',
        type: 'currency',
        selected: true,
      },
      { label: 'Spend', name: 'spend', type: 'currency', selected: true },
      {
        label: 'Campaign Pacing',
        name: 'budget_pacing',
        type: 'currency',
        selected: true,
      },
      { label: 'Revenue', name: 'revenue', type: 'currency', selected: true },
      { label: 'Impressions', name: 'impressions', selected: true },
      { label: 'Clicks', name: 'clicks', selected: true },
      {
        label: 'Start Date',
        name: 'datetime_start',
        type: 'string',
        selected: true,
      },
      {
        label: 'End Date',
        name: 'datetime_end',
        type: 'string',
        selected: true,
      },
    ];

    this.Top5Fields = [
      {
        label: 'Spend',
        name: 'merchant_spend',
        type: 'currency',
        selected: true,
      },
      {
        label: 'Revenue',
        name: 'attributed_revenue',
        type: 'currency',
        selected: true,
      },
      { label: 'Units Sold', name: 'attributed_product_count', selected: true },
      { label: 'Impressions', name: 'impressions', selected: true },
      { label: 'Clicks', name: 'clicks', selected: true },
      {
        label: 'CTR',
        name: 'click_through_rate',
        type: 'percent',
        selected: true,
      },
      { label: 'ROI', name: 'roi', selected: true },
    ];

    this.Top5PubsFields = [
      { label: 'Creator', name: 'pub_name', type: 'string', selected: true },
      ...this.Top5Fields,
    ];

    this.top5StoriesFields = [
      { label: 'Post', name: 'story_name', type: 'string', selected: true },
      { label: 'Creator', name: 'pub_name', type: 'string', selected: true },
      ...this.Top5Fields,
    ];

    this.merchantTopPublisherFields = [
      {
        label: 'Creator',
        name: 'pub_name',
        type: 'string',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Revenue',
        name: 'attributed_revenue',
        type: 'currency',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Impressions',
        name: 'impressions',
        selected: true,
        defaultValue: '-',
      },
      { label: 'Clicks', name: 'clicks', selected: true, defaultValue: '-' },
      {
        label: 'CTR',
        name: 'click_through_rate',
        type: 'percent',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'CVR',
        name: 'order_conversion_rate',
        type: 'percent',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Orders',
        name: 'attributed_orders',
        selected: false,
        defaultValue: '-',
      },
      {
        label: 'Units Sold',
        name: 'attributed_product_count',
        selected: false,
        defaultValue: '-',
      },
    ];

    this.merchantTopPublisherHiddenFields = [
      {
        label: 'Spend',
        name: 'merchant_spend',
        type: 'currency',
        selected: true,
        defaultValue: '-',
      },
      { label: 'ROI', name: 'roi', selected: true, defaultValue: '-' },
    ];

    this.strategyTopPublishersFields = [
      {
        label: 'Publisher',
        name: 'pub_name',
        type: 'string',
        selected: true,
        defaultValue: '-',
      },
      {
        label: '# of Stories',
        name: 'number_of_stories',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Spend',
        name: 'merchant_spend',
        type: 'currency',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Revenue',
        name: 'attributed_revenue',
        type: 'currency',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Units Sold',
        name: 'attributed_product_count',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Impressions',
        name: 'impressions',
        selected: true,
        defaultValue: '-',
      },
      { label: 'Clicks', name: 'clicks', selected: true, defaultValue: '-' },
      {
        label: 'CTR',
        name: 'click_through_rate',
        type: 'percent',
        selected: true,
        defaultValue: '-',
      },
      { label: 'ROI', name: 'roi', selected: true, defaultValue: '-' },
      {
        label: 'CVR',
        name: 'order_conversion_rate',
        type: 'percent',
        selected: true,
        defaultValue: '-',
      },
    ];

    this.strategyTopStoriesFields = [
      {
        label: 'Publisher',
        name: 'pub_name',
        type: 'string',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Story',
        name: 'story_name',
        type: 'string',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Link Created',
        name: 'link_created_date',
        type: 'string',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Spend',
        name: 'merchant_spend',
        type: 'currency',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Revenue',
        name: 'attributed_revenue',
        type: 'currency',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Units Sold',
        name: 'attributed_product_count',
        selected: true,
        defaultValue: '-',
      },
      {
        label: 'Impressions',
        name: 'impressions',
        selected: true,
        defaultValue: '-',
      },
      { label: 'Clicks', name: 'clicks', selected: true, defaultValue: '-' },
      {
        label: 'CTR',
        name: 'click_through_rate',
        type: 'percent',
        selected: true,
        defaultValue: '-',
      },
      { label: 'ROI', name: 'roi', selected: true, defaultValue: '-' },
      {
        label: 'CVR',
        name: 'order_conversion_rate',
        type: 'percent',
        selected: true,
        defaultValue: '-',
      },
    ];

    this.strategyTopRecommendationsFields = [
      {
        label: 'Recommended Product',
        name: 'brand_product_name',
        selected: true,
      },
      { label: 'Publisher', name: 'pub_name', selected: true },
      { label: 'Story', name: 'story_name', selected: true },
      { label: 'Spend', name: 'merchant_spend', selected: true },
      { label: 'Revenue', name: 'attributed_revenue', selected: true },
      { label: 'Units Sold', name: 'attributed_product_count', selected: true },
      { label: 'Impressions', name: 'impressions', selected: true },
      { label: 'Clicks', name: 'clicks', selected: true },
      { label: 'CTR', name: 'click_through_rate', selected: true },
      { label: 'CVR', name: 'order_conversion_rate', selected: true },
    ];

    this.strategyTopProductsFields = [
      { label: 'Brand', name: 'brand', selected: true },
      {
        label: 'Product Purchased',
        name: 'brand_product_name',
        selected: true,
      },
      { label: 'Price', name: 'unit_price', selected: true },
      { label: 'Units Sold', name: 'attributed_product_count', selected: true },
      { label: 'Revenue', name: 'attributed_revenue', selected: true },
    ];
  }

  getColumnsForTable(
    statType: any,
    isOrgAdmin = true,
    isAdminView = false,
    flags = null,
  ) {
    let columns: any;
    const staticColumns = this.staticColumnsByStatType[statType];

    if (statType === 'product') {
      columns = staticColumns.concat(this.productStatColumns);
    } else if (statType === 'merchant') {
      columns = staticColumns.concat(
        this.statColumns,
        this.optionalColumns.slice(1),
        this.merchantStatColumns,
      );
    } else if (statType === 'merchantArticle') {
      columns = staticColumns.concat(
        this.merchantArticleColumns,
        this.optionalMerchantArticleColumns,
      );
    } else if (statType === 'merchantArticleSearch') {
      columns = this.merchantArticleSearchFields;
    } else if (statType === 'merchantProductsSearch') {
      columns = this.merchantProductsSearchFields;
    } else if (statType === 'merchantRecommendation') {
      columns = this.merchantRecommendationColumns.concat(
        this.optionalMerchantRecommendationColumns,
      );
    } else if (statType === 'merchant-performance-overview') {
      columns = this.MerchantPerformanceOverviewFields;
    } else if (statType === 'merchantTopPublisher') {
      // Shallow copy to prevent mutation of base fields list
      columns = [...this.merchantTopPublisherFields];
      if (flags && (flags as any).bestBuyAdminFlag) {
        columns.splice(2, 0, ...this.merchantTopPublisherHiddenFields);
      }
    } else {
      columns = staticColumns.concat(this.statColumns, this.optionalColumns);
    }

    if (statType === 'merchant') {
      // Order columns according to merchantDefaultFields
      let orderedColumns: any = [];
      const defaultFields = this.getDefaultFields('merchant');

      defaultFields.forEach((fieldName: any) => {
        const index = columns.findIndex((col: any) => col.name === fieldName);
        orderedColumns = orderedColumns.concat(columns.splice(index, 1));
      });

      columns = orderedColumns.concat(columns);
    }

    if (!isOrgAdmin) {
      this.orgAdminOnlyFields.forEach((fieldName: any) => {
        const index = columns.findIndex((col: any) => col.name === fieldName);
        if (index !== -1) {
          columns.splice(index, 1);
        }
      });
    }
    return columns;
  }

  getDefaultFields(statType: any) {
    const staticFields = this.staticFieldsByStatType[statType];
    const merchTables = [
      'merchant',
      'merchantArticle',
      'merchantRecommendation',
      'merchantTopPublisher',
    ];
    const defaultFields = !merchTables.includes(statType)
      ? this.defaultFields
      : this.merchantDefaultFields[statType];

    // Ensure static fields are at the beginning of the array
    return staticFields.concat(defaultFields);
  }

  getDefaultSortOptions(statType: any) {
    const columnName =
      statType === 'product'
        ? 'total_attributed_revenue'
        : ['merchantArticle', 'merchantTopPublisher'].includes(statType)
        ? `${statType === 'merchantArticle' ? 'total_' : ''}attributed_revenue`
        : 'clicks';

    return {
      order_by: columnName,
      direction: 'desc',
    };
  }

  getSortDisabledFields(statType: any) {
    if (statType === 'merchantArticle') {
      return this.sortDisabledFields.concat(this.merchantArticleDisabledFields);
    }
    if (statType === 'merchantRecommendation') {
      return this.sortDisabledFields.concat(
        this.merchantRecommendationDisabledFields,
      );
    }
    if (statType === 'merchantTopPublisher') {
      return ['pub_name'];
    }
    return this.sortDisabledFields;
  }

  setColumnsSelected(columns: any, statType: any, savedParams: any) {
    const savedFields = savedParams && savedParams[`${statType}_columns`];

    if (savedFields) {
      columns.forEach((columnObj: any) => {
        columnObj.selected = savedFields.includes(columnObj.name);
      });
    } else {
      columns = this.selectDefaultColumns(columns, statType);
    }

    return columns;
  }

  selectDefaultColumns(columns: any, statType: any) {
    const defaultFields = this.getDefaultFields(statType);

    return columns.map((columnObj: any) => {
      columnObj.selected =
        defaultFields.includes(columnObj.name) || statType === 'product';
      return columnObj;
    });
  }

  parseBackendColumns(columns: any) {
    return columns
      .filter((col: any) => !col.exclude)
      .map((col: any) => {
        return {
          label: col.label,
          name: col.name,
          type: col.type,
          selected: col.default_selected,
          sortDisabled: col.sort_disabled || false,
        };
      });
  }
}

export { Columns };
