export const calculatePagination = (
  pageNum: number,
  perPage: number,
  limit: number,
) => {
  /*
   * We use a limit of 500 for stats queries, which doesn't directly
   * correspond with the frontend perPage. Need to calculate the offset
   * for the stats query, or where to fetch from the existing stats.
   */
  const offset = (pageNum - 1) * perPage;
  const page = Math.floor(offset / limit) + 1;
  // Indexes of the requested stats in the array of 500 stat objects
  const indexStart = offset % limit;
  const indexEnd = indexStart + perPage;

  return [page, indexStart, indexEnd];
};

/* Pagination helpers */
export const invalidPage = (
  page: number,
  totalItems: number,
  limit: number,
) => {
  const lastPage = Math.ceil(totalItems / limit);

  if (lastPage && page > lastPage) return true;
  return false;
};
