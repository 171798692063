import { titleCase } from '@frontend/shared-utils';
import { cloneDeep } from 'lodash';

enum profileChannelTypes {
  YouTube = 1,
  Instagram = 2,
  Twitch = 3,
  TikTok = 4,
  Substack = 5,
  Other = 6,
  Twitter = 7,
  Facebook = 8,
  Site = 9,
  Newsletter = 10,
}

export const getChannelName = (channel: any) => {
  const keys = Object.keys(profileChannelTypes);
  const res = keys.find(
    (key: any) => profileChannelTypes[key] === channel.channel_type,
  );

  return res;
};

export const formatBrands = (data: any) => {
  return data.map((brand: any) => {
    return brand._source
      ? {
          label: titleCase(brand._source.full_name),
          value: brand._source.full_name.toLowerCase(),
          merchId: brand._source.merch_id,
          disabled: false,
          imageUrl: brand._source.logo_url,
        }
      : {
          label: titleCase(brand.name),
          value: brand.name.toLowerCase(),
          merchId: brand.merch_id,
          disabled: false,
          hidden: true,
        };
  });
};

export const formatChannels = (data: any) => {
  const sorted = sortChannelTypes(data.getChannelTypes);
  return sorted.map((channel: any) => {
    const item = {
      label: channel.name,
      value: channel.name,
      disabled: false,
      imageUrl: `/images/socials/${channel.name.toLowerCase()}.svg`,
    };
    return item;
  });
};

export const sortChannelTypes = (data: any) => {
  const result: any = [...data];
  result.sort((a: any, b: any) => {
    if (Number(a.order) < Number(b.order)) {
      return -1;
    }

    return 0;
  });
  return result;
};

export const mockBrands = {
  data: {
    getActiveMerchants: {
      hits: {
        hits: [
          {
            _source: {
              cover_image_url:
                'https://static.bam-x.com/assets/staging/cover_images/merchants/00000test.jpeg',
              full_name: '00000test',
              logo_url:
                'https://static.bam-x.com/assets/staging/logos/merchants/00000test.jpeg',
              merch_id: 2549,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url:
                'https://static.bam-x.com/assets/staging/cover_images/merchants/000demo.jpeg',
              full_name: '000demo',
              logo_url:
                'https://static.bam-x.com/assets/staging/logos/merchants/000demo.jpeg',
              merch_id: 2447,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url: null,
              full_name: '00test',
              logo_url:
                'https://static.bam-x.com/assets/staging/logos/merchants/00test.png',
              merch_id: 2383,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url:
                'https://static.bam-x.com/assets/staging/cover_images/merchants/0testpymerch.jpeg',
              full_name: '0test py merch',
              logo_url: null,
              merch_id: 2510,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url:
                'https://static.bam-x.com/assets/staging/cover_images/merchants/qa_merch1.jpeg',
              full_name: 'Acceptance Merch1',
              logo_url:
                '//static.bam-x.com/assets/logos/merchants/qa_merch1.png',
              merch_id: 600,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url:
                'https://static.bam-x.com/assets/staging/cover_images/merchants/amazon.jpeg',
              full_name: 'Amazon',
              logo_url:
                'https://static.bam-x.com/assets/staging/logos/merchants/amazon.jpeg',
              merch_id: 1031,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url: null,
              full_name: 'Auto merchant 1443 DONT TOUCH',
              logo_url:
                'https://static.bam-x.com/assets/staging/logos/merchants/merchant100222111.jpeg',
              merch_id: 1443,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url: null,
              full_name: 'Nathan Cobb 4',
              logo_url: null,
              merch_id: 3349,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url: null,
              full_name: 'Nathan Test',
              logo_url: null,
              merch_id: 3345,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
          {
            _source: {
              cover_image_url: null,
              full_name: 'Nathan Test 2',
              logo_url: null,
              merch_id: 3346,
              __typename: 'cFields',
            },
            __typename: 'cHits',
          },
        ],
        __typename: 'pHits',
      },
      __typename: 'merchList',
    },
  },
};

export const mockChannels = {
  data: {
    getChannelTypes: [
      {
        name: 'YouTube',
        __typename: 'channelType',
      },
      {
        name: 'Instagram',
        __typename: 'channelType',
      },
      {
        name: 'Twitch',
        __typename: 'channelType',
      },
      {
        name: 'TikTok',
        __typename: 'channelType',
      },
      {
        name: 'Substack',
        __typename: 'channelType',
      },
      {
        name: 'Other',
        __typename: 'channelType',
      },
    ],
  },
};

export const sortByName = (orgs: any) => {
  const newOrgs = cloneDeep(orgs);

  return newOrgs.sort((a: any, b: any) =>
    a.short_name.toLowerCase() > b.short_name.toLowerCase() ? 1 : -1,
  );
};
