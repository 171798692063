import React, { FC } from 'react';
import { useAppState } from '@frontend/howl-web-app/overmind';
import classNames from 'classnames';

export type DialogContainerDashViewProps = {
  /**React node to add inside the dialog container */
  children: any;

  /**Tailwind width classes */
  width?: string;
};

export const DialogContainerDashView: FC<DialogContainerDashViewProps> =
  React.forwardRef(({ children, width = 'w-full' }, ref: any) => {
    const { sidebarOpen } = useAppState().ui;
    return (
      <div
        ref={ref}
        className={classNames(
          'container-sm transform overflow-hidden rounded-lg bg-white p-4 mx-0 sm:mx-4 text-left align-middle shadow-xl transition-all',
          { 'lg:ml-[272px]': sidebarOpen },
          width,
        )}
      >
        {children}
      </div>
    );
  });

DialogContainerDashView.displayName = 'DialogContainerDashView';
