import * as React from 'react';

export const PlaceholderIndicator = () => {
  return (
    <div
      // className="w-full h-full fixed block top-0 left-0 opacity-75 z-50"
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        display: 'block',
        top: '0px',
        left: '0px',
        opacity: '0.75',
        zIndex: 50,
      }}
    >
      <span
        // className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
        className="block relative w-0 h-0"
        style={{
          color: 'rgba(16, 185, 129)',
          opacity: '0.75',
          top: '50%',
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          position: 'relative',
          width: '60px',
          height: '60px',
        }}
      >
        <svg
          // className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800"
          className="animateSpin"
          style={{
            opacity: '0.25',
            marginLeft: '-1px',
            marginRight: '3px',
            width: '50%',
            color: 'rgba(31, 41, 55)',
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            // className="opacity-25"
            style={{ opacity: '0.25' }}
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            // className="opacity-75"
            style={{ opacity: '0.75' }}
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </span>
    </div>
  );
};
