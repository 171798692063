import { IContext } from 'overmind';
import {
  createStateHook,
  createActionsHook,
  createEffectsHook,
  createReactionHook,
} from 'overmind-react';
import { merge, namespaced } from 'overmind/config';

import * as api from './api';
import * as graphql from './graphql';
import * as auth from './auth';
import * as actions from './actions';
import * as session from './session';
import * as user from './user';
import * as date from './date';
import * as order from './order';
import * as publisher from './publisher';
import * as publisherDashboard from './publisher-dashboard';
import * as contentModule from './content-module';
import * as graph from './graph';
import * as report from './report';
import * as bid from './bid';
import * as search from './search';
import * as creatorWorkspace from './creator-workspace';
import * as creatorHomepage from './creator-homepage';
import * as link from './link';
import * as account from './account';
import * as payment from './payment';
import * as auction from './auction';
import * as collection from './collection';
import * as smartLink from './smart-link';
import * as feed from './feed';
import * as rate from './rate';
import * as recommendations from './recommendations';
import * as error from './error';
import * as product from './product';
import * as productForm from './product-form';
import * as megatable from './megatable';
import * as analytics from './analytics';
import * as creatorCampaignCenter from './creator-campaign-center';
import * as ecommAnalytics from './ecomm-analytics';
import * as ecommCampaignCenter from './ecomm-campaign-center';
import * as ecommHome from './ecomm-home';
import * as ecommCampaignCenterIO from './ecomm-campaign-center-io';
import * as stats from './stats';
import * as ecommStrategyCreation from './ecomm-strategy-creation';
import * as ecommCartExclusions from './ecomm-cart-exclusions';
import * as waitlists from './waitlists';
import * as subscriptions from './subscriptions';
import * as organization from './organization';
import * as listManagement from './list-management';
import * as creatorCartExclusions from './creator-cart-exclusions';
import * as flatFeeVerification from './flat-fee-verification';
import * as storefront from './storefront';
import * as ecommPartnerDiscovery from './ecomm-partner-discovery';
import * as ecommMerchantStats from './ecomm-merchant-stats';
import * as ecommStrategyManager from './ecomm-strategy-manager';
import * as ecommNewsFeed from './ecomm-news-feed';
import * as ecommDesk from './ecomm-desk';
import * as ecommRatesLists from './ecomm-rates-lists';
import * as ecommCampaignPerformance from './ecomm-campaign-performance';
import * as creatorOnboarding from './creator-onboarding';
import * as ecommPaymentSetup from './ecomm-payment-setup';
import * as adminBonusManagement from './admin-bonus-management';

import * as ui from './ui';

export const config = merge(
  { actions },
  namespaced({
    api,
    graphql,
    auth,
    session,
    user,
    date,
    order,
    publisher,
    publisherDashboard,
    contentModule,
    graph,
    report,
    bid,
    search,
    creatorWorkspace,
    creatorHomepage,
    link,
    organization,
    account,
    payment,
    auction,
    collection,
    smartLink,
    feed,
    rate,
    recommendations,
    error,
    product,
    productForm,
    megatable,
    analytics,
    creatorCampaignCenter,
    creatorCartExclusions,
    ecommCampaignCenter,
    ecommHome,
    ecommCampaignCenterIO,
    ui,
    ecommAnalytics,
    stats,
    ecommStrategyCreation,
    ecommCartExclusions,
    waitlists,
    subscriptions,
    listManagement,
    ecommStrategyManager,
    flatFeeVerification,
    storefront,
    ecommPartnerDiscovery,
    ecommMerchantStats,
    ecommNewsFeed,
    ecommDesk,
    ecommCampaignPerformance,
    ecommRatesLists,
    creatorOnboarding,
    ecommPaymentSetup,
    adminBonusManagement,
  }),
);

export type { FlatFee } from './ecomm-strategy-manager';
export type { FlatFeeVerificationDetail } from './flat-fee-verification';

export type {
  StatItem,
  GraphDataByRange,
  PublisherState,
  AnalyticsGraphState,
  AnalyticsTableState,
  EarningsChange,
} from './publisher';
export type {
  ContentSelection,
  ContentTypes,
  ContentMeta,
} from './content-module';
export type { Brand } from './creator-campaign-center';
export type { Campaign } from './creator-campaign-center';
export type { BillingUserResponse } from './account';

export { Roles, RoleNames, RoleDisplayNames } from './account';
export type { PermissionRoles } from './account';

export type { StorefrontLink } from './storefront';

export type { Guild, Tier, CreatorTier } from './ecomm-rates-lists';

export { withOvermind } from './hocs/withOvermind';

export type Context = IContext<typeof config>;

export const useAppState = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
export const useEffects = createEffectsHook<Context>();
export const useReaction = createReactionHook<Context>();
