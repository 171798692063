import { useMediaQuery } from '@frontend/shared-utils';
import React, { ElementType } from 'react';

type Variant =
  | 'display-large'
  | 'display-medium'
  | 'display-small'
  | 'headline-large'
  | 'headline-medium'
  | 'headline-small'
  | 'subtitle'
  | 'body'
  | 'body-500'
  | 'body-700'
  | 'dense'
  | 'dense-500'
  | 'dense-700'
  | 'caption'
  | 'caption-500'
  | 'caption-700'
  | 'label';

interface TypographyProps {
  /* Typography size */
  variant: Variant;

  /* Mobile Only Typography size */
  mobileVariant?: Variant;

  /* HTML content */
  children: React.ReactNode;

  /* Tailwind classes to style element */
  className?: string;

  /* Set HTML tag */
  as?: ElementType;

  /* Cypress selector */
  dataCy?: string;
}

const tags: Record<Variant, ElementType> = {
  'display-large': 'h1',
  'display-medium': 'h2',
  'display-small': 'h3',
  'headline-large': 'h1',
  'headline-medium': 'h2',
  'headline-small': 'h3',
  subtitle: 'p',
  label: 'p',
  body: 'p',
  'body-500': 'p',
  'body-700': 'p',
  caption: 'p',
  'caption-500': 'p',
  'caption-700': 'p',
  dense: 'p',
  'dense-500': 'p',
  'dense-700': 'p',
};

const sizes: Record<Variant, string> = {
  'display-large': 'text-display-large font-bold',
  'display-medium': 'text-display-medium	font-normal',
  'display-small': 'text-display-small	font-normal',
  'headline-large': 'text-headline-large	font-medium',
  'headline-medium': 'text-headline-medium	font-medium',
  'headline-small': 'text-headline-small	font-medium',
  subtitle: 'text-base font-medium',
  body: 'text-base ',
  'body-500': 'text-base font-medium',
  'body-700': 'text-base font-bold',
  dense: 'text-sm font-',
  'dense-500': 'text-sm font-medium',
  'dense-700': 'text-sm font-bold',
  caption: 'text-xs',
  'caption-500': 'text-xs font-medium',
  'caption-700': 'text-xs font-bold',
  label: 'text-xs font-medium',
};

export const Typography = ({
  variant,
  mobileVariant,
  children,
  className = '',
  dataCy,
}: TypographyProps) => {
  let sizeClasses;
  const Tag = tags[variant];
  const isMobile = useMediaQuery('(max-width: 639px)');

  if (mobileVariant && isMobile) {
    sizeClasses = sizes[mobileVariant];
  } else {
    sizeClasses = sizes[variant];
  }
  return (
    <Tag data-cy={dataCy} className={`${sizeClasses} ${className}`}>
      {children}
    </Tag>
  );
};

Typography.displayName = 'Typography';
Typography.filePath = 'libs/shared/ui-kit/src/v2/typography/typography.tsx';
