import { useActions, useAppState } from '@frontend/howl-web-app/overmind';
import { useRouter } from 'next/router';
import { useAnalytics } from '@lightspeed/react-mixpanel-script';
import { useContext, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { OptimizelyContext } from '@optimizely/react-sdk';

type IdentityProviderProps = { children: React.ReactElement };

export function IdentityProvider({ children }: IdentityProviderProps) {
  const router = useRouter();
  const mixpanel = useAnalytics();
  const ldClient = useLDClient();
  const { optimizely } = useContext(OptimizelyContext);
  const { sessionData } = useAppState().session;
  const { selectedOrgId, selectedOrgType } = useAppState().organization;
  const {
    identifyFullStoryUser,
    identityWithChameleon,
    identifyMixpanelUser,
    identifyOptimizelyUser,
    updateIdentityWithLd,
  } = useActions().auth;
  const userId = sessionData?.userId;
  const [firstLogin, setFirstLogin] = useState<boolean>();

  useEffect(() => {
    if (userId && router.isReady && !firstLogin && selectedOrgId !== null) {
      setFirstLogin(true);
      if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
        identifyFullStoryUser();
        identifyMixpanelUser({ mixpanelClient: mixpanel });
      }

      if (optimizely) identifyOptimizelyUser({ optimizely });
    }
  }, [userId, router.isReady, selectedOrgId]);

  useEffect(() => {
    if (selectedOrgId) {
      if (selectedOrgType === 'publishers') {
        identityWithChameleon({
          orgId: selectedOrgId,
        });
      }
    }
  }, [selectedOrgId, selectedOrgType]);

  useEffect(() => {
    if (userId && ldClient && router.isReady) {
      updateIdentityWithLd({ ldClient });
    }
  }, [router.isReady, ldClient, userId, selectedOrgId, selectedOrgType]);

  return children;
}
