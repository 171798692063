export type EndpointParams = {
  strategies: (
    | 'date_from'
    | 'date_to'
    | 'order_by'
    | 'direction'
    | 'page'
    | 'per_page'
    | 'merch_id'
    | 'strategy_id'
    | 'strategy_status'
    | 'search_query'
    | 'search_type'
    | 'use_targeting'
    | 'campaign_label'
    | 'fee_types'
  )[];
  'strategy-products': (
    | 'date_from'
    | 'date_to'
    | 'order_by'
    | 'direction'
    | 'page'
    | 'per_page'
    | 'product_query'
    | 'is_in_stock'
    | 'on_sale'
  )[];
  rates: (
    | 'date_from'
    | 'date_to'
    | 'order_by'
    | 'direction'
    | 'page'
    | 'per_page'
    | 'merch_id'
    | 'pricing_models'
    | 'filter_by'
    | 'search_query'
    | 'search_type'
    | 'use_targeting'
  )[];
  'rates-for-merch': (
    | 'date_from'
    | 'date_to'
    | 'order_by'
    | 'direction'
    | 'page'
    | 'per_page'
    | 'pricing_models'
    | 'filter_by'
  )[];
  evergreen: ('date_from' | 'date_to' | 'merch_id')[];
  'merchant-pub-network': ('merch_id' | 'in_network')[];
};

export type StrategyDataRow = {
  pricing_model: any;
  commission_rate: any;
  evergreen_rate: any;
  strategy_bid: any;
};

type State = {
  retailerOptions: any[];
  strategyOptions: any[];
  endpointParams: EndpointParams;
};

export const state: State = {
  retailerOptions: [
    { label: 'All Partners', value: 'all', adminViewLabel: '' },
    { label: 'Active Partners', value: 'active', adminViewLabel: 'Active' },
    {
      label: 'Inactive Partners',
      value: 'inactive',
      adminViewLabel: 'Inactive',
    },
    { label: 'New Partners', value: 'new,active', adminViewLabel: 'New' },
  ],
  strategyOptions: [
    { label: 'All Strategies', value: 'all' },
    { label: 'Active Strategies', value: 'active' },
    { label: 'Inactive Strategies', value: 'inactive' },
  ],
  endpointParams: {
    strategies: [
      'date_from',
      'date_to',
      'order_by',
      'direction',
      'page',
      'per_page',
      'merch_id',
      'strategy_id',
      'strategy_status',
      'search_query',
      'search_type',
      'campaign_label',
      'fee_types',
      'use_targeting',
    ],
    'strategy-products': [
      'date_from',
      'date_to',
      'order_by',
      'direction',
      'page',
      'per_page',
      'product_query',
      'is_in_stock',
      'on_sale',
    ],
    rates: [
      'date_from',
      'date_to',
      'order_by',
      'direction',
      'page',
      'per_page',
      'merch_id',
      'pricing_models',
      'filter_by',
      'search_query',
      'search_type',
      'use_targeting',
    ],
    'rates-for-merch': [
      'date_from',
      'date_to',
      'order_by',
      'direction',
      'page',
      'per_page',
      'pricing_models',
      'filter_by',
    ],
    evergreen: ['date_from', 'date_to', 'merch_id'],
    'merchant-pub-network': ['merch_id', 'in_network'],
  },
};
