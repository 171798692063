import React from 'react';

import classNames from 'classnames';
import { useAppState } from '@frontend/howl-web-app/overmind';

export const BrickBanner = () => {
  const { selectedOrgId, selectedOrg } = useAppState().organization;

  if (!selectedOrg || selectedOrg.publisher_type !== 2) return null;

  return (
    <div className={'pt-12'}>
      <div
        className={classNames(
          'text-black hover:no-underline flex items-center justify-center gap-2 bg-[#FFF969] text-sm sm:text-base px-4 py-2 border-t',
        )}
      >
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-[#958F00]"
          >
            <path
              d="M10 6.4502V10.4502M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19ZM10.0498 13.4502V13.5502L9.9502 13.5498V13.4502H10.0498Z"
              stroke="#958F00"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="mr-3">
          We're officially in the new Howl preview period. Log in details have
          been sent to your email.{' '}
          <a
            href={
              'https://help.howl.link/hc/en-us/sections/26334243439003-General-Questions'
            }
            className={'text-[#412BC9] font-medium underline'}
          >
            Read the FAQ
          </a>
        </span>
      </div>
    </div>
  );
};
