type YourPartnersState = {
  selectedSegment: any;
  partners: any[];
  partnersLoading: boolean;
  listsLoading: boolean;
  showAddPartners: number[] | null;
  showAddRemovePartner: number | null;
  lists: any[] | null;
  page_info: {
    page: number;
    per_page: number;
    totalItems: number | null;
  };
};

type State = {
  yourPartners: YourPartnersState;
};

export const state: State = {
  yourPartners: {
    selectedSegment: { label: 'Loyal', value: 'loyal' },
    partners: [],
    partnersLoading: false,
    listsLoading: false,
    lists: null,
    showAddPartners: null,
    showAddRemovePartner: null,
    page_info: {
      page: 1,
      per_page: 3,
      totalItems: null,
    },
  },
};
