import { cloneDeep } from 'lodash';
import { Context } from '..';
import { snakeCase } from '@frontend/shared-utils';

const endpoint = '/api/v0/auction_config/distribution/';

export const getPublishers = (context: Context) => {
  return context.effects.api.get('/api/v0/publishers/').then((resp: any) => {
    return resp.data.data;
  });
};

export const getAuctionDistributions = (context: Context) => {
  return context.effects.api.get(endpoint).then((resp: any) => {
    return resp.data.data;
  });
};

export const saveAuctionDistributions = (
  context: Context,
  distributions: any,
) => {
  return context.effects.api.post(endpoint, distributions);
};

export const removePublisherOverride = (context: Context, pubId: any) => {
  return context.effects.api.delete(endpoint, { pub_id: pubId });
};

/* Component functions */
export const getDistributionData = (context: Context, algorithms: any) => {
  // Split global and publisher distributions
  const [globalDistributions, publisherDistributions] = cloneDeep(
    algorithms,
  ).reduce(
    ([globalDist, pubDist]: any, algo: any) => {
      return algo.pub_id === null
        ? [[...globalDist, algo], pubDist]
        : [globalDist, [...pubDist, algo]];
    },
    [[], []],
  );

  // Create a mapping to unique pub_id key {1002: {}, 1003: {}, ...}
  const pubAlgos = publisherDistributions
    .map((algo: any) => algo.pub_id)
    .filter(
      (value: any, index: any, self: any) =>
        value && self.indexOf(value) === index,
    )
    .reduce((result: any, pubId: any) => {
      result[pubId] = {};
      return result;
    }, {});

  publisherDistributions.forEach((algorithm: any) => {
    Object.assign(pubAlgos[algorithm.pub_id], {
      [snakeCase(algorithm.algorithm_name)]: algorithm.distribution_fraction,
    });
  });

  return [globalDistributions, pubAlgos];
};

export const getPublisherDisplayed = (
  context: Context,
  { publishers, pubId }: { publishers: any; pubId: any },
) => {
  const publisher = publishers.find((publisher: any) => {
    return publisher.pub_id === pubId;
  });
  return publisher;
};
