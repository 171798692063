import { Context } from '..';

export const getCategories = (
  context: Context,
  params: { direction: string; order_by: string },
) => {
  return context.effects.api.get(`/api/v0/content_manager/overview/`, params);
};

export const getCategory = (
  context: Context,
  { categoryId, params }: { categoryId: any; params?: any },
) => {
  return context.effects.api.get(
    `/api/v0/content_manager/overview/category/${categoryId}/`,
    params,
  );
};

export const setModuleStatus = (
  context: Context,
  {
    moduleTypeId,
    moduleId,
    status,
  }: { moduleTypeId: any; moduleId: any; status: any },
) => {
  const params = { status };
  return context.effects.api.put(
    `/api/v0/modules/${moduleTypeId}/${moduleId}/status/`,
    params,
  );
};

export const deleteModule = (
  context: Context,
  { moduleTypeId, moduleId }: { moduleTypeId: any; moduleId: any },
) => {
  return context.effects.api.delete(
    `/api/v0/modules/${moduleTypeId}/${moduleId}/`,
  );
};

export const getMockCategories = () => {
  return {
    data: {
      data: [
        {
          data: [
            {
              num_creators: 1,
              num_modules: 6,
              datetime_last_updated: '2021-10-26T18:26:29Z',
              category_id: 9,
              category_name: 'x-Automation Attribute -Touch Me Not',
            },
            {
              num_creators: 2,
              num_modules: 4,
              datetime_last_updated: '2021-10-26T18:18:11Z',
              category_id: 14,
              category_name: 'Reviewed/USA Today',
            },
            {
              num_creators: 5,
              num_modules: 6,
              datetime_last_updated: '2021-10-20T20:33:58Z',
              category_id: 2,
              category_name: 'Electronics',
            },
            {
              num_creators: 2,
              num_modules: 1,
              datetime_last_updated: '2021-10-20T19:44:13Z',
              category_id: 12,
              category_name: 'Focus - Beauty & Skincare - Mature',
            },
            {
              num_creators: 1,
              num_modules: 4,
              datetime_last_updated: '2021-10-18T16:17:39Z',
              category_id: 22,
              category_name: 'Broad-Tech-Deals',
            },
            {
              num_creators: 1,
              num_modules: 1,
              datetime_last_updated: '2021-10-14T18:50:35Z',
              category_id: 5,
              category_name: 'Home',
            },
            {
              num_creators: 2,
              num_modules: 1,
              datetime_last_updated: '2021-10-14T17:22:07Z',
              category_id: 7,
              category_name: 'Gardening',
            },
            {
              num_creators: 0,
              num_modules: 0,
              datetime_last_updated: null,
              category_id: 8,
              category_name: 'sale',
            },
            {
              num_creators: 0,
              num_modules: 0,
              datetime_last_updated: null,
              category_id: 11,
              category_name: 'Focus - Beauty & Skincare - Youthful',
            },
            {
              num_creators: 0,
              num_modules: 0,
              datetime_last_updated: null,
              category_id: 13,
              category_name: 'Gizmodo (The Inventory, G/O Media)',
            },
            {
              num_creators: 1,
              num_modules: 0,
              datetime_last_updated: null,
              category_id: 15,
              category_name: 'Conde Nast properties',
            },
            {
              num_creators: 0,
              num_modules: 0,
              datetime_last_updated: null,
              category_id: 23,
              category_name: 'test new attribute',
            },
          ],
        },
      ],
    },
  };
};

export const getMockCategory = () => {
  return {
    data: {
      data: [
        {
          category: {
            num_creators: 5,
            num_modules: 6,
            datetime_last_updated: '2021-10-20T20:33:58Z',
            category_id: 2,
            category_name: 'Electronics',
          },
          data: [
            {
              module_type_id: 1,
              module_type_name: 'Trending Campaigns',
              available: 0,
              modules: [
                {
                  module_id: 1,
                  category_id: 2,
                  module_type_id: 1,
                  module_name: 'Trending Electronics Campaigns',
                  module_label: null,
                  module_description: null,
                  module_color: null,
                  module_status: 'Published',
                  datetime_last_updated: '2021-10-14T17:14:00Z',
                },
              ],
            },
            {
              module_type_id: 2,
              module_type_name: 'Brand Spotlight',
              available: 1,
              modules: [
                {
                  module_id: 24,
                  category_id: 2,
                  module_type_id: 2,
                  module_name: "Macy's Spotlight",
                  module_label: null,
                  module_description: null,
                  module_color: 1,
                  module_status: 'Published',
                  datetime_last_updated: '2021-10-19T14:57:12Z',
                },
              ],
            },
            {
              module_type_id: 3,
              module_type_name: 'Campaign Spotlight',
              available: 2,
              modules: [
                {
                  module_id: 5,
                  category_id: 2,
                  module_type_id: 3,
                  module_name: 'Campaign 100371 Spotlight',
                  module_label: null,
                  module_description: null,
                  module_color: null,
                  module_status: 'Published',
                  datetime_last_updated: '2021-10-14T17:34:07Z',
                },
              ],
            },
            {
              module_type_id: 4,
              module_type_name: "Editor's Picks: Campaigns",
              available: 2,
              modules: [
                {
                  module_id: 28,
                  category_id: 2,
                  module_type_id: 4,
                  module_name: 'Editors picks: electronics campaigns',
                  module_label: null,
                  module_description: null,
                  module_color: null,
                  module_status: 'Published',
                  datetime_last_updated: '2021-10-20T20:33:58Z',
                },
              ],
            },
            {
              module_type_id: 5,
              module_type_name: 'New on Narrativ',
              available: 0,
              modules: [
                {
                  module_id: 6,
                  category_id: 2,
                  module_type_id: 5,
                  module_name: 'New electronics brands',
                  module_label: null,
                  module_description: null,
                  module_color: null,
                  module_status: 'Published',
                  datetime_last_updated: '2021-10-14T17:36:19Z',
                },
              ],
            },
            {
              module_type_id: 6,
              module_type_name: "Editor's Picks: Brands",
              available: 1,
              modules: [
                {
                  module_id: 7,
                  category_id: 2,
                  module_type_id: 6,
                  module_name: 'Editors picks: electronics brands',
                  module_label: null,
                  module_description: null,
                  module_color: null,
                  module_status: 'Published',
                  datetime_last_updated: '2021-10-14T17:37:10Z',
                },
              ],
            },
          ],
        },
      ],
    },
  };
};
