import { derived } from 'overmind';

type State = {
  dateTime: any;
  defaultEndDate: any;
  defaultStartDate: any;
  currentMonth: any;
};

export const state: State = {
  // Current time and default values for the daterange picker.
  dateTime: derived(() => new Date()),
  defaultEndDate: null,
  defaultStartDate: null,
  currentMonth: null,
};
