import { rehydrate } from 'overmind';
import { Context } from '..';
import { Session } from './state';
import { removeCookie, setCookie } from '@frontend/shared-utils';

const currentUserStorageSlug = 'currentUser';

export const setSession = (context: Context, theValue: Session | null) => {
  // nested object in tree, rework this
  // state.session.sessionData.accessList = theValue?.accessList?.slice(); // TODO: spread operator getting tslib error [...accessList];
  // delete theValue?.accessList;

  // this nonsense only required because we're using the root namespace state as a class
  // const keys = Object.keys(state.session.sessionData);
  // const defaults = {};
  // keys.forEach(item => {
  //   defaults[item] = null;
  // });

  // const theUpdate = { ...defaults, ...theValue };
  // Giving sessionData a skeleton value prevents a lot of value errors in the dashboard for things that use it
  if (theValue === null) {
    theValue = {
      authVersion: null,
      bamxVersion: null,
      orgId: null,
      isAdmin: false,
      isOrgAdmin: false,
      orgName: null,
      termsAccepted: false,
      userId: null,
      userName: null,
      userRole: null,
      accessList: [],
      email: null,
      firstName: null,
      lastName: null,
      accessToken: null,
      refreshToken: null,
      exp: null,
      publisher_type: null,
      instant_pay_access: null,
      billing_platform: null,
    };
  }

  context.state.session.sessionData = { ...theValue };

  // console.log('setSession theUpdate: ', theUpdate);
  // console.log('setSession state.session: ', state.session);
  // console.log('setSession theValue: ', theValue);
  // console.log('setSession defaults: ', defaults);
  // console.log('setSession state.session.sessionData', state.session.sessionData);
  // const keys = Object.keys(theValue);

  // console.log('state.session: ', state.session);
  // console.log('state.session keys: ', keys);
  // keys.forEach(field => {
  //   state.session[field] = theValue[field];
  // });

  context.actions.session.saveToStorage();
};

export const logout = async (context: Context) => {
  await context.effects.api.logout();
  context.actions.session.destroy();
  window.location.href = '/signin';
};

export const saveToStorage = (context: Context) => {
  // FIX: Root cause of session bug is probably happening here
  if (typeof window === 'undefined') return;

  const currentUser = context.state.session.sessionData
    ? {
        fields: Object.keys(context.state.session.sessionData),
        accessList: context.state.auth.accessList,
        ...context.state.session.sessionData,
      }
    : null;

  //  Remove unused object to prevent exceeding localStorage memory limit
  delete currentUser?.allAdminOrgs;

  try {
    window.localStorage.setItem(
      currentUserStorageSlug,
      JSON.stringify(currentUser),
    );
  } catch (e) {
    console.log('e: ', e);
    // Remove launch darkly cashed flags to prevent exceeding localStorage memory limit
    Object.entries(localStorage)
      .map((x) => x[0])
      .filter((x) => x.substring(0, 3) == 'ld:')
      .map((x) => localStorage.removeItem(x));
    try {
      window.localStorage.setItem(
        currentUserStorageSlug,
        JSON.stringify(currentUser),
      );
    } catch (e) {
      console.log('e: ', e);
    }
  }

  setCookie({
    name: currentUserStorageSlug,
    value: currentUser
      ? JSON.stringify({
          userId: currentUser.userId,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          userRole: currentUser.userRole,
        })
      : null,
  });
};

export const removeFromStorage = () => {
  if (typeof window === 'undefined') return;

  delete window.localStorage[currentUserStorageSlug];

  removeCookie({ name: currentUserStorageSlug });
};

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const isInvalidSession = (session: Session) => {
  // session.hasOwnProperty() throws lint error

  // orgId null check handles when orgId is 0
  if (
    !!Object.getOwnPropertyDescriptor(session, 'orgId') &&
    session.orgId === null
  ) {
    return true;
  }

  if (
    !!Object.getOwnPropertyDescriptor(session, 'termsAccepted') &&
    session.termsAccepted === null
  ) {
    return true;
  }

  if (
    !!Object.getOwnPropertyDescriptor(session, 'userRole') &&
    !session.userRole
  ) {
    return true;
  }

  if (
    !!Object.getOwnPropertyDescriptor(session, 'accessToken') &&
    !session.accessToken
  ) {
    return true;
  }

  return false;
};

export const setCurrentUserFromStorage = (context: Context) => {
  const { currentUser: session } = window.localStorage;

  if (!session) return;

  // TODO: continue here, type out session properties into new model
  const sessionData = JSON.parse(session);

  if (isInvalidSession(sessionData)) {
    // TODO: remove this bandaid logic after root cause of session bug is discovered
    context.actions.session.removeFromStorage();
    return;
  }

  context.actions.session.update(sessionData);

  const accessToken = sessionData.accessToken;
  const tokenData = parseJwt(accessToken);

  if (accessToken && tokenData.bamx_perms) {
    context.actions.auth.setAuthData({
      accessToken: accessToken,
      bamxPerms: tokenData.bamx_perms,
    });
  }
};

export const setIsAdmin = (context: Context, theValue: boolean) => {
  context.state.session = {
    sessionData: {
      ...context.state.session.sessionData,
      isAdmin: theValue,
    },
  };
};

export const updateSessionTokens = (
  context: Context,
  { accessToken, refreshToken }: { accessToken: string; refreshToken: string },
): any => {
  // TODO: these shouldn't live in session, but are for now during legacy migration
  try {
    if (
      accessToken &&
      refreshToken &&
      context.state.session.sessionData &&
      (context.state.session.sessionData.accessToken !== accessToken ||
        context.state.session.sessionData.refreshToken !== refreshToken)
    ) {
      context.state.session.sessionData = {
        ...context.state.session.sessionData,
        accessToken: accessToken,
        refreshToken: refreshToken,
      };

      context.actions.session.saveToStorage();
    }
  } catch (e) {
    console.log('e: ', e);
    return Promise.reject(new Error('Failed to update session tokens'));
  }
};

// ******************************
// TODO: older functions to remove eventually
export const create = (context: Context, theValue: Session) => {
  context.actions.session.setSession(theValue);
};

export const destroy = (context: Context) => {
  context.actions.session.setSession(null);
  context.actions.session.removeFromStorage();
};

export const update = (context: Context, theValue: Session) => {
  const session = { ...context.state.session.sessionData, ...theValue };
  context.actions.session.setSession(session);
};
// ******************************

export const getAccessListIds = (
  context: Context,
  allowedPublisherRoles?: any[],
) => {
  const { accessList } = context.state.auth;
  const { sessionData } = context.state.session;

  const pubType = sessionData.allPubs ? 'publishers' : 'merchants';

  const orgType = sessionData.isAdmin ? pubType : sessionData.userRole;
  const list = sessionData.isAdmin ? sessionData.allAdminOrgs : accessList;

  return list.reduce((agg: any, org: any) => {
    if (org.orgId !== sessionData.orgId && org.type === orgType) {
      if (
        orgType === 'publishers' &&
        allowedPublisherRoles &&
        !allowedPublisherRoles.includes(org.role)
      )
        // role isn't allowed.
        return agg;
      agg += `${!agg ? '' : ','}${org.orgId}`;
    }
    return agg;
  }, '');
};
