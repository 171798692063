import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Player from '@vimeo/player';
import useInView from 'react-cool-inview';
import cx from 'classnames';
import Image from 'next/legacy/image';

export const VimeoVideo = forwardRef(function VimeoVideo(
  {
    data,
    className,
    iframeClassName,
    autoPlay = true,
    loop = false,
    videoPlayCallBackFunc,
    videoPauseCallBackFunc,
    videoEndCallBackFunc,
    videoTimeupdateCallBackFunc,
    ...rest
  }: any,
  ref,
) {
  const videoRef = useRef();
  const [iframePlayer, setIframePlayer] = useState<any>(null);
  const { observe, inView } = useInView();
  const videoTitle = data.title ? data.title : '';

  useEffect(() => {
    if (videoRef.current && iframePlayer === null) {
      setIframePlayer(new Player(videoRef.current));
    }
  }, [iframePlayer]);

  useImperativeHandle(ref, () => ({
    playVideo: () => {
      iframePlayer.play();
    },
    pauseVideo: () => {
      iframePlayer.pause();
    },
  }));

  useEffect(() => {
    if (iframePlayer) {
      iframePlayer.on('play', function (e: any) {
        videoPlayCallBackFunc(e);
      });
      iframePlayer.on('pause', function (e: any) {
        videoPauseCallBackFunc(e);
      });
    }
  }, [iframePlayer, videoPlayCallBackFunc, videoPauseCallBackFunc]);

  useEffect(() => {
    if (iframePlayer) {
      iframePlayer.on('ended', function (e: any) {
        videoEndCallBackFunc(e);
      });
    }
  }, [iframePlayer, videoEndCallBackFunc]);

  useEffect(() => {
    if (iframePlayer) {
      iframePlayer.on('timeupdate', function (e: any) {
        videoTimeupdateCallBackFunc(e);
      });
    }
  }, [iframePlayer, videoTimeupdateCallBackFunc]);

  useEffect(() => {
    if (iframePlayer) {
      if (inView && autoPlay === true) {
        iframePlayer.play();
      } else {
        iframePlayer.pause();
      }
    }
  }, [iframePlayer, inView, autoPlay]);

  return (
    <>
      <div className={cx('vimeo-video', className)} {...rest}>
        {data.thumbnail && (
          <div className="thumbnail">
            <Image
              src={data.thumbnail}
              alt={videoTitle}
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
        )}
        {data.id && (
          <iframe
            className={iframeClassName}
            ref={(node) => {
              observe(node);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              videoRef.current = node;
            }}
            title={videoTitle}
            src={`https://player.vimeo.com/video/${data.id}?${
              autoPlay ? 'background=1&autopause=0' : 'controls=1&muted=0'
            }${loop ? '&loop=1' : '&loop=0'}`}
            frameBorder="0"
            allow="autoplay; fullscreen;"
            allowFullScreen
          />
        )}
      </div>
      {/*<style jsx>{`
        .vimeo-video {
          overflow: hidden;
          pointer-events: none;
          user-select: none;

          .thumbnail {
            height: 100vh;
            width: 100vw;
            overflow: hidden;
            z-index: -1;
          }
          iframe {
            opacity: 0;
            height: ${height};
            width: ${width};
            pointer-events: none;
            transition: 0.6s;
          }
          &.is-ready {
            iframe {
              opacity: 1;
            }
          }

          @media screen and (min-aspect-ratio: 16 / 9) {
            iframe {
              width: 101vw;
              height: 56.25vw;
            }
          }
        }
      `}</style>*/}
    </>
  );
});
