import { Context } from '..';
import { SnackbarProps } from '@frontend/shared-ui-kit/snackbar';

export const setSidebarOpen = (context: Context, theValue: boolean) => {
  context.state.ui.sidebarOpen = theValue;
};

export const setCreatorSidebarOpen = (context: Context, theValue: boolean) => {
  context.state.ui.creatorSidebarOpen = theValue;
};

export const setRecoverPasswordErrorMessage = (
  context: Context,
  theValue: string,
) => {
  context.state.ui.recoverPasswordErrorMessage = theValue;
};

export const triggerSnackbar = (
  context: Context,
  props: SnackbarProps | null,
) => {
  context.state.ui.snackbar = props ? { ...props } : null;
  context.state.ui.snackbarIter = context.state.ui.snackbarIter + 1;
};

export const clearSnackbar = (context: Context) => {
  context.state.ui.snackbar = null;
};

export const toggleHelpWidget = (context: Context, val: boolean) => {
  context.state.ui.helpWidgetVisible = val;
};
