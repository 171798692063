import { SnackbarProps } from '@frontend/shared-ui-kit/snackbar';

export const nextJsPageUris = ['/admin'];

type State = {
  creatorSidebarOpen: boolean;
  sidebarOpen: boolean;
  recoverPasswordErrorMessage: string;
  snackbar: SnackbarProps | null;
  snackbarIter: number;
  helpWidgetVisible: boolean;
};

export const state: State = {
  creatorSidebarOpen: false,
  sidebarOpen: false,
  recoverPasswordErrorMessage: '',
  snackbar: null,
  snackbarIter: 0,
  helpWidgetVisible: true,
};
