type Campaign = {
  merch_id: string;
  strategy_id: string;
  strategy_name: string;
  strategy_thumbnail_image_url: string;
  attributed_revenue: string;
  strategy_datetime_start: string;
  strategy_datetime_end: string;
  recommended_links: string;
  merch_logo_url: string;
  clicks: number;
  order_conversion_rate: string;
};

type Partner = {
  pub_id: number;
  pub_logo_url: string;
  pub_name?: string;
  pub_url?: string;
  pub_category_names?: string;
  attributed_revenue: string;
  effective_commission_rate?: string;
  order_conversion_rate?: string;
  clicks?: number;
};

export type Product = {
  merchant_product_id: number | null;
  brand: string | null;
  brand_product_name: string | null;
  unit_price: string;
  attributed_revenue: string;
  attributed_product_count: number;
  product_image_url: string | null;
};

type EcommNewsFeed = {
  topCampaigns: Campaign[] | null;
  topPartners: Partner[] | null;
  topProducts: Product[] | null;
  creatorInterests: Partner[] | null;
};

export const state: EcommNewsFeed = {
  topCampaigns: null,
  topPartners: null,
  topProducts: null,
  creatorInterests: null,
};
