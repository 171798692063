import React from 'react';

type Props = {
  children: React.ReactNode;
  cypressSelector?: string;
  padding?: string;
  background?: string;
  border?: string;
  rounded?: string;
  margin?: string;
  height?: string;
  width?: string;
  position?: string;
  shadow?: string;
  className?: string;
};

const Card = ({
  children,
  cypressSelector = 'card',
  padding = 'p-6',
  background = 'bg-white',
  border = 'border-none',
  rounded = 'rounded-lg',
  margin = 'm-0',
  height = 'auto',
  width = '',
  position = '',
  shadow = '',
  className = '',
}: Props) => (
  <div
    data-cy={cypressSelector}
    className={`${
      border ? 'border ' + border : ''
    } ${padding} ${rounded} ${background} ${margin} ${height} ${width} ${position} ${shadow} ${className}`}
    style={{ height }}
  >
    {children}
  </div>
);

export default Card;
