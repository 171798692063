export interface SephoraMock {
  strategy_id: number;
  collections: Collection[];
  overview: Overview;
  details: Details;
  sephora_data: {
    merch_id: number;
    evergreen_strategy: number;
  };
}

interface Details {
  key_talking_points: string[];
  additional: string;
}

interface Overview {
  key_objective: string;
  commission: string;
  attribution: string;
  dates: string;
  creators_participating: Creatorsparticipating;
}

interface Creatorsparticipating {
  total: number;
  creator_images: Creatorimage[];
}

interface Creatorimage {
  url: string;
}

interface Collection {
  id: number;
  name: string;
  description: string;
  products: number[];
}

export enum AppliedStatus {
  Eligible = 'eligible',
  Applied = 'applied',
  Invited = 'invited',
  Confirmed = 'confirmed',
  NotSelected = 'not_selected',
}

export enum CampaignCTA {
  AppliedButton = 'applied-button',
  ReservedButton = 'reserved-button',
  ContactLink = 'contact-link',
}

export type Brand = {
  merch_name: string;
  pub_id: number;
  description: string;
  logo_url: string;
  merch_id: number;
  cover_image_url: string;
  attribution_window: string;
  attribution_window_subtext: string | null;
  evergreen_rate: string;
  merchant_url: string;
  evergreen_pricing_model: string;
  evergreen_rate_raw: string;
  epp_rate?: string;
  epc_rate?: string;
  posting_description?: string;
};

export type Campaign = {
  strategy_id: number;
  strategy_name: string;
  strategy_start_date: string;
  strategy_end_date: string | null;
  is_evergreen: boolean;
  strategy_rate: string;
  strategy_thumbnail: string;
  flat_fee?: string | null;
  merch_id: number;
  merchant_logo_url: string;
  pub_id?: number;
  strategy_rate_raw?: string;
  strategy_rate_pricing_model: string;
  reserved_status?: string;
  attribution_window: string;
  attribution_window_subtext?: string | null;
  strategy_description?: string;
  merch_name?: string;
  strategy_rate_range?: string;
  tagline?: string;
  flat_fee_total_range_min?: string;
  flat_fee_total_range_max?: string;
  flat_fee_future_range_count?: number;
  strategy_type?: number;
  has_gifting?: boolean;
  campaign_brief_url?: string;
  content_submission_url?: string;
  datetime_published?: string;
  applied_status?: string;
  apply_by_date?: string | null;
  eligibility_status: string;
  budget_amount?: string;
  budget_spent?: string;
  percent_budget_used?: string;
  pricing_model?: string;
  deduct_returns?: boolean;
};

export type FeaturedCampaign = {
  strategy_id: number;
  strategy_name: string;
  strategy_start_date: string;
  strategy_end_date: string;
  is_evergreen: boolean;
  strategy_rate: string;
  strategy_thumbnail: string;
  strategy_description?: string;
  merch_id: number;
  flat_fee?: string;
  merch_name: string;
  merchant_logo_url: string;
  pub_id: number;
  reserved_status: string;
  tagline?: string;
  flat_fee_total_range_min?: string;
  flat_fee_total_range_max?: string;
  flat_fee_future_range_count?: number;
  strategy_type?: number;
  has_gifting?: boolean;
};

export type GiftInfo = {
  description: string;
  quantity: number;
  signup_url: string;
  has_gift: boolean;
  total_products: number;
  opted_in: boolean;
};

export type FlatFeeDetails = {
  version: number;
  gmv_goal: number;
  content_submission_url: string;
  campaign_brief_url: string;
  flat_fees: FlatFee[];
  has_flat_fee_details: boolean;
};

export type FlatFee = {
  flat_fee_id: number;
  month: string;
  post_count: number;
  payout: number;
  status: 'VERIFIED' | 'REJECTED' | 'UNVERIFIED' | 'OPTED_IN';
};

export type Product = {
  store_page_url: string;
  image_url: string | null;
  product_name: string;
  product_brand: string;
  product_description?: string | null;
  product_price?: string;
  sale_price?: string;
  merch_id: number;
  gift_available?: boolean;
  num_variants?: number;
};

export type Reserved = {
  strategy_id: number;
  strategy_name: string;
  strategy_start_date: string;
  strategy_end_date: string;
  strategy_thumbnail: string;
  merch_id: number;
  reserved_status: string;
};

export type Brands = Brand[];
export type Campaigns = Campaign[];
export type Products = Product[];
export type ReservedCampaigns = Reserved[];
export type GmvDetails = {
  attributed_revenue: string;
};
export type ParticipatingCreator = {
  pub_id: number;
  logo_url: string;
  full_name: string;
};

type creatorCampaignCenter = {
  products: Products;
  gifts: Products | null;
  myFlatFees: Campaigns;
  myFlatFeesTotalItems: number;
  selectedCampaign: Campaign | null;
  participatingCreators: ParticipatingCreator[] | null;
  totalParticipatingCreators: number | null;
  creatorLogos: string[] | null;
  totalParticipatingCreatorsBrandTotal: number | null;
  featuredCreators: string[] | null;
  minParticipatingCreators: number;
  productsTotalItems: number;
  giftInfo: GiftInfo | null;
  modal: {
    type: 'reserved' | 'notInterested' | null;
    isVisible: boolean;
    campaignName?: string;
    strategyId?: number;
    featuredCampaign?: boolean;
  };
  reservedCampaigns: ReservedCampaigns;
  isLoading: boolean;
  myFlatFeesIsLoading: boolean;
  feedbackStatus: feedbackStatus | null;
  campaignProductsLoading: boolean;
  flatFeeDetails: FlatFeeDetails | null;
  gmvDetails: GmvDetails | null;
  error: string | null;
};

export type feedbackStatus = {
  strategy_feedback_status?: string;
  liked_statistic: number;
  disliked_statistic: number;
};

export const state: creatorCampaignCenter = {
  productsTotalItems: 0,
  products: [],
  myFlatFees: [],
  myFlatFeesTotalItems: 0,
  selectedCampaign: null,
  participatingCreators: null,
  totalParticipatingCreators: null,
  creatorLogos: null,
  totalParticipatingCreatorsBrandTotal: null,
  featuredCreators: [],
  minParticipatingCreators: 10,
  modal: {
    type: null,
    isVisible: false,
  },
  reservedCampaigns: [],
  isLoading: true,
  myFlatFeesIsLoading: false,
  campaignProductsLoading: false,
  feedbackStatus: null,
  giftInfo: null,
  flatFeeDetails: null,
  gifts: null,
  gmvDetails: null,
  error: null,
};

// mockData
export const brandMocks: Brands = [
  {
    merch_name: 'Lake & Syye',
    pub_id: 1,
    description: 'Cruelty free and vegan hydrocolloid pimple patches',
    logo_url: 'https://static.bam-x.com/assets/logos/merchants/saks.png',
    evergreen_rate: '75% Commission',
    merch_id: 1,
    cover_image_url: '/images/demo-assets/test_bottles.png',
    merchant_url: 'http://www.saksfifthavenue.com/',
    attribution_window: 'Post-click 4',
    attribution_window_subtext: '(includes mobile app orders)',
    evergreen_pricing_model: 'EPP',
    evergreen_rate_raw: '75.00',
    epp_rate: '10% - 15%',
    epc_rate: '$0.05 per click',
  },
  {
    merch_name: 'Lake & Syye',
    pub_id: 1,
    description: 'Cruelty free and vegan hydrocolloid pimple patches',
    logo_url: 'https://static.bam-x.com/assets/logos/merchants/saks.png',
    evergreen_rate: '75% Commission',
    merch_id: 2,
    cover_image_url: '/images/demo-assets/test_bottles.png',
    merchant_url: 'http://www.saksfifthavenue.com/',
    attribution_window: 'Post-click 4',
    attribution_window_subtext: null,
    evergreen_pricing_model: 'EPP',
    evergreen_rate_raw: '75.00',
    epp_rate: '10%',
    epc_rate: '$0.04 - $0.07 per click',
  },
  {
    merch_name: 'Lake & Syye',
    pub_id: 1,
    description: 'Cruelty free and vegan hydrocolloid pimple patches',
    logo_url: 'https://static.bam-x.com/assets/logos/merchants/saks.png',
    evergreen_rate: '75% Commission',
    merch_id: 3,
    cover_image_url: '/images/demo-assets/test_bottles.png',
    merchant_url: 'http://www.saksfifthavenue.com/',
    attribution_window: 'Post-click 4',
    attribution_window_subtext: null,
    evergreen_pricing_model: 'EPP',
    evergreen_rate_raw: '75.00',
    epp_rate: '10%',
    epc_rate: '',
  },
  {
    merch_name: 'Lake & Syye',
    pub_id: 1,
    description: 'Cruelty free and vegan hydrocolloid pimple patches',
    logo_url: 'https://static.bam-x.com/assets/logos/merchants/saks.png',
    evergreen_rate: '75% Commission',
    merch_id: 4,
    cover_image_url: '/images/demo-assets/test_bottles.png',
    merchant_url: 'http://www.saksfifthavenue.com/',
    attribution_window: 'Post-click 4',
    attribution_window_subtext: null,
    evergreen_pricing_model: 'EPP',
    evergreen_rate_raw: '75.00',
    epp_rate: '',
    epc_rate: '$0.04 per click',
  },
];

export const productMocks: Products = [
  {
    store_page_url: 'http://www.violetgrey.com/',
    image_url: 'https://static.bam-x.com/assets/logos/merchants/bestbuy.png',
    product_name:
      'Samsung - Galaxy Tab S7 Plus - 12.4” - 256GB - With S Pen - Wi-Fi - Mystic Black',
    product_brand: 'Violet Grey',
    product_description: 'organic, non-GMO string cheese',
    product_price: '50.00',
    sale_price: '50.00',
    merch_id: 1,
    gift_available: true,
    num_variants: 2,
  },
  {
    store_page_url: 'http://www.violetgrey.com/',
    image_url: 'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
    product_name: 'Silly string',
    product_brand: 'Violet Grey',
    product_description:
      'organic, non-GMO string cheeseorganic, non-GMO string cheeseorganic, non-GMO string cheeseorganic, non-GMO string cheese',
    product_price: '50.00',
    sale_price: '50.00',
    merch_id: 1,
    num_variants: 3,
  },
  {
    store_page_url: 'http://www.violetgrey.com/',
    image_url: 'https://static.bam-x.com/assets/logos/merchants/saks.png',
    product_name: 'Silly string',
    product_brand: 'Violet Grey',
    product_description: 'organic, non-GMO string cheese',
    product_price: '50.00',
    sale_price: '50.00',
    merch_id: 1,
    num_variants: 1,
  },
  {
    store_page_url: 'http://www.violetgrey.com/',
    image_url: 'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
    product_name: 'Silly string',
    product_brand: 'Violet Grey',
    product_description: 'organic, non-GMO string cheese',
    product_price: '50.00',
    sale_price: '50.00',
    merch_id: 1,
    num_variants: 1,
  },
];

export const campaignMocks: Campaigns = [
  {
    strategy_id: 3000,
    strategy_name:
      'LG Home Audio at Best BuyLG Home Audio at Best BuyLG Home Audio at Best BuyLG Home Audio at Best Buy',
    strategy_start_date: '2022-01-20T00:00:00Z',
    strategy_end_date: '2022-02-26T23:59:59Z',
    is_evergreen: true,
    strategy_rate: '30% Commission',
    strategy_thumbnail: '/images/demo-assets/rare_beauty.png',
    merch_id: 1125,
    merchant_logo_url:
      'https://static.bam-x.com/assets/logos/merchants/bestbuy.png',
    pub_id: 1254,
    strategy_rate_raw: '30.00',
    strategy_rate_pricing_model: 'EPP',
    strategy_description:
      'Treat your lips to all-day hydration & a universally flattering light pink tint with this limited edition version of our instant-classic Lip SOS balm, created in collaboration with actress, musician & entrepreneur Christina Milian. This coconut (Christina’s lifelong moisturizing essential) & peptide-powered lip treatment smooths your pout, leaving just the right amount of gloss & buildable color. And because she’s a positively real multihyphenate, Christina made sure this lip hero can transition seamlessly from a no-makeup workday to a glammed-up evening.',
    merch_name: 'New Merch',
    reserved_status: 'always on',
    attribution_window: 'Post-click 30',
    attribution_window_subtext: '(includes mobile app orders)',
    flat_fee: '1500.00',
    flat_fee_total_range_max: '1500.00',
    flat_fee_total_range_min: '500.00',
    flat_fee_future_range_count: 2,
    tagline:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis.',
    strategy_type: 2,
    has_gifting: true,
    campaign_brief_url: 'https://www.who.int/publications/who-guidelines',
    content_submission_url: 'https://www.dictionary.com/browse/submission',
    datetime_published: '2022-01-20T00:00:00Z',
    applied_status: 'invited',
    apply_by_date: '2022-03-02T23:59:59Z',
    eligibility_status: '',
  },
  {
    strategy_id: 3037,
    strategy_name:
      'Bissell Small Appliances Sale 2022 at Best Buy - Elevated Rates',
    strategy_start_date: '2022-02-14T00:00:00Z',
    strategy_end_date: '2022-03-06T23:59:59Z',
    is_evergreen: false,
    strategy_rate: '22% Commission',
    strategy_thumbnail: '/images/demo-assets/rare_beauty.png',
    merch_id: 1125,
    merchant_logo_url:
      'https://static.bam-x.com/assets/logos/merchants/bestbuy.png',
    pub_id: 1254,
    strategy_rate_raw: '22.00',
    strategy_rate_pricing_model: 'EPP',
    reserved_status: 'unreserved',
    attribution_window: 'Post-click 30',
    attribution_window_subtext: '(includes mobile app orders)',
    flat_fee: '1500.00',
    flat_fee_total_range_max: '1500.00',
    flat_fee_total_range_min: '1500.00',
    flat_fee_future_range_count: 2,
    strategy_type: 3,
    applied_status: 'applied',
    apply_by_date: '2022-03-02T23:59:59Z',
    eligibility_status: '',
  },
  {
    strategy_id: 3099,
    strategy_name: 'Black-Owned Brands at Best Buy',
    strategy_start_date: '2022-02-01T00:00:00Z',
    strategy_end_date: null,
    is_evergreen: false,
    strategy_rate: '17% Commission',
    strategy_thumbnail: '/images/demo-assets/rare_beauty.png',
    merch_id: 1125,
    merchant_logo_url:
      'https://static.bam-x.com/assets/logos/merchants/bestbuy.png',
    pub_id: 1254,
    strategy_rate_raw: '17.00',
    strategy_rate_pricing_model: 'EPP',
    reserved_status: 'reserved',
    attribution_window: 'Post-click 30',
    attribution_window_subtext: '(includes mobile app orders)',
    flat_fee: '1500.00',
    flat_fee_total_range_max: '1500.00',
    flat_fee_total_range_min: '500.00',
    flat_fee_future_range_count: 2,
    applied_status: 'eligible',
    apply_by_date: '2022-03-02T23:59:59Z',
    eligibility_status: '',
  },
  {
    strategy_id: 3036,
    strategy_name:
      "Samsung Major Appliances President's Day Sale at Best Buy - Elevated Rates",
    strategy_start_date: '2022-02-10T00:00:00Z',
    strategy_end_date: '2022-03-02T23:59:59Z',
    is_evergreen: false,
    strategy_rate: '17% Commission',
    strategy_thumbnail: '/images/demo-assets/rare_beauty.png',
    merch_id: 1125,
    merchant_logo_url:
      'https://static.bam-x.com/assets/logos/merchants/bestbuy.png',
    pub_id: 1254,
    strategy_rate_raw: '17.00',
    strategy_rate_pricing_model: 'EPP',
    reserved_status: 'unreserved',
    attribution_window: 'Post-click 30',
    attribution_window_subtext: '(includes mobile app orders)',
    flat_fee: '1500.00',
    flat_fee_total_range_max: '1500.00',
    flat_fee_total_range_min: '500.00',
    flat_fee_future_range_count: 2,
    applied_status: 'confirmed',
    apply_by_date: '2022-03-02T23:59:59Z',
    eligibility_status: '',
  },
];

export const featuredCampaignMock: FeaturedCampaign = {
  strategy_id: 3000,
  strategy_name: 'LG Home Audio at Best Buy',
  strategy_start_date: '2022-01-20T00:00:00Z',
  strategy_end_date: '2022-02-26T23:59:59Z',
  is_evergreen: false,
  strategy_rate: '30% Commission',
  strategy_thumbnail: '/images/demo-assets/rare_beauty.png',
  merch_id: 1125,
  merchant_logo_url:
    'https://static.bam-x.com/assets/logos/merchants/bestbuy.png',
  pub_id: 1254,
  merch_name: 'New Merch',
  reserved_status: 'unreserved',
  flat_fee: '1500.00',
  flat_fee_total_range_min: '200.00',
  flat_fee_total_range_max: '1500.00',
  flat_fee_future_range_count: 2,
  tagline:
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis.',
  strategy_type: 2,
  has_gifting: true,
};

export const reservedMocks: ReservedCampaigns = [
  {
    strategy_id: 1000,
    strategy_name: 'Strategy 1000',
    strategy_start_date: '2022-01-08',
    strategy_end_date: '2022-01-15',
    strategy_thumbnail:
      'https://static.bam-x.com/assets/test/merchants/thumbnail/image1.png',
    merch_id: 1000,
    reserved_status: 'reserved',
  },
  {
    strategy_id: 1000,
    strategy_name: 'Strategy 1000',
    strategy_start_date: '2022-01-09',
    strategy_end_date: '2022-01-16',
    strategy_thumbnail:
      'https://static.bam-x.com/assets/test/merchants/thumbnail/image1.png',
    merch_id: 1000,
    reserved_status: 'reserved',
  },
  {
    strategy_id: 1000,
    strategy_name: 'Strategy 1000',
    strategy_start_date: '2022-02-08',
    strategy_end_date: '2022-02-15',
    strategy_thumbnail:
      'https://static.bam-x.com/assets/test/merchants/thumbnail/image1.png',
    merch_id: 1000,
    reserved_status: 'reserved',
  },
  {
    strategy_id: 1000,
    strategy_name: 'Strategy 1000',
    strategy_start_date: '2022-02-20',
    strategy_end_date: '2022-02-28',
    strategy_thumbnail:
      'https://static.bam-x.com/assets/test/merchants/thumbnail/image1.png',
    merch_id: 1000,
    reserved_status: 'reserved',
  },
];
export const feedbackStatusMocks: feedbackStatus = {
  strategy_feedback_status: 'like',
  liked_statistic: 67,
  disliked_statistic: 33,
};
