export type ReportState = {
  params: {
    admin_stats: boolean;
    is_report: boolean;
    date_from?: string;
    date_to?: string;
    page?: number | null;
    per_page?: number | null;
    gifting_available?: boolean;
    cpc?: boolean;
    on_sale?: boolean;
    product_launch?: boolean;
    flat_fee?: boolean;
    merch_ids?: string;
    search_query?: string;
    additional_orgs?: string | null;
    direction?: string;
    order_by?: string;
    report_params: {
      slug: string;
      report_type: string;
      columns: string[];
      endpoint: string;
    };
  };
  rowCount?: number;
  isPolling: boolean;
  error: {
    showError: boolean;
    errorMessage: string;
  };
};

export const state: ReportState = {
  params: {
    admin_stats: false,
    is_report: true,
    date_from: '',
    date_to: '',
    page: null,
    per_page: null,
    gifting_available: false,
    cpc: false,
    on_sale: false,
    product_launch: false,
    flat_fee: false,
    additional_orgs: null,
    direction: '',
    order_by: '',
    report_params: {
      slug: '',
      report_type: '',
      columns: [],
      endpoint: '',
    },
  },
  rowCount: undefined,
  isPolling: false,
  error: {
    showError: false,
    errorMessage: '',
  },
};
