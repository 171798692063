import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@frontend/shared-utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-lg p-2 text-xs font-semibold transition-colors whitespace-nowrap',
  {
    variants: {
      variant: {
        default: 'bg-neutral-opacity-50 text-base-dark',
        brand: 'bg-interaction-selected text-interaction-primary',
        success: 'bg-system-success-accent text-system-success',
        critical: 'bg-system-critical-accent text-system-critical',
        warning: 'bg-system-warning-accent text-system-warning',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const indicatorVariants = cva('w-2 h-2 rounded-full mr-2', {
  variants: {
    variant: {
      default: 'bg-base-dark',
      brand: 'bg-interaction-primary',
      success: 'bg-system-success',
      critical: 'bg-system-critical',
      warning: 'bg-system-warning',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  leadingProp?: 'indicator' | null;
}

function Badge({
  className,
  variant = 'default',
  leadingProp = 'indicator',
  ...props
}: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props}>
      {leadingProp && (
        <span className={cn(indicatorVariants({ variant }))}></span>
      )}
      {props.children}
    </div>
  );
}

export { Badge, badgeVariants };
