// Howl Rate = % in GMV that goes to Howl
// Partner Rate = % in GMV that goes to the Partner

export function getPartnerRateFromROI({
  roi,
  howlRate,
}: {
  roi: number;
  howlRate: number; // a decimal value, e.g. .6
}) {
  return (1 / roi - howlRate) * 100;
}

export function getROIFromPartnerRate({
  partnerRate,
  howlRate,
}: {
  partnerRate: number; // a percentage value, e.g. 60%
  howlRate: number;
}) {
  return 1 / (partnerRate / 100 + howlRate);
}

export function getROIFromPartnerRateAndCogs({
  partnerRate,
  cogs,
}: {
  partnerRate: number; // a percentage value, e.g. 6%
  cogs: number; // a decimal value, the ratio of the partner rate to howl
}) {
  // Cogs is the partner's portion of spend, so (1 - cogs) is the howl portion
  // Multiply the ratio of howl over partner portion against partner rate to get the howl rate
  // Add the partner and howl rate together to get the total spend
  const newTotalSpendPercent = partnerRate + partnerRate * ((1 - cogs) / cogs);
  // ROI is 1 over the total percent spend of gmv as a decimal
  return 1 / (newTotalSpendPercent / 100);
}

export function getHowlRateFromCOGS({
  roi,
  cogs, // a decimal value, e.g. .6 which is the per dollar revenue split for the partner
}: {
  roi: number;
  cogs: number;
}) {
  return (1 - cogs) / roi;
}

export function getHowlRateFromPartnerRate({
  roi,
  partnerRate, // a percentage amount of GMV that a partner will receive
}: {
  roi: number;
  partnerRate: number;
}) {
  // convert partnerRate to cogs
  // .125 - 0.065
  return (1 / roi - partnerRate / 100) * 100;
}

export function getHowlRateFromPartnerRateAndCogs({
  partnerRate, // a percentage amount of GMV that a partner will receive
  cogs,
}: {
  partnerRate: number;
  cogs: number;
}) {
  // cogs = .6
  // partnerRate = 12%
  // ratio = 1 - .6 / .6
  // howlRate = partnerRate * 1-cogs/cogs
  return Number((partnerRate * ((1 - cogs) / cogs)).toFixed(2));
}

function roundToSingleDecimal(value: number) {
  const decimalPart = value - Math.floor(value);
  if (
    Math.abs(decimalPart - 0.01) < 0.001 ||
    Math.abs(decimalPart - 0.99) < 0.001
  ) {
    return Math.round(value);
  }
  return Math.round(value * 10) / 10;
}

export function getPartnerRateFromCOGS({
  roi,
  cogs,
}: {
  roi: number;
  cogs: number;
}) {
  return roundToSingleDecimal(cogs / roi);
}

export function getRoundedRateFromCOGS({
  roi,
  cogs,
}: {
  roi: number;
  cogs: number;
}) {
  let newPartnerRate = cogs / roi;
  // Round partnerRate to nearest 0.5
  newPartnerRate = Math.round(newPartnerRate * 2) / 2;
  // Adjust the ROI so that the partner rate remains the same
  let newROI = cogs / newPartnerRate;
  newROI = Number(newROI.toFixed(10));
  return { newROI, newPartnerRate };
}

interface RateStrategyData {
  isCogs: boolean;
  paymentModel: string;
  paymentModelValue: string;
  pricingModelValue: string;
  eppFeeRate: number;
}

export function getHowlRateFromFromStrategyData({
  isCogs,
  paymentModel,
  paymentModelValue,
  pricingModelValue,
  eppFeeRate,
}: RateStrategyData) {
  // isCogs
  // paymentModel
  // paymentModelValue
  // strategy_pricing_model_value
  // epp_fee_rate

  let howlRate = 0;
  if (isCogs && paymentModelValue) {
    // if a strategy has cogs, the rate tier will also
    howlRate = getHowlRateFromCOGS({
      cogs: parseFloat(paymentModelValue) / 100,
      roi: parseFloat(pricingModelValue),
    });
  } else if (paymentModelValue && paymentModel === 'EPP') {
    // the publisher payment model is also ROI/EPP so use this to get COGS
    // COGS = 1/roi - 1/paymentModelValue
    const totalSpend = 1 / parseFloat(pricingModelValue);
    howlRate = totalSpend - 1 / parseFloat(paymentModelValue);
  } else {
    if (eppFeeRate === 0 && pricingModelValue) {
      // if eppFeeRate is zero, assume we want to use COGS of 60%
      // which means the howl rate if 40% of the merchant spend which we can get from 1/ROI
      howlRate = (1 / parseFloat(pricingModelValue)) * 0.4;
    } else {
      howlRate = eppFeeRate || 0;
    }
  }
  return howlRate;
}

export function transformLowerBoundRatePercent(input: string): string {
  if (!input.includes(' - ') || !input.includes('%')) {
    return input;
  }
  // Split the input string to get the individual percentages
  const [firstPercentage, secondPercentage] = input.split(' - ');

  // Remove the "%" sign from the percentages and convert them to numbers
  const firstPercentageNumber = parseFloat(firstPercentage.replace('%', ''));
  const secondPercentageNumber = parseFloat(secondPercentage.replace('%', ''));

  // Check if the first percentage is less than or equal to 1
  // and the second percentage is greater than 1
  if (firstPercentageNumber <= 1 && secondPercentageNumber > 1) {
    // Return a string formatted as "Up to {second percentage}"
    return `Up to ${secondPercentage}`;
  }

  // If the condition is not met, return the original input string
  return input;
}
