import React from 'react';
import { Transition } from '@headlessui/react';
import Image from 'next/legacy/image';
import { useMediaQuery } from '@mantine/hooks';

function FloatingCity({ children }: any) {
  const isMobile = useMediaQuery('(max-width: 640px)');

  return (
    <div className={'flex justify-center lg:justify-end h-full w-full'}>
      {
        <Image
          src={
            isMobile
              ? '/images/mobile-planet-scape-light.jpg'
              : '/images/planet-scape-light.jpg'
          }
          layout="fill"
          objectFit="cover"
        />
      }

      <div className={'mx-4 sm:mx-0 z-10 w-full sm:w-auto'}>
        <Transition
          show
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={
            'relative z-10 px-5 text-left bg-neutral-gray-10 rounded-[14px] sm:px-20 py-6 sm:py-16 mr-0 lg:mr-44 sm:mt-12 mt-8 sm:min-w-[30rem] min-w-full shadow-dark'
          }
        >
          {children}
        </Transition>
      </div>
    </div>
  );
}

export default React.memo(FloatingCity);
