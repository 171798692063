import React from 'react';
import moment from 'moment/moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Badge } from '../badge';
import {
  cn,
  formatCurrencyThousands,
  GoalState,
  GoalTier,
  holidayTierIcons,
} from '@frontend/shared-utils';
import { Progress } from '../progress';

interface BonusProgressProps {
  goal: any;
  color?: string;
  subtractDatesV2: any;
  brand?: any;
  progressBubble?: {
    heading: string;
    description: string;
  };
  goalState?: GoalState;
  holidayHubView?: boolean;
  totalBonusEarnedDB?: string;
  earningsDB?: string;
  optedIn?: boolean;
}

export const BonusProgress = ({
  goal,
  color = 'green',
  subtractDatesV2,
  brand,
  progressBubble,
  goalState,
  holidayHubView,
  totalBonusEarnedDB,
  earningsDB,
  optedIn,
}: BonusProgressProps) => {
  const router = useRouter();
  if (!goal) {
    return null;
  }

  const tiers = goal.goal_tiers;
  const earnings: number =
    (goalState === GoalState.Expired || goalState === GoalState.Final) &&
    earningsDB !== undefined
      ? parseInt(earningsDB as string)
      : parseFloat(goal.earnings_usd);
  const goalType = goal.goal_type;
  const pubId = router.query.publisherId as string;

  const textColors: { [key: string]: string } = {
    green: 'text-green-400',
    violet: 'text-violet-400',
  };

  optedIn = optedIn || goal.goal_creator.length > 0;

  // Sort the goal tiers based on their gmv_goal
  goal.goal_tiers.sort(
    (a: GoalTier, b: GoalTier) =>
      parseInt(a.gmv_goal as string) - parseInt(b.gmv_goal as string),
  );

  const completedTiers: GoalTier[] = goal.goal_tiers.filter(
    (tier: GoalTier) => (earnings || 0) >= parseFloat(tier.gmv_goal as string),
  );

  // If no index param, return total bonus earned for all tiers,
  // otherwise return total bonus earned for the tier at the index
  const totalBonus = (index?: number) => {
    let total = 0;
    for (
      let i = 0;
      i < (index !== undefined ? index : completedTiers.length);
      i++
    ) {
      if (index !== undefined) {
        total += parseInt(goal.goal_tiers[i].tier_reward_usd as string);
      } else {
        total += parseInt(completedTiers[i].tier_reward_usd as string);
      }
    }
    return total.toLocaleString();
  };

  // Compare current tier to tier stored in local storage to determine if toast banner should be shown
  const currentTier: GoalTier | undefined = goal.goal_tiers.find(
    (tier: GoalTier) => earnings < parseInt(tier.gmv_goal as string),
  );

  const currentTierIndex = currentTier
    ? goal.goal_tiers.indexOf(currentTier)
    : goal.goal_tiers.length;

  const nextTierIndex = Math.min(completedTiers.length, goal.goal_tiers.length);

  const areAllTiersAchieved = goal.goal_tiers.length === currentTierIndex;

  const topRewardAmount = goal.goal_tiers
    .reduce(
      (acc: number, tier: GoalTier) =>
        acc + parseInt(tier.tier_reward_usd as string),
      0,
    )
    .toLocaleString();

  const now = new Date();
  const daysLeft = Math.floor(
    subtractDatesV2({
      d1: goal.datetime_end,
      d2: now,
      unit: 'day',
    }) as number,
  );

  const daysUntilStart = Math.floor(
    subtractDatesV2({
      d1: moment(goal.datetime_start).utc(),
      d2: moment().utc(),
      unit: 'day',
    }) as number,
  );

  const customTierIcons =
    goalType === 'HOWL_HOLIDAY_BONUS' ? holidayTierIcons : undefined;

  const TimeLeft = () =>
    daysUntilStart <= 0 ? (
      <Badge
        variant={
          daysLeft > 7
            ? 'success'
            : daysLeft <= 7 && daysLeft > 1
            ? 'warning'
            : 'critical'
        }
        leadingProp={null}
        className={'h-6 font-medium w-fit'}
      >
        {goalState !== GoalState.Expired ? (
          <div>
            Ends in {daysLeft} {daysLeft !== 1 ? 'days' : 'day'}
          </div>
        ) : (
          <div>Bonus period has ended</div>
        )}
      </Badge>
    ) : null;

  return (
    <div className={'w-full'}>
      {/** Progress Bar Header */}
      <div
        className={cn(
          'flex md:flex-row flex-col w-full',
          (goalState === GoalState.Upcoming || !optedIn) && 'hidden',
          goalType !== 'DEFAULT' && 'md:px-16 px-6 md:mt-6 md:ml-0',
          holidayHubView && 'md:px-0',
        )}
      >
        {!holidayHubView && (
          <div className={cn('flex grow md:mb-0 mb-3')}>
            <div className={'flex flex-col md:w-auto w-full'}>
              <div
                className={'font-medium text-2xl flex flex-row md:mb-0 mb-1'}
              >
                <div className={'grow'}>
                  {goalState === GoalState.Final
                    ? 'Your Results'
                    : 'Your Progress'}
                </div>
                <div className={'-mt-0.5 md:hidden'}>
                  {goalType === 'DEFAULT' && <TimeLeft />}
                </div>
              </div>
              <div
                className={cn(
                  'text-black/60 md:mb-2 text-sm',
                  goalState === GoalState.Final && 'hidden',
                )}
              >
                <span>Earn up to </span>
                <span className={'font-medium'}>${topRewardAmount} </span>
                {goalType === 'DEFAULT' ? (
                  <span>
                    by driving sales with {brand.merch_name} when you hit
                    certain milestones.
                  </span>
                ) : (
                  <span>
                    by recommending to your community the products you love this
                    holiday season.
                  </span>
                )}
              </div>
              <div className={'md:flex hidden'}>
                {goalType === 'DEFAULT' && <TimeLeft />}
              </div>
            </div>
          </div>
        )}

        {goalState !== GoalState.Upcoming && (
          <div
            className={cn(
              'flex md:flex-row flex-col grow',
              goalState === GoalState.InProgress &&
                !holidayHubView &&
                'md:pr-[330px]',
            )}
          >
            <div className={'grow mt-1'}>
              {holidayHubView && (
                <div className={'flex gap-3 items-center md:mb-0 mb-3'}>
                  <div
                    className={
                      'rounded-full w-12 h-12 border border-border-subtle overflow-hidden shrink-0'
                    }
                  >
                    <img src={brand.logo_url} alt={brand.merch_name} />
                  </div>
                  <div>
                    <div className={'flex flex-col'}>
                      <Link
                        href={`/publisher/${pubId}/campaign-center/brands/${brand.merch_id}`}
                        className={
                          'text-black text-opacity-90 text-md font-medium text-ellipsis line-clamp-1 cursor-pointer'
                        }
                      >
                        {goal.name}
                      </Link>
                      <div>
                        <TimeLeft />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={cn('flex md:flex-row flex-col md:ml-4')}>
          <div
            className={cn(
              'flex flex-row md:static relative',
              goalState === GoalState.Upcoming && '!hidden',
              !optedIn && 'hidden',
            )}
          >
            <div
              className={cn(
                'h-fit md:mb-3 px-4 py-2 rounded-lg bg-transparent border w-full md:mr-0 mr-2',
                holidayHubView && 'md:mt-0',
                goalType === 'HOWL_HOLIDAY_BONUS' &&
                  (goalState === GoalState.Expired ||
                    goalState === GoalState.Final) &&
                  'hidden',
              )}
            >
              <p
                className={cn(
                  'font-medium text-xl text-center text-black/60',
                  earnings !== 0 && 'text-text-success',
                )}
              >
                ${earnings.toLocaleString()}
              </p>
              <p className="text-black/60 md:text-sm text-xs text-center whitespace-nowrap">
                Total Sales
              </p>
            </div>
            <div
              className={cn(
                'h-fit px-4 py-2 border rounded-lg md:ml-4 relative right-0 w-full ml-2',
                goalType === 'DEFAULT' &&
                  'bg-gradient-to-r from-nebula-10/20 to-nebula-10',
                goalType === 'HOWL_HOLIDAY_BONUS' &&
                  'bg-gradient-to-r from-violet-10/20 to-violet-10',
                totalBonus() === '0' &&
                  'bg-gradient-to-r from-white to-white border border-neutral-200',
                holidayHubView && 'md:mr-0',
                goalState === GoalState.Expired && 'ml-0',
              )}
            >
              <p
                className={cn(
                  'font-medium text-xl text-center text-green-400',
                  goalType === 'HOWL_HOLIDAY_BONUS' && 'text-violet-400',
                  totalBonus() === '0' && 'text-text-secondary',
                )}
              >
                {(goalState === GoalState.Expired ||
                  goalState === GoalState.Final) &&
                totalBonusEarnedDB !== undefined
                  ? `$${totalBonusEarnedDB}`
                  : `$${totalBonus()}`}
              </p>
              <p className="text-black/60 md:text-sm text-xs text-center whitespace-nowrap">
                {goalType === 'HOWL_HOLIDAY_BONUS' &&
                goalState === GoalState.Expired &&
                !areAllTiersAchieved
                  ? 'Estimated Bonus Pending Final Data'
                  : 'Total Bonus Earned'}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/** Progress Bar */}
      <div
        className={cn(
          'md:pr-20 md:pl-6 mt-4',
          goalType !== 'DEFAULT' &&
            'my-auto w-full md:pl-[52px] md:pr-[130px] pr-6 pl-6 pb-2',
        )}
      >
        <div className="w-full flex flex-row md:flex-col md:justify-center md:pr-2">
          <div
            className={cn(
              'w-full md:mt-6 md:ml-0 flex flex-col justify-center',
              holidayHubView && 'md:mt-4',
              (goalState === GoalState.Upcoming || !optedIn) && 'md:mt-0',
              goalState !== GoalState.Upcoming && 'min-h-40',
              (areAllTiersAchieved || goalState === GoalState.Expired) &&
                'md:mt-0',
              (areAllTiersAchieved || goalState === GoalState.Expired) &&
                holidayHubView &&
                'md:min-h-24',
            )}
          >
            <div className={'ml-4 flex md:flex-row flex-col w-full mt-6'}>
              {tiers &&
                tiers.map((tier: any, idx: number) => {
                  const gmvGoal = parseFloat(tier.gmv_goal);
                  let progressValue = 0;
                  if (earnings < gmvGoal) {
                    const exceedAmount =
                      idx < tiers.length && idx > 0
                        ? earnings -
                          (parseFloat(
                            tiers[idx - 1].gmv_goal,
                          ) as unknown as number)
                        : earnings;
                    const diffBetweenGoals =
                      idx < tiers.length && idx > 0
                        ? gmvGoal -
                          (parseFloat(
                            tiers[idx - 1].gmv_goal,
                          ) as unknown as number)
                        : gmvGoal;
                    progressValue = (exceedAmount / diffBetweenGoals) * 100;
                  } else if (earnings == gmvGoal || earnings > gmvGoal) {
                    progressValue = 100;
                  } else {
                    progressValue = 0;
                  }
                  const disabled =
                    goalState !== GoalState.InProgress &&
                    goalState !== GoalState.Upcoming &&
                    progressValue < 100;
                  return (
                    <div key={idx} className={'w-full md:block flex'}>
                      <div className="md:text-right flex md:justify-end"></div>
                      <Progress
                        value={progressValue}
                        id={idx.toString()}
                        color={color}
                        isActive={goalState === GoalState.InProgress}
                        customTierIcons={customTierIcons}
                        progressBubble={
                          goalState === GoalState.InProgress
                            ? progressBubble
                            : undefined
                        }
                      />
                      <div
                        className={cn(
                          'md:mt-3 flex md:flex-row flex-col justify-between mr-3',
                          !!customTierIcons && 'mr-2',
                        )}
                      >
                        <div></div>
                        <div
                          className={cn(
                            'md:text-center md:mb-0 -mb-[7px] md:-mr-[78px] w-40 md:mt-2',
                            !!customTierIcons &&
                              'md:mt-3 md:mb-[3px] ml-1 md:-mr-[74px]',
                          )}
                        >
                          <div
                            className={cn(
                              'font-medium leading-6 text-sm',
                              disabled && '!text-text-disabled',
                              {
                                'text-sm': holidayHubView,
                              },
                            )}
                          >
                            {progressValue >= 100 ? (
                              <div className={cn(textColors[color])}>
                                <p
                                  className={cn(
                                    'font-medium md:mb-0',
                                    !!customTierIcons && 'md:ml-1 mb-3',
                                    currentTierIndex - 1 === idx && 'mb-0',
                                    !tier.tier_reward_label &&
                                      currentTierIndex - 1 !== idx &&
                                      'mb-3',
                                  )}
                                >
                                  Earned
                                </p>

                                {tier.tier_reward_label ? (
                                  <div>{tier.tier_reward_label}</div>
                                ) : (
                                  <div
                                    className={cn(
                                      currentTierIndex - 1 !== idx && 'hidden',
                                    )}
                                  >
                                    ${totalBonus(idx + 1)}{' '}
                                    <br
                                      className={cn(
                                        'lg:hidden md:block hidden',
                                        holidayHubView && 'lg:block',
                                      )}
                                    />{' '}
                                    Total Bonus
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                {disabled ? (
                                  <p
                                    className={cn(
                                      'text-neutral-400 font-medium leading-6 md:mb-0',
                                      disabled && '!text-text-disabled',
                                      !!customTierIcons && 'md:ml-1 mb-2.5',
                                    )}
                                  >
                                    Expired
                                  </p>
                                ) : (
                                  <div>
                                    <div
                                      className={cn(
                                        'font-normal text-sm',
                                        nextTierIndex === idx
                                          ? 'text-black/60'
                                          : 'text-black/40',
                                      )}
                                    >
                                      {formatCurrencyThousands(
                                        tier.gmv_goal,
                                        'thousands',
                                      )}{' '}
                                      in Sales
                                    </div>

                                    {tier.tier_reward_label ? (
                                      <div
                                        className={cn(
                                          'whitespace-nowrap md:ml-[-100%] md:mr-[-100%] md:text-center',
                                          nextTierIndex === idx
                                            ? 'text-black/90'
                                            : 'text-black/60',
                                        )}
                                      >
                                        {tier.tier_reward_label}
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          'flex flex-col md:text-center text-left'
                                        }
                                      >
                                        <div
                                          className={cn(
                                            '',
                                            nextTierIndex === idx
                                              ? 'text-black/90'
                                              : 'text-black/60',
                                          )}
                                        >
                                          ${totalBonus(idx + 1)}{' '}
                                          <br
                                            className={cn(
                                              'lg:hidden md:block hidden',
                                              holidayHubView && 'lg:block',
                                            )}
                                          />{' '}
                                          Total Bonus
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
