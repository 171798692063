import React from 'react';
import classNames from 'classnames';

type MocksButtonProps = {
  setShowMocks: any;
  showMocks: boolean;
};

export const MocksButton = ({ setShowMocks, showMocks }: MocksButtonProps) => {
  const env = process.env['NEXT_PUBLIC_APP_ENV'];
  const mocksButton =
    env && ['local', 'staging'].includes(env) ? (
      <button
        className={classNames(
          'left-5 bottom-5 fixed z-50 cursor-pointer rounded-lg',
          {
            'bg-green-100': showMocks,
            'bg-red-100': !showMocks,
          },
        )}
        onClick={() => setShowMocks(!showMocks)}
      >
        Mocks
      </button>
    ) : null;

  return <>{mocksButton}</>;
};
