import React from 'react';
import Link from 'next/link';
import { Button, Color } from '@frontend/shared-ui-kit/button';
import classNames from 'classnames';

interface BannerProps {
  title: string;
  href?: string;
  children: string;
  buttonText: string;
  onClick?: any;
}

export const Banner = ({
  title,
  href,
  children,
  buttonText,
  onClick,
}: BannerProps) => {
  const handleClick = () => {
    if (onClick !== undefined) {
      return onClick();
    }
    return true;
  };

  let contents = (
    <div
      className={classNames(
        'bg-white flex flex-col xl:flex-row text-center xl:text-left items-center justify-center mt-8 px-8 py-6 relative z-20 mx-4 sm:mx-12 -mb-4 sm:mb-12 shadow rounded-lg sm:container md:mx-auto lg:mb-8',
      )}
    >
      <span className="flex-none pr-8 text-2xl font-bold mb-2 xl:mb-0">
        {title}
      </span>
      <span className="text-sm sm:text-base flex-1 mr-3 mb-4 xl:mb-0 xl:pl-6 xl:pr-8">
        {children}
      </span>
      <div className="flex-none">
        <Button color="green" onClick={handleClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );

  if (href) {
    contents = (
      <Link target="_blank" href={href} className="cursor-pointer">
        {contents}
      </Link>
    );
  }

  return contents;
};
