import { supportedPhylloPlatforms } from '@frontend/shared-utils';

export const platformFormat = (channel_handle: any, channel_type: any) => {
  channel_type = channel_type.toLowerCase();
  if (supportedPhylloPlatforms.includes(channel_type)) {
    // Format the YouTube, Instagram, and TikTok url's if they are invalid
    const youtubeRegEx = new RegExp(
      /(https?:\/\/)?(www\.)?youtu((\.be)|(be\..{2,5}))\/?(\S+)/,
    );
    const instagramRegEx = new RegExp(
      /(https?:\/\/)?(www\.)?instagram\/?(\S+)/,
    );
    const tiktokRegEx = new RegExp(/(https?:\/\/)?(www\.)?tiktok\/?(\S+)/);
    const twitterRegEx = new RegExp(/(https?:\/\/)?(www\.)?twitter\/?(\S+)/);

    const handle_no_at = channel_handle.replace(/\s/g, '').replace('@', '');
    if (channel_type === 'youtube' && !youtubeRegEx.test(channel_handle)) {
      channel_handle = 'https://www.youtube.com/@' + handle_no_at;
    } else if (
      channel_type === 'instagram' &&
      !instagramRegEx.test(channel_handle)
    ) {
      channel_handle = 'https://www.instagram.com/' + handle_no_at;
    } else if (channel_type === 'tiktok' && !tiktokRegEx.test(channel_handle)) {
      channel_handle = 'https://www.tiktok.com/@' + handle_no_at;
    } else if (
      channel_type === 'twitter' &&
      !twitterRegEx.test(channel_handle)
    ) {
      channel_handle = 'https://www.twitter.com/' + handle_no_at;
    }
  }
  return channel_handle;
};

export const platformUrlValidation = (platform: any, platformUrl: any) => {
  const emailHttpReg = new RegExp(
    /(((ftp|http|https):\/\/)|(\/)|(..\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
  );
  const emailReg = new RegExp(
    /[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  );
  return !platformUrl
    ? 'Please include a channel handle or url'
    : platformUrl.toLowerCase().startsWith('http') &&
      !emailHttpReg.test(platformUrl) // Test if string has http
    ? 'Please enter a valid complete URL'
    : !emailReg.test(platformUrl) // Test without http/https
    ? 'Please enter a valid complete URL'
    : null;
};

export const platformValidate = (
  primaryPlatform: any,
  primaryPlatformUrl: any,
  secondaryPlatforms: any[],
) => {
  const primaryPlatformUrlError =
    primaryPlatform && primaryPlatformUrl
      ? platformUrlValidation(primaryPlatform, primaryPlatformUrl)
      : null;

  let atLeastOneFilled: string | null = 'Please fill out at least one channel.';

  const newErrors = secondaryPlatforms.map((platform: any, idx: number) => {
    let error = null;
    if (platform.secondaryPlatformUrl.length) {
      atLeastOneFilled = null;
    }
    if (platform.secondaryPlatform && platform.secondaryPlatformUrl) {
      const sameAsSecondary = secondaryPlatforms.some(
        (otherPlatform: any, otherIdx: number) =>
          platform.secondaryPlatformUrl ===
            otherPlatform.secondaryPlatformUrl &&
          idx > otherIdx &&
          platform.secondaryPlatform.value ===
            otherPlatform.secondaryPlatform.value &&
          idx > otherIdx,
      );
      const sameAsPrimary =
        (platform.secondaryPlatform as any)?.value ===
          (primaryPlatform as any)?.value &&
        platform.secondaryPlatformUrl === primaryPlatformUrl;

      if (sameAsSecondary || sameAsPrimary) {
        return 'Please remove duplicate channels';
      }

      error = platformUrlValidation(
        platform.secondaryPlatform,
        platform.secondaryPlatformUrl,
      );
    }
    return error;
  });
  if (atLeastOneFilled) {
    newErrors[0] = atLeastOneFilled;
  }
  const valid =
    !primaryPlatformUrlError && !newErrors.find((error: any) => !!error);

  return {
    valid,
    primaryPlatformUrlError: primaryPlatformUrlError,
    secondaryPlatformErrors: newErrors,
  };
};
