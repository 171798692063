import { Context } from '..';

export const fetchTopPosts = async ({ effects, state }: Context) => {
  state.ecommHome.topPosts = null;

  let fetchOpenGraphRequests = null;

  const result = await effects.api
    .get(
      `/api/v0/merchants/${state.organization.selectedOrgId}/content_feeds/`,
      {
        sort_by: 'trending',
        page: 1,
        per_page: 8,
      },
    )
    .then(async (response: any) => {
      state.ecommHome.topPosts = response.data.data[0].stats;
      if (response.data.data[0].stats && response.data.data[0].stats.length) {
        fetchOpenGraphRequests = response.data.data[0].stats.map(
          (post: any, postIndex: number) => {
            return fetch(`/api/metadata`, {
              method: 'POST',
              body: JSON.stringify({
                url: post.story_url,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (state.ecommHome.topPosts && data) {
                  if (data.ogTitle) {
                    state.ecommHome.topPosts[postIndex].story_name =
                      data.ogTitle;
                  }
                  if (data.ogImage && data.ogImage.url) {
                    state.ecommHome.topPosts[postIndex].pub_logo_url =
                      data.ogImage.url;
                  }
                }
                return data;
              });
          },
        );
        await Promise.all(fetchOpenGraphRequests);
      }
      return response;
    });

  return result;
};
