type CartExclusions = {
  skus: [];
  brands: [];
  google_categories: [];
  merchant_categories: [];
};

export type CartExclusionsResponse = {
  cart_exclusion_expression_id: number | null;
  cart_exclusions: CartExclusions;
};

type CreatorCartExclusions = {
  data: CartExclusionsResponse[];
};

export const state: CreatorCartExclusions = {
  data: [],
};
