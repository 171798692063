import React from 'react';

import { useDecision as useDecisionOptimizely } from '@optimizely/react-sdk';
import {
  OptimizelyDecideOption,
  UserAttributes,
} from '@optimizely/optimizely-sdk';
import { useQuery } from '@tanstack/react-query';
import { OptimizelyDecision } from '@optimizely/react-sdk/dist/utils';

const useOverridesQuery = (featureKey: string) =>
  useQuery({
    queryKey: ['feature-flag-overrides', featureKey],
    queryFn: () => {
      const items = localStorage.getItem('feature-flag-overrides');
      const overrides = items ? JSON.parse(items) : {};

      return overrides[featureKey] || null;
    },
  });

export const useDecision = (
  featureKey: string,
  options?: {
    autoUpdate?: boolean;
    timeout?: number;
    decideOptions?: OptimizelyDecideOption[];
  },
  overrides?: {
    overrideUserId?: string;
    overrideAttributes?: UserAttributes;
  },
): { decision: OptimizelyDecision; ready: boolean; error: boolean } => {
  const { data: overridesDecision, isFetched: isOverridesFetched } =
    useOverridesQuery(featureKey);

  const [optimizelyDecision, optimizelyReady, optimizelyError] =
    useDecisionOptimizely(featureKey, options, overrides);

  const overridesPayload = {
    userContext: {
      id: 'guest',
    },
    variationKey: featureKey,
    enabled: overridesDecision?.variation === 'on',
    variables: {},
    ruleKey: '',
    flagKey: featureKey,
    reasons: [],
  };

  // if we aren't done fetching overrides, or we are and they exist
  if (!isOverridesFetched || overridesDecision)
    return {
      decision: overridesPayload,
      ready: isOverridesFetched,
      error: false,
    };

  // otherwise return optimizely results
  return {
    decision: optimizelyDecision,
    ready: optimizelyReady,
    error: optimizelyError,
  };
};
