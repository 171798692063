import React from 'react';
import classNames from 'classnames';

const Arrow = ({
  direction,
  onClick,
  color,
  shadow,
  disabled = false,
}: {
  direction: string;
  onClick: any;
  color?: string;
  shadow?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div
      data-cy={`arrow-${direction}`}
      className={classNames(
        'flex items-center justify-center border-solid border z-10 absolute w-12 h-12 rounded-full',
        color,
        {
          'right-0 py-4 pr-4 pl-3': direction === 'right',
          'left-0 py-4 pr-3 pl-4': direction === 'left',
          'top-0 p-4': direction === 'up',
          'bottom-0 p-4': direction === 'down',
          'border-gray-300': !color,
          'shadow-lg': shadow,
          'bg-neutral-gray-200': disabled,
          'bg-white cursor-pointer': !disabled,
        },
      )}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick();
      }}
    >
      <div
        className={classNames(
          'transform inline-block p-1 border-solid border-t-0 border-r border-b border-l-0',
          color,
          {
            '-rotate-45': direction === 'right',
            'rotate-135': direction === 'left',
            '-rotate-135': direction === 'up',
            'rotate-45': direction === 'down',
            'border-black': !color,
          },
        )}
      />
    </div>
  );
};

export default Arrow;
