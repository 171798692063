import moment from 'moment';
import { Networks } from '../megatable/models';
import { Columns } from '../../publisher/column-service';

/* Pagination helpers */
const invalidPage = (page: any, totalItems: any, limit: any) => {
  const lastPage = Math.ceil(totalItems / limit);

  if (lastPage && page > lastPage) return true;
  return false;
};

const calculatePagination = (pageNum: any, perPage: any, limit: any) => {
  /*
   * We use a limit of 500 for stats queries, which doesn't directly
   * correspond with the frontend perPage. Need to calculate the offset
   * for the stats query, or where to fetch from the existing stats.
   */
  const offset = (pageNum - 1) * perPage;
  const page = Math.floor(offset / limit) + 1;
  // Indexes of the requested stats in the array of 500 stat objects
  const indexStart = offset % limit;
  const indexEnd = indexStart + perPage;

  return [page, indexStart, indexEnd];
};

/* Formatting functions */
export const formatNumberWithCommas = (number: any) => {
  let numString = `${number}`;
  if (number >= 1000 || number <= -1000) {
    const thousandRegex = /(-?\d+)(\d{3})/;
    while (thousandRegex.test(numString)) {
      numString = numString.replace(thousandRegex, '$1,$2');
    }
  }
  return numString;
};

const formatNumber = (number: any, type?: string, columnName?: string) => {
  const { roundedFields, roundedFieldsLessThanOne } = new Columns();
  let numString;
  number = parseFloat(number);

  /*
   * Depending on column type leading 0 is removed or
   * Keep leading 0 if number is less than 1 (0.67 => 0.67)
   * Round number if greater than 1 or equal to 0.
   */
  if (
    (number > 1 && roundedFields.includes(columnName as any)) ||
    roundedFieldsLessThanOne.includes(columnName as any)
  ) {
    number = Math.round(number);
  }

  /*
   * Values > 1 should have 2 decimal places of precision by default.
   * Rounded decimal fields should be 2 places of precision
   */
  if (
    ['currency', 'percent', 'roi'].includes(type as any) &&
    ((!roundedFields.includes(columnName as any) && number > 0) ||
      (roundedFields.includes(columnName as any) &&
        Math.abs(number) > 0 &&
        Math.abs(number) < 1) ||
      (roundedFields.includes(columnName as any) && number < 0))
  ) {
    numString = number.toFixed(2);
  } else if (['roi', 'roi_actual', 'roi_goal'].includes(columnName as any)) {
    numString = number.toFixed(1);
  } else if (roundedFields.includes(columnName as any) && number > 1) {
    numString = number.toFixed(0);
  } else {
    numString = (number || 0).toString();
  }

  if (columnName && columnName.includes('percent_difference')) {
    numString = number.toFixed(0);
  }

  numString = formatNumberWithCommas(numString);

  /*
   * Add trailing zero to currency values less than $1 ($0.4 => $0.40)
   * Values greater than 1 will have been rounded above.
   */
  if (['currency', 'roi'].includes(type as any) && numString.match(/0\.\d$/)) {
    numString += '0';
  }

  return numString;
};

const formatCurrencyThousands = (number: any, unit: any, prefix = '$') => {
  // Remove commas, dollar sign, etc
  number = parseFloat(
    number
      .split('')
      .reduce(
        (acc: any, curr: any) => (/[\d.]/g.test(curr) ? acc + curr : acc),
        '',
      ),
  );
  if (number < 1000) {
    return `${prefix}${number}`;
  }

  number = Math.round(number);
  const labels = ['', 'K', 'M', 'B'];
  let thousands = 0;

  while (number >= 1000 && thousands < labels.length - 1) {
    if (labels[thousands] === unit) {
      break;
    }
    number /= 1000;
    thousands++;
  }

  // Remove trailing 0's after decimal and the decimal itself, adds commas back in
  let numberString = number.toFixed(1);
  while (
    numberString.includes('.') &&
    ['0', '.'].includes(numberString[numberString.length - 1])
  ) {
    numberString = numberString.substring(0, numberString.length - 1);
  }
  numberString = formatNumberWithCommas(numberString);

  return `${prefix}${numberString}${thousands ? labels[thousands] : ''}`;
};

const formatString = (value: any) => {
  const valueMap: any = {
    POST_CLICK: 'Post Click',
    LAST_CLICK: 'Last Click',
    MULTIPLE: 'Multiple',
    Unknown: '-',
  };

  return valueMap[value] || value;
};

const formatCellValue = (val: any, type: any, options?: any) => {
  if (val === '-') return val;

  if (type === 'date') {
    return moment.utc(val).format(options.dateFormat);
  }

  let value: string | number = parseFloat(val);

  if (options) {
    if (options.round) {
      value = Math.round(value);
    }

    if (
      typeof options.precision !== 'undefined' ||
      options.precision !== null
    ) {
      if (!options.nonZeroPrecision && value !== 0) {
        value = value.toFixed(options.precision);
      }
    }

    if (options.noSingleDecimal) {
      value = parseFloat(val);
      const singleDecimal = String(value).split('.')[1]?.length === 1;
      value = singleDecimal ? Number(value).toFixed(2) : String(value);
    }
  }

  //	Opt out function
  if (!options || !options.noCommas) {
    value = formatNumberWithCommas(value);
  }

  switch (type) {
    case 'currency':
      value = typeof value === 'string' ? value : value.toString();

      // If negative value, format it as -$x.xx rather than $-x.xx
      return value[0] === '-' ? `${value[0]}$${value.slice(1)}` : `$${value}`;
    case 'percent':
      return `${value}%`;
    case 'multiple':
      return `${value}x`;
    default:
      return value;
  }
};

const formatValue = (
  value: any,
  type?: any,
  columnName?: any,
  convertToDashes = false,
) => {
  const zeroAllowedColumns = [
    'daily_budget_amount',
    'monthly_budget_amount',
    'publisher_revenue',
  ];
  if (type === 'string') return formatString(value);

  // Return '-' for falsy numerical values like 0 or NaN
  if (
    convertToDashes &&
    !parseFloat(value) &&
    !zeroAllowedColumns.includes(columnName)
  )
    return '-';

  value = formatNumber(value, type, columnName); // Returns number as string
  switch (type) {
    case 'currency':
      // If negative value, format it as -$x.xx rather than $-x.xx
      return value[0] === '-' ? `${value[0]}$${value.slice(1)}` : `$${value}`;
    case 'percent':
      return `${value}%`;
    case 'percentChange':
      return `${value > 0 ? '+' : ''}${value}%`;
    case 'multiple':
      return `${value}x`;
    default:
      return value;
  }
};

const titleCase = (name: any) => {
  if (!name) {
    return '';
  }
  return name.replace(
    /(?:^|([ \t([]))([a-z])/g,
    (match: any, value1: any, value2: any) => {
      return `${value1 || ''}${value2.toUpperCase()}`;
    },
  );
};

const camelCaseToDash = (name: any) => {
  return name.replace(/([A-Z])/g, (g: any) => `-${g[0].toLowerCase()}`);
};

const labelToDash = (name: any) => {
  return name.replace(/ /g, '-').toLowerCase();
};

const displayNetworks = (networkList: any, type: any) => {
  // TODO: This may be deprecated as it's removed from megatable
  /*
   * Returns formatted networks for either a tooltip ('Amazon', 'Narrativ') or text
   * for the table cell ('Narrativ' / 'Narrativ - Rakuten'). Compares networkList to '-'
   * due to edge case when totals for total-row haven't been calculated
   */
  if (!networkList || networkList === '-') return '';

  const networks = new Networks().defaultNetworks;
  networks.push({ label: 'Amazon', name: 'amazon', selected: false });

  if (type === 'tooltip') {
    const formattedNetworkList = networkList.map((network: any) => {
      const networkMatch: any = networks.find(
        (item: any) => item.name === network,
      );
      return networkMatch.label;
    });
    return formattedNetworkList.join(', ');
  }
  if (type === 'label') {
    if (networkList.length === 1) {
      const networkMatch: any = networks.find(
        (item: any) => item.name === networkList[0],
      );
      return networkMatch.label;
    }
    return 'Multiple';
  }
};

const getNetworkQueryParam = (networks: any) => {
  // Returns selected networks formatted for api, ex: 'narrativ,rakuten'
  return networks
    .reduce((agg: any, network: any) => {
      if (network.selected) agg += `${network.name},`;
      return agg;
    }, '')
    .slice(0, -1);
};

const getSelectedString = (options: any) => {
  return options
    .reduce(
      (options: any, option: any) =>
        option.selected ? options.concat([option.label]) : options,
      [],
    )
    .join(',');
};

// Converts TitleCase to snake_case
const snakeCase = (name: any) => {
  return name.replace(/([^_A-Z])([A-Z])/g, '$1_$2').toLowerCase();
};

/* Service functions */
const buildQueryString = (params: any) => {
  // {key1: value1, key2: value2} => '?key1=value1&key2=value2'
  let queryStr = '?';
  Object.keys(params).forEach((key, i) => {
    queryStr += `${i === 0 ? '' : '&'}${key}=${params[key]}`;
  });

  return queryStr;
};

const getStatsFromCache = (
  statCache: any,
  page: any,
  indexStart: any,
  indexEnd: any,
) => {
  if (!statCache.pages[page]) return [];

  return Promise.resolve(statCache.pages[page].slice(indexStart, indexEnd));
};

const orderParams = (params: any, orderedKeys: any) => {
  // Order params so they're consistent & easier to mock in tests
  const orderedParams: any = {};

  orderedKeys.forEach((key: any) => {
    if (params[key]) {
      orderedParams[key] = params[key];
    }
  });

  return orderedParams;
};

/* DOM functions */
const handleImageError = (e: any) => {
  e.target.onerror = null;
  e.target.src = '/images/product_placeholder.png';
};

const scrollPage = (selector: any, dimension: any, distance: any) => {
  const element = document.querySelector(selector);
  element[dimension] = distance;
};

const removeOrgId = (pathname: any) => {
  const orgMatch = pathname.match(/(publisher|merchant)(\/\d+)*(\/.+)/);
  const adminMatch = pathname.match(/(admin)(\/.+)/);
  const genericMatch = pathname.match(/(\/v2.*)/);
  return orgMatch
    ? `/${orgMatch[1]}${orgMatch[3]}`
    : adminMatch
    ? `/${adminMatch[1]}${adminMatch[2]}`
    : genericMatch
    ? genericMatch[1]
    : pathname;
};

export {
  buildQueryString,
  calculatePagination,
  camelCaseToDash,
  displayNetworks,
  formatNumber,
  formatCurrencyThousands,
  formatString,
  formatCellValue,
  formatValue,
  getNetworkQueryParam,
  getSelectedString,
  getStatsFromCache,
  handleImageError,
  invalidPage,
  labelToDash,
  orderParams,
  removeOrgId,
  scrollPage,
  snakeCase,
  titleCase,
};
