import { Context } from '..';
import { Bonus, CreateBonus, GoalTier } from '@frontend/shared-utils';

export const onInitializeOvermind = (context: Context) => {};

export const getMerchantData = async ({ effects }: Context) => {
  return await effects.api
    .get('/api/v0/merchants/', { is_active: true })
    .then((resp: any) => {
      return resp.data.data;
    });
};

// Functions below support both brand and platform-wide goals (merchId is optional)
export const createBonus = async (
  { effects }: Context,
  { merchId, bonus }: { merchId?: number; bonus: CreateBonus },
) => {
  if (merchId) {
    return await effects.api.post(`/api/v0/merchants/${merchId}/goals/`, bonus);
  } else {
    return await effects.api.post(`/api/v0/merchants/goals/`, bonus);
  }
};

export const updateBonus = async (
  { effects }: Context,
  {
    merchId,
    bonusId,
    bonus,
  }: { merchId?: number; bonusId: number; bonus: Bonus },
) => {
  if (merchId) {
    return await effects.api.put(
      `/api/v0/merchants/${merchId}/goals/${bonusId}/`,
      bonus,
    );
  } else {
    return await effects.api.put(`/api/v0/merchants/goals/${bonusId}/`, bonus);
  }
};

export const getBonuses = async ({ effects }: Context, merchId?: string) => {
  if (merchId) {
    return await effects.api
      .get(`/api/v0/merchants/${merchId}/goals/`)
      .then((resp: any) => {
        return resp.data.data[0].goals;
      });
  } else {
    return await effects.api
      .get(`/api/v0/merchants/goals/`)
      .then((resp: any) => {
        return resp.data.data[0].goals;
      });
  }
};

// API Currently only accepts single tier at a time
export const createBonusTier = async (
  { effects }: Context,
  {
    merchId,
    bonusId,
    tier,
  }: { merchId?: number; bonusId: number; tier: GoalTier },
) => {
  if (merchId) {
    return await effects.api.post(
      `/api/v0/merchants/${merchId}/goals/${bonusId}/goal_tiers/`,
      tier,
    );
  } else {
    return await effects.api.post(
      `/api/v0/merchants/goals/${bonusId}/goal_tiers/`,
      tier,
    );
  }
};

export const updateBonusTier = async (
  { effects }: Context,
  {
    merchId,
    bonusId,
    tierId,
    tier,
  }: { merchId?: number; bonusId: number; tierId: number; tier: GoalTier },
) => {
  if (merchId) {
    return await effects.api.put(
      `/api/v0/merchants/${merchId}/goals/${bonusId}/goal_tiers/${tierId}/`,
      tier,
    );
  } else {
    return await effects.api.put(
      `/api/v0/merchants/goals/${bonusId}/goal_tiers/${tierId}/`,
      tier,
    );
  }
};
