import { CheckIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { Icon } from '@frontend/shared-ui-kit/icon';
import { ListGroup } from '@frontend/shared-ui-kit/list-group';
import { Slider } from '@frontend/shared-ui-kit/slider';
import React, { useMemo } from 'react';
import {
  PermissionRoles,
  RoleNames,
  useActions,
  useAppState,
} from '@frontend/howl-web-app/overmind';
import { useRouter } from 'next/router';
import { alphabetical } from 'radash';
import classNames from 'classnames';
import { ChevronLeft } from 'react-feather';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tooltip } from '@frontend/shared-ui-kit/tooltip';

export const AccountSwitcher = ({
  setToggleAccountSwitcher,
  close,
  setLoading,
  accounts,
  showPortalNavigation,
  showSwitchAccounts,
}: {
  setToggleAccountSwitcher: any;
  close?: any;
  setLoading: any;
  accounts: any[];
  showPortalNavigation?: boolean;
  showSwitchAccounts?: boolean;
}) => {
  const router = useRouter();
  const { brandAllSitesMode } = useFlags();

  const { setSelectedOrg, clearOrgs } = useActions().organization;
  const { sessionData } = useAppState().session;
  const { accessList } = useAppState().auth;

  const { selectedOrgType, selectedOrgId, allSites, selectedOrg, orgs } =
    useAppState().organization;

  const toggleAllSites = async () => {
    // Most of the brand dashboard is locked down for all-sites view
    // So we want to redirect the user to the analytics page when enabled (if they aren't there already)
    if (
      selectedOrgType === 'merchants' &&
      !allSites &&
      !router.pathname.includes('/analytics')
    ) {
      router.push(`/merchant/${selectedOrgId}/analytics/overview`);
    }
    setSelectedOrg({ selectedOrg: selectedOrg, allSites: !allSites });
  };

  const handleOrgSelect = (org: any) => {
    const selectedOrg = {
      type: org.pub_id ? 'publisher' : 'merchant',
      orgId: org.pub_id ? org.pub_id : org.merch_id,
      path: org.pub_id ? 'dashboard' : 'desk',
    };
    if (org.merch_id) {
      const selOrg = orgs.find((o) => o.merch_id === parseInt(org.merch_id));
      if (selOrg) setSelectedOrg({ selectedOrg: selOrg, allSites: false });
    }
    setLoading(true);
    router.push(
      `/${selectedOrg.type}/${selectedOrg.orgId}/${selectedOrg.path}`,
    );
    clearOrgs();

    if (close) {
      close();
    }
  };

  const showCheckIcon = (org: any) => {
    if (selectedOrgType === 'publishers' && selectedOrgId === org.pub_id) {
      return (
        <div className="ml-4">
          <CheckIcon className="w-4 h-4" />
        </div>
      );
    }

    if (selectedOrgType === 'merchants' && selectedOrgId === org.merch_id) {
      return (
        <div className="ml-4">
          <CheckIcon className="w-4 h-4" />
        </div>
      );
    }

    return null;
  };
  const orgElements =
    accounts &&
    alphabetical(accounts, (account) => account.org_name).map((org, idx) => {
      return (
        <ListGroup.Item
          onClick={() => handleOrgSelect(org)}
          key={idx}
          className={'min-h-8'}
          containerClassName={classNames(
            'transition !px-4 flex items-start text-sm',
            {
              'hover:bg-ice-10 focus:bg-ice-100': !showPortalNavigation,
              'hover:bg-primary-10 hover:text-primary-400 font-medium rounded-xl mx-2':
                showPortalNavigation,
            },
          )}
          rightContent={showCheckIcon(org)}
        >
          <div className="h-8 overflow-hidden text-ellipsis flex items-center">
            {org.org_name}
          </div>
        </ListGroup.Item>
      );
    });

  const allSitesCondition = useMemo(() => {
    if (
      sessionData.isAdmin ||
      !['publishers', 'merchants'].includes(sessionData.userRole) ||
      (sessionData.userRole === 'merchants' && !brandAllSitesMode) ||
      !accessList
    )
      return false;

    if (sessionData.userRole === 'publishers') {
      const allowedRoles: PermissionRoles[] = [
        RoleNames.BILLING_ADMIN,
        RoleNames.ACCOUNT_OWNER,
        RoleNames.TEAM_ADMIN,
      ];
      const pubCount = accessList.filter(
        (org) => org.type === 'publishers' && allowedRoles.includes(org.role),
      ).length;
      return pubCount > 1;
    } else {
      const merchCount = accessList.filter(
        (org) => org.type === 'merchants',
      ).length;
      return merchCount > 1;
    }
  }, [sessionData.isAdmin, sessionData.userRole, accessList]);

  return (
    <div className="w-1/2" data-cy="nav-dropdown-column-organization">
      <div className={classNames('w-full')}>
        <div
          className={classNames({
            'flex items-center': showPortalNavigation,
          })}
        >
          <button
            className={classNames(
              'w-full px-4 text-center relative font-medium text-sm h-11',
              {
                'flex items-center': showPortalNavigation,
              },
            )}
            tabIndex={-1}
            onClick={() => setToggleAccountSwitcher(false)}
            data-cy="nav-switch-account-close"
          >
            <span className={'mr-2 float-left'}>
              <ChevronLeft className="w-4 h-4" />
            </span>
            <div
              className={classNames({
                'hover:underline': showPortalNavigation,
              })}
            >
              Back
            </div>
          </button>
        </div>
        {showSwitchAccounts ? (
          <div className="overflow-x-clip relative h-auto">
            <ListGroup className="max-h-48 overflow-y-scroll">
              {orgElements}
            </ListGroup>
            {allSitesCondition && (
              <div className="w-full border-t">
                <div className="flex px-4 items-center relative font-medium text-sm space-x-4 mx-2 h-12">
                  <p className="min-w-max">All Sites</p>
                  <div className="flex items-center justify-between w-full">
                    <Tooltip label="Toggle an aggregated analytics view of all sites.">
                      <Icon iconName="portalInfo" className="mr-4" />
                    </Tooltip>

                    <Slider
                      onClick={toggleAllSites}
                      selected={allSites}
                    ></Slider>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
