export type AnalyticsParams = {
  date_from?: string;
  date_to?: string;
  compare_from?: string;
  compare_to?: string;
  order_by?: string | null;
  direction?: 'desc' | 'asc';
  page?: number;
  per_page?: number;
  pub_ids?: string | null;
  strategies?: string | null;
  strategy_groups?: string | null;
  granularity?: string;
  networks?: string;
  group_by?: string | null;
  edit_id?: string | null;
  auction_id?: string;
  product_ids?: string;
  columns?: string | null;
  merchant_product_categories?: string | null;
  strategy_group_type?: string | null;
  product_brand?: string | null;
  product_category?: string | null;
  breadcrumb?: string | null;
  search_query?: string | null;
  strategy_types?: string | null;
  display_ios?: boolean;
  strategy_status?: string | null;
  strategy_group_status?: string | null;
  merch_ids?: string | null;
};

export type AnalyticsState = {
  tableData?: any;
  subRowData?: any;
  tableInfo?: { total_items: number; total_pages: number };
  totalsData?: any;
  graphData?: any;
  graphTicks?: any[];
  graphRows?: any[];
  rechartsGraphData?: any;
  topBarData?: any;
  tableIsLoading?: boolean;
  graphIsLoading?: boolean;
  showGraph?: boolean;
  showYoy?: boolean;
  compareYoy?: boolean;
  params?: AnalyticsParams;
  graphParams?: {
    date_from?: string;
    date_to?: string;
    compare_date_from?: string;
    compare_date_to?: string;
  };
  selectedColumns?: any;
  selectedPubs?: { label: string; value: any }[];
  selectedStrategies?: { label: string; value: any }[];
  strategyOptions?: { label: string; value: any }[];
  selectedStrategyGroups?: { label: string; value: any }[];
  selectedRows?: any;
  selectedGranularity?: { label: string; value: string };
  selectedColors?: { strokeColors: any; textColors: any; checkboxColors: any };
  selectedGroupBy?: { label?: string; value?: string };
  selectedStrategyGroupTypes?: { label: string; value: string }[];
  selectedStatusFilter?: { label: string; value: string }[];
  selectedObjective?: { label: string; value: string }[];
  newSelectedGroupBy?: { label: string; value: string }[];
  chartType?: string;
  category?:
    | ''
    | 'overview'
    | 'creators'
    | 'recommendations'
    | 'posts'
    | 'products'
    | 'product-clicked'
    | 'categories'
    | 'secondary-attribution'
    | 'campaigns-overview'
    | 'ios'
    | 'campaigns';
  merchantId?: string;
  merchantCategories?: { label: string; value: string }[];
  merchantBrands?: { label: string; value: string }[];
  selectedMerchantCategories?: { label: string; value: string }[];
  selectedMerchantBrands?: { label: string; value: string }[];
  reportIsDownloading?: boolean;
  filteredPubName?: string;
  hasOneProductCategory?: boolean;
  graphYoY?: boolean;
  unprocessedBarData?: any[];
  searchQuery?: string | null;
  selectedStrategyStatus?: { label: string; value: string }[];
  crumbData?: any;
};

type State = {
  analyticsView: AnalyticsState;
  merchantStrategies: any[];
  merchantStrategyGroups: any[];
};

export const state: State = {
  analyticsView: {
    tableData: [],
    subRowData: {},
    tableInfo: { total_items: 0, total_pages: 0 },
    totalsData: {},
    graphData: [],
    rechartsGraphData: [],
    graphTicks: [],
    graphRows: [],
    topBarData: {},
    tableIsLoading: true,
    graphIsLoading: true,
    showGraph: true,
    showYoy: false,
    compareYoy: false,
    params: {
      date_from: '',
      date_to: '',
      compare_from: '',
      compare_to: '',
      order_by: null,
      direction: 'desc',
      page: 1,
      per_page: 20,
      pub_ids: null,
      edit_id: null,
      strategies: null,
      strategy_groups: null,
      granularity: 'daily',
      networks: 'narrativ',
      group_by: null,
      columns: null,
      merchant_product_categories: null,
      strategy_group_type: null,
      product_brand: null,
      product_category: null,
      breadcrumb: null,
      search_query: null,
      strategy_types: null,
      display_ios: true,
      strategy_status: null,
      strategy_group_status: null,
      merch_ids: null,
    },
    graphParams: {
      date_from: '',
      date_to: '',
    },
    selectedPubs: [],
    selectedStrategies: [],
    strategyOptions: [],
    selectedStrategyGroups: [],
    selectedColumns: [],
    selectedRows: {},
    selectedGranularity: { label: 'Daily', value: 'daily' },
    selectedGroupBy: { label: 'Product Name', value: 'brand_product_name' },
    selectedColors: { strokeColors: [], textColors: [], checkboxColors: [] },
    selectedStrategyGroupTypes: [],
    selectedStatusFilter: [{ label: 'Live', value: '1' }],
    selectedObjective: [],
    selectedStrategyStatus: [{ label: 'Live', value: '1' }],
    chartType: 'clicks',
    category: '',
    merchantId: '',
    selectedMerchantCategories: [],
    selectedMerchantBrands: [],
    merchantBrands: [],
    merchantCategories: [],
    newSelectedGroupBy: [],
    reportIsDownloading: false,
    filteredPubName: '',
    graphYoY: false,
    unprocessedBarData: [],
    searchQuery: null,
    crumbData: {},
  },
  merchantStrategies: [],
  merchantStrategyGroups: [],
};
