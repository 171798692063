import { Context } from '..';
import {
  sortAlphabetical,
  calculatePagination,
  getStatsFromCache,
  invalidPage,
  orderParams,
} from '@frontend/shared-utils';

export const getMerchants = (context: Context) => {
  return context.effects.api.get('/api/v0/merchants/').then((resp: any) => {
    context.state.search.merchants = [];
    const merchSlugs = context.state.search.merchSlugIds.map(
      (slugId: any) => slugId.slug,
    );
    resp.data.data.forEach((merchant: any) => {
      if (merchSlugs.includes(merchant.slug) && merchant.show_in_dashboard) {
        merchant.label = merchant.full_name;
        context.state.search.merchants.push(merchant);
      }
    });

    return sortAlphabetical(context.state.search.merchants, 'full_name');
  }); // TODO: Error response
};

export const getSearchTrends = (
  context: Context,
  {
    pubId,
    params,
    pageNum,
    perPage,
    resetPages = false,
  }: {
    pubId: any;
    params: any;
    pageNum: number;
    perPage: number;
    resetPages: boolean;
  },
) => {
  if (!context.state.search.trendsByMerch[params.merch_ids]) {
    context.state.search.trendsByMerch[params.merch_ids] = {
      pages: {},
      last_query_params: {},
    };
  }

  if (resetPages) {
    context.state.search.trendsByMerch[params.merch_ids].pages = {};
  }

  const stats = context.state.search.trendsByMerch[params.merch_ids];
  const totalTerms = stats ? stats.total_items || 0 : 0;
  [
    context.state.search.page,
    context.state.search.indexStart,
    context.state.search.indexEnd,
  ] = calculatePagination(pageNum, perPage, context.state.search.limit);

  if (
    invalidPage(
      context.state.search.page,
      totalTerms,
      context.state.search.limit,
    )
  ) {
    return Promise.resolve('The page requested does not exist');
  }

  params.page = context.state.search.page;
  params.per_page = context.state.search.limit;
  if (
    stats.pages[context.state.search.page] &&
    context.actions.search.isSameQuery({
      params,
      previousParams: stats.last_query_params,
    })
  ) {
    return getStatsFromCache(
      stats,
      context.state.search.page,
      context.state.search.indexStart,
      context.state.search.indexEnd,
    );
  }

  return context.actions.search.querySearchTrends({ pubId, params });
};

export const querySearchTrends = (
  context: Context,
  { pubId, params }: { pubId: any; params: any },
) => {
  if (params.search_term === '') params.search_term = null;

  const paramKeys = [
    'date_from',
    'date_to',
    'page',
    'per_page',
    'merch_ids',
    'search_term',
    'order_by',
    'direction',
  ];
  params = orderParams(params, paramKeys);

  return context.effects.api
    .get(`/api/v0/publishers/${pubId}/searches/`, params)
    .then(
      (resp: any) =>
        context.actions.search.onSearchTrendsLoaded({ resp, params }),
      // TODO: add error modal () => onError(),
    );
};

export const onSearchTrendsLoaded = (
  context: Context,
  { resp, params }: { resp: any; params: any },
) => {
  const merchIds = params.merch_ids;
  const data = resp.data.data.length ? resp.data.data[0] : [];
  context.state.search.trendsByMerch[merchIds].pages[
    context.state.search.page
  ] = data.stats;

  Object.assign(context.state.search.trendsByMerch[merchIds], {
    total_items: data.page_info.total_items,
    total_searches: data.page_info.total_searches,
    last_query_params: { ...params },
  });

  return getStatsFromCache(
    context.state.search.trendsByMerch[merchIds],
    context.state.search.page,
    context.state.search.indexStart,
    context.state.search.indexEnd,
  );
};

/* Helper functions */
export const isSameQuery = (
  context: Context,
  { params, previousParams }: { params: any; previousParams: any },
) => {
  return (
    params.search_term === previousParams.search_term &&
    params.order_by === previousParams.order_by &&
    params.direction === previousParams.direction
  );
};
