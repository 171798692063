import { cloneDeep } from 'lodash';
import { Context } from '..';
import {
  calculatePagination,
  invalidPage,
  sortAlphabetical,
} from '@frontend/shared-utils';

import { NetworkObject, QueryParams, Stats, StatCache, COLUMNS } from './state';

export const setBreadcrumbs = (context: Context, breadcrumbs: any[]) => {
  context.state.megatable.breadcrumbs = breadcrumbs;
};

export const setBreadcrumbClicked = (context: Context, clicked: boolean) => {
  context.state.megatable.breadcrumbClicked = clicked;
};

export const getStats = (
  context: Context,
  {
    orgId,
    statType,
    pageNum,
    perPage,
    params,
    resetSearch,
  }: {
    orgId: any;
    statType: any;
    pageNum: any;
    perPage: any;
    params: any;
    resetSearch?: any;
  },
) => {
  const statCache = context.actions.megatable.getStatCache({
    statType,
    resetSearch,
    isStatCache: true,
  });

  const limit =
    statType === 'merchantTopPublisher'
      ? context.state.megatable.reducedLimit
      : context.state.megatable.limit;

  [
    context.state.megatable.page,
    context.state.megatable.indexStart,
    context.state.megatable.indexEnd,
  ] = calculatePagination(pageNum, perPage, limit);

  if (invalidPage(context.state.megatable.page, statCache.total_items, limit)) {
    return Promise.resolve('The page requested does not exist');
  }

  if (statCache.pages[context.state.megatable.page]) {
    return context.actions.megatable.sliceMegatableData(statCache);
  }

  context.actions.publisherDashboard.checkAllSites({ params });
  params.page = context.state.megatable.page;
  params.per_page = limit;
  params = context.actions.megatable.orderParams(params);

  return context.actions.megatable
    .queryPublisherStats({ statType, orgId, params })
    .then(() => {
      return context.actions.megatable.sliceMegatableData(statCache);
    });
};

export const queryPublisherStats = (
  context: Context,
  { statType, orgId, params }: { statType: any; orgId: any; params: any },
) => {
  const endpoint = context.actions.megatable.getStatEndpoint({
    orgId,
    statType,
  });

  return context.effects.api.get(endpoint, params).then((resp: any) => {
    const data = resp.data.data[0];
    const statCache = context.state.megatable.statCacheKeyedOnType[statType];

    statCache.pages[context.state.megatable.page] = data.stats;
    statCache.total_items = data.page_info.total_items;
  });
};

export const loadStatTotals = (
  context: Context,
  { pubId, statType, params }: { pubId: any; statType: any; params: any },
) => {
  const statCache = context.state.megatable.statCacheKeyedOnType[statType];
  if (statCache && statCache.totals) {
    return Promise.resolve(statCache.totals);
  }
  if (params.search_query) return Promise.resolve([]);

  const endpoint = context.actions.megatable.getStatEndpoint({
    orgId: pubId,
    statType,
    isTotal: true,
  });
  params.page = 1;
  params.per_page =
    statType === 'merchantTopPublisher'
      ? context.state.megatable.reducedLimit
      : context.state.megatable.limit;
  context.actions.publisherDashboard.checkAllSites({ params });
  params = context.actions.megatable.orderParams(params);

  return context.effects.api.get(endpoint, params).then((resp: any) => {
    // New pattern on BE for Totals sends just a row-dict back, not an array with a single row-dict
    const data = resp.data.data[0].stats;
    statCache.totals = statType === 'merchantTopPublisher' ? data : data[0];
    return statCache.totals;
  });
};

// TODO: Deprecate after switching over overview-table to getOrgPartners()
export const getMerchants = (context: Context) => {
  if (context.state.megatable.merchants.length > 0) {
    return Promise.resolve(context.state.megatable.merchants);
  }

  return context.effects.api
    .get('/api/v0/merchants/')
    .then((resp: any) => {
      /*
       * TODO: legacy endpoint doesn't allow easy filtering, refactor to params after
       * backend filters are implemented.
       */
      const merchants = resp.data.data
        .filter((merchant: any) => {
          return merchant.show_in_dashboard;
        })
        .map((merchant: any) => {
          return {
            ...merchant,
            label: merchant.full_name,
            selected: true, // All merchants selected by default
          };
        });

      context.state.megatable.merchants = sortAlphabetical(
        merchants,
        'full_name',
      );
      return context.state.megatable.merchants;
    })
    .catch(() => []);
};

export const getOrgPartners = (context: Context, orgType: any) => {
  if ((context.state.megatable as any)[orgType].length > 0) {
    return Promise.resolve((context.state.megatable as any)[orgType]);
  }

  return context.effects.api
    .get(`/api/v0/${orgType}/`)
    .then((resp: any) => {
      const partners = resp.data.data
        .filter((partner: any) => {
          return partner.show_in_dashboard;
        })
        .map((partner: any) => {
          return { ...partner, label: partner.full_name, selected: true };
        });

      (context.state.megatable as any)[orgType] = sortAlphabetical(
        partners,
        'full_name',
      );
      return (context.state.megatable as any)[orgType];
    })
    .catch(() => []);
};

/* Helper function */
export const orderParams = (context: Context, params: any[]) => {
  const orderedParams: any = {};
  context.state.megatable.orderedKeys.forEach((key) => {
    if ((params as any)[key]) {
      orderedParams[key] = (params as any)[key];
    }
  });

  return orderedParams;
};

export const getQueryParams = (
  context: Context,
  {
    urlParams,
    oldState,
    statType,
  }: { urlParams: any; oldState: any; statType: any },
) => {
  // Translate params from query string into params for the state
  /*
   * newParams: contains lower-level params (paramKeys)
   * that have the same value in state and query string.
   * page, perPage, networks are handled on the top level bc
   * their values differ btwn state (objects) and request params (strings).
   */
  let newParams = context.actions.megatable.getParamsFromQueryString({
    urlParams,
    statType,
  });
  newParams = context.actions.megatable.getParamsFromSession(newParams);

  const newNetworks = context.actions.megatable.getNetworksFromString({
    networkString: urlParams.get('networks'),
    currentNetworks: cloneDeep(oldState.networks),
    statType,
  });
  const newGranularities = context.actions.megatable.getGranularitiesFromString(
    {
      granularityString: urlParams.get('granularity'),
      currentGranularities: cloneDeep(oldState.granularities),
    },
  );
  const newMerchants = context.actions.megatable.getPartnersFromString({
    partnerIdString: urlParams.get('merch_ids'),
    currentPartners: cloneDeep(oldState.merchants),
    idString: 'merch_id',
  });
  const newPublishers = context.actions.megatable.getPartnersFromString({
    partnerIdString: urlParams.get('pub_ids'),
    currentPartners: cloneDeep(oldState.publishers),
    idString: 'pub_id',
  });
  const page = parseInt(urlParams.get('page')) || oldState.page;
  const perPage = parseInt(urlParams.get('per_page')) || oldState.perPage;

  return [
    newParams,
    page,
    perPage,
    newNetworks,
    newGranularities,
    newMerchants,
    newPublishers,
  ];
};

export const getParamsFromQueryString = (
  context: Context,
  { urlParams, statType }: { urlParams: any; statType: any },
) => {
  const newParams: any = {};

  const paramKeys = [
    'date_from',
    'date_to',
    'order_by',
    'direction',
    'search_query',
    'granularity',
    'graphed_ids',
  ];

  if (statType === 'article') {
    paramKeys.push('edit_ids');
  } else if (statType === 'smartlink') {
    paramKeys.push('edit_id');
  } else if (statType === 'product') {
    paramKeys.push('auction_id');
  }

  paramKeys.forEach((key) => {
    let value = urlParams.get(key);

    if (key === 'edit_id') value = parseInt(value);

    if (value && value !== 'null') {
      newParams[key] = value;
    } else if (!value && key === 'search_query') {
      newParams.search_query = null;
    }
  });

  return newParams;
};

export const getParamsFromSession = (
  context: Context,
  queryParams: QueryParams,
) => {
  /*
   * If these params are not specified in the URL, we pull them from session.
   * This way they can be retained until the user signs out.
   */
  const sessionKeys = ['date_from', 'date_to'];

  sessionKeys.forEach((key) => {
    const { dashboardParams } = context.state.session.sessionData;

    if (!(queryParams as any)[key] && dashboardParams && dashboardParams[key]) {
      (queryParams as any)[key] = dashboardParams[key];
    }
  });

  return queryParams;
};

export const getNetworksFromString = (
  context: Context,
  {
    networkString,
    currentNetworks,
    statType,
  }: { networkString: any; currentNetworks: any; statType: any },
) => {
  if (!networkString) return null;

  const newNetworks = currentNetworks.map((network: any) => {
    // Currently backend doesn't support networks for product stats query
    if (statType === 'product') {
      network.selected = false;
    } else {
      network.selected = networkString.split(',').includes(network.name);
    }
    return network;
  });

  return newNetworks;
};

export const getGranularitiesFromString = (
  context: Context,
  {
    granularityString,
    currentGranularities,
  }: { granularityString: any; currentGranularities: any },
) => {
  if (!granularityString) return null;

  const newGranularities = currentGranularities.map((granularity: any) => {
    granularity.selected = granularity.name === granularityString;
    return granularity;
  });

  return newGranularities;
};

export const getPartnersFromString = (
  context: Context,
  {
    partnerIdString,
    currentPartners,
    idString,
  }: { partnerIdString: any; currentPartners: any; idString: any },
) => {
  if (!partnerIdString) return null;

  const partnerIdList = partnerIdString
    .split(',')
    .map((partnerId: any) => parseInt(partnerId));
  return currentPartners.map((partner: any) => {
    partner.selected = partnerIdList.includes(partner[idString]);
    return partner;
  });
};

export const getNetworkString = (
  context: Context,
  networkObjects: NetworkObject[],
) => {
  // Convert an array of networks to comma separated string `narrativ,rakuten`
  return networkObjects
    .filter((network) => network.selected)
    .map((network) => network.name)
    .join(',');
};

export const getPartnerIds = (
  context: Context,
  { partners, idPrefix }: { partners: any; idPrefix: any },
) => {
  // Convert array of partners to comma separated list of pub/merch IDs
  return partners
    .filter((partner: any) => partner.selected)
    .map((partner: any) => partner[`${idPrefix}_id`])
    .join(',');
};

export const getRequestParams = (
  context: Context,
  {
    state,
    statType,
    isTotal,
    partnerType,
  }: { state: any; statType: any; isTotal: any; partnerType: any },
) => {
  // Translate the state params into params for the API request
  const params = cloneDeep(state.params);
  const merchantStatTables = ['merchantArticle', 'merchantRecommendation'];

  const networkStr = context.actions.megatable.getNetworkString(state.networks);
  if (networkStr) params.networks = networkStr;

  const idLabel = partnerType === 'merchants' ? 'merch_ids' : 'pub_ids';
  const allPartners = state[partnerType];
  const partnerIds = context.actions.megatable.getPartnerIds({
    partners: allPartners,
    idPrefix: idLabel.split('_')[0],
  });
  const allPartnersSelected =
    allPartners.length ===
    allPartners.filter((partner: any) => partner.selected).length;

  // If all partners are selected and we're on Top-Pubs page, send no ids instead of all ids
  // TODO: Refactor all tables to utilize this for optimization
  if (
    partnerIds.length &&
    !(allPartnersSelected && statType === 'merchantTopPublisher')
  ) {
    params[idLabel] = partnerIds;
  }

  if (isTotal) {
    delete params.direction;
    delete params.order_by;

    if (statType === 'product') {
      params.is_total = true;
    } else {
      params.group_by =
        statType === 'merchantTopPublisher'
          ? null
          : merchantStatTables.includes(statType)
          ? 'merch_id'
          : 'pub_id';
    }
  }

  if (statType === 'product') {
    params.no_returns = true;
  }

  if (merchantStatTables.includes(statType)) {
    delete params.networks;
  }

  // If a param is null, remove it from the API request params
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === 'null') {
      delete params[key];
    }
  });

  return params;
};

export const getStatEndpoint = (
  context: Context,
  { orgId, statType, isTotal }: { orgId: any; statType: any; isTotal?: any },
) => {
  const endpointMap: any = {
    article: `/api/v0/publishers${orgId ? `/${orgId}` : ''}/stats_by_edit/`,
    article_graph: `/api/v0/publishers/${orgId}/edit_stats_by_date/`,
    merchant: `/api/v0/publishers/${orgId}/stats_by_merchant/`,
    merchantArticle: `/api/v0/merchants/${orgId}/merch_ed/stats_by_edit/`,
    merchantRecommendation: `/api/v0/merchants/${orgId}/merch_ed/stats_by_recommendation/`,
    merchantArticle_graph: `/api/v0/merchants/${orgId}/edit_stats_by_date/`,
    merchantRecommendation_graph: `/api/v0/merchants/${orgId}/recommendation_stats_by_date/`,
    merchantTopPublisher: `/api/v0/merchants/${orgId}/${
      isTotal ? 'aggregated_' : ''
    }pubs_performance/`,
    merchant_epc: `/api/v0/publishers/${orgId}/stats_by_merchant/`,
    merchant_clicks: `/api/v0/publishers/${orgId}/stats_by_merchant/`,
    product: `/api/v0/publishers/${orgId}/stats_by_product/`,
    smartlink: `/api/v0/publishers/${orgId}/stats_by_bam_link/`,
  };
  return endpointMap[statType];
};

export const getStatCache = (
  context: Context,
  {
    statType,
    resetSearch,
    isStatCache = false,
  }: { statType: any; resetSearch: any; isStatCache?: any },
) => {
  if (resetSearch || !context.state.megatable.statCacheKeyedOnType[statType]) {
    context.state.megatable.statCacheKeyedOnType[statType] = isStatCache
      ? new StatCache()
      : {};
  }
  return context.state.megatable.statCacheKeyedOnType[statType];
};

export const sliceMegatableData = (context: Context, stats: Stats) => {
  if (!stats.pages[context.state.megatable.page])
    return Promise.resolve({ stats: [] });

  return Promise.resolve({
    stats: stats.pages[context.state.megatable.page].slice(
      context.state.megatable.indexStart,
      context.state.megatable.indexEnd,
    ),
    totalItems: stats.total_items,
  });
};

/* Megatable Graph functions */
export const queryEditStats = (
  context: Context,
  {
    pubId,
    statType,
    params,
    resetSearch,
  }: { pubId: any; statType: any; params: any; resetSearch: any },
) => {
  // Need to handle multiple row identifiers now that we graph more than just stories
  // edit_id: Stories tables
  // auction_id: Recommendations tables
  const identifier =
    statType === 'merchantRecommendation_graph' ? 'auction_id' : 'edit_id';

  const { granularity } = params;
  const rowId = params[identifier];
  const statCache = context.actions.megatable.getStatCache({
    statType,
    resetSearch,
  });

  if (!statCache[rowId]) {
    statCache[rowId] = {};
  }

  if (statCache[rowId][granularity]) {
    return Promise.resolve(statCache[rowId][granularity]);
  }

  const endpoint = context.actions.megatable.getStatEndpoint({
    orgId: pubId,
    statType,
  });
  context.actions.publisherDashboard.checkAllSites({ params });
  params = context.actions.megatable.orderParams(params);

  return context.effects.api.get(endpoint, params).then((resp: any) => {
    const data = resp.data.data[0].stats;
    if (!context.state.megatable.statCacheKeyedOnType[statType][rowId]) {
      context.state.megatable.statCacheKeyedOnType[statType][rowId] = {};
    }
    context.state.megatable.statCacheKeyedOnType[statType][rowId][
      params.granularity
    ] = data;

    return Promise.resolve(data);
  });
};

// Query specific edits when graphed rows aren't in stats
export const queryGraphedRows = (
  context: Context,
  {
    pubId,
    statType,
    params,
    resetSearch,
  }: { pubId: any; statType: any; params: any; resetSearch: any },
) => {
  const statCacheType = `${statType}_graph_rows`;
  const statCache = context.actions.megatable.getStatCache({
    statType: statCacheType,
    resetSearch,
  });
  const endpoint = context.actions.megatable.getStatEndpoint({
    orgId: pubId,
    statType,
  });

  // Need to handle multiple identifiers now that we graph more than just stories
  const identifier =
    statType === 'merchantRecommendation' ? 'auction_id' : 'edit_id';

  const graphedRowData: any = [];
  const missingRowIds: any = [];
  const idList = params[`${identifier}s`].split(',');

  if (!resetSearch) {
    idList.forEach((editId: any) => {
      if (statCache[editId]) {
        graphedRowData.push(statCache[editId]);
      } else {
        missingRowIds.push(editId);
      }
    });
    // Generate comma-separated string of remaining edit IDs not in stat cache
    if (!missingRowIds.length) {
      return Promise.resolve(graphedRowData);
    }
    params[`${identifier}s`] = missingRowIds.join(',');
  }

  params = context.actions.megatable.orderParams(params);

  return context.effects.api.get(endpoint, params).then((resp: any) => {
    const data = resp.data.data[0].stats;
    data.forEach((edit: any) => {
      statCache[edit[identifier]] = edit;
      graphedRowData.push(edit);
    });
    return Promise.resolve(graphedRowData);
  });
};

/* Report functions */
export const getStatsReportPayload = (
  context: Context,
  {
    orgId,
    orgType,
    statType,
    columns,
    reportOptions,
    queryParams,
    reportVersion,
    reportEndpoint,
  }: {
    orgId: any;
    orgType: any;
    statType: any;
    columns: any;
    reportOptions: any;
    queryParams: any;
    reportVersion: any;
    reportEndpoint: any;
  },
) => {
  const { allSites } = context.state.organization;

  return context.effects.api
    .get(`/api/v0/${orgType}/${orgId}/`)
    .then((resp: any) => {
      const { slug } = resp.data.data[0];
      let payload: any = {
        date_from: queryParams.date_from,
        date_to: queryParams.date_to,
      };

      if (reportOptions.reportType === 'visible') {
        payload.page = reportOptions.currentPage;
        payload.per_page = reportOptions.perPage;
      }
      if (reportOptions.perPage) {
        payload.per_page = reportOptions.perPage;
        queryParams.per_page = reportOptions.perPage;
      }

      const { reportParams, ...otherParams } = queryParams;

      if (reportVersion === 'V2') {
        payload = {
          ...payload,
          is_report: true,
          report_params: {
            slug,
            report_type: statType,
            endpoint: reportEndpoint,
            columns: columns.join(','),
            ...reportParams,
          },
          ...otherParams,
        };
      } else {
        payload = {
          ...payload,
          slug,
          columns: columns.join(','),
          report_type: context.state.megatable.reportStatTypes[statType],
          order_by: queryParams.order_by,
          direction: queryParams.direction,
          networks: queryParams.networks,
        };

        if (queryParams.edit_id) payload.edit_id = queryParams.edit_id;
        if (queryParams.search_query)
          payload.search_query = queryParams.search_query;
        if (queryParams.edit_ids) payload.edit_ids = queryParams.edit_ids;
        if (queryParams.auction_id) payload.auction_id = queryParams.auction_id;
        if (statType === 'merchantArticleSearch')
          payload.type = queryParams.type;
        if ('is_winning' in queryParams)
          payload.is_winning = queryParams.is_winning;
        if (['merchantArticle', 'merchantRecommendation'].includes(statType)) {
          payload.merch_ed = true;
        }
        if (allSites) {
          const { accessList } = context.state.auth;
          payload.merch_ids = accessList
            ?.filter((org) => org.type === 'merchants')
            ?.map((org) => org.orgId)
            ?.join(',') as string;
        }
      }
      return Promise.resolve({ orgId, payload });
    });
};

export const generateStatsReport = (
  context: Context,
  {
    orgId,
    statType,
    columns,
    reportOptions,
    queryParams,
    reportVersion,
    reportEndpoint,
  }: {
    orgId: any;
    statType: any;
    columns: any;
    reportOptions: any;
    queryParams: any;
    reportVersion: any;
    reportEndpoint: any;
  },
) => {
  const { allSites } = context.state.organization;
  const { accessList } = context.state.auth;
  const merchantStatTypes = [
    'merchantArticle',
    'merchantArticleSearch',
    'merchantProductsSearch',
    'merchantRecommendation',
    'merchant-performance-overview',
    'merchantTopPublisher',
    'top-five-stories',
    'top-five-pubs',
    'strategy-tab-publishers',
    'strategy-tab-stories',
    'top-pubs',
    'top-stories',
    'strategy-tab-recommendations',
    'strategy-tab-products',
    'campaign-center-strategies-tab',
    'campaign-center-campaigns-tab',
    'strategy-feedback',
    'merchant-secondary-attribution',
    'merchant-overview',
    'ios-analytics',
    'campaigns-analytics',
  ];
  const orgType = merchantStatTypes.includes(statType)
    ? 'merchants'
    : 'publishers';
  const v1Endpoint = allSites
    ? `/api/v0/${orgType}/partner_reports/`
    : `/api/v0/${orgType}/${orgId}/partner_reports/`;
  const v2Endpoint = allSites
    ? `/api/v0/${orgType}/request_partner_report/`
    : `/api/v0/${orgType}/${orgId}/request_partner_report/`;
  return context.actions.megatable
    .getStatsReportPayload({
      orgId,
      orgType,
      statType,
      columns,
      reportOptions,
      queryParams,
      reportVersion,
      reportEndpoint,
    })
    .then((data: any) => {
      const endpoint = reportVersion === 'V2' ? v2Endpoint : v1Endpoint;
      return context.effects.api.post(endpoint, data.payload);
    })
    .then((reportResp: any) => {
      context.state.megatable.reportKey = reportResp.data.data[0].report_key;
      return context.effects.api.get(
        `${v1Endpoint}${context.state.megatable.reportKey}/`,
        {
          merch_ids: allSites
            ? (accessList
                ?.filter((org) => org.type === 'merchants')
                ?.map((org) => org.orgId)
                ?.join(',') as string)
            : null,
        },
      );
    })
    .then((urlResp: any) => {
      const data = {
        report_url: urlResp.data.data[0].report_url,
        report_key: context.state.megatable.reportKey,
        org_type: orgType,
      };
      return Promise.resolve(data);
    });
};

export const generateProductStatsReport = async (
  context: Context,
  {
    params,
    orgId,
    strategy,
    statType,
  }: { params: any; orgId: any; strategy: any; statType: any },
) => {
  const columns = [
    'product_name',
    'product_sku',
    'product_price',
    'is_in_stock',
    'publisher_total_earnings',
    'clicks',
    'publisher_epc',
  ];

  const baseUrl = `/api/v0/publishers/${orgId}/`;
  const getUrl = `${baseUrl}partner_reports/`;
  const postUrl = `${baseUrl}request_partner_report/`;

  const getSlug = await context.effects.api.get(
    `/api/v0/merchants/${strategy.merch_id}/`,
  );

  const slug = `${getSlug.data.data[0].slug}_${strategy.strategy_name
    .toLowerCase()
    .replace(/[^0-9a-zA-Z]+/g, '_')}`;

  const payload: any = {
    admin_stats: context.state.session.sessionData.isAdminView,
    is_report: true,
    date_from: params.date_from,
    date_to: params.date_to,
    strategy_id: strategy.strategy_id,
    report_params: {
      report_type: statType,
      columns,
      endpoint: `${baseUrl}/strategies/${strategy.strategy_id}/products_v2/`,
      slug,
    },
  };

  if (params.is_in_stock) payload.is_in_stock = params.is_in_stock;
  if (params.direction) payload.direction = params.direction;
  if (params.order_by) payload.order_by = params.order_by;

  // Get report key to get report data
  const postResponse = await context.effects.api.post(postUrl, payload);
  const reportKey = postResponse.data.data[0].report_key;
  const reportData = await context.effects.api.get(`${getUrl}${reportKey}/`);

  const data = {
    report_url: reportData.data.data[0].report_url,
    report_key: reportKey,
    org_type: 'publishers',
  };
  return Promise.resolve(data);
};

export const checkReportReady = (
  context: Context,
  {
    reportUrl,
    reportKey,
    orgId,
    orgType,
  }: {
    reportUrl: any;
    reportKey: any;
    orgId: any;
    orgType: any;
  },
) => {
  const { allSites } = context.state.organization;
  const { accessList } = context.state.auth;
  const statusCheckUrl = allSites
    ? `/api/v0/${orgType}/partner_reports/${reportKey}/status/`
    : `/api/v0/${orgType}/${orgId}/partner_reports/${reportKey}/status/`;
  return new Promise((resolve, reject) => {
    const pollUrl = window.setInterval(() => {
      context.effects.api
        .get(
          statusCheckUrl,
          {
            merch_ids: allSites
              ? (accessList
                  ?.filter((org) => org.type === 'merchants')
                  ?.map((org) => org.orgId)
                  ?.join(',') as string)
              : null,
          },
          { headers: { Range: 'bytes=0-0' } },
        )
        .then((response: any) => {
          if (response.status === 200) {
            window.clearInterval(pollUrl);
            window.clearTimeout(reportTimeout);
            resolve(
              context.actions.megatable.triggerReportDownload({
                reportUrl,
              }),
            );
          }
        })
        .catch(() => {
          // Empty error handler to avoid 404 errors in console
        });
    }, 5000);

    const reportTimeout = window.setTimeout(() => {
      window.clearInterval(pollUrl);
      // TODO: invoke ModalService when implemented
      reject('Something went wrong while generating report');
    }, 60000);
  });
};

export const triggerReportDownload = (
  context: Context,
  { reportUrl }: { reportUrl: any },
) => {
  const { navigator } = window;

  // If in Chrome or Safari - download via virtual link click
  const isChromeOrSafari =
    (/Chrome/.test(navigator.userAgent) &&
      /Google Inc/.test(navigator.vendor)) ||
    (/Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor));

  if (isChromeOrSafari) {
    const el = document.createElement('a');
    el.setAttribute('href', reportUrl);
    el.style.display = 'none';

    /*
     * Some Chromium browsers like Chrome on IOS don't support downloads,
     * check if download property is available on element.
     */
    if (el.download !== undefined) {
      el.setAttribute('download', `${context.state.megatable.reportKey}.tsv`);
    } else {
      window.open(reportUrl);
    }

    /*
     * Add anchor el to DOM, download property tells the browser that a click
     * should trigger a download from the href. Append to header to avoid reopening
     * the reports dropdown when download is complete
     */
    const megatableHeader =
      document.querySelector('.table-container') ||
      document.querySelector('.table-container-v2');

    if (megatableHeader) megatableHeader.appendChild(el);
    el.click();
    if (megatableHeader) megatableHeader.removeChild(el);
  } else {
    window.open(reportUrl);
  }
  return Promise.resolve();
};

export const getColumnsForTable = (
  context: Context,
  {
    statType,
    isOrgAdmin = true,
    flags = null,
  }: { statType: string; isOrgAdmin: any; flags?: any },
) => {
  let columns: any;
  const staticColumns = (COLUMNS.staticColumnsByStatType as any)[statType];
  if (statType === 'product') {
    columns = staticColumns.concat(COLUMNS.productStatColumns);
  } else if (statType === 'merchant') {
    columns = staticColumns.concat(
      COLUMNS.statColumns,
      COLUMNS.optionalColumns.slice(1),
      COLUMNS.merchantStatColumns,
    );
  } else if (statType === 'merchantArticle') {
    columns = staticColumns.concat(
      COLUMNS.merchantArticleColumns,
      COLUMNS.optionalMerchantArticleColumns,
    );
  } else if (statType === 'merchantArticleSearch') {
    columns = COLUMNS.merchantArticleSearchFields;
  } else if (statType === 'merchantProductsSearch') {
    columns = COLUMNS.merchantProductsSearchFields;
  } else if (statType === 'merchantRecommendation') {
    columns = COLUMNS.merchantRecommendationColumns.concat(
      COLUMNS.optionalMerchantRecommendationColumns,
    );
  } else if (statType === 'merchant-performance-overview') {
    columns = COLUMNS.MerchantPerformanceOverviewFields;
  } else if (statType === 'merchantTopPublisher') {
    // Shallow copy to prevent mutation of base fields list
    columns = [...COLUMNS.merchantTopPublisherFields];
    if (flags && flags.bestBuyAdminFlag) {
      columns.splice(2, 0, ...COLUMNS.merchantTopPublisherHiddenFields);
    }
  } else {
    columns = staticColumns.concat(
      COLUMNS.statColumns,
      COLUMNS.optionalColumns,
    );
  }

  if (statType === 'merchant') {
    // Order columns according to merchantDefaultFields
    let orderedColumns: any = [];
    const defaultFields =
      context.actions.megatable.getDefaultFields('merchant');

    defaultFields.forEach((fieldName: any) => {
      const index = columns.findIndex((col: any) => col.name === fieldName);
      orderedColumns = orderedColumns.concat(columns.splice(index, 1));
    });

    columns = orderedColumns.concat(columns);
  }

  if (!isOrgAdmin) {
    COLUMNS.orgAdminOnlyFields.forEach((fieldName) => {
      const index = columns.findIndex((col: any) => col.name === fieldName);
      if (index !== -1) {
        columns.splice(index, 1);
      }
    });
  }
  return columns;
};

export const getDefaultFields = (context: Context, statType: string) => {
  const staticFields = (COLUMNS.staticFieldsByStatType as any)[statType];
  const merchTables = [
    'merchant',
    'merchantArticle',
    'merchantRecommendation',
    'merchantTopPublisher',
  ];
  const defaultFields = !merchTables.includes(statType)
    ? COLUMNS.defaultFields
    : (COLUMNS.merchantDefaultFields as any)[statType];

  // Ensure static fields are at the beginning of the array
  return staticFields.concat(defaultFields);
};

export const getDefaultSortOptions = (context: Context, statType: string) => {
  const columnName =
    statType === 'product'
      ? 'total_attributed_revenue'
      : ['merchantArticle', 'merchantTopPublisher'].includes(statType)
      ? `${statType === 'merchantArticle' ? 'total_' : ''}attributed_revenue`
      : 'clicks';

  return {
    order_by: columnName,
    direction: 'desc',
  };
};

export const getSortDisabledFields = (context: Context, statType: string) => {
  if (statType === 'merchantArticle') {
    return COLUMNS.sortDisabledFields.concat(
      COLUMNS.merchantArticleDisabledFields,
    );
  }
  if (statType === 'merchantRecommendation') {
    return COLUMNS.sortDisabledFields.concat(
      COLUMNS.merchantRecommendationDisabledFields,
    );
  }
  if (statType === 'merchantTopPublisher') {
    return ['pub_name'];
  }
  return COLUMNS.sortDisabledFields;
};

export const setColumnsSelected = (
  context: Context,
  {
    columns,
    statType,
    savedParams,
  }: { columns: any; statType: any; savedParams: any },
) => {
  const savedFields = savedParams && savedParams[`${statType}_columns`];

  if (savedFields) {
    columns.forEach((columnObj: any) => {
      columnObj.selected = savedFields.includes(columnObj.name);
    });
  } else {
    columns = context.actions.megatable.selectDefaultColumns({
      columns,
      statType,
    });
  }

  return columns;
};

export const selectDefaultColumns = (
  context: Context,
  { columns, statType }: { columns: any; statType: any },
) => {
  const defaultFields = context.actions.megatable.getDefaultFields(statType);

  return columns.map((columnObj: any) => {
    columnObj.selected =
      defaultFields.includes(columnObj.name) || statType === 'product';
    return columnObj;
  });
};

export const parseBackendColumns = (context: Context, columns: any) => {
  return columns
    .filter((col: any) => !col.exclude)
    .map((col: any) => {
      return {
        label: col.label,
        name: col.name,
        type: col.type,
        selected: col.default_selected,
        sortDisabled: col.sort_disabled || false,
      };
    });
};
