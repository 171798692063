export type FlatFeeVerificationDetail = {
  flatFeeId: number;
  pubId: number;
  status: string;
  pubName: string;
  statusNote: string | null;
};

type FlatFeeVerification = {
  strategyId: string | null;
  selectedStrategy: any;
  order_by: string | null;
  direction: 'asc' | 'desc' | null;
  selectedDate: { label: string; value: string } | null;
  page: number | null;
  per_page: number | null;
  totalItems: number | null;
  campaigns: any[];
  flatFeeVerifications: any[];
  strategyIsDirty: boolean;
  selectedStatusFilter: { label: string; value: string } | null;
  imageError: string | null;
  searchTerm: string | null;
  flatFeeVerificationDetail: FlatFeeVerificationDetail | null;
  isOverBudget: boolean | null;
};

export const state: FlatFeeVerification = {
  strategyId: null,
  selectedStrategy: null,
  order_by: null,
  direction: null,
  selectedDate: null,
  page: 1,
  per_page: 20,
  totalItems: null,
  campaigns: [],
  flatFeeVerifications: [],
  strategyIsDirty: false,
  selectedStatusFilter: null,
  imageError: null,
  searchTerm: null,
  flatFeeVerificationDetail: null,
  isOverBudget: null,
};
