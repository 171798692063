import { derived } from 'overmind';

export type ContentMeta = {
  id?: number | string;
  error?: string;
  merch_id?: number; // 1189
  pub_id?: number; // 600
  strategy_end_date?: string; // "2021-10-31T23:59:59Z"
  strategy_id?: number; // 100008
  strategy_name?: string; // "Some strategy name"
  strategy_rate?: string; // "$0.40 per click"
  strategy_start_date?: string; // "2021-10-19T00:00:00Z"
  strategy_thumbnail?: string;
  merch_name?: string;
  product_name?: string;
  product_price?: string;
  recommendation_status?: number;
  is_automated?: boolean;
};

export const apiThemeToUiThemeMap: { [key: number]: string } = {
  1: 'themeA',
  2: 'themeB',
  3: 'themeC',
  4: 'themeD',
  5: 'themeE',
  6: 'themeF',
  7: 'themeG',
  8: 'themeH',
};

export type ContentTypes = 'campaigns' | 'brands' | 'products' | 'videos';

export type MetaMatchResults = {
  [key: string]: { item?: ContentMeta | null; error?: string };
};

export type ContentSelection = {
  key: string;
  id?: string;
  description?: string;
  recommendation_status?: number;
  soft_delete?: boolean;
};

export type ContentModuleType =
  | 'trendingOnNarrativ'
  | 'newOnNarrativ'
  | 'brandSpotlight'
  | 'campaignSpotlight'
  | 'editorsPicksBrands'
  | 'editorsPicksCampaigns';

export type ModuleTypeConfig = {
  id: number;
  title: string;
  primaryContentType: ContentTypes;
  secondaryContentType?: ContentTypes;
  moduleProperties: {
    category?: boolean;
    displayTitle?: boolean;
    displayLabel?: boolean;
    description?: boolean;
    color?: boolean;
    displayTitlePlaceholder?: string;
  };
  contentProperties?: {
    description?: boolean;
  };
  primaryFieldCounts: { minFields: number; maxFields: number };
  secondaryFieldCounts?: { minFields: number; maxFields: number };
};

export type ContentModuleTheme = {
  defaultGradientClassnames: string;
};

type State = {
  selectedContent: {
    [key in ContentTypes]: {
      [key: string]: ContentSelection;
    };
  };
  selectedContentLists: { [key in ContentTypes]: ContentSelection[] };
  contentMeta: { [key in ContentTypes]: { [key: string]: ContentMeta } };
  metaMatchResults: MetaMatchResults;
  loadingMeta: boolean;
  categoryId: number | null;
  moduleTypeSettings: { [key: string]: ModuleTypeConfig } | null;
  themes: { [key: string]: ContentModuleTheme };
};

export const state: State = {
  selectedContent: {
    campaigns: {},
    brands: {},
    products: {},
    videos: {},
  },
  selectedContentLists: derived((state: State) => {
    return {
      campaigns: Object.values(state.selectedContent.campaigns),
      brands: Object.values(state.selectedContent.brands),
      products: Object.values(state.selectedContent.products),
      videos: Object.values(state.selectedContent.videos),
    };
  }),
  contentMeta: {
    campaigns: {},
    brands: {},
    products: {},
    videos: {},
  },
  metaMatchResults: {},
  loadingMeta: false,
  categoryId: null,
  themes: {
    themeA: {
      defaultGradientClassnames:
        'from-content-module-theme-a-100 to-content-module-theme-a-200',
    },
    themeB: {
      defaultGradientClassnames:
        'from-content-module-theme-b-100 to-content-module-theme-b-200',
    },
    themeC: {
      defaultGradientClassnames:
        'from-content-module-theme-c-100 to-content-module-theme-c-200',
    },
    themeD: {
      defaultGradientClassnames:
        'from-content-module-theme-d-100 to-content-module-theme-d-200',
    },
    themeE: {
      defaultGradientClassnames:
        'from-content-module-theme-e-100 to-content-module-theme-e-200',
    },
    themeF: {
      defaultGradientClassnames:
        'from-content-module-theme-f-100 to-content-module-theme-f-200',
    },
    themeG: {
      defaultGradientClassnames:
        'from-content-module-theme-g-100 to-content-module-theme-g-200',
    },
    themeH: {
      defaultGradientClassnames:
        'from-content-module-theme-h-100 to-content-module-theme-h-200',
    },
  },
  moduleTypeSettings: {
    trendingOnNarrativ: {
      id: 1,
      title: 'Trending on Narrativ',
      primaryContentType: 'campaigns',
      moduleProperties: {
        category: true,
        displayTitle: true,
        displayTitlePlaceholder: 'e.g. Trending on Narrativ',
      },
      primaryFieldCounts: {
        minFields: 4,
        maxFields: 20,
      },
    },
    newOnNarrativ: {
      id: 5,
      title: 'New on Narrativ',
      primaryContentType: 'brands',
      moduleProperties: {
        category: true,
        displayTitle: true,
        displayTitlePlaceholder: 'e.g. New on Narrativ',
      },
      contentProperties: {
        description: true,
      },
      primaryFieldCounts: {
        minFields: 4,
        maxFields: 4,
      },
    },
    brandSpotlight: {
      id: 2,
      title: 'Brand Spotlight',
      primaryContentType: 'campaigns',
      secondaryContentType: 'products',
      moduleProperties: {
        category: true,
        displayTitle: true,
        color: true,
        displayTitlePlaceholder: 'e.g. Brand Spotlight',
      },
      primaryFieldCounts: {
        minFields: 1,
        maxFields: 1,
      },
      secondaryFieldCounts: {
        minFields: 3,
        maxFields: 3,
      },
    },
    campaignSplotlight: {
      id: 3,
      title: 'Campaign Spotlight',
      primaryContentType: 'campaigns',
      moduleProperties: {
        category: true,
        displayTitle: true,
        description: true,
        displayTitlePlaceholder: 'e.g. Campaign Spotlight',
      },
      primaryFieldCounts: {
        minFields: 1,
        maxFields: 1,
      },
    },
    editorsPicksBrands: {
      id: 6,
      title: "Editor's Picks: Brands",
      primaryContentType: 'brands',
      moduleProperties: {
        category: true,
        displayLabel: true,
        displayTitle: true,
        description: true,
        displayTitlePlaceholder: 'e.g. Brands You Might Like',
      },
      primaryFieldCounts: {
        minFields: 4,
        maxFields: 20,
      },
    },
    editorsPicksCampaigns: {
      id: 4,
      title: "Editor's Picks: Campaigns",
      primaryContentType: 'campaigns',
      moduleProperties: {
        category: true,
        displayLabel: true,
        displayTitle: true,
        description: true,
        displayTitlePlaceholder: 'e.g. Minimalist Beauty Campaigns',
      },
      primaryFieldCounts: {
        minFields: 4,
        maxFields: 20,
      },
    },
    videoModule: {
      id: 7,
      title: 'Video Module',
      primaryContentType: 'videos',
      moduleProperties: {
        category: true,
        displayLabel: false,
        displayTitle: true,
        description: false,
        displayTitlePlaceholder: 'e.g. Featured Video',
      },
      primaryFieldCounts: {
        minFields: 0,
        maxFields: 0,
      },
    },
  },
};
