import { Context } from '..';

export const getStatsData = async (
  { state, effects }: Context,
  { params, merchantId }: { params?: any; merchantId: string },
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/kpi_snapshot/`;
  // use when we migrate to the skeleton loader
  state.ecommMerchantStats.isLoading = true;
  const respData = await effects.api.get(endpoint, params);

  try {
    const goals = respData.data.data[0].stats[0].goals;
    const roi = respData.data.data[0].stats[0].roi;
    const revSpend = respData.data.data[0].stats[0].revenue_spend;
    const activeCreators = respData.data.data[0].stats[0].active_creators;
    const approvalRating = respData.data.data[0].stats[0].approval_rating;
    const feedbackThemes = respData.data.data[0].stats[0].feedback_theme;
    const creatorScore = respData.data.data[0].stats[0].creator_score;

    const merchantStatsData = {
      goals: goals || [],
      roi: roi || {},
      revenue_spend: revSpend || {},
      active_creators: activeCreators || {},
      feedback_themes: feedbackThemes || {},
      creator_score: creatorScore || {},
      approval_rating: approvalRating || {},
    };

    state.ecommMerchantStats.merchant_stats = merchantStatsData;
  } catch (error) {
    console.log(error);
  } finally {
    state.ecommMerchantStats.isLoading = false;
  }
};

export const setUnfundedSpend = ({ state }: Context, unfundedSpend: number) => {
  state.ecommMerchantStats.unfundedSpend = unfundedSpend;
};
