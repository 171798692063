import React, { useEffect, useState } from 'react';
import { useAppState, PermissionRoles } from '@frontend/howl-web-app/overmind';

import { useRouter } from 'next/router';

export function requiresRole<T>(
  rolesList: PermissionRoles[],
  WrappedComponent: React.FunctionComponent<T>,
): React.FunctionComponent<T> {
  const WithRole = (props: T) => {
    const router = useRouter();
    const [roleAnalyzed, setRoleAnalyzed] = useState<boolean>(false);
    const [hasRequiredRole, setHasRequiredRole] = useState<boolean>(false);

    const { role } = useAppState().account;

    useEffect(() => {
      if (role) {
        setHasRequiredRole((rolesList || []).includes(role));
        setRoleAnalyzed(true);
      }
    }, [role, rolesList]);

    useEffect(() => {
      if (!hasRequiredRole && roleAnalyzed) {
        router.push('/');
      }
    }, [role, roleAnalyzed]);

    if (hasRequiredRole) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };

  WithRole.displayName = `requiresRole()`;

  return WithRole;
}
