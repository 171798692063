import React from 'react';
import { useAppState } from '@frontend/howl-web-app/overmind';
import { Transition } from '@headlessui/react';
import { PortalNavigationMenu } from './PortalNavigationMenu';

export const PortalMobileNavigationSideBar = () => {
  const { creatorSidebarOpen } = useAppState().ui;
  return (
    <Transition
      show={creatorSidebarOpen}
      enter="w-full transition duration-300 flex flex-row overflow-hidden fixed h-full z-30 top-16"
      enterFrom="-translate-x-full"
      enterTo="-translate-x-0"
      leave="transition duration-300 fixed z-30 top-16 h-full w-full"
      leaveFrom="-translate-x-0"
      leaveTo="-translate-x-full"
      entered="w-full flex flex-row overflow-hidden fixed h-full z-30 top-16 inset-y-0 left-0 z-30 overflow-y-auto"
      appear={true}
      unmount={false}
    >
      <div className="z-40 flex flex-col justify-start w-full h-full bg-white border border-b border-opacity-20">
        <PortalNavigationMenu />
      </div>
    </Transition>
  );
};
