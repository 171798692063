import { Overmind, rehydrate } from 'overmind';
import { Context } from '..';
import { AccessListItem, BamxPerms, Organization } from './state';
import { User } from '../user/state';
import * as FullStory from '@fullstory/browser';
import { chameleon } from '@frontend/shared-utils';
// import { setTRPCToken } from '@frontend/howl-web-app/trpc';
import { ReactSDKClient } from '@optimizely/react-sdk/dist/client';
import { signOut } from 'next-auth/react';

type Credentials = {
  username: string;
  password: string;
  client_id?: string;
  client_secret?: string;
};

const clientCredentials = {
  client_id: process.env['NEXT_PUBLIC_CLIENT_ID'],
  client_secret: process.env['NEXT_PUBLIC_CLIENT_SECRET'],
};

export const onInitializeOvermind: (
  context: Context,
  instance: Overmind<Context>,
) => void = (context: Context) => {
  // TODO: only save the jwt, not the full user - and move to auth, not session
  context.actions.session.setCurrentUserFromStorage(); // TODO: remove it from session

  // context.actions.auth.resolveAuthenticated({data: });
  context.actions.auth.setJwt(context.state.session.sessionData.accessToken); // TODO: remove it from session
  context.actions.auth.setDestroyed(false);
  context.actions.auth.setReported(true);
};

export const setAccessList = (context: Context, theValue: AccessListItem[]) => {
  context.state.auth.accessList = theValue;
};
export const setReported = (context: Context, theValue: boolean) => {
  context.state.auth.reported = theValue;
};
export const setJwt = (context: Context, theValue: string | null) => {
  context.state.auth.jwt = theValue;
  // if (theValue) {
  //   setTRPCToken(theValue);
  // }
};

export const resolveAuthenticated = (
  context: Context,
  { data }: { data: any },
) => {
  context.actions.auth.createSession({ data });

  context.actions.auth.setAuthData({
    accessToken: data.access_token,
    bamxPerms: data.bamx_perms,
  });
};

export const identityWithChameleon = (
  context: Context,
  { orgId }: { orgId: number },
) => {
  if (orgId) {
    const { sessionData } = context.state.session;
    if (sessionData.userId) {
      if (chameleon && process.env['NEXT_PUBLIC_CHAMELEON_PROJECT_ID']) {
        chameleon.identify(sessionData?.userId, {
          email: sessionData?.email,
          name: `${sessionData?.firstName} ${sessionData?.lastName}`.trim(),
          orgType: sessionData?.publisher_type
            ? sessionData?.publisher_type === 1
              ? 'publisher'
              : 'creator'
            : sessionData.userRole === 'merchant'
            ? 'merchant'
            : null,
          orgName: sessionData?.orgName,
          pubId: orgId,
        });
      }
    }
  }
};

export const identifyMixpanelUser = (
  context: Context,
  { mixpanelClient }: { mixpanelClient: any },
) => {
  const { sessionData } = context.state.session;

  if (mixpanelClient && sessionData.userId) {
    console.log('mixpanel identify...');
    mixpanelClient.identify(sessionData?.userId);
    mixpanelClient.people.set({
      first_name: sessionData.firstName,
    });
  }
};

export const updateIdentityWithLd = (
  context: Context,
  { ldClient }: { ldClient: any },
) => {
  const publisherType =
    context.state.organization?.selectedOrg?.publisher_type === 1
      ? 'publisher'
      : context.state.organization?.selectedOrg?.publisher_type === 2
      ? 'creator'
      : null;
  const accessList = context.state.session.sessionData?.isAdmin
    ? []
    : context.actions.session.getAccessListIds().split(',');

  const orgId = context.state.organization?.selectedOrgId;

  const identity = {
    key: `${context.state.session.sessionData.userId}`,
    custom: {
      orgId: orgId,
      userRole: context.state.session.sessionData.userRole,
      pubType: publisherType,
      isAdmin: context.state.session.sessionData.isAdmin,
      billingUser: context.actions.payment.checkIfBillingUser(),
      stripeSetupComplete:
        !context.state.session.sessionData.isAdmin &&
        context.actions.payment.checkIfStripeSetupComplete(),
      accessList: accessList,
      host: window.location.hostname,
    },
  };

  return ldClient.identify(identity, null);
};

export const identifyOptimizelyUser = async (
  context: Context,
  { optimizely }: { optimizely: ReactSDKClient },
) => {
  const identity = context.actions.auth.getIdentityForOptimizely();

  await optimizely.setUser(identity);
};

export const getIdentityForOptimizely = (context: Context) => {
  // console.log('[OPTIMIZELY] doing the getIdentityForOptimizely fetch again');
  const publisherType =
    context.state.session?.sessionData?.publisher_type === 1
      ? 'publisher'
      : context.state.session?.sessionData?.publisher_type === 2
      ? 'creator'
      : null;
  /*
    const accessList = context.state.session.sessionData.isAdmin
      ? []
      : context.actions.session.getAccessListIds().split(',');

    return {
      id: `${context.state.session.sessionData.userId}`,
      attributes: {
        orgId: context.state.session.sessionData.orgId,
        userRole: context.state.session.sessionData.userRole,
        pubType: publisherType,
        isAdmin: context.state.session.sessionData.isAdmin,
        billingUser: !!context.actions.payment.checkIfBillingUser(),
        stripeSetupComplete:
          !context.state.session.sessionData.isAdmin &&
          context.actions.payment.checkIfStripeSetupComplete(),
        accessList: accessList,
        host: window.location.hostname,
      },
    };*/
  return {
    id: `${context.state.session?.sessionData?.userId}`,
    attributes: {
      orgId: context.state.session?.sessionData?.orgId,
      userRole: context.state.session?.sessionData?.userRole,
      pubType: publisherType,
      host: window.location.hostname,
    },
  };
};

export const identifyFullStoryUser = (context: Context) => {
  const identity = {
    displayName: context.state.session.sessionData.firstName,
    publisherType_int: context.state.session.sessionData.publisher_type,
    isAdmin_bool: context.state.session.sessionData.isAdmin,
    orgId_int: context.state.session.sessionData.orgId,
    userRole_str: context.state.session.sessionData.userRole,
    accountName_str: context.state.session.sessionData.orgName,
    email: context.state.session.sessionData.email,
  };
  FullStory.identify(`${context.state.session.sessionData.userId}`, identity);
  FullStory.event('Successful login', {});
};

export const setAuthData = (
  context: Context,
  { accessToken, bamxPerms }: { accessToken: string; bamxPerms: any },
) => {
  context.actions.auth.setJwt(accessToken);
  context.actions.auth.setDestroyed(false);
  context.actions.auth.createAccessList(bamxPerms);
  context.actions.auth.setReported(true);
};

type NextAuthSession = {
  // TODO; refer to the e2e fixture at apps/howl-web-e2e/src/fixtures/auth/pub_user-login.json
  expires: string;
  accessToken: string;
  user: {
    uid: string;
    email: string;
    role: string;
    bamx_perms: any;
    bamx_version: any;
    given_name: any;
    family_name: any;
  };
};

export const syncNextAuthWithOvermind = async (
  context: Context,
  session: NextAuthSession,
) => {
  /* TODO:
    1. get the existing login api call from python REST data working in this session variable (Pankaj + Jeff work)
    2. Then, the rest of this logic "should" be able to resume as though it was a previous login call
   */
  const publishers = session?.user?.bamx_perms?.publishers;
  if (publishers && Object.keys(publishers).length > 22) {
    context.actions.ui.triggerSnackbar({
      content:
        'Account limit reached. Please contact questions@planethowl.com for assistance.',
      color: 'systemError',
      duration: 20000,
    });
    throw new Error(
      'Account limit reached. Please contact questions@planethowl.com for assistance.',
    );
  }

  context.actions.auth.setJwt(session.accessToken);
  context.actions.auth.setReported(true);

  context.actions.auth.createAccessList(session.user.bamx_perms);
  context.actions.auth.updateSessionNextAuth({
    data: session,
  });
};

export const login = (context: Context, credentials: Credentials) => {
  // return authService.login(credentials);
  const loginData = { ...credentials, ...clientCredentials };

  return context.effects.api
    .post('/api/v0/login/', loginData)
    .then((resp: any) => {
      const data = resp.data.data[0];
      const publishers = data?.user?.bamx_perms?.publishers;
      if (publishers && Object.keys(publishers).length > 22) {
        context.actions.ui.triggerSnackbar({
          content:
            'Account limit reached. Please contact questions@planethowl.com for assistance.',
          color: 'systemError',
          duration: 20000,
        });
        throw new Error(
          'Account limit reached. Please contact questions@planethowl.com for assistance.',
        );
      }

      context.actions.auth.resolveAuthenticated({ data });

      return resp;
    })
    .catch((err: any) => {
      // actions.auth.logout();
      return Promise.reject(err);
    });
};

export const logout = async (context: Context, refreshPage = false) => {
  // log out of cognito
  const logoutUrl = await fetch('/api/auth/logout-url')
    .then((res) => res.json())
    .then((data) => data?.url || '');

  if (refreshPage) context.actions.auth.setDestroyed(true);
  if (chameleon) {
    chameleon.clear();
  }

  await signOut({ callbackUrl: logoutUrl || '/' });
  context.actions.organization.clearOrgs();
  context.actions.session.destroy();
  context.actions.auth.setJwt(null);
};

export const mobileLogout = async (context: Context, sendToApp = false) => {
  // log out of cognito
  const logoutUrl = await fetch(
    `/api/auth/logout-url?loginOnly=1${sendToApp ? '&sendToApp=1' : ''}`,
  )
    .then((res) => res.json())
    .then((data) => data?.url || '');

  if (chameleon) {
    chameleon.clear();
  }

  context.actions.session.destroy();
  context.actions.auth.setJwt(null);
  context.actions.organization.clearOrgs();

  await signOut({ callbackUrl: logoutUrl || '/' });
};

export const setDestroyed = (context: Context, val: boolean) => {
  context.state.auth.destroyed = val;
};

export const createAccessList = (context: Context, bamxPerms: BamxPerms) => {
  const accessList: any[] = [];

  const sessionData = context.state.session.sessionData;

  context.actions.session.setIsAdmin(false);

  Object.keys(bamxPerms).forEach((orgKey: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const orgList = bamxPerms[orgKey];
    if (orgKey === 'admin') {
      context.actions.session.setIsAdmin(true);

      accessList.push({
        type: 'admin',
        label: 'admin',
        orgId: 0,
        termsAccepted: true,
        isOrgAdmin: true,
      });
    } else if (orgKey === 'bamx') {
      // i.e. {bamx: {product_match: true}}
      Object.keys(orgList).forEach((accessType) => {
        if (!orgList[accessType]) return;

        accessList.push({
          type: accessType,
          label: accessType,
          orgId: 0,
          termsAccepted: true,
          isOrgAdmin: true,
        });
      });
    } else {
      Object.keys(orgList).forEach((orgId) => {
        let termsAccepted = orgKey === 'merchants';
        if (sessionData && sessionData.accessList) {
          const foundOrg = sessionData.accessList.find((org) => {
            return parseInt(org.orgId, 10) === parseInt(orgId, 10);
          });

          if (foundOrg) {
            termsAccepted = foundOrg.termsAccepted;
          }
        }

        accessList.push({
          type: orgKey,
          label: context.actions.auth.getUserRoleLabel(orgKey),
          orgId: parseInt(orgId),
          // termsAccepted gets updated by loadTermsConditions()
          termsAccepted: termsAccepted,
          isOrgAdmin: orgList[orgId].is_org_admin,
          orgName: orgList[orgId].org_name,
          isBillingUser: orgList[orgId].is_billing_user,
          stripeId: orgList[orgId].stripe_id,
          stripeSetupComplete: orgList[orgId].stripe_setup_complete || false,
          stripePayoutsEnabled: orgList[orgId].stripe_payouts_enabled || false,
          role: orgList[orgId].role,
        });
      });
    }
  });

  context.actions.auth.setAccessList(accessList);
};

export const createSession = (context: Context, { data }: { data: any }) => {
  const userId = Number(data.user.id);
  let role;
  switch (data.user.role) {
    case 'publisher':
      role = 'publishers';
      break;
    case 'merchant':
      role = 'merchants';
      break;
    case 'admin':
      role = 'admin';
      break;
    default:
      role = null;
  }

  context.actions.session.create({
    authVersion: process.env['NEXT_PUBLIC_AUTH_VERSION'],
    bamxVersion: data.user.bamx_version || 0,
    orgId: null,
    termsAccepted: false,
    isOrgAdmin: false,
    isAdmin: data.user.bamx_perms.admin || false,
    userId: !isNaN(userId) ? userId : data.user.id,
    userName: '',
    userRole: role,
    email: data.user.email,
    firstName: data.user.given_name,
    lastName: data.user.family_name,
    accessToken: data.accessToken,
    refreshToken: '',
    exp: '',
  });
  // console.log('session after createSession', context.state.session);

  return Number(data?.sub);
};

export const updateSessionNextAuth = (
  context: Context,
  { data }: { data: any },
) => {
  // Don't set userRole as that comes from organization selection.
  const session = {
    bamxVersion: data.user.bamx_version || 0,
    isAdmin: data.user.bamx_perms.admin || false,
    userId: data.user.id,
    email: data.user.email,
    userName: data.user.username,
    firstName: data.user.given_name,
    lastName: data.user.family_name,
    accessToken: data.accessToken,
  };

  context.actions.session.update(session);
};

export const updateSession = (
  context: Context,
  { theOrg, allSites = false }: { theOrg: Organization; allSites?: boolean },
): any => {
  let org: Organization = theOrg || {};
  const publisherType = org.publisher_type || null;
  const instantPayAccess = org.instant_pay_access || null;
  const billingPlatform = org.billing_platform || null;

  // this is messed up until we get TypeScript implemented, as different property definitions are sharing this function
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  org.orgName = org.orgName || org['short_name'];

  if (org.pub_id) {
    org.orgId = org.pub_id;
    org.type = 'publishers';
  } else if (org.merch_id) {
    org.orgId = org.merch_id;
    org.type = 'merchants';
  } else {
    org.orgId = org.orgId || 0;
  }

  if (context.state.session.sessionData.isAdmin) {
    Object.assign(org, {
      type: org.type || 'admin',
      isOrgAdmin: true,
      termsAccepted: true,
    });
  } else {
    org = context.actions.auth.getOrgDataFromAccessList(org);
  }

  /*
   * New User flow handles terms and conditions redirect for primary and secondary users
   * Update session even if user is not an org admin or has not accepted terms.
   */

  try {
    context.actions.session.update({
      orgId: org.orgId,
      orgName: org.orgName,
      userRole: org.type,
      isOrgAdmin: org.isOrgAdmin,
      termsAccepted: org.termsAccepted,
      publisher_type: publisherType,
      instant_pay_access: instantPayAccess,
      billing_platform: billingPlatform,
      allSites: allSites,
    });
    return context.state.session.sessionData;
  } catch (e) {
    console.log('e: ', e);
    return Promise.reject(new Error('Could not set user to an account'));
  }
};

export const changePassword = (
  context: Context,
  {
    credentials,
    reloadPage,
  }: {
    credentials: {
      username: string;
      old_password: string;
      new_password: string;
      repeat_new_password: string;
    };
    reloadPage?: boolean;
  },
) => {
  Object.assign(credentials, clientCredentials);
  return context.effects.api
    .post('/api/v0/accounts/password/change/', credentials)
    .then(() => {
      if (reloadPage) window.location.reload();
    });
};

export const completeResetPassword = (
  context: Context,
  {
    resetToken,
    newPassword,
    email,
  }: { resetToken: string; newPassword: string; email: string },
) => {
  // return authService.completeResetPassword(resetToken, newPassword);
  const data = {
    reset_token: resetToken,
    new_password: newPassword,
    ...clientCredentials,
  };
  return context.effects.api.post(
    '/api/v0/accounts/password/reset_completions/',
    data,
  );
};

export const requestResetPassword = (context: Context, email: string) => {
  const data = { email, use_howl: true, ...clientCredentials };
  return context.effects.api.post(
    '/api/v0/accounts/password/reset_requests/',
    data,
  );
};

export const requestVerifyEmail = (context: Context, email: string) => {
  const data = { email, ...clientCredentials };
  return context.effects.api.post(
    '/api/v0/accounts/password/verify_email_request/',
    data,
  );
};

export const completeVerifyEmail = (context: Context, token: string) => {
  const data = { reset_token: token, ...clientCredentials };
  return context.effects.api.post(
    '/api/v0/accounts/password/verify_email_completion/',
    data,
  );
};

export const cognitoChangePassword = (
  context: Context,
  {
    credentials,
    reloadPage,
  }: {
    credentials: {
      username: string;
      old_password: string;
      new_password: string;
      repeat_new_password: string;
    };
    reloadPage?: boolean;
  },
) => {
  Object.assign(credentials, clientCredentials);
  return context.effects.api
    .post('/api/v0/accounts/password/cognito_change/', credentials)
    .then(() => {
      if (reloadPage) window.location.reload();
    });
};

export const cognitoResetPasswordRequest = (
  context: Context,
  username: string,
) => {
  const data = { username, ...clientCredentials };
  return context.effects.api.post(
    '/api/v0/accounts/password/cognito_reset_request/',
    data,
  );
};

export const cognitoResetPassword = (
  context: Context,
  {
    resetToken,
    newPassword,
    email,
  }: { resetToken: string; newPassword: string; email: string },
) => {
  const data = {
    reset_token: resetToken,
    new_password: newPassword,
    username: email,
    ...clientCredentials,
  };
  return context.effects.api.post(
    '/api/v0/accounts/password/cognito_reset/',
    data,
  );
};

export const buildOrgRequests = (context: Context, user: User) => {
  /*
   * Pass user object as an argument so this function
   * can be used by the OrgSelect component
   */
  let requests: any = [];
  let pubParams = {};
  let merchParams = {};

  if (user?.isAdmin) {
    // Not passing org IDs returns all entities
    requests = [
      context.effects.api.get('/api/v0/merchants/'),
      context.effects.api.get('/api/v0/publishers/'),
    ];
  } else {
    const [merchantIds, publisherIds] = context.actions.auth.getOrgIds({
      ...user,
      accessList: context.state.auth.accessList, // TODO: consolidate this logic
    });

    if (merchantIds.length) {
      merchParams = { merchants: merchantIds.join(',') };
      requests.push(context.effects.api.get('/api/v0/merchants/', merchParams));
    }
    if (publisherIds.length) {
      pubParams = { publishers: publisherIds.join(','), new_terms_logic: true };
      requests.push(context.effects.api.get('/api/v0/publishers/', pubParams));
    }
  }

  return requests;
};

export const buildOrgDicts = (
  context: Context,
  organizations: Organization[],
) => {
  const orgDicts: any = { merchants: {}, publishers: {} };

  organizations.forEach((org: any) => {
    const orgType = org.pub_id ? 'publishers' : 'merchants';
    const orgId = org.pub_id || org.merch_id || 0;
    orgDicts[orgType][orgId] = org;
  });

  return orgDicts;
};

export const getOrgIds = (context: Context, user: User): any => {
  // Get the IDs of organizations the user has access to from accessList
  const merchantIds: any[] = [];
  const publisherIds: any[] = [];

  /*
   * accessList: [{
   *  type: "publishers", label: "publisher", orgId: 1054,
   *  termsAccepted: true, isOrgAdmin: false,
   * },]
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  user.accessList?.forEach((org: any) => {
    if (org.type === 'merchants') {
      merchantIds.push(org.orgId);
    } else if (org.type === 'publishers') {
      publisherIds.push(org.orgId);
    }
  });

  return [merchantIds, publisherIds];
};

export const getOrgDataFromAccessList = (
  context: Context,
  org: Organization,
) => {
  return (
    context.state.auth.accessList?.find((accessObject: any) => {
      return org.orgId === accessObject.orgId && org.type === accessObject.type;
    }) || {}
  );
};

export const getUserRoleLabel = (context: Context, role: string) => {
  switch (role) {
    case 'merchants':
      return 'retailer';
    case 'publishers':
      return 'publisher';
    default:
      return role;
  }
};

export const getLatestTermsInfo = async (context: Context) => {
  const res = await context.effects.api.get('/api/v0/terms-conditions/latest/');
  return res.data.data[0];
};
