type Nullable<T> = T | null;

export type StorefrontOverview = {
  title: string;
  bio: string;
  channels: Channel[];
  website: string;
  profile_pic_url: string;
  url_slug: string;
  is_published: boolean;
  storefront_id: number;
};

interface Creatorinfo {
  full_name: string;
}

export interface StorefrontResponse {
  storefront: StorefrontOverview;
  creator_info: Creatorinfo;
  collections: Collection[];
  creator_channels: Creatorchannel[];
}

interface Creatorchannel {
  pub_id: number;
  channel_type: string;
  platform_handle: string;
  channel_id: number;
  rank: number;
}

export type Channel = {
  channel_id: number;
  platform_handle: string;
  channel_type: string;
  rank: number;
};

export type Collection = {
  collection_id: number;
  title: string;
  storefront_links: StorefrontLink[];
  description: string;
  rank: number;
  is_published: boolean;
};

export interface CollectionResponse {
  title: string;
  storefront_id?: number;
  rank?: number;
  description: string;
  collection_id?: number;
  is_published?: boolean;
  storefront_links?: StorefrontLink[];
  social_post_url?: string;
  social_post_channel_type?: number;
  social_post_image_thumbnail_url?: string;
}

export type StorefrontLink = {
  storefront_link_id: number;
  bam_link_id: number;
  rank: number;
  bam_link_url?: Nullable<string>;
  auction_id?: Nullable<number>;
  edit_id?: Nullable<number>;
  link_source?: Nullable<number>;
  merch_id?: any;
  pub_id?: Nullable<number>;
  uid_created?: Nullable<number>;
  datetime_link_created?: Nullable<string>;
  edit_name?: Nullable<string>;
  edit_channel?: any;
  datetime_edit_created?: Nullable<string>;
  store_page_url?: Nullable<string>;
  feed_merchant_id?: Nullable<number>;
  image_url?: Nullable<string>;
  merch_logo_url?: Nullable<string>;
  merchant_product_id?: Nullable<number>;
  product_brand?: Nullable<string>;
  product_name?: Nullable<string>;
  auction_url?: Nullable<string>;
  encoded_auction_url?: Nullable<string>;
  howl_link_url?: Nullable<string>;
  user_image_url?: Nullable<string>;
  user_product_name?: Nullable<string>;
  user_brand_name?: Nullable<string>;
  user_link_description?: Nullable<string>;
  product_url?: Nullable<string>;
  exclusive_match_requested?: Nullable<number>;
};

export type ModifyLink = StorefrontLink & {
  id?: string;
  isDeleted?: boolean;
  isConfirmed?: boolean;
};

type BamLink = {
  bam_link_id: number;
};

export type State = {
  storefrontId: number | null;
  overview: StorefrontOverview | null;
  collection: CollectionResponse | null;
  collectionLinks: ModifyLink[] | null;
  collections: Collection[] | null;
  showUnpublished: boolean;
  oldLinks: string;
  newlyCreatedLinks: BamLink[] | null;
};

export const state: State = {
  storefrontId: null,
  overview: null,
  collection: null,
  collectionLinks: null,
  collections: null,
  showUnpublished: false,
  oldLinks: '',
  newlyCreatedLinks: null,
};
