import { Campaign, Context } from '..';
import moment from 'moment';

export const fetchCampaign = async (
  { effects, state }: Context,
  {
    merchantId,
    strategyIds,
  }: {
    merchantId?: number;
    strategyIds?: number | number[];
  },
) => {
  if (strategyIds === undefined || merchantId === undefined) {
    return;
  }

  const merchantEndpoint = `/api/v0/merchants/${merchantId}/`;
  const endpoint = `/api/v0/merchants/${merchantId}/strategies/${strategyIds.toString()}/preview/`;

  try {
    state.ecommCampaignCenter.isLoading = true;

    const merchantResponse = await effects.api.get(merchantEndpoint);
    const merchantData = merchantResponse.data.data[0];
    const campaignResponse = await effects.api.get(endpoint);
    const campaignData = campaignResponse.data.data[0];

    const strategyRateDisplay =
      campaignData.strategy_payment_model === 'EPC' ||
      (campaignData.strategy_payment_model === null &&
        campaignData.strategy_pricing_model === 'CPC')
        ? `$${parseFloat(campaignData.strategy_rate).toFixed(2)} per click`
        : `${
            campaignData.strategy_rate * 100 ==
            Math.floor(campaignData.strategy_rate * 100)
              ? (campaignData.strategy_rate * 100).toFixed(0)
              : (campaignData.strategy_rate * 100).toFixed(2)
          }% Commission`;

    const strategy_min_rate = campaignData.strategy_min_rate;
    const strategy_max_rate = campaignData.strategy_max_rate;
    const strategy_flat_fee_min = campaignData.strategy_flat_fee_min;
    const strategy_flat_fee_max = campaignData.strategy_flat_fee_max;

    let rateRange;
    if (
      strategy_max_rate &&
      strategy_min_rate &&
      strategy_max_rate !== strategy_min_rate
    ) {
      const minRate =
        campaignData.strategy_pricing_model === 'EPP'
          ? strategy_min_rate * 100
          : strategy_min_rate;

      const maxRate =
        campaignData.strategy_pricing_model === 'EPP'
          ? strategy_max_rate * 100
          : strategy_max_rate;

      const stringifyMin = minRate.toFixed(2).toString();
      const stringifyMax = maxRate.toFixed(2).toString();

      rateRange =
        campaignData.strategy_pricing_model === 'EPP'
          ? `${stringifyMin}% - ${stringifyMax}% Commission`
          : `$${stringifyMin} - $${stringifyMax} per click`;
    }

    let flatFee;
    if (strategy_flat_fee_min && strategy_flat_fee_max) {
      if (strategy_flat_fee_max !== strategy_flat_fee_min) {
        flatFee = `${strategy_flat_fee_min} - ${strategy_flat_fee_max}`;
      } else {
        flatFee = `${strategy_flat_fee_min}`;
      }
    }

    const selectedCampaign = {
      strategy_id: campaignData.strategy_id,
      strategy_name: campaignData.strategy_name,
      strategy_start_date: campaignData.strategy_datetime_start,
      strategy_end_date: campaignData.strategy_datetime_end,
      is_evergreen: campaignData.strategy_is_evergreen,
      strategy_rate: strategyRateDisplay,
      strategy_thumbnail: campaignData.strategy_thumbnail,
      merch_id: merchantId,
      merchant_logo_url: merchantData.logo_url,
      strategy_rate_pricing_model: campaignData.strategy_pricing_model,
      attribution_window: `Post Click ${campaignData.attribution_window}`,
      strategy_description: campaignData.strategy_description,
      merch_name: merchantData.full_name,
      strategy_rate_range: rateRange,
      flat_fee: flatFee,
      eligibility_status: '',
    };

    state.ecommCampaignCenter.selectedCampaign = selectedCampaign;
    state.ecommCampaignCenter.feedbackStatus = {
      liked_statistic: parseInt(campaignData.strategy_liked_percentage),
      disliked_statistic: parseInt(campaignData.strategy_disliked_percentage),
    };

    return selectedCampaign;
  } catch (e: any) {
    state.ecommCampaignCenter.isLoading = false;
    throw new Error(e);
  }
};

export const formatCampaignDateInfo = (
  context: Context,
  campaign: Campaign,
) => {
  const dateInfo = { duration: '', daysText: '' };
  const strategyStart = moment.utc(campaign.strategy_start_date);
  const strategyEnd = moment.utc(campaign.strategy_end_date);

  if (campaign.strategy_start_date && campaign.strategy_end_date) {
    dateInfo.duration = `${strategyStart.format(
      'MMM D',
    )} - ${strategyEnd.format('MMM D, YYYY')}`;
    const today = moment().utc();
    if (strategyStart > today) {
      dateInfo.daysText = `Starts in ${strategyStart
        .startOf('day')
        .diff(today.startOf('day'), 'days')} days`;
    } else if (strategyEnd < today) {
      dateInfo.daysText = 'Completed';
    } else {
      dateInfo.daysText = `${strategyEnd
        .startOf('day')
        .diff(today.startOf('day'), 'days')} Days Left`;
    }
  } else if (campaign.strategy_start_date) {
    dateInfo.duration = `${strategyStart.format('MMM D, YYYY')} - Present`;
    dateInfo.daysText = '';
  }
  return dateInfo;
};

export const fetchBrandProducts = async (
  { effects, state }: Context,
  {
    merchantId,
    strategyIdList,
    searchQuery,
    page,
    perPage,
    inStockOnly,
    saleOnly,
  }: {
    merchantId: number;
    strategyIdList: string;
    searchQuery: string;
    page: number;
    perPage: number;
    inStockOnly?: boolean;
    saleOnly?: boolean;
  },
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/strategies/${strategyIdList}/strategy_products/`;
  try {
    state.ecommCampaignCenter.isLoading = true;
    const response = await effects.api.get(endpoint, {
      search_query: searchQuery ? searchQuery : null,
      page,
      per_page: perPage,
      in_stock_only: inStockOnly,
      sale_only: saleOnly,
    });

    state.ecommCampaignCenter.products = response.data.data[0].products;
    state.ecommCampaignCenter.productsTotalItems =
      response.data.data[0].total_items;
    state.ecommCampaignCenter.isLoading = false;
  } catch {
    state.ecommCampaignCenter.products = [];
    state.ecommCampaignCenter.productsTotalItems = 0;
    state.ecommCampaignCenter.isLoading = false;
  }
};

export const clearSelectedCampaign = ({ state }: Context) => {
  state.ecommCampaignCenter.selectedCampaign = null;
};

//  Empty functions to mirror actions on creator side
export const fetchFeedbackStatus = () => {
  return null;
};

export const fetchGifts = async (
  { effects, state }: Context,
  {
    merchantId,
    strategyId,
  }: {
    merchantId: number;
    strategyId: string;
  },
) => {
  try {
    const endpoint = `/api/v0/merchants/${merchantId}/strategies/${strategyId}/strategy_gift/`;

    const res = await effects.api.get(endpoint, { return_entity: true });

    const data = res.data.data[0].gift[0];
    const hasGift =
      res.data.data[0].gift[0] &&
      !res.data.data[0].gift[0].strategy_is_archived;

    const giftInfo = hasGift
      ? {
          description: data.strategy_gift_description,
          has_gift: true,
          quantity: data.strategy_gift_quantity,
          signup_url: data.strategy_gift_signup_url,
          total_products: data.total_products,
          // TODO: flip once BE logic is implemented
          opted_in: false,
        }
      : null;

    state.ecommCampaignCenter.giftInfo = giftInfo;
    return giftInfo;
  } catch (e: any) {
    state.ecommCampaignCenter.giftInfo = null;
    return new Error(e);
  }
};

export const updateStrategyStatus = (
  { effects }: Context,
  {
    merchantId,
    strategyId,
    updatedStatus,
  }: { merchantId?: string; strategyId: string; updatedStatus: string },
) => {
  effects.api.put(
    `/api/v0/merchants/${merchantId}/strategies/${strategyId}/update_strategy/`,
    {
      strategy: { strategy_is_enabled: updatedStatus === 'unpause' },
    },
  );
};

export const getRollup = (
  { effects }: Context,
  { merchantId, strategyId }: { merchantId?: string; strategyId: string },
) => {
  const response = effects.api.get(
    `/api/v0/merchants/${merchantId}/strategies/${strategyId}/rollup/`,
  );
  const strategyIds = response.data.data[0].strategy_ids;
  return strategyIds.join(',');
};

export const publishCampaign = (
  { effects }: Context,
  { merchantId, strategyId }: { merchantId?: string; strategyId: string },
) => {
  return effects.api.put(
    `/api/v0/merchants/${merchantId}/strategies/${strategyId}/update_strategy/`,
    {
      strategy: { strategy_is_enabled: true, strategy_is_draft: false },
    },
  );
};

export const undoDeleteCampaign = (
  { effects }: Context,
  { merchantId, strategyId }: { merchantId?: string; strategyId: string },
) => {
  return effects.api.put(
    `/api/v0/merchants/${merchantId}/strategies/${strategyId}/update_strategy/`,
    {
      strategy: { strategy_is_archived: false },
    },
  );
};

export const undoDeactivateCampaign = (
  { effects }: Context,
  { merchantId, strategyId }: { merchantId?: string; strategyId: string },
) => {
  return effects.api.put(
    `/api/v0/merchants/${merchantId}/strategies/${strategyId}/update_strategy/`,
    {
      strategy: { strategy_is_enabled: true },
    },
  );
};
