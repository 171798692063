import { Context } from '..';

export const fetchCampaignCartExclusions = async (
  { state, effects }: Context,
  {
    publisher_id,
    strategy_id,
  }: {
    publisher_id: number;
    strategy_id: number;
  },
) => {
  const endpoint = `/api/v0/publishers/${publisher_id}/campaigns/${strategy_id}/campaign_cart_exclusions/`;
  try {
    const result = await effects.api.get(endpoint);
    state.creatorCartExclusions.data = result.data.data[0];
  } catch {
    state.creatorCartExclusions.data = [];
  }
};
