import { Context } from '..';
import { TargetListsResponse } from './state';

export const setListManagementShowEditor = (
  { state }: Context,
  showEditor: boolean,
) => {
  state.listManagement.showEditor = showEditor;
};

export const setUpdatingList = ({ state }: Context, updatingList: boolean) => {
  state.listManagement.updatingList = updatingList;
};

export const setListManagementData = (
  { state }: Context,
  responseData: TargetListsResponse[],
) => {
  state.listManagement.data = responseData;
};

export const setTargetListId = ({ state }: Context, targetListId: number) => {
  state.listManagement.targetListId = targetListId;
};

export const setTargetListType = (
  { state }: Context,
  targetListType: string,
) => {
  state.listManagement.targetListType = targetListType;
};

export const setTargetListName = (
  { state }: Context,
  targetListName: string,
) => {
  state.listManagement.targetListName = targetListName;
};

export const createTargetList = (
  { effects }: Context,
  {
    merchantId,
    targetListType,
    payload,
  }: { merchantId: string; targetListType: string; payload: any },
) => {
  const merchEndpoint = `/api/v0/merchants/${merchantId}/target_lists/${targetListType}/create_target_list/`;
  const adminEndpoint = `/api/v0/merchants/target_lists/${targetListType}/create_target_list/`;
  const endpoint = merchantId ? merchEndpoint : adminEndpoint;

  return effects.api.post(endpoint, payload);
};

export const updateTargetList = (
  { effects }: Context,
  {
    merchantId,
    targetListId,
    targetListType,
    payload,
  }: {
    merchantId: string;
    targetListId: number;
    targetListType: string;
    payload: any;
  },
) => {
  const merchEndpoint = `/api/v0/merchants/${merchantId}/target_lists/${targetListType}/${targetListId}/update_target_list/`;
  const adminEndpoint = `/api/v0/merchants/target_lists/${targetListType}/${targetListId}/update_target_list/`;
  const endpoint = merchantId ? merchEndpoint : adminEndpoint;

  return effects.api.put(endpoint, payload);
};

export const getTargetListById = (
  { effects }: Context,
  {
    merchantId,
    targetListType,
    targetListId,
  }: { merchantId: string; targetListType: string; targetListId: number },
) => {
  const scope = merchantId ? 'account_scope' : 'network_scope';
  const merchEndpoint = `/api/v0/merchants/${merchantId}/target_lists/${targetListType}/${targetListId}/target_list_by_id/`;
  const adminEndpoint = `/api/v0/merchants/target_lists/${targetListType}/${targetListId}/target_list_by_id/`;
  const endpoint = merchantId ? merchEndpoint : adminEndpoint;

  return effects.api.get(endpoint, { scopes: scope, per_page: 50000 });
};

export const getTargetListsByType = async (
  { effects }: Context,
  {
    listType,
    merchantId,
    scopes = 'account_scope,network_scope',
  }: {
    listType: 'publisher' | 'brand' | 'sku';
    merchantId: number;
    scopes?: string;
  },
) => {
  const endpoint = `/api/v0/merchants/target_lists/${listType}/target_list_info/`;

  try {
    const response = await effects.api.get(endpoint, {
      merch_id: merchantId,
      scopes: scopes,
    });

    return response.data.data[0].target_list_info;
  } catch (error) {
    console.error(error);
  }
};

export const getTargetLists = async (
  { effects }: Context,
  {
    merchantId,
    scopes,
    listType,
  }: {
    merchantId: number | null;
    scopes?: string;
    listType?: 'publisher' | null;
  },
) => {
  const baseEndpoint = '/api/v0/merchants/target_lists';
  const endpoint = listType
    ? `${baseEndpoint}/${listType}/target_list_info/`
    : `${baseEndpoint}/target_list_info/`;

  return await effects.api
    .get(endpoint, { merch_id: merchantId, scopes })
    .then((resp: any) => {
      return resp.data.data[0].target_list_info;
    })
    .catch((err: Error) => console.error(err));
};

export const getPublisherTargetLogos = async (
  { effects, state }: Context,
  merchantId: number,
) => {
  state.listManagement.logosLoading = true;
  const endpoint = `/api/v0/merchants/${merchantId}/target_lists/publisher_list_logos/`;
  const targetListIds = state.listManagement.data.map(
    (list: any) => list.target_list_id,
  );

  const logosByListId: { [target_list_id: number]: any[] } = {};

  return await effects.api
    .get(endpoint, {
      target_list_ids: targetListIds.join(','),
    })
    .then((resp: any) => {
      const lists = resp.data.data[0].target_list_info;
      lists.forEach((list: { target_list_id: number; pub_logos: any[] }) => {
        logosByListId[list.target_list_id] = list.pub_logos;
      });

      const data = state.listManagement.data.map((list: any) => {
        list.pub_logos = logosByListId[list.target_list_id];
        return list;
      });
      state.listManagement.data = data;
      return data;
    })
    .catch((err: Error) => console.error(err))
    .finally(() => {
      state.listManagement.logosLoading = false;
    });
};

export const setTargetListsByType = (
  { state }: Context,
  {
    listType,
    targetLists,
  }: {
    listType: 'publisher' | 'brand' | 'sku';
    targetLists: TargetListsResponse[];
  },
) => {
  state.listManagement.targetLists[listType] = targetLists;
};

export const setSnackBarTxt = ({ state }: Context, message: string) => {
  state.listManagement.snackBarTxt = message;
};

export const setTargetListError = ({ state }: Context, error: string) => {
  state.listManagement.error = error;
};

export const setOpenRows = ({ state }: Context, rowIndex: number) => {
  if (rowIndex in state.listManagement.openRows) {
    delete state.listManagement.openRows[rowIndex];
  } else {
    state.listManagement.openRows[rowIndex] = true;
  }
};
