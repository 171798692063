import { useMediaQuery } from '@frontend/shared-utils';
import React, { ElementType } from 'react';

type Variant =
  | 'd1'
  | 'd2'
  | 'd3'
  | 'd3-bold'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subtitle'
  | 'body'
  | 'body-bold'
  | 'body-2'
  | 'body-2-bold'
  | 'label-1'
  | 'label-2'
  | 'label-3'
  | 'caption'
  | 'caption-500'
  | 'caption-700';

interface TypographyProps {
  /* Typography size */
  variant: Variant;

  /* Mobile Only Typography size */
  mobileVariant?: Variant;

  /* HTML content */
  children: React.ReactNode;

  /* Tailwind classes to style element */
  className?: string;

  /* Set HTML tag */
  as?: ElementType;

  /* Cypress selector */
  dataCy?: string;
}

const tags: Record<Variant, ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  d1: 'h1',
  d2: 'h2',
  d3: 'h3',
  'd3-bold': 'h3',
  subtitle: 'p',
  'label-1': 'p',
  'label-2': 'p',
  'label-3': 'p',
  body: 'p',
  'body-bold': 'p',
  'body-2': 'p',
  'body-2-bold': 'p',
  caption: 'p',
  'caption-500': 'p',
  'caption-700': 'p',
};

const sizes: Record<Variant, string> = {
  d1: 'text-6xl font-bold',
  d2: 'text-5xl	font-normal',
  d3: 'text-[34px]	font-normal',
  'd3-bold': 'text-[34px]	font-bold',
  h1: 'text-3xl	font-bold',
  h2: 'text-2xl	font-bold',
  h3: 'text-xl	font-medium',
  h4: 'text-lg	font-medium',
  subtitle: 'text-base font-medium',
  body: 'text-base',
  'body-bold': 'text-base font-bold',
  'body-2': 'text-sm',
  'body-2-bold': 'text-sm font-bold',
  'label-1': 'uppercase text-sm font-medium',
  'label-2': 'uppercase text-xs font-medium',
  'label-3': 'text-sm font-medium',
  caption: 'text-xs',
  'caption-500': 'text-xs font-medium',
  'caption-700': 'text-xs font-bold',
};

export const Typography = ({
  variant,
  mobileVariant,
  children,
  className = '',
  as,
  dataCy,
}: TypographyProps) => {
  let sizeClasses;
  const Tag = as || tags[variant];
  const isMobile = useMediaQuery('(max-width: 639px)');

  if (mobileVariant && isMobile) {
    sizeClasses = sizes[mobileVariant];
  } else {
    sizeClasses = sizes[variant];
  }
  return (
    <Tag data-cy={dataCy} className={`${sizeClasses} ${className}`}>
      {children}
    </Tag>
  );
};

Typography.displayName = 'Typography';
Typography.filePath = 'libs/shared/ui-kit/src/lib/typography/typography.tsx';
