const hideSnackbar = () => {
  return {
    type: 'HIDE_SNACKBAR',
  };
};

const showSnackbar = () => {
  return {
    type: 'SHOW_SNACKBAR',
  };
};

const setSnackbar = (msg) => {
  return {
    type: 'SET_SNACKBAR',
    value: msg,
  };
};

const setArticles = (articles, totalItems, statKey, page) => {
  return {
    type: 'SET_ARTICLES',
    value: articles,
    totalItems,
    statKey,
    page,
  };
};

const setProducts = (products, totalItems, statKey, page) => {
  return {
    type: 'SET_PRODUCTS',
    value: products,
    totalItems,
    statKey,
    page,
  };
};

const setTabStats = (stats, totalItems, statKey, page, tab) => {
  return {
    type: 'SET_TAB_STATS',
    value: stats,
    totalItems,
    statKey,
    page,
    tab,
  };
};

const setSearchEdit = (edit) => {
  return {
    type: 'SET_SEARCH_EDIT',
    value: edit,
  };
};

const setPublisherList = (list) => {
  return {
    type: 'SET_PUBLISHER_LIST',
    value: list,
  };
};

const setPublisherMap = (map) => {
  return {
    type: 'SET_PUBLISHER_MAP',
    value: map,
  };
};

const setStrategyCrumb = (crumb, tab) => {
  return {
    type: 'SET_STRATEGY_CRUMB',
    value: crumb,
    crumbPage: tab,
  };
};

const setAccountLink = (accountLink) => {
  return {
    type: 'SET_ACCOUNT_LINK',
    value: accountLink,
  };
};

export {
  hideSnackbar,
  showSnackbar,
  setSnackbar,
  setArticles,
  setProducts,
  setSearchEdit,
  setTabStats,
  setPublisherList,
  setPublisherMap,
  setStrategyCrumb,
  setAccountLink,
};
