import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import set from 'lodash/set';
import without from 'lodash/without';
import { v4 as uuid } from 'uuid';
import { pipe, waitUntil } from 'overmind';
import moment from 'moment';
import {
  getPartnerRateFromROI,
  getROIFromPartnerRate,
} from '@frontend/shared-utils';

import { Context } from '..';
import {
  CreatorTier,
  CreatorTiers,
  defaultFormState,
  defaultPageState,
  FlowType,
  Gifting,
  Merchant,
  RateTier,
  Strategy,
  StrategyGroup,
  StrategyRules,
  Targeting,
  TargetRule,
  TargetRuleForm,
  TargetType,
} from './state';

//<editor-fold desc="Initializers">
export const resetState = ({ state }: Context) => {
  state.ecommStrategyManager.formState = cloneDeep(defaultFormState);
  state.ecommStrategyManager.pageState = cloneDeep(defaultPageState);
  state.ecommStrategyManager.rateTiers = [];
  state.ecommStrategyManager.gifting = null;
  state.ecommStrategyManager.targeting = null;
  state.ecommStrategyManager.strategy = null;
  state.ecommStrategyManager.strategyGroup = null;
  state.ecommStrategyManager.strategyRules = null;
};

export const resetStrategyStatus = ({ state }: Context) => {
  const { strategy, flowType }: any = state.ecommStrategyManager;
  if (!strategy && flowType === 'edit')
    throw new Error('Source data unavailable');

  state.ecommStrategyManager.formState.strategy_is_enabled =
    flowType === 'edit' ? strategy.strategy_is_enabled : false;
  state.ecommStrategyManager.formState.strategy_is_draft =
    flowType === 'edit' ? strategy.strategy_is_draft : true;
};

export const getAndSetPageData = async (
  { state, actions }: Context,
  {
    flowType,
    merchantId,
    strategyGroupId,
    strategyId,
    cloneId,
  }: {
    flowType: FlowType;
    merchantId: number;
    strategyGroupId: number;
    strategyId: number;
    cloneId?: number;
  },
) => {
  const {
    getMerchantById,
    getStrategyGroupById,
    getStrategyById,
    getStrategyRules,
    getRateTiersByStrategyId,
    getGiftingByStrategyId,
    getTargetExpressionById,
    getTargetListsByType,
    getTargetListById,
    getMerchantCreatorTiers,
    setMerchant,
    setStrategyGroup,
    setStrategy,
    setStrategyRules,
    setCloneStrategy,
    setCreatorTiers,
    setRateTiers,
    setGifting,
    setTargeting,
    setFlowType,
    setPage,
    setPageIsDirty,
    setInputErrors,
    setFormErrors,
    setTargetLists,
    setMerchantAttributionWindow,
  } = actions.ecommStrategyManager;

  setFlowType(flowType);

  try {
    const [merchant, strategyGroup, publisherTargetLists, creatorTiers] =
      await Promise.all([
        getMerchantById(merchantId),
        getStrategyGroupById({ merchantId, strategyGroupId }),
        getTargetListsByType({ merchantId, listType: 'publisher' }),
        getMerchantCreatorTiers(merchantId),
      ]);

    setMerchant(merchant);
    setStrategyGroup(strategyGroup);
    setCreatorTiers(creatorTiers);
    await setTargetLists({
      listType: 'publisher',
      targetLists: publisherTargetLists,
    });

    if (flowType === 'edit') {
      const [strategy, strategyRules, rateTierResp, gifting] =
        await Promise.all([
          getStrategyById({ merchantId, strategyId }),
          getStrategyRules({ merchantId, strategyId }),
          getRateTiersByStrategyId({ merchantId, strategyId }),
          getGiftingByStrategyId({ merchantId, strategyId }),
        ]);

      const { rate_tiers: rateTiers, attribution_window: attributionWindow } =
        rateTierResp;
      setStrategy(strategy);
      setStrategyRules(strategyRules);
      setGifting(gifting);
      setMerchantAttributionWindow(attributionWindow);

      if (rateTiers) {
        const rts = await Promise.all(
          rateTiers.map(async (rt: any) => {
            if (rt.publisher_list_id) {
              const targetList = await getTargetListById({
                merchantId: merchantId,
                targetListType: 'publisher',
                targetListId: rt.publisher_list_id,
              });
              rt.publisher_list_scope =
                targetList.target_list_info.find(
                  (tl: any) => tl.target_list_id === targetList.target_list_id,
                ) === undefined
                  ? 1
                  : 2;
              rt.publisher_list_items = targetList.target_list;
            }

            return rt;
          }),
        );
        setRateTiers(rts);
      } else {
        setRateTiers(rateTiers);
      }

      let targeting = state.ecommStrategyManager.targeting;
      if (strategy.target_expression_id) {
        targeting = await getTargetExpressionById({
          merchantId,
          expressionId: strategy.target_expression_id,
        });

        setTargeting(targeting);
      }

      if (strategy?.strategy_is_evergreen) {
        setPage('SETUP_PAGE');
      } else {
        setPage('OBJECTIVES_PAGE');
      }
    } else if (flowType === 'clone' && cloneId) {
      const [cloneStrategy, cloneRateTiersResp] = await Promise.all([
        getStrategyById({ merchantId, strategyId: cloneId }),
        getRateTiersByStrategyId({ merchantId, strategyId: cloneId }),
      ]);

      setCloneStrategy(cloneStrategy);
      setRateTiers(cloneRateTiersResp.rate_tiers);

      let targeting = state.ecommStrategyManager.targeting;
      if (cloneStrategy.target_expression_id) {
        targeting = await getTargetExpressionById({
          merchantId,
          expressionId: cloneStrategy.target_expression_id,
        });

        setTargeting(targeting);
      }
      setPage('OBJECTIVES_PAGE');
    } else {
      setPage('OBJECTIVES_PAGE');
    }

    setPageIsDirty(false);
    setInputErrors({});
    setFormErrors([]);
  } catch (e) {
    throw new Error();
  }
};

export const buildSetUpPageFormValues = ({ state }: Context) => {
  const {
    strategy,
    strategyCloneOriginal,
    strategyRules,
    flowType,
    rateTiers,
  } = state.ecommStrategyManager;

  const strategyDataSource =
    flowType === 'clone' ? strategyCloneOriginal : strategy;

  if (
    strategyDataSource === null ||
    strategyDataSource === undefined ||
    flowType === 'create'
  )
    return {
      strategy_is_draft: true,
      strategy_is_enabled: false,
    };

  const formState = pick(strategyDataSource, [
    'strategy_type',
    'strategy_name',
    'strategy_tagline',
    'strategy_key_objective',
    'content_submission_url',
    'campaign_brief_url',
    'strategy_description',
    'strategy_utm_query_string',
    'strategy_thumbnail_image_url',
    'strategy_budget_amount',
    'strategy_budget_type',
    'strategy_datetime_start',
    'strategy_datetime_end',
    'strategy_time_start',
    'strategy_time_end',
    'strategy_pricing_model',
    'strategy_payment_model',
    'strategy_priority',
    'strategy_is_enabled',
    'strategy_is_draft',
  ]) as any;

  // map legacy strategy_type
  if (formState.strategy_type === 'default') {
    formState.strategy_type = 'always_on';
  }

  if (
    strategyDataSource.cpc_fee_rate === null &&
    strategyDataSource.epp_fee_rate === null
  ) {
    formState.isTransactionFeesEnabled = false;
  } else {
    formState.isTransactionFeesEnabled = true;
    if (
      strategyDataSource.cpc_fee_rate !== null &&
      strategyDataSource.cpc_fee_rate !== undefined
    ) {
      formState.cpc_fee_rate = strategyDataSource.cpc_fee_rate * 100;
    }

    if (
      strategyDataSource.epp_fee_rate !== null &&
      strategyDataSource.epp_fee_rate !== undefined
    ) {
      formState.epp_fee_rate = strategyDataSource.epp_fee_rate * 100;
    }
  }

  //  Strategy Time Ranges
  let hasTimeOfDay = false;
  if (strategyDataSource.strategy_datetime_start) {
    const date = new Date(strategyDataSource.strategy_datetime_start);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    hasTimeOfDay ||= hours !== 0 || minutes !== 0;
  }
  if (strategyDataSource.strategy_datetime_end) {
    const date = new Date(strategyDataSource.strategy_datetime_end);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    hasTimeOfDay ||= hours !== 23 || minutes !== 59;
  }

  if (hasTimeOfDay) {
    formState.isTimeOfDayEnabled = hasTimeOfDay;
    formState.strategy_time_start = moment(
      strategyDataSource.strategy_datetime_start,
    )
      .utc()
      .format('HH:mm');
    formState.strategy_time_end = moment(
      strategyDataSource.strategy_datetime_end,
    )
      .utc()
      .format('HH:mm');
  }

  // Pricing and Payment Models
  if (strategyDataSource.strategy_pricing_model) {
    formState.strategy_pricing_model_value =
      strategyDataSource.strategy_pricing_model === 'EPP'
        ? strategyDataSource.strategy_roi_goal
        : strategyDataSource.strategy_bid;
  }

  formState.isPaymentModelEnabled = true;
  if (strategyDataSource.strategy_payment_model) {
    formState.strategy_payment_model_value =
      strategyDataSource.strategy_payment_model === 'EPP'
        ? strategyDataSource.strategy_publisher_roi_target
        : strategyDataSource.strategy_publisher_epc;
  } else if (
    strategyDataSource.strategy_cogs !== null &&
    strategyDataSource.strategy_cogs !== undefined
  ) {
    formState.strategy_payment_model_value =
      strategyDataSource.strategy_cogs * 100;
    formState.strategy_payment_model = 'COGS';
  } else {
    formState.isPaymentModelEnabled = false;
  }

  if (
    strategyDataSource.flat_fee_rate !== null &&
    strategyDataSource.flat_fee_rate !== undefined
  ) {
    formState.flat_fee_rate = strategyDataSource.flat_fee_rate * 100;
  }

  //  Automation Rules
  if (strategyRules?.merchant_rules) {
    const strategyRulesFormValues: any = {};
    strategyRules.merchant_rules.forEach((rule) => {
      if (rule) {
        const ruleKey = rule.rule_id ? rule.rule_id : `new-${uuid()}`;
        const ruleValues: any = {
          rule_action_notification_send_to:
            rule.rule_actions[0].rule_action_notification_send_to.split(','),
          rule_action_type: rule.rule_actions[0].rule_action_type,
          rule_condition_type: rule.rule_conditions[0].rule_condition_type,
          rule_condition_id: rule.rule_conditions[0].rule_condition_id,
          rule_action_id: rule.rule_actions[0].rule_action_id,
        };

        if (
          rule.rule_conditions[0].rule_condition_type === 'STRATEGY_SPEND_CHECK'
        ) {
          const ruleThreshold =
            rule.rule_conditions[0].rule_condition_meta.threshold * 100;
          ruleValues['threshold'] = 100 - ruleThreshold; // BE threshold is spend/budget
        }

        strategyRulesFormValues[ruleKey] = ruleValues;
      }
    });

    formState.merchant_rules = strategyRulesFormValues;
  }

  if (flowType === 'clone') {
    formState.strategy_name += ` (Copy)`;
    formState.strategy_datetime_created = null;
    formState.strategy_datetime_start = null;
    formState.strategy_datetime_end = null;
    formState.merchant_rules = {};
    formState.isFlatFeeEnabled = rateTiers?.some(
      (rt) => rt.flat_fees.length > 0,
    );
    formState.gifting = {};
    formState.strategy_is_enabled = false;
    formState.strategy_is_draft = true;
  }

  return formState;
};

export const buildTargetingFormValues = async ({ state, actions }: Context) => {
  const { merchant, targeting, flowType } = state.ecommStrategyManager;
  if (
    merchant === null ||
    targeting === null ||
    targeting.target_expression === null ||
    flowType === 'create'
  ) {
    return {
      targetExpressionValues: {},
      targetExpressionFormat: [],
    };
  }

  const { target_rules, target_expression } = targeting;
  const { strategyCloneOriginal } = state.ecommStrategyManager;
  const targetRules = target_rules;
  const targetExpression = target_expression;
  const cloneId = strategyCloneOriginal?.strategy_id;

  const formatTargetRule = async (rule: TargetRule) => {
    const TargetFieldType: any = {
      target_rule_sku_list_id: 'sku',
      target_rule_brand_list_id: 'brand',
      target_rule_brand_url_list_id: 'brand_url',
      target_rule_google_category_list_id: 'google_category',
      target_rule_merchant_category_list_id: 'merchant_category',
      target_rule_publisher_list_id: 'publisher',
      target_rule_supplier_type: 'supplier_type',
      target_rule_supplier_link_type: 'supplier_link_type',
      target_rule_link_creation_datetime: 'link_date',
    };

    const typeIdPair: [string, string | number] = Object.entries(rule).filter(
      ([listType, listId]) => {
        return (
          !['target_rule_operator', 'target_rule_id'].includes(listType) &&
          listId !== null
        );
      },
    )[0];

    if (!typeIdPair) {
      return null;
    }

    const targetListType = TargetFieldType[typeIdPair[0]];
    const targetListId = typeIdPair[1] as number;

    if (targetListType === 'supplier_type') {
      return {
        target_rule_id: rule.target_rule_id,
        target_list_type: targetListType,
        target_rule_operator: rule.target_rule_operator,
        target_rule_supplier_type: rule.target_rule_supplier_type,
      };
    }

    if (targetListType === 'supplier_link_type') {
      return {
        target_rule_id: rule.target_rule_id,
        target_list_type: targetListType,
        target_rule_operator: rule.target_rule_operator,
        target_rule_supplier_link_type: rule.target_rule_supplier_link_type,
      };
    }

    if (targetListType === 'link_date') {
      return {
        target_rule_id: rule.target_rule_id,
        target_list_type: targetListType,
        target_rule_operator: rule.target_rule_operator,
        target_rule_link_date: rule.target_rule_link_creation_datetime,
      };
    }

    return actions.ecommStrategyManager
      .getTargetListById({
        merchantId: merchant.merch_id,
        targetListType,
        targetListId,
      })
      .then((targetData: any) => {
        const targetList = targetData.target_list;
        const targetCount = targetData.page_info.total_items;

        const targetListItems = targetList.map((item: any) => {
          return item[`target_${targetListType}`];
        });

        return {
          target_rule_id: rule.target_rule_id,
          target_list_type: targetListType,
          target_rule_operator: rule.target_rule_operator,
          target_list_items: targetListItems,
          target_list: targetList,
          target_list_id: targetListId,
          target_count: targetCount,
        };
      });
  };

  const targetRuleValues = await Promise.all(
    targetRules.map((rule: any) => formatTargetRule(rule)),
  );

  const targetExpressionValues: { [k: string]: any } = {};

  targetRuleValues.forEach((rule: any) => {
    if (rule) {
      const ruleKey = cloneId ? `new-${uuid()}` : rule.target_rule_id;
      targetExpressionValues[ruleKey] = rule;
    }
  });

  let expressionFormat = JSON.parse(targetExpression);

  if (cloneId && targetExpressionValues) {
    const cloneExpressionFormat: string[][] = expressionFormat.map(
      (targetGroup: string[]) => {
        return targetGroup.map((targetRuleId: string) => {
          return (
            Object.entries(targetExpressionValues).find(
              ([, ruleValue]: [string, any]) =>
                ruleValue.target_rule_id === targetRuleId,
            ) as any
          )[0];
        });
      },
    );

    expressionFormat = cloneExpressionFormat;
  }

  if (expressionFormat.length === 0) {
    expressionFormat.push([]);
  }

  return { targetExpressionFormat: expressionFormat, targetExpressionValues };
};

export const buildCustomizationFormValues = async ({
  state,
  actions,
}: Context) => {
  const {
    merchant,
    rateTiers,
    gifting,
    strategy,
    strategyCloneOriginal,
    flowType,
  } = state.ecommStrategyManager;
  const { getTargetListById, setGifting } = actions.ecommStrategyManager;

  const rateTierFormValues: any = {};
  let giftingFormValues: any = {};

  if ((!strategy && flowType === 'edit') || !rateTiers) throw new Error();

  rateTiers
    .filter((rt) => !rt.rate_tier_is_default)
    .forEach((rt) => {
      const rateTierFormValue: any = pick(rt, [
        'rate_tier_name',
        'rate_tier_description',
        'strategy_id',
        'rate_tier_is_enabled',
        'rate_tier_is_default',
        'flat_fee_gmv_goal',
        'is_base_rate',
      ]);

      const rateTierId =
        strategy?.strategy_id !== rt.strategy_id
          ? `new-${uuid()}`
          : rt.rate_tier_id;

      rateTierFormValue.rate_tier_id = rateTierId;

      const strategySource =
        strategy === null && flowType === 'clone'
          ? strategyCloneOriginal
          : strategy;

      rateTierFormValue.pricingModel = strategySource?.strategy_pricing_model;

      if (strategySource?.strategy_pricing_model === 'CPC') {
        rateTierFormValue.pricingModelValue = rt.strategy_bid;
      } else if (strategySource?.strategy_pricing_model === 'EPP') {
        rateTierFormValue.pricingModelValue = rt.strategy_roi_goal;
      }

      rateTierFormValue.paymentModel = strategySource?.strategy_payment_model;
      if (!rt.rate_tier_is_enabled) {
        rateTierFormValue.paymentModelValue = null;
      } else if (strategySource?.strategy_payment_model === 'EPP') {
        rateTierFormValue.paymentModelValue = rt.strategy_publisher_roi_target;
      } else if (strategySource?.strategy_payment_model === 'EPC') {
        rateTierFormValue.paymentModelValue = rt.strategy_publisher_epc;
      } else if (strategySource?.strategy_payment_model === null) {
        rateTierFormValue.paymentModelValue = rt.strategy_cogs
          ? rt.strategy_cogs * 100
          : null;
      }

      if (rateTierFormValue.paymentModelValue) {
        rateTierFormValue.paymentModelValue = parseFloat(
          rateTierFormValue.paymentModelValue,
        ).toFixed(2);
      }

      if (rt.publisher_type) {
        rateTierFormValue.partnerGrouping = 'partnerType';
        rateTierFormValue.partnerValue =
          rt.publisher_type === 1 ? 'publisher' : 'creator';
      }

      if (rt.publisher_list_scope === 1) {
        rateTierFormValue.partnerGrouping = 'partnerList';
        rateTierFormValue.partnerListValue =
          rt.publisher_list_items?.map((item: any) => item.target_publisher) ||
          [];
        rateTierFormValue.partnerValue = rt.publisher_list_id;
        rateTierFormValue.use_custom_lists = true;
      } else if (rt.publisher_list_id) {
        rateTierFormValue.partnerGrouping = 'partnerList';
        rateTierFormValue.partnerValue = rt.publisher_list_id;
        rateTierFormValue.partnerListValue =
          rt.publisher_list_items?.map((item: any) => item.target_publisher) ||
          [];
      }

      if (rt.flat_fees) {
        const flatFeesMap: any = {};
        rt.flat_fees.forEach((ff: any) => {
          if (flowType === 'clone') {
            const clonedFlatFee = cloneDeep(ff);
            clonedFlatFee.flat_fee_id = `new-${uuid()}`;
            delete clonedFlatFee.rate_tier_id;
            flatFeesMap[`new-${uuid()}`] = clonedFlatFee;
          } else {
            flatFeesMap[ff.flat_fee_id] = ff;
          }
        });
        rateTierFormValue.flat_fees = flatFeesMap;
      }

      if (rt.attribution_window) {
        const attributionHours = rt.attribution_window / 60 / 60;
        // Determine if attribution was set by hours or days by checking modulo 24 over the number of hours
        const attributionByDays = attributionHours % 24;
        rateTierFormValue.rate_tier_attribution_enabled = true;
        rateTierFormValue.rate_tier_attribution_window =
          attributionByDays === 0 ? 'days' : 'hours';
        rateTierFormValue.rate_tier_attribution_duration =
          attributionByDays === 0 ? attributionHours / 24 : attributionHours;
      }

      rateTierFormValues[rateTierId] = rateTierFormValue;
    });

  if (merchant && gifting && gifting.strategy_gift_sku_list_id) {
    giftingFormValues = pick(gifting, [
      'strategy_gift_id',
      'strategy_gift_signup_url',
      'strategy_gift_description',
      'strategy_gift_sku_list_id',
      'strategy_gift_quantity',
    ]);
    const targetListData = await getTargetListById({
      merchantId: merchant?.merch_id,
      targetListType: 'sku',
      targetListId: parseInt(gifting.strategy_gift_sku_list_id),
    });
    const skuList = targetListData.target_list;
    const skuListItems = skuList.map((item: any) => item.target_sku);
    setGifting({ ...gifting, skuList, skuListItems });
  }

  const isFlatFeeEnabled = rateTiers.some((rt) => rt.flat_fees.length > 0);
  const isFlatFeeGMVGoalEnabled = rateTiers.some(
    (rt) => !!rt.flat_fee_gmv_goal && parseFloat(rt.flat_fee_gmv_goal) > 0,
  );

  if (flowType === 'clone') {
    giftingFormValues = {};
  }

  return {
    rate_tiers: rateTierFormValues,
    gifting: giftingFormValues,
    isFlatFeeEnabled: isFlatFeeEnabled,
    isFlatFeeGMVGoalEnabled: isFlatFeeGMVGoalEnabled,
  };
};

export const setInitialFormState = async ({ state, actions }: Context) => {
  const setUpPageFormValues =
    actions.ecommStrategyManager.buildSetUpPageFormValues();

  const targetingValues: {
    targetExpressionFormat: any[][];
    targetExpressionValues: { [k: number]: TargetRuleForm };
  } = await actions.ecommStrategyManager.buildTargetingFormValues();

  const customizationValues =
    await actions.ecommStrategyManager.buildCustomizationFormValues();

  state.ecommStrategyManager.formState = await {
    ...setUpPageFormValues,
    ...targetingValues,
    ...customizationValues,
  };

  state.ecommStrategyManager.formState.workingBudget =
    actions.ecommStrategyManager.getWorkingBudget();
};
//</editor-fold>

//<editor-fold desc="Data Fetchers">
export const getMerchantById = ({ effects }: Context, merchantId: number) => {
  const endpoint = `/api/v0/merchants/${merchantId}/`;

  return effects.api
    .get(endpoint)
    .then((res: any) => Promise.resolve(res.data.data[0]));
};

export const getStrategyGroupById = (
  { effects }: Context,
  {
    strategyGroupId,
    merchantId,
  }: { strategyGroupId: number; merchantId: number },
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/strategy_groups/${strategyGroupId}/strategy_group_by_id/`;

  return effects.api
    .get(endpoint)
    .then((res: any) => Promise.resolve(res.data.data[0]));
};

export const getStrategyById = async (
  { effects }: Context,
  { strategyId, merchantId }: { strategyId: number; merchantId: number },
) => {
  if (!strategyId) {
    return null;
  }

  const endpoint = `/api/v0/merchants/${merchantId}/strategies/${strategyId}/strategy_by_id/`;

  return await effects.api
    .get(endpoint)
    .then((res: any) => Promise.resolve(res.data.data[0]));
};

export const getMerchantCreatorTiers = async (
  { effects }: Context,
  merchantId: number,
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/creator_tiers/`;

  return await effects.api
    .get(endpoint)
    .then((res: any) => {
      return Promise.resolve(res.data.data[0]);
    })
    .catch(() => {
      console.error('No data');
      return {};
    });
};

export const getStrategyRules = async (
  { effects }: Context,
  { merchantId, strategyId }: { merchantId: number; strategyId: number },
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/rules/?strategies=${strategyId}`;

  return await effects.api.get(endpoint).then((res: any) => {
    return Promise.resolve(res.data.data[0]);
  });
};

export const getRateTiersByStrategyId = (
  { effects }: Context,
  { merchantId, strategyId }: { merchantId: number; strategyId: number },
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/strategies/${strategyId}/rate_tiers/`;

  return effects.api
    .get(endpoint)
    .then((res: any) => Promise.resolve(res.data.data[0]));
};

export const getGiftingByStrategyId = (
  { effects }: Context,
  { merchantId, strategyId }: { merchantId: number; strategyId: number },
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/strategies/${strategyId}/strategy_gift/`;
  const params = {
    return_entity: true,
  };

  return effects.api.get(endpoint, params).then((res: any) => {
    const gifts = res.data.data[0].gift.length > 0 ? res.data.data[0].gift : [];
    return Promise.resolve(
      gifts.find((gift: any) => !gift.strategy_gift_is_archived) || [],
    );
  });
};

export const getTargetExpressionById = (
  { effects }: Context,
  { merchantId, expressionId }: { merchantId: number; expressionId: number },
) => {
  if (!expressionId) {
    return null;
  }

  const endpoint = `/api/v0/merchants/${merchantId}/target_expressions/${expressionId}/target_expression_by_id/`;

  return effects.api
    .get(endpoint)
    .then((res: any) => Promise.resolve(res.data.data[0]));
};

export const getTargetListsByType = (
  { effects }: Context,
  {
    merchantId,
    listType,
  }: { merchantId: number; listType: 'brand' | 'publisher' },
) => {
  const endpoint = `/api/v0/merchants/target_lists/${listType}/target_list_info/`;
  return effects.api
    .get(endpoint, {
      merch_id: merchantId,
      scopes: 'account_scope,network_scope',
    })
    .then((res: any) => {
      const targetLists = res.data.data[0].target_list_info;
      targetLists.forEach((list: any, idx: number) => {
        targetLists[idx].partnerCount = list.target_list_count;
      });

      return targetLists;
    });
};

export const getTargetListById = (
  { effects }: Context,
  {
    merchantId,
    targetListType,
    targetListId,
  }: { merchantId: number; targetListType: string; targetListId: number },
) => {
  const endpoint = `/api/v0/merchants/${merchantId}/target_lists/${targetListType}/${targetListId}/target_list_by_id/`;
  return effects.api
    .get(endpoint, { per_page: 50000 })
    .then((res: any) => Promise.resolve(res.data.data[0]));
};

//</editor-fold>

//<editor-fold desc="Data Setters">
export const setMerchant = ({ state }: Context, merchant: Merchant) => {
  state.ecommStrategyManager.merchant = merchant;
};

export const setStrategyGroup = (
  { state }: Context,
  strategyGroup: StrategyGroup,
) => {
  state.ecommStrategyManager.strategyGroup = strategyGroup;
};

export const setStrategy = ({ state }: Context, strategy: Strategy) => {
  state.ecommStrategyManager.strategy = strategy;
};

export const setStrategyBudgetAmount = async (
  { state, actions }: Context,
  budget_amount: string,
) => {
  const { getWorkingBudget } = actions.ecommStrategyManager;
  state.ecommStrategyManager.formState.strategy_budget_amount = budget_amount;
  state.ecommStrategyManager.formState.workingBudget = getWorkingBudget();
};

export const setStrategyFlatFeeRate = async (
  { state, actions }: Context,
  flat_fee_rate: string,
) => {
  const { getWorkingBudget } = actions.ecommStrategyManager;
  state.ecommStrategyManager.formState.flat_fee_rate = flat_fee_rate;
  state.ecommStrategyManager.formState.workingBudget = getWorkingBudget();
};

export const setStrategyRules = (
  { state }: Context,
  strategyRules: StrategyRules,
) => {
  state.ecommStrategyManager.strategyRules = strategyRules;
};

export const setCloneStrategy = ({ state }: Context, strategy: Strategy) => {
  state.ecommStrategyManager.strategyCloneOriginal = strategy;
};

export const setCreatorTiers = (
  { state }: Context,
  creatorTiers: { [key in CreatorTiers]: CreatorTier },
) => {
  state.ecommStrategyManager.creatorTiers = creatorTiers;
};

export const setRateTiers = ({ state }: Context, rateTiers: RateTier[]) => {
  state.ecommStrategyManager.rateTiers = rateTiers;
};

export const setGifting = ({ state }: Context, gifting: Gifting) => {
  state.ecommStrategyManager.gifting = gifting;
};

export const setFlowType = ({ state }: Context, flowType: FlowType) => {
  state.ecommStrategyManager.flowType = flowType;
};

export const setTargeting = (
  { state }: Context,
  targeting: Targeting | null,
) => {
  state.ecommStrategyManager.targeting = targeting;
};

export const setTargetLists = (
  { state }: Context,
  {
    listType,
    targetLists,
  }: { listType: 'brand' | 'publisher'; targetLists: any },
) => {
  state.ecommStrategyManager.targetLists[listType] = targetLists;
};

export const setMerchantAttributionWindow = (
  { state }: Context,
  merchAttributionWindow: number | null = null,
) => {
  if (state.ecommStrategyManager.merchant !== null) {
    state.ecommStrategyManager.merchant.attribution_window =
      merchAttributionWindow;
  }
};
// </editor-fold>

//<editor-fold desc="Page State Setters">
export const setIsLoading = ({ state }: Context, isLoading: boolean) => {
  state.ecommStrategyManager.pageState.isLoading = isLoading;
};

export const setIsSubmitting = ({ state }: Context, isSubmitting: boolean) => {
  state.ecommStrategyManager.pageState.isSubmitting = isSubmitting;
};

export const setPage = ({ state }: Context, page: string) => {
  state.ecommStrategyManager.pageState.page = page;
};

export const setPageIsDirty = ({ state }: Context, isDirty: boolean) => {
  state.ecommStrategyManager.pageState.isDirty = isDirty;
};

export const setInputErrors = (
  { state }: Context,
  inputErrors: { [k: string]: string },
) => {
  state.ecommStrategyManager.pageState.inputErrors = inputErrors;
};

export const setFormErrors = ({ state }: Context, formErrors: string[]) => {
  state.ecommStrategyManager.pageState.formErrors = formErrors;
};
//</editor-fold>

//<editor-fold desc="API">
export const createStrategy = async (
  { effects }: Context,
  { merchantId, strategy }: { merchantId: number; strategy: any },
) => {
  return await effects.api.post(
    `/api/v0/merchants/${merchantId}/create_strategy/`,
    { strategy },
  );
};

export const updateStrategy = async (
  { effects }: Context,
  {
    merchantId,
    strategyId,
    strategy,
  }: { merchantId: number; strategyId: number; strategy: Strategy },
) => {
  return await effects.api.put(
    `/api/v0/merchants/${merchantId}/strategies/${strategyId}/update_strategy/`,
    { strategy },
  );
};

export const archiveStrategy = async (
  { state, effects }: Context,
  { strategyId }: { strategyId: number },
) => {
  const { merchant } = state.ecommStrategyManager;
  if (!merchant) throw new Error('Source data unavailable');

  return await effects.api.put(
    `/api/v0/merchants/${merchant.merch_id}/strategies/${strategyId}/update_strategy/`,
    { strategy: { strategy_is_enabled: false, strategy_is_archived: true } },
  );
};

export const publishStrategy = async (
  { state, effects }: Context,
  { strategyId }: { strategyId: number },
) => {
  const { merchant } = state.ecommStrategyManager;
  if (!merchant) throw new Error('Source data unavailable');

  return await effects.api.put(
    `/api/v0/merchants/${merchant.merch_id}/strategies/${strategyId}/update_strategy/`,
    { strategy: { strategy_is_enabled: true, strategy_is_draft: false } },
  );
};

export const saveStrategyImage = (
  { effects }: Context,
  {
    strategyId,
    merchantId,
    strategyImage,
  }: { strategyId: number; merchantId: number; strategyImage: File },
) => {
  const formData = new FormData();
  formData.append('file', strategyImage);
  return effects.api.post(
    `/api/v0/merchants/${merchantId}/strategies/${strategyId}/upload_thumbnail/`,
    formData,
  );
};

export const createAutomationRule = (
  { effects }: Context,
  { rule, merchantId }: { rule: any; merchantId: number },
) => {
  try {
    return effects.api.post(`/api/v0/merchants/${merchantId}/rules/`, rule);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const updateAutomationRule = async (
  { effects }: Context,
  {
    rule,
    merchantId,
    ruleId,
  }: { rule: any; merchantId: number; ruleId: string | number },
) => {
  try {
    return await effects.api.put(
      `/api/v0/merchants/${merchantId}/rules/${ruleId}/`,
      rule,
    );
  } catch (e: any) {
    throw new Error(e);
  }
};
export const createRateTier = (
  { state, effects }: Context,
  rateTier: RateTier,
) => {
  const { merchant, strategy } = state.ecommStrategyManager;

  if (!merchant || !strategy) throw new Error();

  const endpoint = `/api/v0/merchants/${merchant.merch_id}/strategies/${strategy.strategy_id}/rate_tiers/`;
  const payload: any = { ...rateTier };
  delete payload.rate_tier_id;

  return effects.api.post(endpoint, { rate_tier: payload });
};

export const updateRateTier = (
  { state, effects }: Context,
  rateTier: RateTier,
) => {
  const { merchant, strategy } = state.ecommStrategyManager;

  if (!merchant || !strategy) {
    throw new Error();
  }

  const endpoint = `/api/v0/merchants/${merchant.merch_id}/strategies/${strategy.strategy_id}/rate_tiers/${rateTier.rate_tier_id}/`;

  return effects.api.put(endpoint, { rate_tier: rateTier });
};

export const updateTargetList = (
  { state, effects }: Context,
  {
    targetListItems,
    targetListType,
    targetListId,
  }: {
    targetListItems: any[];
    targetListType: TargetType;
    targetListId: string;
  },
) => {
  const { merchant } = state.ecommStrategyManager;

  if (!merchant) {
    throw new Error();
  }

  const endpoint = `/api/v0/merchants/${merchant.merch_id}/target_lists/${targetListType}/${targetListId}/update_target_list/`;

  return effects.api
    .put(endpoint, {
      target_list: {
        target_list_items: targetListItems,
      },
    })
    .then(() => targetListId)
    .catch((e: Error) => {
      throw e;
    });
};

export const createTargetList = (
  { state, effects }: Context,
  {
    targetListItems,
    targetListType,
  }: { targetListItems: any[]; targetListType: TargetType },
) => {
  const { api } = effects;
  const { merchant, strategy } = state.ecommStrategyManager;

  if (!merchant || !strategy) throw new Error();

  const endpoint = `/api/v0/merchants/${merchant.merch_id}/target_lists/${targetListType}/create_target_list/`;

  return api
    .post(endpoint, {
      target_list: {
        target_list_name: `${strategy.strategy_id}_${targetListType}`,
        target_list_type: targetListType,
        target_list_items: targetListItems,
      },
    })
    .then((resp: any) => resp.data.data[0].target_list_id)
    .catch((err: Error) => {
      throw err;
    });
};

export const updateTargetExpression = async (
  { state, effects }: Context,
  targetExpression: TargetRule[][],
) => {
  const { merchant, strategy } = state.ecommStrategyManager;

  if (!merchant || !strategy) throw new Error();

  const endpoint = `/api/v0/merchants/${merchant.merch_id}/target_expressions/${strategy.target_expression_id}/update_target_expression/`;

  return effects.api.put(endpoint, {
    target_expression: {
      strategy_id: strategy.strategy_id,
      target_rules: targetExpression,
    },
  });
};

export const createTargetExpression = pipe(
  waitUntil((state: any) => !!state.ecommStrategyManager.strategy),
  async ({ state, effects }: Context, targetExpression: TargetRule[][]) => {
    const { merchant, strategy } = state.ecommStrategyManager;
    if (!merchant || !strategy) throw new Error();

    const endpoint = `/api/v0/merchants/${merchant.merch_id}/target_expressions/create_target_expression/`;

    return await effects.api.post(endpoint, {
      target_expression: {
        strategy_id: strategy.strategy_id,
        target_rules: targetExpression,
      },
    });
  },
);

//</editor-fold>

//<editor-fold desc="Form State Setters">
export const setFormValues = (
  { state }: Context,
  newFormValues:
    | {
        field: string;
        value: any;
      }
    | {
        field: string;
        value: any;
      }[],
) => {
  state.ecommStrategyManager.pageState.isDirty = true;
  const newValues: { field: string; value: any }[] = Array.isArray(
    newFormValues,
  )
    ? newFormValues
    : [newFormValues];

  newValues.forEach(({ field, value }) => {
    (state.ecommStrategyManager.formState as any)[field] = value;
  });
};

export const setCompletedStrategy = (
  { state }: Context,
  options: { reset?: boolean; action?: null | 'publish' } = {
    reset: false,
    action: null,
  },
) => {
  const { strategy, strategyGroup, flowType }: any = state.ecommStrategyManager;

  if (!options.reset && (!strategyGroup || !strategy))
    throw new Error('Missing source data');

  state.ecommStrategyManager.completedStrategy =
    options.reset || !strategy.strategy_id
      ? null
      : {
          flowType,
          action: options.action,
          strategyId: strategy.strategy_id,
          strategyGroupId: strategyGroup.strategy_group_id,
        };
};

export const calculatePartnerRates = (
  { state }: Context,
  pricingModelValue: string,
) => {
  const { formState, merchant } = state.ecommStrategyManager;

  if (!merchant) throw new Error('Missing source data');

  if (formState.isPaymentModelEnabled) {
    return null;
  }

  if (
    formState.strategy_pricing_model === 'EPP' &&
    !merchant.epp_fee_rate &&
    !formState?.epp_fee_rate
  ) {
    return null;
  }

  if (
    formState.strategy_pricing_model === 'CPC' &&
    !merchant.cpc_fee_rate &&
    !formState?.cpc_fee_rate
  ) {
    return null;
  }

  let rate: number | null = null;

  // Campaign-level transaction fee acts as override, otherwise default to account-level transaction fee
  if (formState.strategy_pricing_model === 'EPP' && pricingModelValue) {
    // rate = (1 / ROI) - TransactionFee
    const howlRate = formState?.epp_fee_rate
      ? parseFloat(formState.epp_fee_rate) / 100
      : merchant.epp_fee_rate || 0; // e.g. 10% of GMV = 0.1

    rate = getPartnerRateFromROI({
      roi: parseFloat(pricingModelValue),
      howlRate,
    });
  } else if (formState.strategy_pricing_model === 'CPC' && pricingModelValue) {
    // rate = CPC - (CPC * TransactionFee)
    rate =
      parseFloat(pricingModelValue) -
      parseFloat(pricingModelValue) *
        (formState?.cpc_fee_rate
          ? parseFloat(formState.cpc_fee_rate) / 100
          : merchant.cpc_fee_rate || 0);
  }

  if (rate && rate < 0) {
    return null;
  }
  return rate;
};

export const calculateROIFromPartnerRate = (
  { state }: Context,
  partnerRateValue: string,
) => {
  const { formState, merchant } = state.ecommStrategyManager;
  const partnerRate = parseFloat(partnerRateValue);

  if (!merchant) throw new Error('Missing source data');

  if (
    formState.strategy_pricing_model === 'EPP' &&
    !merchant.epp_fee_rate &&
    !formState?.epp_fee_rate
  ) {
    return null;
  }

  if (formState.strategy_pricing_model === 'CPC') {
    return null;
  }

  if (partnerRate && partnerRate < 0) {
    return null;
  }

  const howlRate = formState?.epp_fee_rate
    ? parseFloat(formState.epp_fee_rate) / 100
    : merchant.epp_fee_rate || 0;

  if (partnerRate) {
    return getROIFromPartnerRate({ partnerRate, howlRate });
  }

  return partnerRate;
};

export const calculateTotalFlatFeeSpend = ({ state }: Context) => {
  const { formState, targetLists } = state.ecommStrategyManager;
  let total = 0;
  Object.values(formState.rate_tiers)
    .filter((rt) => rt.rate_tier_is_enabled)
    .forEach((rt) => {
      let totalBudget = 0;
      Object.values(rt.flat_fees).forEach((ff) => {
        const partnerList = !rt.use_custom_lists
          ? targetLists.publisher.find(
              (pub: any) => pub.target_list_id === rt.partnerValue,
            )
          : { partnerCount: rt.partnerListValue.length };
        totalBudget +=
          ff?.amount && partnerList ? partnerList.partnerCount * ff.amount : 0;
      });

      state.ecommStrategyManager.formState.rate_tiers[
        rt.rate_tier_id
      ].flatFeesTotalBudget = totalBudget;
      total += totalBudget;
    });

  state.ecommStrategyManager.formState.totalFlatFeeSpend = total;
};

export const onAutomationRuleChange = (
  { state, actions }: Context,
  { ruleId, field, value }: { ruleId: string; field: string; value: any },
) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;
  setPageIsDirty(true);

  (state.ecommStrategyManager.formState.merchant_rules as any)[ruleId][field] =
    value;
};

export const toggleAutomationRuleIsShowing = (
  { state }: Context,
  { ruleId, isShowing }: { ruleId: string; isShowing: boolean },
) => {
  state.ecommStrategyManager.formState.merchant_rules[ruleId].isShowing =
    isShowing;
};

export const setFlatFeeGMVGoalIsEnabled = (
  { state }: Context,
  isEnabled: boolean,
) => {
  state.ecommStrategyManager.formState.isFlatFeeGMVGoalEnabled = isEnabled;
};

export const addAutomationRule = ({ state, actions }: Context) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);
  state.ecommStrategyManager.formState.merchant_rules[`new-${uuid()}`] = {
    rule_action_type: 'SEND_NOTIFICATION',
    isShowing: true,
  };
};

export const removeAutomationRule = (
  { state, effects, actions }: Context,
  { merchantId, ruleId }: { merchantId: number; ruleId: string },
) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);
  delete state.ecommStrategyManager.formState.merchant_rules[ruleId];

  if (!ruleId.startsWith('new')) {
    return effects.api.delete(
      `/api/v0/merchants/${merchantId}/rules/${ruleId}/`,
    );
  } else {
    return Promise.resolve(true);
  }
};

export const setRateTierFormValue = (
  { state, actions }: Context,
  newValues:
    | { field: string; value: any; rateTierId: string }
    | { field: string; value: any; rateTierId: string }[],
) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;
  setPageIsDirty(true);
  const changes = Array.isArray(newValues) ? newValues : [newValues];
  changes.forEach(({ field, value, rateTierId }) => {
    (state.ecommStrategyManager.formState.rate_tiers as any)[rateTierId][
      field
    ] = value;
  });
};

export const setGiftingFormValue = (
  { state, actions }: Context,
  newValues: { field: string; value: any },
) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;
  setPageIsDirty(true);
  const changes = Array.isArray(newValues) ? newValues : [newValues];
  changes.forEach(({ field, value }) => {
    (state.ecommStrategyManager.formState.gifting as any)[field] = value;
  });
};

export const setFlatFeeFormValue = (
  { state, actions }: Context,
  {
    rateTierId,
    flatFeeId,
    field,
    value,
  }: { rateTierId: string; flatFeeId: string; field: string; value: any },
) => {
  const {
    calculateTotalFlatFeeSpend,
    validateFlatFeeDuplicates,
    setPageIsDirty,
  } = actions.ecommStrategyManager;

  setPageIsDirty(true);

  (state.ecommStrategyManager.formState.rate_tiers as any)[
    rateTierId
  ].flat_fees[flatFeeId][field] = value;

  if (field === 'datetime_start') {
    validateFlatFeeDuplicates(rateTierId);
  } else {
    calculateTotalFlatFeeSpend();
  }
};

export const toggleRateTierIsEnabled = (
  { state, actions }: Context,
  { rateTierId, isEnabled }: { rateTierId: string; isEnabled: boolean },
) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);
  state.ecommStrategyManager.formState.rate_tiers[
    rateTierId
  ].rate_tier_is_enabled = isEnabled;
};

export const addRateTier = ({ state, actions }: Context) => {
  const { strategy } = state.ecommStrategyManager;
  const { setPageIsDirty } = actions.ecommStrategyManager;

  // if (!strategy) {
  //   throw new Error();
  // }

  setPageIsDirty(true);
  const newId = `new-${uuid()}`;

  state.ecommStrategyManager.formState.rate_tiers[newId] = {
    rate_tier_id: newId,
    rate_tier_name: '',
    strategy_id: strategy?.strategy_id as any,
    rate_tier_is_enabled: true,
    rate_tier_is_default: false,
    paymentModel: strategy?.strategy_payment_model,
    paymentModelValue:
      strategy === null
        ? null
        : strategy?.strategy_payment_model === null
        ? strategy.strategy_cogs
          ? (strategy.strategy_cogs * 100).toFixed(2).toString()
          : null
        : strategy.strategy_payment_model === 'EPC'
        ? strategy.strategy_publisher_epc
        : strategy.strategy_publisher_roi_target,
    pricingModel: strategy?.strategy_pricing_model,
    pricingModelValue:
      strategy === null
        ? null
        : strategy?.strategy_pricing_model === 'CPC'
        ? strategy.strategy_bid
        : strategy.strategy_pricing_model === 'EPP'
        ? strategy.strategy_roi_goal
        : null,
    flat_fees: {},
    rate_tier_attribution_enabled: false,
    flatFeesTotalBudget: 0,
    partnerListValue: [],
  };
};

export const removeRateTier = (
  { state, actions }: Context,
  rateTierId: string,
) => {
  const { flowType, strategy } = state.ecommStrategyManager;
  const { setPageIsDirty, calculateTotalFlatFeeSpend } =
    actions.ecommStrategyManager;

  setPageIsDirty(true);

  if (flowType === 'edit' && strategy?.strategy_type === 'flat_fee') {
    state.ecommStrategyManager.formState.rate_tiers[
      rateTierId
    ].rate_tier_is_enabled = false;
  } else {
    delete state.ecommStrategyManager.formState.rate_tiers[rateTierId];
  }
  calculateTotalFlatFeeSpend();
};

export const addGift = ({ state, actions }: Context) => {
  const { strategy } = state.ecommStrategyManager;
  const { setPageIsDirty } = actions.ecommStrategyManager;

  if (!strategy) throw new Error('Source data unavailable');

  setPageIsDirty(true);

  if (!strategy) {
    throw new Error();
  }

  state.ecommStrategyManager.gifting = {
    strategy_gift_id: null,
    strategy_gift_sku_list_id: '',
    strategy_gift_description: '',
    strategy_gift_quantity: 1,
    strategy_gift_signup_url: '',
    strategy_gift_is_archived: false,
    skuList: null,
    skuListItems: null,
  };

  state.ecommStrategyManager.formState.gifting = {
    strategy_gift_id: null,
    strategy_gift_sku_list_id: '',
    strategy_gift_description: '',
    strategy_gift_quantity: 1,
    strategy_gift_signup_url: '',
    strategy_gift_is_archived: false,
  };
};

export const onGiftingSKUListChange = (
  { state, actions }: Context,
  {
    target,
  }: {
    target: {
      name: string;
      value: { target_sku: string; target_product_name: string | null }[];
    };
  },
) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;

  if (state.ecommStrategyManager.gifting) {
    setPageIsDirty(true);
    state.ecommStrategyManager.gifting.skuList = target.value;
    state.ecommStrategyManager.gifting.skuListItems = target.value.map(
      (i: { target_sku: string; target_product_name: string | null }) =>
        i.target_sku,
    );
  } else {
    throw new Error('Gifting source data unavailable');
  }
};

export const removeGifting = ({ state, actions }: Context) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);
  if (
    state.ecommStrategyManager.formState.gifting &&
    state.ecommStrategyManager.gifting &&
    state.ecommStrategyManager.gifting.strategy_gift_id
  ) {
    // if gifting is already enabled, archive
    state.ecommStrategyManager.formState.gifting.strategy_gift_is_archived =
      true;
  } else {
    // if gifting is not enabled, revert
    state.ecommStrategyManager.formState.gifting = null;
    state.ecommStrategyManager.gifting = null;
  }
};

export const addFlatFee = (
  { state, actions }: Context,
  rateTierId: string | number,
) => {
  const { formState } = state.ecommStrategyManager;
  const { toIso8601UtcDatetime, getStartOf, compareDates } = actions.date;
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);

  const flatFees = formState.rate_tiers[rateTierId].flat_fees
    ? Object.values(formState.rate_tiers[rateTierId].flat_fees).sort((a, b) =>
        compareDates({ d1: a.datetime_start, d2: b.datetime_start }),
      )
    : [];

  const strategyStartDate = formState.strategy_datetime_start;

  const newStartDate = flatFees.length
    ? toIso8601UtcDatetime(
        getStartOf({
          date: moment
            .utc(flatFees[flatFees.length - 1].datetime_start)
            .add(1, 'month'),
          unit: 'month',
        }),
      )
    : toIso8601UtcDatetime(
        getStartOf({
          date: moment.utc(strategyStartDate),
          unit: 'month',
        }),
      );

  const newId = `new-${uuid()}`;

  state.ecommStrategyManager.formState.rate_tiers[rateTierId].flat_fees[newId] =
    {
      flat_fee_id: newId,
      datetime_start: newStartDate,
    };
};

export const removeFlatFee = (
  { state, actions }: Context,
  { rateTierId, flatFeeId }: { rateTierId: string; flatFeeId: string },
) => {
  const { setPageIsDirty, calculateTotalFlatFeeSpend } =
    actions.ecommStrategyManager;

  setPageIsDirty(true);
  delete state.ecommStrategyManager.formState.rate_tiers[rateTierId].flat_fees[
    flatFeeId
  ];

  calculateTotalFlatFeeSpend();
};

export const onTargetExpressionListTypeChange = (
  { state, actions }: Context,
  {
    targetExpressionId,
    value,
  }: { targetExpressionId: string | number; value: any },
) => {
  const { formState, strategy } = state.ecommStrategyManager;
  const { setPageIsDirty } = actions.ecommStrategyManager;

  if (!strategy) throw new Error('Source data unavailable');

  setPageIsDirty(true);

  const newTargetValue = {
    ...formState.targetExpressionValues[targetExpressionId],
    target_list_items: [],
    target_list: [],
    target_list_id: null,
    target_rule_operator: null,
    target_rule_supplier_type: null,
    target_rule_link_date:
      value === 'link_date' ? moment(strategy.strategy_datetime_created) : null,
    target_rule_supplier_link_type: null,
    isTargetFromList: false,
    target_list_type: value,
  };

  state.ecommStrategyManager.formState.targetExpressionValues[
    targetExpressionId
  ] = newTargetValue;
};

export const onTargetSKUListChange = (
  { state, actions }: Context,
  { targetExpressionId, value }: { targetExpressionId: string; value: any },
) => {
  const { formState } = state.ecommStrategyManager;
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);

  let newListItems = value;
  if (
    formState.targetExpressionValues[targetExpressionId].target_list_type ===
    'sku'
  ) {
    newListItems = value.map((i: any) => i.target_sku);
  }

  state.ecommStrategyManager.formState.targetExpressionValues[
    targetExpressionId
  ]['target_list'] = value;
  state.ecommStrategyManager.formState.targetExpressionValues[
    targetExpressionId
  ]['target_list_items'] = newListItems;
};

export const setTargetExpressionFormValue = (
  { state, actions }: Context,
  {
    targetExpressionId,
    field,
    value,
  }: { targetExpressionId: any; field: string; value: any },
) => {
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);

  (state.ecommStrategyManager.formState.targetExpressionValues as any)[
    targetExpressionId
  ][field] = value;
};

export const toggleIsTargetFromList = (
  { state, actions }: Context,
  {
    isTargetFromList,
    targetExpressionId,
  }: { isTargetFromList: boolean; targetExpressionId: string },
) => {
  state.ecommStrategyManager.formState.targetExpressionValues[
    targetExpressionId
  ].isTargetFromList = isTargetFromList;
};

export const addTargetingRule = (
  { state, actions }: Context,
  targetGroup: string,
) => {
  const { formState } = state.ecommStrategyManager;
  const { setPageIsDirty } = actions.ecommStrategyManager;
  setPageIsDirty(true);

  const newId = `new-${uuid()}`;
  formState.targetExpressionFormat[parseInt(targetGroup)].push(newId);
  formState.targetExpressionValues[newId] = {
    target_list_items: [],
    target_list_type: null,
    target_rule_operator: null,
  };
};

export const removeTargetingRule = (
  { state, actions }: Context,
  { targetGroup, targetId }: { targetGroup: string; targetId: string },
) => {
  const { formState } = state.ecommStrategyManager;
  const { setPageIsDirty } = actions.ecommStrategyManager;

  setPageIsDirty(true);
  formState.targetExpressionFormat[parseInt(targetGroup)] = without(
    [...formState.targetExpressionFormat[parseInt(targetGroup)]],
    targetId,
  );
  delete formState.targetExpressionValues[targetId];
};

export const addTargetingGroup = ({ state, actions }: Context) => {
  const { formState } = state.ecommStrategyManager;
  const { setPageIsDirty } = actions.ecommStrategyManager;

  formState.targetExpressionFormat.push([]);
  setPageIsDirty(true);
};

export const removeTargetingGroup = (
  { state, actions }: Context,
  targetGroup: string,
) => {
  const { formState } = state.ecommStrategyManager;
  const { setPageIsDirty, removeTargetingRule } = actions.ecommStrategyManager;
  setPageIsDirty(true);

  const targetIdsToDelete =
    formState.targetExpressionFormat[parseInt(targetGroup)];

  targetIdsToDelete.forEach((targetId) => {
    removeTargetingRule({ targetGroup, targetId });
  });

  const newList = [...formState.targetExpressionFormat];
  newList.splice(parseInt(targetGroup), 1);

  state.ecommStrategyManager.formState.targetExpressionFormat = newList;
};

export const getPartnerListOptions = ({ state }: Context) => {
  const { targetLists } = state.ecommStrategyManager;
  const options: any = {};
  targetLists.publisher.forEach((list: any) => {
    options[list.target_list_id] = {
      value: list.target_list_id,
      label: list.target_list_name,
      global: list.target_list_scope === 'network_scope',
    };
  });

  return options;
};

//</editor-fold>

//<editor-fold desc="Savers(?)">
export const saveStrategy = async ({ state, actions }: Context) => {
  const { flowType, merchant, strategy } = state.ecommStrategyManager;
  const { createStrategy, updateStrategy, buildStrategyPayload } =
    actions.ecommStrategyManager;

  if (!merchant || !flowType) {
    throw new Error('Source data unavailable');
  }

  const strategyPayload = buildStrategyPayload();

  if (['create', 'clone'].includes(flowType) && !strategy) {
    return createStrategy({
      merchantId: merchant.merch_id,
      strategy: strategyPayload,
    })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  } else {
    return updateStrategy({
      merchantId: merchant.merch_id,
      strategyId: strategy?.strategy_id as any,
      strategy: strategyPayload,
    })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  }
};

export const saveFlatFeeStrategy = async ({ state, actions }: Context) => {
  const { flowType, strategy, merchant } = state.ecommStrategyManager;
  const {
    createStrategy,
    updateStrategy,
    buildFlatFeeStrategyPayload,
    getStrategyById,
    setStrategy,
  } = actions.ecommStrategyManager;

  if (!merchant || !flowType) {
    throw new Error('Source data unavailable');
  }

  const strategyPayload = buildFlatFeeStrategyPayload();

  if (['create', 'clone'].includes(flowType) && !strategy) {
    return await createStrategy({
      merchantId: merchant.merch_id,
      strategy: strategyPayload,
    })
      .then(async (res) => {
        await getStrategyById({
          strategyId: res.data.data[0].strategy_id,
          merchantId: merchant.merch_id,
        }).then(async (strategy) => {
          await setStrategy(strategy);
        });
        return res;
      })
      .catch((err) => {
        throw err;
      });
  } else {
    return await updateStrategy({
      merchantId: merchant.merch_id,
      strategyId: strategy?.strategy_id as any,
      strategy: strategyPayload,
    })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  }
};

export const saveFlatFees = async ({ state, actions }: Context) => {
  const { formState } = state.ecommStrategyManager;
  const { buildFlatFeesPayloads, createRateTier, updateRateTier } =
    actions.ecommStrategyManager;
  const flatFeesPayloads = await buildFlatFeesPayloads();

  return Promise.all(
    flatFeesPayloads.map((rt: RateTier) => {
      if (
        typeof rt.rate_tier_id === 'string' &&
        rt.rate_tier_id.startsWith('new')
      ) {
        return createRateTier(rt)
          .then((res: any) => {
            const newRateTierId = res.data.data[0].rate_tier.rate_tier_id;
            const newRateTier = { ...formState.rate_tiers[rt.rate_tier_id] };
            newRateTier.rate_tier_id = newRateTierId;

            formState.rate_tiers[newRateTierId] = newRateTier;
            delete formState.rate_tiers[rt.rate_tier_id];

            return Promise.resolve(res.data.data[0].rate_tier);
          })
          .catch((error: any) => {
            throw error;
          });
      } else {
        return updateRateTier(rt);
      }
    }),
  ).then((res) => {
    return Promise.resolve(res);
  });
};

export const saveAutomationRules = async ({ state, actions }: Context) => {
  const {
    buildAutomationRulesPayload,
    createAutomationRule,
    updateAutomationRule,
  } = actions.ecommStrategyManager;
  const { merchant } = state.ecommStrategyManager;

  if (!merchant) return false;

  const rulesPayload = buildAutomationRulesPayload();

  return await Promise.all(
    rulesPayload.map(async (rule) => {
      if (rule.merchant_rule.rule_id) {
        return await updateAutomationRule({
          merchantId: merchant.merch_id,
          ruleId: rule.merchant_rule.rule_id,
          rule,
        });
      } else {
        return await createAutomationRule({
          merchantId: merchant.merch_id,
          rule,
        });
      }
    }),
  );
};

export const saveTargeting = async ({ state, actions }: Context) => {
  const { strategy } = state.ecommStrategyManager;
  if (!strategy) throw new Error();

  const {
    buildTargetingPayload,
    createTargetExpression,
    updateTargetExpression,
  } = actions.ecommStrategyManager;

  state.ecommStrategyManager.pageState.isSubmitting = true;
  const targetingPayload = await buildTargetingPayload();

  return strategy.target_expression_id
    ? await updateTargetExpression(targetingPayload)
    : await createTargetExpression(targetingPayload);
};

export const saveRateTiers = async ({ state, actions }: Context) => {
  const { formState } = state.ecommStrategyManager;
  const { buildRateTiersPayloads, createRateTier, updateRateTier } =
    actions.ecommStrategyManager;

  const rateTierPayloads = await buildRateTiersPayloads();

  return Promise.all(
    rateTierPayloads.map((rt: RateTier) => {
      if (
        typeof rt.rate_tier_id === 'string' &&
        rt.rate_tier_id.startsWith('new')
      ) {
        return createRateTier(rt)
          .then((res: any) => {
            const newRateTierId = res.data.data[0].rate_tier.rate_tier_id;
            const newRateTier = { ...formState.rate_tiers[rt.rate_tier_id] };
            newRateTier.rate_tier_id = newRateTierId;

            formState.rate_tiers[newRateTierId] = newRateTier;
            delete formState.rate_tiers[rt.rate_tier_id];

            return Promise.resolve(res.data.data[0].rate_tier);
          })
          .catch((error: any) => {
            throw error;
          });
      } else {
        return updateRateTier(rt);
      }
    }),
  ).then((res) => {
    Promise.resolve(res);
  });
};

export const saveGifting = async ({ state, actions, effects }: Context) => {
  const { merchant, strategy, formState, gifting } = state.ecommStrategyManager;
  const { createTargetList, updateTargetList } = actions.ecommStrategyManager;

  if (!merchant || !strategy || !gifting || !formState.gifting) {
    throw new Error();
  }

  const endpoint = `api/v0/merchants/${merchant.merch_id}/strategies/${strategy.strategy_id}/strategy_gift/`;
  const isNew =
    !formState.gifting || !formState.gifting.strategy_gift_sku_list_id;

  // if new, create the sku target list
  if (isNew) {
    if (!gifting.skuListItems || gifting.skuListItems.length === 0) {
      return Promise.resolve({ success: false });
    } else {
      const newTargetListId = await createTargetList({
        targetListItems: gifting.skuListItems,
        targetListType: 'sku',
      });

      const strategy_gift = {
        ...formState.gifting,
        strategy_gift_sku_list_id: newTargetListId,
      };
      delete strategy_gift.strategy_gift_id;

      // POST = create gift
      const createGiftResponse = await effects.api.post(endpoint, {
        strategy_gift,
        return_entity: true,
      });

      formState.gifting.strategy_gift_id =
        createGiftResponse.data.data[0].strategy_gift_id;

      const newGiftSkuListId =
        createGiftResponse.data.data[0].strategy_gift_sku_list_id;
      if (newGiftSkuListId) {
        formState.gifting.strategy_gift_sku_list_id = newGiftSkuListId;
        return Promise.resolve({ success: true });
      } else {
        return Promise.resolve({ success: false });
      }
    }
  } else {
    // update the target list
    if (gifting.skuListItems && formState.gifting?.strategy_gift_sku_list_id) {
      await updateTargetList({
        targetListItems: gifting.skuListItems,
        targetListType: 'sku',
        targetListId: formState.gifting.strategy_gift_sku_list_id,
      });
    }

    // PUT = update gift
    const updateGiftResponse = await effects.api.put(endpoint, {
      strategy_gift: formState.gifting,
    });
    return Promise.resolve({ success: updateGiftResponse.status === 200 });
  }
};
//</editor-fold>

//<editor-fold desc="Payload formatters">
export const buildStrategyPayload = ({ state, actions }: Context) => {
  const { flowType, formState, merchant, strategy, strategyGroup } =
    state.ecommStrategyManager;

  if (!merchant || !strategyGroup) throw new Error('Source data unavailable');

  const payload = pick(formState, [
    'strategy_type',
    'strategy_budget_type',
    'strategy_tagline',
    'strategy_key_objective',
    'strategy_name',
    'strategy_description',
    'strategy_utm_query_string',
    'strategy_thumbnail_image_url',
    'strategy_priority',
    'strategy_pricing_model',
    'strategy_payment_model',
    'strategy_is_enabled',
    'strategy_is_draft',
  ]) as any;

  if (
    !formState.strategy_tagline ||
    formState.strategy_tagline.trim().length === 0
  ) {
    payload.strategy_tagline = null;
  }

  if (
    !formState.strategy_key_objective ||
    formState.strategy_key_objective.trim().length === 0
  ) {
    payload.strategy_key_objective = null;
  }

  if (
    !formState.strategy_description ||
    formState.strategy_description.trim().length === 0
  ) {
    payload.strategy_description = null;
  }

  if (formState.strategy_thumbnail_image_url === null) {
    payload.strategy_thumbnail_image_url = null;
    state.ecommStrategyManager.formState.strategy_thumbnail_image_url = null;
  } else {
    const pathArray: any = formState.strategy_thumbnail_image_url?.split('/');
    const imageName = pathArray[pathArray.length - 1];
    payload.strategy_thumbnail_image_url = imageName;
  }

  if (formState.strategy_budget_amount) {
    payload.strategy_budget_amount = parseFloat(
      formState.strategy_budget_amount,
    );
  } else {
    payload.strategy_budget_amount = null;
  }

  const pricingModelValue = parseFloat(
    formState.strategy_pricing_model_value as any,
  );

  if (formState.strategy_pricing_model === 'EPP') {
    payload.strategy_roi_goal = pricingModelValue;
    payload.strategy_bid = null;
    payload.cpc_fee_rate = null;
  } else if (formState.strategy_pricing_model === 'CPC') {
    payload.strategy_bid = pricingModelValue;
    payload.strategy_roi_goal = null;
    payload.epp_fee_rate = null;
  }

  if (
    formState.isPaymentModelEnabled &&
    formState.strategy_payment_model_value
  ) {
    const paymentModelValue = parseFloat(
      formState.strategy_payment_model_value,
    );
    if (formState.strategy_payment_model === 'EPP') {
      payload.strategy_publisher_roi_target = paymentModelValue;
      payload.strategy_publisher_epc = null;
      payload.strategy_cogs = null;
    } else if (formState.strategy_payment_model === 'EPC') {
      payload.strategy_publisher_epc = paymentModelValue;
      payload.strategy_publisher_roi_target = null;
      payload.strategy_cogs = null;
    } else {
      payload.strategy_payment_model = null;
      payload.strategy_cogs = paymentModelValue / 100;
      payload.strategy_publisher_epc = null;
      payload.strategy_publisher_roi_target = null;
    }
  } else {
    payload.strategy_payment_model = null;
    payload.strategy_cogs = null;
    payload.strategy_publisher_epc = null;
    payload.strategy_publisher_roi_target = null;
  }

  if (formState.isTransactionFeesEnabled && formState.cpc_fee_rate) {
    payload.cpc_fee_rate = parseFloat(formState.cpc_fee_rate) / 100;
  }
  if (formState.isTransactionFeesEnabled && formState.epp_fee_rate) {
    payload.epp_fee_rate = parseFloat(formState.epp_fee_rate) / 100;
  }

  //  Prevents sending empty string
  if (
    !formState.strategy_utm_query_string ||
    formState.strategy_utm_query_string.trim().length === 0
  ) {
    payload.strategy_utm_query_string = null;
  }

  if (formState.strategy_priority === null) {
    delete payload.strategy_priority;
  }

  if (['create', 'clone'].includes(flowType as any) && !strategy) {
    payload.merch_id = merchant.merch_id;
    payload.strategy_group_id = strategyGroup.strategy_group_id;
  }

  // Override the visibility field to public as admin-only campaigns have been deprecated in the new CC
  if (formState.strategy_visibility === 'admin') {
    payload.strategy_visibility = 'public';
  }

  if (formState.strategy_datetime_start) {
    let hours = 0,
      minutes = 0;
    const seconds = 0;

    if (formState.isTimeOfDayEnabled && formState.strategy_time_start) {
      const chunks = formState.strategy_time_start.split(':');
      hours = parseInt(chunks[0]);
      minutes = parseInt(chunks[1]);
    }

    const date = new Date(formState.strategy_datetime_start);
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);
    payload.strategy_datetime_start = actions.date.toIso8601UtcDatetime(
      moment.utc(date),
    );
  }
  if (formState.strategy_datetime_end) {
    let hours = 23,
      minutes = 59,
      seconds = 0;
    if (formState.isTimeOfDayEnabled && formState.strategy_time_end) {
      const chunks = formState.strategy_time_end.split(':');
      hours = parseInt(chunks[0]);
      minutes = parseInt(chunks[1]);
    }
    if (hours === 23 && minutes === 59) {
      seconds = 59; // existing data has end dates as 23:59:59
    }
    const date = new Date(formState.strategy_datetime_end);
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);

    payload.strategy_datetime_end = actions.date.toIso8601UtcDatetime(
      moment.utc(date),
    );
  }

  return payload;
};

export const buildFlatFeeStrategyPayload = ({ state, actions }: Context) => {
  const { formState, flowType, merchant, strategyGroup } =
    state.ecommStrategyManager;

  if (!merchant || !strategyGroup) throw new Error('Source data unavailable');

  const payload = pick(formState, [
    'strategy_type',
    'strategy_name',
    'strategy_description',
    'strategy_thumbnail_image_url',
    'content_submission_url',
    'campaign_brief_url',
    'strategy_is_enabled',
    'strategy_is_draft',
  ]) as any;

  if (flowType === 'create') {
    payload.merch_id = merchant.merch_id;
    payload.strategy_group_id = strategyGroup.strategy_group_id;
    payload.strategy_force_publish = formState.strategy_force_publish;
  }

  // payload.strategy_pricing_model = 'EPP';
  payload.strategy_payment_model = null;

  if (
    !formState.strategy_description ||
    formState.strategy_description.trim().length === 0
  ) {
    payload.strategy_description = null;
  }

  if (formState.strategy_thumbnail_image_url === null) {
    payload.strategy_thumbnail_image_url = null;
    state.ecommStrategyManager.formState.strategy_thumbnail_image_url = null;
  } else {
    const pathArray: any = formState.strategy_thumbnail_image_url?.split('/');
    const imageName = pathArray[pathArray.length - 1];
    payload.strategy_thumbnail_image_url = imageName;
  }

  if (formState.strategy_budget_amount) {
    payload.strategy_budget_amount = parseFloat(
      formState.strategy_budget_amount,
    );
  } else {
    payload.strategy_budget_amount = null;
  }

  if (formState.strategy_datetime_start) {
    let hours = 0,
      minutes = 0;
    const seconds = 0;

    if (formState.isTimeOfDayEnabled && formState.strategy_time_start) {
      const chunks = formState.strategy_time_start.split(':');
      hours = parseInt(chunks[0]);
      minutes = parseInt(chunks[1]);
    }

    const date = new Date(formState.strategy_datetime_start);
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);
    payload.strategy_datetime_start = actions.date.toIso8601UtcDatetime(
      moment.utc(date),
    );
  }
  if (formState.strategy_datetime_end) {
    let hours = 23,
      minutes = 59,
      seconds = 0;
    if (formState.isTimeOfDayEnabled && formState.strategy_time_end) {
      const chunks = formState.strategy_time_end.split(':');
      hours = parseInt(chunks[0]);
      minutes = parseInt(chunks[1]);
    }
    if (hours === 23 && minutes === 59) {
      seconds = 59; // existing data has end dates as 23:59:59
    }
    const date = new Date(formState.strategy_datetime_end);
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);

    payload.strategy_datetime_end = actions.date.toIso8601UtcDatetime(
      moment.utc(date),
    );
  }

  if (
    formState.flat_fee_rate !== undefined &&
    formState.flat_fee_rate !== null
  ) {
    payload.flat_fee_rate = parseFloat(formState.flat_fee_rate) / 100;
  }

  return payload;
};

export const buildAutomationRulesPayload = ({ state }: Context) => {
  const { formState, strategy, strategyGroup, merchant } =
    state.ecommStrategyManager;

  if (!merchant || !strategyGroup || !strategy)
    throw new Error('Source data unavailable');

  return Object.entries(formState.merchant_rules).map(
    ([ruleId, ruleValues]) => {
      const {
        rule_action_notification_send_to,
        rule_action_type,
        rule_condition_type,
        threshold,
        rule_condition_id,
        rule_action_id,
      }: any = ruleValues;

      let ruleConditions;
      let ruleName;

      if (rule_condition_type === 'STRATEGY_SPEND_CHECK') {
        ruleConditions = [
          {
            rule_condition_type: rule_condition_type,
            rule_condition_meta: { threshold: (100 - threshold) / 100 }, // BE threshold is spend/budget
            strategy_id: strategy.strategy_id,
            strategy_group_id: strategyGroup.strategy_group_id,
            merch_id: merchant.merch_id,
            rule_condition_id: rule_condition_id,
          },
        ];
        ruleName = `Budget Remaining is less than ${threshold}%`;
      } else if (rule_condition_type === 'STRATEGY_UPDATED_CHECK') {
        ruleConditions = [
          {
            rule_condition_type: rule_condition_type,
            strategy_id: strategy.strategy_id,
            strategy_group_id: strategyGroup.strategy_group_id,
            merch_id: merchant.merch_id,
            rule_condition_id: rule_condition_id,
          },
        ];
        ruleName = 'Campaign Updated';
      }

      const ruleActions = [
        {
          rule_action_id: rule_action_id,
          rule_action_type: rule_action_type,
          rule_action_notification_send_to:
            rule_action_notification_send_to?.join(','),
        },
      ];

      const rulePayload = {
        merchant_rule: {
          rule_id: ruleId.startsWith('new') ? null : ruleId,
          rule_name: ruleName,
          rule_schedule_type: 'DAILY',
          rule_is_run_once: true,
          rule_conditions: ruleConditions,
          rule_actions: ruleActions,
        },
      };

      return rulePayload;
    },
  );
};

export const buildTargetingPayload = async ({ state, actions }: Context) => {
  const { formState, flowType } = state.ecommStrategyManager;
  const { createTargetList, updateTargetList } = actions.ecommStrategyManager;
  const { toIso8601UtcDatetime } = actions.date;

  const TargetTypeField = {
    sku: 'target_rule_sku_list_id',
    brand: 'target_rule_brand_list_id',
    brand_url: 'target_rule_brand_url_list_id',
    google_category: 'target_rule_google_category_list_id',
    merchant_category: 'target_rule_merchant_category_list_id',
    publisher: 'target_rule_publisher_list_id',
    supplier_type: 'target_rule_supplier_type',
    supplier_link_type: 'target_rule_supplier_link_type',
    link_date: 'target_rule_link_creation_datetime',
  };

  const rulesMap: any = {};

  await Promise.all(
    Object.entries(formState.targetExpressionValues).map(
      async ([targetRuleId, listValues]: any[]) => {
        const {
          target_list_items,
          target_list_type,
          target_list_id,
          target_rule_operator,
          target_rule_supplier_type,
          target_rule_supplier_link_type,
          target_rule_link_date,
          isTargetFromList,
        } = listValues;

        let payloadItems = null;

        if (isTargetFromList) {
          payloadItems = target_list_id;
        } else {
          if (target_list_type === 'publisher') {
            payloadItems = target_list_items.map((item: string) =>
              parseInt(item),
            );
          } else if (target_list_type === 'supplier_type') {
            payloadItems = target_rule_supplier_type;
          } else if (target_list_type === 'supplier_link_type') {
            payloadItems = target_rule_supplier_link_type;
          } else if (target_list_type === 'link_date') {
            payloadItems = toIso8601UtcDatetime(target_rule_link_date);
          } else {
            payloadItems = target_list_items.slice();
          }
        }

        let targetPayload = payloadItems;

        if (
          target_list_type !== 'supplier_type' &&
          target_list_type !== 'supplier_link_type' &&
          target_list_type !== 'link_date' &&
          !isTargetFromList
        ) {
          const newListId =
            target_list_id && flowType !== 'clone'
              ? await updateTargetList({
                  targetListItems: payloadItems,
                  targetListType: target_list_type,
                  targetListId: target_list_id,
                })
              : await createTargetList({
                  targetListItems: payloadItems,
                  targetListType: target_list_type,
                });

          targetPayload = parseInt(newListId);
        }

        const formattedRule: any = {
          [(TargetTypeField as any)[target_list_type]]: targetPayload,
          target_rule_operator,
        };

        if (!targetRuleId.startsWith('new')) {
          formattedRule.target_rule_id = parseInt(targetRuleId);
        }

        rulesMap[targetRuleId] = formattedRule;
      },
    ),
  );

  let payload: any[][] = [];
  formState.targetExpressionFormat.forEach((group: string[], idx: number) => {
    payload.push([]);
    group.forEach((ruleId: string) => {
      payload[idx].push(rulesMap[ruleId]);
    });
  });

  payload = payload.filter((group: string[]) => group.length);

  return payload;
};

export const buildFlatFeesPayloads = async ({ state, actions }: Context) => {
  const { strategy, formState } = state.ecommStrategyManager;

  if (!strategy) throw new Error();

  return Promise.all(
    Object.values(formState.rate_tiers).map(async (rateTier, idx) => {
      const payload: any = pick(rateTier, [
        'rate_tier_name',
        'strategy_id',
        'rate_tier_id',
        'rate_tier_is_enabled',
      ]);

      payload.strategy_id = strategy.strategy_id;
      payload.rate_tier_name = `${rateTier.partnerValue}-${idx}`;

      if (formState.isFlatFeeGMVGoalEnabled && rateTier.flat_fee_gmv_goal) {
        payload.flat_fee_gmv_goal = parseFloat(rateTier.flat_fee_gmv_goal);
      }

      if (rateTier?.partnerListValue?.length > 0 && !rateTier.partnerValue) {
        const resp = await actions.ecommStrategyManager.createTargetList({
          targetListItems: rateTier.partnerListValue.map((id: string) =>
            parseInt(id),
          ),
          targetListType: 'publisher',
        });
        payload.publisher_list_id = resp;
      } else if (rateTier.use_custom_lists && rateTier.partnerValue) {
        await actions.ecommStrategyManager.updateTargetList({
          targetListItems: rateTier.partnerListValue.map((id: string) =>
            parseInt(id),
          ),
          targetListType: 'publisher',
          targetListId: rateTier.partnerValue,
        });
        payload.publisher_list_id = rateTier.partnerValue;
      } else {
        payload.publisher_list_id = rateTier.partnerValue;
      }

      if (rateTier.flat_fees && !isEmpty(rateTier.flat_fees)) {
        payload.flat_fees = Object.values(rateTier.flat_fees).map((ff: any) => {
          const formattedFlatFee = { ...ff };
          if (ff.flat_fee_id.toString().startsWith('new')) {
            delete formattedFlatFee.flat_fee_id;
          }
          formattedFlatFee.amount = parseFloat(ff.amount).toFixed(2).toString();
          formattedFlatFee.post_count = parseInt(ff.post_count);
          formattedFlatFee.datetime_start = actions.date.formatDate({
            dateObj: ff.datetime_start,
            fmt: 'YYYY-MM-DD',
            isUtc: true,
          });

          return formattedFlatFee;
        });
      } else {
        payload.flat_fees = [];
      }
      return payload;
    }),
  );
};

export const buildRateTiersPayloads = async ({ state, actions }: Context) => {
  const { formState, strategy, rateTiers } = state.ecommStrategyManager;

  if (!strategy) throw new Error();

  return Promise.all(
    Object.values(formState.rate_tiers).map(async (rateTier) => {
      const payload: any = pick(rateTier, [
        'rate_tier_name',
        'rate_tier_description',
        'strategy_id',
        'rate_tier_id',
        'rate_tier_is_enabled',
      ]);

      payload.strategy_id = strategy.strategy_id;

      if (!rateTier.rate_tier_description) {
        payload.rate_tier_description = null;
      }

      //  Pricing Model
      if (rateTier.pricingModel === 'CPC' && rateTier.pricingModelValue) {
        payload.strategy_bid = parseFloat(rateTier.pricingModelValue);
      }

      if (rateTier.pricingModel === 'EPP' && rateTier.pricingModelValue) {
        payload.strategy_roi_goal = parseFloat(rateTier.pricingModelValue);
      }

      //  Payment Model
      if (rateTier.paymentModelValue) {
        if (rateTier.paymentModel === 'EPP') {
          payload.strategy_publisher_roi_target = parseFloat(
            rateTier.paymentModelValue,
          );
        }

        if (rateTier.paymentModel === 'EPC') {
          payload.strategy_publisher_epc = parseFloat(
            rateTier.paymentModelValue,
          );
        }

        if (rateTier.paymentModel === null) {
          payload.strategy_cogs = parseFloat(rateTier.paymentModelValue) / 100;
        }
      }

      if (rateTier.partnerValue || rateTier.partnerListValue?.length > 0) {
        if (rateTier.partnerGrouping === 'partnerType') {
          payload.publisher_type =
            rateTier.partnerValue === 'publisher' ||
            rateTier.publisher_type === 1
              ? 'publisher'
              : 'creator';
        }

        if (rateTier.partnerGrouping === 'partnerList') {
          if (rateTier.partnerListValue.length > 0 && !rateTier.partnerValue) {
            const resp = await actions.ecommStrategyManager.createTargetList({
              targetListItems: rateTier.partnerListValue.map((id: string) =>
                parseInt(id),
              ),
              targetListType: 'publisher',
            });
            payload.publisher_list_id = resp;
          } else if (rateTier.use_custom_lists && rateTier.partnerValue) {
            await actions.ecommStrategyManager.updateTargetList({
              targetListItems: rateTier.partnerListValue.map((id: string) =>
                parseInt(id),
              ),
              targetListType: 'publisher',
              targetListId: rateTier.partnerValue,
            });
            payload.publisher_list_id = rateTier.partnerValue;
          } else {
            payload.publisher_list_id = rateTier.partnerValue;
          }
        }

        if (rateTier.flat_fees && !isEmpty(rateTier.flat_fees)) {
          payload.flat_fees = Object.values(rateTier.flat_fees).map(
            (ff: any) => {
              const formattedFlatFee = { ...ff };
              if (ff.flat_fee_id.toString().startsWith('new')) {
                delete formattedFlatFee.flat_fee_id;
              }
              formattedFlatFee.amount = parseFloat(ff.amount)
                .toFixed(2)
                .toString();
              formattedFlatFee.post_count = parseInt(ff.post_count);
              formattedFlatFee.datetime_start = actions.date.formatDate({
                dateObj: ff.datetime_start,
                fmt: 'YYYY-MM-DD',
                isUtc: true,
              });

              return formattedFlatFee;
            },
          );
        }
      }

      if (rateTier.rate_tier_attribution_enabled) {
        // H hours ->      H * 60 * 60 seconds
        // D Days  -> D * 24 * 60 * 60 seconds
        const attrWindow =
          parseInt(rateTier.rate_tier_attribution_duration as any) *
          60 *
          60 *
          (rateTier.rate_tier_attribution_window === 'days' ? 24 : 1);

        // If window has changed, include new window in payload
        if (
          attrWindow !==
          rateTiers.find((rt) => rt.rate_tier_id === rateTier.rate_tier_id)
            ?.attribution_window
        ) {
          payload.attribution_window = attrWindow;
        }
      } else if (rateTier.rate_tier_attribution_enabled === false) {
        // If window was enabled but is now disabled, send null value
        if (
          rateTiers.find((rt) => rt.rate_tier_id === rateTier.rate_tier_id)
            ?.attribution_window !== null
        ) {
          payload.attribution_window = null;
        }
      }

      return payload;
    }),
  );
};
// </editor-fold>

//<editor-fold desc="Page Submits">
// Set up Page missing due to changing behavior with Image upload
// export const submitSetUpForm = () => {
//
// }

export const submitRateTiersForm = ({ state, actions }: Context) => {
  const { merchant, strategy, strategyGroup, pageState } =
    state.ecommStrategyManager;

  if (!merchant || !strategy || !strategyGroup) throw new Error();

  const {
    validateRateTiersForm,
    setFormErrors,
    saveRateTiers,
    setPageIsDirty,
    setIsSubmitting,
  } = actions.ecommStrategyManager;

  setIsSubmitting(true);
  const isValid = validateRateTiersForm();

  if (!isValid) {
    return Promise.resolve({ success: false });
  }

  return saveRateTiers()
    .then(() => {
      setPageIsDirty(false);
      return Promise.resolve({ success: true });
    })
    .catch((e) => {
      setFormErrors([
        ...pageState.formErrors,
        e.message || 'Sorry, there was an error. Rate Tier could not be saved.',
      ]);
      return Promise.resolve({ success: false });
    })
    .finally(() => {
      setIsSubmitting(false);
    });
};

export const submitGiftingForm = ({ state, actions }: Context) => {
  const { merchant, strategy, strategyGroup, formState, pageState } =
    state.ecommStrategyManager;

  if (formState.gifting && Object.keys(formState.gifting).length > 0) {
    if (!merchant || !strategy || !strategyGroup) throw new Error();

    const { setPageIsDirty, setIsSubmitting, saveGifting, setFormErrors } =
      actions.ecommStrategyManager;

    setIsSubmitting(true);

    return saveGifting()
      .then((res) => {
        setPageIsDirty(false);
        if (!res.success) {
          setFormErrors([
            ...pageState.formErrors,
            'Please provide at least one sku.',
          ]);
        }
        return Promise.resolve(res);
      })
      .catch((e) => {
        setFormErrors([
          ...pageState.formErrors,
          e.message || 'Sorry, there was an error. Gifting could not be saved.',
        ]);
        return Promise.resolve({ success: false });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  } else {
    return Promise.resolve({ success: true });
  }
};

export const submitTargetingForm = async (
  { state, actions }: Context,
  { publish }: { publish?: boolean },
) => {
  const { strategy } = state.ecommStrategyManager;

  const {
    setInputErrors,
    setFormErrors,
    setIsSubmitting,
    setPageIsDirty,
    saveTargeting,
    validateTargetingForm,
    publishStrategy,
  } = actions.ecommStrategyManager;

  if (!strategy) throw new Error('Source data unavailable');

  const targetingErrors = validateTargetingForm();

  if (targetingErrors) {
    setInputErrors(targetingErrors.inputErrors);
    setFormErrors(targetingErrors.formErrors);
    return Promise.resolve({ success: false });
  } else {
    setInputErrors({});
    setFormErrors([]);
  }

  return await saveTargeting()
    .then(() => {
      setPageIsDirty(false);
      if (publish) {
        return publishStrategy({ strategyId: strategy.strategy_id })
          .then(() => {
            return Promise.resolve({ success: true });
          })
          .catch((err) => {
            throw err.message;
          });
      } else {
        return Promise.resolve({ success: true });
      }
    })
    .catch((e) => {
      setFormErrors(e);
      return Promise.resolve({ success: false });
    })
    .finally(() => {
      setIsSubmitting(false);
    });

  return;
};
//</editor-fold>

//<editor-fold desc="Validators">

export const validateSendTo = async (
  { effects }: Context,
  { sendTo, merchantId }: { sendTo: string[]; merchantId: number },
) => {
  const resp = await effects.api.get(
    `/api/v0/merchants/${merchantId}/rules/validate/`,
    { send_to: sendTo },
  );
  return resp.data.errors;
};

export const validateRateTiersForm = ({ state, actions }: Context) => {
  const { formState, pageState } = state.ecommStrategyManager;
  const { validateFlatFeeDuplicates } = actions.ecommStrategyManager;

  const rateTiersInputErrors: any = {};
  let isValid = true;
  state.ecommStrategyManager.pageState.inputErrors.rate_tiers = null;

  Object.values(formState.rate_tiers).forEach((rateTier) => {
    const rateTierErrors = {};

    const isDupValid = validateFlatFeeDuplicates(rateTier.rate_tier_id);

    if (!isDupValid) {
      isValid = false;
    }

    if (
      !rateTier.partnerValue &&
      (!rateTier.partnerListValue || rateTier?.partnerListValue?.length === 0)
    ) {
      set(
        pageState.inputErrors.rate_tiers,
        [rateTier.rate_tier_id, 'partnerValue'],
        ['Field is required'],
      );
      isValid = false;
    }

    if (rateTier.rate_tier_attribution_enabled) {
      if (!rateTier.rate_tier_attribution_duration) {
        set(
          pageState.inputErrors.rate_tiers,
          [rateTier.rate_tier_id, 'rate_tier_attribution_duration'],
          ['Field is required'],
        );
        isValid = false;
      }
      if (!rateTier.rate_tier_attribution_window) {
        set(
          pageState.inputErrors.rate_tiers,
          [rateTier.rate_tier_id, 'rate_tier_attribution_window'],
          ['Field is required'],
        );
        isValid = false;
      }
    }

    rateTiersInputErrors[rateTier.rate_tier_id] = rateTierErrors;
  });

  if (!isValid) {
    state.ecommStrategyManager.pageState.formErrors = [
      'Rate Tier config is invalid',
    ];
  } else {
    state.ecommStrategyManager.pageState.inputErrors.rate_tiers = null;
    state.ecommStrategyManager.pageState.formErrors = [];
  }

  return isValid;
};

export const validateStrategyForm = ({ state, actions }: Context) => {
  const { formState } = state.ecommStrategyManager;
  const { compareDates, compareHours } = actions.date;
  const errors: any = {};

  if (!formState.strategy_datetime_start) {
    errors.strategy_datetime_start = 'Field is required';
  } else if (
    formState.strategy_datetime_end &&
    compareDates({
      d1: formState.strategy_datetime_start,
      d2: formState.strategy_datetime_end,
    }) > 0
  ) {
    errors.strategy_datetime_start = 'Start date must come before end date';
  }

  if (formState.isTimeOfDayEnabled) {
    if (!formState.strategy_time_start) {
      errors.strategy_time_start = 'Field is required';
    } else if (
      formState.strategy_time_end &&
      compareDates({
        d1: formState.strategy_datetime_start,
        d2: formState.strategy_datetime_end,
      }) === 0
    ) {
      if (
        compareHours({
          d1: formState.strategy_time_start,
          d2: formState.strategy_time_end,
        }) === 1
      ) {
        errors.strategy_time_start = 'Must be before end time';
      }
    }
  }

  if (!formState.strategy_pricing_model_value) {
    errors.strategy_pricing_model_value = 'Field is required';
  } else {
    if (parseFloat(formState.strategy_pricing_model_value) <= 0) {
      errors.strategy_pricing_model_value = 'Value must be above 0';
    }
  }

  if (formState.isPaymentModelEnabled) {
    if (
      formState.strategy_payment_model_value &&
      formState.strategy_pricing_model_value
    ) {
      if (
        formState.strategy_payment_model === 'EPP' &&
        parseFloat(formState.strategy_payment_model_value) <= 0
      ) {
        errors.strategy_payment_model_value = 'Value must be above 0';
      }
    } else {
      errors.strategy_payment_model_value = 'Field is required';
    }
  }

  if (formState.strategy_utm_query_string) {
    if (formState.strategy_utm_query_string.length > 512) {
      errors.strategy_utm_query_string =
        'String length must be less than 512 characters';
    }

    // See https://bamerati.atlassian.net/wiki/spaces/BI/pages/2978086919/Dynamic+UTMs For more information on regex
    if (
      !formState.strategy_utm_query_string.match(
        /^[\w]+=({{\w+}}|[\w%+-])+(&[\w]+=({{\w+}}|[\w%+-])+)*$/g,
      )
    ) {
      errors.strategy_utm_query_string =
        'String does not match the required format';
    }
  }

  if (formState.isTransactionFeesEnabled) {
    if (
      formState.strategy_pricing_model === 'CPC' &&
      formState.cpc_fee_rate &&
      parseFloat(formState.cpc_fee_rate) <= 0
    ) {
      errors.cpc_fee_rate = 'Value must be above 0';
    }
    if (
      formState.strategy_pricing_model === 'EPP' &&
      formState.epp_fee_rate &&
      parseFloat(formState.epp_fee_rate) <= 0
    ) {
      errors.cpc_fee_rate = 'Value must be above 0';
    }
  }

  return !isEmpty(errors)
    ? { inputErrors: errors, formError: 'Invalid Strategy Values' }
    : {};
};

export const validateFlatFeeStrategyForm = ({ state, actions }: Context) => {
  const { formState } = state.ecommStrategyManager;
  const { compareDates } = actions.date;
  const errors: any = {};

  const URLRegex =
    /(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/g;

  if (!formState.strategy_datetime_start) {
    errors.strategy_datetime_start = 'Field is required';
  } else if (
    formState.strategy_datetime_end &&
    compareDates({
      d1: formState.strategy_datetime_start,
      d2: formState.strategy_datetime_end,
    }) > 0
  ) {
    errors.strategy_datetime_start = 'Start date must come before end date';
  }

  if (formState.campaign_brief_url) {
    if (!formState.campaign_brief_url.match(URLRegex)) {
      errors.campaign_brief_url = 'String should be a valid url';
    }
  }
  if (formState.content_submission_url) {
    if (!formState.content_submission_url.match(URLRegex)) {
      errors.content_submission_url = 'String should be a valid url';
    }
  }

  if (formState.rate_tiers) {
    if (formState.strategy_datetime_end) {
      const invalidRateTiers = [] as string[];
      Object.values(formState.rate_tiers).forEach((rt) => {
        if (isEmpty(rt.flat_fees)) {
          errors.rate_tiers = { [rt.rate_tier_id]: 'Tier must have Flat Fees' };
          return;
        }

        Object.values(rt.flat_fees).forEach((ff) => {
          const invalid =
            moment(ff.datetime_start) > moment(formState.strategy_datetime_end);
          if (invalid) {
            invalidRateTiers.push(rt.rate_tier_id);
          }
        });
      });

      if (invalidRateTiers.length) {
        errors.rate_tiers = {};
        invalidRateTiers.forEach((rtId) => {
          errors.rate_tiers[rtId] =
            'Flat Fee Dates go beyond Campaign Date Range';
        });
      }
    }
  }

  if (!isEmpty(errors)) {
    state.ecommStrategyManager.pageState.inputErrors = errors;
    state.ecommStrategyManager.pageState.formErrors = [
      'Invalid Strategy Values',
    ];
    return false;
  } else {
    state.ecommStrategyManager.pageState.inputErrors = {};
    state.ecommStrategyManager.pageState.formErrors = [];
    return true;
  }
};

export const validateAutomationRulesForm = ({ state }: Context) => {
  const { formState } = state.ecommStrategyManager;
  const ruleErrors: any = {};

  if (!isEmpty(formState.merchant_rules)) {
    Object.entries(formState.merchant_rules).forEach(([ruleId, ruleValue]) => {
      const ruleError: any = {};
      const {
        rule_action_notification_send_to,
        rule_action_type,
        rule_condition_type,
        threshold,
      } = ruleValue;

      if (!rule_condition_type) {
        ruleError['rule_condition_type'] = 'Field is required';
      }

      if (
        typeof rule_action_notification_send_to !== 'undefined' &&
        rule_action_notification_send_to.length === 0
      ) {
        ruleError['rule_action_notification_send_to'] = 'Field is required';
      }

      if (!rule_action_type) {
        ruleError['rule_action_type'] = 'Field is required';
      }

      if (rule_condition_type === 'STRATEGY_SPEND_CHECK') {
        if (!threshold) {
          ruleError['threshold'] = 'Field is Required';
        } else if (threshold < 0 || threshold > 100) {
          ruleError['threshold'] = 'Value must be above 0 or less than 100';
        }
      }

      if (Object.entries(ruleError).length > 0) ruleErrors[ruleId] = ruleError;
    });
  }

  return !isEmpty(ruleErrors)
    ? {
        inputErrors: { merchant_rules: ruleErrors },
        formError: 'Invalid Rule Configuration',
      }
    : {};
};

export const validateTargetingForm = ({ state }: Context) => {
  const { formState } = state.ecommStrategyManager;
  const targetingValues = formState.targetExpressionValues;

  const errors: any = {};

  Object.entries(targetingValues).forEach(([targetId, targetValue]) => {
    if (
      targetValue.target_rule_operator === null ||
      targetValue.target_list_type === null ||
      ([
        'sku',
        'brand',
        'google_category',
        'merchant_category',
        'publisher',
      ].includes(targetValue.target_list_type) &&
        !targetValue.isTargetFromList &&
        targetValue.target_list_items?.length === 0) ||
      (targetValue.target_list_type === 'supplier_type' &&
        targetValue.target_rule_supplier_type === null) ||
      (targetValue.target_list_type === 'supplier_link_type' &&
        targetValue.target_rule_supplier_link_type === null) ||
      (targetValue.target_list_type === 'link_date' &&
        targetValue.target_rule_link_date === null)
    ) {
      errors[targetId] = ['All fields are required'];
    }
  });

  return isEmpty(errors)
    ? false
    : { inputErrors: errors, formErrors: ['Targeting Config is Invalid'] };
};

export const validateFlatFeeDuplicates = (
  { state }: Context,
  rateTierId: string,
) => {
  const { formState, pageState } = state.ecommStrategyManager;
  const flatFees = Object.values(formState.rate_tiers[rateTierId].flat_fees);

  const dupMap: any = {};
  const dups = new Set();

  flatFees.forEach((ff) => {
    set(
      state.ecommStrategyManager.pageState.inputErrors,
      ['rate_tiers', rateTierId, 'flat_fees'],
      null,
    );

    if (dupMap[ff.datetime_start]) {
      dups.add(dupMap[ff.datetime_start]);
      dups.add(ff.flat_fee_id);
    } else {
      dupMap[ff.datetime_start] = ff.flat_fee_id;
    }
  });

  const dupIds = Array.from(dups);
  let isValid = true;

  if (dupIds.length) {
    isValid = false;
    dupIds.forEach((id: any) => {
      const currentErrs = get(
        pageState,
        [
          'inputErrors',
          'rate_tiers',
          rateTierId,
          'flat_fees',
          id,
          'datetime_start',
        ],
        null,
      );
      if (currentErrs) {
        pageState.inputErrors.rate_tiers[rateTierId].flat_fees[
          id
        ].datetime_start.push('Duplicate');
      } else {
        set(
          pageState,
          [
            'inputErrors',
            'rate_tiers',
            rateTierId,
            'flat_fees',
            id,
            'datetime_start',
          ],
          ['Duplicate'],
        );
      }
    });
  } else {
    set(
      pageState,
      ['inputErrors', 'rate_tiers', rateTierId, 'flat_fees'],
      null,
    );
  }

  return isValid;
};

//</editor-fold>

//<editor-fold desc="Helper Functions">
export const getWorkingBudget = ({ state }: Context) => {
  const { formState } = state.ecommStrategyManager;

  let workingBudget = 0;

  if (!formState.strategy_budget_amount) {
    workingBudget = 0;
  } else if (!formState.flat_fee_rate) {
    workingBudget = parseFloat(formState.strategy_budget_amount);
  } else {
    workingBudget =
      parseFloat(formState.strategy_budget_amount) -
      (parseFloat(formState.flat_fee_rate) / 100) *
        parseFloat(formState.strategy_budget_amount);
  }

  return workingBudget;
};
//</editor-fold>
