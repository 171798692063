export type ColumnPreset =
  | {
      label: string;
      value: string;
      columns: {
        label: string;
        value: string;
      }[];
      visibleInDropdown: boolean;
      className?: string;
    }
  | {
      label: string;
      value: string;
      visibleInDropdown: boolean;
      columns?: undefined;
      className?: string;
    };

export const STATUS_DROPDOWN_ITEMS = [
  { label: 'Completed', value: 3 },
  { label: 'Upcoming', value: 2 },
  { label: 'Paused', value: 4 },
  { label: 'Live', value: 1 },
];

export const DEFAULT_STATUS_DROPDOWN_ITEMS = [
  { label: 'Upcoming', value: 2 },
  { label: 'Paused', value: 4 },
  { label: 'Live', value: 1 },
];

export const DEFAULT_COLUMNS = [
  { label: 'Type', value: 'strategy_group_type' },
  { label: 'Budget', value: 'strategy_group_budget_amount' },
  { label: 'Spend', value: 'merchant_spend' },
  { label: 'Budget Remaining', value: 'strategy_group_budget_remaining' },
  { label: 'Revenue', value: 'attributed_revenue' },
  { label: 'Impressions', value: 'impressions' },
  { label: 'Clicks', value: 'clicks' },
  { label: 'Campaigns', value: 'strategy_group_number_of_strategies' },
  { label: 'Start Date', value: 'strategy_group_datetime_start' },
  { label: 'End Date', value: 'strategy_group_datetime_end' },
];

const PLANNING_COLUMNS = [
  { label: 'Type', value: 'strategy_group_type' },
  { label: 'Budget', value: 'strategy_group_budget_amount' },
  { label: 'Campaigns', value: 'strategy_group_number_of_strategies' },
  { label: 'Start Date', value: 'strategy_group_datetime_start' },
  { label: 'End Date', value: 'strategy_group_datetime_end' },
];

const MANAGEMENT_COLUMNS = [
  { label: 'Spend', value: 'merchant_spend' },
  { label: 'Budget Remaining', value: 'strategy_group_budget_remaining' },
  { label: 'Pacing', value: 'merchant_spend_pacing' },
  { label: 'Revenue', value: 'attributed_revenue' },
  { label: 'Impressions', value: 'impressions' },
  { label: 'Clicks', value: 'clicks' },
];

export const COLUMN_PRESETS = [
  {
    label: 'Default',
    value: 'default',
    columns: DEFAULT_COLUMNS,
    visibleInDropdown: true,
  },
  {
    label: 'Planning',
    value: 'planning',
    columns: PLANNING_COLUMNS,
    visibleInDropdown: true,
  },
  {
    label: 'Management',
    value: 'management',
    columns: MANAGEMENT_COLUMNS,
    visibleInDropdown: true,
  },
  // { label: 'All Columns', value: 'all', visibleInDropdown: true }, Disabled for now, default column selection is all columns
  { label: 'Custom', value: 'custom', visibleInDropdown: false },
];

export const STAT_TYPE = 'campaign-center-campaigns-tab';
