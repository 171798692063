import classNames from 'classnames';
import { useState } from 'react';
import { BulkImageModal } from './bulk-image-modal';
import { Icon } from './icon';

type ImageSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface BulkImageUploaderProps {
  addImage: (e: any) => void;

  deleteImage: any;

  maxShownImages?: number;

  imageSize?: ImageSize;

  modalHeader?: string;

  // TODO: Figure out how to get this to be more generic
  imageLinks?: { link: string | null; image_url: string[] }[];

  updateImageLinks?: any;

  disabled?: boolean;

  errorMessage?: string;
}

const imageSizeClasses = {
  xs: '',
  sm: '',
  md: 'w-9 h-9',
  lg: '',
  xl: '',
};

export const BulkImageUploader = ({
  addImage,
  deleteImage,
  maxShownImages = 3,
  imageSize = 'md',
  modalHeader,
  imageLinks,
  updateImageLinks,
  disabled,
  errorMessage,
}: BulkImageUploaderProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const imageModal = showModal ? (
    <BulkImageModal
      headerText={modalHeader}
      setShowModal={setShowModal}
      addImage={addImage}
      deleteImage={deleteImage}
      imageLinks={imageLinks}
      updateImageLinks={updateImageLinks}
      disabled={disabled}
      errorMessage={errorMessage}
    />
  ) : null;

  const displayImages: string[] =
    imageLinks
      ?.map((contentLink: any) => {
        return contentLink.image_url;
      })
      .flat() || [];

  return (
    <div className="flex flex-row justify-between items-center">
      <div data-cy="bulk-uploader-images" className="flex flex-row space-x-2">
        {displayImages.slice(0, maxShownImages).map((image, idx) => {
          return (
            <img
              src={image}
              alt={image}
              data-cy={`uploaded-image-${idx}`}
              className={classNames(
                'rounded border border-violet-400',
                imageSizeClasses[imageSize],
              )}
              onClick={() => window.open(image, '_blank')}
              key={idx}
            />
          );
        })}
        {displayImages.length > maxShownImages && (
          <div
            className={classNames(
              'flex rounded bg-violet-400 items-center justify-center text-white',
              imageSizeClasses[imageSize],
            )}
          >
            {`+${displayImages.length - maxShownImages}`}
          </div>
        )}
      </div>
      <div data-cy="bulk-upload-icon" className="mx-4 justify-self-end">
        <div onClick={() => setShowModal(true)}>
          <Icon iconName="upload" />
        </div>
      </div>
      {imageModal}
    </div>
  );
};
