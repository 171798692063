'use client';

import { useEffect, useState } from 'react';
import * as FullStory from '@fullstory/browser';

function createStub() {
  return function (...args: any[]) {
    console.log('Analytics stub called', args);
  };
}

interface AbstractFullStory {
  event: (eventName: string, eventProperties?: { [key: string]: any }) => any;
  identify: () => any;
}

export const useFullStoryAnalytics = () => {
  const [fullstory, setFullStory] = useState<any>(null);

  useEffect(() => {
    let fullstory: AbstractFullStory;

    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
      fullstory = {
        identify: createStub(),
        event: createStub(),
      };
    } else {
      fullstory = FullStory as unknown as AbstractFullStory;
    }
    setFullStory(fullstory);
  }, []);

  return fullstory;
};
