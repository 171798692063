import React, { useState } from 'react';

import Link from 'next/link';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '@frontend/shared-ui-kit/v2/dropdown-menu';
import { useActions, useAppState } from '@frontend/howl-web-app/overmind';

import classNames from 'classnames';
import { Icon } from '@frontend/shared-ui-kit/icon';
import { useClickOutside } from '@mantine/hooks';

import { AccountSwitcher } from '../navigation/account-dropdown/account-switcher';
import { useRouter } from 'next/router';

export const PortalAccountDropdown = ({
  displayName,
  showSwitchAccounts,
  accounts,
  toggleAccountSwitcher,
  setToggleAccountSwitcher,
  displayAlertIcon,
}: {
  displayName: string;
  showSwitchAccounts: boolean;
  accounts: any;
  toggleAccountSwitcher: boolean;
  setToggleAccountSwitcher: (value: boolean) => void;
  displayAlertIcon: boolean;
}) => {
  const { logout } = useActions().auth;
  const router = useRouter();
  const { selectedOrgId } = useAppState().organization;

  const { setCreatorSidebarOpen } = useActions().ui;
  const ref = useClickOutside(() => setToggleAccountSwitcher(false));
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const alertIcon = displayAlertIcon ? (
    <Icon
      iconName="filledAlert"
      size={16}
      className="inline-flex "
      dataCy="nav-payment-setup-alert"
    />
  ) : null;

  const badgeAlertIcon = displayAlertIcon ? (
    <Icon
      iconName="filledAlert"
      size={12}
      className="inline-flex absolute top-0 left-3 border border-white rounded-full"
      dataCy="nav-payment-setup-alert"
    />
  ) : null;

  if (!displayName) {
    return null;
  }

  return (
    <>
      <div className="basis-auto">
        <DropdownMenu open={open}>
          <DropdownMenuTrigger
            tabIndex={0}
            data-cy="nav-account"
            onClick={() => setOpen(true)}
            onKeyUpCapture={(event) => {
              if (event.key === 'Enter') {
                setOpen(true);
              }
            }}
            className="max-w-sm text-sm hover:bg-interaction-secondary-hover focus:bg-interaction-secondary-pressed py-2 px-4 rounded-md outline-none transition"
          >
            <div className="flex gap-x-2 items-center relative">
              {badgeAlertIcon}
              <Icon
                image={true}
                iconName="userCircle"
                size={20}
                className="inline-flex"
                dataCy="nav-payment-setup-alert"
              />
              <span
                className={classNames(
                  'line-clamp-1 text-left text-ellipsis break-all hidden xl:block xl:max-w-[160px] 2xl:max-w-[200px] whitespace-nowrap',
                )}
              >
                {displayName}
              </span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onInteractOutside={() => {
              setOpen(false);
              setCreatorSidebarOpen(false);
            }}
            className="bg-white w-72 rounded-xl overflow-hidden"
            align="end"
          >
            <div
              ref={ref}
              className={classNames(
                'w-[576px] flex flex-row transform transition',
                {
                  '-translate-x-72': toggleAccountSwitcher,
                },
              )}
            >
              <div className="w-72 flex flex-col py-2">
                <DropdownMenuItem className="mx-2 w-auto px-3 py-[9px] text-black block xl:hidden">
                  {displayName}
                </DropdownMenuItem>
                <DropdownMenuSeparator className="block xl:hidden"></DropdownMenuSeparator>

                <DropdownMenuItem
                  data-cy="nav-item-account-settings"
                  onClick={() => {
                    setOpen(false);
                    setCreatorSidebarOpen(false);
                    router.push(
                      `/publisher/${selectedOrgId}/account-settings/general-settings`,
                    );
                  }}
                  className="mx-2 w-auto px-3 py-[9px] hover:bg-interaction-selected focus:bg-interaction-selected text-black font-medium cursor-pointer rounded-xl transition"
                >
                  <span className="w-full list-none flex items-center justify-between">
                    Account Settings
                    {alertIcon}
                  </span>
                </DropdownMenuItem>
                {showSwitchAccounts ? (
                  <DropdownMenuItem
                    asChild
                    onClick={() => setToggleAccountSwitcher(true)}
                    data-cy="nav-switch-account-open"
                    className="mx-2 w-auto px-3 py-[9px] hover:bg-interaction-selected focus:bg-interaction-selected text-black font-medium cursor-pointer rounded-xl transition"
                  >
                    <span className="flex justify-between">
                      Switch Accounts
                      {!toggleAccountSwitcher && (
                        <Icon
                          className="mr-2 "
                          iconName="chevronRight"
                          color="black"
                          size={20}
                        />
                      )}
                    </span>
                  </DropdownMenuItem>
                ) : null}

                <DropdownMenuItem
                  onClick={() => {
                    setOpen(false);
                    setCreatorSidebarOpen(false);
                    router.push(`/publisher/${selectedOrgId}/refer-and-earn`);
                  }}
                  className="mx-2 w-auto px-3 py-[9px] hover:bg-interaction-selected focus:bg-interaction-selected text-black font-medium cursor-pointer rounded-xl transition"
                >
                  <span className="w-full list-none flex items-center justify-between">
                    Refer & Earn $100
                    {/*My Referrals*/}
                  </span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="mx-2 w-auto px-3 py-[9px] hover:bg-interaction-selected focus:bg-interaction-selected font-medium cursor-pointer text-red-400 rounded-xl transition"
                  onClick={() => {
                    logout(true);
                  }}
                  data-cy="sign-out"
                >
                  <div className="w-full">Sign Out</div>
                </DropdownMenuItem>
              </div>
              {showSwitchAccounts && (
                <AccountSwitcher
                  showSwitchAccounts={toggleAccountSwitcher}
                  showPortalNavigation
                  accounts={accounts}
                  close={() => {
                    setCreatorSidebarOpen(false);
                    setOpen(false);
                  }}
                  setLoading={setLoading}
                  setToggleAccountSwitcher={setToggleAccountSwitcher}
                />
              )}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};
