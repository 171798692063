import { getRootDomain } from '../misc/window';
import Cookies from 'js-cookie';

const getDefaultOptions = ({
  wildcard = true,
  serverSide = false,
}: {
  wildcard?: boolean;
  serverSide?: boolean;
}) => {
  return {
    domain:
      !serverSide &&
      wildcard &&
      typeof window !== 'undefined' &&
      window.location.hostname !== 'localhost'
        ? `.${getRootDomain()}`
        : undefined,
    path: '/',
  };
};

export const setCookie = ({
  name,
  value,
  options = {},
  wildcard = true,
  serverSide = false,
}: {
  name: string;
  value: any;
  options?: any;
  wildcard?: boolean;
  serverSide?: boolean;
}) => {
  if (typeof window === 'undefined' && !serverSide) return;

  Cookies.set(name, value, {
    ...getDefaultOptions({ wildcard, serverSide }),
    expires: 365,
    ...options,
  });
};

export const removeCookie = ({
  name,
  options = {},
  wildcard = true,
  serverSide = false,
}: {
  name: string;
  options?: any;
  wildcard?: boolean;
  serverSide?: boolean;
}) => {
  Cookies.remove(name, {
    ...getDefaultOptions({ wildcard, serverSide }),
    ...options,
  });
};
