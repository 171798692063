import React from 'react';
import Link from 'next/link';

const TermsConditions = (props: any) => {
  if (!props.showNotice || props.userRole !== 'publisher') return null;

  return (
    <div className="terms-conditions-banner">
      We have updated our terms and conditions, please&nbsp;
      <Link href="/publisher/terms-conditions">click here</Link>
      &nbsp;to review them
      <span
        className="narrativ-icon cancel"
        onClick={props.clickHandler}
      ></span>
    </div>
  );
};

export default TermsConditions;
