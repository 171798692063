import { Context } from '..';
import { ProductLinkData } from './state';

export const getEdits = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v2/publishers/${publisherId}/bulk_links/edits/`,
    params,
  );
};

export const getEdit = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v2/publishers/${publisherId}/bulk_links/edit_by_id/`,
    params,
  );
};

export const fetchEdits = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v0/publishers/${publisherId}/edits/`,
    params,
  );
};

export const createStory = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.post(
    `/api/v2/publishers/${publisherId}/bulk_links/edits/`,
    params,
  );
};

export const editStory = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.put(
    `/api/v2/publishers/${publisherId}/bulk_links/edits/`,
    params,
  );
};

export const autoGenerateEdit = (context: Context, publisherId: number) => {
  return context.effects.api.get(
    `/api/v2/publishers/${publisherId}/bulk_links/edits/generate/`,
  );
};

export const getNextAutoGeneratedEditName = async (
  context: Context,
  publisherId: number,
) => {
  const res = await context.effects.api.get(
    `/api/v2/publishers/${publisherId}/bulk_links/edits/generateV2/`,
  );

  return res.data.data[0];
};

export const autoGenerateEditV2 = async (
  context: Context,
  {
    publisherId,
    editCreationType,
  }: { publisherId: number; editCreationType: string },
) => {
  const res = await context.effects.api.post(
    `/api/v2/publishers/${publisherId}/bulk_links/edits/generateV2/`,
    { edit_creation_type: editCreationType },
  );

  return res.data.data[0].edit;
};

export const setOldLinks = (context: Context, links: string | string[]) => {
  context.state.link.oldLinks = links;
};

export const createLinks = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.post(
    `/api/v2/publishers/${publisherId}/bulk_links/links/`,
    { ...params, from_howl: true },
  );
};

export const getLinks = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v0/publishers/${publisherId}/link_management/list/`,
    params,
  );
};

export const getLinksV1 = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v0/publishers/${publisherId}/link_management_v1/list/`,
    params,
  );
};

export const parseLinks = (context: Context, input: string) => {
  return input.split('\n').filter((link) => link !== '');
};

// manage link columns helper methods
export const setMonetizeableColor = (context: Context, status: string) => {
  const formattedStatus = status.toLowerCase();
  return formattedStatus === 'monetizeable'
    ? 'green'
    : formattedStatus === 'not monetizeable'
    ? 'dark-gray'
    : 'light-grey';
};

export const getMockData = () => {
  return {
    edits: [
      {
        edit_id: 100127,
        name: '10 Nail Strengtheners a Top Manicurist Relies On',
        url: 'https://www-byrdie-com.cdn.ampproject.org/',
        channel: 'youtube',
        datetime_created: '2021-05-30',
      },
      {
        edit_id: 100080,
        name: '4 Steps to Getting Rid of Cystic Acne - Article',
        url: 'https://www.allure.com/story/get-rid-of-cystic-acne-fast',
        channel: null,
        datetime_created: null,
      },
      {
        edit_id: 100081,
        name: 'How to Get Rid of Blackheads, According to Top Dermatologists',
        url: 'https://www.allure.com/story/how-to-get-rid-blackheads',
        channel: null,
        datetime_created: null,
      },
      {
        edit_id: 100082,
        name: 'NL Daily Deals',
        url: '',
        channel: null,
        datetime_created: null,
      },
    ],
    page_info: {
      next_page: true,
    },
  };
};

export const getManageLinksMockData = () => {
  const longLink =
    'http://bestbuy.7tiv.net/c/196318/614286/10014?u=https%3A%2F%2Fwww.bestbuy.com%2Fsite%2Flenovo-yoga-c940-2-in-1-14-touch-screen-laptop-intel-core-i7-12gb-memory-512gb-solid-state-drive-iron-gray%2F6367799.p%3FskuId%3D6367799&subid2=1600453881953akbrpawfd&nrtv=true';
  const link = 'https://narrativ-link/12345';
  const edits = [
    {
      bam_link_id: 1,
      edit_id: 1,
      edit_name: 'The Best Mens Skincare',
      // product_name: 'Beard Oil',
      bam_link_url: longLink,
      auction_url: link,
      howl_link_url: link,
      monetizeable_status: 'Loading Status...',
      edit_channel: 'Youtube',
      datetime_link_created: '2021-05-30',
      clipboard: 'some text',
    },
    {
      bam_link_id: 2,
      edit_id: 2,
      edit_name: 'Best Dogwear',
      product_name:
        'Selfless by Hyram Salicylic Acid & Sea Kelp Pore Clearing & Oil Control Serum 1 oz/ 30 mL',
      bam_link_url: 'https://narrativ.com/',
      auction_url: link,
      howl_link_url: link,
      datetime_link_created: '2021-12-12',
      edit_channel: 'Instagram',
      monetizeable_status: 'Processing',
    },
    {
      bam_link_id: 3,
      edit_id: 3,
      edit_name: 'Fastest Internet',
      product_name: 'Super Router',
      bam_link_url: 'https://narrativ.com/',
      auction_url: link,
      howl_link_url: link,
      datetime_link_created: '2021-07-25',
      edit_channel: 'Facebook',
      monetizeable_status: 'Not Monetizeable',
    },
    {
      bam_link_id: 4,
      edit_id: 4,
      edit_name: 'Cool Wallets',
      product_name: 'Leather Wallet',
      bam_link_url: 'https://narrativ.com/',
      auction_url: link,
      howl_link_url: link,
      datetime_link_created: '2021-01-01',
      edit_channel: 'TikTok',
      monetizeable_status: 'Monetizeable',
    },
    {
      bam_link_id: 5,
      edit_id: 5,
      edit_name: 'Music Gear',
      product_name: 'Ibanez M522 Mandolin - Dark Violin Sunburst Gloss',
      bam_link_url: 'https://narrativ.com/',
      auction_url: link,
      howl_link_url: link,
      datetime_link_created: '2021-11-11',
      edit_channel: 'Substack',
      monetizeable_status: 'Monetizeable',
    },
  ];
  return [...edits, ...edits];
};

export const getMockPastedLinks = () => {
  let pastedLinks =
    'http://fb.com\n\nhttp://fb.com\n\nhttp://blarf\n\nhttp:/sssss\n';
  for (let i = 0; i < 30; i++) {
    pastedLinks += `\n\nhttp://apple.com/${i}`;
  }
  return pastedLinks;
};

export const openGlobalLinkCreationModal = (
  context: Context,
  value: boolean,
) => {
  context.state.link.globalLinkCreationModalOpen = value;
  if (value === false) {
    context.state.link.globalLinkCreationModalBypassLinkCreation = false;
  }
};

export const setGlobalLinkCreationModalBypassLinkCreation = (
  context: Context,
  value: boolean,
) => {
  context.state.link.globalLinkCreationModalBypassLinkCreation = value;
};

export const setGlobalLinkProductAndOpenModal = (
  context: Context,
  payload: { value: ProductLinkData; linkSource?: string } | null,
) => {
  context.state.link.previewLinkProduct = payload
    ? {
        value: payload.value,
        options: { link_source: payload?.linkSource },
      }
    : null;

  if (payload?.value !== null) {
    context.actions.link.openGlobalLinkCreationModal(true);
  }
};

// Open the global link creation modal and auto create link
export const openAndBypassGlobalLinkCreationWithProduct = (
  context: Context,
  {
    value,
    linkSource = undefined,
  }: { value: ProductLinkData; linkSource?: string },
) => {
  context.state.link.previewLinkProduct = {
    value: value,
    options: { link_source: linkSource },
  };
  if (value !== null) {
    context.actions.link.setGlobalLinkCreationModalBypassLinkCreation(true);
    context.actions.link.openGlobalLinkCreationModal(true);
  }
};

export const openAndBypassGlobalLinkCreationWithUrl = (
  context: Context,
  { url, linkSource = undefined }: { url: string; linkSource?: string },
) => {
  context.state.link.previewLinkUrl = {
    value: url,
    options: { link_source: linkSource },
  };
  if (url !== null) {
    context.actions.link.setGlobalLinkCreationModalBypassLinkCreation(true);
    context.actions.link.openGlobalLinkCreationModal(true);
  }
};

export const setGlobalLinkUrlAndOpenModal = (
  context: Context,
  value: string | null,
) => {
  if (value === null) {
    context.state.link.previewLinkUrl = null;
  }
  if (value !== null) {
    context.state.link.previewLinkUrl = { value: value };
    context.actions.link.openGlobalLinkCreationModal(true);
  }
};

export const getLinkMonStatuses = async (
  context: Context,
  {
    pubId,
    link,
    useV2 = false,
    version = 0,
  }: { pubId: any; link: string; useV2?: boolean; version?: number },
) => {
  const apiUrl = `/api/${
    version === 0 ? 'v0' : 'v1'
  }/publishers/${pubId}/bam_link_preview/`;
  const request = {
    url: link,
    recommendations: true,
    version: version,
  };
  const result = Object.create({
    link: link,
    monetization_status: 'out-of-network',
    recommended_products: [],
    exact_match_product: null,
    exclusions: null,
    error: false,
  });

  await context.effects.api
    .post(apiUrl, request)
    .then((res: any) => {
      result.exact_match_product = res.data.data[0]['exact_match_product'];
      result.monetization_status = res.data.data[0]['monetization_status'];
      result.recommended_products = res.data.data[0][
        'recommended_products'
      ].map(({ merchant, product }: { merchant: any; product: any }) => {
        return {
          merchant: merchant.short_name,
          productName: product.item_data.name,
          imageUrl: product.item_data.image_url,
          productUrl: product.store_page_url,
          bamId: product.bamx_item_id,
        };
      });
      result.exclusions = res.data.data[0]['exclusions']
        ? res.data.data[0]['exclusions']['description']
        : null;
    })
    .catch(() => {
      result.error = true;
    });

  return result;
};
