import { Moment } from 'moment';

export type FlowType = 'create' | 'edit' | 'clone' | null;

export type Merchant = {
  merch_id: number;
  pricing_model: string | null;
  cpc_fee_rate?: number | null;
  epp_fee_rate?: number | null;
  attribution_window?: number | null;
};

export type StrategyGroup = {
  strategy_group_id: number;
  strategy_group_name: string;
  strategy_group_datetime_start: string | null;
  strategy_group_datetime_end?: string;
};

export type CreatorTier = {
  total_pubs: number;
  total_partners: number;
  base_rate: number;
  partners: { pub_id: number; full_name: string; logo_url: string | null }[];
};

export type CreatorTiers = 'poor' | 'fair' | 'good' | 'excellent';

export type Strategy = {
  strategy_id: number;
  strategy_name: string;
  strategy_tagline?: string;
  strategy_key_objective?: string;
  strategy_description?: string | null;
  strategy_thumbnail_image_url?: string | null;
  strategy_type: string;
  strategy_budget_type: BudgetType;
  strategy_budget_amount: number | null;
  strategy_is_enabled: boolean;
  strategy_is_draft: boolean;

  target_expression_id: number | null;
  strategy_is_evergreen?: boolean;
  strategy_pricing_model: 'EPP' | 'CPC';
  strategy_payment_model: 'EPP' | 'EPC' | null;
  strategy_bid?: string;
  strategy_roi_goal?: string;
  strategy_publisher_roi_target?: string;
  strategy_publisher_epc?: string;
  strategy_cogs?: number;
  cpc_fee_rate?: number | null;
  epp_fee_rate?: number | null;
  flat_fee_rate?: number | null;
  strategy_visibility?: string;

  strategy_datetime_created?: string;
  strategy_datetime_start: string | null;
  strategy_datetime_end?: string | null;
  strategy_time_start?: string | null;
  strategy_time_end?: string | null;
};

export type StrategyRule = {
  rule_actions: {
    rule_action_id: number;
    rule_action_notification_message: string;
    rule_action_notification_send_to: string;
    rule_action_type: 'SEND_NOTIFICATION';
  }[];
  rule_conditions: {
    pub_id?: string | null;
    rule_condition_id: number;
    rule_condition_meta: { threshold: number };
    rule_condition_type: 'STRATEGY_SPEND_CHECK' | 'STRATEGY_UPDATED_CHECK';
  }[];
  rule_id: number;
  rule_name: string;
  rule_datetime_created: string;
  rule_datetime_updated: string;
  rule_is_active: boolean;
  rule_is_complete: boolean;
  rule_is_run_once: boolean;
  rule_last_run_time: string;
  rule_schedule_type: 'DAILY';
};

export type StrategyRules = {
  merchant_rules: StrategyRule[];
  page_info: {
    page: number;
    per_page: number;
    total_items: number;
    total_pages: number;
  };
};

export type RateTier = {
  rate_tier_id: string | number;
  rate_tier_name: string;
  strategy_bid: string | null;
  strategy_roi_goal: string | null;
  strategy_publisher_epc: string | null;
  strategy_publisher_roi_target: string | null;
  strategy_cogs?: number;
  rate_tier_description: string;
  rate_tier_is_enabled: boolean;
  rate_tier_is_default: boolean;
  publisher_list_id: number;
  publisher_type: number | string;
  flat_fees: any[];
  attribution_window: number;
  strategy_id: number;
  flat_fee_gmv_goal?: string;
  is_base_rate?: boolean;
  publisher_list_scope?: number;
  publisher_list_items?: number[];
};

export type Gifting = {
  strategy_gift_id?: string | null;
  strategy_gift_sku_list_id: string | null;
  strategy_gift_description: string;
  strategy_gift_quantity: number;
  strategy_gift_signup_url: string;
  strategy_gift_is_archived: boolean;
  skuList: any | null;
  skuListItems: string[] | null;
};

export type TargetRule = {
  target_rule_id: number;
  target_rule_operator: string;

  target_rule_brand_list_id?: number;
  target_rule_google_category_list_id?: number;
  target_rule_merchant_category_list_id: number;
  target_rule_publisher_list_id?: number;
  target_rule_sku_list_id?: number;
  target_rule_supplier_link_type?: string;
  target_rule_supplier_type?: string;
  target_rule_link_creation_datetime?: string;
};

export type TargetList = {
  target_list_id: number;
  target_list_name: string;
  target_list_type: string;
  target_list_scope: string;
  target_list_scope_merch_id?: number | null;
};

export type Targeting = {
  target_expression_id: number;
  target_expression: string;
  target_rules: TargetRule[];
  target_lists?: TargetList[];
};

export type StrategyType =
  | 'always_on'
  | 'sale'
  | 'product_launch'
  | 'priority_products'
  | 'awareness'
  | 'flat_fee';

export type BudgetType = 'lifetime' | 'monthly';

export type AutomationRuleForm = {
  rule_condition_type?:
    | 'STRATEGY_SPEND_CHECK'
    | 'STRATEGY_UPDATED_CHECK'
    | null;
  rule_action_notification_send_to?: string[];
  rule_action_type: 'SEND_NOTIFICATION';
  rule_condition_id?: number;
  rule_action_id?: number;
  threshold?: number;
  isShowing: boolean;
};

export type TargetRuleForm = {
  target_rule_id?: string;
  target_list_type: string | null;
  target_rule_operator: string | null;
  target_rule_link_date?: string | null | Moment;
  target_rule_supplier_type?: string | null;
  target_rule_supplier_link_type?: string | null;
  target_rule_link_creation_datetime?: string | null;
  target_list_items?: any[] | null;
  target_list?: any[] | null;
  target_list_id?: number | null;
  target_count?: number | null;
  isTargetFromList?: boolean;
};

export type FlatFee = {
  flat_fee_id?: string | number;
  rate_tier_id?: string | number;
  post_count: number;
  amount: number;
  datetime_start: string;
};

export type FlatFeeForm = {
  [k: string]: {
    flat_fee_id?: number | string;
    post_count?: number;
    amount?: number;
    datetime_start: string;
  };
};

export type TargetType =
  | 'sku'
  | 'brand'
  | 'google_category'
  | 'merchant_category'
  | 'publisher'
  | 'supplier_type'
  | 'supplier_link_type'
  | 'link_date';

export type RateTierForm = {
  rate_tier_id: string;
  strategy_id: number;
  rate_tier_name: string;
  rate_tier_description?: string;
  rate_tier_is_enabled: boolean;
  rate_tier_is_default: boolean;
  flat_fee_gmv_goal?: string | null;
  flat_fees: FlatFeeForm;
  pricingModel?: 'CPC' | 'EPP';
  paymentModel?: 'EPP' | 'EPC' | null;
  pricingModelValue?: string | null;
  paymentModelValue?: string | null;
  partnerGrouping?: 'partnerType' | 'partnerList';
  partnerValue?: string;
  publisher_type?: string | number;
  rate_tier_attribution_enabled: boolean;
  rate_tier_attribution_duration?: string;
  rate_tier_attribution_window?: string;
  flatFeesTotalBudget?: number;
  is_base_rate?: boolean;
  use_custom_lists?: boolean;
  partnerListValue: string[];
};

export type GiftingForm = {
  strategy_gift_id?: number | null;
  strategy_gift_sku_list_id: string | null;
  strategy_gift_description: string;
  strategy_gift_signup_url: string;
  strategy_gift_quantity: number;
  strategy_gift_is_archived: boolean;
};

type State = {
  flowType: FlowType;
  merchant: Merchant | null;
  strategyGroup: StrategyGroup | null;
  strategy: Strategy | null;
  strategyCloneOriginal?: Strategy | null;
  strategyRules: StrategyRules | null;
  targetLists: {
    brand?: any;
    publisher?: any;
  };
  targeting: Targeting | null;
  rateTiers: RateTier[];
  creatorTiers: { [key in CreatorTiers]?: CreatorTier };
  gifting: Gifting | null;
  strategyImage: File | string | null;
  formState: {
    strategy_type: StrategyType;
    strategy_name: string;
    strategy_tagline?: string;
    strategy_key_objective?: string;
    strategy_description?: string | null;
    campaign_brief_url?: string | null;
    content_submission_url?: string | null;
    strategy_thumbnail_image_url?: string | null;
    strategy_budget_amount?: string;
    strategy_budget_type: BudgetType;
    strategy_datetime_start: string | null;
    strategy_datetime_end?: string | null;
    strategy_time_start?: string | null;
    strategy_time_end?: string | null;
    strategy_priority?: string | null;
    strategy_pricing_model: 'EPP' | 'CPC' | null;
    strategy_pricing_model_value: string | null;
    strategy_payment_model?: 'EPP' | 'EPC' | 'COGS';
    strategy_payment_model_value?: string;
    strategy_is_enabled: boolean;
    strategy_is_draft: boolean;
    strategy_force_publish?: boolean;
    strategy_visibility?: string;
    cpc_fee_rate?: string;
    epp_fee_rate?: string;
    priority?: 'very_high' | 'high' | 'normal' | 'low' | 'very_low';
    strategy_utm_query_string?: string;
    isTransactionFeesEnabled: boolean;
    isPaymentModelEnabled: boolean;
    isTimeOfDayEnabled: boolean;
    isFlatFeeEnabled: boolean;
    merchant_rules: { [k: string]: AutomationRuleForm };
    targetExpressionFormat: string[][];
    targetExpressionValues: { [k: number | string]: TargetRuleForm };
    rate_tiers: {
      [k: string]: RateTierForm;
    };
    gifting: GiftingForm | null;
    isFlatFeeGMVGoalEnabled: boolean;
    flat_fee_gmv_goal?: string;
    flat_fee_rate?: string;
    totalFlatFeeSpend?: number;
    workingBudget?: number;
  };
  pageState: {
    page: string | null;
    isDirty: boolean;
    formErrors: string[];
    inputErrors: any;
    validationErrors: any | null;
    isLoading: boolean;
    isSubmitting: boolean;
  };
  completedStrategy: {
    flowType: FlowType;
    strategyId: number;
    strategyGroupId: number;
    action?: 'publish' | null;
  } | null;
};

export const defaultFormState = {
  strategy_type: 'always_on' as StrategyType,
  strategy_name: '',
  strategy_tagline: '',
  strategy_key_objective: '',
  strategy_description: '',
  campaign_brief_url: '',
  content_submission_url: '',
  strategy_thumbnail_image_url: null,
  strategy_budget_type: 'lifetime' as BudgetType,
  strategy_datetime_start: null,
  strategy_datetime_end: null,
  strategy_pricing_model: null,
  strategy_pricing_model_value: '',
  strategy_priority: null,
  strategy_is_enabled: false,
  strategy_is_draft: true,
  isTransactionFeesEnabled: false,
  isPaymentModelEnabled: false,
  isTimeOfDayEnabled: false,
  isFlatFeeEnabled: false,
  isFlatFeeGMVGoalEnabled: false,
  merchant_rules: {},
  rate_tiers: {},
  gifting: null,
  giftingListItems: [],
  targetExpressionFormat: [[]],
  targetExpressionValues: {},
  totalFlatFeeSpend: 0,
  workingBudget: 0,
  flat_fee_rate: '0',
};

export const defaultPageState = {
  page: null,
  isDirty: false,
  formErrors: [],
  validationErrors: null,
  inputErrors: {},
  isSubmitting: false,
  isLoading: true,
};

export const state: State = {
  flowType: null,
  merchant: null,
  strategyGroup: null,
  strategy: null,
  strategyRules: null,
  targetLists: {},
  targeting: null,
  rateTiers: [],
  creatorTiers: {},
  gifting: null,
  formState: { ...defaultFormState },
  strategyImage: null,
  pageState: { ...defaultPageState },
  completedStrategy: null,
};
