import moment from 'moment';
import { Context } from '..';

export const getData = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(`/api/v0/orders/${publisherId}/`, params);
};

export const getProductsData = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v0/orders/${publisherId}/products/`,
    params,
  );
};

export const getMerchants = (context: Context, publisherId: string) => {
  return context.effects.api.get(`/api/v0/orders/${publisherId}/merchants/`);
};

export const getRecent = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v0/orders/${publisherId}/recent/`,
    params,
  );
};

export const getOverview = (context: Context, publisherId: number) => {
  return context.effects.api.get(`/api/v0/orders/${publisherId}/overview/`);
};

export const getTopProductsEarnings = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v0/publishers/${publisherId}/top_orders_product_earnings/`,
    params,
  );
};

export const getTopProductsAggregates = (
  context: Context,
  { publisherId, params }: { publisherId: any; params: any },
) => {
  return context.effects.api.get(
    `/api/v0/publishers/${publisherId}/top_orders_product_aggregates/`,
    params,
  );
};

export const format = (
  context: Context,
  { datetime, todayText }: { datetime: any; todayText?: any },
) => {
  const today = moment();
  const yesterday = context.actions.date.offsetFromDate({
    originalDate: today,
    number: -1,
    unit: 'day',
  });
  const datetimeConverted = moment.utc(datetime);

  const date =
    context.actions.date.compareDates({ d1: datetimeConverted, d2: today }) ===
    0
      ? todayText
        ? 'Today '
        : ''
      : context.actions.date.compareDates({
          d1: datetimeConverted,
          d2: yesterday,
        }) === 0
      ? 'Yesterday '
      : `${datetimeConverted.format('MMMM D, YYYY')} `;

  const time = datetimeConverted.format('h:mma');
  return `${date}at ${time} EST`;
};

export const getMockProductsData = () => {
  return [
    {
      brand: 'Some other brand',
      name: 'Some other product 1',
      image_url:
        'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
      store_page_url: 'https://apple.com',
      unit_price_usd: '25.11',
      quantity: 2,
      bookmarked: true,
      is_excluded: true,
    },
    {
      brand: 'Some cool brand',
      name: 'Some cool name 2',
      image_url: 'https://static.bam-x.com/assets/logos/merchants/violets.png',
      store_page_url:
        'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
      unit_price_usd: '50.00',
      quantity: 1,
    },
    {
      brand: 'Some cool brand',
      name: 'Some cool name 3',
      image_url:
        'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
      store_page_url:
        'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
      unit_price_usd: '50.00',
      quantity: 1,
    },
    {
      brand: 'Some cool brand',
      name: 'Some cool name 4',
      image_url:
        'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
      store_page_url:
        'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
      unit_price_usd: '12.00',
      quantity: 2,
      is_excluded: true,
    },
  ];
};

export const getMockData = () => {
  return {
    pub_id: 1576,
    attributed_checkouts: [
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          '6bcbd0a710530d90d81c78cc4256c410c856903a95bd793e86dfa1d60d7c8c94',
        checkout_id: 'K184193895',
        attributed_click_auction_id: '1746671181178382385',
        datetime_attributed_link_created: '2021-07-19',
        merch_name: "Macy's",
        datetime_attributed_checkout: '2021-06-15T00:00:00Z',
        datetime_attributed_checkout_utc: '2021-06-15T00:00:00Z',
        datetime_attributed_click: '2021-07-23T15:26:48Z',
        product_clicked_mpid: 12133146339,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2572981?$lg$',
        product_clicked_store_page_url: 'https://bananarepublic.com',
        attributed_edit_id: 1841363,
        attributed_edit_name: 'December Beauty Haul',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-05-30',
        publisher_earnings_usd: '20.00',
        checkout_value_usd: '100.00',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          '269422fed983a2c831890aabf4e0ba67f97289d0cf5cc20c9213bbdff4ab3d4a',
        checkout_id: 'K184216858',
        attributed_click_auction_id: '1746671181178382385',
        datetime_attributed_link_created: '2021-07-19',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-25T14:28:13Z',
        datetime_attributed_checkout_utc: '2021-07-25T14:28:13Z',
        datetime_attributed_click: '2021-07-24T14:28:13Z',
        product_clicked_mpid: 12133146339,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2572981?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/lift-snatch-brow-tint-pen-waterproof-eyebrow-pen-pimprod2021269?sku=2572981',
        attributed_edit_id: 1841363,
        attributed_edit_name: 'From NOT to fLuFfY brows LOL',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-07-19',
        publisher_earnings_usd: '16.16',
        checkout_value_usd: '132.00',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          'd2b711c0ad86643b0b4e1f8c837dc7d30f7a70f709442f693d05d95f6214804b',
        checkout_id: 'K184224606',
        attributed_click_auction_id: '1746671181178382385',
        datetime_attributed_link_created: '2021-07-19',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-25T14:27:25Z',
        datetime_attributed_checkout_utc: '2021-07-25T14:27:25Z',
        datetime_attributed_click: '2021-07-24T14:27:25Z',
        product_clicked_mpid: 12133146339,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: null,
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2572981?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/lift-snatch-brow-tint-pen-waterproof-eyebrow-pen-pimprod2021269?sku=2572981',
        attributed_edit_id: 1841363,
        attributed_edit_name: 'From NOT to fLuFfY brows LOL',
        attributed_edit_url: 'https://www.fake.com/',
        datetime_attributed_edit_created: '2021-07-19',
        publisher_earnings_usd: '6.20',
        checkout_value_usd: '50.68',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          'f2c561a49f2cae93446bf8e5a7980ea140af82e2b2b51fb6a526c4e925cd1b73',
        checkout_id: 'K184188885',
        attributed_click_auction_id: '1744485506637746823',
        datetime_attributed_link_created: '2021-06-25',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-25T13:13:22Z',
        datetime_attributed_checkout_utc: '2021-07-25T13:13:22Z',
        datetime_attributed_click: '2021-06-28T13:13:22Z',
        product_clicked_mpid: 12517855363,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2579045?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/lift-snatch-brow-tint-pen-waterproof-eyebrow-pen-pimprod2021269?sku=2579045',
        attributed_edit_id: 1794461,
        attributed_edit_name: 'Madison Beer makeup tutorial',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-06-25',
        publisher_earnings_usd: '4.09',
        checkout_value_usd: '27.98',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          'aba32d350c53da3c713b37fdf0e356c7f1c2befa600b1ae7391072fb24aa6172',
        checkout_id: 'K184183656',
        attributed_click_auction_id: '1746671181178382385',
        datetime_attributed_link_created: '2021-07-19',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-25T07:43:52Z',
        datetime_attributed_checkout_utc: '2021-07-25T07:43:52Z',
        datetime_attributed_click: '2021-07-22T07:43:52Z',
        product_clicked_mpid: 12133146339,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2572981?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/lift-snatch-brow-tint-pen-waterproof-eyebrow-pen-pimprod2021269?sku=2572981',
        attributed_edit_id: 1841363,
        attributed_edit_name: 'From NOT to fLuFfY brows LOL',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-07-19',
        publisher_earnings_usd: '5.74',
        checkout_value_usd: '43.00',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          '55dded7bdae85e612c295b6505d4d6b0c4929c1b7f78f3b6fdde5d41f4db61ab',
        checkout_id: 'K184183590',
        attributed_click_auction_id: '1742630691074288084',
        datetime_attributed_link_created: '2021-06-05',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-25T01:17:45Z',
        datetime_attributed_checkout_utc: '2021-07-25T01:17:45Z',
        datetime_attributed_click: '2021-06-27T01:17:45Z',
        product_clicked_mpid: 950683077,
        product_clicked_name: 'Slim Lip Pencil Creamy Long-Lasting Lip Liner',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2226178?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/slim-lip-pencil-creamy-long-lasting-lip-liner-xlsImpprod2150078?sku=2226178',
        attributed_edit_id: 1742836,
        attributed_edit_name: 'Trying this new lip lining technique',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-06-05',
        publisher_earnings_usd: '6.47',
        checkout_value_usd: '48.50',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          'ff6623897277a7a45f3dbe920fd8a29caaab2ca75f48d1b289d95bd9fae60208',
        checkout_id: 'K184198405',
        attributed_click_auction_id: '1746671181178382385',
        datetime_attributed_link_created: '2021-07-19',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-24T23:51:12Z',
        datetime_attributed_checkout_utc: '2021-07-24T23:51:12Z',
        datetime_attributed_click: '2021-07-24T23:51:12Z',
        product_clicked_mpid: 12133146339,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2572981?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/lift-snatch-brow-tint-pen-waterproof-eyebrow-pen-pimprod2021269?sku=2572981',
        attributed_edit_id: 1841363,
        attributed_edit_name: 'From NOT to fLuFfY brows LOL',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-07-19',
        publisher_earnings_usd: '2.57',
        checkout_value_usd: '20.99',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          '68787193a1c115ed08ada09d37ef7d5505c88686a2702c6b9d71fd881ab74c59',
        checkout_id: 'K184157732',
        attributed_click_auction_id: '1745457866706538691',
        datetime_attributed_link_created: '2021-07-06',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-24T14:38:42Z',
        datetime_attributed_checkout_utc: '2021-07-24T14:38:42Z',
        datetime_attributed_click: '2021-07-07T14:38:42Z',
        product_clicked_mpid: 950691894,
        product_clicked_name: 'Highlight & Contour Pro Face Palette',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2284091?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/highlight-contour-pro-face-palette-xlsImpprod12011207?sku=2284091',
        attributed_edit_id: 1815041,
        attributed_edit_name: 'Contouring basics for beginners',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-07-06',
        publisher_earnings_usd: '17.73',
        checkout_value_usd: '132.98',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          '28f9a70528d4046bf7eaaa661b4a725943ccbd442db6422100406fb964b06feb',
        checkout_id: 'K184144747',
        attributed_click_auction_id: '1746671181178382385',
        datetime_attributed_link_created: '2021-07-19',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-24T14:38:42Z',
        datetime_attributed_checkout_utc: '2021-07-24T14:38:42Z',
        datetime_attributed_click: '2021-07-24T14:02:01Z',
        product_clicked_mpid: 12133146339,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2572981?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/lift-snatch-brow-tint-pen-waterproof-eyebrow-pen-pimprod2021269?sku=2572981',
        attributed_edit_id: 1841363,
        attributed_edit_name: 'From NOT to fLuFfY brows LOL',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-07-19',
        publisher_earnings_usd: '1.40',
        checkout_value_usd: '11.00',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          '03bd21661513038df4ae08c4d02ffba89b551b9196e2452bbaeec7cf97b3d388',
        checkout_id: 'K184151581',
        attributed_click_auction_id: '1746509068091374194',
        datetime_attributed_link_created: '2021-07-17',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-07-24T12:43:05Z',
        datetime_attributed_checkout_utc: '2021-07-24T12:43:05Z',
        datetime_attributed_click: '2021-07-24T12:43:05Z',
        product_clicked_mpid: 12340544730,
        product_clicked_name: 'Air Matte Blush',
        product_clicked_brand: 'NARS',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2573406?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/air-matte-blush-pimprod2020308?sku=2573406',
        attributed_edit_id: 1838855,
        attributed_edit_name: 'Trying some new NEW for a glowy complexion',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-07-17',
        publisher_earnings_usd: '6.73',
        checkout_value_usd: '50.46',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
      },
    ],
    date: {
      date_from: null,
      date_to: null,
    },
    page_info: {
      page: 1,
      per_page: 10,
      next_page: true,
    },
  };
};

export const getMockRecent = () => {
  return {
    attributed_checkouts: [
      {
        pub_id: 1576,
        merch_id: 2000,
        event_id: '4',
        checkout_id: null,
        attributed_click_auction_id: null,
        datetime_attributed_link_created: null,
        merch_name: null,
        datetime_attributed_checkout: '2022-03-28T09:14:16Z',
        datetime_attributed_checkout_utc: '2021-11-23T04:28:13Z',
        datetime_attributed_click: null,
        product_clicked_mpid: null,
        product_clicked_name: null,
        product_clicked_brand: null,
        product_clicked_image_url: null,
        product_clicked_store_page_url: null,
        attributed_edit_id: null,
        attributed_edit_name: null,
        attributed_edit_url: null,
        datetime_attributed_edit_created: null,
        publisher_earnings_usd: '10.00',
        checkout_value_usd: '100.00',
        logo_url: '//static.bam-x.com/assets/logos/merchants/qa_merch1.png',
        products: [
          {
            brand: 'Some other brand',
            name: 'Some other product 1Some other product 1Some other product 1Some other product 1Some other product 1',
            image_url:
              'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
            store_page_url: 'https://apple.com',
            unit_price_usd: '25.11',
            quantity: 2,
            bookmarked: true,
          },
          {
            brand: 'Some cool brand',
            name: 'Some cool name 2',
            image_url:
              'https://static.bam-x.com/assets/logos/merchants/violets.png',
            store_page_url:
              'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
            unit_price_usd: '50.00',
            quantity: 1,
          },
          {
            brand: 'Some cool brand',
            name: 'Some cool name 3',
            image_url:
              'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
            store_page_url:
              'https://static.bam-x.com/assets/logos/merchants/violetgrey.png',
            unit_price_usd: '50.00',
            quantity: 1,
          },
        ],
      },
      {
        pub_id: 1576,
        merch_id: 1158,
        event_id:
          '269422fed983a2c831890aabf4e0ba67f97289d0cf5cc20c9213bbdff4ab3d4a',
        checkout_id: 'K184216858',
        attributed_click_auction_id: '1746671181178382385',
        datetime_attributed_link_created: '2021-07-19',
        merch_name: 'Ulta Beauty',
        datetime_attributed_checkout: '2021-11-23T04:28:13Z',
        datetime_attributed_checkout_utc: '2021-07-25T14:28:13Z',
        datetime_attributed_click: '2021-07-24T14:28:13Z',
        product_clicked_mpid: 12133146339,
        product_clicked_name:
          'Lift & Snatch Brow Tint Pen Waterproof Eyebrow Pen',
        product_clicked_brand: 'NYX Professional Makeup',
        product_clicked_image_url:
          'https://images.ulta.com/is/image/Ulta/2572981?$lg$',
        product_clicked_store_page_url:
          'https://www.ulta.com/p/lift-snatch-brow-tint-pen-waterproof-eyebrow-pen-pimprod2021269?sku=2572981',
        attributed_edit_id: 1841363,
        attributed_edit_name: 'From NOT to fLuFfY brows LOL',
        attributed_edit_url: 'https://www.youtube.com/',
        datetime_attributed_edit_created: '2021-07-19',
        publisher_earnings_usd: '16.16',
        checkout_value_usd: '132.00',
        logo_url:
          'https://static.bam-x.com/assets/logos/merchants/ultabeauty.png',
        products: [
          {
            unit_price_usd: '10.00',
            brand: 'Sample Product',
            image_url: 'www.image_url/sample',
            name: 'Product Name Sample',
            quantity: 8,
            store_page_url: 'www.event_products_url/sample',
          },
        ],
      },
      {
        pub_id: 1576,
        merch_id: 1735,
        event_id:
          'da878bf3e588a95de06be797f30863d6ebd3c84150871ce7cce726e7f055cb68',
        checkout_id: 'PMo8arWjxR',
        attributed_click_auction_id: '1753816568971496533',
        datetime_attributed_link_created: '2021-10-04T13:36:03Z',
        merch_name: 'merchant-100003231',
        datetime_attributed_checkout: '2021-12-07T18:59:39Z',
        datetime_attributed_checkout_utc: '2021-12-07',
        datetime_attributed_click: '2021-12-07T18:59:39Z',
        product_clicked_mpid: null,
        product_clicked_name: null,
        product_clicked_brand: null,
        product_clicked_image_url: null,
        product_clicked_store_page_url: null,
        attributed_edit_id: 124273,
        attributed_edit_name:
          'Publisher 1105 link test beauty product 18521633368952',
        attributed_edit_url: null,
        datetime_attributed_edit_created: '2021-10-04T13:36:03Z',
        publisher_earnings_usd: '0.67',
        checkout_value_usd: '20.00',
        logo_url: null,
        products: [
          {
            name: 'test beauty product 18521633368952',
            brand: 'test 1304',
            image_url: 'https://paganresearch.io/images/narrativ.jpg',
            unit_price_usd: '10.00',
            quantity: 2,
            store_page_url:
              'https://staging-api.bam-x.com/api/v0/widgets/merchant/1735/product/1729455668/',
          },
        ],
      },
      {
        pub_id: 1576,
        merch_id: 1735,
        event_id:
          '96b8c5cc43679739c02fc283e0a4468e197cb872be08a4f1a66f69a255fb2a4d',
        checkout_id: 'Kp8ppSrACN',
        attributed_click_auction_id: '1753816567501219965',
        datetime_attributed_link_created: '2021-10-04T13:36:02Z',
        merch_name: 'merchant-100003231',
        datetime_attributed_checkout: '2021-12-07T18:59:39Z',
        datetime_attributed_checkout_utc: '2021-12-07',
        datetime_attributed_click: '2021-12-07T18:59:39Z',
        product_clicked_mpid: null,
        product_clicked_name: null,
        product_clicked_brand: null,
        product_clicked_image_url: null,
        product_clicked_store_page_url: null,
        attributed_edit_id: 124272,
        attributed_edit_name:
          'Publisher 1105 link test beauty product 18521633368950',
        attributed_edit_url: null,
        datetime_attributed_edit_created: '2021-10-04T13:36:02Z',
        publisher_earnings_usd: '0.67',
        checkout_value_usd: '20.00',
        logo_url: null,
        products: [],
      },
    ],
  };
};
export const getMockOverview = () => {
  return {
    publisher_earnings: '100.0',
    product_count: 4,
    pub_id: 1576,
    date_from: '2021-07-14',
    date_to: '2021-07-21',
  };
};
