import { cloneDeep } from 'lodash';
import { titleCase } from '@frontend/shared-utils';
import { Context } from '..';
import { PermissionRoles, RoleNames, Org } from './state';
import mockBrands from './brands.json';
import mockChannels from './channels.json';
// import { getPageTitle } from '../link';

const domainRegex =
  /^(\*(\.)?)?(([a-zA-Z0-9]+[_-])*[a-zA-Z0-9]+)\.[a-zA-Z0-9-_.-]+$/;

export const filterParams = (
  context: Context,
  { params, endpointType }: { params: any; endpointType: any },
) => {
  const genericParams = ['full_name', 'short_name', 'url'];
  const sharedEditParams = [
    ...genericParams,
    'affiliate_config',
    'is_pilot',
    'logo_url',
    'cover_image_url',
    'notes',
    'show_in_dashboard',
    'widget_options',
    'slug',
    // TODO: uncomment once backend is updated
    // 'categories',
  ];
  const endpointParams: any = {
    '/publisher/id': [
      ...sharedEditParams,
      'amazon_tracking_id',
      'cogs',
      'has_jstag',
      'reserve_cpc',
      'publisher_type',
      'terms_accepted',
      'user_terms_accepted',
      'datetime_terms_accepted',
      'instant_pay_access',
      'billing_platform',
      'billing_country',
      'network_average_ctr',
      'tier_type',
      'exclude_refresh',
    ],
    '/org': [...genericParams, 'publisher_type'],
    '/merchant/id': [
      ...sharedEditParams,
      'ftp_account_name',
      'ftp_account_password',
      'expected_import_frequency',
      'impression_tracker',
      'click_tracker',
      'utm_params',
      'share_goal',
      'is_pacing_enabled',
      'pricing_model',
      'max_cpc',
      'cpc_goal',
      'roi_goal',
      'commission_rate',
      'is_active',
      'is_feed_importer_enabled',
      'merchant_type',
      'cpc_fee_rate',
      'epp_fee_rate',
      'deduct_returns',
    ],
    '/billing/customers': [
      'merch_id',
      'email',
      'invoicing_enabled',
      'campaign_invoicing_enabled',
    ],
    '/categories/': ['name'],
  };

  return params
    ? Object.keys(params).reduce(
        (acc, curr) =>
          endpointParams[endpointType].includes(curr)
            ? { ...acc, [curr]: params[curr] }
            : acc,
        {},
      )
    : null;
};

export const getAccount = (
  context: Context,
  {
    accountType,
    accountId,
    allCategories,
  }: { accountType: any; accountId: any; allCategories: any },
) => {
  const params: any = {};
  if (accountType.toLowerCase() === 'publisher') {
    params['new_terms_logic'] = true;
  }
  if (allCategories) {
    params['all_categories'] = allCategories;
  }
  return context.effects.api.get(`/api/v0/${accountType}s/${accountId}/`, {
    with_categories: true,
    ...params,
  });
};

export const getAccountUsers = (
  context: Context,
  { accountType, accountId }: { accountType: any; accountId: any },
) => {
  return context.effects.api.get(
    `/api/v0/admins/${accountType}_info/${accountId}`,
  );
};

export const getCurrentUser = (context: Context) => {
  return context.effects.api.get(
    `/api/v0/users/${context.state.session.sessionData.userId}/`,
  );
};

export const getBillingCountries = (context: Context) => {
  return context.effects.api.get(`/api/v0/billing/countries/`);
};
export const getBillingUserDetail = async (
  context: Context,
  { accountId }: { accountId: string },
) => {
  try {
    const res = await context.effects.api.get(
      `api/v0/publisher_user_role/${accountId}/get_billing_user_and_data/`,
    );
    context.state.account.billingUser = res.data.data;
    return res;
  } catch (error) {
    return null;
  }
};

export const getPublisherAccounts = (context: Context) => {
  const endpoint = '/api/v0/publishers/';
  return context.effects.api.get(endpoint, {
    with_categories: true,
    new_terms_logic: true,
  });
};

export const getMerchantAccounts = (context: Context) => {
  const endpoint = '/api/v0/merchants/';
  return context.effects.api.get(endpoint, { with_categories: true });
};

export const createAccount = (
  context: Context,
  { params, accountType }: { params: any; accountType: any },
) => {
  const endpoint = `/api/v0/${accountType}s/`;
  const filteredParams = context.actions.account.filterParams({
    params,
    endpointType: '/org',
  });

  const { firstErrorMsg, newParams, fieldErrors } =
    context.actions.account.validateParams({
      params: filteredParams,
    });

  if (firstErrorMsg) {
    return Promise.reject([new Error(firstErrorMsg), fieldErrors]);
  }

  return context.effects.api.post(endpoint, newParams);
};

export const saveAccountSlug = (
  context: Context,
  {
    params,
    accountType,
    accountId,
  }: { params: any; accountType: any; accountId: any },
) => {
  const endpoint = `/api/v0/${accountType}s/${accountId}/`;
  const filteredParams = context.actions.account.filterParams({
    params,
    endpointType: `/${accountType}/id`,
  });
  const { firstErrorMsg, newParams, fieldErrors } =
    context.actions.account.validateParams({
      params: filteredParams,
    });
  if (firstErrorMsg) {
    return Promise.reject([new Error(firstErrorMsg), fieldErrors]);
  }
  return context.effects.api.put(endpoint, newParams);
};

export const getSubscriptionDetails = (
  context: Context,
  { accountId }: { accountId: any },
) => {
  return context.effects.api.get(
    `/api/v0/merchants/${accountId}/subscription_info/`,
  );
};

export const createUserByEmail = (
  context: Context,
  {
    email,
    referral_type,
    referral_code,
  }: { email: any; referral_type: any; referral_code: any },
) => {
  const params = {
    email: email,
    referral_type: referral_type,
    referral_code: referral_code,
  };
  return context.effects.api.post(`/api/v0/create_user_by_email/`, params);
};

export const createUserWithPassword = (
  context: Context,
  {
    email,
    password,
    referral_type,
    referral_code,
  }: {
    email: string;
    password: string;
    referral_type: any;
    referral_code: any;
  },
) => {
  const params = {
    email: email,
    password: password,
    referral_type: referral_type,
    referral_code: referral_code,
  };
  return context.effects.api.post(`/api/v0/create_user_with_password/`, params);
};

export const getBrandSSUConfigDetails = (
  context: Context,
  { accountId }: { accountId: any },
) => {
  return context.effects.api.get(`/api/v0/merchants/${accountId}/ssu_config/`);
};

export const getPublisherSSUConfigDetails = (
  context: Context,
  { accountId }: { accountId: any },
) => {
  return context.effects.api.get(`/api/v0/publishers/${accountId}/ssu_config/`);
};

export const saveSSUConfigDetails = (
  context: Context,
  { accountId, payload }: { accountId: any; payload: any },
) => {
  return context.effects.api.put(
    `/api/v0/merchants/${accountId}/update_ssu_config/`,
    { ssu_config: payload },
  );
};

export const completeRequirement = (
  context: Context,
  { pubId, requirementId }: { pubId: number; requirementId: number },
) => {
  return context.effects.api.post(
    `/api/v0/publishers/${pubId}/requirements/${requirementId}/`,
  );
};

export const getRequirementInfo = (
  context: Context,
  {
    pubId,
    requirementType,
    merchId,
  }: { pubId: number; requirementType: number; merchId: any },
) => {
  return context.effects.api.get(
    `/api/v0/publishers/${pubId}/requirements/${requirementType}/merchant/${merchId}/`,
  );
};

export const updateRequirementInfo = (
  context: Context,
  {
    merchId,
    requirementUrl,
    requirementType,
    availableDate,
    requiredDate,
  }: {
    merchId: any;
    requirementUrl?: string;
    requirementType: number;
    availableDate: Date;
    requiredDate: Date;
  },
) => {
  return context.effects.api.post(
    `/api/v0/requirement/${requirementType}/merchant/${merchId}/update_info/`,
    {
      requirement_url: requirementUrl,
      available_date: availableDate,
      required_date: requiredDate,
    },
  );
};

export const getLinkMonStatuses = async (
  context: Context,
  { pubId, link }: { pubId: any; link: string },
) => {
  const apiUrl = `/api/v0/publishers/${pubId}/bam_link_preview/`;
  const request = {
    url: link,
    recommendations: true,
  };
  const result = Object.create({
    link: link,
    monetization_status: 'out-of-network',
    recommended_products: [],
    exclusions: null,
    error: false,
  });

  await context.effects.api
    .post(apiUrl, request)
    .then((res: any) => {
      result.monetization_status = res.data.data[0]['monetization_status'];
      result.recommended_products = res.data.data[0][
        'recommended_products'
      ].map(({ merchant, product }: { merchant: any; product: any }) => {
        return {
          merchant: merchant.short_name,
          productName: product.item_data.name,
          imageUrl: product.item_data.image_url,
          productUrl: product.store_page_url,
        };
      });
      result.exclusions = res.data.data[0]['exclusions']
        ? res.data.data[0]['exclusions']['description']
        : null;
    })
    .catch(() => {
      result.error = true;
    });

  return result;
};

export const saveSubscriptionDetails = (
  context: Context,
  { accountId, payload }: { accountId: any; payload: any },
) => {
  return context.effects.api.put(
    `/api/v0/merchants/${accountId}/update_subscription_info/`,
    { subscription_info: payload },
  );
};

export const editAccount = (
  context: Context,
  {
    params,
    accountType,
    accountId,
    affiliateConfigArgs,
  }: {
    params: any;
    accountType: any;
    accountId: any;
    affiliateConfigArgs: any;
  },
) => {
  const endpoint = `/api/v0/${accountType}s/${accountId}/`;
  const filteredParams = context.actions.account.filterParams({
    params,
    endpointType: `/${accountType}/id`,
  });
  const { firstErrorMsg, newParams, fieldErrors } =
    context.actions.account.validateParams({
      params: filteredParams,
    });
  const [linkMateErrors, validatedAffiliateConfig] =
    context.actions.account.validateAffiliateConfig({
      affiliateConfigArgs,
      fieldErrors,
    });
  const raiseError = firstErrorMsg || linkMateErrors;
  if (raiseError) {
    return Promise.reject([new Error(raiseError), fieldErrors]);
  }
  const paramsWithLinkConfig = {
    ...newParams,
    affiliate_config: {
      ...newParams.affiliate_config,
      ...validatedAffiliateConfig,
    },
  };
  return context.effects.api.put(endpoint, paramsWithLinkConfig);
};

export const createUser = (context: Context, { params }: { params: any }) => {
  const { firstErrorMsg, newParams, fieldErrors } =
    context.actions.account.validateParams({
      params,
    });
  if (firstErrorMsg) {
    return Promise.reject([new Error(firstErrorMsg), fieldErrors]);
  }
  return context.effects.api.post('/api/v0/users/', newParams);
};

export const editUser = (
  context: Context,
  { params, id }: { params: any; id: any },
) => {
  const { firstErrorMsg, newParams, fieldErrors } =
    context.actions.account.validateParams({
      params,
    });
  if (firstErrorMsg) {
    return Promise.reject([new Error(firstErrorMsg), fieldErrors]);
  }
  return context.effects.api.put(`/api/v0/users/${id}/`, newParams);
};

export const createPubUserWithRole = (
  context: Context,
  {
    pubId,
    firstName,
    lastName,
    email,
    role,
  }: {
    pubId: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  },
) => {
  const params = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    role: role,
  };
  return context.effects.api.post(
    `/api/v0/publisher_user_role/${pubId}/create_user/`,
    params,
  );
};

export const editPubUserWithRole = (
  context: Context,
  {
    pubId,
    uid,
    firstName,
    lastName,
    email,
    role,
  }: {
    pubId: string;
    uid: number;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  },
) => {
  const params = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    role: role,
  };
  return context.effects.api.put(
    `/api/v0/publisher_user_role/${pubId}/${uid}/`,
    params,
  );
};

export const deletePubUserWithRole = (
  context: Context,
  {
    pubId,
    uid,
  }: {
    pubId: string | string[];
    uid: number;
  },
) => {
  return context.effects.api.delete(
    `/api/v0/publisher_user_role/${pubId}/${uid}/`,
  );
};

export const setRole = (
  { state }: Context,
  role: PermissionRoles | undefined,
) => {
  // If role is not found, fall back to basic Team Member
  state.account.role = role ? role : RoleNames.TEAM_MEMBER;
};

export const getRole = ({ state }: Context) => {
  return state.account.role;
};

export const getPubUsersWithRoles = async (
  context: Context,
  {
    pubId,
    keyword,
    roles,
    sort_by,
  }: {
    pubId: number;
    keyword: string;
    roles: any;
    sort_by: string;
  },
) => {
  const params = {
    keyword: keyword,
    roles: roles,
    sort_by: sort_by,
  };
  return context.effects.api.get(
    `/api/v0/publisher_user_role/${pubId}/get_team_members/`,
    params,
  );
};

export const getCategories = (context: Context) => {
  return context.effects.api.get(`/api/v0/categories/`);
};

export const createCategory = (context: Context, name: string) => {
  const params = { name };
  return context.effects.api.post(`/api/v0/categories/`, params);
};

export const deleteCategory = (context: Context, categoryId: string) => {
  const params = { is_enabled: false };
  return context.effects.api.put(`/api/v0/categories/${categoryId}/`, params);
};

export const editCategory = (
  context: Context,
  { categoryId, name }: { categoryId: string; name: string },
) => {
  const params = { name };
  return context.effects.api.put(`/api/v0/categories/${categoryId}/`, params);
};

export const getUntagCounts = (context: Context, categoryId: string) => {
  return context.effects.api.get(
    `/api/v0/categories/${categoryId}/untag_counts/`,
  );
};

export const updateMerchCategoryPairings = (
  context: Context,
  { merchId, categoryIds }: { merchId: string; categoryIds: number[] },
) => {
  const params = { category_ids: categoryIds };
  return context.effects.api.put(
    `/api/v0/categories/${merchId}/update_merchant_mappings/`,
    params,
  );
};

export const updateMerchCategoryInterests = (
  context: Context,
  { merchId, categories }: { merchId: string; categories: any },
) => {
  return context.effects.api.put(
    `/api/v0/categories/${merchId}/update_merchant_mappings_v2/`,
    { categories },
  );
};

export const updatePubCategoryPairings = (
  context: Context,
  { pubId, categoryIds }: { pubId: string; categoryIds: any },
) => {
  const params = { category_ids: categoryIds };
  return context.effects.api.put(
    `/api/v0/categories/${pubId}/update_publisher_mappings/`,
    params,
  );
};

export const updatePubCategoryPairingsMultiInterest = (
  context: Context,
  { pubId, categories }: { pubId: string; categories: any },
) => {
  const params = { categories: categories };
  return context.effects.api.put(
    `/api/v0/categories/${pubId}/update_publisher_mappings_v2/`,
    params,
  );
};

export const setInNetworkOrgs = (context: Context, orgs: Org[]) => {
  context.state.account.inNetworkOrgs = orgs;
};

export const setOutOfNetworkOrgs = (context: Context, orgs: Org[]) => {
  context.state.account.outOfNetworkOrgs = orgs;
};

export const getNetworkOrgs = (
  context: Context,
  {
    accountType,
    accountId,
    otherOrgType,
    editing,
    showError,
  }: {
    accountType: any;
    accountId: any;
    otherOrgType: any;
    editing: any;
    showError: any;
  },
) => {
  const sortByName = context.effects.account.sortByName;
  const endpoint = `/api/v0/${accountType}s/${accountId}/network/${otherOrgType}s/`;
  return context.effects.api
    .get(endpoint)
    .then((res: any) => {
      const networkOrgs = res.data.data[0][`${otherOrgType}s`];

      const filteredInNetwork = networkOrgs.filter(
        (org: Org) => org.is_in_network,
      );
      const filteredOutOfNetwork = networkOrgs.filter(
        (org: Org) =>
          !org.is_in_network && (accountType !== 'publisher' || org.is_active),
      );

      if (editing) {
        setInNetworkOrgs(context, sortByName(filteredInNetwork));
        setOutOfNetworkOrgs(context, sortByName(filteredOutOfNetwork));
      } else {
        const combinedOrgs = sortByName(
          filteredInNetwork.concat(filteredOutOfNetwork),
        );
        setInNetworkOrgs(context, combinedOrgs);
      }
    })
    .catch((e: any) => showError(`Getting network failed, ${e && e.message}`));
};

export const updateNetwork = (
  context: Context,
  {
    otherOrgType,
    accountType,
    accountId,
  }: { otherOrgType: any; accountType: any; accountId: any },
) => {
  const id = otherOrgType === 'publisher' ? 'pub_id' : 'merch_id';
  const inNetworkOrgParams = context.state.account.inNetworkOrgs.map(
    (org: any) => {
      return { [id]: org[id], is_in_network: true };
    },
  );
  const outOfNetworkOrgParams = context.state.account.outOfNetworkOrgs.map(
    (org: any) => {
      return { [id]: org[id], is_in_network: false };
    },
  );
  const params = {
    [`${otherOrgType}s`]: [...inNetworkOrgParams, ...outOfNetworkOrgParams],
  };

  return context.effects.api.put(
    `/api/v0/${accountType}s/${accountId}/network/`,
    params,
  );
};

export const saveMerchantBilling = (
  context: Context,
  { params, stripeId }: { params: any; stripeId: any },
) => {
  const filteredParams = context.actions.account.filterParams({
    params,
    endpointType: '/billing/customers',
  });
  const { newParams } = context.actions.account.validateParams({
    params: filteredParams,
  });
  const validEmail = /^.+@.+\..+$/.test(params.email);
  if (validEmail) {
    if (stripeId) {
      return context.effects.api.put(
        `/api/v0/billing/customers/${stripeId}/`,
        newParams,
      );
    }
    return context.effects.api.post('/api/v0/billing/customers/', newParams);
  }
  return Promise.reject([
    new Error('Please use a valid Invoice Recipient email address'),
    ['invoice_recipient'],
  ]);
};

// export const validateParams: (
//   context: Context: { state: any; actions: any; effects: any },
//   { params, accountType }: { params: any; accountType: any },
// ) => { firstErrorMsg: any; newParams: any; fieldErrors: any } = (
//   context: Context,
//   { params, accountType },
// ): { firstErrorMsg: any; newParams: any; fieldErrors: any } => {
export const validateParams = (
  context: Context,
  { params }: { params: any },
) => {
  const newParams: any = cloneDeep(params);
  const fieldErrors: any[] = [];
  let firstErrorMsg = '';
  const validators = [
    context.actions.account.checkNonEmpty,
    context.actions.account.checkLength,
  ];

  validators.forEach((validate) => {
    if (!firstErrorMsg && newParams) {
      Object.entries(newParams).forEach(([param, value]) => {
        const errorMsg = validate({ param, value });
        // Only highlight one field if there's a length error
        if (
          errorMsg &&
          (!firstErrorMsg || validate !== context.actions.account.checkLength)
        ) {
          fieldErrors.push(param);
          firstErrorMsg = errorMsg;
        }
      });
    }
  });

  if (
    newParams &&
    newParams.widget_options &&
    newParams.widget_options.domains
  ) {
    if (!firstErrorMsg) {
      firstErrorMsg = context.actions.account.checkDomains({
        value: newParams.widget_options.domains,
      });
    }
    newParams.widget_options.domains = context.actions.account.formatDomains(
      newParams.widget_options.domains,
    );
  }
  return { firstErrorMsg, newParams, fieldErrors };
};

export const validateAffiliateConfig = (
  context: Context,
  {
    affiliateConfigArgs,
    fieldErrors,
  }: { affiliateConfigArgs: any; fieldErrors: any },
) => {
  const { defaultAffiliate, linkmateAffiliate } = affiliateConfigArgs;
  let configErr;
  if (
    Object.prototype.hasOwnProperty.call(
      affiliateConfigArgs,
      defaultAffiliate,
    ) &&
    !affiliateConfigArgs[defaultAffiliate]
  ) {
    configErr = `Missing information for ${defaultAffiliate}`;
    fieldErrors.push(defaultAffiliate.replace(/ /g, '-').toLowerCase());
  } else if (
    Object.prototype.hasOwnProperty.call(
      affiliateConfigArgs,
      linkmateAffiliate,
    ) &&
    !affiliateConfigArgs[linkmateAffiliate]
  ) {
    configErr = `Missing information for ${linkmateAffiliate}`;
    fieldErrors.push(linkmateAffiliate.replace(/ /g, '-').toLowerCase());
  }

  const defAffPayload =
    context.actions.account.getDefaultAndLinkmateAffiliateParams({
      key: defaultAffiliate,
      affiliateConfigArgs,
    });
  const linkAffPayload =
    context.actions.account.getDefaultAndLinkmateAffiliateParams({
      key: linkmateAffiliate,
      affiliateConfigArgs,
    });

  return [configErr, { ...defAffPayload, ...linkAffPayload }];
};

export const getDefaultAndLinkmateAffiliateParams = (
  context: Context,
  { key, affiliateConfigArgs }: { key: any; affiliateConfigArgs: any },
) => {
  const value = affiliateConfigArgs[key]?.value;
  const paramMap: any = {
    Amazon: { amazon_config: { id: value } },
    'Impact Radius': { impact_radius_config: { tracking_url: value } },
    'Impact Radius Subids': { impact_radius_config: { tracking_url: value } },
    'Commission Junction': {
      commission_junction_config: {},
    },
    Partnerize: { partnerize_config: {} },
    Linkshare: { linkshare_config: { member_id: value } },
    PMG: { pmg_config: { tracking_url: value } },
    Skimlinks: { skimlinks_config: { id: value } },
    Viglink: { viglink_config: { key: value } },
  };

  if (key === 'Commission Junction' && typeof value === 'object') {
    paramMap[key].commission_junction_config.tracking_url = value[0];
    if (value.length >= 2) {
      if (value[1] !== '') {
        paramMap[key].commission_junction_config.tracking_url_creator =
          value[1];
      }
      if (value.length >= 3 && value[2] !== '') {
        paramMap[key].commission_junction_config.affiliate_id = value[2];
      }
    }
  } else if (key === 'Partnerize' && typeof value === 'object') {
    paramMap[key].partnerize_config.tracking_url = value[0];
    if (value.length === 2 && value[1] !== '') {
      paramMap[key].partnerize_config.affiliate_id = value[1];
    }
  }

  return value ? paramMap[key] || {} : {};
};

export const removeProtocol = (context: Context, domain: string) => {
  // To strip protocol and trailing slash if any
  return domain.replace(/^https?:\/\/?/, '').replace(/\/$/, '');
};

export const formatDomains = (context: Context, domains: string[]) => {
  // Remove all empty domains
  let newDomains = cloneDeep(domains);
  newDomains = newDomains.filter((domain: any) => domain);

  newDomains.forEach((domain: any) => {
    const inputDomain = context.actions.account.removeProtocol(domain);
    if (domainRegex.exec(inputDomain as unknown as string)) {
      domain = inputDomain;
    }
  });

  // Remove all duplicate domains
  newDomains = newDomains.filter(
    (domain: any, index: any, domains: any) =>
      domains.indexOf(domain) === index,
  );

  return newDomains;
};

export const checkDomains = (context: Context, { value }: { value: any }) => {
  let errorMsg = '';
  value.forEach((domain: any) => {
    const inputDomain = context.actions.account.removeProtocol(domain);
    if (!domainRegex.exec(inputDomain as unknown as string)) {
      errorMsg = `Invalid domain name '${inputDomain}'`;
    }
  });

  return errorMsg;
};

export const checkNonEmpty = (
  context: Context,
  { param, value }: { param: any; value: any },
) => {
  let errorMsg;

  const requiredParams = [
    'full_name',
    'short_name',
    'url',
    'cogs',
    'email',
    'first_name',
    'last_name',
    'invoice_recipient',
  ];
  if (requiredParams.includes(param) && !value && typeof value === 'string') {
    errorMsg = 'Please complete all required fields.';
  }

  return errorMsg;
};

export const checkLength = (
  context: Context,
  { param, value }: { param: any; value: any },
): any => {
  let errorMsg;

  const maxLengths: any = {
    short_name: 49,
    first_name: 100,
    last_name: 100,
    email: 255,
  };

  if (
    Object.keys(maxLengths).includes(param) &&
    value.length > maxLengths[param]
  ) {
    const formattedParam = param.replace(/_/g, ' ');
    errorMsg = `${titleCase(formattedParam)} must be less than ${
      maxLengths[param]
    } characters.`;
  }

  return errorMsg;
};

export const sortByName = (
  context: Context,
  orgs: { short_name: string }[],
) => {
  const newOrgs = cloneDeep(orgs);

  return newOrgs.sort((a: any, b: any) =>
    a.short_name.toLowerCase() > b.short_name.toLowerCase() ? 1 : -1,
  );
};

export const sortByPubName = (
  context: Context,
  orgs: { pub_name: string }[],
) => {
  const newOrgs = cloneDeep(orgs);

  return newOrgs.sort((a: any, b: any) =>
    a.pub_name.toLowerCase() > b.pub_name.toLowerCase() ? 1 : -1,
  );
};

export const setEditingChannels = (context: Context, value: boolean) => {
  context.state.account.editingChannels = value;
};

export const setEditingBrands = (context: Context, value: boolean) => {
  context.state.account.editingBrands = value;
};

export const getBrands = async (
  context: Context,
  { pubId }: { pubId: any },
) => {
  const res = context.state.publisher.showMocks
    ? { data: mockBrands }
    : await context.effects.api.get(`/api/v0/creator_profile/${pubId}/brands/`);
  context.state.account.brands = res.data.data[0].brands;
};

export const getChannels = async (
  context: Context,
  { pubId }: { pubId: any },
) => {
  const res = context.state.publisher.showMocks
    ? { data: mockChannels }
    : await context.effects.api.get(
        `/api/v0/creator_profile/${pubId}/channels/`,
      );
  context.state.account.channels = res.data.data[0].channels;
};

const handleUpsertChannels = (formValues: any) => {
  const newSecondaryChannels = formValues.secondaryPlatforms.filter(
    (channel: any) => channel.secondaryPlatform,
  );

  return newSecondaryChannels.map((platform: any) => {
    return {
      ...(platform.secondaryPlatformId
        ? { channel_id: platform.secondaryPlatformId }
        : {}),
      channel_type: platform.secondaryPlatform.value,
      channel_url: platform.secondaryPlatformUrl,
    };
  });
};

const handleRemovedChannels = (prevValues: any, formValues: any) => {
  const prevSecondaryChannels = prevValues.filter(
    (channel: any) => !channel.is_primary,
  );

  const removedChannels = prevSecondaryChannels.filter(
    (prevChannel: any) =>
      !formValues.secondaryPlatforms.find(
        (newBrand: any) =>
          newBrand.secondaryPlatformId ===
          prevChannel.creator_profile_channel_id,
      ),
  );

  return removedChannels;
};

export const editChannels = async (
  context: Context,
  {
    pubId,
    formValues,
    prevValues,
  }: { pubId: any; formValues: any; prevValues: any },
) => {
  const upsertChannels = handleUpsertChannels(formValues);
  const removedChannels = handleRemovedChannels(prevValues, formValues);

  const params = {
    primary_channel: {
      channel_url: formValues.primaryPlatformUrl,
      channel_type: formValues.primaryPlatform.value,
    },
    ...(removedChannels.length
      ? {
          remove_channels: removedChannels
            .map((channel: any) => channel.creator_profile_channel_id)
            .join(', '),
        }
      : {}),
    // update and create channels
    upsert_channels: upsertChannels,
  };

  return context.effects.api.post(
    `/api/v0/creator_profile/${pubId}/channels/`,
    params,
  );
};

export const editBrands = async (
  context: Context,
  {
    pubId,
    formValues,
    prevValues,
  }: { pubId: any; formValues: any; prevValues: any },
) => {
  const newValues = formValues.brandPlatforms;
  const removedBrands = prevValues.filter(
    (prevBrand: any) =>
      !newValues.find(
        (newBrand: any) =>
          newBrand.label.toLowerCase() === prevBrand.name.toLowerCase(),
      ),
  );

  const createdBrands = newValues.filter(
    (newBrand: any) =>
      !prevValues.find(
        (prevBrand: any) =>
          prevBrand.name.toLowerCase() === newBrand.label.toLowerCase(),
      ),
  );

  const params = {
    ...(removedBrands.length
      ? {
          remove_brands: removedBrands
            .map((brand: any) => brand.creator_profile_brand_id)
            .join(', '),
        }
      : {}),
    // includes new and existing brands
    create_brands: createdBrands.map((brand: any) => {
      return brand.__isNew__
        ? {
            name: brand.value,
          }
        : {
            name: brand.label,
            merch_id: brand.merchId,
          };
    }),
  };

  return context.effects.api.post(
    `/api/v0/creator_profile/${pubId}/brands/`,
    params,
  );
};

export const removeSingleBrand = async (context: Context, brandId: number) => {
  return context.effects.api.post(
    `/api/v0/creator_profile/${context.state.organization.selectedOrgId}/brands/${brandId}/?favorite_status=false`,
  );
};

export const addSingleBrand = async (
  context: Context,
  { brandId }: { brandId: number },
) => {
  const params = {
    favorite_status: true,
  };

  return context.effects.api.post(
    `/api/v0/creator_profile/${context.state.organization.selectedOrgId}/brands/${brandId}/?favorite_status=true`,
    params,
  );
};
