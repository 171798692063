export type Stats = {
  pages: any[];
  total_items: number;
};
export type QueryParams = {
  date_from?: any;
  date_to?: any;
};
export type NetworkObject = {
  selected?: any;
  name?: any;
};

export class StatCache {
  total_items: any;

  totals: any;

  pages: any;

  constructor() {
    this.total_items = null;
    this.totals = null;
    this.pages = {}; // Store stats keyed on the backend page number
  }
}

type State = {
  limit: number;
  indexStart: number;
  indexEnd: number;
  page: number;
  reducedLimit: number;
  paginationOptions: number[];
  breadcrumbs: any;
  breadcrumbClicked: boolean;
  orderedKeys: string[];
  statCacheKeyedOnType: any;
  merchants: any;
  publishers: any;
  reportStatTypes: any;
  reportKey: any;
  dateRangeDropdowns: any;
};

export const state: State = {
  limit: 500, // The size of API responses, perPage is for the display
  indexStart: 0,
  indexEnd: 0,
  page: 0,
  reducedLimit: 100, // Don't need to always query 500 rows, 100 improves latency
  paginationOptions: [500, 100, 50],

  // Keep track of breadcrumbs throughout megatable components
  breadcrumbs: [],
  breadcrumbClicked: false,

  // Order of params for megatable queries
  orderedKeys: [
    'date_from',
    'date_to',
    'order_by',
    'direction',
    'edit_ids',
    'auction_id',
    'edit_id',
    'search_query',
    'networks',
    'granularity',
    'merch_ids',
    'group_by',
    'is_total',
    'page',
    'per_page',
    'no_returns',
    'additional_pubs',
    'additional_merchs',
    'pub_ids',
    'auction_ids',
  ],

  // Preserves stats of megatable components
  statCacheKeyedOnType: {
    article: null, // new StatCache()
    smartlink: null,
    merchant_clicks: null,
    merchant_epc: null,
    article_graph: null,
    merchantArticle: null,
    merchantRecommendation: null,
    merchantArticle_graph: null,
    merchantRecommendation_graph: null,
    merchantTopPublisher: null,
    /*
     * Graphed rows not in current page, needed for graphed IDs in
     * the query param to be honored when not in current page of stats
     */
    article_graph_rows: null,
  },
  merchants: [],
  publishers: [],
  dateRangeDropdowns: [
    {
      label: 'Last 7 Days',
      name: 'sevenDay',
      unit: 'day',
      selected: false,
    },
    {
      label: 'This Month',
      name: 'thisMonth',
      unit: 'day',
      selected: false,
    },
    {
      label: 'Last Month',
      name: 'lastMonth',
      unit: 'day',
      selected: false,
    },
    {
      label: 'Last 6 Months',
      name: 'halfYear',
      unit: 'month',
      selected: true,
    },
    {
      label: 'Year to Date',
      name: 'thisYear',
      unit: 'month',
      selected: false,
    },
    {
      label: 'Year over Year',
      name: 'multiYear',
      unit: 'year',
      selected: false,
    },
  ],
  reportStatTypes: {
    merchantArticle: 'merchant_articles',
    merchantRecommendation: 'merchant_recommendations',
    merchantArticleSearch: 'merchant_article_search',
    merchantProductsSearch: 'article_search_products',
    article: 'articles',
    smartlink: 'smartlinks',
    merchant: 'merchants',
    product: 'products',
  },
  reportKey: null,
};

export const Top5Fields = [
  { label: 'Spend', name: 'merchant_spend', type: 'currency', selected: true },
  {
    label: 'Revenue',
    name: 'attributed_revenue',
    type: 'currency',
    selected: true,
  },
  { label: 'Units Sold', name: 'attributed_product_count', selected: true },
  { label: 'Impressions', name: 'impressions', selected: true },
  { label: 'Clicks', name: 'clicks', selected: true },
  { label: 'CTR', name: 'click_through_rate', type: 'percent', selected: true },
  { label: 'ROI', name: 'roi', selected: true },
];

export const COLUMNS = {
  statColumns: [
    // Columns on article/smartlink/merchant tables, ordered for the first two
    {
      label: 'Earnings',
      name: 'publisher_total_earnings',
      type: 'currency',
      tooltip: `The Amount You've Earned`,
      tooltipSize: 1.7,
    },
    {
      label: 'Revenue',
      name: 'total_attributed_revenue',
      type: 'currency',
      tooltip: `Revenue driven to the merchant on sales of products you've written about`,
      tooltipSize: 2.8,
    },
    {
      label: 'Clicks',
      name: 'clicks',
    },
    {
      label: 'Conversion Rate',
      name: 'product_conversion_rate',
      type: 'percent',
      tooltip: 'Products sold divided by Clicks',
      tooltipSize: 0.8,
    },
    {
      label: 'AOV',
      name: 'publisher_aov',
      type: 'currency',
      tooltip: 'Average Order Value',
      tooltipSize: 2.6,
    },
  ],
  optionalColumns: [
    {
      label: 'Attr. Model',
      name: 'attribution_model',
      type: 'string',
      tooltip: 'Attribution Model',
      tooltipSize: 1,
    },
    {
      label: 'CTR',
      name: 'click_through_rate',
      type: 'percent',
      tooltip: 'Clickthrough Rate',
      tooltipSize: 2.6,
    },
    {
      label: 'Orders',
      name: 'total_attributed_orders',
    },
    {
      label: 'Order Conversion Rate',
      name: 'order_conversion_rate',
      type: 'percent',
      tooltip: 'Orders divided by Clicks',
      tooltipSize: 1,
    },
  ],

  // Columns displayed by default, except the static columns
  defaultFields: [
    'total_attributed_revenue',
    'publisher_total_earnings',
    'clicks',
    'publisher_epc',
    'product_conversion_rate',
    'publisher_aov',
  ],
  merchantDefaultFields: {
    merchant: [
      'publisher_epc',
      'publisher_total_earnings',
      'clicks',
      'total_attributed_revenue',
      'product_conversion_rate',
      'publisher_aov',
    ],
    merchantArticle: [
      'edit_name',
      'pub_name',
      'clicks',
      'order_conversion_rate',
      'total_attributed_revenue',
    ],
    merchantTopPublisher: [
      'pub_name',
      'merchant_spend',
      'attributed_revenue',
      'roi',
      'impressions',
      'clicks',
      'click_through_rate',
      'order_conversion_rate',
    ],
    merchantRecommendation: [
      'edit_name',
      'pub_name',
      'clicks',
      'order_conversion_rate',
      'total_attributed_revenue',
    ],
  },

  roundedFields: [
    'commission_rate',
    'commission_rate_new',
    'merchant_revenue',
    'merchant_spend',
    'product_price',
    'publisher_aov',
    'publisher_effective_cpc',
    'publisher_cpa_revenue',
    'publisher_cpc_revenue',
    'publisher_total_earnings',
    'total_attributed_revenue',
    'revenue',
    'spend',
    'roi_variance',
    'daily_budget_amount',
    'monthly_budget_amount',
    'percent_spend_remaining',
    'merchants_revenue',
    'pacing_towards',
    'revenue_percent_difference',
    'spend_percent_difference',
    'take_rate_percent_difference',
    'earnings_percent_difference',
    'earnings',
    'mtd_spend',
    'guardrail_upper_bound',
    'guardrail_lower_bound',
    'budget_amount',
    'budget_pacing',
    'daily_budget_usd',
    'monthly_budget_usd',
    'attributed_revenue',
  ],

  roundedFieldsLessThanOne: ['publisher_total_earnings'],

  // Table-specific columns
  merchantStatColumns: [
    {
      label: 'EPC',
      name: 'publisher_epc',
      type: 'currency',
      tooltip: 'Earnings per Click',
      tooltipSize: 2.6,
    },
    {
      label: 'Smartlinks',
      name: 'bam_link_count',
      tooltip: 'Number of Smartlinks',
      tooltipSize: 1.5,
    },
    {
      label: 'RPC',
      name: 'publisher_rpc',
      type: 'currency',
      tooltip: 'Revenue per Click',
      tooltipSize: 2.6,
    },
    { label: 'Views', name: 'bam_link_viewable_impressions' },
    { label: 'Impressions', name: 'bam_link_impressions' },
    { label: 'Clicks (CPA)', name: 'cpa_clicks' },
    { label: 'Clicks (OON)', name: 'oon_clicks' },
    { label: 'EPC (CPA)', name: 'publisher_effective_cpc', type: 'currency' },
    {
      label: 'Earnings (CPA)',
      name: 'publisher_cpa_revenue',
      type: 'currency',
    },
    {
      label: 'Earnings (CPC)',
      name: 'publisher_cpc_revenue',
      type: 'currency',
    },
  ],
  productStatColumns: [
    {
      label: 'Price',
      name: 'product_price',
      type: 'currency',
      tooltip:
        'Average price of the product across all checkouts in the time period ' +
        '(individual prices can vary)',
      tooltipSize: 4.5,
    },
    {
      label: 'Merchant',
      name: 'merchant_name',
      type: 'string',
    },
    {
      label: 'Quantity',
      name: 'quantity',
      tooltip: 'Total units sold of the product',
      tooltipSize: 2.5,
    },
    {
      label: 'Revenue',
      name: 'total_attributed_revenue',
      type: 'currency',
      tooltip:
        'Total revenue driven to the merchant by the product bought in the time period',
      tooltipSize: 3,
    },
  ],

  merchantArticleColumns: [
    { label: 'Publisher', name: 'pub_name', type: 'string' },
    { label: 'Clicks', name: 'clicks' },
    {
      label: 'Conversion Rate',
      name: 'order_conversion_rate',
      type: 'percent',
    },
    { label: 'Revenue', name: 'total_attributed_revenue', type: 'currency' },
  ],

  merchantRecommendationColumns: [
    {
      label: 'Recommended Product',
      name: 'recommended_product',
      type: 'string',
    },
    { label: 'Publisher', name: 'pub_name', type: 'string' },
    { label: 'Story', name: 'edit_name', type: 'string' },
    { label: 'Clicks', name: 'clicks' },
    {
      label: 'Conversion Rate',
      name: 'order_conversion_rate',
      type: 'percent',
    },
    { label: 'Revenue', name: 'total_attributed_revenue', type: 'currency' },
  ],

  optionalMerchantArticleColumns: [
    { label: 'Impressions', name: 'impressions' },
    { label: 'CTR', name: 'click_through_rate', type: 'percent' },
    { label: 'Spend', name: 'merchant_spend', type: 'currency' },
    { label: 'CPC', name: 'cost_per_click', type: 'currency' },
    { label: 'Orders', name: 'total_attributed_orders' },
    { label: 'Products Sold', name: 'total_attributed_product_count' },
  ],

  optionalMerchantRecommendationColumns: [
    { label: 'Impressions', name: 'impressions' },
    { label: 'CTR', name: 'click_through_rate', type: 'percent' },
    { label: 'Spend', name: 'merchant_spend', type: 'currency' },
    { label: 'CPC', name: 'cost_per_click', type: 'currency' },
    { label: 'Orders', name: 'total_attributed_orders' },
    { label: 'Products Sold', name: 'total_attributed_product_count' },
  ],

  staticColumnsByStatType: {
    article: [
      { label: 'Story', name: 'edit_name', type: 'string' },
      { label: 'Url', name: 'edit_url', type: 'string', exclude: true },
    ],
    merchant: [{ label: 'Merchant', name: 'merchant_name', type: 'string' }],
    product: [
      { label: 'Product Purchased', name: 'product_name', type: 'string' },
    ],
    smartlink: [
      { label: 'Link', name: 'bam_link_merchant_product_name', type: 'string' },
    ],
    merchantArticle: [{ label: 'Story', name: 'edit_name', type: 'string' }],
  },
  /*
   * Fields that cannot be de-selected in columns dropdown.
   * Currently also cannot be sorted by.
   */
  staticFieldsByStatType: {
    article: ['edit_name', 'edit_url'],
    merchant: ['merchant_name'],
    product: ['product_name'],
    smartlink: ['bam_link_merchant_product_name'],
    merchantArticle: ['edit_name'],
    merchantRecommendation: ['recommended_product', 'edit_name'],
    merchantTopPublisher: ['pub_name'],
  },

  /*
   * Fields where sorting isn't yet supported by the API
   */
  sortDisabledFields: ['affiliate_networks', 'edit_name'],

  merchantArticleDisabledFields: ['click_through_rate', 'pub_name'],

  merchantRecommendationDisabledFields: ['pub_name', 'recommended_product'],
  /*
   * Fields where we sort ascending by default
   */
  defaultAscendingFields: [
    'bam_link_merchant_product_name',
    'edit_name',
    'merchant_name',
    'product_name',
    'recommended_product',
  ],
  /*
   * Secondary users cannot see these fields across all tables
   */
  orgAdminOnlyFields: [
    'publisher_total_earnings',
    'publisher_epc',
    'commission_rate',
    'publisher_effective_cpc',
    'publisher_cpa_revenue',
    'publisher_cpc_revenue',
  ],

  merchantArticleSearchFields: [
    {
      label: 'Winning',
      name: 'is_winning',
      selected: true,
      sortDisabled: true,
    },
    {
      label: 'Publisher',
      name: 'pub_name',
      type: 'string',
      selected: true,
      sortDisabled: true,
    },
    {
      label: 'Article Name',
      name: 'edit_name',
      type: 'string',
      selected: true,
      sortDisabled: true,
    },
    { label: 'Page Views', name: 'impressions', selected: true },
    { label: 'Clicks', name: 'clicks', selected: true },
    {
      label: 'CVR',
      name: 'order_conversion_rate',
      type: 'percent',
      selected: true,
    },
    { label: 'Products', name: 'products_count', selected: true },
    { label: 'Revenue', name: 'revenue_range', type: 'string', selected: true },
    // TODO: enable after first release article search
    // {label: 'AOV', name: 'publisher_aov', type: 'currency', selected: false},
    // {label: 'Last Date Updated', name: 'last_updated', selected: false},
  ],

  merchantProductsSearchFields: [
    {
      label: 'Brand',
      name: 'brand',
      type: 'string',
      selected: true,
      sortDisabled: true,
    },
    {
      label: 'Product Name',
      name: 'recommended_product',
      type: 'string',
      selected: true,
      sortDisabled: true,
    },
    { label: 'Clicks', name: 'clicks', selected: true },
    {
      label: 'Price',
      name: 'product_price',
      type: 'currency',
      selected: true,
      sortDisabled: true,
    },
    { label: 'Qty Sold', name: 'total_attributed_orders', selected: true },
    {
      label: 'Revenue',
      name: 'total_attributed_revenue',
      type: 'currency',
      selected: true,
    },
    {
      label: 'CVR',
      name: 'order_conversion_rate',
      type: 'percent',
      selected: true,
    },
    { label: 'AOV', name: 'publisher_aov', type: 'currency', selected: true },
  ],

  MerchantPerformanceOverviewFields: [
    { label: 'Status', name: 'status', type: 'string', selected: true },
    { label: 'Campaign Name', name: 'name', type: 'string', selected: true },
    { label: 'Campaign Label', name: 'labels', type: 'string', selected: true },
    {
      label: 'Bidding Strategy',
      name: 'strategy_type',
      type: 'string',
      selected: false,
    },
    { label: 'ROI Goal', name: 'roi_goal', selected: true },
    { label: 'ROI', name: 'roi_actual', selected: true },
    {
      label: 'Budget',
      name: 'budget_amount',
      type: 'currency',
      selected: true,
    },
    { label: 'Spend', name: 'spend', type: 'currency', selected: true },
    {
      label: 'Campaign Pacing',
      name: 'budget_pacing',
      type: 'currency',
      selected: true,
    },
    { label: 'Revenue', name: 'revenue', type: 'currency', selected: true },
    { label: 'Impressions', name: 'impressions', selected: true },
    { label: 'Clicks', name: 'clicks', selected: true },
    {
      label: 'Start Date',
      name: 'datetime_start',
      type: 'string',
      selected: true,
    },
    { label: 'End Date', name: 'datetime_end', type: 'string', selected: true },
  ],

  Top5Fields,

  Top5PubsFields: [
    { label: 'Publisher', name: 'pub_name', type: 'string', selected: true },
    ...Top5Fields,
  ],

  top5StoriesFields: [
    { label: 'Story', name: 'story_name', type: 'string', selected: true },
    { label: 'Publisher', name: 'pub_name', type: 'string', selected: true },
    ...Top5Fields,
  ],

  merchantTopPublisherFields: [
    {
      label: 'Publisher',
      name: 'pub_name',
      type: 'string',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Revenue',
      name: 'attributed_revenue',
      type: 'currency',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Impressions',
      name: 'impressions',
      selected: true,
      defaultValue: '-',
    },
    { label: 'Clicks', name: 'clicks', selected: true, defaultValue: '-' },
    {
      label: 'CTR',
      name: 'click_through_rate',
      type: 'percent',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'CVR',
      name: 'order_conversion_rate',
      type: 'percent',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Orders',
      name: 'attributed_orders',
      selected: false,
      defaultValue: '-',
    },
    {
      label: 'Units Sold',
      name: 'attributed_product_count',
      selected: false,
      defaultValue: '-',
    },
  ],

  merchantTopPublisherHiddenFields: [
    {
      label: 'Spend',
      name: 'merchant_spend',
      type: 'currency',
      selected: true,
      defaultValue: '-',
    },
    { label: 'ROI', name: 'roi', selected: true, defaultValue: '-' },
  ],

  strategyTopPublishersFields: [
    {
      label: 'Publisher',
      name: 'pub_name',
      type: 'string',
      selected: true,
      defaultValue: '-',
    },
    {
      label: '# of Stories',
      name: 'number_of_stories',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Spend',
      name: 'merchant_spend',
      type: 'currency',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Revenue',
      name: 'attributed_revenue',
      type: 'currency',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Units Sold',
      name: 'attributed_product_count',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Impressions',
      name: 'impressions',
      selected: true,
      defaultValue: '-',
    },
    { label: 'Clicks', name: 'clicks', selected: true, defaultValue: '-' },
    {
      label: 'CTR',
      name: 'click_through_rate',
      type: 'percent',
      selected: true,
      defaultValue: '-',
    },
    { label: 'ROI', name: 'roi', selected: true, defaultValue: '-' },
    {
      label: 'CVR',
      name: 'order_conversion_rate',
      type: 'percent',
      selected: true,
      defaultValue: '-',
    },
  ],

  strategyTopStoriesFields: [
    {
      label: 'Publisher',
      name: 'pub_name',
      type: 'string',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Story',
      name: 'story_name',
      type: 'string',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Link Created',
      name: 'link_created_date',
      type: 'string',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Spend',
      name: 'merchant_spend',
      type: 'currency',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Revenue',
      name: 'attributed_revenue',
      type: 'currency',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Units Sold',
      name: 'attributed_product_count',
      selected: true,
      defaultValue: '-',
    },
    {
      label: 'Impressions',
      name: 'impressions',
      selected: true,
      defaultValue: '-',
    },
    { label: 'Clicks', name: 'clicks', selected: true, defaultValue: '-' },
    {
      label: 'CTR',
      name: 'click_through_rate',
      type: 'percent',
      selected: true,
      defaultValue: '-',
    },
    { label: 'ROI', name: 'roi', selected: true, defaultValue: '-' },
    {
      label: 'CVR',
      name: 'order_conversion_rate',
      type: 'percent',
      selected: true,
      defaultValue: '-',
    },
  ],

  strategyTopRecommendationsFields: [
    {
      label: 'Recommended Product',
      name: 'brand_product_name',
      selected: true,
    },
    { label: 'Publisher', name: 'pub_name', selected: true },
    { label: 'Story', name: 'story_name', selected: true },
    { label: 'Spend', name: 'merchant_spend', selected: true },
    { label: 'Revenue', name: 'attributed_revenue', selected: true },
    { label: 'Units Sold', name: 'attributed_product_count', selected: true },
    { label: 'Impressions', name: 'impressions', selected: true },
    { label: 'Clicks', name: 'clicks', selected: true },
    { label: 'CTR', name: 'click_through_rate', selected: true },
    { label: 'CVR', name: 'order_conversion_rate', selected: true },
  ],

  strategyTopProductsFields: [
    { label: 'Brand', name: 'brand', selected: true },
    { label: 'Product Purchased', name: 'brand_product_name', selected: true },
    { label: 'Price', name: 'unit_price', selected: true },
    { label: 'Units Sold', name: 'attributed_product_count', selected: true },
    { label: 'Revenue', name: 'attributed_revenue', selected: true },
  ],
};
