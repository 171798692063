import {
  isFlatFeeCampaign,
  getFlatFeeRange,
} from '../creator-campaign-center/effects';

export const HBO_MAX_PERFORMANCE_STRATEGY_ID =
  process.env.NEXT_PUBLIC_APP_ENV === 'release' ? 7371 : 147604;
const HBO_MAX_MERCH_ID =
  process.env.NEXT_PUBLIC_APP_ENV === 'release' ? 1435 : 3396;
const HBO_MAX_PERFORMANCE_COMMISSION = '$80 per Subscription';

export const trimCommission = (
  rate: any,
  params?: { strategyId?: number; merchId?: number; endText?: string },
) => {
  let endText = ' Commission';

  if (params?.endText === '' || params?.endText) {
    endText = params?.endText;
  }
  if (
    params?.strategyId === HBO_MAX_PERFORMANCE_STRATEGY_ID ||
    params?.merchId === HBO_MAX_MERCH_ID
  ) {
    return HBO_MAX_PERFORMANCE_COMMISSION;
  }
  const match = String(rate).match(/([0-9.]+)% Commission/i);
  if (match && match[1]) {
    if (
      Math.abs(Math.round(parseFloat(match[1])) - parseFloat(match[1])) < 0.02
    ) {
      // Parse the string to a float and round up to the nearest whole number
      const roundedNum = Math.round(parseFloat(match[1]));
      roundedNum.toString(); // Convert back to a string and return it
      match[1] = roundedNum.toString();
    }
  }
  return match
    ? `${parseFloat(match[1])}%${endText}`
    : rate === '0'
    ? 'Commission Not Applicable'
    : rate;
};

export const trimCardCommission = (strategy: any) => {
  const rate = strategy.strategy_rate;
  const commissionMatch = String(rate).match(/([0-9.]+)% Commission/i);
  const cpcMatch = String(rate).match(/(\$[0-9.]+) per click/i);

  if (commissionMatch && commissionMatch[1]) {
    if (
      Math.abs(
        Math.round(parseFloat(commissionMatch[1])) -
          parseFloat(commissionMatch[1]),
      ) < 0.02
    ) {
      // Parse the string to a float and round up to the nearest whole number
      const roundedNum = Math.round(parseFloat(commissionMatch[1]));
      roundedNum.toString(); // Convert back to a string and return it
      commissionMatch[1] = roundedNum.toString();
    }
  }
  return strategy.strategy_id === HBO_MAX_PERFORMANCE_STRATEGY_ID
    ? { value: HBO_MAX_PERFORMANCE_COMMISSION, label: '' }
    : isFlatFeeCampaign(strategy)
    ? {
        value: getFlatFeeRange(strategy),
        label: 'Flat Fee',
      }
    : cpcMatch
    ? {
        value: cpcMatch[1],
        label: 'Per Click',
      }
    : commissionMatch
    ? {
        value: `${parseFloat(commissionMatch[1])}%`,
        label: 'Commission',
      }
    : {
        value: '',
        label: '',
      };
};
