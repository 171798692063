import { useAppState } from '@frontend/howl-web-app/overmind';
import { Icon } from '@frontend/shared-ui-kit/icon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import React from 'react';

export const AccountCreatorNavItems = (props: {
  onNavClick: () => any;
  showAlertIcon: boolean;
}) => {
  const flags = useFlags();
  const { sessionData } = useAppState().session;
  const org: string = sessionData.orgId;

  const alertIcon = props.showAlertIcon ? (
    <Icon
      iconName="filledAlert"
      size={16}
      className="inline-flex ml-28"
      dataCy="nav-payment-setup-alert"
    />
  ) : null;

  return (
    <>
      {flags['subscriptions'] ? (
        <Link href={`/publisher/${org}/subscriptions/your-plan`} legacyBehavior>
          <li
            data-cy="nav-item-your-plan"
            onClick={() => props.onNavClick()}
            className="list-none h-10 flex items-center text-sm hover:bg-ice-10 focus:bg-ice-100 transition px-4 cursor-pointer"
          >
            Your Plan
          </li>
        </Link>
      ) : null}

      <Link
        href={`/publisher/${org}/account-settings/general-settings`}
        legacyBehavior
      >
        <li
          data-cy="nav-item-account-settings"
          onClick={() => props.onNavClick()}
          className="list-none h-10 flex items-center text-sm hover:bg-ice-10 focus:bg-ice-100 transition px-4 cursor-pointer"
        >
          Account Settings
          {alertIcon}
        </li>
      </Link>
    </>
  );
};
