import Link from 'next/link';
import React from 'react';

export const AdminAccountSettingsLink = () => {
  return (
    <Link href={`/admin/account-settings/general-settings`} legacyBehavior>
      <li
        className="list-none h-10 flex items-center text-sm hover:bg-ice-10 focus:bg-ice-100 transition px-4 cursor-pointer"
        onClick={() => close()}
      >
        Account Settings
      </li>
    </Link>
  );
};
