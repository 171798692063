import { cn } from '@frontend/shared-utils';
import React from 'react';
import { Typography } from '../typography';
import { Button } from '../button/button';

interface EmptyStateProps {
  children?: React.ReactNode;
  border?: boolean;
  className?: string;
}
const EmptyState = ({
  children,
  border = true,
  className,
}: EmptyStateProps) => {
  return (
    <div
      className={cn('w-full rounded-2xl p-8', className, {
        'border border-border-subtle': border,
      })}
    >
      {children}
    </div>
  );
};

const EmptyStateTitle = ({ children }: any) => {
  return <Typography variant="body-500">{children}</Typography>;
};
const EmptyStateDescription = ({ children }: any) => {
  return (
    <Typography variant="dense" className="text-text-secondary mt-2">
      {children}
    </Typography>
  );
};

const EmptyStateIcon = ({ children }: any) => {
  return (
    <div className="w-6 h-8 flex justify-center items-center">{children}</div>
  );
};

const EmptyStatePrimaryButton = ({ children, ...props }: any) => {
  return (
    <Button size="medium" variant={'primary'} {...props}>
      {children}
    </Button>
  );
};

const EmptyStateSecondaryButton = ({ children, ...props }: any) => {
  return (
    <Button size="medium" variant={'secondary'} {...props}>
      {children}
    </Button>
  );
};

const EmptyStateContent = ({
  children,
  variant,
  className,
}: {
  children: any;
  variant: 'row' | 'column';
  className: string;
}) => {
  return (
    <div
      className={cn('flex items-center mt-8', className, {
        'flex-row': variant === 'row',
        'flex-col': variant === 'column',
      })}
    >
      {children}
    </div>
  );
};

export {
  EmptyState,
  EmptyStateTitle,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateContent,
  EmptyStatePrimaryButton,
  EmptyStateSecondaryButton,
};
