export const LoadingLayout = ({ children }: any) => {
  return (
    <div className="flex flex-col h-full pb-safe">
      {/* Nav Skeleton */}
      <div className="h-14 flex items-center justify-between px-4 sm:px-8 border-b border-subtle">
        <div className="w-48 h-8 bg-black bg-opacity-10 rounded-lg animate-pulse" />
        <div className="w-32 h-8 bg-black bg-opacity-10 rounded-lg animate-pulse" />
      </div>

      {/* Body Skeleton */}
      <div className="w-full container max-w-6xl mx-auto mt-8 px-4 lg:px-0">
        <div className="h-8 w-60 rounded-lg animate-pulse bg-black bg-opacity-10"></div>
        <div className="mt-8 grid gap-8 grid-cols-1 sm:grid-cols-3">
          <div className="h-72 bg-black bg-opacity-10 rounded-lg animate-pulse"></div>
          <div className="h-72 bg-black bg-opacity-10 rounded-lg animate-pulse"></div>
          <div className="h-72 bg-black bg-opacity-10 rounded-lg animate-pulse"></div>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-8">
          <div className="bg-black bg-opacity-10 w-full h-72 rounded-lg animate-pulse"></div>
          <div className="bg-black bg-opacity-10 w-full h-72 rounded-lg animate-pulse"></div>
        </div>
      </div>

      <div className="hidden">{children}</div>
    </div>
  );
};
