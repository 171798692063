import { Context } from '..';
import { pipe, waitUntil } from 'overmind';
import { v4 as uuid } from 'uuid';

import { RateTier, Rule } from './state';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import without from 'lodash/without';

const getTargetListTypeField = (type: string) => {
  const listTypeField: { [k: string]: string } = {
    sku: 'target_rule_sku_list_id',
    brand: 'target_rule_brand_list_id',
    google_category: 'target_rule_google_category_list_id',
    merchant_category: 'target_rule_merchant_category_list_id',
    publisher: 'target_rule_publisher_list_id',
    supplier_type: 'target_rule_supplier_type',
    supplier_link_type: 'target_rule_supplier_link_type',
    link_date: 'target_rule_link_creation_datetime',
  };

  return listTypeField[type];
};

const getTargetTypeByField = (field: string) => {
  const listFieldType: { [k: string]: string } = {
    target_rule_sku_list_id: 'sku',
    target_rule_brand_list_id: 'brand',
    target_rule_google_category_list_id: 'google_category',
    target_rule_merchant_category_list_id: 'merchant_category',
    target_rule_publisher_list_id: 'publisher',
    target_rule_supplier_type: 'supplier_type',
    target_rule_supplier_link_type: 'supplier_link_type',
    target_rule_link_creation_datetime: 'link_date',
  };

  return listFieldType[field];
};

export const resetState = ({ state }: Context) => {
  state.ecommStrategyCreation = {
    ...state.ecommStrategyCreation,
    strategy: {
      strategy_id: null,
      target_expression_id: null,
    },
    formStrategyId: null,
    isFlatFeeEnabled: false,
    targeting: {
      targetExpression: [[]],
      targetRules: {},
    },
  };
};

export const resetTargeting = ({ state }: Context) => {
  state.ecommStrategyCreation.targeting = {
    targetExpression: [[]],
    targetRules: {},
  };
};

export const setStrategy = ({ state }: Context, strategy: any) => {
  state.ecommStrategyCreation.strategy = strategy;
};

export const setMerchant = ({ state }: Context, merchantId: number) => {
  state.ecommStrategyCreation.merchant.merchantId = merchantId;
};

export const setFormStrategyId = ({ state }: Context, strategyId: number) => {
  state.ecommStrategyCreation.formStrategyId = strategyId;
};

//  Targeting Page
export const getTargetExpressionById = async (
  { state, effects }: Context,
  expressionId: string,
) => {
  const { merchantId } = state.ecommStrategyCreation.merchant;

  const endpoint = `/api/v0/merchants/${merchantId}/target_expressions/${expressionId}/target_expression_by_id/`;

  try {
    const response = await effects.api.get(endpoint);
    return response.data.data[0];
  } catch (error) {
    console.error(error);
  }
};

export const getTargetListById = async (
  { state, effects }: Context,
  {
    targetListType,
    targetListId,
  }: { targetListType: string; targetListId: any },
) => {
  const { merchantId } = state.ecommStrategyCreation.merchant;
  const endpoint = `/api/v0/merchants/${merchantId}/target_lists/${targetListType}/${targetListId}/target_list_by_id/`;

  //  Arbitrarily large per_page value to avoid paging for now.
  //  SKU dropdown will need rework before paging is functional
  try {
    const response = await effects.api.get(endpoint, { per_page: 50000 });
    return response.data.data[0];
  } catch (error) {
    console.error(error);
  }
};

export const saveTargeting = async (
  { actions }: Context,
  { targetValues, cloneId }: { targetValues: any; cloneId: number },
) => {
  const { toIso8601UtcDatetime } = actions.date;
  const { updateTargetList, createTargetList, saveTargetExpression }: any =
    actions.ecommStrategyCreation;

  const targetRulesData = await Promise.all(
    Object.entries(targetValues).map(
      async ([targetRuleId, listValues]: any[]) => {
        const {
          target_list_items,
          target_list_type,
          target_list_id,
          target_rule_operator,
          target_rule_supplier_type,
          target_rule_supplier_link_type,
          target_rule_link_date,
          selectFromList,
        } = listValues;

        let payloadItems = null;

        if (selectFromList) {
          payloadItems = target_list_id;
        } else {
          if (target_list_type === 'publisher') {
            payloadItems = target_list_items.map((item: any) => parseInt(item));
          } else if (target_list_type === 'supplier_type') {
            payloadItems = target_rule_supplier_type;
          } else if (target_list_type === 'supplier_link_type') {
            payloadItems = target_rule_supplier_link_type;
          } else if (target_list_type === 'link_date') {
            payloadItems = toIso8601UtcDatetime(target_rule_link_date);
          } else {
            payloadItems = target_list_items.slice();
          }
        }

        let targetPayload = payloadItems;

        if (
          target_list_type !== 'supplier_type' &&
          target_list_type !== 'supplier_link_type' &&
          target_list_type !== 'link_date' &&
          !selectFromList
        ) {
          targetPayload =
            target_list_id && !cloneId
              ? await updateTargetList({
                  targetListItems: payloadItems,
                  targetListType: target_list_type,
                  targetListId: target_list_id,
                })
              : await createTargetList({
                  targetListItems: payloadItems,
                  targetListType: target_list_type,
                });
        }

        return {
          targetRuleId,
          targetPayload,
          targetOperator: target_rule_operator,
          targetListType: target_list_type,
        };
      },
    ),
  );

  return saveTargetExpression({ targetRulesData });
};

export const updateTargetList = (
  { state, effects }: Context,
  { targetListItems, targetListType, targetListId }: any,
) => {
  const { api } = effects;
  const { merchantId } = state.ecommStrategyCreation.merchant;

  const endpoint = `/api/v0/merchants/${merchantId}/target_lists/${targetListType}/${targetListId}/update_target_list/`;

  return api
    .put(endpoint, {
      target_list: {
        target_list_items: targetListItems,
      },
    })
    .then(() => targetListId)
    .catch((err: Error) => console.log(err.message));
};

export const createTargetList = (
  { state, effects }: Context,
  { targetListItems, targetListType }: any,
) => {
  const { api } = effects;
  const { merchantId } = state.ecommStrategyCreation.merchant;
  const { formStrategyId } = state.ecommStrategyCreation;

  const endpoint = `/api/v0/merchants/${merchantId}/target_lists/${targetListType}/create_target_list/`;

  return api
    .post(endpoint, {
      target_list: {
        target_list_name: `${formStrategyId}_${targetListType}`,
        target_list_type: targetListType,
        target_list_items: targetListItems,
      },
    })
    .then((resp: any) => resp.data.data[0].target_list_id)
    .catch((err: Error) => console.log(err.message));
};

export const saveTargetExpression = (
  { state, actions, effects }: Context,
  {
    targetRulesData,
  }: {
    targetRulesData: {
      targetPayload: any;
      targetRuleId: string;
      targetOperator: string;
      targetListType: string;
    }[];
  },
) => {
  const { merchantId } = state.ecommStrategyCreation.merchant;
  const { strategy } = state.ecommStrategyCreation;
  const { formStrategyId } = state.ecommStrategyCreation;
  const { formatTargetRulesPayload } = actions.ecommStrategyCreation;
  const { api } = effects;

  const createEndpoint = `/api/v0/merchants/${merchantId}/target_expressions/create_target_expression/`;
  const updateEndpoint = `/api/v0/merchants/${merchantId}/target_expressions/${strategy.target_expression_id}/update_target_expression/`;

  const targetRulesMap: any = {};
  targetRulesData.forEach(
    ({ targetPayload, targetRuleId, targetOperator, targetListType }) => {
      const retVal = {
        [getTargetListTypeField(targetListType)]: [
          'supplier_type',
          'link_date',
          'supplier_link_type',
        ].includes(targetListType)
          ? targetPayload
          : parseInt(targetPayload),
        target_rule_operator: targetOperator,
      };

      if (!targetRuleId.startsWith('new')) {
        retVal['target_rule_id'] = parseInt(targetRuleId);
      }

      targetRulesMap[targetRuleId] = retVal;

      return retVal;
    },
  );

  const payload = {
    target_expression: {
      strategy_id: formStrategyId,
      target_rules: formatTargetRulesPayload(targetRulesMap),
    },
  };

  return strategy.target_expression_id
    ? Promise.resolve(api.put(updateEndpoint, payload))
    : Promise.resolve(api.post(createEndpoint, payload));
};

export const addTargetingGroup = ({ state }: Context) => {
  const { targetExpression } = state.ecommStrategyCreation.targeting;
  state.ecommStrategyCreation.targeting.targetExpression = [
    ...targetExpression,
    [],
  ];
};

export const removeTargetingGroup = (
  { state }: Context,
  targetGroup: string,
) => {
  const { targetExpression } = state.ecommStrategyCreation.targeting;

  const newList = [...targetExpression];
  newList.splice(parseInt(targetGroup), 1);

  state.ecommStrategyCreation.targeting.targetExpression = newList;
};

export const addTargetingRule = (
  { state }: Context,
  { targetGroup, targetId }: { targetGroup: number; targetId: string },
) => {
  const { targetExpression } = state.ecommStrategyCreation.targeting;

  const newList = [...targetExpression];

  newList[targetGroup].push(targetId);
  state.ecommStrategyCreation.targeting.targetExpression = newList;
};

export const removeTargetingRule = (
  { state }: Context,
  { targetGroup, targetId }: { targetGroup: number; targetId: string },
) => {
  const { targetExpression } = state.ecommStrategyCreation.targeting;

  const newList = without([...targetExpression[targetGroup]], targetId);

  state.ecommStrategyCreation.targeting.targetExpression[targetGroup] = newList;
};

export const formatTargetRule = async ({ actions }: Context, rule: Rule) => {
  const typeIdPair = Object.entries(rule).filter(([listType, listId]) => {
    return (
      !['target_rule_operator', 'target_rule_id'].includes(listType) &&
      listId !== null
    );
  })[0];

  if (!typeIdPair) {
    return null;
  }

  const targetListType = getTargetTypeByField(typeIdPair[0]);
  const targetListId = typeIdPair[1];

  if (targetListType === 'supplier_type') {
    return {
      target_rule_id: rule.target_rule_id,
      target_list_type: targetListType,
      target_rule_operator: rule.target_rule_operator,
      target_rule_supplier_type: rule.target_rule_supplier_type,
    };
  }

  if (targetListType === 'supplier_link_type') {
    return {
      target_rule_id: rule.target_rule_id,
      target_list_type: targetListType,
      target_rule_operator: rule.target_rule_operator,
      target_rule_supplier_link_type: rule.target_rule_supplier_link_type,
    };
  }

  if (targetListType === 'link_date') {
    return {
      target_rule_id: rule.target_rule_id,
      target_list_type: targetListType,
      target_rule_operator: rule.target_rule_operator,
      target_rule_link_date: rule.target_rule_link_creation_datetime,
    };
  }

  return actions.ecommStrategyCreation
    .getTargetListById({ targetListType, targetListId })
    .then((targetData: any) => {
      const targetList = targetData.target_list;
      const targetCount = targetData.page_info.total_items;

      const targetListItems = targetList.map((item: any) => {
        return item[`target_${targetListType}`];
      });

      return {
        target_rule_id: rule.target_rule_id,
        target_list_type: targetListType,
        target_rule_operator: rule.target_rule_operator,
        target_list_items: targetListItems,
        target_list: targetList,
        target_list_id: targetListId,
        target_count: targetCount,
      };
    });
};

export const formatTargetExpression = (
  { state, actions }: Context,
  {
    targetRules,
    targetExpression,
    cloneId,
  }: {
    targetRules: any;
    targetExpression: string;
    cloneId?: string;
  },
) => {
  const { formatTargetRule } = actions.ecommStrategyCreation;

  return Promise.all(
    targetRules.map((rule: any) => formatTargetRule(rule)),
  ).then((targetRuleValues) => {
    const targetExpressionValues: { [k: string]: any } = {};

    targetRuleValues.forEach((rule: any) => {
      if (rule) {
        const ruleKey = cloneId ? `new-${uuid()}` : rule.target_rule_id;
        targetExpressionValues[ruleKey] = rule;
      }
    });

    let expressionFormat = JSON.parse(targetExpression);

    if (cloneId && targetExpressionValues) {
      const cloneExpressionFormat: string[][] = expressionFormat.map(
        (targetGroup: string[]) => {
          return targetGroup.map((targetRuleId: string) => {
            return (
              Object.entries(targetExpressionValues as any).find(
                ([, ruleValue]: [string, any]) =>
                  ruleValue.target_rule_id === targetRuleId,
              ) as any
            )[0];
          });
        },
      );

      expressionFormat = cloneExpressionFormat;
    }

    if (expressionFormat.length === 0) {
      expressionFormat.push([]);
    }

    state.ecommStrategyCreation.targeting.targetExpression = expressionFormat;

    return targetExpressionValues;
  });
};

export const formatTargetRulesPayload = (
  { state }: Context,
  targetRulesMap: { [k: string]: any },
) => {
  const { targetExpression } = state.ecommStrategyCreation.targeting;
  let payload: any = [];

  targetExpression.forEach((group, idx) => {
    payload.push([]);
    group.forEach((ruleId: string) => {
      payload[idx].push(targetRulesMap[ruleId]);
    });
  });

  payload = payload.filter((group: any[]) => group.length);

  return payload;
};

//  Customization Page
export const setRateTiers = async (
  { state }: Context,
  rateTiers: { [k: string]: RateTier },
) => {
  state.ecommStrategyCreation.rateTiers = rateTiers;
};

export const getRateTiers = pipe(
  waitUntil((state: any) => !!state.ecommStrategyCreation.merchant.merchantId),
  async ({ state, effects }: Context, strategyId: string | number) => {
    const { merchant } = state.ecommStrategyCreation;
    const endpoint = `/api/v0/merchants/${merchant.merchantId}/strategies/${strategyId}/rate_tiers/`;
    try {
      const response = await effects.api.get(endpoint);
      return response.data.data[0].rate_tiers;
    } catch (error) {
      console.error(error);
    }
  },
);

export const saveRateTiers = async (
  { actions }: Context,
  rateTiers: RateTier[],
) => {
  const { createRateTier, updateRateTier } = actions.ecommStrategyCreation;
  return await Promise.all(
    rateTiers.map(async (rt: RateTier) => {
      if (
        typeof rt.rate_tier_id === 'string' &&
        rt.rate_tier_id.startsWith('new')
      ) {
        return await createRateTier(rt);
      } else {
        return await updateRateTier(rt);
      }
    }),
  );
};

export const createRateTier = (
  { state, effects, actions }: Context,
  rateTier: RateTier,
) => {
  const { merchant, formStrategyId, rateTiers } = state.ecommStrategyCreation;
  const { formatRateTierFormData } = actions.ecommStrategyCreation;

  const endpoint = `/api/v0/merchants/${merchant.merchantId}/strategies/${formStrategyId}/rate_tiers/`;
  const payload = omit(rateTier, ['rate_tier_id']);

  return effects.api
    .post(endpoint, { rate_tier: payload })
    .then((res: any) => {
      const newRateTierId = res.data.data[0].rate_tier.rate_tier_id;
      const newRateTiers = { ...rateTiers };
      newRateTiers[newRateTierId] = formatRateTierFormData({
        ...rateTier,
        rate_tier_id: newRateTierId,
      });
      delete newRateTiers[rateTier.rate_tier_id];
      state.ecommStrategyCreation.rateTiers = newRateTiers;

      return res;
    })
    .catch((error: any) => {
      throw error;
    });
};

export const updateRateTier = (
  { state, effects }: Context,
  rateTier: RateTier,
) => {
  const { merchant, formStrategyId } = state.ecommStrategyCreation;
  const endpoint = `/api/v0/merchants/${merchant.merchantId}/strategies/${formStrategyId}/rate_tiers/${rateTier.rate_tier_id}/`;

  return effects.api
    .put(endpoint, { rate_tier: rateTier })
    .then((res: any) => res)
    .catch((error: any) => {
      throw error;
    });
};

export const formatRateTierPayload = ({ actions }: Context, rateTier: any) => {
  const payload: any = {
    ...pick(rateTier, [
      'rate_tier_name',
      'rate_tier_description',
      'strategy_id',
      'rate_tier_id',
      'rate_tier_is_enabled',
      'rate_tier_is_default',
    ]),
  };

  if (!rateTier.rate_tier_description) {
    payload.rate_tier_description = null;
  }

  //  Pricing Model
  if (rateTier.pricingModel === 'CPC') {
    payload.strategy_bid = parseFloat(rateTier.pricingModelValue);
  }

  if (rateTier.pricingModel === 'EPP') {
    payload.strategy_roi_goal = parseFloat(rateTier.pricingModelValue);
  }

  //  Payment Model
  if (rateTier.paymentModel === 'EPP') {
    payload.strategy_publisher_roi_target = parseFloat(
      rateTier.paymentModelValue,
    );
  }

  if (rateTier.paymentModel === 'EPC') {
    payload.strategy_publisher_epc = parseFloat(rateTier.paymentModelValue);
  }

  if (rateTier.paymentModel === null) {
    payload.strategy_cogs = parseFloat(rateTier.paymentModelValue) / 100;
  }

  if (rateTier.partnerValue) {
    if (rateTier.partnerGrouping === 'partnerType') {
      payload.publisher_type =
        rateTier.partnerValue === 'publisher' || rateTier.publisher_type === 1
          ? 'publisher'
          : 'creator';
    }

    if (rateTier.partnerGrouping === 'partnerList') {
      payload.publisher_list_id = rateTier.partnerValue;
    }

    if (rateTier.flat_fees) {
      payload.flat_fees = rateTier.flat_fees.map((ff: any) => {
        const newFlatFee = { ...ff };
        //  TODO: Re-insert once validation is placed before formatting
        // if (ff.flat_fee_id.toString().startsWith('new')) {
        //   delete newFlatFee.flat_fee_id;
        // }
        newFlatFee.amount = parseFloat(ff.amount).toFixed(2).toString();
        newFlatFee.post_count = parseInt(ff.post_count);
        newFlatFee.datetime_start = actions.date.formatDate({
          dateObj: ff.datetime_start,
          fmt: 'YYYY-MM-DD',
          isUtc: true,
        });

        return newFlatFee;
      });
    }
  }

  return payload;
};

export const formatRateTiersForm = (
  { actions }: Context,
  rateTierSettings: {
    rateTiers: any[];
    campaignPricingModel: string;
    campaignPaymentModel: string | null;
  },
) => {
  const { formatRateTierFormData } = actions.ecommStrategyCreation;
  const formRateTierValues = {} as { [k: string]: RateTier };
  if (rateTierSettings.rateTiers) {
    rateTierSettings.rateTiers
      .filter((rt) => !rt.rate_tier_is_default)
      .forEach((rt: RateTier) => {
        formRateTierValues[rt.rate_tier_id] = formatRateTierFormData({ ...rt });
        // enforce pricing and payment model to match campaign
        formRateTierValues[rt.rate_tier_id].pricingModel =
          rateTierSettings.campaignPricingModel;
        formRateTierValues[rt.rate_tier_id].paymentModel =
          rateTierSettings.campaignPaymentModel;
      });
  }

  return formRateTierValues;
};

export const formatRateTierFormData = (
  { state }: Context,
  rateTier: RateTier,
) => {
  const { strategy } = state.ecommStrategyCreation;
  const { targetLists } = state.listManagement;

  const formattedValues: any = {
    ...pick(rateTier, [
      'rate_tier_name',
      'rate_tier_description',
      'strategy_id',
      'rate_tier_id',
      'rate_tier_is_enabled',
      'rate_tier_is_default',
      'pricingModelValue',
      'paymentModelValue',
      'partnerValue',
      'partnerGrouping',
      'flat_fees',
    ]),
  };

  //  Pricing Model
  if (rateTier.strategy_bid === null && rateTier.strategy_roi_goal === null) {
    formattedValues.pricingModel = strategy.strategy_pricing_model;
    formattedValues.pricingModelValue =
      strategy.strategy_pricing_model === 'CPC'
        ? strategy.strategy_bid
        : strategy.strategy_pricing_model === 'EPP'
        ? strategy.strategy_roi_goal
        : null;
  }

  if (rateTier.strategy_bid) {
    formattedValues.pricingModel = 'CPC';
    formattedValues.pricingModelValue = rateTier.strategy_bid;
  }

  if (rateTier.strategy_roi_goal) {
    formattedValues.pricingModel = 'EPP';
    formattedValues.pricingModelValue = rateTier.strategy_roi_goal;
  }

  //  Payment Model
  formattedValues.paymentModel = strategy.strategy_payment_model;
  //  Disabled or Paused Rate Tier
  if (!rateTier.rate_tier_is_enabled) {
    formattedValues.paymentModelValue = null;
  }
  //  New Rate Tier
  else if (
    [
      rateTier.strategy_publisher_roi_target,
      rateTier.strategy_publisher_epc,
      rateTier.strategy_cogs,
    ].every((v) => v === null)
  ) {
    if (strategy.strategy_payment_model === 'EPP') {
      formattedValues.paymentModelValue =
        strategy.strategy_publisher_roi_target;
    }

    if (strategy.strategy_payment_model === 'EPC') {
      formattedValues.paymentModelValue = strategy.strategy_publisher_epc;
    }
    if (strategy.strategy_payment_model === null) {
      formattedValues.paymentModelValue = strategy.strategy_cogs
        ? strategy.strategy_cogs * 100
        : null;
    }
  }
  //  Existing Rate Tier
  else {
    if (rateTier.strategy_publisher_roi_target) {
      formattedValues.paymentModelValue =
        rateTier.strategy_publisher_roi_target;
    } else if (rateTier.strategy_publisher_epc) {
      formattedValues.paymentModelValue = rateTier.strategy_publisher_epc;
    } else {
      formattedValues.paymentModelValue = rateTier.strategy_cogs
        ? rateTier.strategy_cogs * 100
        : null;
    }
  }

  if (formattedValues.paymentModelValue) {
    formattedValues.paymentModelValue = parseFloat(
      formattedValues.paymentModelValue,
    ).toFixed(2);
  }

  //  Partner

  if (rateTier.publisher_type) {
    formattedValues.partnerGrouping = 'partnerType';
    const pubType = rateTier.publisher_type === 1 ? 'publisher' : 'creator';
    formattedValues.partnerValue = pubType;
  }

  if (rateTier.publisher_list_id) {
    formattedValues.partnerGrouping = 'partnerList';
    const publisherList = targetLists.publisher.find(
      (pubList) => pubList.target_list_id === rateTier.publisher_list_id,
    );

    if (publisherList) {
      formattedValues.partnerValue = publisherList.target_list_id;
    } else {
      formattedValues.partnerValue = rateTier.publisher_list_id;
    }
  }

  return formattedValues;
};

export const setFlatFeesAccess = ({ state }: Context, payload: boolean) => {
  state.ecommStrategyCreation.isFlatFeeEnabled = payload;
};

export const validatePayload = (context: Context, payload: any) => {
  let isValid = true;
  const inputErrors: any = {};
  const formErrors: any = new Set();

  payload.forEach((rt: any) => {
    inputErrors[rt.rate_tier_id] = {};
    if (
      !rt.rate_tier_is_default &&
      !rt.publisher_type &&
      !rt.publisher_list_id
    ) {
      isValid = false;
      inputErrors[rt.rate_tier_id]['Partners'] = ['All fields are required'];
    }

    inputErrors[rt.rate_tier_id].flat_fees = {};

    const dupMap = {} as any;
    const dups = new Set();
    if (rt.flat_fees)
      rt.flat_fees.forEach((ff: any) => {
        inputErrors[rt.rate_tier_id].flat_fees[ff.flat_fee_id] = {};
        if (dupMap[ff.datetime_start]) {
          dups.add(dupMap[ff.datetime_start]);
          dups.add(ff.flat_fee_id);
        }
        dupMap[ff.datetime_start] = ff.flat_fee_id;
      });

    const dupArray = Array.from(dups);
    dupArray.forEach((ffId: any) => {
      inputErrors[rt.rate_tier_id].flat_fees[ffId].datetime_start = [
        'No duplicate months',
      ];
    });
    if (dupArray.length) {
      formErrors.add('No duplicate months');
      isValid = false;
    }
  });
  return { isValid, inputErrors, formErrors: Array.from(formErrors) };
};

export const setMerchantFeeRateEPP = (
  { state }: Context,
  feeRate: number | null,
) => {
  state.ecommStrategyCreation.merchant.merchantFeeRateEPP = feeRate;
};

export const setMerchantFeeRateCPC = (
  { state }: Context,
  feeRate: number | null,
) => {
  state.ecommStrategyCreation.merchant.merchantFeeRateCPC = feeRate;
};

export const calculatePartnerRate = (
  { state }: Context,
  {
    pricingModel,
    pricingModelValue,
    strategyEppRate,
    strategyCpcRate,
    paymentModelValue,
  }: {
    pricingModel: string;
    pricingModelValue: number;
    strategyEppRate?: number;
    strategyCpcRate?: number;
    paymentModelValue?: number;
  },
) => {
  const { strategy, merchant } = state.ecommStrategyCreation;

  const merchantEppRate = merchant.merchantFeeRateEPP;
  const merchantCpcRate = merchant.merchantFeeRateCPC;
  const stratEppRate = strategyEppRate || strategy.epp_fee_rate;
  const stratCpcRate = strategyCpcRate || strategy.cpc_fee_rate;
  const paymentModelSet = !!paymentModelValue;

  if (paymentModelSet) {
    return null;
  }

  if (pricingModel === 'EPP' && !merchantEppRate && !stratEppRate) {
    return null;
  }

  if (pricingModel === 'CPC' && !merchantCpcRate && !stratCpcRate) {
    return null;
  }

  let rate: number | null = null;
  // Campaign-level transaction fee acts as override, otherwise default to account-level transaction fee
  if (pricingModel === 'EPP' && pricingModelValue) {
    // rate = (1 / ROI) - TransactionFee
    rate =
      (1 / pricingModelValue -
        (stratEppRate ? stratEppRate : merchantEppRate || 0)) *
      100;
  } else if (pricingModel === 'CPC' && pricingModelValue) {
    // rate = CPC - (CPC * TransactionFee)
    rate =
      pricingModelValue -
      pricingModelValue * (stratCpcRate ? stratCpcRate : merchantCpcRate || 0);
  }

  if (rate && rate < 0) {
    return null;
  }

  return rate;
};

export const setStrategyEditId = ({ state }: Context, strategyId: number) => {
  state.ecommStrategyCreation.strategyEditId = strategyId;
};

export const setStrategyGroupEditId = (
  { state }: Context,
  strategyGroupId: number,
) => {
  state.ecommStrategyCreation.strategyGroupEditId = strategyGroupId;
};
