'use client';

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastIconError,
  ToastIconSuccess,
  ToastIconWarning,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '../toast';
import { useToast } from './use-toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        let icon;
        switch (props.variant) {
          case 'success':
            icon = <ToastIconSuccess />;
            break;
          case 'warning':
            icon = <ToastIconWarning />;
            break;
          case 'error':
            icon = <ToastIconError />;
            break;
          default:
            icon = null;
        }
        return (
          <Toast key={id} {...props}>
            {icon}
            <div className="grid">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
              {action}
            </div>
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
