export type Guild = 'tech' | 'fashion' | 'beauty' | 'home' | 'gaming';
export type Tier = 'low' | 'medium' | 'high' | 'custom';
export type Partner = {
  full_name: string;
  logo_url: string;
  pub_id: number;
};

export type CreatorTier = {
  total_pubs: number;
  total_partners: number;
  base_rate: number;
  partners: Partner[];
};

export type TargetListPublisher = {
  pub_id: number;
  full_name: string;
  logo_url?: string;
  publisher_type: number;
  guild: string;
};

type EcommRatesListsState = {
  merchantId: string | null;
  data: {
    creatorTiers: {
      [key in Tier]: CreatorTier | null;
    };
    targetListCreators: { [target_list_id: number]: any };
  };
  editingRateTier: Tier | null;
};

export const state: EcommRatesListsState = {
  merchantId: null,
  data: {
    creatorTiers: {
      low: null,
      medium: null,
      high: null,
      custom: null,
    },
    targetListCreators: {},
  },
  editingRateTier: null,
};
