export type AccountLink = {
  currently_due: any;
  disabled_reason: string;
  pending_verification: any;
  payouts_enabled: any;
  account_setup_complete: any;
  errors: any[];
  url: string;
  current_deadline: null;
};

type State = {
  accountLink: AccountLink | null;
  earningsColumns: any;
  payoutsColumns: any;
  payoutsBreakdownColumns: any;
  instantPayInfo: any;
  transferClicked: any;
  bonusInfo: any;
  loadingInstantPayInfo: boolean;
};

export const state: State = {
  accountLink: null,
  earningsColumns: null,
  payoutsColumns: null,
  payoutsBreakdownColumns: [
    {
      dataKey: 'transfer_date',
      label: 'Transaction Date',
      width: 4,
      sortable: true,
    },
    {
      dataKey: 'merchant_name',
      label: 'Merchant',
      width: 2,
      justifyContent: 'left',
      sortable: true,
    },
    {
      dataKey: 'earnings_month',
      label: 'Earnings Month',
      width: 1,
      justifyContent: 'right',
      sortable: true,
    },
    {
      dataKey: 'amount_usd',
      label: 'Amount',
      width: 1.8,
      justifyContent: 'right',
      sortable: true,
    },
  ],
  instantPayInfo: null,
  transferClicked: false,
  bonusInfo: {},
  loadingInstantPayInfo: false,
};
