import { sortAlphabetical } from '@frontend/shared-utils';
import cloneDeep from 'lodash/cloneDeep';
import { pipe, debounce } from 'overmind';
import { Context, PermissionRoles, RoleNames } from '../';
import { AdminOrgItem, MerchOrgItem, PubOrgItem } from './state';

export const loadOrgs = async ({ state, actions }: Context) => {
  if (!state.organization.orgs.length) {
    state.organization.isLoading = true;
    let organizations: any[] = [];
    const responses = await Promise.all(
      actions.auth.buildOrgRequests(state.session.sessionData),
    );

    responses.forEach((resp: any) => {
      organizations = [...organizations, ...(resp?.data?.data || [])];
    });

    actions.organization.sortOrgs(organizations);
    organizations = actions.organization.filterOrgs(organizations);
    if (state.session.sessionData?.isAdmin) {
      organizations = [...organizations, { pub_id: 0, short_name: 'Admin' }];
      state.session.sessionData.allAdminOrgs = organizations;
    }
    state.organization.orgs = organizations;
    state.organization.isLoading = false;
  }
};

// TODO: remove this function and use pure react-query for state
export const setOrgs = ({ state }: Context, orgs: any[] | undefined) => {
  state.organization.orgs = orgs || [];

  if (state.session.sessionData?.isAdmin) {
    state.session.sessionData.allAdminOrgs = orgs || [];
  }
};

export const sortOrgs = (context: Context, orgs: any[]) => {
  return sortAlphabetical(orgs, 'short_name');
};

export const setLoading = (context: Context, value: boolean) => {
  context.state.organization.isLoading = value;
};

export const filterOrgs = (context: Context, orgs: any[]) => {
  const orgIsHidden = (org: any) => {
    // TODO Refactor show in dashboard for pub_id.
    // This should only affect admin dropdown and not affect creator access to platform
    if (org.pub_id) {
      return false;
    }

    // Hide pilot orgs and inactive merchants from dropdown
    if (org.merch_id) {
      return !org.show_in_dashboard;
    }

    // If show_in_dashboard is enabled, do not hide org
    return (!org.is_active || org.is_pilot) && !org.show_in_dashboard;
  };
  return orgs.filter((org) => !orgIsHidden(org));
};

export const setSelectedOrg = (
  { state, actions }: Context,
  {
    selectedOrg,
    allSites = false,
  }: {
    selectedOrg: MerchOrgItem | PubOrgItem | AdminOrgItem;
    allSites?: boolean;
  },
) => {
  if (!selectedOrg) {
    return;
  }

  //Update session for backwards compatibility
  actions.auth.updateSession({
    theOrg: selectedOrg as any,
    allSites: allSites,
  });

  state.organization.allSites = allSites;

  let selectedOrgType: 'publishers' | 'merchants' | 'admin' | null = null;
  let selectedOrgId = null;
  if ('merch_id' in selectedOrg) {
    selectedOrgId = selectedOrg.merch_id;
    selectedOrgType = 'merchants';
  }
  if ('pub_id' in selectedOrg) {
    selectedOrgId = selectedOrg.pub_id;
    if (selectedOrg.pub_id === 0) {
      selectedOrgType = 'admin';
    } else {
      selectedOrgType = 'publishers';
    }

    if ('categories' in selectedOrg) {
      state.organization.selectedOrgCategories = selectedOrg.categories;
    }
  }

  if (selectedOrgId && selectedOrgType) {
    const role = actions.organization.findRole({
      selectedOrgId,
      selectedOrgType,
    });
    actions.account.setRole(role);
  }

  state.organization.selectedOrgType = selectedOrgType;
  state.organization.selectedOrgId = selectedOrgId;

  state.organization.selectedOrg = cloneDeep(selectedOrg);
  actions.creatorOnboarding.setOnboardingStatus();

  if (
    selectedOrgType === 'merchants' &&
    (selectedOrg as MerchOrgItem).pricing_model
  ) {
    actions.session.update({
      merchPricingModel: (selectedOrg as MerchOrgItem).pricing_model,
    });
  }
};

export const setSearchFilter = pipe(
  debounce(300),
  ({ state }: Context, val: string) => {
    state.organization.searchFilter = val;
  },
);

export const clearOrgs = ({ state }: Context) => {
  state.organization.orgs = [];
  state.organization.selectedOrgType = null;
  state.organization.selectedOrgId = null;
  state.organization.allSites = false;
  state.organization.selectedOrg = null;
};

export const findRole = (
  { state }: Context,
  {
    selectedOrgId,
    selectedOrgType,
  }: { selectedOrgId: number; selectedOrgType: string },
) => {
  if (state.session.sessionData.isAdmin) {
    return RoleNames.ACCOUNT_OWNER;
  }

  const accessList = state.auth.accessList;

  const role: PermissionRoles | undefined = accessList?.find((org) => {
    return org.orgId === selectedOrgId && org.type === selectedOrgType;
  })?.role;

  return role;
};
