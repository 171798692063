import { AccessListItem } from '../auth/state';

export type User = {
  isAdmin?: boolean;
  accessList?: AccessListItem[];
};

type State = {
  user: User | null;
};

export const state: State = {
  user: null,
};
