import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

export function requiresEmailVerified<T>(
  WrappedComponent: React.FunctionComponent<T>,
): React.FunctionComponent<T> {
  const WithRole = (props: T) => {
    const router = useRouter();
    const { data } = useSession();
    const email_verified = data?.user?.email_verified;

    useEffect(() => {
      if (email_verified === false) {
        router.push('/');
      }
    }, [email_verified]);

    if (email_verified) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };

  WithRole.displayName = `requiresEmailVerified()`;

  return WithRole;
}
