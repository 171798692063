import * as React from 'react';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';

export const Button = ({
  text,
  icon,
  iconRight,
  outlined,
  minimal,
  className,
  onClick,
  disabled,
}: IButtonProps) => {
  return (
    <button
      type="button"
      className={classNames(
        'inline-flex items-center cursor-pointer px-4 py-2 border border-transparent rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 tracking-wider disabled:opacity-50',
        {
          'flex-row-reverse': iconRight,
          'text-white bg-primary-blue-1000 hover:bg-primary-blue-900':
            !minimal && !outlined,
          'border-primary-blue-1000 text-primary-blue-1000': outlined,
          'border-0 text-primary-blue-1000': minimal,
        },
        className,
      )}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      {icon}
      {text}
    </button>
  );
};

export interface IButtonProps {
  /** The text label shown in the button. */
  text?: string;

  /** An optional icon component to display. */
  icon?: React.ReactElement;

  /** If using an icon, should it align right? Defaults left. */
  iconRight?: boolean;

  /** Whether this button should use outlined styles. */
  outlined?: boolean;

  /** Whether this button should use minimal styles. */
  minimal?: boolean;

  /** Additional classes that override default button classes. */
  className?: string;

  /** Click handler for when button is clicked. */
  onClick?: MouseEventHandler;

  /** If the button is disabled or not. */
  disabled?: boolean;
}
