import React, { useEffect, useState } from 'react';
import { useAppState } from '@frontend/howl-web-app/overmind';

import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

export function checkOnboardingStatus<T>(
  WrappedComponent: React.FunctionComponent<T>,
): React.FunctionComponent<T> {
  const WithOnboardingStatus = (props: T) => {
    const router = useRouter();
    const { selectedOrgType, selectedOrgId, selectedOrg } =
      useAppState().organization;
    const { onboardingStatus } = useAppState().creatorOnboarding;
    const [onboardStatusSet, setOnboardingStatusSet] = useState(false);
    const [disableRedirect, setDisableRedirect] = useState(false);
    const session = useSession();

    useEffect(() => {
      // Disable redirect if user is admin and on onboarding page
      if (
        session.data?.user?.role === 'admin' &&
        router.pathname.includes('/onboarding')
      ) {
        setDisableRedirect(true);
      }
      // Redirect to onboarding page if user is publisher and onboarding status is 1, 2, 6
      if (
        selectedOrgId &&
        selectedOrgType === 'publishers' &&
        selectedOrg?.onboarding_status &&
        [1, 2, 6].includes(selectedOrg?.onboarding_status) &&
        ![3, 4].includes(onboardingStatus || 0) &&
        router.isReady &&
        router.pathname !== '/publisher/[publisherId]/onboarding'
      ) {
        if (!disableRedirect) {
          router.push(`/publisher/${selectedOrgId}/onboarding`);
        }
      }

      setOnboardingStatusSet(true);
    }, [selectedOrgId, router.pathname]);

    if (onboardStatusSet) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };

  WithOnboardingStatus.displayName = `checkOnboardingStatus()`;

  return WithOnboardingStatus;
}
