import React, { useEffect, useState } from 'react';
import { useActions, useAppState } from '@frontend/howl-web-app/overmind';
import {
  Bonus,
  cn,
  formatNumberWithCommas,
  getBonusStatus,
  GoalCreator,
  GoalState,
  GoalTier,
  GoalType,
} from '@frontend/shared-utils';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { Button } from '@frontend/shared-ui-kit/v2/button';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { BonusDropdown } from './index';
import { useToast } from '@frontend/shared-ui-kit/v2/toaster';
import { useMediaQuery } from '@mantine/hooks';
import Link from 'next/link';
import { Icon } from '@frontend/shared-ui-kit/v2/icon';
import { useRouter } from 'next/router';
import { Badge } from '@frontend/shared-ui-kit/v2/badge';
import { api } from '@frontend/howl-web-app/trpc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

interface BonusBarProps {
  goal: Bonus;
}

export const BonusBar = ({ goal }: BonusBarProps) => {
  const { toast } = useToast();
  const { selectedOrgId } = useAppState().organization;
  const { subtractDatesV2 } = useActions().date;
  const { optInPlatformBonus } = useActions().creatorHomepage;

  const [goalType, setGoalType] = useState<GoalType>();
  const [goalState, setGoalState] = useState<GoalState>();
  const [isOptedIn, setIsOptedIn] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [totalBonusEarnedDB, setTotalBonusEarnedDB] = useState<string>();
  const [earningsDB, setEarningsDB] = useState<string>();
  const [finalEarningsDate, setFinalEarningsDate] = useState<Date>();

  const router = useRouter();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const pubId = router.query['publisherId'] as string;
  const now = new Date();
  const flags = useFlags();

  const { data } = api.creatorBonusEarnings.getGoalTierAchievements.useQuery(
    {
      pub_id: selectedOrgId as number,
      goal_id: goal.goal_id as number,
    },
    {
      staleTime: 1000 * 60 * 5, // 5 Minutes
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data && data[0]) {
      setTotalBonusEarnedDB(parseInt(data[0].tierRewardSum).toLocaleString());
      setEarningsDB(data[0].amount);
    }
  }, [data]);

  const daysToFinal = 13;
  const daysToHide = 21;

  let currentTierIndex: number = 0;
  let earnings: number;

  useEffect(() => {
    if (goal && data) {
      setIsOptedIn(
        goal.goal_creator.find(
          (gc: GoalCreator) => gc.pub_id === parseInt(pubId),
        )?.opted_in === true,
      );

      setGoalType(goal.goal_type as GoalType);
      const bonusStatus = getBonusStatus(goal, false, daysToHide);
      setGoalState(bonusStatus);

      earnings =
        bonusStatus === GoalState.Expired && earningsDB !== undefined
          ? parseInt(earningsDB as string)
          : parseFloat(goal.earnings_usd as string);

      const currentTier: GoalTier | undefined = goal.goal_tiers.find(
        (tier: GoalTier) => earnings <= parseInt(tier.gmv_goal as string),
      );

      currentTierIndex = currentTier
        ? goal.goal_tiers.indexOf(currentTier)
        : goal.goal_tiers.length;

      // Show Final state if goal is expired and all tiers have been achieved, or if current date is after finalize date
      if (bonusStatus !== GoalState.Hidden) {
        const allTiersAchieved = goal.goal_tiers.length === currentTierIndex;
        const endDate = new Date(goal.datetime_end);
        const finalEarningsDate = new Date(endDate);
        finalEarningsDate.setDate(endDate.getDate() + daysToFinal);
        setFinalEarningsDate(finalEarningsDate);

        if (
          (allTiersAchieved && bonusStatus === GoalState.Expired) ||
          moment(now).isAfter(finalEarningsDate)
        ) {
          setGoalState(GoalState.Final);
        }
      }
    }
  }, [goal, earningsDB, data]);

  const hideExpired =
    (goalState === GoalState.Expired || goalState === GoalState.Final) &&
    !isOptedIn;

  // Early exit if query fails, there's no response data, or goal is hidden
  if (!goalState || goalState === GoalState.Hidden || hideExpired) {
    return null;
  }

  const daysLeft = Math.floor(
    subtractDatesV2({
      d1: goal.datetime_end,
      d2: now,
      unit: 'day',
    }) as number,
  );

  const optInAvailable = goal.goal_type !== 'NEW_CREATOR_BONUS';

  const completedTiers: GoalTier[] = goal.goal_tiers.filter(
    (tier: GoalTier) => (earnings || 0) >= parseFloat(tier.gmv_goal as string),
  );

  const nextTierIndex = Math.min(completedTiers.length, goal.goal_tiers.length);

  const topRewardAmount = goal.goal_tiers
    .reduce((acc, tier) => acc + parseInt(tier.tier_reward_usd as string), 0)
    .toLocaleString();

  const amountLeft =
    nextTierIndex < goal.goal_tiers.length
      ? parseFloat(goal.goal_tiers[nextTierIndex].gmv_goal as string) -
        Math.floor(parseFloat(goal.earnings_usd || '0'))
      : 0;

  const isGoalCompleted = amountLeft <= 0;

  const totalRewardEarned = () => {
    let total = 0;
    for (let i = 0; i < completedTiers.length; i++) {
      total += parseInt(completedTiers[i].tier_reward_usd as string);
    }
    return total.toLocaleString();
  };

  const joinProgram = () => {
    if (!goal.goal_id) return;
    optInPlatformBonus({
      goalId: goal.goal_id,
    }).then((r) => {
      setIsOptedIn(true);
      toast({
        title: 'Bonus Program Joined!',
        description: `Make progress by creating links and sharing with your audience!`,
        variant: 'success',
      });

      // Sets the tier in local storage back to 0 so that we can differentiate between goals
      window.localStorage.setItem(
        'howl_bonus_tier',
        JSON.stringify({ tier: 0 }),
      );
    });
  };

  const showHideBonusesButton = () => {
    return (
      <Button
        className={cn(
          'md:!w-32 md:ml-8 !p-0 !w-8 !font-medium text-white !text-sm bg-transparent hover:bg-transparent focus:bg-transparent shrink-0',
          [GoalState.Expired, GoalState.Final].includes(goalState) &&
            'text-violet-400',
        )}
        onClick={(e) => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        {isDropdownOpen
          ? isMobile
            ? ''
            : 'Hide Bonus'
          : isMobile
          ? ''
          : 'Show Bonus'}

        {isDropdownOpen ? (
          <ChevronUpIcon
            width={18}
            height={18}
            className={'md:ml-1 ml-0 mt-[1px] stroke-2'}
          />
        ) : (
          <ChevronDownIcon
            width={18}
            height={18}
            className={'md:ml-1 ml-0 mt-[1px] stroke-2'}
          />
        )}
      </Button>
    );
  };

  return (
    <>
      <BonusDropdown
        goal={goal}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        goalState={goalState}
        finalEarningsDate={finalEarningsDate}
        totalBonusEarnedDB={totalBonusEarnedDB}
        earningsDB={earningsDB}
        currentTierIndex={currentTierIndex}
      ></BonusDropdown>

      <div
        className={cn(
          'z-[25] w-full text-white h-auto text-sm',
          isOptedIn && isMobile && 'flex-row',
        )}
      >
        {/* Hot 100 Bar */}
        {goalType === GoalType.HowlHot100 &&
          goalState === GoalState.InProgress && (
            <div
              className={
                'min-h-10 w-full bg-gradient-to-r from-[#DA2468] via-[#D2493C] to-[#DA5191] md:px-8 px-4 flex flex-row gap-2 py-2 justify-between'
              }
            >
              <div
                className={cn(
                  'my-auto text-white',
                  optInAvailable && isMobile && 'flex',
                )}
              >
                <span className={'font-medium my-auto overflow-nowrap'}>
                  {goal.name}
                </span>
                {amountLeft <= 0 && !isMobile && (
                  <span>
                    <span> • </span>
                    <span>
                      Congrats, you've already locked in an upfront $
                      {totalRewardEarned()} bonus!
                    </span>
                  </span>
                )}
              </div>

              <Link
                href={`/publisher/${selectedOrgId}/payments/earnings`}
                className="flex font-medium text-white items-center"
              >
                <span className="mr-1">View Earnings</span>
                <ArrowRightIcon width={18} height={18} />
              </Link>
            </div>
          )}

        {/* Howl Holiday Bonus/New Creator Bonus In Progress Bar */}
        {(goalType === GoalType.HowlHolidayBonus ||
          goalType === GoalType.NewCreatorBonus) &&
          goalState === GoalState.InProgress &&
          !isGoalCompleted && (
            <div
              className={cn(
                'w-full bg-violet-300 md:px-8 px-4 flex flex-row gap-2 py-2 justify-between',
                goal.goal_tiers.length === 1 && isMobile && 'flex-col pb-3',
                optInAvailable &&
                  !isOptedIn &&
                  'bg-primary-10 text-black flex-row',
              )}
            >
              <div
                className={cn('my-auto', optInAvailable && isMobile && 'flex')}
              >
                <span className={'font-medium my-auto overflow-nowrap'}>
                  {goal.name}
                </span>

                {optInAvailable && !isOptedIn && !isMobile && (
                  <span>
                    <span> is back! Earn up to </span>
                    <span className={'font-medium'}>
                      {' '}
                      ${topRewardAmount.toLocaleString()}{' '}
                    </span>
                    <span>
                      by recommending to your community the products you love
                      this holiday season.
                    </span>
                  </span>
                )}

                {((isOptedIn && !isMobile) || goal.goal_tiers.length === 1) && (
                  <span>
                    <span> • </span>
                    <span>You need </span>
                    <span className="font-medium">
                      ${formatNumberWithCommas(amountLeft)}
                    </span>
                    <span> more in sales to earn </span>

                    {nextTierIndex > 0 ? (
                      <span> an additional </span>
                    ) : (
                      <span> a </span>
                    )}

                    <span className="font-medium">
                      $
                      {formatNumberWithCommas(
                        parseInt(
                          goal.goal_tiers[nextTierIndex]
                            .tier_reward_usd as string,
                        ),
                      )}
                    </span>
                    <span> bonus.</span>
                  </span>
                )}
              </div>
              <div
                className={cn(
                  'flex flex-row grow justify-normal sm:justify-end sm:ml-16 shrink-0',
                  goal.goal_tiers.length === 1 && 'justify-end',
                  goal.goal_tiers.length === 1 && isMobile && 'justify-start',
                )}
              >
                <Badge
                  variant={
                    optInAvailable && !isOptedIn
                      ? 'default'
                      : daysLeft > 7
                      ? 'brand'
                      : daysLeft <= 7 && daysLeft > 1
                      ? 'warning'
                      : 'critical'
                  }
                  className={cn(
                    'h-6 font-medium my-auto',
                    isMobile && 'mx-auto',
                    optInAvailable && !isOptedIn && 'bg-white/60',
                  )}
                  leadingProp={null}
                >
                  Ends in {daysLeft}{' '}
                  {daysLeft > 1 || daysLeft < 1 ? 'days' : 'day'}
                </Badge>

                {optInAvailable && !isOptedIn && (
                  <Button
                    className="md:!w-32 md:ml-8 !p-0 !font-medium !justify-end text-violet-400 !bg-transparent !text-sm hover:bg-transparent focus:bg-transparent shrink-0"
                    onClick={(e) => {
                      joinProgram();
                    }}
                  >
                    {isMobile ? 'Join' : 'Join Program'}
                    <ArrowRightIcon
                      width={18}
                      height={18}
                      className={'ml-1 mt-[1px]'}
                    />
                  </Button>
                )}
                {goal.goal_tiers.length > 1 &&
                  isOptedIn &&
                  showHideBonusesButton()}
              </div>
            </div>
          )}

        {/* Howl Holiday Bonus/New Creator Bonus Completed Bar */}
        {(goalType === GoalType.HowlHolidayBonus ||
          goalType === GoalType.NewCreatorBonus) &&
          goalState === GoalState.InProgress &&
          isGoalCompleted && (
            <div
              className={
                'w-full bg-gradient-to-r from-green-300 to-green-400 md:px-8 px-4 flex flex-row gap-2 py-2 justify-between text-white'
              }
            >
              <div className={'my-auto'}>
                <span className="font-medium my-auto">{goal.name}</span>
                {!isMobile && (
                  <span>
                    <span> • </span>
                    <span>Congrats, you've earned a </span>
                    <span className="font-medium">
                      ${formatNumberWithCommas(totalRewardEarned())}
                    </span>
                    <span> bonus</span>
                    {goal.goal_tiers.length > 1 ? (
                      <span> — you've unlocked the top bonus!</span>
                    ) : (
                      <span> bonus.</span>
                    )}
                  </span>
                )}
              </div>

              <div
                className={
                  'flex flex-row grow justify-normal sm:justify-end sm:ml-16'
                }
              >
                <Badge
                  variant={'success'}
                  className={cn(
                    'h-6 font-medium text-green-400 my-auto',
                    isMobile && 'mx-auto',
                  )}
                  leadingProp={null}
                >
                  <Icon
                    iconName={'circleCheckOutline'}
                    className="mr-1 stroke-2"
                  />
                  Completed
                </Badge>
                {goal.goal_tiers.length > 1 ? (
                  showHideBonusesButton()
                ) : (
                  <Link
                    href={`/publisher/${selectedOrgId}/payments/earnings`}
                    className="flex font-medium text-white mr-4 items-center"
                  >
                    <span className="mr-1">View Earnings</span>
                    <ArrowRightIcon width={18} height={18} />
                  </Link>
                )}
              </div>
            </div>
          )}

        {/* Howl Holiday Bonus Expired Bar */}
        {goalType === GoalType.HowlHolidayBonus &&
          (goalState === GoalState.Expired ||
            goalState === GoalState.Final) && (
            <div
              className={
                'w-full bg-neutral-100 md:px-8 px-4 flex flex-row gap-2 py-2 justify-between'
              }
            >
              <div
                className={cn(
                  'my-auto text-black',
                  optInAvailable && isMobile && 'flex',
                )}
              >
                <span className={'font-medium my-auto overflow-nowrap'}>
                  {goal.name}
                </span>
                {!isMobile && (
                  <span>
                    <span> • </span>
                    <span>The earning period has ended — you earned </span>
                    <span className="font-medium">
                      $
                      {(goalState === GoalState.Expired ||
                        goalState === GoalState.Final) &&
                      totalBonusEarnedDB !== undefined
                        ? totalBonusEarnedDB
                        : totalRewardEarned()}
                    </span>
                    <span> in bonuses. </span>
                  </span>
                )}
              </div>

              <div
                className={
                  'flex flex-row grow justify-normal sm:justify-end sm:ml-16'
                }
              >
                {goalState === GoalState.Final ? (
                  <Badge
                    variant={'success'}
                    className={cn(
                      'h-6 font-medium text-green-400 my-auto',
                      isMobile && 'mx-auto',
                    )}
                    leadingProp={null}
                  >
                    <Icon
                      iconName={'circleCheckOutline'}
                      className="mr-1 stroke-2"
                    />
                    Bonus Earned
                  </Badge>
                ) : (
                  <Badge
                    variant={'critical'}
                    className={cn(
                      'h-6 font-medium my-auto',
                      isMobile && 'mx-auto',
                    )}
                    leadingProp={null}
                  >
                    Pending Final Calculation
                  </Badge>
                )}
                {goal.goal_tiers.length > 1 && showHideBonusesButton()}
              </div>
            </div>
          )}
      </div>
    </>
  );
};
