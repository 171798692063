import Head from 'next/head';
import classNames from 'classnames';

import { useActions, useAppState } from '@frontend/howl-web-app/overmind';
import { HelpWidget } from '@frontend/shared-ui-kit/help-widget';
import { Snackbar } from '@frontend/shared-ui-kit/snackbar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { checkOnboardingStatus } from '@frontend/howl-web-app/feature-main';
import { FeatureFlagWidget } from '@frontend/shared-ui-kit/feature-flag-widget';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { isObject } from 'lodash';
import { useLocalStorage } from '@mantine/hooks';
import { Bonus, useMediaQuery } from '@frontend/shared-utils';
import { isEmpty } from 'radash';
import { Toaster } from '@frontend/shared-ui-kit/v2/toaster/toaster';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  PortalNavigationHeader,
  PortalMobileNavigationHeader,
  PortalMobileNavigationSideBar,
  VerifyEmailBanner,
  BonusBar,
  EarningsFooter,
  Flag,
} from '@frontend/howl-web-app/feature-main';
import { BrickBanner } from '@frontend/howl-web-app/shared/components';

export const CreatorLayout = checkOnboardingStatus(
  ({ children, isLoading }: any) => {
    const { snackbar, snackbarIter, helpWidgetVisible } = useAppState().ui;
    const { clearSnackbar } = useActions().ui;
    const { selectedOrg } = useAppState().organization;
    const { data: session } = useSession();
    const { sessionData } = useAppState().session;
    const { fetchBonuses } = useActions().creatorHomepage;

    const flags = useFlags();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const showPortalMobileNavigationHeader = selectedOrg && isMobile;
    const showPortalNavigationHeader = selectedOrg && !isMobile;
    const showPortalMobileNavigationSideBar = selectedOrg && isMobile;
    const router = useRouter();
    const email_verified = session?.user?.email_verified;

    const [emailVerified, setEmailVerified] = useState<boolean>(false);
    const [sessionHideVerifyEmail, setValue] = useLocalStorage<boolean>({
      key: 'sessionHideVerifyEmail',
      defaultValue: false,
    });

    // Don't show BonusBar if the user's email is unverified
    useEffect(() => {
      setEmailVerified(
        isEmpty(session) ||
          !isObject(session) ||
          email_verified !== false ||
          sessionHideVerifyEmail ||
          !flags?.delayedEmailVerification ||
          !sessionData?.email,
      );
    }, [
      session,
      email_verified,
      sessionHideVerifyEmail,
      flags?.delayedEmailVerification,
      sessionData?.email,
    ]);

    const [goal, setGoal] = useState<Bonus>();

    const displayName = !isEmpty(selectedOrg)
      ? selectedOrg.full_name
        ? `${selectedOrg.full_name}`
        : `${selectedOrg.short_name}`
      : '';

    useQuery({
      queryKey: ['bonusBar', selectedOrg?.pub_id],
      queryFn: async () => {
        return await fetchBonuses({ howlWideOnly: true, endPadding: 21 });
      },
      staleTime: 1000 * 60 * 5, // 5 Minutes
      refetchOnWindowFocus: false,
      onSuccess: (goals) => {
        goals = goals.filter((goal) => goal.merch_id === null);
        for (const goal of goals) {
          const optedIn = goal.goal_creator.length > 0;
          if (goal.goal_type === 'HOWL_HOT_100' && optedIn) {
            setGoal(goal);
            break;
          } else if (goal.goal_type !== 'HOWL_HOT_100') {
            setGoal(goal);
          }
        }
      },
      enabled:
        !router.pathname.includes('/onboarding') &&
        !!selectedOrg &&
        selectedOrg?.pub_id !== 0,
    });

    return (
      <div className={'mt-30 h-full'}>
        <Head>
          <title>{children.type.title || 'Howl'}</title>
        </Head>

        {/* {flags && flags['zendesk'] && <HelpWidget />} */}
        {flags && flags['zendesk'] && helpWidgetVisible ? (
          <HelpWidget name={displayName} />
        ) : null}

        <FeatureFlagWidget />
        <div className={classNames('flex flex-col bg-white pb-safe h-full')}>
          {showPortalMobileNavigationHeader ? (
            <PortalMobileNavigationHeader />
          ) : null}
          <BrickBanner />

          {showPortalNavigationHeader ? <PortalNavigationHeader /> : null}

          {/*<BrickBanner />*/}

          {emailVerified && goal && (
            <>
              {['NEW_CREATOR_BONUS', 'HOWL_HOT_100'].includes(
                goal.goal_type,
              ) ? (
                <>
                  {router.pathname.includes('/dashboard') && (
                    <BonusBar goal={goal} />
                  )}
                </>
              ) : (
                <BonusBar goal={goal} />
              )}
            </>
          )}

          <div className={'flex flex-row flex-1 relative w-full h-full'}>
            {showPortalMobileNavigationSideBar ? (
              <PortalMobileNavigationSideBar />
            ) : null}
            <div
              className={classNames(
                'flex-1 flex flex-col items-end shadow-3xl shadow-inner transition-all w-full h-full',
              )}
            >
              <div
                id="page-container"
                className={classNames(
                  'relative bg-neutral-gray-10 sm:pb-0 w-full transition-all h-full md:w-full delay-100',
                )}
              >
                <VerifyEmailBanner />
                {!isLoading ? children : null}
                <Flag name="earningsFooter">
                  <div
                    data-cy="earnings-footer"
                    className={'fixed bottom-0 w-full bg-white z-10'}
                  >
                    <EarningsFooter />
                  </div>
                </Flag>
              </div>
            </div>

            {snackbar && (
              <Snackbar
                {...snackbar}
                key={snackbarIter}
                onClose={() => clearSnackbar()}
              />
            )}

            <Toaster />
          </div>
        </div>
      </div>
    );
  },
);
