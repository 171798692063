import {
  STATUS_DROPDOWN_ITEMS,
  STAT_TYPE,
  COLUMN_PRESETS,
  DEFAULT_COLUMNS,
  DEFAULT_STATUS_DROPDOWN_ITEMS,
  ColumnPreset,
} from './constants';

export type DateRange = {
  startDate: moment.Moment;
  endDate: moment.Moment;
};

export type DropdownOption = {
  label: string;
  value: string | number;
};

type ecommCampaignCenterIO = {
  tableData: any;
  totalsData: any;
  loading: boolean;
  searchQuery: string;
  raisedRow: number | null;
  page: number;
  perPage: number;
  dateRange?: DateRange;
  columnModalOpen: boolean;
  selectedColumns: DropdownOption[];
  statusFilter: DropdownOption[];
  selectedPubs: DropdownOption[];
  selectedBudgetType: DropdownOption[];
  columnPresets: ColumnPreset[];
  selectedColumnPreset: ColumnPreset;
  statType: string;
  statusDropdownItems: DropdownOption[];
  orderBy: string;
  direction: string;
};

export const state: ecommCampaignCenterIO = {
  tableData: null,
  totalsData: null,
  loading: true,
  searchQuery: '',
  raisedRow: null,
  page: 1,
  perPage: 20,
  dateRange: undefined,
  columnModalOpen: false,
  statusFilter: DEFAULT_STATUS_DROPDOWN_ITEMS,
  selectedPubs: [],
  selectedBudgetType: [],
  selectedColumns: DEFAULT_COLUMNS,
  columnPresets: COLUMN_PRESETS,
  selectedColumnPreset: COLUMN_PRESETS[0],
  statType: STAT_TYPE,
  statusDropdownItems: STATUS_DROPDOWN_ITEMS,
  orderBy: 'attributed_revenue',
  direction: 'desc',
};
