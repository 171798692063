import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { formatNumber } from '../../common/utils';

const InstantPayPill = ({
  isShowing,
  redirectToTab,
  instantPayAmount,
}: any) => {
  const ipLabel = <div className="hidden sm:inline">Instant Pay:</div>;
  const pill = (
    <div className="inline-block" data-ref="instant-pay-btn-nav">
      <div
        className="border border-deep-space-500 px-2 rounded-[50px] h-[30px] flex justify-center items-center p-1 bg-gradient-to-b
          from-violet-300 to-violet-500 text-base cursor-pointer sm:mr-2 mr-1"
        onClick={() => redirectToTab()}
      >
        <img
          width="8px"
          height="10px"
          src={'/images/icons/icon-lightning.svg'}
          alt={''}
          className="text-center mx-1"
        />
        <div className="inline text-white text-sm font-normal">
          {ipLabel}
          <div className="inline px-1">
            {`$${formatNumber(instantPayAmount, 'currency')}`}
          </div>
        </div>
      </div>
    </div>
  );

  const instantPayPill = isShowing ? pill : null;
  return <>{instantPayPill}</>;
};

export default withLDConsumer()(InstantPayPill);
