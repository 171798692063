import React from 'react';
import { UserIcon } from '@heroicons/react/outline';
import { useActions } from '@frontend/howl-web-app/overmind';

const SignOut = () => {
  const { logout } = useActions().auth;
  return (
    <div
      onClick={() => {
        logout(true);
      }}
      data-cy="signout"
      className="w-full mt-4 mb-3.5 inline-block text-[#C4BEFA] transition hover:bg-[#373737] cursor-pointer"
    >
      <button className="appearance-none border-none p-0 m-0 w-full">
        <div className="flex items-center h-8 px-6 space-x-2">
          <div className="float-right">
            <UserIcon className="h-4 w-4" />
          </div>
          <span>Sign Out</span>
        </div>
      </button>
    </div>
  );
};

export default SignOut;
