import React, { useEffect, useState } from 'react';
import { FlagListItem } from './flag-list-item';
import { optimizelyClient } from '@frontend/shared-feature-flags';
import { Button } from '../button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Input } from '../input';

const useOverridesQuery = () =>
  useQuery({
    queryKey: ['feature-flag-overrides', 'all'],
    queryFn: () => {
      const items = localStorage.getItem('feature-flag-overrides');
      const overrides = items ? JSON.parse(items) : {};

      return overrides || null;
    },
  });

export const FlagList = () => {
  const [flagKeys, setFlagKeys] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const { data: overridesData } = useOverridesQuery();

  const queryClient = useQueryClient();

  const resetFlagOverrides = useMutation({
    mutationFn: () => {
      localStorage.removeItem('feature-flag-overrides');

      return Promise.resolve(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['feature-flag-overrides'],
      });
    },
  });

  const optimizelyConfig = optimizelyClient.getOptimizelyConfig();

  useEffect(() => {
    if (optimizelyConfig?.featuresMap) {
      setFlagKeys(Object.keys(optimizelyConfig?.featuresMap));
    }
  }, [optimizelyConfig?.featuresMap]);

  return (
    <div className="h-full px-3 pb-4 overflow-y-auto bg-[#0d1117] h-[500px] w-[350px] flex flex-col">
      <div>
        <div className="flex justify-between items-center mb-2 min-h-[45px] border-b border-gray-400">
          <a
            className="text-white flex items-center"
            href={
              'https://app.optimizely.com/v2/projects/23709790223/flags/list'
            }
            target={'_blank'}
            rel="noreferrer"
          >
            Feature Flags
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
          {overridesData && Object.keys(overridesData).length > 0 && (
            <Button
              variant={'solid'}
              color={'red'}
              size={'sm'}
              onClick={() => resetFlagOverrides.mutate()}
            >
              Reset all {Object.keys(overridesData).length} overrides
            </Button>
          )}
        </div>
      </div>
      <div className={'overflow-auto flex-1'}>
        {flagKeys
          .filter((item) => {
            return (
              (!searchString ||
                item.toLowerCase().includes(searchString.toLowerCase())) &&
              // so we don't accidentally lock ourselves out of the widget
              item !== 'feature_flag_widget'
            );
          })
          .map((item) => (
            <FlagListItem
              isOverridden={overridesData[item]}
              key={item}
              flagKey={item}
            />
          ))}
      </div>
      <div className="flex justify-between items-center">
        <Input
          type="text"
          className="bg-opacity-30 bg-white !text-[#dddddd] placeholder-text-[#777777] !border-[#000000]"
          placeholder="Find a flag..."
          onChange={setSearchString}
          debounce
          debounceDuration={500}
          clearable
        />
        <div className={'text-gray-400 text-sm mx-1.5'}>
          v{optimizelyConfig?.revision}
        </div>
      </div>
    </div>
  );
};
