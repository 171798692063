export const createApi = () => {
  let client: any;
  // let options: any;

  return {
    initialize() {
      // options = clientOptions;
    },

    getClient() {
      return client;
    },

    setClient(newClient: any) {
      client = newClient;
    },
  };
};

export const api = createApi();
