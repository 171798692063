import { createClient } from '@frontend/shared-utils';

export const createApi = () => {
  let client: any;

  return {
    initialize({ clientOptions }: { clientOptions: any }) {
      client = createClient(clientOptions);
    },

    getClient() {
      return client;
    },

    setClient(newClient: any) {
      client = newClient;
    },

    getNewAccessToken() {
      // TODO: fill this in
      return Promise.resolve(null);
    },

    // ************ old stuff **************
    logout() {
      return this.post('/api/v0/logout/');
    },

    /* HTTP aliases */
    get(path: string, data?: any, options?: any) {
      let payload: any = {};

      if (data) payload.params = data;
      if (options) payload = Object.assign(payload, options);
      // console.log('trying: ', path, payload);
      return client.httpClient.get(path, payload);
    },

    post(path: string, data?: any) {
      return client.httpClient.post(path, data, {
        headers: {
          'Content-Type':
            data instanceof FormData
              ? 'multipart/form-data;'
              : 'application/json;charset=UTF-8',
        },
      });
    },

    put(path: string, data?: any, options?: any) {
      return client.httpClient.put(path, data, options);
    },

    delete(path: string, data?: any) {
      return client.httpClient.delete(path, { data });
    },

    async rawRequest(method: string, url: string, data?: any, options?: any) {
      const noAuthClient = createClient({ ...client, getJWT: () => null });

      return noAuthClient.httpClient.request({
        ...options,
        method: method,
        url: url,
        data: data,
      });
    },
  };
};

export const api = createApi();
