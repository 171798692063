import React, { FC } from 'react';
import { ListGroupItem } from './list-group-item';
import classNames from 'classnames';

export type ListGroupProps = {
  /** Additional classes that append to the ListGroup container element. */
  className?: string;

  /** React node of content to display in the list group.  */
  children?: React.ReactNode;
};

const ListGroupComponent: FC<ListGroupProps> & { filePath: string } = ({
  children,
  className,
}) => {
  return <ul className={classNames('flex flex-col', className)}>{children}</ul>;
};

ListGroupComponent.displayName = 'ListGroup';

/** The ListGroup component displays a list of items (such as links) for users to interact with. */
export const ListGroup = Object.assign(ListGroupComponent, {
  Item: ListGroupItem,
});

ListGroupComponent.filePath =
  'libs/shared/ui-kit/src/lib/list-group/list-group.tsx';
