import { Context } from '..';

export const getDefaultPaymentMethod = async (context: Context) => {
  try {
    const endpoint = `api/v0/billing/merchants/${context.state.organization.selectedOrgId}/default_payment_method`;

    context.state.ecommPaymentSetup.loadingDefaultPaymentMethod = true;
    const resp = await context.effects.api.get(endpoint);
    const data = resp.data.data[0];

    context.state.ecommPaymentSetup.defaultPaymentMethod =
      data.default_payment_method;

    context.state.ecommPaymentSetup.loadingDefaultPaymentMethod = false;
  } catch (error) {
    context.actions.ui.triggerSnackbar({
      content: 'Something went wrong. Please reload the page to try again.',
      color: 'systemError',
      duration: 20000,
    });
  }
};

export const getSetupIntentSecret = async (context: Context) => {
  try {
    context.state.ecommPaymentSetup.loadingSetupIntentSecret = true;

    const endpoint = `api/v0/billing/merchants/${context.state.organization.selectedOrgId}/setup_intent_secret`;

    const resp = await context.effects.api.get(endpoint);
    const data = resp.data.data[0];

    if (data.setup_intent_secret) {
      context.state.ecommPaymentSetup.setupIntentSecret =
        data.setup_intent_secret;
    }

    context.state.ecommPaymentSetup.loadingSetupIntentSecret = false;
  } catch (error) {
    context.actions.ui.triggerSnackbar({
      content: 'Something went wrong. Please reload the page to try again.',
      color: 'systemError',
      duration: 20000,
    });
  }
};

export const deletePaymentMethod = async (context: Context) => {
  const paymentId = context.state.ecommPaymentSetup.defaultPaymentMethod.id;
  const endpoint = `api/v0/billing/merchants/${context.state.organization.selectedOrgId}/payment_methods/${paymentId}/`;

  await context.effects.api.delete(endpoint);

  context.state.ecommPaymentSetup.defaultPaymentMethod = null;
};

export const setSetupIntentSecret = (context: Context, secret: string) => {
  context.state.ecommPaymentSetup.setupIntentSecret = secret;
};

export const getMerchant = async (context: Context) => {
  const endpoint = `api/v0/merchants/${context.state.organization.selectedOrgId}/`;

  const resp = await context.effects.api.get(endpoint);
  const data = resp.data.data[0];

  context.state.ecommPaymentSetup.merchant = data;
};

export const saveDefaultPaymentMethod = async (
  context: Context,
  paymentId: string,
) => {
  const endpoint = `api/v0/billing/merchants/${context.state.organization.selectedOrgId}/default_payment_method`;

  await context.effects.api.post(endpoint, {
    payment_id: paymentId,
  });
};

export const setLoadingDefaultPaymentMethod = async (
  context: Context,
  loading: boolean,
) => {
  context.state.ecommPaymentSetup.loadingDefaultPaymentMethod = loading;
};
