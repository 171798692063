import React, { FC, HTMLAttributeAnchorTarget } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

export type ListGroupItemProps = {
  /** Additional classes that append to the list item element. */
  className?: string;

  /** Additional classes that append to the list item container element. */
  containerClassName?: string;

  /** A relative path or url for navigating on click. */
  href?: string;

  /** When used with href, allows the link target to be specified. */
  target?: HTMLAttributeAnchorTarget;

  /** Click handler for when list item gets clicked. */
  onClick?: () => void;

  /** Flag to indicate that this list item is disabled. */
  disabled?: boolean;

  /** Adds content before main list item contents.  */
  leftContent?: React.ReactNode;

  /** Adds content after main list item contents.  */
  rightContent?: React.ReactNode;

  /** React node of content to display in the list item.  */
  children?: React.ReactNode;

  // icon?: FC<ComponentProps<'svg'>>;
};

/** The ListGroupItem component displays a single list item within a ListGroup. */
export const ListGroupItem: FC<ListGroupItemProps> & { filePath: string } = ({
  children,
  className,
  containerClassName,
  href,
  target,
  onClick,
  disabled,
  leftContent,
  rightContent,
}) => {
  const Component = typeof href === 'undefined' ? 'button' : 'a';
  const content = (
    <Component
      className={classNames(
        'flex text-left group items-center w-full',
        className,
      )}
      onClick={onClick}
      type="button"
      target={target}
      disabled={disabled}
    >
      {leftContent}
      <div className={'flex-1'}>{children}</div>
      {rightContent}
    </Component>
  );

  return (
    <li className={classNames('py-2 px-1', containerClassName)}>
      {typeof href === 'undefined' ? (
        content
      ) : (
        <Link href={href} passHref legacyBehavior>
          {content}
        </Link>
      )}
    </li>
  );
};

ListGroupItem.displayName = 'ListGroup.Item';
ListGroupItem.filePath =
  'libs/shared/ui-kit/src/lib/list-group/list-group-item.tsx';
