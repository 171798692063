import moment from 'moment';

type formatType =
  | 'string'
  | 'currency'
  | 'currencyRounded'
  | 'percent'
  | 'percentChange'
  | 'multiple'
  | 'fixed1'
  | 'roi'
  | 'number';
const roundedFieldsLessThanOne = ['publisher_total_earnings'];

const roundedFields = [
  'commission_rate',
  'commission_rate_new',
  'merchant_revenue',
  'merchant_spend',
  'product_price',
  'publisher_aov',
  'publisher_effective_cpc',
  'publisher_cpa_revenue',
  'publisher_cpc_revenue',
  'publisher_total_earnings',
  'publisher_earnings_usd',
  'attributed_revenue_usd',
  'total_attributed_revenue',
  'revenue',
  'spend',
  'roi_variance',
  'daily_budget_amount',
  'monthly_budget_amount',
  'percent_spend_remaining',
  'merchants_revenue',
  'pacing_towards',
  'revenue_percent_difference',
  'spend_percent_difference',
  'take_rate_percent_difference',
  'earnings_percent_difference',
  'earnings',
  'mtd_spend',
  'guardrail_upper_bound',
  'guardrail_lower_bound',
  'budget_amount',
  'budget_pacing',
  'daily_budget_usd',
  'monthly_budget_usd',
  'attributed_revenue',
  'revenue_current',
  'spend_current',
  'sale_percentage',
  'percent_budget_used',
  'bonus_spend',
  'flat_fee_budget',
  'attributed_revenue_projection',
  'merchant_spend_projection',
  'attributed_revenue_before',
  'merchant_spend_before',
  'clicks_before',
  'bonus_spend_before',
  'flat_fee_budget_before',
  'rpc_before',
  'flat_fees',
  'flat_fees_before',
];

const getStatsFromCache = (
  statCache: any,
  page: number,
  indexStart: number,
  indexEnd: number,
) => {
  if (!statCache.pages[page]) return [];

  return Promise.resolve(statCache.pages[page].slice(indexStart, indexEnd));
};

/* Formatting functions */
const formatNumberWithCommas = (number: any) => {
  let numString = `${number}`;
  if (number >= 1000 || number <= -1000) {
    const thousandRegex = /(-?\d+)(\d{3})/;
    while (thousandRegex.test(numString)) {
      numString = numString.replace(thousandRegex, '$1,$2');
    }
  }
  return numString;
};

const formatNumber = (number: any, type: formatType, columnName: any) => {
  let numString;
  number = parseFloat(number);

  /*
   * Depending on column type leading 0 is removed or
   * Keep leading 0 if number is less than 1 (0.67 => 0.67)
   * Round number if greater than 1 or equal to 0.
   */
  if (
    (number > 1 && roundedFields.includes(columnName)) ||
    roundedFieldsLessThanOne.includes(columnName)
  ) {
    number = Math.round(number);
  }

  /*
   * Values > 1 should have 2 decimal places of precision by default.
   * Rounded decimal fields should be 2 places of precision
   */
  if (
    ['currency', 'percent', 'roi'].includes(type) &&
    ((!roundedFields.includes(columnName) && number > 0) ||
      (roundedFields.includes(columnName) &&
        Math.abs(number) > 0 &&
        Math.abs(number) < 1) ||
      (roundedFields.includes(columnName) && number < 0))
  ) {
    numString = number.toFixed(2);
  } else if (['roi', 'roi_actual', 'roi_goal'].includes(columnName)) {
    numString = number.toFixed(1);
  } else if (roundedFields.includes(columnName) && number > 1) {
    numString = number.toFixed(0);
  } else {
    numString = (number || 0).toString();
  }

  if (columnName && columnName.includes('percent_difference')) {
    numString = number.toFixed(0);
  }

  numString = formatNumberWithCommas(numString);

  /*
   * Add trailing zero to currency values less than $1 ($0.4 => $0.40)
   * Values greater than 1 will have been rounded above.
   */
  if (['currency', 'roi'].includes(type) && numString.match(/0\.\d$/)) {
    numString += '0';
  }

  return numString;
};

const formatCurrencyThousands = (
  number: any,
  unit: any,
  prefix = '$',
  precision = 1,
  roundSmallNumbers = false,
) => {
  if (!number) return '0';

  // Remove commas, dollar sign, etc
  number = parseFloat(
    number
      .split('')
      .reduce(
        (acc: any, curr: any) => (/[\d.]/g.test(curr) ? acc + curr : acc),
        '',
      ),
  );
  if (number < 1000) {
    return `${prefix}${roundSmallNumbers ? Math.round(number) : number}`;
  }

  number = Math.round(number);
  const labels = ['', 'K', 'M', 'B'];
  let thousands = 0;

  while (number >= 1000 && thousands < labels.length - 1) {
    if (labels[thousands] === unit) {
      break;
    }
    number /= 1000;
    thousands++;
  }

  // Remove trailing 0's after decimal and the decimal itself, adds commas back in
  let numberString = number.toFixed(precision);
  while (
    numberString.includes('.') &&
    ['0', '.'].includes(numberString[numberString.length - 1])
  ) {
    numberString = numberString.substring(0, numberString.length - 1);
  }
  numberString = formatNumberWithCommas(numberString);

  return `${prefix}${numberString}${thousands ? labels[thousands] : ''}`;
};

const formatString = (value: any) => {
  const valueMap: any = {
    POST_CLICK: 'Post Click',
    LAST_CLICK: 'Last Click',
    MULTIPLE: 'Multiple',
    Unknown: '-',
  };

  return valueMap[value] || value;
};

const formatCellValue = (val: any, type?: any, options?: any) => {
  if (!val) return val;

  if (type === 'date') {
    return moment.utc(val).format(options.dateFormat);
  }

  let value: string | number = val;

  // Some strings passed in have a dollar sign already appended, remove that before formatting
  if (typeof value === 'string') {
    value = parseFloat(value.replace('$', ''));
  }

  if (options) {
    if (options.round) {
      value = Math.round(value);
    }

    if (
      typeof options.precision !== 'undefined' ||
      options.precision !== null
    ) {
      value = value.toFixed(options.precision);
    }
  }

  //	Opt out function
  if (!options || !options.noCommas) {
    value = formatNumberWithCommas(value);
  }

  switch (type) {
    case 'currency':
      value = typeof value === 'string' ? value : value.toString();

      // If negative value, format it as -$x.xx rather than $-x.xx
      return value[0] === '-' ? `${value[0]}$${value.slice(1)}` : `$${value}`;
    case 'percent':
      return `${value}%`;
    case 'multiple':
      return `${value}x`;
    default:
      return value;
  }
};

const formatValue = (
  value: any,
  type: formatType,
  columnName?: any,
  convertToDashes = false,
) => {
  const zeroAllowedColumns = [
    'daily_budget_amount',
    'monthly_budget_amount',
    'publisher_revenue',
  ];
  if (type === 'string') return formatString(value);

  // Return '-' for falsy numerical values like 0 or NaN
  if (
    convertToDashes &&
    !parseFloat(value) &&
    !zeroAllowedColumns.includes(columnName)
  )
    return '-';

  value = formatNumber(value, type, columnName); // Returns number as string
  switch (type) {
    case 'currency':
      // If negative value, format it as -$x.xx rather than $-x.xx
      return value[0] === '-' ? `${value[0]}$${value.slice(1)}` : `$${value}`;
    case 'currencyRounded':
      // If negative value, format it as -$x.xx rather than $-x.xx
      return value[0] === '-'
        ? `${value[0]}$${value.slice(1).split('.')[0]}`
        : `$${value.split('.')[0]}`;
    case 'percent':
      return `${value}%`;
    case 'percentChange':
      return `${value > 0 ? '+' : ''}${value}%`;
    case 'multiple':
      return `${value}x`;
    case 'fixed1':
      return parseFloat(value).toFixed(1);
    default:
      return value;
  }
};

const titleCase = (name: string) => {
  if (!name) {
    return '';
  }
  return name.replace(/(?:^|([ \t([]))([a-z])/g, (match, value1, value2) => {
    return `${value1 || ''}${value2.toUpperCase()}`;
  });
};

// Converts TitleCase to snake_case
const snakeCase = (name: string) => {
  return name.replace(/([^_A-Z])([A-Z])/g, '$1_$2').toLowerCase();
};

const orderParams = (params: any, orderedKeys: any) => {
  // Order params so they're consistent & easier to mock in tests
  const orderedParams: any = {};

  orderedKeys.forEach((key: any) => {
    if (params[key]) {
      orderedParams[key] = params[key];
    }
  });

  return orderedParams;
};

export {
  formatNumberWithCommas,
  formatNumber,
  formatCurrencyThousands,
  formatString,
  formatCellValue,
  formatValue,
  getStatsFromCache,
  orderParams,
  titleCase,
  snakeCase,
};
