import React from 'react';

export function requiresPublicAuth<T>(
  WrappedComponent: React.FunctionComponent<T>,
): React.FunctionComponent<T> {
  const WithAuth = (props: T) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <WrappedComponent {...props} />;
  };

  WithAuth.displayName = `requiresPublicAuth()`;

  return WithAuth;
}
