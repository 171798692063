import { Context } from '..';
import {
  calculatePagination,
  formatValue,
  getStatsFromCache,
  invalidPage,
  orderParams,
  titleCase,
} from '@frontend/shared-utils';

export const setCartDataByMerch = (context: Context, cartData: any) => {
  context.state.product.cartDataByMerch = cartData;
};

export const getProductTrends = (
  context: Context,
  {
    pubId,
    merchIds,
    params,
    pageNum,
    perPage,
    dateRange,
    resetPages = false,
  }: {
    pubId: any;
    merchIds: any;
    params: any;
    pageNum: any;
    perPage: any;
    dateRange: any;
    resetPages: any;
  },
) => {
  if (!context.state.product.productsByMerch[merchIds]) {
    context.state.product.productsByMerch[merchIds] = {
      '30d': {
        pages: {},
        last_search_term: null,
      },
      '24h': {
        pages: {},
        last_search_term: null,
      },
    };
  }

  if (resetPages) {
    context.state.product.productsByMerch[merchIds][dateRange].pages = {};
  }

  const stats = context.state.product.productsByMerch[merchIds][dateRange];
  const searchTerm = params.search_term;
  const totalProducts = stats ? stats.total_items : 0;
  [
    context.state.product.page,
    context.state.product.indexStart,
    context.state.product.indexEnd,
  ] = calculatePagination(pageNum, perPage, context.state.product.limit);

  if (
    invalidPage(
      context.state.product.page,
      totalProducts,
      context.state.product.limit,
    )
  ) {
    return Promise.resolve('The page requested does not exist');
  }

  params.page = context.state.product.page;
  params.per_page = context.state.product.limit;
  params.merch_ids = merchIds;
  params.date_range = dateRange;

  if (
    stats.pages[context.state.product.page] &&
    searchTerm === stats.last_search_term
  ) {
    return getStatsFromCache(
      stats,
      context.state.product.page,
      context.state.product.indexStart,
      context.state.product.indexEnd,
    );
  }

  return context.actions.product.queryProductTrends({
    pubId,
    params,
    dateRange,
  });
};

export const queryProductTrends = (
  context: Context,
  { pubId, params, dateRange }: { pubId: any; params: any; dateRange: any },
) => {
  if (params.search_term === '') params.search_term = null;

  const paramKeys = [
    'date_from',
    'date_to',
    'page',
    'per_page',
    'merch_ids',
    'search_term',
    'order_by',
    'direction',
    'product_type',
    'date_range',
  ];
  params = orderParams(params, paramKeys);

  const today = context.actions.date.getCurrentDateTime();
  const dailyParams = {
    ...params,
    date_from: context.actions.date.formatDate({
      dateObj: context.actions.date.offsetFromDate({
        originalDate: today,
        number: -1,
        unit: 'day',
      }),
    }),
    date_to: context.actions.date.formatDate({ dateObj: today }),
  };

  const endpoint = `/api/v0/publishers/${pubId}/products/`;
  const queryParams = dateRange === '30d' ? params : dailyParams;

  return context.effects.api.get(endpoint, queryParams).then(
    (resp: any) =>
      context.actions.product.onProductsLoaded({ resp, params, dateRange }),
    // TODO: add error modal () => onError(),
  );
};

export const onProductsLoaded = (
  context: Context,
  { resp, params, dateRange }: { resp: any; params: any; dateRange: any },
) => {
  const merchIds = params.merch_ids;

  const data = resp.data.data.length ? resp.data.data[0] : [];
  context.state.product.productsByMerch[merchIds][dateRange].pages[
    context.state.product.page
  ] = data.stats;

  Object.assign(context.state.product.productsByMerch[merchIds][dateRange], {
    total_items: data.page_info.total_items,
    stats_summary: data.page_info.stats_summary,
    last_search_term: params.search_term || null,
  });

  return getStatsFromCache(
    context.state.product.productsByMerch[merchIds][dateRange],
    context.state.product.page,
    context.state.product.indexStart,
    context.state.product.indexEnd,
  );
};

export const queryCartData = (
  context: Context,
  { pubId, params }: { pubId: any; params: any },
) => {
  const merchId = params.merch_id;
  const productName = params.product_name;
  const currentDateRange = params.date_range;

  if (!context.state.product.cartDataByMerch[merchId]) {
    context.state.product.cartDataByMerch[merchId] = {};
  }

  if (!context.state.product.cartDataByMerch[merchId][productName]) {
    context.state.product.cartDataByMerch[merchId][productName] = {};
  }

  if (
    context.state.product.cartDataByMerch[merchId][productName][
      currentDateRange
    ]
  ) {
    return Promise.resolve(
      context.state.product.cartDataByMerch[merchId][productName][
        currentDateRange
      ],
    );
  }

  const endpoint = `/api/v0/publishers/${pubId}/cart_siblings/`;
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    const data = resp.data.data.length ? resp.data.data[0] : [];
    context.state.product.cartDataByMerch[merchId][productName][
      currentDateRange
    ] = data;
    return Promise.resolve(
      context.state.product.cartDataByMerch[merchId][productName][
        currentDateRange
      ],
    );
  });
};

// Totals for all merchants
export const queryCartSummary = (
  context: Context,
  { pubId, params }: { pubId: any; params: any },
) => {
  const endpoint = `/api/v0/publishers/${pubId}/bestsellers/totals/`;
  return context.effects.api.get(endpoint, params).then((resp: any) => {
    const data = resp.data.data.length ? resp.data.data[0] : {};
    return Promise.resolve(data);
  });
};

/* Formatting functions */
export const displayPrice = (
  context: Context,
  { product, type }: { product: any; type?: any },
) => {
  const price =
    type === 'sale'
      ? product.sale_price
      : product.feed_price_usd || product.unit_price_usd;
  return formatValue(price, 'currency');
};

export const getProductLabel = (context: Context, product: any) => {
  let brand = product.product_brand;
  let name = product.product_name;

  // Title case all brand and product names
  if (brand === 'NULL' || !brand) {
    brand = null;
  } else {
    brand = titleCase(brand);
  }
  name = name ? titleCase(name) : '';

  return brand ? `${brand} | ${name}` : name;
};
