'use client';

import { useRef, useEffect } from 'react';
import pluralize from 'pluralize';

/* Utils used by multiple roles */
const sortAlphabetical = (arr: any, key: any) => {
  return arr.sort((a: any, b: any) => a[key].localeCompare(b[key]));
};

const asyncFlush = () => new Promise((resolve) => setTimeout(resolve, 0));

const deepUpdate = async (wrapper: any) => {
  await asyncFlush();
  await wrapper.update();
};

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const buildQueryFilter = (filters: any) => {
  const filterString = filters
    .map((filter: any) => {
      const { field, operation, value } = filter;
      if (!value) return null;

      return `${encodeURI(field)}|${encodeURI(operation)}|${encodeURI(value)}`;
    })
    .filter((filter: any) => !!filter)
    .join(';');
  return filterString || null;
};

const buildCountDescription = (
  count: any,
  entityName: any,
  dropdownFields: any,
) => {
  if (typeof count === 'undefined' || count === null) {
    return null;
  }

  const pluralizeModifier = pluralize(entityName, count);

  let filteredFields = '';
  if (dropdownFields.length) {
    const andedFields = joinArrayWithAnd(
      dropdownFields.map((field: any) => field.label),
    );
    filteredFields = `(${andedFields})`;
  }

  return `Showing ${count.toString()} ${pluralizeModifier} ${filteredFields}`;
};

// Niche util function that joins array values into a string while prepending the final value with 'And'
const joinArrayWithAnd = (inputArray: any) => {
  if (!inputArray.length) return '';

  if (inputArray.length === 1) {
    return inputArray[0];
  }

  const finalValue = inputArray.pop();
  return `${inputArray.join(', ')} and ${finalValue}`;
};

export {
  asyncFlush,
  sortAlphabetical,
  deepUpdate,
  usePrevious,
  buildQueryFilter,
  buildCountDescription,
  joinArrayWithAnd,
};
