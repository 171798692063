import { Transition } from '@headlessui/react';
import React from 'react';
import classNames from 'classnames';

export function SignUpBackground({ activeStep }: { activeStep: number }) {
  return (
    <>
      <Transition
        appear={true}
        show={activeStep === 1}
        enter="transition-opacity duration-[1500ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-[1500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={classNames(
          'fixed w-full h-full top-0 bg-no-repeat left-0 sm:bg-cover bg-[length:150%] xl:bg-cover bg-right-bottom sm:bg-bottom xl:bg-repeat-x xl:bg-no-repeat-y',
          'bg-[url(/images/signup/planetscape_signIn_1.jpg)]',
        )}
        unmount={false}
      ></Transition>
      <Transition
        className={classNames(
          'fixed w-full h-full top-0 bg-no-repeat left-0 sm:bg-cover bg-[length:150%] xl:bg-cover bg-right-bottom sm:bg-bottom xl:bg-repeat-x xl:bg-no-repeat-y',
          'bg-[url(/images/signup/planetscape_signIn_2.jpg)]',
        )}
        show={activeStep === 2}
        enter="transition-opacity duration-[1500ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-[1500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount={false}
      ></Transition>
      <Transition
        className={classNames(
          'fixed w-full h-full top-0 bg-no-repeat left-0 sm:bg-cover bg-[length:150%] xl:bg-cover bg-right-bottom sm:bg-bottom xl:bg-repeat-x xl:bg-no-repeat-y',
          'bg-[url(/images/signup/planetscape_signIn_3.jpg)]',
        )}
        show={activeStep === 3}
        enter="transition-opacity duration-[1500ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-[1500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount={false}
      ></Transition>
      <Transition
        className={classNames(
          'fixed w-full h-full top-0 bg-no-repeat left-0 sm:bg-cover bg-[length:150%] xl:bg-cover bg-right-bottom sm:bg-bottom xl:bg-repeat-x xl:bg-no-repeat-y',
          'bg-[url(/images/signup/planetscape_signIn_4.jpg)]',
        )}
        show={activeStep === 4}
        enter="transition-opacity duration-[1500ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-[1500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      ></Transition>
      <Transition
        className={classNames(
          'fixed w-full h-full top-0 bg-no-repeat left-0 sm:bg-cover bg-[length:150%] xl:bg-cover bg-right-bottom sm:bg-bottom xl:bg-repeat-x xl:bg-no-repeat-y',
          'bg-[url(/images/signup/planetscape_signIn_4.jpg)]',
        )}
        show={activeStep === 5}
        enter="transition-opacity duration-[1500ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-[1500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount={false}
      ></Transition>
      <Transition
        className={classNames(
          'fixed w-full h-full top-0 bg-no-repeat left-0 sm:bg-cover bg-[length:150%] xl:bg-cover bg-right-bottom sm:bg-bottom xl:bg-repeat-x xl:bg-no-repeat-y',
          'bg-[url(/images/signup/planetscape_signIn_5.jpg)]',
        )}
        show={activeStep === 6}
        enter="transition-opacity duration-[1500ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-[1500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount={false}
      ></Transition>
    </>
  );
}
