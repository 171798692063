import { Context } from '..';

export const getRecommendedCampaigns = (
  context: Context,
  { pubId }: { pubId: any },
) => {
  const endpoint = `/api/v0/recommendations/opt_in_recommendations/${pubId}/`;
  return context.effects.api.get(endpoint).then((resp: any) => {
    const data = resp.data.data.length ? resp.data.data[0] : {};
    return Promise.resolve(data);
  });
};
