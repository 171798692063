import { formatNumberWithCommas } from '@frontend/shared-utils';
import { RoleNames } from '../account/state';
import { AppliedStatus, CampaignCTA } from './state';

export const flatFeeText = `This Flat Fee amount is for the current month of the campaign.`;

export const getFlatFeeRange = (campaign: any) => {
  return campaign.flat_fee_total_range_min != campaign.flat_fee_total_range_max
    ? `$${format(campaign.flat_fee_total_range_min)} - $${format(
        campaign.flat_fee_total_range_max,
      )}`
    : campaign.flat_fee_total_range_min
    ? `$${format(campaign.flat_fee_total_range_min)}`
    : '-';
};

const format = (value: any) => {
  const noDecimal = Number(value).toFixed(0);
  return formatNumberWithCommas(noDecimal);
};

export const isFlatFeeCampaign = (campaign: any) => {
  return [1, 2].includes(getFlatFeeVersion(campaign) as any);
};

export const getFlatFeeVersion = (campaign: any) => {
  return campaign?.strategy_type === 10
    ? 2
    : campaign?.flat_fee_total_range_max
    ? 1
    : null;
};

export const getCampaignHref = (
  campaign: any,
  publisherId: number,
  flags: any,
) => {
  const tab =
    isFlatFeeCampaign(campaign) && flags?.flatFee3 ? 'flat-fees' : 'campaigns';
  return `/publisher/${publisherId}/campaign-center/${tab}/${campaign.strategy_id}`;
};

export const getCampaignCTA = ({
  campaign,
  flags,
  role,
}: {
  campaign: any;
  flags: any;
  role: any;
}) => {
  const canSeeEarnings = [
    RoleNames.ACCOUNT_OWNER,
    RoleNames.BILLING_ADMIN,
    RoleNames.TEAM_ADMIN,
  ].includes(role as any);

  return (isFlatFeeCampaign(campaign) as any)
    ? flags['flatFee3']
      ? campaign.applied_status !== AppliedStatus.NotSelected &&
        campaign.applied_status !== AppliedStatus.Confirmed
        ? canSeeEarnings
          ? CampaignCTA.AppliedButton
          : CampaignCTA.ContactLink
        : null
      : null
    : canSeeEarnings
    ? CampaignCTA.ReservedButton
    : null;
};
