export type GaParams = {
  timestamp?: string;
  is_admin?: boolean;
  org_id?: any;
  publisher_type?: string;
  user_role?: string;
  user_id?: any;
};

type State = {
  ga: any;
  dimensionMap: any;
  propertyID: string;
};

export const state: State = {
  ga: null,
  dimensionMap: {
    timestamp: 'dimension1',
    query: 'dimension2',
    bam_link_id: 'dimension3',
    is_admin: 'dimension5',
    group_field: 'dimension6',
    org_id: 'dimension7',
    user_id: 'dimension4',
    publisher_type: 'dimension10',
  },
  propertyID: '64241223-1',
};
