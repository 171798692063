import React, { useEffect, useState } from 'react';

export const ImageWithFallback = (props: any): JSX.Element => {
  const { src, alt, fallBack, ...rest } = props;
  const [imgSrc, setImgSrc] = useState<any>(src);
  const fallBackSrc = fallBack || '/images/homepage_placeholder.svg';

  useEffect(() => {
    setImgSrc(src);
    return () => {
      setImgSrc(null);
    };
  }, [src]);

  return (
    <img
      {...rest}
      alt={alt}
      src={imgSrc ? imgSrc : fallBackSrc}
      onError={() => {
        setImgSrc(fallBackSrc);
      }}
    />
  );
};
