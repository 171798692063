import { OnboardStateResponse } from './state';
import { platformFormat } from './effects';
import { Context } from '../index';
import { objectify } from 'radash';
import {
  parsePhylloProfile,
  supportedPhylloPlatforms,
} from '@frontend/shared-utils';

// const CHANNEL_TYPES = {
//   Youtube: 1,
//   Instagram: 2,
//   Twitch: 3,
//   Tiktok: 4,
//   Substack: 5,
//   Other: 6,
//   Twitter: 7,
//   Facebook: 8,
//   Site: 9,
//   Newsletter: 10,
// };

export const setOnboardingDebugMode = (
  { state }: Context,
  debugMode: boolean,
) => {
  state.creatorOnboarding.debugMode = debugMode;
};

export const setRejectedDebugMode = (
  { state }: Context,
  debugMode: boolean,
) => {
  state.creatorOnboarding.debugRejectedFlow = debugMode;
};

export const setLoading = (
  { state }: Context,
  page: null | 'basic' | 'channels' | 'guilds' | 'brands',
) => {
  state.creatorOnboarding.isLoading = page;
};

export const setSubmitting = (
  { state }: Context,
  page: null | 'basic' | 'channels' | 'guilds' | 'brands',
) => {
  state.creatorOnboarding.isSubmitting = page;
};

export const prefillForm = ({ state }: Context) => {
  const { data } = state.creatorOnboarding;

  if (!data) return;

  state.creatorOnboarding.formState = {
    firstName: data.first_name,
    lastName: data.last_name,
    creatorName: data.creator_name,
    email: data.email,
  };

  state.creatorOnboarding.selectedGuilds = objectify(
    data.guild_selections,
    (g) => g.category_id,
    () => true,
  );
};

export const addContinueCount = ({ state }: Context) => {
  state.creatorOnboarding.progressTracker.step += 1;
};

export const removeContinueCount = ({ state }: Context) => {
  state.creatorOnboarding.progressTracker.step -= 1;
};

export const _setProgressTracker = (
  { state }: Context,
  data: OnboardStateResponse,
) => {
  const totalSteps = ['info', 'channels'];
  if (!data.guild_selections.length) {
    totalSteps.push('guilds');
  }
  if (!data.brand_selections.length) {
    totalSteps.push('brands');
  }
  state.creatorOnboarding.progressTracker.pageOrder = totalSteps;
  state.creatorOnboarding.progressTracker.step = 1;
};

export const getOnboardingData = async (
  { state, effects, actions }: Context,
  referralData: { referralCode: number; referralType: string } | null,
) => {
  const { sessionData } = state.session;

  const endpoint = `/api/v0/publishers/${sessionData.orgId}/onboarding_selections/`;

  try {
    const payload: any = {
      referral_code: null,
      referral_type: null,
    };
    if (referralData) {
      payload.referral_code = referralData.referralCode;
      payload.referral_type = referralData.referralType;
    }
    const response = await effects.api.post(endpoint, payload);

    const data = response.data.data[0];

    state.creatorOnboarding.creatorChannels = data.social_channels;

    if (data && data.email) {
      if (!data.email.trim().endsWith('@pages.plusgoogle.com')) {
        state.creatorOnboarding.hasEmail = true;
      }
    }

    actions.creatorOnboarding._setProgressTracker(data);
    return { data };
  } catch (e: any) {
    throw new Error(e);
  }
};

export const setOnboardingInProgress = ({ state, effects }: Context) => {
  if (state.creatorOnboarding.debugMode) {
    return new Promise((resolve) => {
      return resolve('Debug Onboarding Complete');
    });
  }
  return effects.api.put(
    `/api/v0/publishers/${state.organization.selectedOrgId}/onboard/status/`,
    {
      status: 'in_progress',
    },
  );
};

export const setOnboardingComplete = ({ state, effects }: Context) => {
  if (state.creatorOnboarding.debugMode) {
    return new Promise((resolve) => {
      return resolve('Debug Onboarding Complete');
    });
  }
  return effects.api.put(
    `/api/v0/publishers/${state.organization.selectedOrgId}/onboard/status/`,
    {
      status: 'accepted',
    },
  );
};

export const setOnboardingRejected = ({ state, effects }: Context) => {
  if (state.creatorOnboarding.debugRejectedFlow) {
    return new Promise((resolve) => {
      return resolve('Debug Onboarding Rejected');
    });
  }

  return effects.api.put(
    `/api/v0/publishers/${state.organization.selectedOrgId}/onboard/status/`,
    {
      status: 'rejected',
    },
  );
};

export const setOnboardingStatus = (
  { state }: Context,
  value: number | null = null,
) => {
  if (value) state.creatorOnboarding.onboardingStatus = value;
  else {
    state.creatorOnboarding.onboardingStatus =
      state.organization.selectedOrg.onboarding_status;
  }
};

export const setOnboardingData = (
  { state }: Context,
  { data }: { data: OnboardStateResponse },
) => {
  state.creatorOnboarding.data = data;
};

export const getGuildCategories = async ({ effects }: Context) => {
  const endpoint = `/api/v0/categories/`;
  try {
    const response = await effects.api.get(endpoint);
    return response.data.data[0].categories;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const setGuildOptions = (
  { state }: Context,
  options: { category_id: number; name: string }[],
) => {
  state.creatorOnboarding.guildOptions = options;
};

export const onGuildSelect = ({ state }: Context, guildId: number) => {
  if (state.creatorOnboarding.selectedGuilds[guildId]) {
    state.creatorOnboarding.selectedGuilds[guildId] = false;
  } else {
    state.creatorOnboarding.selectedGuilds[guildId] = true;
  }
};

export const getGuildBrands = async ({
  state,
  effects,
}: Context): Promise<
  { merch_id: number; full_name: string; logo_url: string }[]
> => {
  const { sessionData } = state.session;

  const endpoint = `/api/v0/publishers/${sessionData.orgId}/guild_brands/`;
  try {
    const response = await effects.api.get(endpoint, { sort_by: 'gmv' });
    return response.data.data[0].brands;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const onBrandSelect = (
  { state }: Context,
  { brandId, name }: { brandId: number; name: string },
) => {
  if (state.creatorOnboarding.selectedBrands[brandId]) {
    state.creatorOnboarding.selectedBrands[brandId] = null;
  } else {
    state.creatorOnboarding.selectedBrands[brandId] = {
      name: name,
      merch_id: brandId,
    };
  }
};

export const saveUserInfo = async (
  { state, effects }: Context,
  formValues: {
    firstName: string;
    lastName: string;
    creatorName: string;
    email: string;
  },
) => {
  const { sessionData } = state.session;

  const endpoint = `/api/v0/publishers/${sessionData.orgId}/onboard/`;
  const payload = {
    first_name: formValues.firstName,
    last_name: formValues.lastName,
    creator_name: formValues.creatorName,
    email: formValues.email,
  };
  if (state.creatorOnboarding.debugMode) {
    return new Promise((resolve) => {
      state.creatorOnboarding.formState = formValues;
      return resolve('Debug Onboarding User Info');
    });
  }

  try {
    const response = await effects.api.post(endpoint, payload);
    state.creatorOnboarding.formState = formValues;
    return response;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveGuildSelections = ({ state, effects }: Context) => {
  if (state.creatorOnboarding.debugMode) {
    return new Promise((resolve) => {
      return resolve('Debug Onboarding User Info');
    });
  }
  const { sessionData } = state.session;
  const { selectedGuilds } = state.creatorOnboarding;
  const endpoint = `/api/v0/categories/${sessionData.orgId}/update_publisher_onboarding_mappings/`;

  const payload = {
    category_ids: Object.entries(selectedGuilds)
      .filter(([, enabled]) => enabled)
      .map((g) => g[0]),
  };

  try {
    return effects.api.put(endpoint, payload);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveBrandSelections = ({ state, effects }: Context) => {
  if (state.creatorOnboarding.debugMode) {
    return new Promise((resolve) => {
      return resolve('Debug Onboarding User Info');
    });
  }
  const { sessionData } = state.session;

  const endpoint = `/api/v0/creator_profile/${sessionData.orgId}/brands/`;

  const create_brands = [] as { name: string; merch_id: number }[];

  Object.entries(state.creatorOnboarding.selectedBrands).forEach(
    ([, brand]) => {
      if (brand) {
        create_brands.push(brand);
      }
    },
  );

  const payload = {
    create_brands,
  };

  try {
    return effects.api.post(endpoint, payload);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const _checkUserForRejection = (
  { state }: Context,
  bypassRejection: boolean,
) => {
  if (bypassRejection) {
    return false;
  }
  const acquisitionType = state.creatorOnboarding.acquisitionType;
  const referralType = state.creatorOnboarding.referralType;
  let userIsRejected = true;

  if (acquisitionType === 'waitlist') {
    userIsRejected = false;
  }

  // If User is referred by brand, admin, or sales.
  // Users who are creator referrals can be rejected.
  if (referralType && [2, 4, 5].includes(referralType)) {
    userIsRejected = false;
  }

  // Referrals by creator or brands can be rejected
  // SSO can be rejected

  return userIsRejected;
};

export const buildCreatorChannelsPayload = async (
  { state, actions }: Context,
  {
    formValues,
    bypassRejection,
  }: { formValues: ChannelForm; bypassRejection: boolean },
) => {
  // const { creatorChannels } = state.creatorOnboarding;

  const payload = {
    primary_channel: {} as
      | { channel_url: string; channel_type: string }
      | undefined,
    channels: [] as { channel_url: string; channel_type: string }[],
  };

  const phylloRequests = [];
  const engagementRateThreshold = 0.001;
  let engagementRateAboveThreshold = false;
  let onlyTwitterPhyllo: any = null; //If the only phyllo-supported platform is Twitter (no engagement_rate), then we want to accept the creator
  let phylloApiFailed = false;
  let userIsRejected = false;
  const valuesNotSupportedByPhyllo = formValues.secondaryPlatforms.reduce(
    (acc, value) => {
      if (
        !acc &&
        value.secondaryPlatform &&
        !supportedPhylloPlatforms.includes(
          value.secondaryPlatform.value.toLocaleLowerCase(),
        )
      ) {
        return true;
      } else {
        return acc;
      }
    },
    false,
  );
  const primaryChannel = formValues.secondaryPlatforms[0];
  if (primaryChannel.secondaryPlatform) {
    payload.primary_channel = {
      channel_url: platformFormat(
        primaryChannel.secondaryPlatformUrl,
        primaryChannel.secondaryPlatform.value,
      ),
      channel_type: primaryChannel.secondaryPlatform.value,
    };

    const primaryChannelType =
      primaryChannel.secondaryPlatform.value.toLocaleLowerCase();
    if (supportedPhylloPlatforms.includes(primaryChannelType)) {
      onlyTwitterPhyllo = primaryChannelType === 'twitter' ? true : false;
    }
  }

  const isPrimaryFormValueTheSameAsSignUpValue =
    state.creatorOnboarding.creatorChannels.length &&
    state.creatorOnboarding.creatorChannels[0]?.phyllo_data &&
    primaryChannel.secondaryPlatformUrl ===
      state.creatorOnboarding.creatorChannels[0].channel_url;

  if (isPrimaryFormValueTheSameAsSignUpValue) {
    // Don't call Phyllo if channel from sign up has phyllo data already
    // Check if first value in form is the same channel from sign up. or if user changed it.{
    const primaryChannelFromSignup = state.creatorOnboarding.creatorChannels[0];
    if (
      primaryChannelFromSignup.phyllo_data &&
      primaryChannelFromSignup.phyllo_data.engagement_rate >
        engagementRateThreshold
    ) {
      engagementRateAboveThreshold = true;
    }
  }
  let primaryPhylloProfile: any;

  if (
    !isPrimaryFormValueTheSameAsSignUpValue &&
    primaryChannel.secondaryPlatform
  ) {
    phylloRequests.push(
      parsePhylloProfile(
        primaryChannel.secondaryPlatformUrl,
        primaryChannel.secondaryPlatform.value,
        payload.primary_channel?.channel_url,
        true,
      ),
    );
  }

  // We don't want to duplicate calls to Phyllo for secondary channels either
  const secondaryPlatformsWithPhyllo: string[] = [];
  const existingSecondaryChannels: any =
    state.creatorOnboarding.creatorChannels.length &&
    state.creatorOnboarding.creatorChannels.length > 1
      ? state.creatorOnboarding.creatorChannels.slice(1)
      : [];
  for (let i = 0; i < existingSecondaryChannels.length; i++) {
    const secondaryChannel = existingSecondaryChannels[i];
    if (secondaryChannel.phyllo_data) {
      secondaryPlatformsWithPhyllo.push(secondaryChannel.channel_url);
      // Check here for engagementRateThreshold too
      if (
        secondaryChannel.phyllo_data?.engagement_rate > engagementRateThreshold
      ) {
        engagementRateAboveThreshold = true;
      }
    }
  }

  //Loop through the secondary channels (start at index 1) and create a map of payloads
  const secondaryChannelMap = new Map();
  for (let i = 1; i < formValues.secondaryPlatforms.length; i++) {
    const channel = formValues.secondaryPlatforms[i];
    if (!channel.secondaryPlatform?.value || !channel.secondaryPlatformUrl) {
      continue;
    }
    const formatted_channel = platformFormat(
      channel.secondaryPlatformUrl,
      channel.secondaryPlatform.value,
    );

    const channelPayload = {
      channel_url: formatted_channel,
      channel_type: channel.secondaryPlatform.value,
    };

    secondaryChannelMap.set(formatted_channel, channelPayload);

    const channelType = channel.secondaryPlatform.value.toLocaleLowerCase();
    if (supportedPhylloPlatforms.includes(channelType)) {
      if (channelType === 'twitter' && onlyTwitterPhyllo !== false) {
        // Set onlyTwitterPhyllo to true IF it's not already false
        // We don't want to override "false" to "true" if we have other Phyllo-supported profiles
        onlyTwitterPhyllo = true;
      } else {
        onlyTwitterPhyllo = false;
      }
    }

    // Only send a request to Phyllo if we don't already have the data
    if (
      !secondaryPlatformsWithPhyllo ||
      (secondaryPlatformsWithPhyllo &&
        !secondaryPlatformsWithPhyllo.includes(channel.secondaryPlatformUrl))
    ) {
      phylloRequests.push(
        parsePhylloProfile(
          channel.secondaryPlatformUrl,
          channel.secondaryPlatform?.value ?? '',
          formatted_channel,
          false,
        ),
      );
    }
  }

  // Make Phyllo requests (parallel) and update the payloads
  try {
    await Promise.all(
      phylloRequests.map((promise) =>
        promise.catch((error) => {
          // This handles individual errors so the rest doesn't fail
          phylloApiFailed = true;
          console.log(error);
        }),
      ),
    ).then((results) => {
      results.forEach((phylloProfile: any) => {
        if (phylloProfile) {
          if (phylloProfile.is_primary) {
            delete phylloProfile.is_primary;
            delete phylloProfile.initial_channel;
            primaryPhylloProfile = phylloProfile;
            (payload.primary_channel as any)['creator_phyllo_profile'] =
              primaryPhylloProfile;
            (payload.primary_channel as any)['channel_url'] =
              primaryPhylloProfile['profile_url'];
          } else {
            delete phylloProfile.is_primary;
            // Get the payload using the map and update it
            const key = phylloProfile.initial_channel;
            delete phylloProfile.initial_channel;
            const secondaryChannelPayload = secondaryChannelMap.get(key);
            (secondaryChannelPayload as any)['creator_phyllo_profile'] =
              phylloProfile;
            (secondaryChannelPayload as any)['channel_url'] =
              phylloProfile['profile_url'];
            secondaryChannelMap.set(key, secondaryChannelPayload);
          }

          if (phylloProfile.engagement_rate > engagementRateThreshold) {
            engagementRateAboveThreshold = true;
          }
        }
      });
    });
  } catch {
    console.log('An error occurred while setting Phyllo payloads');
    // Set phylloApiFailed to true if we have no Phyllo data at all (previously-set or no primary)
    if (!isPrimaryFormValueTheSameAsSignUpValue && !primaryPhylloProfile) {
      phylloApiFailed = true;
    }
  }

  // Turn map into an array to set in the payload
  const channel_payload = [];
  for (const value of secondaryChannelMap.values()) {
    channel_payload.push(value);
  }
  payload.channels = channel_payload;

  // Check Rejection if phyllo failed and user does not have any values not supported by phyllo (exclude Twitter)
  if (
    !engagementRateAboveThreshold &&
    !onlyTwitterPhyllo &&
    phylloApiFailed &&
    !valuesNotSupportedByPhyllo
  ) {
    userIsRejected =
      actions.creatorOnboarding._checkUserForRejection(bypassRejection);
  }

  // Check Rejection if engagementRate is not above threshold and user does not have any values not supported by phyllo (exclude Twitter)
  if (
    (!engagementRateAboveThreshold &&
      !onlyTwitterPhyllo &&
      !valuesNotSupportedByPhyllo) ||
    state.creatorOnboarding.debugRejectedFlow
  ) {
    userIsRejected =
      actions.creatorOnboarding._checkUserForRejection(bypassRejection);
  }

  return { payload, userIsRejected };
};

type ChannelForm = {
  // primaryPlatform: {
  //   label: string;
  //   value: string;
  // } | null;
  // primaryPlatformUrl: string;
  secondaryPlatforms: {
    secondaryPlatformUrl: string;
    secondaryPlatform: { label: string; value: string } | null;
  }[];
};
export const saveCreatorChannels = async (
  { state, actions, effects }: Context,
  {
    formValues,
    bypassRejection,
  }: { formValues: ChannelForm; bypassRejection: boolean },
) => {
  const endpoint = `/api/v0/creator_profile/${state.organization.selectedOrgId}/save_profile_with_phyllo_data/`;
  const { payload, userIsRejected } =
    await actions.creatorOnboarding.buildCreatorChannelsPayload({
      formValues: formValues,
      bypassRejection: bypassRejection,
    });

  if (state.creatorOnboarding.debugMode) {
    return new Promise((resolve) => {
      return resolve({ response: {}, userIsRejected });
    });
  }

  try {
    const response = await effects.api.post(endpoint, payload);
    if (userIsRejected) {
      await actions.creatorOnboarding.setOnboardingRejected().then(() => {
        state.creatorOnboarding.onboardingStatus = 6;
      });
    }

    return { response, userIsRejected };
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveReferralData = async (
  { effects }: Context,
  {
    orgId,
    code,
    type,
  }: {
    orgId: number;
    code: string;
    type: string;
  },
) => {
  const payload = { referral_code: code, referral_type: type };
  try {
    const response = await effects.api.post(
      `/api/v0/publishers/${orgId}/set_referral/`,
      payload,
    );
    return response;
  } catch (e: any) {
    window.localStorage.removeItem('howl_referral_data');
  }
};

export const setAcquisitionType = (
  { state }: Context,
  acquisitionType: string,
) => {
  state.creatorOnboarding.acquisitionType = acquisitionType;
};

export const setReferralType = ({ state }: Context, referralType: number) => {
  state.creatorOnboarding.referralType = referralType;
};

export const getHasEarned100 = async ({ state, effects, actions }: Context) => {
  const { sessionData } = state.session;

  const endpoint = `/api/v0/publishers/${sessionData.orgId}/creator_milestone_dates/`;

  try {
    const response = await effects.api.get(endpoint);
    const data = response.data.data[0];
    return data?.date_first_100_earnings ? true : false;
  } catch (e: any) {
    throw new Error(e);
  }
};
