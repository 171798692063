export interface MerchOrgItem {
  full_name: string;
  share_goal?: any;
  click_tracker?: any;
  max_cpc: string;
  affiliate_config: Record<string, never>;
  cpc_fee_rate: number;
  url?: any;
  merch_id: number;
  pricing_model: string;
  epp_fee_rate: number;
  expected_import_frequency: number;
  impression_tracker?: any;
  notes?: any;
  show_in_dashboard: boolean;
  logo_url: string;
  cover_image_url?: any;
  daily_budget_amount?: any;
  is_feed_importer_enabled: boolean;
  manual_click_value?: any;
  widget_options: Widgetoptions;
  is_pacing_enabled: boolean;
  roi_goal: number;
  ftp_account_password: string;
  merchant_type?: any;
  is_active: boolean;
  commission_rate?: any;
  slug: string;
  cpc_goal?: any;
  short_name: string;
  ftp_account_name: string;
  is_pilot: boolean;
  utm_params?: any;
}

export interface PubOrgItem {
  full_name: string;
  billing_platform?: any;
  affiliate_config: Record<string, never>;
  url?: any;
  reserve_cpc: string;
  show_in_dashboard: boolean;
  logo_url: string;
  pub_id: number;
  has_jstag: boolean;
  instant_pay_access: number;
  widget_options: Widgetoptions;
  cogs: number;
  slug: string;
  notes?: any;
  short_name: string;
  publisher_type: number;
  amazon_tracking_id?: any;
  is_pilot: boolean;
  terms_accepted: boolean;
  datetime_terms_accepted: string;
  user_terms_accepted?: any;
  categories: any[] | null;
  onboarding_status?: number;
  acquisition_type?: number;
  referral_type?: number;
}

export interface AdminOrgItem {
  short_name: string;
  pub_id: number;
}

interface Widgetoptions {
  domains: any[];
  linkmate: boolean;
  enable_external_script: boolean;
  enable_verbose_logging: boolean;
}

interface Categories {
  category_id: number;
  name: string;
  is_enabled: boolean;
}

type State = {
  selectedOrgId: number | null;
  selectedOrgType: 'admin' | 'merchants' | 'publishers' | null;
  selectedOrgCategories: Categories[] | null;
  isLoading: boolean;
  orgs: (MerchOrgItem & PubOrgItem & AdminOrgItem)[];
  selectedOrg: any;
  searchFilter: string | null;
  allSites: boolean;
};

export const state: State = {
  selectedOrgId: null,
  selectedOrgType: null,
  selectedOrgCategories: null,
  isLoading: false,
  searchFilter: null,
  allSites: false,
  orgs: [],
  selectedOrg: {},
};
