import React, { ReactNode } from 'react';

export const NavItem = ({
  leadingProp,
  children,
  onClick,
}: {
  leadingProp?: ReactNode;
  children: ReactNode;
  onClick?: () => any;
}) => {
  return (
    <button
      onClick={onClick}
      className="text-sm rounded-lg px-2 py-1.5 hover:bg-interaction-tertiary-hover focus:bg-interaction-tertiary-pressed flex gap-4 items-center"
    >
      {leadingProp}
      {children}
    </button>
  );
};
