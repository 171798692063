'use client';

import { useState } from 'react';
import {
  LDUser,
  initialize,
  LDFlagSet as LDFlagSetType,
} from 'launchdarkly-js-client-sdk';

const NUM_RETRIES = 5;

export type LDFlagSet = LDFlagSetType;

export const useFeatureFlagClient = ({
  ldClientId,
}: {
  ldClientId: string;
}) => {
  const [client, setClient] = useState<any>(null);

  async function waitForInitialization(nthRetry = 1) {
    if (client) {
      return client;
    }

    try {
      const ldClient = initialize(ldClientId, { anonymous: true });
      await ldClient.waitForInitialization();
      setClient(ldClient);
      return ldClient;
    } catch (e: any) {
      if (nthRetry <= NUM_RETRIES) {
        console.log('Retrying feature flag initialization...');
        setTimeout(async () => {
          await waitForInitialization(nthRetry + 1);
        }, 1000);
      } else {
        throw new Error(`Feature flag initialization failed: ${e.message}`);
      }
    }
  }

  async function identify(user: LDUser) {
    let featureFlagClient = client;
    if (!featureFlagClient) {
      featureFlagClient = await waitForInitialization();
    }
    return featureFlagClient.identify(user);
  }

  async function getUser() {
    let featureFlagClient = client;
    if (!featureFlagClient) {
      featureFlagClient = await waitForInitialization();
    }

    return featureFlagClient.getUser();
  }

  return {
    waitForInitialization,
    identify,
    getUser,
  };
};
