import React, { useEffect, useState } from 'react';
import { intersects } from 'radash';
import { useSession } from 'next-auth/react';

import { getClass } from '../../../../ship/utils/component-helpers';
import StatusModule from '../../../merchant/dashboard/status-module';

import NavItem from './nav-item';
import Signout from './nav-signout';
import {
  RoleNames,
  useActions,
  useAppState,
} from '@frontend/howl-web-app/overmind';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMediaQuery } from '@frontend/shared-utils';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import { Flag, ReferralCard } from '@frontend/howl-web-app/feature-main';
import PartnerInviteCard from '../../../merchant/creator-referral/invite-card';

interface NavItem {
  label: string;
  path: string;
  flag?: string;
  flagValue?: boolean;
  permissions?: RoleNames[];
  customHiddenFlag?: boolean;
}

interface SubNavItem extends NavItem {
  absolutePath?: boolean;
  newTag?: boolean;
  customFlag?: boolean;
  beta?: boolean;
}

interface TopNavItem extends NavItem {
  icon?: string;
  subnav?: SubNavItem[];
  isLink?: boolean;
  allSites?: boolean;
}

export const NavSidebar = () => {
  const router = useRouter();
  const { data } = useSession();
  const { sidebarOpen } = useAppState().ui;
  const { sessionData } = useAppState().session;
  const { role } = useAppState().account;
  const { setSidebarOpen } = useActions().ui;
  const { getBonusDetails } = useActions().payment;
  const { bonusInfo } = useAppState().payment;
  const flags = useFlags();
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isMediumScreen = useMediaQuery('(max-width: 1024px)');
  const { allSites } = useAppState().organization;
  const email_verified = data?.user?.email_verified;
  const [navItems, setNavItems] = useState<any>([]);
  const [nextWindowEvent, setNextWindowEvent] = useState(
    sidebarOpen ? 'hide' : 'reveal',
  );
  const accountLink = useSelector((state: any) => state.accountLink) || {};
  const { selectedOrg, selectedOrgType } = useAppState().organization;
  const { techCategories } = useAppState().publisher;

  const isNotTechCategory =
    (selectedOrg &&
      selectedOrg.categories &&
      !intersects(selectedOrg.categories, techCategories)) ||
    false;
  const adminMenu: TopNavItem[] = [
    /*{
      label: 'Home',
      path: '/bid-center',
      subnav: [
        { label: 'eComm Overview', path: '/merch-ed-overview' },
        { label: 'Creator Overview', path: '/publisher-overview' },
      ],
    },*/
    {
      label: 'Creator Workspace',
      path: '/creator-workspace',
      flag: 'creatorWorkspace',
      flagValue: true,
      icon: 'document',
      subnav: [
        { label: 'Content Manager', path: '/content-manager' },
        /*{
          label: 'Terms and Conditions',
          path: '/terms-conditions',
          flag: 'termsManagement',
          flagValue: true,
        },*/
        {
          label: 'Waitlists',
          path: '/waitlists/open',
          flag: 'creatorWaitlist',
          flagValue: true,
        },
      ],
    },
    {
      label: 'eComm Workspace',
      path: '/ecomm-workspace',
      icon: 'document',
      subnav: [
        { label: 'List Management', path: '/list-management' },
        {
          label: 'Flat Fee Management',
          path: '/flat-fee-verification',
        },
        {
          label: 'Bonus Management',
          path: '/bonus-management',
          flagValue: true,
        },
      ],
    },
    /*{
      label: 'Billing',
      path: '/billing',
      flag: 'merchantInvoice',
      flagValue: true,
      subnav: [
        {
          label: 'Merchant Invoices',
          path: '/merchant-invoices',
        },
      ],
    },*/
    {
      label: 'Product Match',
      path: '/product-match',
      flag: 'ecommProductMatch',
      flagValue: true,
      subnav: [
        // {
        //   label: 'Smartlink Management',
        //   path: '/smartlinks/editor',
        // },
        {
          label: 'Match Queues',
          path: '/match-queues',
        },
        {
          label: 'New Product',
          path: '/new-product',
        },
        // { label: 'Bulk Match Page', path: '/bulk-match' },
        // {
        //   label: 'Merchant Products',
        //   path: '/merchant-products',
        //   flag: 'merchantProducts',
        //   flagValue: true,
        // },
        // {
        //   label: 'LinkMate Domains',
        //   path: '/linkmate-domains',
        // },
      ],
    },
    {
      label: 'Admin',
      path: '/accounts',
      subnav: [
        {
          label: 'Accounts',
          path: '/overview',
        },
      ],
    },
  ];

  const productMatchMenu: TopNavItem[] = [
    {
      label: 'Smartlink Management',
      path: '/smartlinks/editor',
    },
    {
      label: 'Waitlists',
      path: '/creator-workspace/waitlists/open',
      flag: 'creatorWaitlist',
      flagValue: true,
    },
    {
      label: 'Product Match',
      path: '/product-match',
      subnav: [
        { label: 'Smartlink Search', path: '/smartlink-search' },
        {
          label: 'Merchant Products',
          path: '/merchant-products',
          flag: 'merchantProducts',
          flagValue: true,
        },
        { label: 'New Product', path: '/new-product' },
        { label: 'Match Queues', path: '/match-queues' },
        { label: 'LinkMate Domains', path: '/linkmate-domains' },
      ],
    },
  ];

  const merchantMenu: TopNavItem[] = [
    {
      label: 'Home',
      path: '/desk',
      icon: 'home',
    },
    {
      label: 'Partner Center',
      path: '/partner-center',
      icon: 'profile',
      subnav: [
        {
          label: 'Discovery',
          path: '/discovery',
          flag: 'ecommPartnerDiscovery',
          flagValue: true,
          beta: true,
        },
        { label: 'Lists', path: '/list' },
        { label: 'Manage Brand Safety', path: '/manage' },
        {
          label: 'Automated Rates',
          path: '/rates',
          flag: 'eCommRatesManagement',
          flagValue: true,
        },
      ],
    },
    {
      label: 'Manage Campaigns',
      path: '/campaign-center/campaigns',
      icon: 'campaigns',
    },
    {
      label: 'Analytics',
      path: '/analytics',
      icon: 'barGraph',
      allSites: true,
      subnav: [
        {
          label: 'IOs',
          path: '/ios',
          flagValue: true,
          flag: 'ioCampaignAnalytics',
        },
        {
          label: 'Campaigns',
          path: '/campaigns',
          flagValue: true,
          flag: 'ioCampaignAnalytics',
        },
        { label: 'Creators', path: '/creators' },
        { label: 'Posts', path: '/posts' },
        { label: 'Links', path: '/recommendations' },
        { label: 'Products Purchased', path: '/products' },
        {
          label: 'Categories',
          path: '/categories',
        },
        {
          label: 'Secondary Attribution',
          path: '/secondary-attribution',
          flagValue: true,
          flag: 'secondaryAttributionAnalytics',
        },
        {
          label: 'Campaign Overview',
          path: '/campaigns-overview',
          flagValue: true,
          flag: 'newCampaignAnalytics',
        },
        { label: 'Monthly', path: '/overview' },
      ],
    },
  ];

  const publisherMenu: TopNavItem[] = [
    {
      label: 'Home',
      path: '/dashboard',
      icon: 'home',
    },
    {
      label: 'My Brands',
      path: '/campaign-center/brands',
      icon: 'priceTag',
      isLink: true,
    },
    {
      label: 'Campaigns',
      path: '/campaign-center/campaigns',
      icon: 'shoppingBag',
      flag: 'creatorCampaignCenter',
      isLink: true,
      flagValue: true,
      subnav: [
        {
          label: 'Sales',
          path: '/campaign-center/campaigns?filter=sale',
          absolutePath: true,
        },
        {
          label: 'Launches',
          path: '/campaign-center/campaigns?filter=launches',
          absolutePath: true,
        },
        {
          label: 'Gifting',
          path: '/campaign-center/campaigns?filter=gifting',
          absolutePath: true,
          customFlag: isNotTechCategory,
        },
        {
          label: 'My Calendar',
          path: '/campaign-center/calendar',
          absolutePath: true,
        },
      ],
    },
    {
      label: 'My Products',
      path: '/links/my-links',
      isLink:
        sessionData?.userRole === 'publishers' &&
        sessionData?.publisher_type === 2,
      icon: 'lightningBolt',
      subnav: [
        {
          label: 'Create Links',
          path: '/links/create-links',
          absolutePath: true,
          flag: 'createLinksNavItem',
          flagValue: true,
        },
        {
          label: 'Storefront',
          path: '/storefront',
          absolutePath: true,
          flag: 'storefrontCreator',
          flagValue: true,
        },
      ],
    },
    {
      label: 'Orders Feed',
      path: '/orders',
      flag: 'ordersPageMvp',
      flagValue: true,
      permissions: [
        RoleNames.ACCOUNT_OWNER,
        RoleNames.BILLING_ADMIN,
        RoleNames.TEAM_ADMIN,
      ],
      icon: 'document',
    },
    {
      label: allSites ? 'Analytics (All Sites)' : 'Analytics',
      path: '/analytics/overview',
      icon: 'barGraph',
      isLink: true,
      permissions: [
        RoleNames.ACCOUNT_OWNER,
        RoleNames.BILLING_ADMIN,
        RoleNames.TEAM_ADMIN,
      ],
      subnav: [
        {
          label: 'Brands',
          path: '/analytics/brands',
          absolutePath: true,
        },
        {
          label: 'Campaigns',
          path: '/analytics/campaigns',
          absolutePath: true,
        },
        {
          label: 'Posts',
          path: '/analytics/posts',
          absolutePath: true,
        },
        {
          label: 'Links',
          path: '/analytics/links',
          absolutePath: true,
        },
        {
          label: 'Storefront',
          path: '/analytics/storefront',
          absolutePath: true,
        },
        {
          label: 'Products',
          path: '/analytics/products',
          absolutePath: true,
          customFlag:
            sessionData?.userRole === 'publishers' &&
            sessionData?.publisher_type === 1,
        },
      ],
    },
    {
      label: 'Payments',
      path: flags.billingTabs
        ? '/payments/payment-history'
        : '/payments/earnings',
      customHiddenFlag: !email_verified,
      permissions: [
        RoleNames.ACCOUNT_OWNER,
        RoleNames.BILLING_ADMIN,
        RoleNames.TEAM_ADMIN,
      ],
      icon: 'purse',
    },
  ];

  const getMenuMap = () => {
    const menuMap: any = {
      admin: adminMenu,
      product_match: productMatchMenu,
      publishers: publisherMenu,
      merchants: merchantMenu,
    };
    let menuView = selectedOrgType || '';
    console.log('selectedOrgType', selectedOrgType);

    if (
      sessionData?.userRole === 'admin' &&
      !router.asPath.includes('/admin/')
    ) {
      if (router.asPath.includes('/merchant/')) {
        menuView = 'merchants';
      }
      if (router.asPath.includes('/publisher/')) {
        menuView = 'publishers';
      }
    }
    return menuMap[menuView];
  };

  useEffect(() => {
    // only run for creators
    if (
      sessionData?.userRole === 'publishers' &&
      sessionData?.publisher_type === 2
    ) {
      getBonusDetails(sessionData?.orgId);
    }
  }, [sessionData?.userRole, sessionData?.publisher_type, sessionData?.orgId]);

  useEffect(() => {
    if (router.isReady) {
      setNavItems(expandSubnavs(getMenuMap()));
      if (isMobile) {
        setSidebarOpen(false);
      }
    }
  }, [
    isNotTechCategory,
    router.pathname,
    selectedOrgType,
    router.isReady,
    bonusInfo,
    allSites,
  ]);

  useEffect(() => {
    checkWindowWidth();
  }, [isMediumScreen]);

  function checkWindowWidth() {
    if (isMediumScreen && nextWindowEvent === 'hide') {
      setNextWindowEvent('reveal');
      setSidebarOpen(false);
    } else if (!isMediumScreen && nextWindowEvent === 'reveal') {
      setNextWindowEvent('hide');
      setSidebarOpen(true);
    }
  }

  const expandSubnavs = (navItems: any) => {
    // Always expand all of the subnavs
    return navItems?.map((item: any) => {
      item.subnavExpanded = true;
      return item;
    });
  };

  const onSubnavItemClicked = () => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  const onNavItemClicked = (navItem: any) => {
    // We want to handle redirect in this component instead of NavItem
    if (!navItem.subnav) {
      // redirectToPath(navItem.path);
      if (isMobile) {
        setSidebarOpen(false);
      }
    }
  };

  /* Helper functions */
  const getOrgPath = (userRole: string, pathname: string) => {
    let orgPath;

    if (userRole === 'admin' || userRole === 'product_match') {
      orgPath = '/admin';
    } else {
      const roleToCheck = pluralize(userRole, 1);
      // Need to escape '\' bc constructing RexExp from string
      // eslint-disable-next-line no-useless-escape
      orgPath = pathname.match(new RegExp(`${roleToCheck}\/\\d+`));
      // eslint-disable-next-line no-useless-escape
      const noOrgIdRegex = new RegExp(`${roleToCheck}\/\\D+`);
      orgPath = orgPath
        ? `/${orgPath[0]}`
        : noOrgIdRegex.test(pathname)
        ? `/${userRole}/${sessionData?.orgId}`
        : null;
    }

    return orgPath;
  };

  // const toggleFeature = () => {
  //   sessionActions.update({
  //     isAdminView: !sessionData.isAdminView,
  //   });
  //   // toggleAdminView();
  // };

  const isStagingPubTestUser =
    sessionData?.userId === 233638 &&
    ['staging', 'local'].includes(process.env.NEXT_PUBLIC_APP_ENV as any);
  const isReleasePubTestUser =
    [2458, 2464].includes(sessionData?.userId) &&
    ['release'].includes(process.env.NEXT_PUBLIC_APP_ENV as any);
  const isPubTestUser = isStagingPubTestUser || isReleasePubTestUser;

  const theNavItems = sessionData?.userRole
    ? navItems
        // Exclude navItems that are not part of permissions
        ?.filter((navItem: NavItem) => {
          if (
            navItem.permissions &&
            !navItem.permissions.includes(role as any)
          ) {
            return false;
          }
          if (navItem.customHiddenFlag) {
            return false;
          }
          return true;
        })
        // Exclude navItems under feature toggle except PubFlagUser for NRP
        ?.filter(
          (navItem: NavItem) =>
            !(
              sessionData?.isAdminView &&
              sessionData?.merchPricingModel !== 'CPA'
            ) ||
            (isPubTestUser && ['/new-recs'].includes(navItem.path)) ||
            (flags && flags[navItem.label.toLowerCase()]),
        )
        ?.map((navItem: any, index: any) => {
          let userRole = sessionData?.userRole;
          if (userRole === 'admin' && !router.asPath.includes('/admin/')) {
            if (router.asPath.includes('/merchant/')) {
              userRole = 'merchant';
            }
            if (router.asPath.includes('/publisher/')) {
              userRole = 'publisher';
            }
          }
          const orgPath = getOrgPath(userRole, router.asPath);

          return navItem.label !== 'Sign Out' ? (
            <NavItem
              currentUser={sessionData}
              item={navItem}
              key={index}
              orgPath={orgPath}
              currentPath={router.asPath}
              onNavItemClicked={() => onNavItemClicked(navItem)}
              onSubnavItemClicked={() => onSubnavItemClicked()}
              isMobile={isMobile}
              flags={flags}
              accountLink={accountLink}
              permissions={navItem.permissions}
            />
          ) : (
            <Signout key="signout" />
          );
        })
    : null;

  return (
    <Transition
      show={sidebarOpen}
      enter="transition duration-200 flex flex-row overflow-hidden fixed h-full z-30 top-16"
      enterFrom="-translate-x-64"
      enterTo="-translate-x-0"
      leave="transition duration-200 flex flex-row overflow-hidden fixed h-full z-30 top-16"
      leaveFrom="-translate-x-0"
      leaveTo="-translate-x-64"
      entered="fixed flex flex-row overflow-hidden h-full z-30 top-16 inset-y-0 left-0 z-30 w-64 overflow-y-auto"
      appear={true}
      unmount={false}
    >
      <div
        className={getClass(
          'static inset-y-0 left-0 z-30 w-64 overflow-y-auto bg-[#010007] text-sm pb-20',
        )}
        data-cy={'sidebar'}
      >
        <>
          {' '}
          {router.asPath.includes('/publisher/') ? (
            <Flag name="referralCreatorDash">
              <div className="px-4 mt-1">
                <ReferralCard />
              </div>
            </Flag>
          ) : null}
          <ul className="p-0 mt-4 pb-24 px-2 flex flex-col space-y-4">
            {theNavItems}
          </ul>
          {router.query.merchantId && (
            <>
              <PartnerInviteCard />
              <StatusModule merchId={router.query.merchantId.toString()} />
            </>
          )}
        </>
      </div>
    </Transition>
  );
};
