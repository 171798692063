import { useAppState } from '@frontend/howl-web-app/overmind';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React from 'react';
import { SignOut } from '../account-dropdown/sign-out';
import { AdminAccountSettingsLink } from './account-settings-link';
import { OrgList } from './org-list';

export const AdminDropdown = ({
  showPortalNavigation,
}: {
  showPortalNavigation?: boolean;
}) => {
  const { sessionData } = useAppState().session;
  const fullName = `${sessionData.firstName} ${sessionData.lastName}`;

  if (
    !sessionData ||
    (!sessionData.isAdmin && sessionData.userRole !== 'product_match')
  )
    return null;

  return (
    <div>
      <Popover className="relative">
        {({ open, close }: any) => (
          <>
            <Popover.Button
              data-cy="nav-admin"
              className={classNames(
                'outline-none text-sm h-10 cursor-pointer  inline-flex items-center rounded-lg px-3',
                {
                  'text-white hover:bg-deep-space-400': !showPortalNavigation,
                },
              )}
            >
              <span className="">Admin</span>
              {open ? (
                <ChevronUpIcon className="h-4 w-4 ml-1 stroke-1" />
              ) : (
                <ChevronDownIcon className="h-4 w-4 ml-1 stroke-1" />
              )}
            </Popover.Button>
            <Transition
              show={open}
              className="relative z-50 drop-shadow-lg"
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel
                className={classNames(
                  'absolute bg-white rounded-lg sm:right-0 w-72 overflow-hidden',
                  {
                    '-right-8': sessionData.orgId !== 0,
                    'right-0': sessionData.orgId === 0,
                    'pt-3 mr-4': sessionData.isAdmin,
                    'top-5': !showPortalNavigation,
                    'shadow-md border': showPortalNavigation,
                  },
                )}
                data-cy="nav-admin-dropdown"
              >
                <div className="w-72 flex flex-col transform transition relative">
                  {sessionData.isAdmin && <OrgList close={close} />}
                  <div
                    className={classNames(
                      'w-full sticky bottom-0 border-t border-neutral-gray-300 bg-white',
                    )}
                  >
                    <p className="h-10 px-4 text-ellipsis text-sm text-neutral-gray-600 flex items-center">
                      {fullName} (Admin)
                    </p>
                    <div>
                      {sessionData.userRole === 'admin' && (
                        <AdminAccountSettingsLink />
                      )}
                      <SignOut />
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
