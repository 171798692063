import React from 'react';
import { Icon } from '@frontend/howl-web-app/feature-main';
import { Button } from '@frontend/shared-ui-kit/button';
import Link from 'next/link';
import { useAppState } from '@frontend/howl-web-app/overmind';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PartnerInviteCard = () => {
  const { sessionData } = useAppState().session;
  const announcement = useFlags().merchantNavAnnouncementJson;

  if (!announcement) return null;

  return (
    <div className="flex flex-col items-center justify-between pb-5 py-6 px-4 bg-ice-100 m-4 rounded">
      <Icon className="mb-2 " iconName={announcement.iconName} />
      <div className="uppercase text-sm my-1.5">{announcement.title}</div>
      <div className="text-sm text-center">{announcement.tagline}</div>
      <Button dataRef="bref-nav" className={'mt-2'}>
        <Link
          href={announcement.href.replaceAll('{{merchId}}', sessionData.orgId)}
        >
          {announcement.anchor}
        </Link>
      </Button>
    </div>
  );
};

export default PartnerInviteCard;
