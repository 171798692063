// models ng-class, facilitates setting className of components
const getClass = (...options: any) => {
  const classes: any = [];

  options.forEach((option: any) => {
    if (!option) return;

    if (typeof option === 'string') {
      return classes.push(option);
    }

    Object.keys(option).forEach((key) => {
      if (option[key]) classes.push(key);
    });
  });

  return classes.join(' ');
};

/*
 * Prevents state updates on unmounted components. Use case: user
 * navigates away from megatables before queryStats call completes,
 * throwing a react state warning
 */
const patchState = (context: any) => {
  const callback = context.setState;

  context.setState = (...args: any) => {
    if (!context._isMounted) return;
    callback.apply(context, args);
  };
};

export { getClass, patchState };
