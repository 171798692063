import { Button } from '@frontend/shared-ui-kit/button';
import Link from 'next/link';
import { useRouter } from 'next/router';

export const ReferralCard = () => {
  const router = useRouter();
  const publisherId = router.query['publisherId'];

  return (
    <div className="flex flex-col items-center bg-ice-100 py-5 px-4 rounded-lg">
      <p className="uppercase font-medium text-xs">Refer & Earn</p>
      <p className="text-xs pb-3 pt-2.5 text-center">
        Get a <span className="font-bold">$100</span> bonus for every Creator
        you refer to Howl.
      </p>
      <Link
        href={`/publisher/${publisherId}/campaign-center/refer-and-earn`}
        passHref
        className="text-violet-400 text-xs mt-2"
      >
        <Button dataCy="cref-nav">Share</Button>
      </Link>
    </div>
  );
};
