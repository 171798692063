import React, { useEffect, useRef } from 'react';
import { useOutsideClick, useClass } from '@frontend/shared-utils';

const noOp = () => {
  // do nothing
};

export const ModalWrapper = ({
  children,
  closeModal,
  showX,
  modalClassName,
  allowOverflow,
  dataCy,
  zIndex,
  disableOutsideClick,
}: {
  children: React.ReactNode;
  closeModal: () => void;
  showX?: boolean;
  modalClassName?: string;
  allowOverflow?: boolean;
  dataCy?: string;
  zIndex?: string;
  disableOutsideClick?: boolean;
}) => {
  const modal = useRef<HTMLElement | null>(null);

  useOutsideClick(modal, disableOutsideClick ? noOp : closeModal);
  const xIcon = showX ? (
    <img
      src={'/images/icons/X-small.png'}
      className="absolute top-5 right-5 w-auto h-4 cursor-pointer"
      alt="close"
      onClick={closeModal}
    />
  ) : null;

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function handleKeyDown(e: any) {
    if (e.key === 'Escape') {
      closeModal();
    }
  }

  return (
    <div
      className={`flex fixed top-16 left-0 justify-center items-center w-full h-full bg-black bg-opacity-30 ${
        zIndex || 'z-30'
      }`}
      data-cy={dataCy}
    >
      <div
        className={useClass(
          'rounded-lg bg-white relative shadow-lg mx-4',
          modalClassName,
          {
            'overflow-visible': allowOverflow,
            'overflow-auto': !allowOverflow,
          },
        )}
        ref={(node) => (modal.current = node)}
        onClick={(e) => e.stopPropagation()}
        data-cy="modal"
      >
        {xIcon}
        {children}
      </div>
    </div>
  );
};
