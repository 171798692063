import React from 'react';

export const LinkIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.8012 12.199C14.8456 11.2438 13.5497 10.7072 12.1985 10.7072C10.8474 10.7072 9.55148 11.2438 8.59586 12.199L4.99203 15.8017C4.03639 16.7573 3.49951 18.0535 3.49951 19.4049C3.49951 20.7564 4.03639 22.0525 4.99203 23.0082C5.94767 23.9638 7.2438 24.5007 8.59528 24.5007C9.94676 24.5007 11.2429 23.9638 12.1985 23.0082L13.9999 21.2069"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1987 15.8017C13.1544 16.7569 14.4502 17.2935 15.8014 17.2935C17.1526 17.2935 18.4484 16.7569 19.4041 15.8017L23.0079 12.199C23.9635 11.2434 24.5004 9.94724 24.5004 8.59576C24.5004 7.24428 23.9635 5.94816 23.0079 4.99251C22.0523 4.03687 20.7561 3.5 19.4046 3.5C18.0532 3.5 16.757 4.03687 15.8014 4.99251L14.0001 6.79385"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
