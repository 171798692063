import { Context } from '..';
import { Tier } from './state';

export const onInitializeOvermind = () => {
  return;
};

// export const setMerchant = ({ state }: Context, merchantId: string) => {
//   state.ecommRatesLists.merchantId = merchantId;
// };

export const saveCreatorTierRate = async (
  { effects }: Context,
  {
    merchantId,
    payload,
  }: {
    merchantId: string;
    payload: {
      low?: number;
      medium?: number;
      high?: number;
    };
  },
) => {
  if (!merchantId) return;

  const endpoint = `/api/v0/merchants/${merchantId}/creator_tier_rates/`;
  try {
    return await effects.api
      .put(endpoint, payload)
      .then((res: any) => res)
      .catch((e: any) => {
        throw new Error(e);
      });
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getCreatorTiers = async (
  { effects }: Context,
  merchantId: string,
) => {
  if (!merchantId) throw new Error('No merchant ID provided');

  const endpoint = `/api/v0/merchants/${merchantId}/creator_tiers/`;
  try {
    return await effects.api.get(endpoint).then((res: any) => {
      return res.data.data[0];
    });
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getCreatorTierDetails = async (
  { effects }: Context,
  {
    tier,
    merchantId,
    params = {},
  }: {
    tier: Tier;
    merchantId: string;
    params?: { per_page?: number; page?: number; search?: string };
  },
) => {
  if (!merchantId || !tier) return;

  const endpoint = `/api/v0/merchants/${merchantId}/creator_tier_rates/${tier.toUpperCase()}/`;

  try {
    return await effects.api
      .get(endpoint, params)
      .then((res: any) => res.data.data[0])
      .catch((e: any) => {
        throw new Error(e);
      });
  } catch (e: any) {
    throw new Error(e);
  }
};

// export const setCreatorTiers = (
//   { state }: Context,
//   creatorTiers: { [key in Tier]: CreatorTier },
// ) => {
//   state.ecommRatesLists.data.creatorTiers = creatorTiers;
// };

export const getTargetListPubs = async (
  { effects, state }: Context,
  { merchantId, targetListId }: { merchantId: string; targetListId: string },
) => {
  return await effects.api
    .get(
      `/api/v0/merchants/${merchantId}/target_lists/${targetListId}/publisher_list_info/`,
    )
    .then((resp: any) => {
      const data = resp.data.data[0].target_list_info;
      state.ecommRatesLists.data.targetListCreators[parseInt(targetListId)] =
        data;
      return data;
    })
    .catch((err: Error) => console.error(err));
};

export const getTargetListsByPubs = async (
  { effects }: Context,
  { merchantId, pubIds }: { merchantId: string; pubIds: number[] },
) => {
  return await effects.api
    .get(
      `/api/v0/merchants/${merchantId}/target_lists/target_lists_by_publisher/`,
      { pub_ids: pubIds.join(',') },
    )
    .then((resp: any) => resp.data.data[0])
    .catch((err: Error) => console.error(err));
};
