import { MouseEventHandler } from 'react';
import classNames from 'classnames';

export type Color = 'black' | 'red' | 'green' | 'violet' | 'gray';
export type Variant = 'solid' | 'outline' | 'ghost';
type Size = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
type LoaderClass = {
  base: string;
  circle: string;
};

export interface ButtonProps<T extends string = never> {
  /** Button color from theme */
  color?: Color;

  /** Predefined button size */
  size?: Size;

  /** Button rounding size */
  rounding?: Size | 'none';

  /** Additional classes that append to the button container. */
  className?: string;

  /** Click handler for when button is clicked. */
  onClick?: MouseEventHandler;

  /** If the button is disabled or not. */
  disabled?: boolean;

  /** E2e targeting tag. */
  dataCy?: string;

  /** Full Story event-tracking tag. */
  dataRef?: string;

  /** Button type attribute */
  type?: 'submit' | 'button' | 'reset';

  /** Button appearance variant */
  variant?: Variant;

  /** Button width tailwind class */
  width?: string;

  /** Button height tailwind class */
  height?: string;

  /** Adds icon before button content  */
  leftIcon?: React.ReactNode;

  /** Adds icon after button content  */
  rightIcon?: React.ReactNode;

  /** Indicate loading state */
  loading?: boolean;

  /** The text / element content shown in the button. */
  children?: React.ReactNode;

  /** Label text for accessibility if no children (icon only). */
  ariaLabel?: string;

  /** The title attribute adds a tooltip with title text to the button. */
  title?: string;

  /** Additional classes that append to the button content. */
  contentClassName?: string;
}

const colorClasses: { [key in Color]: { [key in Variant]: string } } = {
  black: {
    solid:
      'text-white bg-black border border-black focus:bg-[#102837] disabled:bg-neutral-gray-100 disabled:border-neutral-gray-100  disabled:text-neutral-gray-500',
    outline:
      'text-black bg-white border border-neutral-gray-700 hover:bg-[#F2F0FF] focus:bg-[#DCD1FF] focus:text-[#000A4E] disabled:bg-white disabled:border-neutral-gray-500 disabled:text-neutral-gray-500',
    ghost:
      'text-black bg-none hover:bg-[#F2F0FF] focus:bg-[#DCD1FF] disabled:bg-none disabled:text-neutral-gray-500',
  },
  red: {
    solid: 'text-white bg-[#C00020] hover:bg-[#9A001A] focus:bg-[#4D000D]',
    outline:
      'text-[#C00020] bg-white border border-[#C00020] hover:bg-[#FFF8F8] focus:bg-[#FAEBED] disabled:text-neutral-gray-500',
    ghost:
      'text-[#C00020] bg-none hover:bg-[#FFF8F8] focus:bg-[#FAEBED] disabled:bg-none disabled:text-neutral-gray-500',
  },
  green: {
    solid: 'text-white bg-[#51A52B] hover:bg-[#107C3C] focus:bg-[#107C3C]',
    outline:
      'text-[#51A52B] bg-white border border-[#51A52B] hover:bg-[#FFF8F8] focus:bg-[#FAEBED] disabled:text-neutral-gray-500',
    ghost:
      'text-[#51A52B] bg-none hover:bg-[#FFF8F8] focus:bg-[#FAEBED] disabled:bg-none disabled:text-neutral-gray-500',
  },
  violet: {
    solid:
      'text-white bg-violet-300 hover:bg-violet-400 focus:bg-violet-400 disabled:bg-neutral-gray-100 disabled:border-neutral-gray-100  disabled:text-neutral-gray-500',
    outline:
      'text-violet-300 bg-white border border-violet-300 hover:bg-violet-10 focus:bg-violet-10 disabled:text-neutral-gray-500',
    ghost:
      'text-violet-300 bg-none hover:bg-violet-10 focus:bg-bg-violet-10 disabled:bg-none disabled:text-neutral-gray-500',
  },
  gray: {
    solid:
      'text-black bg-neutral-gray-100 hover:bg-neutral-gray-200 focus:bg-neutral-gray-200',
    outline:
      'text-gray-700 bg-white border border-neutral-gray-100 hover:bg-neutral-gray-10 focus:bg-neutral-gray-10',
    ghost: 'text-gray-700 bg-none',
  },
};

const loaderClasses: { [key in Color]: { [key in Variant]: LoaderClass } } = {
  black: {
    solid: { base: 'text-white', circle: '#555555' },
    outline: { base: 'text-black', circle: '#EEEEEE' },
    ghost: { base: 'text-black', circle: '#EEEEEE' },
  },
  red: {
    solid: { base: 'text-white', circle: '#555555' },
    outline: { base: 'text-destructive', circle: '#EEEEEE' },
    ghost: { base: 'text-destructive', circle: '#EEEEEE' },
  },
  green: {
    solid: { base: 'text-white', circle: '#555555' },
    outline: { base: 'text-nebula-300', circle: '#EEEEEE' },
    ghost: { base: 'text-nebula-300', circle: '#EEEEEE' },
  },
  violet: {
    solid: { base: 'text-white', circle: '#555555' },
    outline: { base: 'text-violet-400', circle: '#EEEEEE' },
    ghost: { base: 'text-violet-400', circle: '#EEEEEE' },
  },
  gray: {
    solid: { base: 'text-black', circle: '#555555' },
    outline: { base: 'text-gray-700', circle: '#EEEEEE' },
    ghost: { base: 'text-gray-700', circle: '#EEEEEE' },
  },
};

const sizeClasses: Record<Size, string> = {
  none: '',
  xs: 'text-xs px-2 py-1',
  sm: 'text-sm px-3 py-1.5',
  md: 'text-sm px-[16px] py-[6px]',
  lg: 'text-base px-5 py-2',
  xl: 'text-base px-6 py-3',
  '2xl': 'text-lg px-6 py-6 leading-none',
};

const roundingClasses: Record<Size | 'none', string> = {
  none: '',
  xs: 'rounded-xs',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-full',
  '2xl': 'rounded-full',
};

/** The Button component is used to trigger an action or event, such as submitting a form, opening a dialog, canceling an action, or performing a delete operation. */
export const Button = ({
  size = 'md',
  type = 'button',
  dataCy = 'button',
  dataRef = 'button',
  color = 'black',
  rounding = 'xl',
  variant = 'solid',
  width = 'w-fit',
  height = 'h-min',
  leftIcon,
  rightIcon,
  loading,
  className,
  disabled,
  onClick,
  children,
  ariaLabel,
  title,
  contentClassName,
}: ButtonProps) => {
  const loaderColorMap =
    color && loaderClasses[color] && variant && loaderClasses[color][variant]
      ? loaderClasses[color][variant]
      : ({} as LoaderClass);
  const loaderBaseClass = loaderColorMap.base || 'text-gray-200';
  const loaderCircleColor = loaderColorMap.circle || '#1C64F2';

  const loader = (
    <svg
      role="status"
      className={classNames(`inline w-4 h-4 ${loaderBaseClass} animate-spin`)}
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill={loaderCircleColor}
      />
    </svg>
  );

  function handleClick(e: any) {
    if (typeof onClick !== 'undefined' && !loading) onClick(e);
  }

  return (
    <button
      data-cy={dataCy}
      data-ref={dataRef}
      type={type}
      color={color}
      disabled={disabled}
      onClick={handleClick}
      className={classNames(
        'relative group flex items-center justify-center text-center font-normal focus:z-10 transition-colors duration-100',
        rounding && roundingClasses[rounding],
        color && colorClasses[color] && variant && colorClasses[color][variant],
        {
          'cursor-not-allowed': disabled || loading,
        },
        className,
        width,
        height,
      )}
      aria-label={ariaLabel}
      title={title}
    >
      {loading && <span className="absolute">{loader}</span>}
      <span
        className={classNames(
          'flex items-center transition-all duration-75 ease-in',
          { 'text-transparent': loading },
          sizeClasses[size],
          contentClassName,
        )}
      >
        {!loading && leftIcon}
        {children}
        {!loading && rightIcon}
      </span>
    </button>
  );
};

Button.displayName = 'Button';
Button.filePath = 'libs/shared/ui-kit/src/lib/button/button.tsx';
