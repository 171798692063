import {
  FC,
  HTMLInputTypeAttribute,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { XIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { useDebounce } from '@frontend/shared-utils';

type Color = 'base' | 'red';

export type InputProps = {
  /** Standard html name of input. */
  name?: string;

  /** Standard html value of input. */
  value?: any;

  /** Change handler for when input content is changed. */
  onChange?: (value: any) => void;

  onFocus?: (value: any) => void;

  /** Clicker handler for when input is clicked. */
  onClick?: () => void;

  /** Maximum character limit for input value. */
  maxLength?: number;

  /** Additional classes that append to the input element. */
  className?: string;

  /** Placeholder text displayed in the input prior to value existing. */
  placeholder?: string;

  /** Standard HTML type attribute for input. */
  type?: HTMLInputTypeAttribute;

  /** Additional text displayed under the input. */
  helperText?: ReactNode;

  /** Input color from theme */
  color?: Color;

  /** Standard HTML disabled attribute for preventing user interaction. */
  disabled?: boolean;

  /** Displays X icon that clears input value. */
  clearable?: boolean;

  /** Displays downward chevron for use in dropdown menus. */
  asDropdown?: boolean;

  /** Standard HTML required attribute for input. */
  required?: boolean;

  /** Delay the execution of the onChange event. */
  debounce?: boolean;

  /** The duration (in ms) to delay for when using the debounce prop */
  debounceDuration?: number;

  /** Alters appearance to accommodate user search intentions. */
  search?: boolean;

  /** Prevents changing of input value. */
  readOnly?: boolean;

  /** Prevents auto completion of input value. */
  autoComplete?: 'on' | 'off';

  /** Error state description(s) or flag for indicating problem(s). */
  error?: boolean | string | ReactNode | ReactNode[];

  /** id attribute added to the rendered input */
  id?: string;

  /** minimum value for number input */
  min?: number;

  /** maximum value for number input */
  max?: number;

  /** stepper value and allowed decimal places for number input */
  step?: number;

  /** Blur handler for when input loses focus */
  onBlur?: (value: any) => void;

  /**Selector for E2E Tests */
  dataCy?: string;

  /** Full Story event-tracking tag. */
  dataRef?: string;

  /** Used to set custom inputMode on the input box, typically used for accessibility with virtual keyboards */
  inputMode?:
    | 'email'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;

  /** Regular Expression pattern that's used for validation on the provided input value */
  pattern?: string;

  /** Used to display basic tooltip describing input functionality */
  title?: string;

  /** Used to show custom Icon on right side of input */
  customIcon?: ReactNode;

  /** Clicker handler for when custom icon is clicked. */
  onIconClick?: () => any;

  /** Input handler. Can be used to prevent entry of invalid values. */
  onInput?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const colorClasses: Record<Color, { input: string; helperText: string }> = {
  base: {
    input:
      'bg-white border-[#BBBBBB] text-[#000000] placeholder-text-[#777777] focus:border-[#000000]',
    helperText: 'text-[#999999] text-xs',
  },
  red: {
    input:
      'bg-white border-[#B81818] text-[#000000] placeholder-text-[#777777] focus:border-[#B81818]',
    helperText: 'text-[#B81818] text-xs',
  },
};

/** The Input component presents a standard html input field to receive user input. */
export const Input: FC<InputProps> & { filePath: string } = ({
  name,
  value,
  onChange,
  onFocus,
  onClick,
  onInput,
  maxLength,
  className,
  placeholder,
  type = 'text',
  helperText,
  color = 'base',
  disabled,
  clearable,
  asDropdown,
  required,
  debounce,
  debounceDuration = 600,
  search,
  readOnly,
  autoComplete,
  error,
  id,
  min,
  max,
  step,
  onBlur,
  dataCy,
  inputMode,
  pattern,
  title,
  customIcon,
  onIconClick,
}) => {
  const [localValue, setLocalValue] = useState(value || '');
  const [clearing, setClearing] = useState<boolean>(false);
  const debouncedLocalValue = useDebounce(localValue, debounceDuration);

  // update local input value from props
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleValueSet = useCallback((targetValue: any) => {
    setLocalValue(targetValue);
    if (typeof onChange !== 'undefined' && (!debounce || clearing)) {
      onChange(targetValue);
      setClearing(false);
    }
  }, []);

  // call onChange callback if debouncing when local changed, delayed
  useEffect(() => {
    if (debounce) {
      if (typeof onChange !== 'undefined' && debounce && localValue !== value)
        onChange(debouncedLocalValue);
    }
  }, [debouncedLocalValue, debounce]);

  const calculatedColor = error ? 'red' : color;

  let textNotes = [];

  if (error) {
    textNotes = Array.isArray(error) ? [...error] : [error];
  } else if (helperText) {
    textNotes.push(helperText);
  }

  return (
    <div className="flex relative flex-col w-full">
      <div className="flex">
        <div className="w-full">
          {search && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.49958 0C11.6422 0 15 3.35764 15 7.5C15 9.40417 14.2905 11.1425 13.1213 12.4651L18 17.3436L17.3436 18L12.465 13.1214C11.1423 14.2905 9.40386 15 7.49958 15C3.35769 15 0 11.6422 0 7.5C0 3.35778 3.35769 0 7.49958 0ZM7.49958 0.832778C3.81766 0.832778 0.832824 3.81769 0.832824 7.5C0.832824 11.1823 3.81766 14.1672 7.49958 14.1672C11.1822 14.1672 14.1672 11.1824 14.1672 7.5C14.1672 3.81757 11.1822 0.832778 7.49958 0.832778Z" />
              </svg>
            </div>
          )}

          <input
            data-cy={dataCy}
            onFocus={onFocus}
            className={classNames(
              'block w-full h-full border outline-0 disabled:cursor-not-allowed disabled:opacity-50 p-[9px] text-sm',
              colorClasses[calculatedColor].input,
              {
                'rounded-lg': !search,
                'pl-10 rounded-full': search,
                'pr-8': customIcon || clearable,
              },
              className,
            )}
            type={type}
            placeholder={placeholder}
            name={name}
            value={localValue}
            onChange={(event) => {
              handleValueSet(event.target.value);
            }}
            onInput={onInput}
            onBlur={(e) => (onBlur ? onBlur(e.target.value) : null)}
            onClick={onClick}
            maxLength={maxLength}
            disabled={disabled}
            readOnly={readOnly}
            autoComplete={autoComplete ? autoComplete : undefined}
            required={required}
            id={id}
            min={min}
            max={max}
            step={step}
            inputMode={inputMode}
            pattern={pattern}
            title={title}
          />
          {clearable && !asDropdown && !!localValue?.length && (
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={() => {
                setClearing(true);
                setLocalValue('');
              }}
            >
              <XIcon className="h-4 w-4 text-[#000000]" />
            </div>
          )}
          {asDropdown && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
              <ChevronDownIcon className="h-4 w-4 text-[#000000]" />
            </div>
          )}

          {customIcon && onIconClick && (
            <button
              className="absolute inset-y-0 right-0 flex items-center mr-3 cursor-pointer"
              onClick={onIconClick}
            >
              {customIcon}
            </button>
          )}
        </div>
      </div>
      <div>
        {textNotes?.map((item, idx) => {
          return (
            <p
              data-cy={`${error ? 'error' : 'help'}-messages`}
              className={classNames(
                'mt-1 text-sm',
                colorClasses[calculatedColor].helperText,
              )}
              key={`error-${idx}`}
            >
              {item}
            </p>
          );
        })}
      </div>
    </div>
  );
};

Input.displayName = 'Input';
Input.filePath = 'libs/shared/ui-kit/src/lib/input/input.tsx';
