import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn, customTierIcon } from '@frontend/shared-utils';
import { useMediaQuery } from '@mantine/hooks';
import { Icon } from '../../v2/icon';
import { Tooltip } from '../../v1/tooltip';

interface ProgressProps
  extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  isActive?: boolean;
  customTierIcons?: customTierIcon[];
  progressBubble?: {
    heading: string;
    description: string;
  };
}

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(
  (
    {
      className,
      value,
      color = 'green',
      isActive,
      customTierIcons,
      progressBubble,
      ...props
    },
    ref,
  ) => {
    const index: number = parseInt(props.id!);
    const isFull: boolean = (value || 0) >= 100;
    const isMobile: boolean = useMediaQuery('(max-width: 768px)');
    const bubbleProgress = value === 0 ? 0.1 : value;

    const backgroundColors: { [key: string]: string } = {
      green: 'bg-green-300',
      violet: 'bg-violet-300',
    };

    return (
      <div className={'overflow-visible'}>
        {progressBubble &&
          !isMobile &&
          bubbleProgress &&
          bubbleProgress < 100 &&
          bubbleProgress > 0 && (
            <div className={'relative mr-7 z-[6] overflow-visible'}>
              <div
                className={'absolute right-0 bottom-0 -ml-14 w-28 -mb-[20px]'}
                style={{ left: bubbleProgress + `%` }}
              >
                <Tooltip
                  className={'rounded-lg h-12 text-xs flex flex-col'}
                  radius={4}
                  label={
                    <div
                      className={
                        'text-center justify-center align-middle text-xs'
                      }
                    >
                      <div className={'font-bold'}>
                        {progressBubble.heading}
                      </div>
                      <div>{progressBubble.description}</div>
                    </div>
                  }
                >
                  {' '}
                  <div>
                    <div
                      className={cn(
                        'mt-2 w-2 h-2 rounded-full bg-black mx-auto',
                        color && backgroundColors[color],
                      )}
                    ></div>
                    <div className={'h-5 w-0.5 bg-black mx-auto'}></div>
                  </div>
                </Tooltip>
              </div>
            </div>
          )}

        <div className="relative flex items-center">
          <div
            ref={ref}
            className={cn(
              `md:h-2 md:w-full mr-7 w-2 h-14 overflow-hidden bg-neutral-gray-300 md:mb-0 mb-7`,
              index === 0 && 'md:rounded-l-full',
              index === 0 && isMobile && 'rounded-t-full',
              !isActive && 'bg-neutral-gray-200',
            )}
            {...props}
          >
            <div
              className={cn(
                'h-full w-full flex-1 bg-green-300 transition-all',
                backgroundColors[color],
              )}
              style={
                isMobile
                  ? { height: `${value && value > 0 ? value : 0}%` }
                  : { width: `${value && value > 0 ? value : 0}%` }
              }
            ></div>
          </div>

          {customTierIcons ? (
            <div
              className={cn(
                'absolute h-11 w-11 z-[5] shrink-0 rounded-full md:right-0 right-auto md:bottom-auto md:mt-0 mt-14 flex md:ml-auto md:mb-auto md:-mr-2.5 -ml-[18px]',
                isFull && 'border-2 border-white',
                isFull && !!customTierIcons && backgroundColors[color],
                !isFull && 'bg-neutral-gray-300',
                !isFull && !isActive && 'bg-neutral-gray-200',
              )}
            >
              {(isActive || (!isActive && isFull)) && (
                <Icon
                  iconName={customTierIcons[index].icon}
                  className="h-2 w-2 rounded-full justify-center align-middle m-auto stroke-1"
                  colorClassName={'text-white'}
                />
              )}
            </div>
          ) : (
            <div
              className={cn(
                'absolute h-8 w-8 z-[5] shrink-0 rounded-full md:right-0 right-auto md:bottom-auto md:mt-0 mt-14 flex md:ml-auto md:mb-auto md:-mr-0.5 -ml-3',
                backgroundColors[color],
                isFull && 'border-2 border-white',
                !isFull && 'bg-neutral-gray-300',
              )}
            >
              {isFull ? (
                <Icon
                  iconName="whiteCheckmark"
                  className="h-3 w-3 rounded-full justify-center align-middle m-auto"
                  size={20}
                  image={true}
                  color={'white'}
                />
              ) : (
                <div
                  className={
                    'h-3 w-3 rounded-full border-2 border-white justify-center align-middle m-auto bg-white'
                  }
                ></div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
