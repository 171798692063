import { Context } from '..';
import moment from 'moment';
import { Bonus, GoalType } from '@frontend/shared-utils';

export const fetchMyBrands = async (context: Context) => {
  try {
    const response = await context.effects.api.get(
      `/api/v0/publishers/${context.state.organization.selectedOrgId}/creator_homepage/my_brands/`,
    );
    const brands = response.data?.data?.slice(0, 10);
    return brands;
  } catch (error) {
    return error;
  }
};

export const fetchBonuses = async (
  context: Context,
  {
    goalType,
    startPadding,
    endPadding,
    howlWideOnly = false,
  }: {
    goalType?: string;
    startPadding?: number;
    endPadding?: number;
    howlWideOnly?: boolean;
  },
) => {
  try {
    const response = await context.effects.api.get(
      `/api/v0/publishers/${context.state.organization.selectedOrgId}/goals/`,
      {
        goal_type: goalType,
        start_padding: startPadding,
        end_padding: endPadding,
      },
    );

    return context.actions.creatorHomepage.fetchBrandEarnings({
      goals: response.data.data[0].goals,
      howlWideOnly,
    });
  } catch (e: any) {
    console.warn(e);
    throw new Error(e);
  }
};

export const fetchBrandBonuses = async (
  context: Context,
  {
    merchId,
    startPadding,
    endPadding,
  }: {
    merchId: number;
    startPadding?: number;
    endPadding?: number;
  },
) => {
  try {
    const response = await context.effects.api.get(
      `/api/v0/publishers/${context.state.organization.selectedOrgId}/brands/${merchId}/goals/`,
      {
        start_padding: startPadding,
        end_padding: endPadding,
      },
    );

    return context.actions.creatorHomepage.fetchBrandEarnings({
      goals: response.data.data[0].goals,
    });
  } catch (e: any) {
    console.warn(e);
    throw new Error(e);
  }
};

export const fetchBrandEarnings = async (
  context: Context,
  { goals, howlWideOnly }: { goals: Bonus[]; howlWideOnly?: boolean },
) => {
  try {
    if (!goals || goals.length === 0) return [];
    const pubId = context.state.organization.selectedOrgId;
    const promises = goals.map((goal: any) => {
      const goalIsUpcoming =
        context.actions.date.compareDates({
          d1: moment().utc(),
          d2: moment(goal.datetime_start).utc(),
        }) === -1;
      const creatorIsOptedIn = goal.goal_creator.find(
        (gc: any) => gc.pub_id === pubId,
      )?.opted_in;

      // Optimization - Don't ping performance API if we already know there won't be data
      // Or don't hit performance API for merchant goals if we only care about howl-wide
      if (
        goalIsUpcoming ||
        (!creatorIsOptedIn && goal.goal_type !== GoalType.NewCreatorBonus) ||
        (howlWideOnly && goal.merch_id)
      ) {
        return {
          data: { data: [{ stats: [{ attributed_revenue: '0.00' }] }] },
        };
      }

      return context.effects.api.get(
        `/api/v0/publishers/${pubId}/performance/`,
        {
          group_by: goal.merch_id ? 'merch_id' : null,
          fields: goal.merch_id
            ? 'merch_id,attributed_revenue'
            : 'attributed_revenue',
          date_from: goal.datetime_start.split('T')[0],
          date_to: goal.datetime_end.split('T')[0],
          merch_id: goal.merch_id,
          use_creator_attribution: true,
        },
      );
    });
    const responses = await Promise.all(promises);
    responses.forEach((resp: any, idx) => {
      goals[idx].earnings_usd = resp.data.data[0].stats[0].attributed_revenue;
    });
    return goals;
  } catch (e: any) {
    console.warn(e);
    throw new Error(e);
  }
};

export const optInCreatorBonus = async (
  context: Context,
  { goalId, merchantId }: { goalId: number; merchantId: number },
) => {
  try {
    return await context.effects.api.post(
      `/api/v0/publishers/${context.state.organization.selectedOrgId}/brands/${merchantId}/goals/${goalId}/opt_in/`,
    );
  } catch (e: any) {
    console.warn(e);
    throw new Error(e);
  }
};

export const optInPlatformBonus = async (
  context: Context,
  { goalId }: { goalId: number },
) => {
  try {
    return await context.effects.api.post(
      `/api/v0/publishers/${context.state.organization.selectedOrgId}/goals/${goalId}/opt_in/`,
    );
  } catch (e: any) {
    console.warn(e);
    throw new Error(e);
  }
};
