export type StatItem = {
  publisher_earnings_usd: number;
  total_earnings_usd: number;
  flat_fee_earnings_usd: number;
  attributed_revenue_usd: number;
  clicks: number;
  publisher_epc: string;
  click_through_rate: string;
  conversion_rate: string;
  average_order_value: string;
  min_event_date: string | null;
  date_interval: string;
  pub_id: number;
  links_created: number;
};

export type ProductStatItem = {
  merchant_product_ids: string;
  product_price: string;
  quantity: number;
  attributed_revenue_usd: string;
  merch_id: number;
  merchant_name: string;
  product_name: string;
  product_brand: string;
};

export type PostStatItem = {
  publisher_earnings_usd: number;
  attributed_revenue_usd: number;
  clicks: number;
  publisher_epc: string;
  click_through_rate: string;
  conversion_rate: string;
  average_order_value: string;
  edit_id: number;
  edit_name: string;
  edit_url?: any;
  edit_channel?: any;
};

export type GraphDataByRange = {
  sevenDay: string[];
  thisMonth: string[];
  lastMonth: string[];
  halfYear: string[];
  thisYear: string[];
  multiYear: string[];
};

export type ProjectionsByRange = {
  sevenDay: any;
  thisMonth: any;
  lastMonth: any;
  halfYear: any;
  thisYear: any;
  multiYear: any;
};

export type EarningsChange = {
  amount: any;
  unit: 'percentage' | 'absolute-currency' | 'absolute' | 'invalid';
};

export type AnalyticsOverviewState = {
  statsByDay: StatItem[];
  statsByMonth: StatItem[];
  previousStatsByMonth: StatItem[];
  metricsByMonth: any[];
  statsByYear: StatItem[];
  allTimeEarnings: any;
  dailyEarnings: any;
  dailyChange: EarningsChange;
  monthlyEarnings: any;
  monthlyEarningsRaw: number | null;
  monthlyChange: EarningsChange;
  isLoading: boolean;
  showNoResult: boolean;
  graphDataByRange: GraphDataByRange;
  projectionsByRange: ProjectionsByRange;
  rangeDropdownItems: any;
};

export type AnalyticsTableParams = {
  merchIds?: any[];
  dateFrom?: string;
  dateTo?: string;
  direction?: 'desc' | 'asc';
  orderBy?: string;
  page?: number;
  perPage?: number;
  editId?: number;
  searchQuery?: string | null;
  auctionId?: string;
  strategyId?: string;
  collectionId?: number;
};

export type AnalyticsTableState = {
  data: any;
  totalsData: any;
  isLoading: boolean;
  params: AnalyticsTableParams;
  brands: any[];
  showNoResult: boolean;
  total: number | null;
  hasFlatFees?: boolean | null;
  hasFlatFeesByDay?: boolean | null;
  hasFlatFeesByMonth?: boolean | null;
  hasFlatFeesByYear?: boolean | null;
  columns: AnalyticsTableColumn[];
  availableStrategies?: boolean;
  status?: AnalyticsCampaignStatus[];
  pricingModel?: AnalyticsCampaignPricingModel[];
  featured?: {
    editId?: number | null;
    name?: string | null;
    auctionId?: number;
    merchId?: number | null;
    linkName?: string;
    collectionId?: string;
  };
};

export type PublisherState = {
  analytics: AnalyticsOverviewState;
  analyticsTable: AnalyticsTableState;
  analyticsGraph: AnalyticsGraphState;
  showMocks: boolean;
  displayAlertIcon: boolean;
  techCategories: number[];
};

export type AnalyticsGraphState = {
  selectedRows: { [key: string]: PostStatItem };
  selectedColors: { [key: string]: string };
  stats: { name: string; data: [] }[];
  selectedGranularity: {
    label: string;
    value: 'daily' | 'monthly' | 'yearly';
    disabled: boolean;
  };
  params?: {
    dateTo: string;
    dateFrom: string;
    interval: string;
  };
  isLoading: boolean;
  showNoResult: boolean;
};

export type AnalyticsTableColumn = {
  label: string;
  value: string;
  selected: boolean;
};

export type AnalyticsCampaignStatus = {
  label: string;
  value: string;
  selected: boolean;
};

export type AnalyticsCampaignPricingModel = {
  label: string;
  value: string;
  selected: boolean;
};

export const analyticsOverviewState: AnalyticsOverviewState = {
  statsByDay: [],
  statsByMonth: [],
  previousStatsByMonth: [],
  metricsByMonth: [],
  statsByYear: [],
  allTimeEarnings: null,
  dailyEarnings: null,
  dailyChange: { amount: null, unit: 'invalid' },
  monthlyEarnings: null,
  monthlyEarningsRaw: null,
  monthlyChange: { amount: null, unit: 'invalid' },
  isLoading: true,
  showNoResult: false,
  graphDataByRange: {
    sevenDay: [],
    thisMonth: [],
    lastMonth: [],
    halfYear: [],
    thisYear: [],
    multiYear: [],
  },
  projectionsByRange: {
    sevenDay: {},
    thisMonth: {},
    lastMonth: {},
    halfYear: {},
    thisYear: {},
    multiYear: {},
  },
  rangeDropdownItems: [
    {
      label: 'Last 7 Days',
      name: 'sevenDay',
      unit: 'day',
      disabled_in: ['community_metrics'],
      selected: false,
    },
    {
      label: 'This Month',
      name: 'thisMonth',
      unit: 'day',
      disabled_in: ['community_metrics'],
      selected: false,
    },
    {
      label: 'Last Month',
      name: 'lastMonth',
      unit: 'day',
      disabled_in: ['community_metrics'],
      selected: false,
    },
    {
      label: 'Last 6 Months',
      name: 'halfYear',
      unit: 'month',
      selected: true,
    },
    {
      label: 'Year to Date',
      name: 'thisYear',
      unit: 'month',
      selected: false,
    },
    {
      label: 'Year over Year',
      name: 'multiYear',
      disabled_in: ['community_metrics'],
      unit: 'year',
      selected: false,
    },
    {
      label: 'Previous Year Last 6 Months',
      name: 'previousHalfYear',
      unit: 'month',
      selected: false,
      hidden: true,
    },
    {
      label: 'Previous Year To Date',
      name: 'previousYear',
      unit: 'month',
      selected: false,
      hidden: true,
    },
  ],
};

export const analyticsTableState: AnalyticsTableState = {
  data: null,
  totalsData: null,
  isLoading: false,
  params: {
    orderBy: 'publisher_earnings_usd',
    direction: 'desc',
    page: 1,
    perPage: 50,
    dateFrom: '',
    dateTo: '',
    merchIds: [],
    searchQuery: null,
  },
  brands: [],
  showNoResult: false,
  total: null,
  columns: [],
  availableStrategies: false,
};

export const analyticsGraphState: AnalyticsGraphState = {
  selectedRows: {},
  selectedColors: {},
  stats: [],
  selectedGranularity: {
    label: 'Daily',
    value: 'daily',
    disabled: false,
  },
  params: {
    dateFrom: '',
    dateTo: '',
    interval: '',
  },
  isLoading: true,
  showNoResult: false,
};

export const state: PublisherState = {
  analytics: analyticsOverviewState,
  analyticsTable: analyticsTableState,
  analyticsGraph: analyticsGraphState,
  showMocks: false,
  displayAlertIcon: false,
  techCategories: [48], // Hardcoded Consumer Electronic category.
};
