import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { flattenDeep } from 'lodash';
import Arrow from './arrow';

// TODO: Move to base components
const Carousel = (props: any) => {
  const [showLeftArrow, setshowLeftArrow] = useState(false);
  const [showRightArrow, setshowRightArrow] = useState(true);

  const ref = useRef<any>(null);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    updateCarouselArrowStates();
  }, [props.loading, props.children]);

  function onResize() {
    window.setTimeout(updateCarouselArrowStates, 200);
  }

  function updateCarouselArrowStates() {
    let showLeftArrow = true;
    let showRightArrow = true;

    if (ref.current) {
      const { scrollWidth, clientWidth, scrollLeft } = ref.current;
      const carouselWidth = scrollWidth - clientWidth;

      if (scrollLeft === 0) {
        showLeftArrow = false;
      }

      if (
        Math.ceil(scrollLeft) >= carouselWidth ||
        scrollWidth === clientWidth
      ) {
        showRightArrow = false;
      }
      setshowLeftArrow(showLeftArrow);
      setshowRightArrow(showRightArrow);
    }
  }

  function scrollCarousel(direction: string) {
    const { scrollWidth, clientWidth } = ref.current;
    const numItems = [...flattenDeep(props.children)].length;
    const itemWidth = scrollWidth / numItems;

    // if no scrollDistance prop and screen width is less than 2 items wide, scroll item by item
    const distance = props.scrollDistance
      ? props.scrollDistance
      : clientWidth < itemWidth * 2
      ? scrollWidth / numItems
      : 500;
    const multiple = direction === 'left' ? -1 : 1;
    if (ref.current) ref.current.scrollBy(distance * multiple, 0);
  }

  const rightArrow =
    showRightArrow && !props.loading ? (
      <Arrow direction="right" onClick={() => scrollCarousel('right')} />
    ) : null;

  const leftArrow =
    showLeftArrow && !props.loading ? (
      <Arrow direction="left" onClick={() => scrollCarousel('left')} />
    ) : null;

  return (
    <div className={props.className || 'flex items-center w-full relative'}>
      {leftArrow}
      <div
        data-cy="carousel-items"
        className={classNames(
          'carousel-items w-full flex snap-x sm:snap-none sm:overflow-x-hidden scroll-smooth overflow-x-auto orders-module-item',
          props.carouselItemsClassName || 'py-0 px-10',
          'pt-2',
          props.justifyStyling || 'justify-between',
          props.itemsStyling || 'items-center',
        )}
        ref={ref}
        onScroll={updateCarouselArrowStates}
      >
        {props.children}
      </div>
      {rightArrow}
    </div>
  );
};

export default Carousel;
