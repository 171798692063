import React, { useEffect } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { ListGroup } from '@frontend/shared-ui-kit/list-group';
import {
  useActions,
  useAppState,
  useEffects,
} from '@frontend/howl-web-app/overmind';
import { Virtuoso } from 'react-virtuoso';
import { useRouter } from 'next/router';
import { api } from '@frontend/howl-web-app/trpc';

export const PublisherSelection = ({ close }: { close: () => any }) => {
  const { sessionData } = useAppState().session;
  const router = useRouter();

  useEffect(() => {
    // TODO: reset the infiniteQuery
  }, [sessionData.orgId]);

  const { orgs, searchFilter } = useAppState().organization;

  const { data, fetchNextPage, isInitialLoading } =
    api.admins.getPublisherDropdownList.useInfiniteQuery(
      {
        take: 20,
        search: searchFilter || undefined,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor;
        },
        staleTime: 60000 * 2,
      },
    );

  const { setSelectedOrg, setSearchFilter, setLoading } =
    useActions().organization;
  const { getAuthenticatedStartingUrlPath } = useEffects().auth;
  const { update } = useActions().session;

  const toggleAllPublishers = () => {
    const firstPub = orgs.find((org) => org.pub_id);
    toggleAllSites(firstPub);
  };

  const toggleAllSites = async (org: any) => {
    const updateVal: any = {};
    if (org?.pub_id) {
      updateVal.allPubs = true;
    } else {
      updateVal.allPubs = false;
    }
    update({ ...updateVal });
    setSelectedOrg({ selectedOrg: org, allSites: true });
    await router.push(
      getAuthenticatedStartingUrlPath({
        userRole: org.type,
        orgId: org.orgId,
      }),
    );
  };

  const handleOrgSelect = async (org: any) => {
    setLoading(true);
    const selectedOrg = {
      type: org.pub_id ? 'publisher' : 'merchant',
      orgId: org.pub_id ? org.pub_id : org.merch_id,
      path: org.pub_id ? 'dashboard' : 'desk',
    };
    await router.push(
      `/${selectedOrg.type}/${selectedOrg.orgId}/${selectedOrg.path}`,
    );
    setLoading(false);
  };

  const LoadingSkeleton = () => {
    return (
      <>
        {[1, 2, 3, 4, 5, 6, 7, 8, 10].map((val, idx) => {
          return (
            <li
              key={idx}
              className="h-10 mb-4 flex items-center animate-pulse bg-neutral-gray-100 mx-4 rounded-lg"
            ></li>
          );
        })}
      </>
    );
  };

  const orgItems = (close: any) => {
    return isInitialLoading ? (
      <LoadingSkeleton />
    ) : (
      <Virtuoso
        style={{ height: '100%' }}
        endReached={() => fetchNextPage()}
        overscan={10}
        data={[
          null,
          // ...[].concat(...data?.pages.flatMap((page) => page.items)),
          ...(data?.pages.flatMap((page) => page.items) || [])
            .filter((org) => {
              if (org.pub_id === 0) {
                return false;
              }
              if (!org.show_in_dashboard) {
                return false;
              }

              return true;
            })
            .sort((a, b) => a.full_name.localeCompare(b.full_name)),
        ]}
        itemContent={(index, org) => {
          if (index === 0) {
            return (
              <ListGroup.Item
                onClick={() => {
                  toggleAllPublishers();
                  close();
                }}
                className={'h-10'}
                containerClassName="hover:bg-ice-10 focus:bg-ice-100 transition !px-4 flex items-center"
              >
                All Partners
              </ListGroup.Item>
            );
          }

          return (
            <ListGroup.Item
              onClick={() => {
                handleOrgSelect(org);
                close();
              }}
              key={index}
              className={'h-10'}
              containerClassName="hover:bg-ice-10 focus:bg-ice-100 transition !px-4 flex items-center"
              leftContent={
                org?.pub_id && org?.publisher_type === 2 ? (
                  <div className="text-blue-700 mr-4">C</div>
                ) : (
                  <div className="text-blue-700 mr-4">P</div>
                )
              }
            >
              <div
                className="h-10 overflow-hidden text-ellipsis flex items-center"
                title={(org?.pub_id || '').toString()}
              >
                {org?.full_name}
              </div>
            </ListGroup.Item>
          );
        }}
      />
    );
  };

  return (
    <>
      <div className="flex items-center sticky top-0 bg-white px-4">
        <SearchIcon className="w-6 h-6 mr-2" />
        <input
          type="text"
          className="flex items-center bg-white font-normal outline-none rounded-md py-2 cursor-pointer w-full text-sm"
          placeholder="Search"
          autoComplete="off"
          onChange={(val) => {
            setSearchFilter(val.target.value);
          }}
          defaultValue={searchFilter ? searchFilter : ''}
        />
      </div>
      <div className="h-96 relative text-sm overflow-x-clip">
        <ListGroup className="h-full">{orgItems(close)}</ListGroup>
      </div>
    </>
  );
};
