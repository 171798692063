// import 'react-dates/lib/css/_datepicker.css';
import '../styles/styles.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';

import * as FullStory from '@fullstory/browser';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { withLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { createOvermind } from 'overmind';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as OvermindProvider } from 'overmind-react';
import store from '../redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import { DefaultLayout } from '../components/ui/layouts/DefaultLayout';
import { config } from '@frontend/howl-web-app/overmind';
import { optimizelyClient } from '@frontend/shared-feature-flags';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as UrqlProvider } from 'urql';
import Head from 'next/head';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import {
  Analytics as Mixpanel,
  useAnalytics,
} from '@lightspeed/react-mixpanel-script';
import { useRouter } from 'next/router';
import { IdentityProvider } from '@frontend/howl-web-app/feature-main';
import { api } from '@frontend/howl-web-app/trpc';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@frontend/shared-utils';
import { SessionProvider as NextAuthProvider } from 'next-auth/react';
import { AuthLayout } from '../components/ui/layouts/AuthLayout';
import Script from 'next/script';
import ErrorBoundary from './error';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();
  const mixpanel = useAnalytics();
  const [overmind, setOvermind] = useState<any>(null);
  const flags = useFlags();

  let Layout;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (Component && Component.Layout) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Layout = Component.Layout;
  } else {
    Layout = AuthLayout;
  }

  useEffect(() => {
    setOvermind(
      createOvermind(config, {
        devtools: false,
        // devtools: 'localhost:3031',
      }),
    );

    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      FullStory.init({ orgId: '135TD1' });
      FullStory.event('Successful app load', {});
    }

    // if (['staging', 'release'].includes(process.env.NEXT_PUBLIC_APP_ENV!)) {
    const ddParams = {
      clientToken: 'pub3f03ab3003ea79ab9bcbae363d9ae158',
      site: 'datadoghq.com',
      service: 'howl-web',
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      env: process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV,
    };

    datadogLogs.init({
      ...ddParams,
      forwardErrorsToLogs: true,
      forwardReports: 'all',
      forwardConsoleLogs: 'all',
      telemetrySampleRate: 0,
      silentMultipleInit: true,
    });

    datadogRum.init({
      ...ddParams,
      applicationId: '06d0d443-df2f-42aa-a80e-b844f9ba4036',
      sessionReplaySampleRate: 20,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      silentMultipleInit: true,
    });
    // }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (mixpanel?.track) {
        mixpanel.track('Page view', {
          url,
        });
      }
      if (typeof window !== 'undefined') {
        const pageContainer = document.querySelector('#page-container');
        pageContainer?.scrollTo(0, 0);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // for now, bail on SSR but in the future enable it by removing unsafe window references from code
  if (typeof window === 'undefined') {
    // console.log('bailing on SSR');
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.__H = overmind;

  const component = <Component {...pageProps} />;

  const TheMixpanel: any = Mixpanel;
  const TheRouter: any = Router;

  // TODO: Property 'children' does not exist on type 'IntrinsicAttributes & DndProviderProps<unknown, unknown>'.
  const SilencedDndProvider = DndProvider as any;

  const pageContent = (
    <ReduxProvider store={store}>
      <SilencedDndProvider backend={HTML5Backend}>
        <TheMixpanel appName="howl-web" identity={'public'}>
          <OptimizelyProvider
            optimizely={optimizelyClient}
            user={{ id: 'guest' }}
          >
            <IdentityProvider>
              <TheRouter>
                <NextAuthProvider session={session}>
                  <ErrorBoundary>
                    <Layout>{component}</Layout>
                  </ErrorBoundary>
                </NextAuthProvider>
              </TheRouter>
            </IdentityProvider>
          </OptimizelyProvider>
        </TheMixpanel>
      </SilencedDndProvider>
    </ReduxProvider>
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>

      <OvermindProvider value={overmind}>
        {overmind && (
          <UrqlProvider value={overmind.effects.graphql.getClient()}>
            <QueryClientProvider client={queryClient}>
              {pageContent}
            </QueryClientProvider>
          </UrqlProvider>
        )}
      </OvermindProvider>
    </>
  );
}

const context = {
  kind: 'user',
  key: 'default',
  name: 'default',
  email: 'default',
};

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LD_CLIENT_ID!,
  context: context,
  options: {
    bootstrap: 'localStorage',
    diagnosticOptOut: true,
    logger: basicLogger({ level: 'error' }),
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
})(api.withTRPC(MyApp));
