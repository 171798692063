import React from 'react';

type Props = {
  tooltip: string | string[] | React.ReactElement | React.ReactElement[];
  maxWidthPx: number;
  background?: string;
  textBackground?: string;
  textAlign?: string;
  alignment?: string;
  children?: React.ReactNode;
  top?: string;
  isPreFormatted?: boolean;
  borderStyle?: string;
  textColor?: string;
  position?: string;
  iconCharacter?: string;
};

export const HoverIconTooltip = ({
  tooltip,
  maxWidthPx,
  background,
  textBackground,
  textAlign,
  alignment,
  children,
  top,
  borderStyle,
  textColor = 'text-white',
  position = 'relative',
  iconCharacter = '!',
}: Props) => {
  const DefaultTooltipIcon = () => (
    <span
      data-cy="hover-icon-tooltip-icon"
      className={`relative -top-px text-sm ${textColor}`}
      style={{ lineHeight: 0 }}
    >
      {iconCharacter}
    </span>
  );

  const tipBackground = textBackground ? textBackground : 'bg-gray-600';
  const alignText = textAlign ? textAlign : '';

  let toolTipDisplay;

  if (
    typeof tooltip === 'string' ||
    (typeof tooltip === 'object' && !Array.isArray(tooltip))
  ) {
    toolTipDisplay = <div>{tooltip}</div>;
  } else {
    toolTipDisplay = (
      <div data-cy="hover-icon-multiple-line">
        {tooltip.map((text: string | React.ReactNode, idx: number) => {
          return <div key={idx}>{text}</div>;
        })}
      </div>
    );
  }

  return (
    <div
      className={`group ${position} ml-1 rounded-full ${borderStyle} ${background} inline-flex items-center justify-center`}
      style={{ height: '16px', width: '16px', top: top || 0 }}
      data-cy="hover-icon-tooltip"
    >
      {children || <DefaultTooltipIcon />}
      <div
        className={`z-10 absolute pointer-events-none ${
          alignment || '-top-2 left-1 -translate-y-full'
        } opacity-0 group-hover:opacity-100 transition-opacity text-left`}
        style={{ width: maxWidthPx + 'px' }}
      >
        <div
          className={`inline-block ${tipBackground} px-2 py-2 rounded-md whitespace-normal text-white text-xs
            font-thin ${alignText}`}
        >
          {toolTipDisplay}
        </div>
      </div>
    </div>
  );
};
