export const ChartIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="8" width="3" height="12" rx="1.5" fill="#594DF5" />
      <rect x="6" width="3" height="20" rx="1.5" fill="#594DF5" />
      <rect x="12" y="8" width="3" height="12" rx="1.5" fill="#594DF5" />
      <rect x="18" y="3" width="3" height="17" rx="1.5" fill="#594DF5" />
    </svg>
  );
};
