import React, { useEffect, useState } from 'react';
import StatusItem from './status-item';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffects } from '@frontend/howl-web-app/overmind';
import { useRouter } from 'next/router';

type Props = {
  merchId: string;
};

const paidStatusMap: any = {
  Paid: 'Invoices paid',
  Unpaid: 'Open Invoices',
  Overdue: 'Overdue Invoices',
};

const StatusModule = ({ merchId }: Props) => {
  const api = useEffects().api;
  const router = useRouter();
  const { merchantId } = router.query;
  const [feedStatus, setFeedStatus] = useState<boolean | null>(null);
  const [paidStatus, setPaidStatus] = useState<string | null>(null);
  const [pixelStatus, setPixelStatus] = useState<boolean | null>(null);

  useEffect(() => {
    try {
      api.get(`/api/v0/merchants/${merchId}/feed_status/`).then((resp: any) => {
        setFeedStatus(Boolean(resp.data.data[0].merchant_feed_import_status));
      });
    } catch (error) {
      // could not fetch, do not display
    }
  }, []);

  useEffect(() => {
    try {
      api
        .get(`/api/v0/merchants/${merchId}/invoice_status/`)
        .then((resp: any) => {
          setPaidStatus(resp.data.data[0].merchant_invoice_status);
        });
    } catch (error) {
      // could not fetch, do not display
    }
  }, []);

  useEffect(() => {
    try {
      api.get(`/api/v0/merchants/?merchants=${merchId}`).then((resp: any) => {
        setPixelStatus(resp.data.data[0]?.pricing_model === 'EPP');
      });
    } catch (error) {
      // could not fetch, do not display
    }
  }, []);

  const statusText = paidStatusMap[paidStatus as any];

  return (
    <div
      data-cy="status-module"
      className="px-6 pt-4 pb-6 mt-auto border-t border-gray-700 text-white text-xs"
    >
      <h4 className="tracking-widest pb-3">SYSTEM STATUS</h4>
      {paidStatus !== null && feedStatus !== null && (
        <StatusItem
          statusId="all"
          isOperational={paidStatus !== 'Overdue' && feedStatus}
          statusText="All systems are operational"
        />
      )}
      {paidStatus !== null && (
        <StatusItem
          statusId="invoices"
          isOperational={paidStatus === 'Paid'}
          statusText={statusText}
          link={`/merchant/${merchantId}/account-settings/billing-and-payments/invoices`}
          showSemiOperational={paidStatus === 'Unpaid'} // Used conditionally to indicate a yellow icon for semi-operational status
        />
      )}
      {feedStatus !== null && (
        <StatusItem
          statusId="feed"
          isOperational={feedStatus}
          statusText="Product feed status"
          link={`/merchant/${merchantId}/account-settings/system-status`}
        />
      )}
      {pixelStatus !== null && (
        <StatusItem
          isOperational={pixelStatus}
          statusText="Pixel status"
          statusId="pixel"
          link={`/merchant/${merchantId}/account-settings/system-status`}
        />
      )}
      {paidStatus !== null && feedStatus !== null && pixelStatus !== null && (
        <>
          {paidStatus && feedStatus && <p className="pt-2">No known issues.</p>}
          {!feedStatus && <p className="pt-2">Last feed sync failed.</p>}
        </>
      )}
    </div>
  );
};

export default StatusModule;
