import React from 'react';
import { SanitizedHTMLWrapper } from '@frontend/shared-utils';

type Props = {
  tooltip: string;
  maxWidthPx: number;
  background?: string;
  textBackground?: string;
  textAlign?: string;
  alignment?: string;
  children?: React.ReactNode;
  top?: string;
  isPreFormatted?: boolean;
  innerHtml?: boolean;
};

const DefaultTooltipIcon = () => (
  <span
    data-cy="hover-icon-tooltip-icon"
    className="relative -top-px text-sm text-white"
    style={{ lineHeight: 0 }}
  >
    !
  </span>
);

const HoverIconTooltip = ({
  tooltip,
  maxWidthPx,
  background,
  textBackground,
  textAlign,
  alignment,
  children,
  top,
  isPreFormatted,
  innerHtml,
}: Props) => {
  const tipBackground = textBackground ? textBackground : 'bg-gray-600';
  const alignText = textAlign ? textAlign : '';

  let toolTipText;
  if (innerHtml && isPreFormatted) {
    toolTipText = <SanitizedHTMLWrapper html={tooltip} />;
  } else if (isPreFormatted) {
    toolTipText = <p className="whitespace-pre-wrap">{tooltip}</p>;
  } else {
    toolTipText = tooltip;
  }

  return (
    <div
      className={`group relative ml-1 rounded-full ${background} inline-flex items-center justify-center`}
      style={{ height: '16px', width: '16px', top: top || 0 }}
      data-cy="hover-icon-tooltip"
    >
      {children || <DefaultTooltipIcon />}
      <div
        className={`z-10 absolute pointer-events-none ${
          alignment || '-top-2 left-1 -translate-y-full'
        } opacity-0 group-hover:opacity-100 transition-opacity text-left`}
        style={{ width: maxWidthPx + 'px' }}
      >
        <div
          className={`inline-block ${tipBackground} px-2 py-2 rounded-md whitespace-normal text-white text-xs
            font-thin ${alignText}`}
        >
          {toolTipText}
        </div>
      </div>
    </div>
  );
};

export default HoverIconTooltip;
