import classNames from 'classnames';

interface SignUpStepperProp {
  activeStep: number;
  skipSecondStep: boolean;
}

export const SignUpStepper = ({
  activeStep,
  skipSecondStep,
}: SignUpStepperProp) => {
  return (
    <div className="flex items-center w-full max-w-xl">
      <div className="flex items-center relative">
        <div
          data-cy="signup-stepper-dot"
          className={classNames(' h-3 w-3 rounded-full', {
            'bg-aurora-100': activeStep >= 1,
          })}
        ></div>
        {activeStep === 1 ? (
          <div className="h-16 w-16 absolute bg-[url(/images/signup/ellipse.png)] bg-contain left-1/2 transform -translate-x-1/2 "></div>
        ) : null}
      </div>

      {!skipSecondStep && (
        <>
          <div className="flex-auto border-t-2 border-violet-100"></div>
          <div className="flex items-center relative">
            <div
              data-cy="signup-stepper-dot"
              className={classNames(' h-3 w-3 rounded-full', {
                'bg-aurora-100': activeStep >= 2,
                'bg-violet-100': activeStep < 2,
              })}
            ></div>
            {activeStep === 2 ? (
              <div className="h-16 w-16 absolute bg-[url(/images/signup/ellipse.png)] bg-contain left-1/2 transform -translate-x-1/2 "></div>
            ) : null}
          </div>
        </>
      )}
      <div className="flex-auto border-t-2 border-violet-100"></div>
      <div className="flex items-center relative">
        <div
          data-cy="signup-stepper-dot"
          className={classNames(' h-3 w-3 rounded-full', {
            'bg-aurora-100': activeStep >= 3,
            'bg-violet-100': activeStep < 3,
          })}
        ></div>
        {activeStep === 3 ? (
          <div className="h-16 w-16 absolute bg-[url(/images/signup/ellipse.png)] bg-contain left-1/2 transform -translate-x-1/2 "></div>
        ) : null}
      </div>
      <div className="flex-auto border-t-2 border-violet-100"></div>
      <div className="flex items-center relative">
        <div
          data-cy="signup-stepper-dot"
          className={classNames(' h-3 w-3 rounded-full', {
            'bg-aurora-100': activeStep >= 4,
            'bg-violet-100': activeStep < 4,
          })}
        ></div>
        {activeStep === 4 ? (
          <div className="h-16 w-16 absolute bg-[url(/images/signup/ellipse.png)] bg-contain left-1/2 transform -translate-x-1/2 "></div>
        ) : null}
      </div>
      <div className="flex-auto border-t-2 border-violet-100"></div>
      <div className="flex items-center relative">
        <div
          data-cy="signup-stepper-dot"
          className={classNames(' h-3 w-3 rounded-full', {
            'bg-aurora-100': activeStep >= 5,
            'bg-violet-100': activeStep < 5,
          })}
        ></div>
        {activeStep === 5 ? (
          <div className="h-16 w-16 absolute bg-[url(/images/signup/ellipse.png)] bg-contain left-1/2 transform -translate-x-1/2 "></div>
        ) : null}
      </div>
      <div className="flex-auto border-t-2 border-violet-100"></div>
      <div className="flex items-center relative">
        <div
          className={classNames(' h-3 w-3 rounded-full', {
            'bg-aurora-100': activeStep >= 6,
            'bg-violet-100': activeStep < 6,
          })}
        ></div>
        {activeStep === 6 ? (
          <div className="h-16 w-16 absolute bg-[url(/images/signup/ellipse.png)] bg-contain left-1/2 transform -translate-x-1/2 "></div>
        ) : null}
      </div>
    </div>
  );
};
