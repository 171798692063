'use client';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect, useState } from 'react';

type EventAnalyticsOptions = {
  isObfuscated?: boolean;
  isDebugMode?: boolean;
};

export type AbstractAnalytics = {
  identify: (...args: any[]) => void;
  track: (...args: any[]) => void;
  group: (...args: any[]) => void;
  alias: (...args: any[]) => void;
  debug: (...args: any[]) => void;
};

function createStub() {
  return function (...args: any[]) {
    console.log('Analytics stub called', args);
  };
}

export const useEventAnalytics = (
  writeKey: string,
  { isObfuscated, isDebugMode = false }: EventAnalyticsOptions,
) => {
  const initOptions: any = {};
  const [analytics, setAnalytics] = useState<AbstractAnalytics | null>(null);
  // helps prevent ad-blocking
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#bundle-obfuscation
  if (isObfuscated) {
    initOptions.obfuscate = true;
  }

  useEffect(() => {
    let analytics: AbstractAnalytics;

    if (process.env['NODE_ENV'] === 'development') {
      if (isDebugMode) {
        analytics = AnalyticsBrowser.load({ writeKey }, initOptions);
        analytics.debug(true);
      } else {
        analytics = {
          identify: createStub(),
          track: createStub(),
          group: createStub(),
          alias: createStub(),
          debug: createStub(),
        };
      }
    } else {
      analytics = AnalyticsBrowser.load({ writeKey }, initOptions);
    }
    setAnalytics(analytics);
  }, []);

  return analytics;
};
