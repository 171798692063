import { getSession } from 'next-auth/react';
import { Context } from '..';
import jwt_decode from 'jwt-decode';

const tokenRecheckPeriodInSeconds = 5 * 60;

const isTokenExpiringSoon = (token: string) => {
  const expirationSeconds =
    jwt_decode<{ exp: number }>(token).exp - Date.now() / 1000;

  // console.log('expirationSeconds: ', expirationSeconds);

  // return expirationSeconds <= 60;
  return expirationSeconds <= tokenRecheckPeriodInSeconds;
};

export const onInitializeOvermind = (context: Context) => {
  const inCi =
    window?.location?.hostname?.includes('localhost') &&
    !process.env['NEXT_PUBLIC_VERCEL_ENV'] &&
    process.env.NODE_ENV === 'production';
  const httpUrl =
    (typeof window !== 'undefined' &&
      window.location.hostname === process.env['NEXT_PUBLIC_DEMO_HOSTNAME']) ||
    inCi
      ? process.env['NEXT_PUBLIC_DEMO_API_URL']
      : process.env['NEXT_PUBLIC_HOST'];

  context.effects.api.initialize({
    clientOptions: {
      httpUrl: httpUrl,
      getJWT: async () => {
        let { currentUser } = window.localStorage;
        currentUser = currentUser ? JSON.parse(currentUser) : null;
        if (
          currentUser?.accessToken &&
          isTokenExpiringSoon(currentUser?.accessToken)
        ) {
          const session = await getSession();
          return session?.accessToken;
        } else {
          return currentUser?.accessToken;
        }
      },
      refreshJWT: () => {
        const error = 'deprecated token refresh';
        console.log(error);
        return Promise.reject(error);
      },
      clearJWT: context.actions.api.clearToken,
      logout: context.actions.auth.logout,
    },
  });
};

export const clearToken = async (context: Context) => {
  try {
    // TODO: apparently this does nothing as of 7/6/2021
    // const { data } = await effects.api.logout();
    console.log('clearToken()');
    context.actions.auth.setJwt(null);
    context.actions.session.destroy();

    return null;
  } catch (e) {
    console.error('clearToken.error', e);
    return e;
  }
};
