import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
};

export type Article = {
  __typename?: 'Article';
  hybridGraph?: Maybe<ArticleData>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Categories = {
  __typename?: 'Categories';
  categories: Array<Category>;
};

export type CategoriesOutput = {
  __typename?: 'CategoriesOutput';
  data: Array<Categories>;
};

export type Category = {
  __typename?: 'Category';
  category_id: Scalars['Int'];
  name: Scalars['String'];
};

export type Collection = {
  __typename?: 'Collection';
  collection_id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
  social_post_channel_type?: Maybe<Scalars['String']>;
  social_post_url?: Maybe<Scalars['String']>;
  storefront_id: Scalars['Int'];
  storefront_links?: Maybe<Array<Maybe<StorefrontLink>>>;
  storefront_links_count: Scalars['Int'];
  title: Scalars['String'];
};

export type CreatorChannel = {
  __typename?: 'CreatorChannel';
  channel_id: Scalars['Int'];
  channel_type: Scalars['String'];
  platform_handle: Scalars['String'];
  rank: Scalars['Int'];
};

export type CreatorInfo = {
  __typename?: 'CreatorInfo';
  full_name?: Maybe<Scalars['String']>;
};

export type GetUserExistsOutput = {
  __typename?: 'GetUserExistsOutput';
  data: Array<Maybe<UserExists>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type MerchIdOutput = {
  __typename?: 'MerchIdOutput';
  data: Array<Maybe<Merchant>>;
};

export type PubIdOutput = {
  __typename?: 'PubIdOutput';
  data: Array<Maybe<Publisher>>;
};

export type Storefront = {
  __typename?: 'Storefront';
  collections: Array<Collection>;
  creator_channels: Array<CreatorChannel>;
  creator_info: CreatorInfo;
  storefront: StorefrontOverview;
};

export type StorefrontCollectionOutput = {
  __typename?: 'StorefrontCollectionOutput';
  data: Array<Collection>;
};

export type StorefrontLink = {
  __typename?: 'StorefrontLink';
  bam_link_id: Scalars['Int'];
  collection_id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  merch_logo_url?: Maybe<Scalars['String']>;
  product_brand?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  product_url?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
  storefront_link_id: Scalars['Int'];
  user_brand_name?: Maybe<Scalars['String']>;
  user_image_url?: Maybe<Scalars['String']>;
  user_link_description?: Maybe<Scalars['String']>;
  user_product_name?: Maybe<Scalars['String']>;
};

export type StorefrontOutput = {
  __typename?: 'StorefrontOutput';
  data: Array<Storefront>;
};

export type StorefrontOverview = {
  __typename?: 'StorefrontOverview';
  bio?: Maybe<Scalars['String']>;
  profile_pic_url?: Maybe<Scalars['String']>;
  storefront_id: Scalars['Int'];
  title: Scalars['String'];
  url_slug: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UserExists = {
  __typename?: 'UserExists';
  exists: Scalars['Boolean'];
};

export type ArticleData = {
  __typename?: 'articleData';
  description?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  site_name?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CFields = {
  __typename?: 'cFields';
  cover_image_url: Scalars['String'];
  full_name: Scalars['String'];
  logo_url: Scalars['String'];
  merch_id: Scalars['Int'];
};

export type CHits = {
  __typename?: 'cHits';
  _source: CFields;
};

/** columns and relationships of "channel_type" */
export type Channel_Type = {
  __typename?: 'channel_type';
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
};

/** aggregated selection of "channel_type" */
export type Channel_Type_Aggregate = {
  __typename?: 'channel_type_aggregate';
  aggregate?: Maybe<Channel_Type_Aggregate_Fields>;
  nodes: Array<Channel_Type>;
};

/** aggregate fields of "channel_type" */
export type Channel_Type_Aggregate_Fields = {
  __typename?: 'channel_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Channel_Type_Max_Fields>;
  min?: Maybe<Channel_Type_Min_Fields>;
};


/** aggregate fields of "channel_type" */
export type Channel_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "channel_type". All fields are combined with a logical 'AND'. */
export type Channel_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Type_Bool_Exp>>;
  _not?: InputMaybe<Channel_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_type" */
export enum Channel_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  ChannelTypePkey = 'channel_type_pkey'
}

export enum Channel_Type_Enum {
  /** 6 */
  Facebook = 'Facebook',
  /** 2 */
  Instagram = 'Instagram',
  /** 9 */
  Newsletter = 'Newsletter',
  /** 10 */
  Other = 'Other',
  /** 8 */
  Site = 'Site',
  /** 5 */
  Substack = 'Substack',
  /** 4 */
  TikTok = 'TikTok',
  /** 3 */
  Twitch = 'Twitch',
  /** 7 */
  Twitter = 'Twitter',
  /** 1 */
  YouTube = 'YouTube'
}

/** Boolean expression to compare columns of type "channel_type_enum". All fields are combined with logical 'AND'. */
export type Channel_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Channel_Type_Enum>;
  _in?: InputMaybe<Array<Channel_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Channel_Type_Enum>;
  _nin?: InputMaybe<Array<Channel_Type_Enum>>;
};

/** input type for inserting data into table "channel_type" */
export type Channel_Type_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Channel_Type_Max_Fields = {
  __typename?: 'channel_type_max_fields';
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Channel_Type_Min_Fields = {
  __typename?: 'channel_type_min_fields';
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "channel_type" */
export type Channel_Type_Mutation_Response = {
  __typename?: 'channel_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Type>;
};

/** input type for inserting object relation for remote table "channel_type" */
export type Channel_Type_Obj_Rel_Insert_Input = {
  data: Channel_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Channel_Type_On_Conflict>;
};

/** on_conflict condition type for table "channel_type" */
export type Channel_Type_On_Conflict = {
  constraint: Channel_Type_Constraint;
  update_columns?: Array<Channel_Type_Update_Column>;
  where?: InputMaybe<Channel_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_type". */
export type Channel_Type_Order_By = {
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_type */
export type Channel_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "channel_type" */
export enum Channel_Type_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order'
}

/** input type for updating data in table "channel_type" */
export type Channel_Type_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "channel_type" */
export type Channel_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Type_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

/** update columns of table "channel_type" */
export enum Channel_Type_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order'
}

export type Channel_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channel_Type_Bool_Exp;
};

/** columns and relationships of "content_monetization" */
export type Content_Monetization = {
  __typename?: 'content_monetization';
  monetization_code: Scalars['String'];
  monetization_value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "content_monetization" */
export type Content_Monetization_Aggregate = {
  __typename?: 'content_monetization_aggregate';
  aggregate?: Maybe<Content_Monetization_Aggregate_Fields>;
  nodes: Array<Content_Monetization>;
};

/** aggregate fields of "content_monetization" */
export type Content_Monetization_Aggregate_Fields = {
  __typename?: 'content_monetization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Content_Monetization_Max_Fields>;
  min?: Maybe<Content_Monetization_Min_Fields>;
};


/** aggregate fields of "content_monetization" */
export type Content_Monetization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Monetization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "content_monetization". All fields are combined with a logical 'AND'. */
export type Content_Monetization_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Monetization_Bool_Exp>>;
  _not?: InputMaybe<Content_Monetization_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Monetization_Bool_Exp>>;
  monetization_code?: InputMaybe<String_Comparison_Exp>;
  monetization_value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content_monetization" */
export enum Content_Monetization_Constraint {
  /** unique or primary key constraint on columns "monetization_code" */
  ContentMonetizationPkey = 'content_monetization_pkey'
}

/** input type for inserting data into table "content_monetization" */
export type Content_Monetization_Insert_Input = {
  monetization_code?: InputMaybe<Scalars['String']>;
  monetization_value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Content_Monetization_Max_Fields = {
  __typename?: 'content_monetization_max_fields';
  monetization_code?: Maybe<Scalars['String']>;
  monetization_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Content_Monetization_Min_Fields = {
  __typename?: 'content_monetization_min_fields';
  monetization_code?: Maybe<Scalars['String']>;
  monetization_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "content_monetization" */
export type Content_Monetization_Mutation_Response = {
  __typename?: 'content_monetization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Monetization>;
};

/** on_conflict condition type for table "content_monetization" */
export type Content_Monetization_On_Conflict = {
  constraint: Content_Monetization_Constraint;
  update_columns?: Array<Content_Monetization_Update_Column>;
  where?: InputMaybe<Content_Monetization_Bool_Exp>;
};

/** Ordering options when selecting data from "content_monetization". */
export type Content_Monetization_Order_By = {
  monetization_code?: InputMaybe<Order_By>;
  monetization_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content_monetization */
export type Content_Monetization_Pk_Columns_Input = {
  monetization_code: Scalars['String'];
};

/** select columns of table "content_monetization" */
export enum Content_Monetization_Select_Column {
  /** column name */
  MonetizationCode = 'monetization_code',
  /** column name */
  MonetizationValue = 'monetization_value'
}

/** input type for updating data in table "content_monetization" */
export type Content_Monetization_Set_Input = {
  monetization_code?: InputMaybe<Scalars['String']>;
  monetization_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "content_monetization" */
export type Content_Monetization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Monetization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Monetization_Stream_Cursor_Value_Input = {
  monetization_code?: InputMaybe<Scalars['String']>;
  monetization_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "content_monetization" */
export enum Content_Monetization_Update_Column {
  /** column name */
  MonetizationCode = 'monetization_code',
  /** column name */
  MonetizationValue = 'monetization_value'
}

export type Content_Monetization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Monetization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Monetization_Bool_Exp;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  country_code: Scalars['String'];
  country_name: Scalars['String'];
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  country_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "country_code" */
  CountryPkey = 'country_pkey'
}

export enum Country_Enum {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos [Keeling] Islands */
  Cc = 'CC',
  /** Democratic Republic of the Congo */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Republic of the Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Ivory Coast */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cape Verde */
  Cv = 'CV',
  /** Curacao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands */
  Fk = 'FK',
  /** Micronesia */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar [Burma] */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn Islands */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suricomment */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** São Tomé and Príncipe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Swaziland */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** East Timor */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** U.S. Minor Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vatican City */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** British Virgin Islands */
  Vg = 'VG',
  /** U.S. Virgin Islands */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Kosovo */
  Xk = 'XK',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** Boolean expression to compare columns of type "country_enum". All fields are combined with logical 'AND'. */
export type Country_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Country_Enum>;
  _in?: InputMaybe<Array<Country_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Country_Enum>;
  _nin?: InputMaybe<Array<Country_Enum>>;
};

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  country_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  country_code?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  country_code: Scalars['String'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CountryName = 'country_name'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  country_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  country_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CountryName = 'country_name'
}

export type Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** columns and relationships of "creator_prospect" */
export type Creator_Prospect = {
  __typename?: 'creator_prospect';
  annual_revenue_usd?: Maybe<Scalars['numeric']>;
  business_name?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  country: Country_Enum;
  /** An array relationship */
  creatorProspectCategories: Array<Creator_Prospect_Categories>;
  /** An aggregate relationship */
  creatorProspectCategories_aggregate: Creator_Prospect_Categories_Aggregate;
  /** An array relationship */
  creatorProspectChannels: Array<Creator_Prospect_Channels>;
  /** An aggregate relationship */
  creatorProspectChannels_aggregate: Creator_Prospect_Channels_Aggregate;
  /** An array relationship */
  creatorProspectContents: Array<Creator_Prospect_Content>;
  /** An aggregate relationship */
  creatorProspectContents_aggregate: Creator_Prospect_Content_Aggregate;
  /** An array relationship */
  creatorProspectInterests: Array<Creator_Prospect_Interests>;
  /** An aggregate relationship */
  creatorProspectInterests_aggregate: Creator_Prospect_Interests_Aggregate;
  /** An array relationship */
  creatorProspectNotes: Array<Creator_Prospect_Notes>;
  /** An aggregate relationship */
  creatorProspectNotes_aggregate: Creator_Prospect_Notes_Aggregate;
  datetime_created: Scalars['timestamptz'];
  datetime_updated: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  fit_grade?: Maybe<Fit_Grade_Enum>;
  /** A computed field, executes function "prospect_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_enabled: Scalars['Boolean'];
  is_monetized?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  prospectCountry: Country;
  /** An object relationship */
  prospectStatus: Prospect_Status;
  prospect_status: Prospect_Status_Enum;
  prospect_status_reason?: Maybe<Scalars['String']>;
  referral_code: Scalars['String'];
  referral_type: Referral_Type_Enum;
  source?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  uid_updated?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Categories_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Categories_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectChannelsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Channels_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Channels_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectContentsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Content_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Content_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectInterestsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Interests_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectInterests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Interests_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectNotesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Notes_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};


/** columns and relationships of "creator_prospect" */
export type Creator_ProspectCreatorProspectNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Notes_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};

/** aggregated selection of "creator_prospect" */
export type Creator_Prospect_Aggregate = {
  __typename?: 'creator_prospect_aggregate';
  aggregate?: Maybe<Creator_Prospect_Aggregate_Fields>;
  nodes: Array<Creator_Prospect>;
};

/** aggregate fields of "creator_prospect" */
export type Creator_Prospect_Aggregate_Fields = {
  __typename?: 'creator_prospect_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Max_Fields>;
  min?: Maybe<Creator_Prospect_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Variance_Fields>;
};


/** aggregate fields of "creator_prospect" */
export type Creator_Prospect_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Avg_Fields = {
  __typename?: 'creator_prospect_avg_fields';
  annual_revenue_usd?: Maybe<Scalars['Float']>;
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "creator_prospect". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Bool_Exp>>;
  annual_revenue_usd?: InputMaybe<Numeric_Comparison_Exp>;
  business_name?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  country?: InputMaybe<Country_Enum_Comparison_Exp>;
  creatorProspectCategories?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
  creatorProspectCategories_aggregate?: InputMaybe<Creator_Prospect_Categories_Aggregate_Bool_Exp>;
  creatorProspectChannels?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
  creatorProspectChannels_aggregate?: InputMaybe<Creator_Prospect_Channels_Aggregate_Bool_Exp>;
  creatorProspectContents?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
  creatorProspectContents_aggregate?: InputMaybe<Creator_Prospect_Content_Aggregate_Bool_Exp>;
  creatorProspectInterests?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
  creatorProspectInterests_aggregate?: InputMaybe<Creator_Prospect_Interests_Aggregate_Bool_Exp>;
  creatorProspectNotes?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
  creatorProspectNotes_aggregate?: InputMaybe<Creator_Prospect_Notes_Aggregate_Bool_Exp>;
  datetime_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  fit_grade?: InputMaybe<Fit_Grade_Enum_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_monetized?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  prospectCountry?: InputMaybe<Country_Bool_Exp>;
  prospectStatus?: InputMaybe<Prospect_Status_Bool_Exp>;
  prospect_status?: InputMaybe<Prospect_Status_Enum_Comparison_Exp>;
  prospect_status_reason?: InputMaybe<String_Comparison_Exp>;
  referral_code?: InputMaybe<String_Comparison_Exp>;
  referral_type?: InputMaybe<Referral_Type_Enum_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  story?: InputMaybe<String_Comparison_Exp>;
  uid_updated?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "creator_prospect_categories" */
export type Creator_Prospect_Categories = {
  __typename?: 'creator_prospect_categories';
  category_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  creator_prospect: Creator_Prospect;
  creator_prospect_id: Scalars['Int'];
  datetime_created: Scalars['timestamptz'];
  datetime_updated: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_enabled: Scalars['Boolean'];
  is_primary: Scalars['Boolean'];
  other_category?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "creator_prospect_categories" */
export type Creator_Prospect_Categories_Aggregate = {
  __typename?: 'creator_prospect_categories_aggregate';
  aggregate?: Maybe<Creator_Prospect_Categories_Aggregate_Fields>;
  nodes: Array<Creator_Prospect_Categories>;
};

export type Creator_Prospect_Categories_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Creator_Prospect_Categories_Aggregate_Bool_Exp_Count>;
};

export type Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_And = {
  arguments: Creator_Prospect_Categories_Select_Column_Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Creator_Prospect_Categories_Select_Column_Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "creator_prospect_categories" */
export type Creator_Prospect_Categories_Aggregate_Fields = {
  __typename?: 'creator_prospect_categories_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Categories_Max_Fields>;
  min?: Maybe<Creator_Prospect_Categories_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Categories_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Categories_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Categories_Variance_Fields>;
};


/** aggregate fields of "creator_prospect_categories" */
export type Creator_Prospect_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Aggregate_Order_By = {
  avg?: InputMaybe<Creator_Prospect_Categories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Creator_Prospect_Categories_Max_Order_By>;
  min?: InputMaybe<Creator_Prospect_Categories_Min_Order_By>;
  stddev?: InputMaybe<Creator_Prospect_Categories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Creator_Prospect_Categories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Creator_Prospect_Categories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Creator_Prospect_Categories_Sum_Order_By>;
  var_pop?: InputMaybe<Creator_Prospect_Categories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Creator_Prospect_Categories_Var_Samp_Order_By>;
  variance?: InputMaybe<Creator_Prospect_Categories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Arr_Rel_Insert_Input = {
  data: Array<Creator_Prospect_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_Categories_On_Conflict>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Categories_Avg_Fields = {
  __typename?: 'creator_prospect_categories_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "creator_prospect_categories". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Categories_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Categories_Bool_Exp>>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  creator_prospect?: InputMaybe<Creator_Prospect_Bool_Exp>;
  creator_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  datetime_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  other_category?: InputMaybe<String_Comparison_Exp>;
  uid_created?: InputMaybe<Int_Comparison_Exp>;
  uid_updated?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "creator_prospect_categories" */
export enum Creator_Prospect_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreatorProspectCategoriesPkey = 'creator_prospect_categories_pkey'
}

/** input type for incrementing numeric columns in table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Insert_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  creator_prospect?: InputMaybe<Creator_Prospect_Obj_Rel_Insert_Input>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  other_category?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Creator_Prospect_Categories_Max_Fields = {
  __typename?: 'creator_prospect_categories_max_fields';
  category_id?: Maybe<Scalars['Int']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  other_category?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  other_category?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Creator_Prospect_Categories_Min_Fields = {
  __typename?: 'creator_prospect_categories_min_fields';
  category_id?: Maybe<Scalars['Int']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  other_category?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  other_category?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Mutation_Response = {
  __typename?: 'creator_prospect_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect_Categories>;
};

/** on_conflict condition type for table "creator_prospect_categories" */
export type Creator_Prospect_Categories_On_Conflict = {
  constraint: Creator_Prospect_Categories_Constraint;
  update_columns?: Array<Creator_Prospect_Categories_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect_categories". */
export type Creator_Prospect_Categories_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect?: InputMaybe<Creator_Prospect_Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  is_primary?: InputMaybe<Order_By>;
  other_category?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect_categories */
export type Creator_Prospect_Categories_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "creator_prospect_categories" */
export enum Creator_Prospect_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  OtherCategory = 'other_category',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

/** select "creator_prospect_categories_aggregate_bool_exp_bool_and_arguments_columns" columns of table "creator_prospect_categories" */
export enum Creator_Prospect_Categories_Select_Column_Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary'
}

/** select "creator_prospect_categories_aggregate_bool_exp_bool_or_arguments_columns" columns of table "creator_prospect_categories" */
export enum Creator_Prospect_Categories_Select_Column_Creator_Prospect_Categories_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary'
}

/** input type for updating data in table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Set_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  other_category?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Categories_Stddev_Fields = {
  __typename?: 'creator_prospect_categories_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Categories_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_categories_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Categories_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_categories_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Categories_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  other_category?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Categories_Sum_Fields = {
  __typename?: 'creator_prospect_categories_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** update columns of table "creator_prospect_categories" */
export enum Creator_Prospect_Categories_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  OtherCategory = 'other_category',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

export type Creator_Prospect_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Categories_Var_Pop_Fields = {
  __typename?: 'creator_prospect_categories_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Categories_Var_Samp_Fields = {
  __typename?: 'creator_prospect_categories_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Categories_Variance_Fields = {
  __typename?: 'creator_prospect_categories_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "creator_prospect_categories" */
export type Creator_Prospect_Categories_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** columns and relationships of "creator_prospect_channels" */
export type Creator_Prospect_Channels = {
  __typename?: 'creator_prospect_channels';
  audience_size?: Maybe<Scalars['Int']>;
  /** An object relationship */
  channelType: Channel_Type;
  channel_handle: Scalars['String'];
  channel_type: Channel_Type_Enum;
  /** An object relationship */
  creatorProspect: Creator_Prospect;
  creator_prospect_id: Scalars['Int'];
  /** An array relationship */
  creator_prospect_phyllo_profiles: Array<Creator_Prospect_Phyllo_Profiles>;
  /** An aggregate relationship */
  creator_prospect_phyllo_profiles_aggregate: Creator_Prospect_Phyllo_Profiles_Aggregate;
  datetime_created: Scalars['timestamptz'];
  datetime_updated: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_enabled: Scalars['Boolean'];
  is_primary: Scalars['Boolean'];
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "creator_prospect_channels" */
export type Creator_Prospect_ChannelsCreator_Prospect_Phyllo_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};


/** columns and relationships of "creator_prospect_channels" */
export type Creator_Prospect_ChannelsCreator_Prospect_Phyllo_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};

/** aggregated selection of "creator_prospect_channels" */
export type Creator_Prospect_Channels_Aggregate = {
  __typename?: 'creator_prospect_channels_aggregate';
  aggregate?: Maybe<Creator_Prospect_Channels_Aggregate_Fields>;
  nodes: Array<Creator_Prospect_Channels>;
};

export type Creator_Prospect_Channels_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Creator_Prospect_Channels_Aggregate_Bool_Exp_Count>;
};

export type Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_And = {
  arguments: Creator_Prospect_Channels_Select_Column_Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Creator_Prospect_Channels_Select_Column_Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Channels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "creator_prospect_channels" */
export type Creator_Prospect_Channels_Aggregate_Fields = {
  __typename?: 'creator_prospect_channels_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Channels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Channels_Max_Fields>;
  min?: Maybe<Creator_Prospect_Channels_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Channels_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Channels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Channels_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Channels_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Channels_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Channels_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Channels_Variance_Fields>;
};


/** aggregate fields of "creator_prospect_channels" */
export type Creator_Prospect_Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Aggregate_Order_By = {
  avg?: InputMaybe<Creator_Prospect_Channels_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Creator_Prospect_Channels_Max_Order_By>;
  min?: InputMaybe<Creator_Prospect_Channels_Min_Order_By>;
  stddev?: InputMaybe<Creator_Prospect_Channels_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Creator_Prospect_Channels_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Creator_Prospect_Channels_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Creator_Prospect_Channels_Sum_Order_By>;
  var_pop?: InputMaybe<Creator_Prospect_Channels_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Creator_Prospect_Channels_Var_Samp_Order_By>;
  variance?: InputMaybe<Creator_Prospect_Channels_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Arr_Rel_Insert_Input = {
  data: Array<Creator_Prospect_Channels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_Channels_On_Conflict>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Channels_Avg_Fields = {
  __typename?: 'creator_prospect_channels_avg_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Avg_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "creator_prospect_channels". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Channels_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Channels_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Channels_Bool_Exp>>;
  audience_size?: InputMaybe<Int_Comparison_Exp>;
  channelType?: InputMaybe<Channel_Type_Bool_Exp>;
  channel_handle?: InputMaybe<String_Comparison_Exp>;
  channel_type?: InputMaybe<Channel_Type_Enum_Comparison_Exp>;
  creatorProspect?: InputMaybe<Creator_Prospect_Bool_Exp>;
  creator_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  creator_prospect_phyllo_profiles?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  creator_prospect_phyllo_profiles_aggregate?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp>;
  datetime_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  uid_created?: InputMaybe<Int_Comparison_Exp>;
  uid_updated?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "creator_prospect_channels" */
export enum Creator_Prospect_Channels_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreatorProspectChannelsPkey = 'creator_prospect_channels_pkey'
}

/** input type for incrementing numeric columns in table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Inc_Input = {
  audience_size?: InputMaybe<Scalars['Int']>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Insert_Input = {
  audience_size?: InputMaybe<Scalars['Int']>;
  channelType?: InputMaybe<Channel_Type_Obj_Rel_Insert_Input>;
  channel_handle?: InputMaybe<Scalars['String']>;
  channel_type?: InputMaybe<Channel_Type_Enum>;
  creatorProspect?: InputMaybe<Creator_Prospect_Obj_Rel_Insert_Input>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  creator_prospect_phyllo_profiles?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Arr_Rel_Insert_Input>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Creator_Prospect_Channels_Max_Fields = {
  __typename?: 'creator_prospect_channels_max_fields';
  audience_size?: Maybe<Scalars['Int']>;
  channel_handle?: Maybe<Scalars['String']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Max_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  channel_handle?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Creator_Prospect_Channels_Min_Fields = {
  __typename?: 'creator_prospect_channels_min_fields';
  audience_size?: Maybe<Scalars['Int']>;
  channel_handle?: Maybe<Scalars['String']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Min_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  channel_handle?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Mutation_Response = {
  __typename?: 'creator_prospect_channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect_Channels>;
};

/** input type for inserting object relation for remote table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Obj_Rel_Insert_Input = {
  data: Creator_Prospect_Channels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_Channels_On_Conflict>;
};

/** on_conflict condition type for table "creator_prospect_channels" */
export type Creator_Prospect_Channels_On_Conflict = {
  constraint: Creator_Prospect_Channels_Constraint;
  update_columns?: Array<Creator_Prospect_Channels_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect_channels". */
export type Creator_Prospect_Channels_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  channelType?: InputMaybe<Channel_Type_Order_By>;
  channel_handle?: InputMaybe<Order_By>;
  channel_type?: InputMaybe<Order_By>;
  creatorProspect?: InputMaybe<Creator_Prospect_Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  creator_prospect_phyllo_profiles_aggregate?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  is_primary?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect_channels */
export type Creator_Prospect_Channels_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "creator_prospect_channels" */
export enum Creator_Prospect_Channels_Select_Column {
  /** column name */
  AudienceSize = 'audience_size',
  /** column name */
  ChannelHandle = 'channel_handle',
  /** column name */
  ChannelType = 'channel_type',
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

/** select "creator_prospect_channels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "creator_prospect_channels" */
export enum Creator_Prospect_Channels_Select_Column_Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary'
}

/** select "creator_prospect_channels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "creator_prospect_channels" */
export enum Creator_Prospect_Channels_Select_Column_Creator_Prospect_Channels_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary'
}

/** input type for updating data in table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Set_Input = {
  audience_size?: InputMaybe<Scalars['Int']>;
  channel_handle?: InputMaybe<Scalars['String']>;
  channel_type?: InputMaybe<Channel_Type_Enum>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Channels_Stddev_Fields = {
  __typename?: 'creator_prospect_channels_stddev_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Stddev_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Channels_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_channels_stddev_pop_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Stddev_Pop_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Channels_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_channels_stddev_samp_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Stddev_Samp_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Channels_Stream_Cursor_Value_Input = {
  audience_size?: InputMaybe<Scalars['Int']>;
  channel_handle?: InputMaybe<Scalars['String']>;
  channel_type?: InputMaybe<Channel_Type_Enum>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Channels_Sum_Fields = {
  __typename?: 'creator_prospect_channels_sum_fields';
  audience_size?: Maybe<Scalars['Int']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Sum_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** update columns of table "creator_prospect_channels" */
export enum Creator_Prospect_Channels_Update_Column {
  /** column name */
  AudienceSize = 'audience_size',
  /** column name */
  ChannelHandle = 'channel_handle',
  /** column name */
  ChannelType = 'channel_type',
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

export type Creator_Prospect_Channels_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Channels_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Channels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Channels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Channels_Var_Pop_Fields = {
  __typename?: 'creator_prospect_channels_var_pop_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Var_Pop_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Channels_Var_Samp_Fields = {
  __typename?: 'creator_prospect_channels_var_samp_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Var_Samp_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Channels_Variance_Fields = {
  __typename?: 'creator_prospect_channels_variance_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "creator_prospect_channels" */
export type Creator_Prospect_Channels_Variance_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "creator_prospect" */
export enum Creator_Prospect_Constraint {
  /** unique or primary key constraint on columns "email" */
  CreatorProspectEmailKey = 'creator_prospect_email_key',
  /** unique or primary key constraint on columns "id" */
  CreatorProspectPkey = 'creator_prospect_pkey'
}

/** columns and relationships of "creator_prospect_content" */
export type Creator_Prospect_Content = {
  __typename?: 'creator_prospect_content';
  content_url: Scalars['String'];
  /** An object relationship */
  creatorProspect: Creator_Prospect;
  creator_prospect_id: Scalars['Int'];
  datetime_created: Scalars['timestamp'];
  datetime_updated: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_enabled: Scalars['Boolean'];
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "creator_prospect_content" */
export type Creator_Prospect_Content_Aggregate = {
  __typename?: 'creator_prospect_content_aggregate';
  aggregate?: Maybe<Creator_Prospect_Content_Aggregate_Fields>;
  nodes: Array<Creator_Prospect_Content>;
};

export type Creator_Prospect_Content_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Creator_Prospect_Content_Aggregate_Bool_Exp_Count>;
};

export type Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_And = {
  arguments: Creator_Prospect_Content_Select_Column_Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Creator_Prospect_Content_Select_Column_Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Content_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "creator_prospect_content" */
export type Creator_Prospect_Content_Aggregate_Fields = {
  __typename?: 'creator_prospect_content_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Content_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Content_Max_Fields>;
  min?: Maybe<Creator_Prospect_Content_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Content_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Content_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Content_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Content_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Content_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Content_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Content_Variance_Fields>;
};


/** aggregate fields of "creator_prospect_content" */
export type Creator_Prospect_Content_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "creator_prospect_content" */
export type Creator_Prospect_Content_Aggregate_Order_By = {
  avg?: InputMaybe<Creator_Prospect_Content_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Creator_Prospect_Content_Max_Order_By>;
  min?: InputMaybe<Creator_Prospect_Content_Min_Order_By>;
  stddev?: InputMaybe<Creator_Prospect_Content_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Creator_Prospect_Content_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Creator_Prospect_Content_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Creator_Prospect_Content_Sum_Order_By>;
  var_pop?: InputMaybe<Creator_Prospect_Content_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Creator_Prospect_Content_Var_Samp_Order_By>;
  variance?: InputMaybe<Creator_Prospect_Content_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "creator_prospect_content" */
export type Creator_Prospect_Content_Arr_Rel_Insert_Input = {
  data: Array<Creator_Prospect_Content_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_Content_On_Conflict>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Content_Avg_Fields = {
  __typename?: 'creator_prospect_content_avg_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Avg_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "creator_prospect_content". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Content_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Content_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Content_Bool_Exp>>;
  content_url?: InputMaybe<String_Comparison_Exp>;
  creatorProspect?: InputMaybe<Creator_Prospect_Bool_Exp>;
  creator_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  datetime_created?: InputMaybe<Timestamp_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  uid_created?: InputMaybe<Int_Comparison_Exp>;
  uid_updated?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "creator_prospect_content" */
export enum Creator_Prospect_Content_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreatorProspectContentPkey = 'creator_prospect_content_pkey'
}

/** input type for incrementing numeric columns in table "creator_prospect_content" */
export type Creator_Prospect_Content_Inc_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect_content" */
export type Creator_Prospect_Content_Insert_Input = {
  content_url?: InputMaybe<Scalars['String']>;
  creatorProspect?: InputMaybe<Creator_Prospect_Obj_Rel_Insert_Input>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamp']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Creator_Prospect_Content_Max_Fields = {
  __typename?: 'creator_prospect_content_max_fields';
  content_url?: Maybe<Scalars['String']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamp']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Max_Order_By = {
  content_url?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Creator_Prospect_Content_Min_Fields = {
  __typename?: 'creator_prospect_content_min_fields';
  content_url?: Maybe<Scalars['String']>;
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamp']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Min_Order_By = {
  content_url?: InputMaybe<Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "creator_prospect_content" */
export type Creator_Prospect_Content_Mutation_Response = {
  __typename?: 'creator_prospect_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect_Content>;
};

/** on_conflict condition type for table "creator_prospect_content" */
export type Creator_Prospect_Content_On_Conflict = {
  constraint: Creator_Prospect_Content_Constraint;
  update_columns?: Array<Creator_Prospect_Content_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect_content". */
export type Creator_Prospect_Content_Order_By = {
  content_url?: InputMaybe<Order_By>;
  creatorProspect?: InputMaybe<Creator_Prospect_Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect_content */
export type Creator_Prospect_Content_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "creator_prospect_content" */
export enum Creator_Prospect_Content_Select_Column {
  /** column name */
  ContentUrl = 'content_url',
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

/** select "creator_prospect_content_aggregate_bool_exp_bool_and_arguments_columns" columns of table "creator_prospect_content" */
export enum Creator_Prospect_Content_Select_Column_Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled'
}

/** select "creator_prospect_content_aggregate_bool_exp_bool_or_arguments_columns" columns of table "creator_prospect_content" */
export enum Creator_Prospect_Content_Select_Column_Creator_Prospect_Content_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled'
}

/** input type for updating data in table "creator_prospect_content" */
export type Creator_Prospect_Content_Set_Input = {
  content_url?: InputMaybe<Scalars['String']>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamp']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Content_Stddev_Fields = {
  __typename?: 'creator_prospect_content_stddev_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Stddev_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Content_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_content_stddev_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Stddev_Pop_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Content_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_content_stddev_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Stddev_Samp_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "creator_prospect_content" */
export type Creator_Prospect_Content_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Content_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Content_Stream_Cursor_Value_Input = {
  content_url?: InputMaybe<Scalars['String']>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamp']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Content_Sum_Fields = {
  __typename?: 'creator_prospect_content_sum_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Sum_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** update columns of table "creator_prospect_content" */
export enum Creator_Prospect_Content_Update_Column {
  /** column name */
  ContentUrl = 'content_url',
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

export type Creator_Prospect_Content_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Content_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Content_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Content_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Content_Var_Pop_Fields = {
  __typename?: 'creator_prospect_content_var_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Var_Pop_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Content_Var_Samp_Fields = {
  __typename?: 'creator_prospect_content_var_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Var_Samp_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Content_Variance_Fields = {
  __typename?: 'creator_prospect_content_variance_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "creator_prospect_content" */
export type Creator_Prospect_Content_Variance_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** columns and relationships of "creator_prospect_flags" */
export type Creator_Prospect_Flags = {
  __typename?: 'creator_prospect_flags';
  creator_prospect_id: Scalars['Int'];
  datetime_created: Scalars['timestamp'];
  datetime_updated: Scalars['timestamptz'];
  flagged_by?: Maybe<Scalars['Int']>;
  flagged_reason?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_enabled?: Maybe<Scalars['Boolean']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "creator_prospect_flags" */
export type Creator_Prospect_Flags_Aggregate = {
  __typename?: 'creator_prospect_flags_aggregate';
  aggregate?: Maybe<Creator_Prospect_Flags_Aggregate_Fields>;
  nodes: Array<Creator_Prospect_Flags>;
};

/** aggregate fields of "creator_prospect_flags" */
export type Creator_Prospect_Flags_Aggregate_Fields = {
  __typename?: 'creator_prospect_flags_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Flags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Flags_Max_Fields>;
  min?: Maybe<Creator_Prospect_Flags_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Flags_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Flags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Flags_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Flags_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Flags_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Flags_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Flags_Variance_Fields>;
};


/** aggregate fields of "creator_prospect_flags" */
export type Creator_Prospect_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Flags_Avg_Fields = {
  __typename?: 'creator_prospect_flags_avg_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  flagged_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "creator_prospect_flags". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Flags_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Flags_Bool_Exp>>;
  creator_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  datetime_created?: InputMaybe<Timestamp_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  flagged_by?: InputMaybe<Int_Comparison_Exp>;
  flagged_reason?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  uid_created?: InputMaybe<Int_Comparison_Exp>;
  uid_updated?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "creator_prospect_flags" */
export enum Creator_Prospect_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreatorProspectFlagsPkey = 'creator_prospect_flags_pkey'
}

/** input type for incrementing numeric columns in table "creator_prospect_flags" */
export type Creator_Prospect_Flags_Inc_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  flagged_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect_flags" */
export type Creator_Prospect_Flags_Insert_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamp']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  flagged_by?: InputMaybe<Scalars['Int']>;
  flagged_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Creator_Prospect_Flags_Max_Fields = {
  __typename?: 'creator_prospect_flags_max_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamp']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  flagged_by?: Maybe<Scalars['Int']>;
  flagged_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Creator_Prospect_Flags_Min_Fields = {
  __typename?: 'creator_prospect_flags_min_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamp']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  flagged_by?: Maybe<Scalars['Int']>;
  flagged_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "creator_prospect_flags" */
export type Creator_Prospect_Flags_Mutation_Response = {
  __typename?: 'creator_prospect_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect_Flags>;
};

/** on_conflict condition type for table "creator_prospect_flags" */
export type Creator_Prospect_Flags_On_Conflict = {
  constraint: Creator_Prospect_Flags_Constraint;
  update_columns?: Array<Creator_Prospect_Flags_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect_flags". */
export type Creator_Prospect_Flags_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  flagged_by?: InputMaybe<Order_By>;
  flagged_reason?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect_flags */
export type Creator_Prospect_Flags_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "creator_prospect_flags" */
export enum Creator_Prospect_Flags_Select_Column {
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  FlaggedBy = 'flagged_by',
  /** column name */
  FlaggedReason = 'flagged_reason',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

/** input type for updating data in table "creator_prospect_flags" */
export type Creator_Prospect_Flags_Set_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamp']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  flagged_by?: InputMaybe<Scalars['Int']>;
  flagged_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Flags_Stddev_Fields = {
  __typename?: 'creator_prospect_flags_stddev_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  flagged_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Flags_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_flags_stddev_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  flagged_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Flags_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_flags_stddev_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  flagged_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "creator_prospect_flags" */
export type Creator_Prospect_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Flags_Stream_Cursor_Value_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamp']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  flagged_by?: InputMaybe<Scalars['Int']>;
  flagged_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Flags_Sum_Fields = {
  __typename?: 'creator_prospect_flags_sum_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  flagged_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** update columns of table "creator_prospect_flags" */
export enum Creator_Prospect_Flags_Update_Column {
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  FlaggedBy = 'flagged_by',
  /** column name */
  FlaggedReason = 'flagged_reason',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidUpdated = 'uid_updated'
}

export type Creator_Prospect_Flags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Flags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Flags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Flags_Var_Pop_Fields = {
  __typename?: 'creator_prospect_flags_var_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  flagged_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Flags_Var_Samp_Fields = {
  __typename?: 'creator_prospect_flags_var_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  flagged_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Flags_Variance_Fields = {
  __typename?: 'creator_prospect_flags_variance_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  flagged_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "creator_prospect" */
export type Creator_Prospect_Inc_Input = {
  annual_revenue_usd?: InputMaybe<Scalars['numeric']>;
  category_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect" */
export type Creator_Prospect_Insert_Input = {
  annual_revenue_usd?: InputMaybe<Scalars['numeric']>;
  business_name?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Country_Enum>;
  creatorProspectCategories?: InputMaybe<Creator_Prospect_Categories_Arr_Rel_Insert_Input>;
  creatorProspectChannels?: InputMaybe<Creator_Prospect_Channels_Arr_Rel_Insert_Input>;
  creatorProspectContents?: InputMaybe<Creator_Prospect_Content_Arr_Rel_Insert_Input>;
  creatorProspectInterests?: InputMaybe<Creator_Prospect_Interests_Arr_Rel_Insert_Input>;
  creatorProspectNotes?: InputMaybe<Creator_Prospect_Notes_Arr_Rel_Insert_Input>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  fit_grade?: InputMaybe<Fit_Grade_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_monetized?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  prospectCountry?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  prospectStatus?: InputMaybe<Prospect_Status_Obj_Rel_Insert_Input>;
  prospect_status?: InputMaybe<Prospect_Status_Enum>;
  prospect_status_reason?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  referral_type?: InputMaybe<Referral_Type_Enum>;
  source?: InputMaybe<Scalars['String']>;
  story?: InputMaybe<Scalars['String']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "creator_prospect_interests" */
export type Creator_Prospect_Interests = {
  __typename?: 'creator_prospect_interests';
  /** An object relationship */
  creatorProspect: Creator_Prospect;
  creator_prospect_id: Scalars['Int'];
  datetime_created: Scalars['timestamptz'];
  datetime_updated: Scalars['timestamptz'];
  id: Scalars['Int'];
  interest_id?: Maybe<Scalars['Int']>;
  interest_name?: Maybe<Scalars['String']>;
  isFuture: Scalars['Boolean'];
  is_enabled: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "creator_prospect_interests" */
export type Creator_Prospect_Interests_Aggregate = {
  __typename?: 'creator_prospect_interests_aggregate';
  aggregate?: Maybe<Creator_Prospect_Interests_Aggregate_Fields>;
  nodes: Array<Creator_Prospect_Interests>;
};

export type Creator_Prospect_Interests_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Creator_Prospect_Interests_Aggregate_Bool_Exp_Count>;
};

export type Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_And = {
  arguments: Creator_Prospect_Interests_Select_Column_Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Creator_Prospect_Interests_Select_Column_Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Interests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "creator_prospect_interests" */
export type Creator_Prospect_Interests_Aggregate_Fields = {
  __typename?: 'creator_prospect_interests_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Interests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Interests_Max_Fields>;
  min?: Maybe<Creator_Prospect_Interests_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Interests_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Interests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Interests_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Interests_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Interests_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Interests_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Interests_Variance_Fields>;
};


/** aggregate fields of "creator_prospect_interests" */
export type Creator_Prospect_Interests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Aggregate_Order_By = {
  avg?: InputMaybe<Creator_Prospect_Interests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Creator_Prospect_Interests_Max_Order_By>;
  min?: InputMaybe<Creator_Prospect_Interests_Min_Order_By>;
  stddev?: InputMaybe<Creator_Prospect_Interests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Creator_Prospect_Interests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Creator_Prospect_Interests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Creator_Prospect_Interests_Sum_Order_By>;
  var_pop?: InputMaybe<Creator_Prospect_Interests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Creator_Prospect_Interests_Var_Samp_Order_By>;
  variance?: InputMaybe<Creator_Prospect_Interests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Arr_Rel_Insert_Input = {
  data: Array<Creator_Prospect_Interests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_Interests_On_Conflict>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Interests_Avg_Fields = {
  __typename?: 'creator_prospect_interests_avg_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Avg_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "creator_prospect_interests". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Interests_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Interests_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Interests_Bool_Exp>>;
  creatorProspect?: InputMaybe<Creator_Prospect_Bool_Exp>;
  creator_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  datetime_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interest_id?: InputMaybe<Int_Comparison_Exp>;
  interest_name?: InputMaybe<String_Comparison_Exp>;
  isFuture?: InputMaybe<Boolean_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  uid_created?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "creator_prospect_interests" */
export enum Creator_Prospect_Interests_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreatorProspectInterestsPkey = 'creator_prospect_interests_pkey'
}

/** input type for incrementing numeric columns in table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Inc_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interest_id?: InputMaybe<Scalars['Int']>;
  uid_created?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Insert_Input = {
  creatorProspect?: InputMaybe<Creator_Prospect_Obj_Rel_Insert_Input>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interest_id?: InputMaybe<Scalars['Int']>;
  interest_name?: InputMaybe<Scalars['String']>;
  isFuture?: InputMaybe<Scalars['Boolean']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Creator_Prospect_Interests_Max_Fields = {
  __typename?: 'creator_prospect_interests_max_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Max_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  interest_name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Creator_Prospect_Interests_Min_Fields = {
  __typename?: 'creator_prospect_interests_min_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Min_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  interest_name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Mutation_Response = {
  __typename?: 'creator_prospect_interests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect_Interests>;
};

/** on_conflict condition type for table "creator_prospect_interests" */
export type Creator_Prospect_Interests_On_Conflict = {
  constraint: Creator_Prospect_Interests_Constraint;
  update_columns?: Array<Creator_Prospect_Interests_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect_interests". */
export type Creator_Prospect_Interests_Order_By = {
  creatorProspect?: InputMaybe<Creator_Prospect_Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  interest_name?: InputMaybe<Order_By>;
  isFuture?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect_interests */
export type Creator_Prospect_Interests_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "creator_prospect_interests" */
export enum Creator_Prospect_Interests_Select_Column {
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  InterestName = 'interest_name',
  /** column name */
  IsFuture = 'isFuture',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Note = 'note',
  /** column name */
  UidCreated = 'uid_created'
}

/** select "creator_prospect_interests_aggregate_bool_exp_bool_and_arguments_columns" columns of table "creator_prospect_interests" */
export enum Creator_Prospect_Interests_Select_Column_Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFuture = 'isFuture',
  /** column name */
  IsEnabled = 'is_enabled'
}

/** select "creator_prospect_interests_aggregate_bool_exp_bool_or_arguments_columns" columns of table "creator_prospect_interests" */
export enum Creator_Prospect_Interests_Select_Column_Creator_Prospect_Interests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFuture = 'isFuture',
  /** column name */
  IsEnabled = 'is_enabled'
}

/** input type for updating data in table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Set_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interest_id?: InputMaybe<Scalars['Int']>;
  interest_name?: InputMaybe<Scalars['String']>;
  isFuture?: InputMaybe<Scalars['Boolean']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Interests_Stddev_Fields = {
  __typename?: 'creator_prospect_interests_stddev_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Stddev_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Interests_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_interests_stddev_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Stddev_Pop_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Interests_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_interests_stddev_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Stddev_Samp_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Interests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Interests_Stream_Cursor_Value_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interest_id?: InputMaybe<Scalars['Int']>;
  interest_name?: InputMaybe<Scalars['String']>;
  isFuture?: InputMaybe<Scalars['Boolean']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Interests_Sum_Fields = {
  __typename?: 'creator_prospect_interests_sum_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  interest_id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Sum_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** update columns of table "creator_prospect_interests" */
export enum Creator_Prospect_Interests_Update_Column {
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  InterestName = 'interest_name',
  /** column name */
  IsFuture = 'isFuture',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Note = 'note',
  /** column name */
  UidCreated = 'uid_created'
}

export type Creator_Prospect_Interests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Interests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Interests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Interests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Interests_Var_Pop_Fields = {
  __typename?: 'creator_prospect_interests_var_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Var_Pop_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Interests_Var_Samp_Fields = {
  __typename?: 'creator_prospect_interests_var_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Var_Samp_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Interests_Variance_Fields = {
  __typename?: 'creator_prospect_interests_variance_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "creator_prospect_interests" */
export type Creator_Prospect_Interests_Variance_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Creator_Prospect_Max_Fields = {
  __typename?: 'creator_prospect_max_fields';
  annual_revenue_usd?: Maybe<Scalars['numeric']>;
  business_name?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  prospect_status_reason?: Maybe<Scalars['String']>;
  referral_code?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Creator_Prospect_Min_Fields = {
  __typename?: 'creator_prospect_min_fields';
  annual_revenue_usd?: Maybe<Scalars['numeric']>;
  business_name?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  prospect_status_reason?: Maybe<Scalars['String']>;
  referral_code?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "creator_prospect" */
export type Creator_Prospect_Mutation_Response = {
  __typename?: 'creator_prospect_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect>;
};

/** columns and relationships of "creator_prospect_notes" */
export type Creator_Prospect_Notes = {
  __typename?: 'creator_prospect_notes';
  /** An object relationship */
  creatorProspect: Creator_Prospect;
  creator_prospect_id: Scalars['Int'];
  datetime_created: Scalars['timestamptz'];
  datetime_updated: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_enabled: Scalars['Boolean'];
  note: Scalars['String'];
  uid_created?: Maybe<Scalars['Int']>;
  uid_created_name?: Maybe<Scalars['String']>;
  uid_updated?: Maybe<Scalars['Int']>;
  uid_updated_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "creator_prospect_notes" */
export type Creator_Prospect_Notes_Aggregate = {
  __typename?: 'creator_prospect_notes_aggregate';
  aggregate?: Maybe<Creator_Prospect_Notes_Aggregate_Fields>;
  nodes: Array<Creator_Prospect_Notes>;
};

export type Creator_Prospect_Notes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Creator_Prospect_Notes_Aggregate_Bool_Exp_Count>;
};

export type Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Creator_Prospect_Notes_Select_Column_Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Creator_Prospect_Notes_Select_Column_Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Notes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "creator_prospect_notes" */
export type Creator_Prospect_Notes_Aggregate_Fields = {
  __typename?: 'creator_prospect_notes_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Notes_Max_Fields>;
  min?: Maybe<Creator_Prospect_Notes_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Notes_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Notes_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Notes_Variance_Fields>;
};


/** aggregate fields of "creator_prospect_notes" */
export type Creator_Prospect_Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Aggregate_Order_By = {
  avg?: InputMaybe<Creator_Prospect_Notes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Creator_Prospect_Notes_Max_Order_By>;
  min?: InputMaybe<Creator_Prospect_Notes_Min_Order_By>;
  stddev?: InputMaybe<Creator_Prospect_Notes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Creator_Prospect_Notes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Creator_Prospect_Notes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Creator_Prospect_Notes_Sum_Order_By>;
  var_pop?: InputMaybe<Creator_Prospect_Notes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Creator_Prospect_Notes_Var_Samp_Order_By>;
  variance?: InputMaybe<Creator_Prospect_Notes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Arr_Rel_Insert_Input = {
  data: Array<Creator_Prospect_Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_Notes_On_Conflict>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Notes_Avg_Fields = {
  __typename?: 'creator_prospect_notes_avg_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Avg_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "creator_prospect_notes". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Notes_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Notes_Bool_Exp>>;
  creatorProspect?: InputMaybe<Creator_Prospect_Bool_Exp>;
  creator_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  datetime_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  uid_created?: InputMaybe<Int_Comparison_Exp>;
  uid_created_name?: InputMaybe<String_Comparison_Exp>;
  uid_updated?: InputMaybe<Int_Comparison_Exp>;
  uid_updated_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "creator_prospect_notes" */
export enum Creator_Prospect_Notes_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreatorProspectNotesPkey = 'creator_prospect_notes_pkey'
}

/** input type for incrementing numeric columns in table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Inc_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Insert_Input = {
  creatorProspect?: InputMaybe<Creator_Prospect_Obj_Rel_Insert_Input>;
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_created_name?: InputMaybe<Scalars['String']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
  uid_updated_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Creator_Prospect_Notes_Max_Fields = {
  __typename?: 'creator_prospect_notes_max_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_created_name?: Maybe<Scalars['String']>;
  uid_updated?: Maybe<Scalars['Int']>;
  uid_updated_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Max_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_created_name?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
  uid_updated_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Creator_Prospect_Notes_Min_Fields = {
  __typename?: 'creator_prospect_notes_min_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_created_name?: Maybe<Scalars['String']>;
  uid_updated?: Maybe<Scalars['Int']>;
  uid_updated_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Min_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_created_name?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
  uid_updated_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Mutation_Response = {
  __typename?: 'creator_prospect_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect_Notes>;
};

/** on_conflict condition type for table "creator_prospect_notes" */
export type Creator_Prospect_Notes_On_Conflict = {
  constraint: Creator_Prospect_Notes_Constraint;
  update_columns?: Array<Creator_Prospect_Notes_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect_notes". */
export type Creator_Prospect_Notes_Order_By = {
  creatorProspect?: InputMaybe<Creator_Prospect_Order_By>;
  creator_prospect_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_created_name?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
  uid_updated_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect_notes */
export type Creator_Prospect_Notes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "creator_prospect_notes" */
export enum Creator_Prospect_Notes_Select_Column {
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Note = 'note',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidCreatedName = 'uid_created_name',
  /** column name */
  UidUpdated = 'uid_updated',
  /** column name */
  UidUpdatedName = 'uid_updated_name'
}

/** select "creator_prospect_notes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "creator_prospect_notes" */
export enum Creator_Prospect_Notes_Select_Column_Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled'
}

/** select "creator_prospect_notes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "creator_prospect_notes" */
export enum Creator_Prospect_Notes_Select_Column_Creator_Prospect_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled'
}

/** input type for updating data in table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Set_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_created_name?: InputMaybe<Scalars['String']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
  uid_updated_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Notes_Stddev_Fields = {
  __typename?: 'creator_prospect_notes_stddev_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Stddev_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Notes_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_notes_stddev_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Stddev_Pop_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Notes_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_notes_stddev_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Stddev_Samp_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Notes_Stream_Cursor_Value_Input = {
  creator_prospect_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  uid_created?: InputMaybe<Scalars['Int']>;
  uid_created_name?: InputMaybe<Scalars['String']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
  uid_updated_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Notes_Sum_Fields = {
  __typename?: 'creator_prospect_notes_sum_fields';
  creator_prospect_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  uid_created?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Sum_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** update columns of table "creator_prospect_notes" */
export enum Creator_Prospect_Notes_Update_Column {
  /** column name */
  CreatorProspectId = 'creator_prospect_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Note = 'note',
  /** column name */
  UidCreated = 'uid_created',
  /** column name */
  UidCreatedName = 'uid_created_name',
  /** column name */
  UidUpdated = 'uid_updated',
  /** column name */
  UidUpdatedName = 'uid_updated_name'
}

export type Creator_Prospect_Notes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Notes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Notes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Notes_Var_Pop_Fields = {
  __typename?: 'creator_prospect_notes_var_pop_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Var_Pop_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Notes_Var_Samp_Fields = {
  __typename?: 'creator_prospect_notes_var_samp_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Var_Samp_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Notes_Variance_Fields = {
  __typename?: 'creator_prospect_notes_variance_fields';
  creator_prospect_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_created?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "creator_prospect_notes" */
export type Creator_Prospect_Notes_Variance_Order_By = {
  creator_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uid_created?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "creator_prospect" */
export type Creator_Prospect_Obj_Rel_Insert_Input = {
  data: Creator_Prospect_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_On_Conflict>;
};

/** on_conflict condition type for table "creator_prospect" */
export type Creator_Prospect_On_Conflict = {
  constraint: Creator_Prospect_Constraint;
  update_columns?: Array<Creator_Prospect_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect". */
export type Creator_Prospect_Order_By = {
  annual_revenue_usd?: InputMaybe<Order_By>;
  business_name?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  creatorProspectCategories_aggregate?: InputMaybe<Creator_Prospect_Categories_Aggregate_Order_By>;
  creatorProspectChannels_aggregate?: InputMaybe<Creator_Prospect_Channels_Aggregate_Order_By>;
  creatorProspectContents_aggregate?: InputMaybe<Creator_Prospect_Content_Aggregate_Order_By>;
  creatorProspectInterests_aggregate?: InputMaybe<Creator_Prospect_Interests_Aggregate_Order_By>;
  creatorProspectNotes_aggregate?: InputMaybe<Creator_Prospect_Notes_Aggregate_Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  fit_grade?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  is_monetized?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  prospectCountry?: InputMaybe<Country_Order_By>;
  prospectStatus?: InputMaybe<Prospect_Status_Order_By>;
  prospect_status?: InputMaybe<Order_By>;
  prospect_status_reason?: InputMaybe<Order_By>;
  referral_code?: InputMaybe<Order_By>;
  referral_type?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  story?: InputMaybe<Order_By>;
  uid_updated?: InputMaybe<Order_By>;
};

/** columns and relationships of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles = {
  __typename?: 'creator_prospect_phyllo_profiles';
  audience_countries?: Maybe<Scalars['jsonb']>;
  audience_size?: Maybe<Scalars['Int']>;
  brand_affinity?: Maybe<Scalars['jsonb']>;
  country?: Maybe<Scalars['String']>;
  /** An object relationship */
  creator_prospect_channel: Creator_Prospect_Channels;
  creator_prospect_channel_id: Scalars['Int'];
  datetime_created: Scalars['timestamptz'];
  datetime_updated: Scalars['timestamptz'];
  engagement_rate?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  interests?: Maybe<Scalars['jsonb']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  platform_username: Scalars['String'];
  profile_url: Scalars['String'];
  raw_data: Scalars['json'];
};


/** columns and relationships of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_ProfilesAudience_CountriesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_ProfilesBrand_AffinityArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_ProfilesInterestsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_ProfilesRaw_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Aggregate = {
  __typename?: 'creator_prospect_phyllo_profiles_aggregate';
  aggregate?: Maybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Fields>;
  nodes: Array<Creator_Prospect_Phyllo_Profiles>;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Var_Samp>;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Avg = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_And = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Corr = {
  arguments: Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Max = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Min = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Sum = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Aggregate_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_aggregate_fields';
  avg?: Maybe<Creator_Prospect_Phyllo_Profiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Creator_Prospect_Phyllo_Profiles_Max_Fields>;
  min?: Maybe<Creator_Prospect_Phyllo_Profiles_Min_Fields>;
  stddev?: Maybe<Creator_Prospect_Phyllo_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Creator_Prospect_Phyllo_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Creator_Prospect_Phyllo_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Creator_Prospect_Phyllo_Profiles_Sum_Fields>;
  var_pop?: Maybe<Creator_Prospect_Phyllo_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Creator_Prospect_Phyllo_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Creator_Prospect_Phyllo_Profiles_Variance_Fields>;
};


/** aggregate fields of "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Aggregate_Order_By = {
  avg?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Max_Order_By>;
  min?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Min_Order_By>;
  stddev?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Sum_Order_By>;
  var_pop?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Creator_Prospect_Phyllo_Profiles_Append_Input = {
  audience_countries?: InputMaybe<Scalars['jsonb']>;
  brand_affinity?: InputMaybe<Scalars['jsonb']>;
  interests?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Arr_Rel_Insert_Input = {
  data: Array<Creator_Prospect_Phyllo_Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Creator_Prospect_Phyllo_Profiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Creator_Prospect_Phyllo_Profiles_Avg_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_avg_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_channel_id?: Maybe<Scalars['Float']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Avg_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "creator_prospect_phyllo_profiles". All fields are combined with a logical 'AND'. */
export type Creator_Prospect_Phyllo_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Bool_Exp>>;
  audience_countries?: InputMaybe<Jsonb_Comparison_Exp>;
  audience_size?: InputMaybe<Int_Comparison_Exp>;
  brand_affinity?: InputMaybe<Jsonb_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  creator_prospect_channel?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
  creator_prospect_channel_id?: InputMaybe<Int_Comparison_Exp>;
  datetime_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  engagement_rate?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  interests?: InputMaybe<Jsonb_Comparison_Exp>;
  is_verified?: InputMaybe<Boolean_Comparison_Exp>;
  platform_username?: InputMaybe<String_Comparison_Exp>;
  profile_url?: InputMaybe<String_Comparison_Exp>;
  raw_data?: InputMaybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreatorProspectPhylloProfilesPkey = 'creator_prospect_phyllo_profiles_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Creator_Prospect_Phyllo_Profiles_Delete_At_Path_Input = {
  audience_countries?: InputMaybe<Array<Scalars['String']>>;
  brand_affinity?: InputMaybe<Array<Scalars['String']>>;
  interests?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Creator_Prospect_Phyllo_Profiles_Delete_Elem_Input = {
  audience_countries?: InputMaybe<Scalars['Int']>;
  brand_affinity?: InputMaybe<Scalars['Int']>;
  interests?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Creator_Prospect_Phyllo_Profiles_Delete_Key_Input = {
  audience_countries?: InputMaybe<Scalars['String']>;
  brand_affinity?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Inc_Input = {
  audience_size?: InputMaybe<Scalars['Int']>;
  creator_prospect_channel_id?: InputMaybe<Scalars['Int']>;
  engagement_rate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Insert_Input = {
  audience_countries?: InputMaybe<Scalars['jsonb']>;
  audience_size?: InputMaybe<Scalars['Int']>;
  brand_affinity?: InputMaybe<Scalars['jsonb']>;
  country?: InputMaybe<Scalars['String']>;
  creator_prospect_channel?: InputMaybe<Creator_Prospect_Channels_Obj_Rel_Insert_Input>;
  creator_prospect_channel_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  engagement_rate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Scalars['jsonb']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  platform_username?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  raw_data?: InputMaybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Creator_Prospect_Phyllo_Profiles_Max_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_max_fields';
  audience_size?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  creator_prospect_channel_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  engagement_rate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  platform_username?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Max_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  platform_username?: InputMaybe<Order_By>;
  profile_url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Creator_Prospect_Phyllo_Profiles_Min_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_min_fields';
  audience_size?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  creator_prospect_channel_id?: Maybe<Scalars['Int']>;
  datetime_created?: Maybe<Scalars['timestamptz']>;
  datetime_updated?: Maybe<Scalars['timestamptz']>;
  engagement_rate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  platform_username?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Min_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  platform_username?: InputMaybe<Order_By>;
  profile_url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Mutation_Response = {
  __typename?: 'creator_prospect_phyllo_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Creator_Prospect_Phyllo_Profiles>;
};

/** on_conflict condition type for table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_On_Conflict = {
  constraint: Creator_Prospect_Phyllo_Profiles_Constraint;
  update_columns?: Array<Creator_Prospect_Phyllo_Profiles_Update_Column>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "creator_prospect_phyllo_profiles". */
export type Creator_Prospect_Phyllo_Profiles_Order_By = {
  audience_countries?: InputMaybe<Order_By>;
  audience_size?: InputMaybe<Order_By>;
  brand_affinity?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  creator_prospect_channel?: InputMaybe<Creator_Prospect_Channels_Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  datetime_created?: InputMaybe<Order_By>;
  datetime_updated?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  interests?: InputMaybe<Order_By>;
  is_verified?: InputMaybe<Order_By>;
  platform_username?: InputMaybe<Order_By>;
  profile_url?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect_phyllo_profiles */
export type Creator_Prospect_Phyllo_Profiles_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Creator_Prospect_Phyllo_Profiles_Prepend_Input = {
  audience_countries?: InputMaybe<Scalars['jsonb']>;
  brand_affinity?: InputMaybe<Scalars['jsonb']>;
  interests?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column {
  /** column name */
  AudienceCountries = 'audience_countries',
  /** column name */
  AudienceSize = 'audience_size',
  /** column name */
  BrandAffinity = 'brand_affinity',
  /** column name */
  Country = 'country',
  /** column name */
  CreatorProspectChannelId = 'creator_prospect_channel_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  EngagementRate = 'engagement_rate',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Interests = 'interests',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  PlatformUsername = 'platform_username',
  /** column name */
  ProfileUrl = 'profile_url',
  /** column name */
  RawData = 'raw_data'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_avg_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsVerified = 'is_verified'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsVerified = 'is_verified'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_corr_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_max_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_min_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_sum_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** select "creator_prospect_phyllo_profiles_aggregate_bool_exp_var_samp_arguments_columns" columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Select_Column_Creator_Prospect_Phyllo_Profiles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  EngagementRate = 'engagement_rate'
}

/** input type for updating data in table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Set_Input = {
  audience_countries?: InputMaybe<Scalars['jsonb']>;
  audience_size?: InputMaybe<Scalars['Int']>;
  brand_affinity?: InputMaybe<Scalars['jsonb']>;
  country?: InputMaybe<Scalars['String']>;
  creator_prospect_channel_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  engagement_rate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Scalars['jsonb']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  platform_username?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  raw_data?: InputMaybe<Scalars['json']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Phyllo_Profiles_Stddev_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_stddev_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_channel_id?: Maybe<Scalars['Float']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Stddev_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Phyllo_Profiles_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_stddev_pop_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_channel_id?: Maybe<Scalars['Float']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Stddev_Pop_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Phyllo_Profiles_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_stddev_samp_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_channel_id?: Maybe<Scalars['Float']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Stddev_Samp_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Phyllo_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Phyllo_Profiles_Stream_Cursor_Value_Input = {
  audience_countries?: InputMaybe<Scalars['jsonb']>;
  audience_size?: InputMaybe<Scalars['Int']>;
  brand_affinity?: InputMaybe<Scalars['jsonb']>;
  country?: InputMaybe<Scalars['String']>;
  creator_prospect_channel_id?: InputMaybe<Scalars['Int']>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  engagement_rate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Scalars['jsonb']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  platform_username?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  raw_data?: InputMaybe<Scalars['json']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Phyllo_Profiles_Sum_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_sum_fields';
  audience_size?: Maybe<Scalars['Int']>;
  creator_prospect_channel_id?: Maybe<Scalars['Int']>;
  engagement_rate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Sum_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "creator_prospect_phyllo_profiles" */
export enum Creator_Prospect_Phyllo_Profiles_Update_Column {
  /** column name */
  AudienceCountries = 'audience_countries',
  /** column name */
  AudienceSize = 'audience_size',
  /** column name */
  BrandAffinity = 'brand_affinity',
  /** column name */
  Country = 'country',
  /** column name */
  CreatorProspectChannelId = 'creator_prospect_channel_id',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  EngagementRate = 'engagement_rate',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Interests = 'interests',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  PlatformUsername = 'platform_username',
  /** column name */
  ProfileUrl = 'profile_url',
  /** column name */
  RawData = 'raw_data'
}

export type Creator_Prospect_Phyllo_Profiles_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Phyllo_Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Phyllo_Profiles_Var_Pop_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_var_pop_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_channel_id?: Maybe<Scalars['Float']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Var_Pop_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Phyllo_Profiles_Var_Samp_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_var_samp_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_channel_id?: Maybe<Scalars['Float']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Var_Samp_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Phyllo_Profiles_Variance_Fields = {
  __typename?: 'creator_prospect_phyllo_profiles_variance_fields';
  audience_size?: Maybe<Scalars['Float']>;
  creator_prospect_channel_id?: Maybe<Scalars['Float']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "creator_prospect_phyllo_profiles" */
export type Creator_Prospect_Phyllo_Profiles_Variance_Order_By = {
  audience_size?: InputMaybe<Order_By>;
  creator_prospect_channel_id?: InputMaybe<Order_By>;
  engagement_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: creator_prospect */
export type Creator_Prospect_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "creator_prospect" */
export enum Creator_Prospect_Select_Column {
  /** column name */
  AnnualRevenueUsd = 'annual_revenue_usd',
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Country = 'country',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FitGrade = 'fit_grade',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsMonetized = 'is_monetized',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProspectStatus = 'prospect_status',
  /** column name */
  ProspectStatusReason = 'prospect_status_reason',
  /** column name */
  ReferralCode = 'referral_code',
  /** column name */
  ReferralType = 'referral_type',
  /** column name */
  Source = 'source',
  /** column name */
  Story = 'story',
  /** column name */
  UidUpdated = 'uid_updated'
}

/** input type for updating data in table "creator_prospect" */
export type Creator_Prospect_Set_Input = {
  annual_revenue_usd?: InputMaybe<Scalars['numeric']>;
  business_name?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Country_Enum>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  fit_grade?: InputMaybe<Fit_Grade_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_monetized?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  prospect_status?: InputMaybe<Prospect_Status_Enum>;
  prospect_status_reason?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  referral_type?: InputMaybe<Referral_Type_Enum>;
  source?: InputMaybe<Scalars['String']>;
  story?: InputMaybe<Scalars['String']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Creator_Prospect_Stddev_Fields = {
  __typename?: 'creator_prospect_stddev_fields';
  annual_revenue_usd?: Maybe<Scalars['Float']>;
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Creator_Prospect_Stddev_Pop_Fields = {
  __typename?: 'creator_prospect_stddev_pop_fields';
  annual_revenue_usd?: Maybe<Scalars['Float']>;
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Creator_Prospect_Stddev_Samp_Fields = {
  __typename?: 'creator_prospect_stddev_samp_fields';
  annual_revenue_usd?: Maybe<Scalars['Float']>;
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "creator_prospect" */
export type Creator_Prospect_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_Prospect_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Creator_Prospect_Stream_Cursor_Value_Input = {
  annual_revenue_usd?: InputMaybe<Scalars['numeric']>;
  business_name?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Country_Enum>;
  datetime_created?: InputMaybe<Scalars['timestamptz']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  fit_grade?: InputMaybe<Fit_Grade_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_monetized?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  prospect_status?: InputMaybe<Prospect_Status_Enum>;
  prospect_status_reason?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  referral_type?: InputMaybe<Referral_Type_Enum>;
  source?: InputMaybe<Scalars['String']>;
  story?: InputMaybe<Scalars['String']>;
  uid_updated?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Creator_Prospect_Sum_Fields = {
  __typename?: 'creator_prospect_sum_fields';
  annual_revenue_usd?: Maybe<Scalars['numeric']>;
  category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  uid_updated?: Maybe<Scalars['Int']>;
};

/** update columns of table "creator_prospect" */
export enum Creator_Prospect_Update_Column {
  /** column name */
  AnnualRevenueUsd = 'annual_revenue_usd',
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Country = 'country',
  /** column name */
  DatetimeCreated = 'datetime_created',
  /** column name */
  DatetimeUpdated = 'datetime_updated',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FitGrade = 'fit_grade',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsMonetized = 'is_monetized',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProspectStatus = 'prospect_status',
  /** column name */
  ProspectStatusReason = 'prospect_status_reason',
  /** column name */
  ReferralCode = 'referral_code',
  /** column name */
  ReferralType = 'referral_type',
  /** column name */
  Source = 'source',
  /** column name */
  Story = 'story',
  /** column name */
  UidUpdated = 'uid_updated'
}

export type Creator_Prospect_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Creator_Prospect_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Creator_Prospect_Set_Input>;
  /** filter the rows which have to be updated */
  where: Creator_Prospect_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Creator_Prospect_Var_Pop_Fields = {
  __typename?: 'creator_prospect_var_pop_fields';
  annual_revenue_usd?: Maybe<Scalars['Float']>;
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Creator_Prospect_Var_Samp_Fields = {
  __typename?: 'creator_prospect_var_samp_fields';
  annual_revenue_usd?: Maybe<Scalars['Float']>;
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Creator_Prospect_Variance_Fields = {
  __typename?: 'creator_prospect_variance_fields';
  annual_revenue_usd?: Maybe<Scalars['Float']>;
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uid_updated?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "fit_grade" */
export type Fit_Grade = {
  __typename?: 'fit_grade';
  fit_grade_code: Scalars['String'];
  order?: Maybe<Scalars['String']>;
};

/** aggregated selection of "fit_grade" */
export type Fit_Grade_Aggregate = {
  __typename?: 'fit_grade_aggregate';
  aggregate?: Maybe<Fit_Grade_Aggregate_Fields>;
  nodes: Array<Fit_Grade>;
};

/** aggregate fields of "fit_grade" */
export type Fit_Grade_Aggregate_Fields = {
  __typename?: 'fit_grade_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fit_Grade_Max_Fields>;
  min?: Maybe<Fit_Grade_Min_Fields>;
};


/** aggregate fields of "fit_grade" */
export type Fit_Grade_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fit_Grade_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "fit_grade". All fields are combined with a logical 'AND'. */
export type Fit_Grade_Bool_Exp = {
  _and?: InputMaybe<Array<Fit_Grade_Bool_Exp>>;
  _not?: InputMaybe<Fit_Grade_Bool_Exp>;
  _or?: InputMaybe<Array<Fit_Grade_Bool_Exp>>;
  fit_grade_code?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fit_grade" */
export enum Fit_Grade_Constraint {
  /** unique or primary key constraint on columns "fit_grade_code" */
  FitGradePkey = 'fit_grade_pkey'
}

export enum Fit_Grade_Enum {
  /** 5 */
  Excellent = 'EXCELLENT',
  /** 3 */
  Fair = 'FAIR',
  /** 4 */
  Good = 'GOOD',
  /** 1 */
  NonCreator = 'NON_CREATOR',
  /** 2 */
  Poor = 'POOR'
}

/** Boolean expression to compare columns of type "fit_grade_enum". All fields are combined with logical 'AND'. */
export type Fit_Grade_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Fit_Grade_Enum>;
  _in?: InputMaybe<Array<Fit_Grade_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Fit_Grade_Enum>;
  _nin?: InputMaybe<Array<Fit_Grade_Enum>>;
};

/** input type for inserting data into table "fit_grade" */
export type Fit_Grade_Insert_Input = {
  fit_grade_code?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fit_Grade_Max_Fields = {
  __typename?: 'fit_grade_max_fields';
  fit_grade_code?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fit_Grade_Min_Fields = {
  __typename?: 'fit_grade_min_fields';
  fit_grade_code?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fit_grade" */
export type Fit_Grade_Mutation_Response = {
  __typename?: 'fit_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fit_Grade>;
};

/** on_conflict condition type for table "fit_grade" */
export type Fit_Grade_On_Conflict = {
  constraint: Fit_Grade_Constraint;
  update_columns?: Array<Fit_Grade_Update_Column>;
  where?: InputMaybe<Fit_Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "fit_grade". */
export type Fit_Grade_Order_By = {
  fit_grade_code?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fit_grade */
export type Fit_Grade_Pk_Columns_Input = {
  fit_grade_code: Scalars['String'];
};

/** select columns of table "fit_grade" */
export enum Fit_Grade_Select_Column {
  /** column name */
  FitGradeCode = 'fit_grade_code',
  /** column name */
  Order = 'order'
}

/** input type for updating data in table "fit_grade" */
export type Fit_Grade_Set_Input = {
  fit_grade_code?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "fit_grade" */
export type Fit_Grade_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fit_Grade_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fit_Grade_Stream_Cursor_Value_Input = {
  fit_grade_code?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

/** update columns of table "fit_grade" */
export enum Fit_Grade_Update_Column {
  /** column name */
  FitGradeCode = 'fit_grade_code',
  /** column name */
  Order = 'order'
}

export type Fit_Grade_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fit_Grade_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fit_Grade_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type IsActive = {
  is_active: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type MerchList = {
  __typename?: 'merchList';
  hits: PHits;
};

export type Merchant = {
  __typename?: 'merchant';
  full_name: Scalars['String'];
  is_pilot: Scalars['Boolean'];
  logo_url?: Maybe<Scalars['String']>;
  merch_id: Scalars['Int'];
  short_name: Scalars['String'];
  show_in_dashboard: Scalars['Boolean'];
  slug: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** insert a single row into the table: "creator_prospect" */
  createCreatorProspect?: Maybe<Creator_Prospect>;
  /** insert data into the table: "creator_prospect_categories" */
  createCreatorProspectCategories?: Maybe<Creator_Prospect_Categories_Mutation_Response>;
  /** insert a single row into the table: "creator_prospect_categories" */
  createCreatorProspectCategory?: Maybe<Creator_Prospect_Categories>;
  /** insert a single row into the table: "creator_prospect_channels" */
  createCreatorProspectChannel?: Maybe<Creator_Prospect_Channels>;
  /** insert data into the table: "creator_prospect_channels" */
  createCreatorProspectChannels?: Maybe<Creator_Prospect_Channels_Mutation_Response>;
  /** insert a single row into the table: "creator_prospect_content" */
  createCreatorProspectContent?: Maybe<Creator_Prospect_Content>;
  /** insert data into the table: "creator_prospect_content" */
  createCreatorProspectContents?: Maybe<Creator_Prospect_Content_Mutation_Response>;
  /** insert a single row into the table: "creator_prospect_flags" */
  createCreatorProspectFlag?: Maybe<Creator_Prospect_Flags>;
  /** insert data into the table: "creator_prospect_flags" */
  createCreatorProspectFlags?: Maybe<Creator_Prospect_Flags_Mutation_Response>;
  /** insert a single row into the table: "creator_prospect_interests" */
  createCreatorProspectInterest?: Maybe<Creator_Prospect_Interests>;
  /** insert data into the table: "creator_prospect_interests" */
  createCreatorProspectInterests?: Maybe<Creator_Prospect_Interests_Mutation_Response>;
  /** insert a single row into the table: "creator_prospect_notes" */
  createCreatorProspectNote?: Maybe<Creator_Prospect_Notes>;
  /** insert data into the table: "creator_prospect_notes" */
  createCreatorProspectNotes?: Maybe<Creator_Prospect_Notes_Mutation_Response>;
  /** insert a single row into the table: "creator_prospect_phyllo_profiles" */
  createCreatorProspectPhylloProfile?: Maybe<Creator_Prospect_Phyllo_Profiles>;
  /** insert data into the table: "creator_prospect_phyllo_profiles" */
  createCreatorProspectPhylloProfiles?: Maybe<Creator_Prospect_Phyllo_Profiles_Mutation_Response>;
  /** insert data into the table: "creator_prospect" */
  createCreatorProspects?: Maybe<Creator_Prospect_Mutation_Response>;
  /** insert a single row into the table: "user" */
  createUser?: Maybe<User>;
  /** insert data into the table: "user" */
  createUsers?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "creator_prospect" */
  deleteCreatorProspect?: Maybe<Creator_Prospect>;
  /** delete data from the table: "creator_prospect_categories" */
  deleteCreatorProspectCategories?: Maybe<Creator_Prospect_Categories_Mutation_Response>;
  /** delete single row from the table: "creator_prospect_categories" */
  deleteCreatorProspectCategory?: Maybe<Creator_Prospect_Categories>;
  /** delete single row from the table: "creator_prospect_channels" */
  deleteCreatorProspectChannel?: Maybe<Creator_Prospect_Channels>;
  /** delete data from the table: "creator_prospect_channels" */
  deleteCreatorProspectChannels?: Maybe<Creator_Prospect_Channels_Mutation_Response>;
  /** delete single row from the table: "creator_prospect_content" */
  deleteCreatorProspectContent?: Maybe<Creator_Prospect_Content>;
  /** delete data from the table: "creator_prospect_content" */
  deleteCreatorProspectContents?: Maybe<Creator_Prospect_Content_Mutation_Response>;
  /** delete single row from the table: "creator_prospect_flags" */
  deleteCreatorProspectFlag?: Maybe<Creator_Prospect_Flags>;
  /** delete data from the table: "creator_prospect_flags" */
  deleteCreatorProspectFlags?: Maybe<Creator_Prospect_Flags_Mutation_Response>;
  /** delete single row from the table: "creator_prospect_interests" */
  deleteCreatorProspectInterest?: Maybe<Creator_Prospect_Interests>;
  /** delete data from the table: "creator_prospect_interests" */
  deleteCreatorProspectInterests?: Maybe<Creator_Prospect_Interests_Mutation_Response>;
  /** delete single row from the table: "creator_prospect_notes" */
  deleteCreatorProspectNote?: Maybe<Creator_Prospect_Notes>;
  /** delete data from the table: "creator_prospect_notes" */
  deleteCreatorProspectNotes?: Maybe<Creator_Prospect_Notes_Mutation_Response>;
  /** delete single row from the table: "creator_prospect_phyllo_profiles" */
  deleteCreatorProspectPhylloProfile?: Maybe<Creator_Prospect_Phyllo_Profiles>;
  /** delete data from the table: "creator_prospect_phyllo_profiles" */
  deleteCreatorProspectPhylloProfiles?: Maybe<Creator_Prospect_Phyllo_Profiles_Mutation_Response>;
  /** delete data from the table: "creator_prospect" */
  deleteCreatorProspects?: Maybe<Creator_Prospect_Mutation_Response>;
  /** delete single row from the table: "user" */
  deleteUser?: Maybe<User>;
  /** delete data from the table: "user" */
  deleteUsers?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "channel_type" */
  delete_channel_type?: Maybe<Channel_Type_Mutation_Response>;
  /** delete single row from the table: "channel_type" */
  delete_channel_type_by_pk?: Maybe<Channel_Type>;
  /** delete data from the table: "content_monetization" */
  delete_content_monetization?: Maybe<Content_Monetization_Mutation_Response>;
  /** delete single row from the table: "content_monetization" */
  delete_content_monetization_by_pk?: Maybe<Content_Monetization>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "fit_grade" */
  delete_fit_grade?: Maybe<Fit_Grade_Mutation_Response>;
  /** delete single row from the table: "fit_grade" */
  delete_fit_grade_by_pk?: Maybe<Fit_Grade>;
  /** delete data from the table: "prospect_status" */
  delete_prospect_status?: Maybe<Prospect_Status_Mutation_Response>;
  /** delete single row from the table: "prospect_status" */
  delete_prospect_status_by_pk?: Maybe<Prospect_Status>;
  /** delete data from the table: "referral_type" */
  delete_referral_type?: Maybe<Referral_Type_Mutation_Response>;
  /** delete single row from the table: "referral_type" */
  delete_referral_type_by_pk?: Maybe<Referral_Type>;
  /** insert data into the table: "channel_type" */
  insert_channel_type?: Maybe<Channel_Type_Mutation_Response>;
  /** insert a single row into the table: "channel_type" */
  insert_channel_type_one?: Maybe<Channel_Type>;
  /** insert data into the table: "content_monetization" */
  insert_content_monetization?: Maybe<Content_Monetization_Mutation_Response>;
  /** insert a single row into the table: "content_monetization" */
  insert_content_monetization_one?: Maybe<Content_Monetization>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "fit_grade" */
  insert_fit_grade?: Maybe<Fit_Grade_Mutation_Response>;
  /** insert a single row into the table: "fit_grade" */
  insert_fit_grade_one?: Maybe<Fit_Grade>;
  /** insert data into the table: "prospect_status" */
  insert_prospect_status?: Maybe<Prospect_Status_Mutation_Response>;
  /** insert a single row into the table: "prospect_status" */
  insert_prospect_status_one?: Maybe<Prospect_Status>;
  /** insert data into the table: "referral_type" */
  insert_referral_type?: Maybe<Referral_Type_Mutation_Response>;
  /** insert a single row into the table: "referral_type" */
  insert_referral_type_one?: Maybe<Referral_Type>;
  /** update single row of the table: "creator_prospect" */
  updateCreatorProspect?: Maybe<Creator_Prospect>;
  /** update single row of the table: "creator_prospect_categories" */
  updateCreatorProspectCategories?: Maybe<Creator_Prospect_Categories>;
  /** update data of the table: "creator_prospect_categories" */
  updateCreatorProspectCategory?: Maybe<Creator_Prospect_Categories_Mutation_Response>;
  /** update single row of the table: "creator_prospect_channels" */
  updateCreatorProspectChannel?: Maybe<Creator_Prospect_Channels>;
  /** update data of the table: "creator_prospect_channels" */
  updateCreatorProspectChannels?: Maybe<Creator_Prospect_Channels_Mutation_Response>;
  /** update single row of the table: "creator_prospect_content" */
  updateCreatorProspectContent?: Maybe<Creator_Prospect_Content>;
  /** update data of the table: "creator_prospect_content" */
  updateCreatorProspectContents?: Maybe<Creator_Prospect_Content_Mutation_Response>;
  /** update single row of the table: "creator_prospect_flags" */
  updateCreatorProspectFlag?: Maybe<Creator_Prospect_Flags>;
  /** update data of the table: "creator_prospect_flags" */
  updateCreatorProspectFlags?: Maybe<Creator_Prospect_Flags_Mutation_Response>;
  /** update single row of the table: "creator_prospect_interests" */
  updateCreatorProspectInterest?: Maybe<Creator_Prospect_Interests>;
  /** update data of the table: "creator_prospect_interests" */
  updateCreatorProspectInterests?: Maybe<Creator_Prospect_Interests_Mutation_Response>;
  /** update single row of the table: "creator_prospect_notes" */
  updateCreatorProspectNote?: Maybe<Creator_Prospect_Notes>;
  /** update data of the table: "creator_prospect_notes" */
  updateCreatorProspectNotes?: Maybe<Creator_Prospect_Notes_Mutation_Response>;
  /** update single row of the table: "creator_prospect_phyllo_profiles" */
  updateCreatorProspectPhylloProfile?: Maybe<Creator_Prospect_Phyllo_Profiles>;
  /** update data of the table: "creator_prospect_phyllo_profiles" */
  updateCreatorProspectPhylloProfiles?: Maybe<Creator_Prospect_Phyllo_Profiles_Mutation_Response>;
  /** update data of the table: "creator_prospect" */
  updateCreatorProspects?: Maybe<Creator_Prospect_Mutation_Response>;
  /** update single row of the table: "user" */
  updateUser?: Maybe<User>;
  /** update data of the table: "user" */
  updateUsers?: Maybe<User_Mutation_Response>;
  /** update data of the table: "channel_type" */
  update_channel_type?: Maybe<Channel_Type_Mutation_Response>;
  /** update single row of the table: "channel_type" */
  update_channel_type_by_pk?: Maybe<Channel_Type>;
  /** update multiples rows of table: "channel_type" */
  update_channel_type_many?: Maybe<Array<Maybe<Channel_Type_Mutation_Response>>>;
  /** update data of the table: "content_monetization" */
  update_content_monetization?: Maybe<Content_Monetization_Mutation_Response>;
  /** update single row of the table: "content_monetization" */
  update_content_monetization_by_pk?: Maybe<Content_Monetization>;
  /** update multiples rows of table: "content_monetization" */
  update_content_monetization_many?: Maybe<Array<Maybe<Content_Monetization_Mutation_Response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect_categories" */
  update_creator_prospect_categories_many?: Maybe<Array<Maybe<Creator_Prospect_Categories_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect_channels" */
  update_creator_prospect_channels_many?: Maybe<Array<Maybe<Creator_Prospect_Channels_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect_content" */
  update_creator_prospect_content_many?: Maybe<Array<Maybe<Creator_Prospect_Content_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect_flags" */
  update_creator_prospect_flags_many?: Maybe<Array<Maybe<Creator_Prospect_Flags_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect_interests" */
  update_creator_prospect_interests_many?: Maybe<Array<Maybe<Creator_Prospect_Interests_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect" */
  update_creator_prospect_many?: Maybe<Array<Maybe<Creator_Prospect_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect_notes" */
  update_creator_prospect_notes_many?: Maybe<Array<Maybe<Creator_Prospect_Notes_Mutation_Response>>>;
  /** update multiples rows of table: "creator_prospect_phyllo_profiles" */
  update_creator_prospect_phyllo_profiles_many?: Maybe<Array<Maybe<Creator_Prospect_Phyllo_Profiles_Mutation_Response>>>;
  /** update data of the table: "fit_grade" */
  update_fit_grade?: Maybe<Fit_Grade_Mutation_Response>;
  /** update single row of the table: "fit_grade" */
  update_fit_grade_by_pk?: Maybe<Fit_Grade>;
  /** update multiples rows of table: "fit_grade" */
  update_fit_grade_many?: Maybe<Array<Maybe<Fit_Grade_Mutation_Response>>>;
  /** update data of the table: "prospect_status" */
  update_prospect_status?: Maybe<Prospect_Status_Mutation_Response>;
  /** update single row of the table: "prospect_status" */
  update_prospect_status_by_pk?: Maybe<Prospect_Status>;
  /** update multiples rows of table: "prospect_status" */
  update_prospect_status_many?: Maybe<Array<Maybe<Prospect_Status_Mutation_Response>>>;
  /** update data of the table: "referral_type" */
  update_referral_type?: Maybe<Referral_Type_Mutation_Response>;
  /** update single row of the table: "referral_type" */
  update_referral_type_by_pk?: Maybe<Referral_Type>;
  /** update multiples rows of table: "referral_type" */
  update_referral_type_many?: Maybe<Array<Maybe<Referral_Type_Mutation_Response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectArgs = {
  object: Creator_Prospect_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectCategoriesArgs = {
  objects: Array<Creator_Prospect_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectCategoryArgs = {
  object: Creator_Prospect_Categories_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectChannelArgs = {
  object: Creator_Prospect_Channels_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectChannelsArgs = {
  objects: Array<Creator_Prospect_Channels_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectContentArgs = {
  object: Creator_Prospect_Content_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectContentsArgs = {
  objects: Array<Creator_Prospect_Content_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectFlagArgs = {
  object: Creator_Prospect_Flags_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectFlagsArgs = {
  objects: Array<Creator_Prospect_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectInterestArgs = {
  object: Creator_Prospect_Interests_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectInterestsArgs = {
  objects: Array<Creator_Prospect_Interests_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectNoteArgs = {
  object: Creator_Prospect_Notes_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectNotesArgs = {
  objects: Array<Creator_Prospect_Notes_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectPhylloProfileArgs = {
  object: Creator_Prospect_Phyllo_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Creator_Prospect_Phyllo_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectPhylloProfilesArgs = {
  objects: Array<Creator_Prospect_Phyllo_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_Phyllo_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateCreatorProspectsArgs = {
  objects: Array<Creator_Prospect_Insert_Input>;
  on_conflict?: InputMaybe<Creator_Prospect_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootCreateUsersArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectCategoriesArgs = {
  where: Creator_Prospect_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectCategoryArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectChannelArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectChannelsArgs = {
  where: Creator_Prospect_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectContentArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectContentsArgs = {
  where: Creator_Prospect_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectFlagArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectFlagsArgs = {
  where: Creator_Prospect_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectInterestArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectInterestsArgs = {
  where: Creator_Prospect_Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectNoteArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectNotesArgs = {
  where: Creator_Prospect_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectPhylloProfileArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectPhylloProfilesArgs = {
  where: Creator_Prospect_Phyllo_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreatorProspectsArgs = {
  where: Creator_Prospect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_TypeArgs = {
  where: Channel_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Content_MonetizationArgs = {
  where: Content_Monetization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Monetization_By_PkArgs = {
  monetization_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  country_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Fit_GradeArgs = {
  where: Fit_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fit_Grade_By_PkArgs = {
  fit_grade_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Prospect_StatusArgs = {
  where: Prospect_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prospect_Status_By_PkArgs = {
  status_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Referral_TypeArgs = {
  where: Referral_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Referral_Type_By_PkArgs = {
  referral_type_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Channel_TypeArgs = {
  objects: Array<Channel_Type_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_Type_OneArgs = {
  object: Channel_Type_Insert_Input;
  on_conflict?: InputMaybe<Channel_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_MonetizationArgs = {
  objects: Array<Content_Monetization_Insert_Input>;
  on_conflict?: InputMaybe<Content_Monetization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Monetization_OneArgs = {
  object: Content_Monetization_Insert_Input;
  on_conflict?: InputMaybe<Content_Monetization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_GradeArgs = {
  objects: Array<Fit_Grade_Insert_Input>;
  on_conflict?: InputMaybe<Fit_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_Grade_OneArgs = {
  object: Fit_Grade_Insert_Input;
  on_conflict?: InputMaybe<Fit_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prospect_StatusArgs = {
  objects: Array<Prospect_Status_Insert_Input>;
  on_conflict?: InputMaybe<Prospect_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prospect_Status_OneArgs = {
  object: Prospect_Status_Insert_Input;
  on_conflict?: InputMaybe<Prospect_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Referral_TypeArgs = {
  objects: Array<Referral_Type_Insert_Input>;
  on_conflict?: InputMaybe<Referral_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Referral_Type_OneArgs = {
  object: Referral_Type_Insert_Input;
  on_conflict?: InputMaybe<Referral_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectArgs = {
  _inc?: InputMaybe<Creator_Prospect_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Set_Input>;
  pk_columns: Creator_Prospect_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectCategoriesArgs = {
  _inc?: InputMaybe<Creator_Prospect_Categories_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Categories_Set_Input>;
  pk_columns: Creator_Prospect_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectCategoryArgs = {
  _inc?: InputMaybe<Creator_Prospect_Categories_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Categories_Set_Input>;
  where: Creator_Prospect_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectChannelArgs = {
  _inc?: InputMaybe<Creator_Prospect_Channels_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Channels_Set_Input>;
  pk_columns: Creator_Prospect_Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectChannelsArgs = {
  _inc?: InputMaybe<Creator_Prospect_Channels_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Channels_Set_Input>;
  where: Creator_Prospect_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectContentArgs = {
  _inc?: InputMaybe<Creator_Prospect_Content_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Content_Set_Input>;
  pk_columns: Creator_Prospect_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectContentsArgs = {
  _inc?: InputMaybe<Creator_Prospect_Content_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Content_Set_Input>;
  where: Creator_Prospect_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectFlagArgs = {
  _inc?: InputMaybe<Creator_Prospect_Flags_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Flags_Set_Input>;
  pk_columns: Creator_Prospect_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectFlagsArgs = {
  _inc?: InputMaybe<Creator_Prospect_Flags_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Flags_Set_Input>;
  where: Creator_Prospect_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectInterestArgs = {
  _inc?: InputMaybe<Creator_Prospect_Interests_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Interests_Set_Input>;
  pk_columns: Creator_Prospect_Interests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectInterestsArgs = {
  _inc?: InputMaybe<Creator_Prospect_Interests_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Interests_Set_Input>;
  where: Creator_Prospect_Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectNoteArgs = {
  _inc?: InputMaybe<Creator_Prospect_Notes_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Notes_Set_Input>;
  pk_columns: Creator_Prospect_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectNotesArgs = {
  _inc?: InputMaybe<Creator_Prospect_Notes_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Notes_Set_Input>;
  where: Creator_Prospect_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectPhylloProfileArgs = {
  _append?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Append_Input>;
  _delete_at_path?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_Key_Input>;
  _inc?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Inc_Input>;
  _prepend?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Prepend_Input>;
  _set?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Set_Input>;
  pk_columns: Creator_Prospect_Phyllo_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectPhylloProfilesArgs = {
  _append?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Append_Input>;
  _delete_at_path?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Delete_Key_Input>;
  _inc?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Inc_Input>;
  _prepend?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Prepend_Input>;
  _set?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Set_Input>;
  where: Creator_Prospect_Phyllo_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreatorProspectsArgs = {
  _inc?: InputMaybe<Creator_Prospect_Inc_Input>;
  _set?: InputMaybe<Creator_Prospect_Set_Input>;
  where: Creator_Prospect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_TypeArgs = {
  _set?: InputMaybe<Channel_Type_Set_Input>;
  where: Channel_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Type_By_PkArgs = {
  _set?: InputMaybe<Channel_Type_Set_Input>;
  pk_columns: Channel_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Type_ManyArgs = {
  updates: Array<Channel_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_MonetizationArgs = {
  _set?: InputMaybe<Content_Monetization_Set_Input>;
  where: Content_Monetization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Monetization_By_PkArgs = {
  _set?: InputMaybe<Content_Monetization_Set_Input>;
  pk_columns: Content_Monetization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Monetization_ManyArgs = {
  updates: Array<Content_Monetization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_Categories_ManyArgs = {
  updates: Array<Creator_Prospect_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_Channels_ManyArgs = {
  updates: Array<Creator_Prospect_Channels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_Content_ManyArgs = {
  updates: Array<Creator_Prospect_Content_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_Flags_ManyArgs = {
  updates: Array<Creator_Prospect_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_Interests_ManyArgs = {
  updates: Array<Creator_Prospect_Interests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_ManyArgs = {
  updates: Array<Creator_Prospect_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_Notes_ManyArgs = {
  updates: Array<Creator_Prospect_Notes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Creator_Prospect_Phyllo_Profiles_ManyArgs = {
  updates: Array<Creator_Prospect_Phyllo_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_GradeArgs = {
  _set?: InputMaybe<Fit_Grade_Set_Input>;
  where: Fit_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Grade_By_PkArgs = {
  _set?: InputMaybe<Fit_Grade_Set_Input>;
  pk_columns: Fit_Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Grade_ManyArgs = {
  updates: Array<Fit_Grade_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Prospect_StatusArgs = {
  _set?: InputMaybe<Prospect_Status_Set_Input>;
  where: Prospect_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prospect_Status_By_PkArgs = {
  _set?: InputMaybe<Prospect_Status_Set_Input>;
  pk_columns: Prospect_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prospect_Status_ManyArgs = {
  updates: Array<Prospect_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Referral_TypeArgs = {
  _set?: InputMaybe<Referral_Type_Set_Input>;
  where: Referral_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Referral_Type_By_PkArgs = {
  _set?: InputMaybe<Referral_Type_Set_Input>;
  pk_columns: Referral_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Referral_Type_ManyArgs = {
  updates: Array<Referral_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type PHits = {
  __typename?: 'pHits';
  hits: Array<CHits>;
};

/** columns and relationships of "prospect_status" */
export type Prospect_Status = {
  __typename?: 'prospect_status';
  status_code: Scalars['String'];
  status_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "prospect_status" */
export type Prospect_Status_Aggregate = {
  __typename?: 'prospect_status_aggregate';
  aggregate?: Maybe<Prospect_Status_Aggregate_Fields>;
  nodes: Array<Prospect_Status>;
};

/** aggregate fields of "prospect_status" */
export type Prospect_Status_Aggregate_Fields = {
  __typename?: 'prospect_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Prospect_Status_Max_Fields>;
  min?: Maybe<Prospect_Status_Min_Fields>;
};


/** aggregate fields of "prospect_status" */
export type Prospect_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prospect_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "prospect_status". All fields are combined with a logical 'AND'. */
export type Prospect_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Prospect_Status_Bool_Exp>>;
  _not?: InputMaybe<Prospect_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Prospect_Status_Bool_Exp>>;
  status_code?: InputMaybe<String_Comparison_Exp>;
  status_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "prospect_status" */
export enum Prospect_Status_Constraint {
  /** unique or primary key constraint on columns "status_code" */
  ProspectStatusPkey = 'prospect_status_pkey'
}

export enum Prospect_Status_Enum {
  /** Confirmed Accepted */
  ConfirmedAccepted = 'CONFIRMED_ACCEPTED',
  /** Confirmed Deferred */
  ConfirmedDeferred = 'CONFIRMED_DEFERRED',
  /** Confirmed Rejected */
  ConfirmedRejected = 'CONFIRMED_REJECTED',
  /** Open */
  Open = 'OPEN',
  /** Pending Accepted */
  PendingAccepted = 'PENDING_ACCEPTED',
  /** Pending Deferred */
  PendingDeferred = 'PENDING_DEFERRED',
  /** Pending Rejected */
  PendingRejected = 'PENDING_REJECTED'
}

/** Boolean expression to compare columns of type "prospect_status_enum". All fields are combined with logical 'AND'. */
export type Prospect_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Prospect_Status_Enum>;
  _in?: InputMaybe<Array<Prospect_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Prospect_Status_Enum>;
  _nin?: InputMaybe<Array<Prospect_Status_Enum>>;
};

/** input type for inserting data into table "prospect_status" */
export type Prospect_Status_Insert_Input = {
  status_code?: InputMaybe<Scalars['String']>;
  status_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Prospect_Status_Max_Fields = {
  __typename?: 'prospect_status_max_fields';
  status_code?: Maybe<Scalars['String']>;
  status_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Prospect_Status_Min_Fields = {
  __typename?: 'prospect_status_min_fields';
  status_code?: Maybe<Scalars['String']>;
  status_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "prospect_status" */
export type Prospect_Status_Mutation_Response = {
  __typename?: 'prospect_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prospect_Status>;
};

/** input type for inserting object relation for remote table "prospect_status" */
export type Prospect_Status_Obj_Rel_Insert_Input = {
  data: Prospect_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Prospect_Status_On_Conflict>;
};

/** on_conflict condition type for table "prospect_status" */
export type Prospect_Status_On_Conflict = {
  constraint: Prospect_Status_Constraint;
  update_columns?: Array<Prospect_Status_Update_Column>;
  where?: InputMaybe<Prospect_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "prospect_status". */
export type Prospect_Status_Order_By = {
  status_code?: InputMaybe<Order_By>;
  status_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prospect_status */
export type Prospect_Status_Pk_Columns_Input = {
  status_code: Scalars['String'];
};

/** select columns of table "prospect_status" */
export enum Prospect_Status_Select_Column {
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  StatusName = 'status_name'
}

/** input type for updating data in table "prospect_status" */
export type Prospect_Status_Set_Input = {
  status_code?: InputMaybe<Scalars['String']>;
  status_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "prospect_status" */
export type Prospect_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prospect_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prospect_Status_Stream_Cursor_Value_Input = {
  status_code?: InputMaybe<Scalars['String']>;
  status_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "prospect_status" */
export enum Prospect_Status_Update_Column {
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  StatusName = 'status_name'
}

export type Prospect_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prospect_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prospect_Status_Bool_Exp;
};

export type Publisher = {
  __typename?: 'publisher';
  full_name: Scalars['String'];
  is_pilot: Scalars['Boolean'];
  logo_url?: Maybe<Scalars['String']>;
  pub_id: Scalars['Int'];
  reserve_cpc: Scalars['Float'];
  short_name: Scalars['String'];
  show_in_dashboard: Scalars['Boolean'];
  slug: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch aggregated fields from the table: "channel_type" */
  channel_type_aggregate: Channel_Type_Aggregate;
  /** fetch data from the table: "channel_type" using primary key columns */
  channel_type_by_pk?: Maybe<Channel_Type>;
  /** fetch aggregated fields from the table: "content_monetization" */
  content_monetization_aggregate: Content_Monetization_Aggregate;
  /** fetch data from the table: "content_monetization" using primary key columns */
  content_monetization_by_pk?: Maybe<Content_Monetization>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch aggregated fields from the table: "creator_prospect_flags" */
  creator_prospect_flags_aggregate: Creator_Prospect_Flags_Aggregate;
  /** fetch data from the table: "fit_grade" */
  fit_grade: Array<Fit_Grade>;
  /** fetch aggregated fields from the table: "fit_grade" */
  fit_grade_aggregate: Fit_Grade_Aggregate;
  /** fetch data from the table: "fit_grade" using primary key columns */
  fit_grade_by_pk?: Maybe<Fit_Grade>;
  getActiveMerchants?: Maybe<MerchList>;
  getActiveMerchantsBySearchQuery?: Maybe<MerchList>;
  /** Get Article */
  getArticle?: Maybe<Article>;
  /** Get categories */
  getCategories?: Maybe<CategoriesOutput>;
  /** fetch data from the table: "channel_type" */
  getChannelTypes: Array<Channel_Type>;
  /** fetch data from the table: "content_monetization" */
  getContentMonetization: Array<Content_Monetization>;
  /** fetch data from the table: "country" */
  getCountries: Array<Country>;
  /** fetch data from the table: "creator_prospect" using primary key columns */
  getCreatorProspect?: Maybe<Creator_Prospect>;
  /** fetch data from the table: "creator_prospect_categories" */
  getCreatorProspectCategories: Array<Creator_Prospect_Categories>;
  /** fetch data from the table: "creator_prospect_categories" using primary key columns */
  getCreatorProspectCategory?: Maybe<Creator_Prospect_Categories>;
  /** fetch data from the table: "creator_prospect_channels" using primary key columns */
  getCreatorProspectChannel?: Maybe<Creator_Prospect_Channels>;
  /** fetch aggregated fields from the table: "creator_prospect_categories" */
  getCreatorProspectChannelAggregate: Creator_Prospect_Categories_Aggregate;
  /** fetch data from the table: "creator_prospect_channels" */
  getCreatorProspectChannels: Array<Creator_Prospect_Channels>;
  /** fetch aggregated fields from the table: "creator_prospect_channels" */
  getCreatorProspectChannelsAggregate: Creator_Prospect_Channels_Aggregate;
  /** fetch data from the table: "creator_prospect_content" using primary key columns */
  getCreatorProspectContent?: Maybe<Creator_Prospect_Content>;
  /** fetch data from the table: "creator_prospect_content" */
  getCreatorProspectContents: Array<Creator_Prospect_Content>;
  /** fetch aggregated fields from the table: "creator_prospect_content" */
  getCreatorProspectContentsAggregate: Creator_Prospect_Content_Aggregate;
  /** fetch data from the table: "creator_prospect_flags" using primary key columns */
  getCreatorProspectFlag?: Maybe<Creator_Prospect_Flags>;
  /** fetch data from the table: "creator_prospect_flags" */
  getCreatorProspectFlags: Array<Creator_Prospect_Flags>;
  /** fetch data from the table: "creator_prospect_interests" using primary key columns */
  getCreatorProspectInterest?: Maybe<Creator_Prospect_Interests>;
  /** fetch data from the table: "creator_prospect_interests" */
  getCreatorProspectInterests: Array<Creator_Prospect_Interests>;
  /** fetch aggregated fields from the table: "creator_prospect_interests" */
  getCreatorProspectInterestsAggregate: Creator_Prospect_Interests_Aggregate;
  /** fetch data from the table: "creator_prospect_notes" using primary key columns */
  getCreatorProspectNote?: Maybe<Creator_Prospect_Notes>;
  /** fetch data from the table: "creator_prospect_notes" */
  getCreatorProspectNotes: Array<Creator_Prospect_Notes>;
  /** fetch aggregated fields from the table: "creator_prospect_notes" */
  getCreatorProspectNotesAggregate: Creator_Prospect_Notes_Aggregate;
  /** fetch data from the table: "creator_prospect_phyllo_profiles" using primary key columns */
  getCreatorProspectPhylloProfile?: Maybe<Creator_Prospect_Phyllo_Profiles>;
  /** fetch data from the table: "creator_prospect_phyllo_profiles" */
  getCreatorProspectPhylloProfiles: Array<Creator_Prospect_Phyllo_Profiles>;
  /** fetch aggregated fields from the table: "creator_prospect_phyllo_profiles" */
  getCreatorProspectPhylloProfilesAggregate: Creator_Prospect_Phyllo_Profiles_Aggregate;
  /** fetch data from the table: "creator_prospect" */
  getCreatorProspects: Array<Creator_Prospect>;
  /** fetch aggregated fields from the table: "creator_prospect" */
  getCreatorProspectsAggregate: Creator_Prospect_Aggregate;
  /** Get Merchant by merch_id */
  getMerchByMerchId?: Maybe<MerchIdOutput>;
  /** fetch data from the table: "prospect_status" */
  getProspectStatus: Array<Prospect_Status>;
  /** Get Publisher by pub_id */
  getPubByPubId?: Maybe<PubIdOutput>;
  /** fetch data from the table: "referral_type" */
  getReferralType: Array<Referral_Type>;
  /** Public Storefront Action */
  getStorefront?: Maybe<StorefrontOutput>;
  /** Public GET Storefront Collection Action */
  getStorefrontCollection?: Maybe<StorefrontCollectionOutput>;
  /** getUserExists */
  getUserExists?: Maybe<GetUserExistsOutput>;
  /** fetch aggregated fields from the table: "prospect_status" */
  prospect_status_aggregate: Prospect_Status_Aggregate;
  /** fetch data from the table: "prospect_status" using primary key columns */
  prospect_status_by_pk?: Maybe<Prospect_Status>;
  /** fetch aggregated fields from the table: "referral_type" */
  referral_type_aggregate: Referral_Type_Aggregate;
  /** fetch data from the table: "referral_type" using primary key columns */
  referral_type_by_pk?: Maybe<Referral_Type>;
  /** fetch data from the table: "user" using primary key columns */
  user?: Maybe<User>;
  /** fetch data from the table: "user" */
  users: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  usersAggregate: User_Aggregate;
};


export type Query_RootChannel_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Type_Order_By>>;
  where?: InputMaybe<Channel_Type_Bool_Exp>;
};


export type Query_RootChannel_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootContent_Monetization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Monetization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Monetization_Order_By>>;
  where?: InputMaybe<Content_Monetization_Bool_Exp>;
};


export type Query_RootContent_Monetization_By_PkArgs = {
  monetization_code: Scalars['String'];
};


export type Query_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_By_PkArgs = {
  country_code: Scalars['String'];
};


export type Query_RootCreator_Prospect_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Flags_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Flags_Bool_Exp>;
};


export type Query_RootFit_GradeArgs = {
  distinct_on?: InputMaybe<Array<Fit_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fit_Grade_Order_By>>;
  where?: InputMaybe<Fit_Grade_Bool_Exp>;
};


export type Query_RootFit_Grade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fit_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fit_Grade_Order_By>>;
  where?: InputMaybe<Fit_Grade_Bool_Exp>;
};


export type Query_RootFit_Grade_By_PkArgs = {
  fit_grade_code: Scalars['String'];
};


export type Query_RootGetActiveMerchantsArgs = {
  merchant: IsActive;
};


export type Query_RootGetActiveMerchantsBySearchQueryArgs = {
  merchant: SearchQuery;
};


export type Query_RootGetArticleArgs = {
  app_id: Scalars['String'];
  url: Scalars['String'];
};


export type Query_RootGetChannelTypesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Type_Order_By>>;
  where?: InputMaybe<Channel_Type_Bool_Exp>;
};


export type Query_RootGetContentMonetizationArgs = {
  distinct_on?: InputMaybe<Array<Content_Monetization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Monetization_Order_By>>;
  where?: InputMaybe<Content_Monetization_Bool_Exp>;
};


export type Query_RootGetCountriesArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootGetCreatorProspectArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Categories_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};


export type Query_RootGetCreatorProspectCategoryArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectChannelArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectChannelAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Categories_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};


export type Query_RootGetCreatorProspectChannelsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Channels_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};


export type Query_RootGetCreatorProspectChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Channels_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};


export type Query_RootGetCreatorProspectContentArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectContentsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Content_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};


export type Query_RootGetCreatorProspectContentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Content_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};


export type Query_RootGetCreatorProspectFlagArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectFlagsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Flags_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Flags_Bool_Exp>;
};


export type Query_RootGetCreatorProspectInterestArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectInterestsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Interests_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};


export type Query_RootGetCreatorProspectInterestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Interests_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};


export type Query_RootGetCreatorProspectNoteArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectNotesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Notes_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};


export type Query_RootGetCreatorProspectNotesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Notes_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};


export type Query_RootGetCreatorProspectPhylloProfileArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetCreatorProspectPhylloProfilesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};


export type Query_RootGetCreatorProspectPhylloProfilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};


export type Query_RootGetCreatorProspectsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Bool_Exp>;
};


export type Query_RootGetCreatorProspectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Bool_Exp>;
};


export type Query_RootGetMerchByMerchIdArgs = {
  merch_id: Scalars['Int'];
};


export type Query_RootGetProspectStatusArgs = {
  distinct_on?: InputMaybe<Array<Prospect_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prospect_Status_Order_By>>;
  where?: InputMaybe<Prospect_Status_Bool_Exp>;
};


export type Query_RootGetPubByPubIdArgs = {
  pub_id: Scalars['Int'];
};


export type Query_RootGetReferralTypeArgs = {
  distinct_on?: InputMaybe<Array<Referral_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Type_Order_By>>;
  where?: InputMaybe<Referral_Type_Bool_Exp>;
};


export type Query_RootGetStorefrontArgs = {
  url_slug: Scalars['String'];
};


export type Query_RootGetStorefrontCollectionArgs = {
  collection_id: Scalars['Int'];
  url_slug: Scalars['String'];
};


export type Query_RootGetUserExistsArgs = {
  email: Scalars['String'];
};


export type Query_RootProspect_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prospect_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prospect_Status_Order_By>>;
  where?: InputMaybe<Prospect_Status_Bool_Exp>;
};


export type Query_RootProspect_Status_By_PkArgs = {
  status_code: Scalars['String'];
};


export type Query_RootReferral_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Referral_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Type_Order_By>>;
  where?: InputMaybe<Referral_Type_Bool_Exp>;
};


export type Query_RootReferral_Type_By_PkArgs = {
  referral_type_code: Scalars['String'];
};


export type Query_RootUserArgs = {
  id: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** columns and relationships of "referral_type" */
export type Referral_Type = {
  __typename?: 'referral_type';
  referral_type_code: Scalars['String'];
  referral_type_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "referral_type" */
export type Referral_Type_Aggregate = {
  __typename?: 'referral_type_aggregate';
  aggregate?: Maybe<Referral_Type_Aggregate_Fields>;
  nodes: Array<Referral_Type>;
};

/** aggregate fields of "referral_type" */
export type Referral_Type_Aggregate_Fields = {
  __typename?: 'referral_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Referral_Type_Max_Fields>;
  min?: Maybe<Referral_Type_Min_Fields>;
};


/** aggregate fields of "referral_type" */
export type Referral_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Referral_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "referral_type". All fields are combined with a logical 'AND'. */
export type Referral_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Referral_Type_Bool_Exp>>;
  _not?: InputMaybe<Referral_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Referral_Type_Bool_Exp>>;
  referral_type_code?: InputMaybe<String_Comparison_Exp>;
  referral_type_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "referral_type" */
export enum Referral_Type_Constraint {
  /** unique or primary key constraint on columns "referral_type_code" */
  ReferralTypePkey = 'referral_type_pkey'
}

export enum Referral_Type_Enum {
  /** Admin */
  Admin = 'ADMIN',
  /** Brand */
  Brand = 'BRAND',
  /** Creator */
  Creator = 'CREATOR',
  /** Not referred */
  None = 'NONE',
  /** Sales */
  Sales = 'SALES'
}

/** Boolean expression to compare columns of type "referral_type_enum". All fields are combined with logical 'AND'. */
export type Referral_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Referral_Type_Enum>;
  _in?: InputMaybe<Array<Referral_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Referral_Type_Enum>;
  _nin?: InputMaybe<Array<Referral_Type_Enum>>;
};

/** input type for inserting data into table "referral_type" */
export type Referral_Type_Insert_Input = {
  referral_type_code?: InputMaybe<Scalars['String']>;
  referral_type_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Referral_Type_Max_Fields = {
  __typename?: 'referral_type_max_fields';
  referral_type_code?: Maybe<Scalars['String']>;
  referral_type_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Referral_Type_Min_Fields = {
  __typename?: 'referral_type_min_fields';
  referral_type_code?: Maybe<Scalars['String']>;
  referral_type_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "referral_type" */
export type Referral_Type_Mutation_Response = {
  __typename?: 'referral_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Referral_Type>;
};

/** on_conflict condition type for table "referral_type" */
export type Referral_Type_On_Conflict = {
  constraint: Referral_Type_Constraint;
  update_columns?: Array<Referral_Type_Update_Column>;
  where?: InputMaybe<Referral_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "referral_type". */
export type Referral_Type_Order_By = {
  referral_type_code?: InputMaybe<Order_By>;
  referral_type_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: referral_type */
export type Referral_Type_Pk_Columns_Input = {
  referral_type_code: Scalars['String'];
};

/** select columns of table "referral_type" */
export enum Referral_Type_Select_Column {
  /** column name */
  ReferralTypeCode = 'referral_type_code',
  /** column name */
  ReferralTypeName = 'referral_type_name'
}

/** input type for updating data in table "referral_type" */
export type Referral_Type_Set_Input = {
  referral_type_code?: InputMaybe<Scalars['String']>;
  referral_type_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "referral_type" */
export type Referral_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Referral_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Referral_Type_Stream_Cursor_Value_Input = {
  referral_type_code?: InputMaybe<Scalars['String']>;
  referral_type_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "referral_type" */
export enum Referral_Type_Update_Column {
  /** column name */
  ReferralTypeCode = 'referral_type_code',
  /** column name */
  ReferralTypeName = 'referral_type_name'
}

export type Referral_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Referral_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Referral_Type_Bool_Exp;
};

export type SearchQuery = {
  search_query: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch aggregated fields from the table: "channel_type" */
  channel_type_aggregate: Channel_Type_Aggregate;
  /** fetch data from the table: "channel_type" using primary key columns */
  channel_type_by_pk?: Maybe<Channel_Type>;
  /** fetch data from the table in a streaming manner: "channel_type" */
  channel_type_stream: Array<Channel_Type>;
  /** fetch aggregated fields from the table: "content_monetization" */
  content_monetization_aggregate: Content_Monetization_Aggregate;
  /** fetch data from the table: "content_monetization" using primary key columns */
  content_monetization_by_pk?: Maybe<Content_Monetization>;
  /** fetch data from the table in a streaming manner: "content_monetization" */
  content_monetization_stream: Array<Content_Monetization>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table in a streaming manner: "creator_prospect_categories" */
  creator_prospect_categories_stream: Array<Creator_Prospect_Categories>;
  /** fetch data from the table in a streaming manner: "creator_prospect_channels" */
  creator_prospect_channels_stream: Array<Creator_Prospect_Channels>;
  /** fetch data from the table in a streaming manner: "creator_prospect_content" */
  creator_prospect_content_stream: Array<Creator_Prospect_Content>;
  /** fetch aggregated fields from the table: "creator_prospect_flags" */
  creator_prospect_flags_aggregate: Creator_Prospect_Flags_Aggregate;
  /** fetch data from the table in a streaming manner: "creator_prospect_flags" */
  creator_prospect_flags_stream: Array<Creator_Prospect_Flags>;
  /** fetch data from the table in a streaming manner: "creator_prospect_interests" */
  creator_prospect_interests_stream: Array<Creator_Prospect_Interests>;
  /** fetch data from the table in a streaming manner: "creator_prospect_notes" */
  creator_prospect_notes_stream: Array<Creator_Prospect_Notes>;
  /** fetch data from the table in a streaming manner: "creator_prospect_phyllo_profiles" */
  creator_prospect_phyllo_profiles_stream: Array<Creator_Prospect_Phyllo_Profiles>;
  /** fetch data from the table in a streaming manner: "creator_prospect" */
  creator_prospect_stream: Array<Creator_Prospect>;
  /** fetch data from the table: "fit_grade" */
  fit_grade: Array<Fit_Grade>;
  /** fetch aggregated fields from the table: "fit_grade" */
  fit_grade_aggregate: Fit_Grade_Aggregate;
  /** fetch data from the table: "fit_grade" using primary key columns */
  fit_grade_by_pk?: Maybe<Fit_Grade>;
  /** fetch data from the table in a streaming manner: "fit_grade" */
  fit_grade_stream: Array<Fit_Grade>;
  /** fetch data from the table: "channel_type" */
  getChannelTypes: Array<Channel_Type>;
  /** fetch data from the table: "content_monetization" */
  getContentMonetization: Array<Content_Monetization>;
  /** fetch data from the table: "country" */
  getCountries: Array<Country>;
  /** fetch data from the table: "creator_prospect" using primary key columns */
  getCreatorProspect?: Maybe<Creator_Prospect>;
  /** fetch data from the table: "creator_prospect_categories" */
  getCreatorProspectCategories: Array<Creator_Prospect_Categories>;
  /** fetch data from the table: "creator_prospect_categories" using primary key columns */
  getCreatorProspectCategory?: Maybe<Creator_Prospect_Categories>;
  /** fetch data from the table: "creator_prospect_channels" using primary key columns */
  getCreatorProspectChannel?: Maybe<Creator_Prospect_Channels>;
  /** fetch aggregated fields from the table: "creator_prospect_categories" */
  getCreatorProspectChannelAggregate: Creator_Prospect_Categories_Aggregate;
  /** fetch data from the table: "creator_prospect_channels" */
  getCreatorProspectChannels: Array<Creator_Prospect_Channels>;
  /** fetch aggregated fields from the table: "creator_prospect_channels" */
  getCreatorProspectChannelsAggregate: Creator_Prospect_Channels_Aggregate;
  /** fetch data from the table: "creator_prospect_content" using primary key columns */
  getCreatorProspectContent?: Maybe<Creator_Prospect_Content>;
  /** fetch data from the table: "creator_prospect_content" */
  getCreatorProspectContents: Array<Creator_Prospect_Content>;
  /** fetch aggregated fields from the table: "creator_prospect_content" */
  getCreatorProspectContentsAggregate: Creator_Prospect_Content_Aggregate;
  /** fetch data from the table: "creator_prospect_flags" using primary key columns */
  getCreatorProspectFlag?: Maybe<Creator_Prospect_Flags>;
  /** fetch data from the table: "creator_prospect_flags" */
  getCreatorProspectFlags: Array<Creator_Prospect_Flags>;
  /** fetch data from the table: "creator_prospect_interests" using primary key columns */
  getCreatorProspectInterest?: Maybe<Creator_Prospect_Interests>;
  /** fetch data from the table: "creator_prospect_interests" */
  getCreatorProspectInterests: Array<Creator_Prospect_Interests>;
  /** fetch aggregated fields from the table: "creator_prospect_interests" */
  getCreatorProspectInterestsAggregate: Creator_Prospect_Interests_Aggregate;
  /** fetch data from the table: "creator_prospect_notes" using primary key columns */
  getCreatorProspectNote?: Maybe<Creator_Prospect_Notes>;
  /** fetch data from the table: "creator_prospect_notes" */
  getCreatorProspectNotes: Array<Creator_Prospect_Notes>;
  /** fetch aggregated fields from the table: "creator_prospect_notes" */
  getCreatorProspectNotesAggregate: Creator_Prospect_Notes_Aggregate;
  /** fetch data from the table: "creator_prospect_phyllo_profiles" using primary key columns */
  getCreatorProspectPhylloProfile?: Maybe<Creator_Prospect_Phyllo_Profiles>;
  /** fetch data from the table: "creator_prospect_phyllo_profiles" */
  getCreatorProspectPhylloProfiles: Array<Creator_Prospect_Phyllo_Profiles>;
  /** fetch aggregated fields from the table: "creator_prospect_phyllo_profiles" */
  getCreatorProspectPhylloProfilesAggregate: Creator_Prospect_Phyllo_Profiles_Aggregate;
  /** fetch data from the table: "creator_prospect" */
  getCreatorProspects: Array<Creator_Prospect>;
  /** fetch aggregated fields from the table: "creator_prospect" */
  getCreatorProspectsAggregate: Creator_Prospect_Aggregate;
  /** fetch data from the table: "prospect_status" */
  getProspectStatus: Array<Prospect_Status>;
  /** fetch data from the table: "referral_type" */
  getReferralType: Array<Referral_Type>;
  /** fetch aggregated fields from the table: "prospect_status" */
  prospect_status_aggregate: Prospect_Status_Aggregate;
  /** fetch data from the table: "prospect_status" using primary key columns */
  prospect_status_by_pk?: Maybe<Prospect_Status>;
  /** fetch data from the table in a streaming manner: "prospect_status" */
  prospect_status_stream: Array<Prospect_Status>;
  /** fetch aggregated fields from the table: "referral_type" */
  referral_type_aggregate: Referral_Type_Aggregate;
  /** fetch data from the table: "referral_type" using primary key columns */
  referral_type_by_pk?: Maybe<Referral_Type>;
  /** fetch data from the table in a streaming manner: "referral_type" */
  referral_type_stream: Array<Referral_Type>;
  /** fetch data from the table: "user" using primary key columns */
  user?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "user" */
  users: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  usersAggregate: User_Aggregate;
};


export type Subscription_RootChannel_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Type_Order_By>>;
  where?: InputMaybe<Channel_Type_Bool_Exp>;
};


export type Subscription_RootChannel_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootChannel_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Channel_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Type_Bool_Exp>;
};


export type Subscription_RootContent_Monetization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Monetization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Monetization_Order_By>>;
  where?: InputMaybe<Content_Monetization_Bool_Exp>;
};


export type Subscription_RootContent_Monetization_By_PkArgs = {
  monetization_code: Scalars['String'];
};


export type Subscription_RootContent_Monetization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Monetization_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Monetization_Bool_Exp>;
};


export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_By_PkArgs = {
  country_code: Scalars['String'];
};


export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Categories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Channels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Content_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Content_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Flags_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Flags_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Flags_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Interests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Interests_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Notes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Notes_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_Phyllo_Profiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Phyllo_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};


export type Subscription_RootCreator_Prospect_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Creator_Prospect_Stream_Cursor_Input>>;
  where?: InputMaybe<Creator_Prospect_Bool_Exp>;
};


export type Subscription_RootFit_GradeArgs = {
  distinct_on?: InputMaybe<Array<Fit_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fit_Grade_Order_By>>;
  where?: InputMaybe<Fit_Grade_Bool_Exp>;
};


export type Subscription_RootFit_Grade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fit_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fit_Grade_Order_By>>;
  where?: InputMaybe<Fit_Grade_Bool_Exp>;
};


export type Subscription_RootFit_Grade_By_PkArgs = {
  fit_grade_code: Scalars['String'];
};


export type Subscription_RootFit_Grade_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fit_Grade_Stream_Cursor_Input>>;
  where?: InputMaybe<Fit_Grade_Bool_Exp>;
};


export type Subscription_RootGetChannelTypesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Type_Order_By>>;
  where?: InputMaybe<Channel_Type_Bool_Exp>;
};


export type Subscription_RootGetContentMonetizationArgs = {
  distinct_on?: InputMaybe<Array<Content_Monetization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Monetization_Order_By>>;
  where?: InputMaybe<Content_Monetization_Bool_Exp>;
};


export type Subscription_RootGetCountriesArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Categories_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectCategoryArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectChannelArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectChannelAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Categories_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Categories_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectChannelsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Channels_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Channels_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Channels_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectContentArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectContentsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Content_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectContentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Content_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Content_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectFlagArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectFlagsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Flags_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Flags_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectInterestArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectInterestsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Interests_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectInterestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Interests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Interests_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Interests_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectNoteArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectNotesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Notes_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectNotesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Notes_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Notes_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectPhylloProfileArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGetCreatorProspectPhylloProfilesArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectPhylloProfilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Phyllo_Profiles_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Phyllo_Profiles_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectsArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Bool_Exp>;
};


export type Subscription_RootGetCreatorProspectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Creator_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Creator_Prospect_Order_By>>;
  where?: InputMaybe<Creator_Prospect_Bool_Exp>;
};


export type Subscription_RootGetProspectStatusArgs = {
  distinct_on?: InputMaybe<Array<Prospect_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prospect_Status_Order_By>>;
  where?: InputMaybe<Prospect_Status_Bool_Exp>;
};


export type Subscription_RootGetReferralTypeArgs = {
  distinct_on?: InputMaybe<Array<Referral_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Type_Order_By>>;
  where?: InputMaybe<Referral_Type_Bool_Exp>;
};


export type Subscription_RootProspect_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prospect_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prospect_Status_Order_By>>;
  where?: InputMaybe<Prospect_Status_Bool_Exp>;
};


export type Subscription_RootProspect_Status_By_PkArgs = {
  status_code: Scalars['String'];
};


export type Subscription_RootProspect_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Prospect_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Prospect_Status_Bool_Exp>;
};


export type Subscription_RootReferral_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Referral_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Type_Order_By>>;
  where?: InputMaybe<Referral_Type_Bool_Exp>;
};


export type Subscription_RootReferral_Type_By_PkArgs = {
  referral_type_code: Scalars['String'];
};


export type Subscription_RootReferral_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Referral_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Referral_Type_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** test table for events */
export type User = {
  __typename?: 'user';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type GetActiveMerchantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveMerchantsQuery = { __typename?: 'query_root', getActiveMerchants?: { __typename?: 'merchList', hits: { __typename?: 'pHits', hits: Array<{ __typename?: 'cHits', _source: { __typename?: 'cFields', cover_image_url: string, full_name: string, logo_url: string, merch_id: number } }> } } | null };

export type GetActiveMerchantsSearchQueryVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type GetActiveMerchantsSearchQuery = { __typename?: 'query_root', getActiveMerchantsBySearchQuery?: { __typename?: 'merchList', hits: { __typename?: 'pHits', hits: Array<{ __typename?: 'cHits', _source: { __typename?: 'cFields', cover_image_url: string, full_name: string, logo_url: string, merch_id: number } }> } } | null };

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { __typename?: 'query_root', getCountries: Array<{ __typename?: 'country', country_name: string, country_code: string }> };

export type CreateProspectMutationVariables = Exact<{
  creatorProspect: Creator_Prospect_Insert_Input;
}>;


export type CreateProspectMutation = { __typename?: 'mutation_root', createCreatorProspects?: { __typename?: 'creator_prospect_mutation_response', affected_rows: number } | null };

export type GetChannelTypesOrderedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChannelTypesOrderedQuery = { __typename?: 'query_root', getChannelTypes: Array<{ __typename?: 'channel_type', name: string, order?: string | null }> };

export type GetCreatorProspectQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCreatorProspectQuery = { __typename?: 'query_root', getCreatorProspect?: { __typename?: 'creator_prospect', id: number, email: string, last_name: string, first_name: string, business_name?: string | null, annual_revenue_usd?: any | null, country: Country_Enum, story?: string | null, prospect_status: Prospect_Status_Enum, prospect_status_reason?: string | null, referral_code: string, referral_type: Referral_Type_Enum, notes?: string | null, category_id?: number | null, fit_grade?: Fit_Grade_Enum | null, is_monetized?: boolean | null, prospectCountry: { __typename?: 'country', country_code: string, country_name: string }, creatorProspectChannels: Array<{ __typename?: 'creator_prospect_channels', channel_handle: string, channel_type: Channel_Type_Enum, is_primary: boolean, audience_size?: number | null, is_enabled: boolean, id: number, channelType: { __typename?: 'channel_type', name: string }, creator_prospect_phyllo_profiles: Array<{ __typename?: 'creator_prospect_phyllo_profiles', profile_url: string, image_url?: string | null, platform_username: string, country?: string | null, is_verified?: boolean | null, audience_size?: number | null, audience_countries?: any | null, brand_affinity?: any | null, interests?: any | null, engagement_rate?: any | null, datetime_created: any, datetime_updated: any }> }>, creatorProspectCategories: Array<{ __typename?: 'creator_prospect_categories', id: number, is_enabled: boolean, category_id?: number | null, is_primary: boolean, other_category?: string | null }>, creatorProspectInterests: Array<{ __typename?: 'creator_prospect_interests', id: number, is_enabled: boolean, interest_name?: string | null, isFuture: boolean, interest_id?: number | null }>, creatorProspectNotes: Array<{ __typename?: 'creator_prospect_notes', id: number, note: string, uid_created?: number | null, uid_created_name?: string | null, datetime_created: any }> } | null };

export type GetCreatorProspectsByStatusQueryVariables = Exact<{
  prospect_status?: InputMaybe<Array<Prospect_Status_Enum> | Prospect_Status_Enum>;
  referral_types?: InputMaybe<Array<Referral_Type_Enum> | Referral_Type_Enum>;
  search?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by: Creator_Prospect_Order_By;
}>;


export type GetCreatorProspectsByStatusQuery = { __typename?: 'query_root', getCreatorProspectsAggregate: { __typename?: 'creator_prospect_aggregate', aggregate?: { __typename?: 'creator_prospect_aggregate_fields', totalCount: number } | null }, getCreatorProspects: Array<{ __typename?: 'creator_prospect', id: number, email: string, first_name: string, last_name: string, business_name?: string | null, datetime_created: any, referral_code: string, referral_type: Referral_Type_Enum, annual_revenue_usd?: any | null, story?: string | null, prospect_status: Prospect_Status_Enum, prospect_status_reason?: string | null, category_id?: number | null, prospectCountry: { __typename?: 'country', country_code: string, country_name: string }, creatorProspectChannels: Array<{ __typename?: 'creator_prospect_channels', channel_handle: string, channel_type: Channel_Type_Enum, is_primary: boolean, is_enabled: boolean, channelType: { __typename?: 'channel_type', name: string } }>, creatorProspectInterests: Array<{ __typename?: 'creator_prospect_interests', id: number, is_enabled: boolean, interest_name?: string | null, isFuture: boolean }>, creatorProspectNotes: Array<{ __typename?: 'creator_prospect_notes', id: number, note: string, uid_created?: number | null, uid_created_name?: string | null, datetime_created: any, is_enabled: boolean }> }> };

export type GetNextProspectQueryVariables = Exact<{
  id: Scalars['Int'];
  prospect_status?: InputMaybe<Array<Prospect_Status_Enum> | Prospect_Status_Enum>;
}>;


export type GetNextProspectQuery = { __typename?: 'query_root', getCreatorProspects: Array<{ __typename?: 'creator_prospect', id: number }> };

export type GetPreviousProspectQueryVariables = Exact<{
  id: Scalars['Int'];
  prospect_status?: InputMaybe<Array<Prospect_Status_Enum> | Prospect_Status_Enum>;
}>;


export type GetPreviousProspectQuery = { __typename?: 'query_root', getCreatorProspects: Array<{ __typename?: 'creator_prospect', id: number }> };

export type GetProspectStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProspectStatusQuery = { __typename?: 'query_root', getProspectStatus: Array<{ __typename?: 'prospect_status', status_name?: string | null, status_code: string }> };

export type GetReferralsForAccountQueryVariables = Exact<{
  referral_code?: InputMaybe<Scalars['String']>;
  referral_type?: InputMaybe<Referral_Type_Enum>;
}>;


export type GetReferralsForAccountQuery = { __typename?: 'query_root', getCreatorProspects: Array<{ __typename: 'creator_prospect', prospect_status: Prospect_Status_Enum, datetime_created: any, id: number, business_name?: string | null, creatorProspectChannels: Array<{ __typename?: 'creator_prospect_channels', channel_handle: string }> }> };

export type UpdateCreatorProspectDetailsMutationVariables = Exact<{
  id: Scalars['Int'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  business_name: Scalars['String'];
  email: Scalars['String'];
  country: Country_Enum;
  annual_revenue_usd?: InputMaybe<Scalars['numeric']>;
  category_id?: InputMaybe<Scalars['Int']>;
  is_monetized?: InputMaybe<Scalars['Boolean']>;
  datetime_updated?: InputMaybe<Scalars['timestamptz']>;
  uid_updated: Scalars['Int'];
}>;


export type UpdateCreatorProspectDetailsMutation = { __typename?: 'mutation_root', updateCreatorProspect?: { __typename?: 'creator_prospect', first_name: string, last_name: string, business_name?: string | null, email: string, annual_revenue_usd?: any | null, category_id?: number | null, is_monetized?: boolean | null, datetime_updated: any, uid_updated?: number | null } | null };

export type UpdateCreatorProspectPriorityMutationVariables = Exact<{
  id: Scalars['Int'];
  referral_type: Referral_Type_Enum;
  referral_code?: InputMaybe<Scalars['String']>;
  fit_grade?: InputMaybe<Fit_Grade_Enum>;
}>;


export type UpdateCreatorProspectPriorityMutation = { __typename?: 'mutation_root', updateCreatorProspect?: { __typename?: 'creator_prospect', referral_code: string, referral_type: Referral_Type_Enum, fit_grade?: Fit_Grade_Enum | null } | null };

export type UpdateCreatorProspectStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  prospect_status: Prospect_Status_Enum;
  prospect_status_reason?: InputMaybe<Scalars['String']>;
  uid_updated: Scalars['Int'];
}>;


export type UpdateCreatorProspectStatusMutation = { __typename?: 'mutation_root', updateCreatorProspect?: { __typename?: 'creator_prospect', prospect_status: Prospect_Status_Enum, prospect_status_reason?: string | null, uid_updated?: number | null } | null };

export type UpsertCreatorProspectCategoriesMutationVariables = Exact<{
  categories: Array<Creator_Prospect_Categories_Insert_Input> | Creator_Prospect_Categories_Insert_Input;
}>;


export type UpsertCreatorProspectCategoriesMutation = { __typename?: 'mutation_root', createCreatorProspectCategories?: { __typename?: 'creator_prospect_categories_mutation_response', returning: Array<{ __typename?: 'creator_prospect_categories', id: number, category_id?: number | null, is_primary: boolean, other_category?: string | null, is_enabled: boolean }> } | null };

export type UpsertCreatorProspectChannelsMutationVariables = Exact<{
  channels: Array<Creator_Prospect_Channels_Insert_Input> | Creator_Prospect_Channels_Insert_Input;
}>;


export type UpsertCreatorProspectChannelsMutation = { __typename?: 'mutation_root', createCreatorProspectChannels?: { __typename?: 'creator_prospect_channels_mutation_response', returning: Array<{ __typename?: 'creator_prospect_channels', id: number, creator_prospect_id: number, channel_handle: string, channel_type: Channel_Type_Enum, is_primary: boolean, audience_size?: number | null }> } | null };

export type UpsertCreatorProspectContentsMutationVariables = Exact<{
  contents: Array<Creator_Prospect_Content_Insert_Input> | Creator_Prospect_Content_Insert_Input;
}>;


export type UpsertCreatorProspectContentsMutation = { __typename?: 'mutation_root', createCreatorProspectContents?: { __typename?: 'creator_prospect_content_mutation_response', returning: Array<{ __typename?: 'creator_prospect_content', content_url: string, creator_prospect_id: number }> } | null };

export type UpsertCreatorProspectFlagsMutationVariables = Exact<{
  flags: Array<Creator_Prospect_Flags_Insert_Input> | Creator_Prospect_Flags_Insert_Input;
}>;


export type UpsertCreatorProspectFlagsMutation = { __typename?: 'mutation_root', createCreatorProspectFlags?: { __typename?: 'creator_prospect_flags_mutation_response', returning: Array<{ __typename?: 'creator_prospect_flags', id: number, creator_prospect_id: number, is_enabled?: boolean | null, flagged_reason?: string | null, flagged_by?: number | null }> } | null };

export type UpsertCreatorProspectInterestsMutationVariables = Exact<{
  interests: Array<Creator_Prospect_Interests_Insert_Input> | Creator_Prospect_Interests_Insert_Input;
}>;


export type UpsertCreatorProspectInterestsMutation = { __typename?: 'mutation_root', createCreatorProspectInterests?: { __typename?: 'creator_prospect_interests_mutation_response', returning: Array<{ __typename?: 'creator_prospect_interests', id: number, interest_id?: number | null, isFuture: boolean, is_enabled: boolean, interest_name?: string | null }> } | null };

export type UpsertCreatorProspectNotesMutationVariables = Exact<{
  notes: Array<Creator_Prospect_Notes_Insert_Input> | Creator_Prospect_Notes_Insert_Input;
}>;


export type UpsertCreatorProspectNotesMutation = { __typename?: 'mutation_root', createCreatorProspectNotes?: { __typename?: 'creator_prospect_notes_mutation_response', returning: Array<{ __typename?: 'creator_prospect_notes', note: string, id: number, uid_created_name?: string | null }> } | null };

export type GetArticleQueryVariables = Exact<{
  url: Scalars['String'];
  app_id: Scalars['String'];
}>;


export type GetArticleQuery = { __typename?: 'query_root', getArticle?: { __typename?: 'Article', hybridGraph?: { __typename?: 'articleData', title: string, description?: string | null, site_name?: string | null, url: string, image?: string | null, favicon?: string | null } | null } | null };

export type GetMerchantByMerchIdQueryVariables = Exact<{
  merch_id: Scalars['Int'];
}>;


export type GetMerchantByMerchIdQuery = { __typename?: 'query_root', getMerchByMerchId?: { __typename?: 'MerchIdOutput', data: Array<{ __typename?: 'merchant', full_name: string } | null> } | null };

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = { __typename?: 'query_root', getCategories?: { __typename?: 'CategoriesOutput', data: Array<{ __typename?: 'Categories', categories: Array<{ __typename?: 'Category', category_id: number, name: string }> }> } | null };

export type GetPublisherByPubIdQueryVariables = Exact<{
  pub_id: Scalars['Int'];
}>;


export type GetPublisherByPubIdQuery = { __typename?: 'query_root', getPubByPubId?: { __typename?: 'PubIdOutput', data: Array<{ __typename?: 'publisher', full_name: string } | null> } | null };

export type GetUserExistsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserExistsByEmailQuery = { __typename?: 'query_root', getUserExists?: { __typename?: 'GetUserExistsOutput', data: Array<{ __typename?: 'UserExists', exists: boolean } | null> } | null };

export type GetStorefrontCollectionPublicQueryVariables = Exact<{
  url_slug: Scalars['String'];
  collection_id: Scalars['Int'];
}>;


export type GetStorefrontCollectionPublicQuery = { __typename?: 'query_root', getStorefrontCollection?: { __typename?: 'StorefrontCollectionOutput', data: Array<{ __typename?: 'Collection', collection_id: number, description?: string | null, rank: number, storefront_id: number, title: string, storefront_links_count: number, social_post_channel_type?: string | null, social_post_url?: string | null, storefront_links?: Array<{ __typename?: 'StorefrontLink', bam_link_id: number, collection_id: number, rank: number, storefront_link_id: number, image_url?: string | null, product_url?: string | null, product_brand?: string | null, product_name?: string | null, user_brand_name?: string | null, user_image_url?: string | null, user_link_description?: string | null, user_product_name?: string | null, merch_logo_url?: string | null } | null> | null }> } | null };

export type GetStorefrontPublicQueryVariables = Exact<{
  url_slug: Scalars['String'];
}>;


export type GetStorefrontPublicQuery = { __typename?: 'query_root', getStorefront?: { __typename?: 'StorefrontOutput', data: Array<{ __typename?: 'Storefront', storefront: { __typename?: 'StorefrontOverview', storefront_id: number, bio?: string | null, profile_pic_url?: string | null, title: string, url_slug: string, website?: string | null }, creator_info: { __typename?: 'CreatorInfo', full_name?: string | null }, collections: Array<{ __typename?: 'Collection', collection_id: number, description?: string | null, rank: number, storefront_id: number, title: string, social_post_channel_type?: string | null, storefront_links_count: number, storefront_links?: Array<{ __typename?: 'StorefrontLink', bam_link_id: number, collection_id: number, image_url?: string | null, product_url?: string | null, product_brand?: string | null, product_name?: string | null, rank: number, storefront_link_id: number, user_brand_name?: string | null, user_image_url?: string | null, user_link_description?: string | null, user_product_name?: string | null, merch_logo_url?: string | null } | null> | null }>, creator_channels: Array<{ __typename?: 'CreatorChannel', channel_id: number, channel_type: string, platform_handle: string, rank: number }> }> } | null };


export const GetActiveMerchantsDocument = gql`
    query getActiveMerchants {
  getActiveMerchants(merchant: {is_active: true}) {
    hits {
      hits {
        _source {
          cover_image_url
          full_name
          logo_url
          merch_id
        }
      }
    }
  }
}
    `;

export function useGetActiveMerchantsQuery(options?: Omit<Urql.UseQueryArgs<GetActiveMerchantsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveMerchantsQuery, GetActiveMerchantsQueryVariables>({ query: GetActiveMerchantsDocument, ...options });
};
export const GetActiveMerchantsSearchDocument = gql`
    query getActiveMerchantsSearch($searchQuery: String!) {
  getActiveMerchantsBySearchQuery(merchant: {search_query: $searchQuery}) {
    hits {
      hits {
        _source {
          cover_image_url
          full_name
          logo_url
          merch_id
        }
      }
    }
  }
}
    `;

export function useGetActiveMerchantsSearchQuery(options: Omit<Urql.UseQueryArgs<GetActiveMerchantsSearchQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveMerchantsSearchQuery, GetActiveMerchantsSearchQueryVariables>({ query: GetActiveMerchantsSearchDocument, ...options });
};
export const GetCountriesDocument = gql`
    query getCountries {
  getCountries(order_by: {country_name: asc}) {
    country_name
    country_code
  }
}
    `;

export function useGetCountriesQuery(options?: Omit<Urql.UseQueryArgs<GetCountriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCountriesQuery, GetCountriesQueryVariables>({ query: GetCountriesDocument, ...options });
};
export const CreateProspectDocument = gql`
    mutation createProspect($creatorProspect: creator_prospect_insert_input!) {
  createCreatorProspects(objects: [$creatorProspect]) {
    affected_rows
  }
}
    `;

export function useCreateProspectMutation() {
  return Urql.useMutation<CreateProspectMutation, CreateProspectMutationVariables>(CreateProspectDocument);
};
export const GetChannelTypesOrderedDocument = gql`
    query GetChannelTypesOrdered {
  getChannelTypes(order_by: {order: asc}) {
    name
    order
  }
}
    `;

export function useGetChannelTypesOrderedQuery(options?: Omit<Urql.UseQueryArgs<GetChannelTypesOrderedQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChannelTypesOrderedQuery, GetChannelTypesOrderedQueryVariables>({ query: GetChannelTypesOrderedDocument, ...options });
};
export const GetCreatorProspectDocument = gql`
    query getCreatorProspect($id: Int!) {
  getCreatorProspect(id: $id) {
    id
    email
    last_name
    first_name
    business_name
    annual_revenue_usd
    country
    story
    prospect_status
    prospect_status_reason
    referral_code
    referral_type
    notes
    prospect_status
    prospect_status_reason
    category_id
    fit_grade
    is_monetized
    prospectCountry {
      country_code
      country_name
    }
    creatorProspectChannels {
      channelType {
        name
      }
      channel_handle
      channel_type
      is_primary
      audience_size
      is_enabled
      id
      creator_prospect_phyllo_profiles {
        profile_url
        image_url
        platform_username
        country
        is_verified
        audience_size
        audience_countries
        brand_affinity
        interests
        engagement_rate
        datetime_created
        datetime_updated
      }
    }
    creatorProspectCategories {
      id
      is_enabled
      category_id
      is_primary
      other_category
    }
    creatorProspectInterests {
      id
      is_enabled
      interest_name
      isFuture
      interest_id
    }
    creatorProspectNotes {
      id
      note
      uid_created
      uid_created_name
      datetime_created
    }
  }
}
    `;

export function useGetCreatorProspectQuery(options: Omit<Urql.UseQueryArgs<GetCreatorProspectQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCreatorProspectQuery, GetCreatorProspectQueryVariables>({ query: GetCreatorProspectDocument, ...options });
};
export const GetCreatorProspectsByStatusDocument = gql`
    query getCreatorProspectsByStatus($prospect_status: [prospect_status_enum!], $referral_types: [referral_type_enum!] = [NONE, ADMIN, BRAND, SALES, CREATOR], $search: String = "%", $referral_code: String = "%", $limit: Int = 50, $offset: Int = 0, $order_by: creator_prospect_order_by!) {
  getCreatorProspectsAggregate(
    where: {prospect_status: {_in: $prospect_status}, referral_type: {_in: $referral_types}, is_enabled: {_eq: true}, referral_code: {_ilike: $referral_code}, _or: [{full_name: {_ilike: $search}}, {email: {_ilike: $search}}, {referral_code: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
  getCreatorProspects(
    where: {prospect_status: {_in: $prospect_status}, referral_type: {_in: $referral_types}, is_enabled: {_eq: true}, referral_code: {_ilike: $referral_code}, _or: [{full_name: {_ilike: $search}}, {email: {_ilike: $search}}, {referral_code: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: [$order_by]
  ) {
    id
    email
    first_name
    last_name
    business_name
    datetime_created
    referral_code
    referral_type
    annual_revenue_usd
    story
    prospect_status
    prospect_status_reason
    category_id
    prospectCountry {
      country_code
      country_name
    }
    creatorProspectChannels {
      channelType {
        name
      }
      channel_handle
      channel_type
      is_primary
      is_enabled
    }
    creatorProspectInterests {
      id
      is_enabled
      interest_name
      isFuture
    }
    creatorProspectNotes {
      id
      note
      uid_created
      uid_created_name
      datetime_created
      is_enabled
    }
  }
}
    `;

export function useGetCreatorProspectsByStatusQuery(options: Omit<Urql.UseQueryArgs<GetCreatorProspectsByStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCreatorProspectsByStatusQuery, GetCreatorProspectsByStatusQueryVariables>({ query: GetCreatorProspectsByStatusDocument, ...options });
};
export const GetNextProspectDocument = gql`
    query getNextProspect($id: Int!, $prospect_status: [prospect_status_enum!]) {
  getCreatorProspects(
    limit: 1
    where: {prospect_status: {_in: $prospect_status}, id: {_gt: $id}}
    order_by: {id: asc}
  ) {
    id
  }
}
    `;

export function useGetNextProspectQuery(options: Omit<Urql.UseQueryArgs<GetNextProspectQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNextProspectQuery, GetNextProspectQueryVariables>({ query: GetNextProspectDocument, ...options });
};
export const GetPreviousProspectDocument = gql`
    query getPreviousProspect($id: Int!, $prospect_status: [prospect_status_enum!]) {
  getCreatorProspects(
    limit: 1
    where: {prospect_status: {_in: $prospect_status}, id: {_lt: $id}}
    order_by: {id: desc}
  ) {
    id
  }
}
    `;

export function useGetPreviousProspectQuery(options: Omit<Urql.UseQueryArgs<GetPreviousProspectQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPreviousProspectQuery, GetPreviousProspectQueryVariables>({ query: GetPreviousProspectDocument, ...options });
};
export const GetProspectStatusDocument = gql`
    query getProspectStatus {
  getProspectStatus {
    status_name
    status_code
  }
}
    `;

export function useGetProspectStatusQuery(options?: Omit<Urql.UseQueryArgs<GetProspectStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProspectStatusQuery, GetProspectStatusQueryVariables>({ query: GetProspectStatusDocument, ...options });
};
export const GetReferralsForAccountDocument = gql`
    query getReferralsForAccount($referral_code: String, $referral_type: referral_type_enum) {
  getCreatorProspects(
    where: {referral_code: {_eq: $referral_code}, referral_type: {_eq: $referral_type}}
  ) {
    prospect_status
    datetime_created
    id
    __typename
    business_name
    creatorProspectChannels(where: {is_primary: {_eq: true}}) {
      channel_handle
    }
  }
}
    `;

export function useGetReferralsForAccountQuery(options?: Omit<Urql.UseQueryArgs<GetReferralsForAccountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReferralsForAccountQuery, GetReferralsForAccountQueryVariables>({ query: GetReferralsForAccountDocument, ...options });
};
export const UpdateCreatorProspectDetailsDocument = gql`
    mutation updateCreatorProspectDetails($id: Int!, $first_name: String!, $last_name: String!, $business_name: String!, $email: String!, $country: country_enum!, $annual_revenue_usd: numeric, $category_id: Int, $is_monetized: Boolean, $datetime_updated: timestamptz, $uid_updated: Int!) {
  updateCreatorProspect(
    pk_columns: {id: $id}
    _set: {first_name: $first_name, last_name: $last_name, business_name: $business_name, email: $email, country: $country, annual_revenue_usd: $annual_revenue_usd, category_id: $category_id, is_monetized: $is_monetized, datetime_updated: $datetime_updated, uid_updated: $uid_updated}
  ) {
    first_name
    last_name
    business_name
    email
    annual_revenue_usd
    category_id
    is_monetized
    datetime_updated
    uid_updated
  }
}
    `;

export function useUpdateCreatorProspectDetailsMutation() {
  return Urql.useMutation<UpdateCreatorProspectDetailsMutation, UpdateCreatorProspectDetailsMutationVariables>(UpdateCreatorProspectDetailsDocument);
};
export const UpdateCreatorProspectPriorityDocument = gql`
    mutation updateCreatorProspectPriority($id: Int!, $referral_type: referral_type_enum!, $referral_code: String, $fit_grade: fit_grade_enum) {
  updateCreatorProspect(
    pk_columns: {id: $id}
    _set: {referral_code: $referral_code, referral_type: $referral_type, fit_grade: $fit_grade}
  ) {
    referral_code
    referral_type
    fit_grade
  }
}
    `;

export function useUpdateCreatorProspectPriorityMutation() {
  return Urql.useMutation<UpdateCreatorProspectPriorityMutation, UpdateCreatorProspectPriorityMutationVariables>(UpdateCreatorProspectPriorityDocument);
};
export const UpdateCreatorProspectStatusDocument = gql`
    mutation updateCreatorProspectStatus($id: Int!, $prospect_status: prospect_status_enum!, $prospect_status_reason: String, $uid_updated: Int!) {
  updateCreatorProspect(
    pk_columns: {id: $id}
    _set: {prospect_status: $prospect_status, prospect_status_reason: $prospect_status_reason, uid_updated: $uid_updated}
  ) {
    prospect_status
    prospect_status_reason
    uid_updated
  }
}
    `;

export function useUpdateCreatorProspectStatusMutation() {
  return Urql.useMutation<UpdateCreatorProspectStatusMutation, UpdateCreatorProspectStatusMutationVariables>(UpdateCreatorProspectStatusDocument);
};
export const UpsertCreatorProspectCategoriesDocument = gql`
    mutation upsertCreatorProspectCategories($categories: [creator_prospect_categories_insert_input!]!) {
  createCreatorProspectCategories(
    objects: $categories
    on_conflict: {constraint: creator_prospect_categories_pkey, update_columns: [is_enabled]}
  ) {
    returning {
      id
      category_id
      is_primary
      other_category
      is_enabled
    }
  }
}
    `;

export function useUpsertCreatorProspectCategoriesMutation() {
  return Urql.useMutation<UpsertCreatorProspectCategoriesMutation, UpsertCreatorProspectCategoriesMutationVariables>(UpsertCreatorProspectCategoriesDocument);
};
export const UpsertCreatorProspectChannelsDocument = gql`
    mutation upsertCreatorProspectChannels($channels: [creator_prospect_channels_insert_input!]!) {
  createCreatorProspectChannels(
    objects: $channels
    on_conflict: {constraint: creator_prospect_channels_pkey, update_columns: [channel_handle, channel_type, is_primary, audience_size, is_enabled]}
  ) {
    returning {
      id
      creator_prospect_id
      channel_handle
      channel_type
      is_primary
      audience_size
    }
  }
}
    `;

export function useUpsertCreatorProspectChannelsMutation() {
  return Urql.useMutation<UpsertCreatorProspectChannelsMutation, UpsertCreatorProspectChannelsMutationVariables>(UpsertCreatorProspectChannelsDocument);
};
export const UpsertCreatorProspectContentsDocument = gql`
    mutation upsertCreatorProspectContents($contents: [creator_prospect_content_insert_input!]!) {
  createCreatorProspectContents(
    objects: $contents
    on_conflict: {constraint: creator_prospect_content_pkey, update_columns: [is_enabled, content_url]}
  ) {
    returning {
      content_url
      creator_prospect_id
    }
  }
}
    `;

export function useUpsertCreatorProspectContentsMutation() {
  return Urql.useMutation<UpsertCreatorProspectContentsMutation, UpsertCreatorProspectContentsMutationVariables>(UpsertCreatorProspectContentsDocument);
};
export const UpsertCreatorProspectFlagsDocument = gql`
    mutation upsertCreatorProspectFlags($flags: [creator_prospect_flags_insert_input!]!) {
  createCreatorProspectFlags(
    objects: $flags
    on_conflict: {constraint: creator_prospect_flags_pkey, update_columns: [is_enabled, flagged_by, flagged_reason]}
  ) {
    returning {
      id
      creator_prospect_id
      is_enabled
      flagged_reason
      flagged_by
    }
  }
}
    `;

export function useUpsertCreatorProspectFlagsMutation() {
  return Urql.useMutation<UpsertCreatorProspectFlagsMutation, UpsertCreatorProspectFlagsMutationVariables>(UpsertCreatorProspectFlagsDocument);
};
export const UpsertCreatorProspectInterestsDocument = gql`
    mutation upsertCreatorProspectInterests($interests: [creator_prospect_interests_insert_input!]!) {
  createCreatorProspectInterests(
    objects: $interests
    on_conflict: {constraint: creator_prospect_interests_pkey, update_columns: [is_enabled]}
  ) {
    returning {
      id
      interest_id
      isFuture
      is_enabled
      interest_name
    }
  }
}
    `;

export function useUpsertCreatorProspectInterestsMutation() {
  return Urql.useMutation<UpsertCreatorProspectInterestsMutation, UpsertCreatorProspectInterestsMutationVariables>(UpsertCreatorProspectInterestsDocument);
};
export const UpsertCreatorProspectNotesDocument = gql`
    mutation upsertCreatorProspectNotes($notes: [creator_prospect_notes_insert_input!]!) {
  createCreatorProspectNotes(
    objects: $notes
    on_conflict: {constraint: creator_prospect_notes_pkey, update_columns: note}
  ) {
    returning {
      note
      id
      uid_created_name
    }
  }
}
    `;

export function useUpsertCreatorProspectNotesMutation() {
  return Urql.useMutation<UpsertCreatorProspectNotesMutation, UpsertCreatorProspectNotesMutationVariables>(UpsertCreatorProspectNotesDocument);
};
export const GetArticleDocument = gql`
    query getArticle($url: String!, $app_id: String!) {
  getArticle(url: $url, app_id: $app_id) {
    hybridGraph {
      title
      description
      site_name
      url
      image
      favicon
    }
  }
}
    `;

export function useGetArticleQuery(options: Omit<Urql.UseQueryArgs<GetArticleQueryVariables>, 'query'>) {
  return Urql.useQuery<GetArticleQuery, GetArticleQueryVariables>({ query: GetArticleDocument, ...options });
};
export const GetMerchantByMerchIdDocument = gql`
    query getMerchantByMerchId($merch_id: Int!) {
  getMerchByMerchId(merch_id: $merch_id) {
    data {
      full_name
    }
  }
}
    `;

export function useGetMerchantByMerchIdQuery(options: Omit<Urql.UseQueryArgs<GetMerchantByMerchIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMerchantByMerchIdQuery, GetMerchantByMerchIdQueryVariables>({ query: GetMerchantByMerchIdDocument, ...options });
};
export const GetAllCategoriesDocument = gql`
    query getAllCategories {
  getCategories {
    data {
      categories {
        category_id
        name
      }
    }
  }
}
    `;

export function useGetAllCategoriesQuery(options?: Omit<Urql.UseQueryArgs<GetAllCategoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>({ query: GetAllCategoriesDocument, ...options });
};
export const GetPublisherByPubIdDocument = gql`
    query getPublisherByPubId($pub_id: Int!) {
  getPubByPubId(pub_id: $pub_id) {
    data {
      full_name
    }
  }
}
    `;

export function useGetPublisherByPubIdQuery(options: Omit<Urql.UseQueryArgs<GetPublisherByPubIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPublisherByPubIdQuery, GetPublisherByPubIdQueryVariables>({ query: GetPublisherByPubIdDocument, ...options });
};
export const GetUserExistsByEmailDocument = gql`
    query getUserExistsByEmail($email: String!) {
  getUserExists(email: $email) {
    data {
      exists
    }
  }
}
    `;

export function useGetUserExistsByEmailQuery(options: Omit<Urql.UseQueryArgs<GetUserExistsByEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserExistsByEmailQuery, GetUserExistsByEmailQueryVariables>({ query: GetUserExistsByEmailDocument, ...options });
};
export const GetStorefrontCollectionPublicDocument = gql`
    query getStorefrontCollectionPublic($url_slug: String!, $collection_id: Int!) {
  getStorefrontCollection(url_slug: $url_slug, collection_id: $collection_id) {
    data {
      collection_id
      description
      rank
      storefront_id
      title
      storefront_links_count
      storefront_links {
        bam_link_id
        collection_id
        rank
        storefront_link_id
        image_url
        product_url
        product_brand
        product_name
        user_brand_name
        user_image_url
        user_link_description
        user_product_name
        merch_logo_url
      }
      social_post_channel_type
      social_post_url
    }
  }
}
    `;

export function useGetStorefrontCollectionPublicQuery(options: Omit<Urql.UseQueryArgs<GetStorefrontCollectionPublicQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStorefrontCollectionPublicQuery, GetStorefrontCollectionPublicQueryVariables>({ query: GetStorefrontCollectionPublicDocument, ...options });
};
export const GetStorefrontPublicDocument = gql`
    query getStorefrontPublic($url_slug: String!) {
  getStorefront(url_slug: $url_slug) {
    data {
      storefront {
        storefront_id
        bio
        profile_pic_url
        title
        url_slug
        website
      }
      creator_info {
        full_name
      }
      collections {
        collection_id
        description
        rank
        storefront_id
        title
        social_post_channel_type
        storefront_links_count
        storefront_links {
          bam_link_id
          collection_id
          image_url
          product_url
          product_brand
          product_name
          rank
          storefront_link_id
          user_brand_name
          user_image_url
          user_link_description
          user_product_name
          merch_logo_url
        }
      }
      creator_channels {
        channel_id
        channel_type
        platform_handle
        rank
      }
    }
  }
}
    `;

export function useGetStorefrontPublicQuery(options: Omit<Urql.UseQueryArgs<GetStorefrontPublicQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStorefrontPublicQuery, GetStorefrontPublicQueryVariables>({ query: GetStorefrontPublicDocument, ...options });
};