type State = {
  codes: any;
  messages: any;
};

export const state: State = {
  codes: {
    4001: 'validation',
    4002: 'passwordValidation',
    4003: 'samePassword',
    4301: 'login',
    4302: 'apiToken',
    4303: 'authHeader',
    4305: 'tempPassword',
    4308: 'forbiddenUpdate',
    4500: 'productMatchValidation',
    7000: 'maintenanceDowntime',
  },
  messages: {
    credentials_empty: 'You must fill in all the fields.',
    credentials_current_incorrect: 'Your email or password is incorrect.',
    password_current_empty: 'You must fill in your old password.',
    password_current_incorrect: 'Your current password is incorrect.',
    password_not_match: 'The passwords do not match.',
    password_same:
      'Please choose a new password that is different from your old one.',
    password_token_invalid: 'Invalid reset password URL.',
    password_too_long: 'Password length must not exceed 70 characters.',
    password_too_short: 'Password length must be at least 8 characters.',
    password_too_weak: 'Your password is too weak.',
  },
};
