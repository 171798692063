import React, { useEffect, useState } from 'react';
import { BonusProgress } from '@frontend/shared-ui-kit/v2/progress/bonus-progress';
import { Button } from '@frontend/shared-ui-kit/v2/button';
import { BasicDialog } from '@frontend/shared-ui-kit/dialog';
import { Typography } from '@frontend/shared-ui-kit/typography';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { useActions, useAppState } from '@frontend/howl-web-app/overmind';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { Icon } from '@frontend/shared-ui-kit/v2/icon';
import {
  Toast,
  ToastClose,
  ToastIconSuccess,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@frontend/shared-ui-kit/v2/toast';
import Link from 'next/link';
import { cn, Bonus, GoalState, GoalTier } from '@frontend/shared-utils';

interface BonusDropdownProps {
  goal: Bonus;
  isDropdownOpen: boolean;
  setIsDropdownOpen: any;
  goalState: any;
  finalEarningsDate: any;
  totalBonusEarnedDB?: string;
  earningsDB?: string;
  currentTierIndex: number;
}

export const BonusDropdown = ({
  goal,
  isDropdownOpen,
  setIsDropdownOpen,
  goalState,
  finalEarningsDate,
  totalBonusEarnedDB,
  earningsDB,
  currentTierIndex,
}: BonusDropdownProps) => {
  const { subtractDatesV2 } = useActions().date;
  const { selectedOrgId } = useAppState().organization;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showToastBanner, setShowToastBanner] = useState<boolean>(false);

  // Close dropdown when navigating to a new page
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [window.location.pathname]);

  const formatMonthDay = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
    });
  };

  const formatMonthDateYear = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  const formatMonth = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'long',
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const completedTiers: GoalTier[] = goal.goal_tiers.filter(
    (tier: GoalTier) =>
      parseFloat(goal.earnings_usd || '0') >=
      parseFloat(tier.gmv_goal as string),
  );

  const revenue = parseInt(goal.earnings_usd as string);

  const nextTierIndex = Math.min(completedTiers.length, goal.goal_tiers.length);

  const amountUntilNextGoal = goal.goal_tiers[nextTierIndex]?.gmv_goal
    ? parseInt(goal.goal_tiers[nextTierIndex].gmv_goal as string) - revenue
    : undefined;

  const expiredMessage = (
    <span>
      While the earning period for our Holiday Bonus program is now closed, your
      brand and retailer partners have not finished passing back all of the
      attributed orders and returns. That means your final tier might not be
      accurate just yet. On{' '}
      <span className={'font-medium'}>
        {formatMonthDateYear(finalEarningsDate?.toString())}
      </span>
      , we’ll finalize your bonus amount inclusive of all orders you drove and
      you’ll see final bonus results in your{' '}
      {formatMonth(finalEarningsDate?.toString())} earnings report in the
      Payments tab.
    </span>
  );

  const finalExpiredMessage = (
    <span>
      The Howl Holiday Bonus program is now complete. You can now see your
      finalized bonus amount in your earnings tab. Congrats!
    </span>
  );

  // Logic to check local storage to determine when to show the toast banner
  useEffect(() => {
    const storedTier = window.localStorage.getItem('howl_bonus_tier');
    if (storedTier) {
      if (currentTierIndex > JSON.parse(storedTier).tier) {
        setShowToastBanner(true);
      }
    }
  }, [showToastBanner]);

  const modal = showModal ? (
    <BasicDialog isOpen={true} onClose={() => setShowModal(false)}>
      <Dialog.Panel className="w-[600px] max-w-5xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all z-50 p-8">
        <Typography variant="h2" className="font-medium">
          Terms and Conditions
        </Typography>
        <Button
          className="!absolute top-3 right-3 bg-black/[.04] hover:bg-black/[.07] focus:bg-black/[.07] focus:outline-none py-1.5 px-1.5 rounded-full border-none"
          size={'small'}
          onClick={() => {
            setShowModal(false);
          }}
        >
          <Icon
            iconName="xSmallBold"
            className="m-auto align-middle"
            size={18}
            image={true}
          />
        </Button>
        <Dialog.Description className="text-sm" as="div">
          <Typography variant="h4" className="mt-6 text-sm">
            How it Works
          </Typography>
          The Bonus Program (“The Program”) is a performance reward program. The
          Program is set to run from {formatMonthDay(goal.datetime_start)} at{' '}
          {formatTime(goal.datetime_start)} UTC to{' '}
          {formatMonthDay(goal.datetime_end)} at {formatTime(goal.datetime_end)}{' '}
          UTC.
          <Typography variant="h4" className="mt-6 text-sm">
            Eligibility and Opting In
          </Typography>
          If your account is eligible for the Program, you will see the “Bonus”
          banner in the Howl web app’s navigation system. <br />
          <br /> To participate in the Program, a user of the account must
          explicitly opt in by clicking the “Join” or "Join Program" button.
          This button is available from the account homepage. <br />
          <br /> If you do not opt in to the Program, you will not be actively
          participating, and therefore will not be eligible for any rewards from
          the Program.
          <Typography variant="h4" className="mt-6 text-sm">
            The Goal
          </Typography>
          During the Program, eligible accounts will receive a GMV (Gross
          Merchandise Value) target goal.
          <Typography variant="h4" className="mt-6 text-sm">
            Payment
          </Typography>
          Earnings for your Howl Holiday Bonus program will be added to your
          Payments tab. <br />
          <br />
          {expiredMessage}
        </Dialog.Description>
        <Button
          className={'w-full mt-6'}
          size={'medium'}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Got it
        </Button>
      </Dialog.Panel>
    </BasicDialog>
  ) : null;

  return (
    <>
      <div className={'fixed z-20 w-full'}>
        <Transition
          show={isDropdownOpen}
          appear={true}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100 fixed z-10 w-full h-full"
          leave="transition-opacity duration-300 fixed z-10 w-full h-full"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={'w-full h-full bg-black/50'}
            onClick={() => setIsDropdownOpen(false)}
          ></div>
        </Transition>
      </div>

      <div className={'absolute overflow-auto z-20 w-full'}>
        <Transition
          show={isDropdownOpen}
          appear={true}
          enter="transition-transform duration-300 ease-out"
          enterFrom="transform -translate-y-full"
          enterTo="transform translate-y-0 mt-10"
          leave="transition-transform duration-300 ease-in mt-10"
          leaveFrom="transform translate-y-0"
          leaveTo="transform -translate-y-full"
        >
          <div
            className={
              'w-full md:pt-2 pt-4 bg-white flex flex-col justify-center h-full overflow-x-auto md:pb-0 pb-6'
            }
          >
            <BonusProgress
              goal={goal}
              color={'violet'}
              goalState={goalState}
              subtractDatesV2={subtractDatesV2}
              totalBonusEarnedDB={totalBonusEarnedDB}
              earningsDB={earningsDB}
              progressBubble={{
                heading: '$' + amountUntilNextGoal?.toLocaleString(),
                description: 'Until Next Bonus',
              }}
            ></BonusProgress>

            {showToastBanner ? (
              <div className={'md:mx-12 md:mt-8 -mt-2'}>
                <ToastProvider>
                  <Toast
                    variant={'success'}
                    duration={Infinity}
                    className={' !shadow-none'}
                  >
                    <ToastIconSuccess></ToastIconSuccess>
                    <div className="grid">
                      <ToastTitle className={'text-base font-medium'}>
                        Congratulations, you hit your Tier {currentTierIndex}{' '}
                        bonus!
                      </ToastTitle>
                    </div>
                    <ToastClose
                      onClick={() => {
                        window.localStorage.setItem(
                          'howl_bonus_tier',
                          JSON.stringify({ tier: currentTierIndex }),
                        );
                        setShowToastBanner(false);
                      }}
                    />
                  </Toast>
                  <ToastViewport className={'!relative !max-w-none'} />
                </ToastProvider>
              </div>
            ) : null}
          </div>

          {goalState === GoalState.InProgress ? (
            <>
              <div
                className={
                  'md:pl-16 pl-6 text-black/40 text-sm pb-3 bg-white -mt-[1px]'
                }
              >
                Last Updated: Now
              </div>
              <div
                className={
                  'w-full min-h-12 bg-neutral-gray-100 rounded-b-2xl flex md:flex-row flex-col justify-between'
                }
              >
                <div
                  className={
                    'my-auto min-h-12 flex flex-row md:ml-16 md:p-0 px-6 pt-4'
                  }
                >
                  <Icon
                    iconName="circleInfo"
                    className="my-auto stroke-2 mr-1"
                  ></Icon>
                  <div className={'my-auto md:ml-2 ml-4 text-sm'}>
                    Make progress by creating links and sharing with your
                    audience.
                  </div>
                </div>
                <div
                  className={
                    'my-auto mx-16 font-medium text-violet-400 text-center md:pt-0 md:pb-0 pt-2 pb-4 whitespace-nowrap'
                  }
                >
                  <span
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className={'cursor-pointer text-violet-400'}
                  >
                    See Terms and Conditions
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div
              className={cn(
                'w-full min-h-12 bg-red-10 rounded-b-2xl flex md:flex-row flex-col justify-between py-1',
                goalState === GoalState.Final && 'bg-green-10',
              )}
            >
              <div
                className={
                  'my-auto min-h-12 flex flex-row md:ml-16 md:p-0 px-6 pt-2'
                }
              >
                {goalState === GoalState.Final ? (
                  <div className={'flex flex-row'}>
                    <span className={'my-auto text-green-400'}>
                      <Icon
                        iconName="circleInfo"
                        className=" stroke-2 mr-1 "
                      ></Icon>
                    </span>

                    <span className={'py-2 my-auto md:ml-2 ml-4 text-sm'}>
                      {finalExpiredMessage}
                    </span>
                  </div>
                ) : (
                  <div className={'flex flex-row'}>
                    <Icon
                      iconName="circleWarning"
                      className="my-auto stroke-2 mr-1"
                    ></Icon>
                    <span className={'my-2 md:ml-2 ml-4 text-sm'}>
                      {expiredMessage}
                    </span>
                  </div>
                )}
              </div>
              <div
                className={
                  'my-auto mx-16 font-medium text-violet-400 text-center md:pt-0 md:pb-0 pt-1 pb-4 whitespace-nowrap'
                }
              >
                {goalState === GoalState.Final ? (
                  <Link
                    href={`/publisher/${selectedOrgId}/payments/earnings`}
                    className="flex font-medium text-text-success items-center justify-center"
                  >
                    <span className="mr-1">View Earnings</span>
                    <ArrowRightIcon width={18} height={18} />
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className={'cursor-pointer text-violet-400'}
                  >
                    See Terms and Conditions
                  </span>
                )}
              </div>
            </div>
          )}
        </Transition>
      </div>
      {modal}
    </>
  );
};
