import { Context } from '..';
import {
  AnalyticsTableColumn,
  AnalyticsCampaignStatus,
  AnalyticsCampaignPricingModel,
  AnalyticsTableParams,
  PostStatItem,
  StatItem,
  ProductStatItem,
} from './state';
import cloneDeep from 'lodash/cloneDeep';
import { debounce, Overmind, pipe, waitUntil } from 'overmind';
import { NextRouter } from 'next/router';
import mockYearStats from './year-stats.json';
import mockMonthStats from './month-stats.json';
import mockPreviousMonthStats from './previous-month-stats.json';
import mockDayStats from './day-stats.json';
import mockPosts from './posts.json';
import mockCampaigns from './campaigns.json';
import mockLinks from './links.json';
import mockBrands from './brands.json';

export const onInitializeOvermind: (
  context: Context,
  instance: Overmind<Context>,
) => void = (context: Context) => {
  const currentDate = context.actions.date.getCurrentDateTime();

  // start of this month
  const offsetDate = context.actions.date.offsetFromDate({
    originalDate: currentDate,
    number: -6,
    unit: 'day',
  });
  const dateFrom = context.actions.date.formatDate({ dateObj: offsetDate });

  context.state.publisher.analyticsTable = {
    ...context.state.publisher.analyticsTable,
    params: {
      ...context.state.publisher.analyticsTable.params,
      dateFrom: dateFrom,
      dateTo: context.actions.date.formatDate({ dateObj: currentDate }),
    },
  };
};

export const initAnalyticsTable = ({ state }: Context) => {
  state.publisher.analyticsTable.showNoResult = false;
  state.publisher.analyticsTable.isLoading = false;
  state.publisher.analyticsTable.data = null;
};

export const _fetchStatsByDay = async ({
  effects,
  state,
  actions,
}: Context) => {
  const dayRange = actions.date.getPresetDateRanges().last2Months;
  const today = actions.date.formatDate({
    dateObj: actions.date.getPresetDateRanges().today.startDate,
  });

  const dayParams: any = {
    date_from: actions.date.formatDate({ dateObj: dayRange.startDate }),
    interval: 'day',
  };
  actions.publisherDashboard.checkAllSites({ params: dayParams });
  const pubId = state.organization.selectedOrgId;

  try {
    const result = state.publisher.showMocks
      ? { data: mockDayStats }
      : await effects.api.get(
          `/api/v1/publishers/${pubId}/stats/by_interval/`,
          dayParams,
        );
    if (state.publisher.showMocks) {
      state.publisher.analytics.showNoResult = false;
    }

    let stats = result.data.data[0].stats;
    const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;
    if (stats.length) {
      state.publisher.analyticsTable.hasFlatFeesByDay = hasFlatFees;
      stats = stats.map((stat: any) => {
        return { ...stat, event_date: stat.date_interval };
      });
      const lastItem = stats[stats.length - 1];
      if (
        today !== actions.date.formatDate({ dateObj: lastItem.date_interval })
      ) {
        const defaultStat = {
          publisher_earnings_usd: 0,
          total_earnings_usd: 0,
          attributed_revenue_usd: 0,
          clicks: 0,
          publisher_epc: '0',
          click_through_rate: '0',
          conversion_rate: '0.00',
          average_order_value: '0.00',
          min_event_date: today,
          date_interval: today,
          links_created: null,
        };
        stats.push(defaultStat);
      }
      state.publisher.analytics.statsByDay = stats;
      state.publisher.analytics.dailyChange = effects.publisher.getDailyChange(
        stats,
        hasFlatFees,
      );

      state.publisher.analytics.dailyEarnings =
        effects.publisher.getDailyEarnings(stats, hasFlatFees);
    } else {
      state.publisher.analyticsTable.hasFlatFeesByDay = hasFlatFees;
      state.publisher.analytics.statsByDay = [];
      state.publisher.analytics.dailyEarnings = 0;
      state.publisher.analytics.dailyChange = {
        unit: 'percentage',
        amount: 0,
      };
    }
  } catch (error) {
    state.publisher.analytics.showNoResult = true;
  }
};

export const _fetchStatsByMonth = async ({
  effects,
  state,
  actions,
}: Context) => {
  const getMonthRange = () => {
    const currentMonth = new Date().getMonth();
    return currentMonth > 5 ? 'thisYear' : 'halfYear';
  };
  const monthRange = actions.date.getPresetDateRanges()[getMonthRange()];

  const monthParams: any = {
    date_from: actions.date.formatDate({ dateObj: monthRange.startDate }),
    interval: 'month',
  };
  actions.publisherDashboard.checkAllSites({ params: monthParams });
  const pubId = state.organization.selectedOrgId;
  const today = actions.date.formatDate({
    dateObj: actions.date.getPresetDateRanges().today.startDate,
  });
  try {
    const result = state.publisher.showMocks
      ? { data: mockMonthStats }
      : await effects.api.get(
          `/api/v1/publishers/${pubId}/stats/by_interval/`,
          monthParams,
        );

    if (state.publisher.showMocks) {
      state.publisher.analytics.showNoResult = false;
    }

    let stats = result.data.data[0].stats;
    const metrics = result.data.data[0].metrics;
    interleave_metrics_into_stats(stats, metrics);
    const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;

    if (stats.length) {
      state.publisher.analyticsTable.hasFlatFeesByMonth = hasFlatFees;
      stats = stats.map((stat: any) => {
        return { ...stat, event_date: stat.date_interval };
      });
      const lastItem = stats[stats.length - 1];

      const compareLastDate = actions.date.getMonthDiff({
        d1: today,
        d2: lastItem.date_interval,
      });
      if (compareLastDate === 1) {
        const defaultStat = {
          publisher_earnings_usd: 0,
          total_earnings_usd: 0,
          attributed_revenue_usd: 0,
          clicks: 0,
          publisher_epc: '0',
          click_through_rate: '0',
          conversion_rate: '0',
          average_order_value: '0',
          attributed_orders: 0,
          min_event_date: today,
          date_interval: today,
          links_created: null,
        };

        stats.push(defaultStat);
      }
      state.publisher.analytics.statsByMonth = stats;
      state.publisher.analytics.metricsByMonth = metrics;
      const monthElapsed = actions.date.monthElapsed(true);

      state.publisher.analytics.monthlyChange =
        effects.publisher.getMonthlyChange(stats, monthElapsed, hasFlatFees);

      const { raw, pretty } = effects.publisher.getMonthlyEarnings(
        stats,
        hasFlatFees,
      );
      state.publisher.analytics.monthlyEarnings = pretty;
      state.publisher.analytics.monthlyEarningsRaw = raw;
    } else {
      state.publisher.analyticsTable.hasFlatFeesByMonth = hasFlatFees;
      state.publisher.analytics.statsByMonth = [];
      state.publisher.analytics.monthlyEarnings = 0;
    }
  } catch (error) {
    state.publisher.analytics.showNoResult = true;
  }
};

export const _fetchPreviousStatsByMonth = async ({
  effects,
  state,
  actions,
}: Context) => {
  const monthRange = actions.date.getPresetDateRanges().previous12Months;
  const monthParams: any = {
    date_from: actions.date.formatDate({ dateObj: monthRange.startDate }),
    date_to: actions.date.formatDate({ dateObj: monthRange.endDate }),
    interval: 'month',
  };
  actions.publisherDashboard.checkAllSites({ params: monthParams });
  const pubId = state.organization.selectedOrgId;
  const today = actions.date.formatDate({
    dateObj: actions.date.getPresetDateRanges().today.startDate,
  });
  try {
    const result = state.publisher.showMocks
      ? { data: mockPreviousMonthStats }
      : await effects.api.get(
          `/api/v1/publishers/${pubId}/stats/by_interval/`,
          monthParams,
        );

    if (state.publisher.showMocks) {
      state.publisher.analytics.showNoResult = false;
    }

    let stats = result.data.data[0].stats;
    const metrics = result.data.data[0].metrics;
    interleave_metrics_into_stats(stats, metrics);
    const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;

    if (stats.length) {
      state.publisher.analyticsTable.hasFlatFeesByMonth = hasFlatFees;
      stats = stats.map((stat: any) => {
        return { ...stat, event_date: stat.date_interval };
      });
      const lastItem = stats[stats.length - 1];

      const compareLastDate = actions.date.getMonthDiff({
        d1: today,
        d2: lastItem.date_interval,
      });
      if (compareLastDate === 1) {
        const defaultStat = {
          publisher_earnings_usd: 0,
          total_earnings_usd: 0,
          attributed_revenue_usd: 0,
          clicks: 0,
          publisher_epc: '0',
          click_through_rate: '0',
          conversion_rate: '0',
          average_order_value: '0',
          attributed_orders: 0,
          min_event_date: today,
          date_interval: today,
          links_created: null,
        };

        stats.push(defaultStat);
      }
      state.publisher.analytics.previousStatsByMonth = stats;
    } else {
      state.publisher.analyticsTable.hasFlatFeesByMonth = hasFlatFees;
      state.publisher.analytics.previousStatsByMonth = [];
    }
  } catch (error) {
    state.publisher.analytics.showNoResult = true;
  }
};

export const _fetchStatsByYear = async ({
  effects,
  state,
  actions,
}: Context) => {
  const yearParams: any = {
    interval: 'year',
  };
  actions.publisherDashboard.checkAllSites({ params: yearParams });
  const pubId = state.organization.selectedOrgId;

  try {
    const result = state.publisher.showMocks
      ? { data: mockYearStats }
      : await effects.api.get(
          `/api/v1/publishers/${pubId}/stats/by_interval/`,
          yearParams,
        );

    if (state.publisher.showMocks) {
      state.publisher.analytics.showNoResult = false;
    }

    const stats = result.data.data[0].stats;
    const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;

    if (stats.length) {
      const NUMERIC_STATS_FIELDS = [
        'publisher_earnings_usd',
        'attributed_revenue_usd',
        'publisher_epc',
        'click_through_rate',
        'conversion_rate',
        'average_order_value',
        'total_earnings_usd',
      ];

      stats.forEach((stat: any) => {
        NUMERIC_STATS_FIELDS.forEach((fieldName) => {
          if (fieldName in stat) stat[fieldName] = Number(stat[fieldName]);
        });
      });

      state.publisher.analyticsTable.hasFlatFeesByYear = hasFlatFees;
      state.publisher.analytics.statsByYear = stats;
      state.publisher.analytics.allTimeEarnings =
        effects.publisher.getAllTimeEarnings(stats, hasFlatFees);
    } else {
      state.publisher.analyticsTable.hasFlatFeesByYear = hasFlatFees;
      state.publisher.analytics.statsByYear = [];
      state.publisher.analytics.allTimeEarnings = 0;
    }
  } catch (error) {
    state.publisher.analytics.showNoResult = true;
  }
};

export const getLifetimeEarnings = async (
  { effects }: Context,
  pubId: number,
) => {
  let lifetimeEarnings = '';
  const yearParams: any = {
    interval: 'year',
  };
  await effects.api
    .get(`/api/v1/publishers/${pubId}/stats/by_interval/`, yearParams)
    .then((resp: any) => {
      const stats = resp.data.data[0].stats;
      const hasFlatFees: any = resp.data.data[0].has_flat_fee_earnings;
      lifetimeEarnings = effects.publisher.getAllTimeEarnings(
        stats,
        hasFlatFees,
      );
    })
    .catch((error: any) => {
      console.warn('Unable to get lifetime earnings - ', error);
    });

  return lifetimeEarnings;
};

export const _setGraphRanges = ({ state, actions }: Context) => {
  const matchStatsToLabels = (data: StatItem[], dateLabels: any) => {
    const compare = (stats: any, dateLabel: any) => {
      const formattedDate = actions.date.formatDate({
        dateObj: stats.date_interval,
        fmt: 'Y-MM-DD',
      });

      return formattedDate === dateLabel;
    };

    return dateLabels.map((dateLabel: any) => {
      // Match date label to the most relevant stat or default to empty stat
      const relevantStats = data.filter((stat: any) =>
        compare(stat, dateLabel),
      );
      let matchedStat = relevantStats[0];

      // If there are multiple matches, look for the most recent data point (by date)
      if (relevantStats.length > 1) {
        relevantStats.forEach((stat: any) => {
          const statIsNewer =
            actions.date.compareDates({
              d1: matchedStat.date_interval,
              d2: stat.date_interval,
            }) > 0;
          if (statIsNewer) matchedStat = stat;
        });
      }

      const stats = matchedStat || {
        clicks: 0,
        publisher_earnings_usd: 0,
        total_earnings_usd: 0,
        publisher_epc: 0,
        attributed_revenue_usd: 0,
        count_per_date: 0,
        links_created: 0,
        date_interval: dateLabel,
      };
      return { dateLabel, stats };
    });
  };

  const processGraphData = (stats: any, rangeType: any) => {
    /*
     * rangeType: sevenDay|thisMonth|lastMonth|halfYear|thisYear|multiYear
     * Generate date labels and match them to stats
     */

    const presetRanges: any = actions.date.getPresetDateRanges();
    const rangeObject: any = state.publisher.analytics.rangeDropdownItems.find(
      (item: any) => item.name === rangeType,
    );
    const dateRange = presetRanges[rangeObject.name];
    const timeUnit = rangeObject.unit;

    const dateLabels = actions.publisherDashboard.generateDatesForRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      timeUnit,
      rangeType,
    });
    const statsWithLabels = matchStatsToLabels(stats, dateLabels);

    return actions.publisherDashboard.formatDateLabels({
      statsWithLabels,
      timeUnit,
    });
  };

  const graphDataByRange = {
    sevenDay: processGraphData(
      state.publisher.analytics.statsByDay,
      'sevenDay',
    ),
    halfYear: processGraphData(
      state.publisher.analytics.statsByMonth,
      'halfYear',
    ),
    thisMonth: processGraphData(
      state.publisher.analytics.statsByDay,
      'thisMonth',
    ),
    lastMonth: processGraphData(
      state.publisher.analytics.statsByDay,
      'lastMonth',
    ),
    thisYear: processGraphData(
      state.publisher.analytics.statsByMonth,
      'thisYear',
    ),
    multiYear: state.publisher.analytics.statsByYear
      ? processGraphData(state.publisher.analytics.statsByYear, 'multiYear')
      : [],
  };
  state.publisher.analytics.graphDataByRange = graphDataByRange;
};

export const _setGraphProjections = ({ state, actions }: Context) => {
  const getTimeElapsed = (timeUnit: string) => {
    let timeElapsed;
    if (timeUnit === 'day') {
      timeElapsed = actions.date.dayElapsed(true);
    } else if (timeUnit === 'month') {
      timeElapsed = actions.date.monthElapsed(true);
    } else {
      timeElapsed = actions.date.yearElapsed(true);
    }
    return timeElapsed;
  };

  const graphDataByRange = state.publisher.analytics.graphDataByRange;

  const getProjections = (stats: any, rangeType: any) => {
    const projections: any = {};
    const fieldNames = [
      'clicks',
      'publisher_earnings_usd',
      'attributed_revenue_usd',
    ];
    const stat = stats[stats.length - 1].stats || {}; // Project for the last data point
    const timeUnit = state.publisher.analytics.rangeDropdownItems.find(
      (item: any) => item.name === rangeType,
    ).unit;

    const parseValue = (fieldName: string, value: string) => {
      if (!value) return 0;
      return fieldName === 'clicks' ? parseInt(value) : parseFloat(value);
    };

    fieldNames.forEach((fieldName) => {
      const value = parseValue(fieldName, stat[fieldName]);
      let projectedValue: any = value / getTimeElapsed(timeUnit);

      projectedValue =
        fieldName === 'clicks'
          ? Math.round(projectedValue)
          : projectedValue.toFixed(2);
      projections[fieldName] = projectedValue;

      let projectedDifference: any = projectedValue - value;

      if (fieldName === 'publisher_earnings_usd') {
        const flatFeeKey = 'flat_fee_earnings_usd';
        const flatFeeEarnings = parseValue(flatFeeKey, stat[flatFeeKey]);
        projectedDifference -= flatFeeEarnings;
      }

      projectedDifference =
        fieldName === 'clicks'
          ? Math.round(projectedDifference)
          : projectedDifference.toFixed(2);
      projections[`${fieldName}_difference`] = projectedDifference;
    });

    projections['total_earnings_usd'] = projections['publisher_earnings_usd'];
    projections['total_earnings_usd_difference'] =
      projections['publisher_earnings_usd_difference'];

    return projections;
  };

  const projectionsByRange = {
    sevenDay: getProjections(graphDataByRange.sevenDay, 'sevenDay'),
    halfYear: getProjections(graphDataByRange.halfYear, 'halfYear'),
    thisMonth: getProjections(graphDataByRange.thisMonth, 'thisMonth'),
    lastMonth: {},
    thisYear: getProjections(graphDataByRange.thisYear, 'thisYear'),
    multiYear: state.publisher.analytics.statsByYear
      ? getProjections(graphDataByRange.multiYear, 'multiYear')
      : {},
  };

  state.publisher.analytics.projectionsByRange = projectionsByRange;
};

export const fetchStatsAll = async ({ actions, state }: Context) => {
  state.publisher.analytics.isLoading = true;
  await Promise.all([
    actions.publisher._fetchStatsByDay(),
    actions.publisher._fetchStatsByMonth(),
    actions.publisher._fetchStatsByYear(),
    actions.publisher._fetchPreviousStatsByMonth(),
  ]);
  actions.publisher._setGraphRanges();
  actions.publisher._setGraphProjections();
  state.publisher.analytics.isLoading = false;
};

const formatParams = (params: AnalyticsTableParams) => {
  const newParams = cloneDeep(params);

  const formattedParams: any = {
    date_to: newParams.dateTo,
    date_from: newParams.dateFrom,
    direction: newParams.direction,
    page: newParams.page,
    per_page: newParams.perPage,
    order_by: newParams.orderBy,
    edit_id: newParams.editId,
    auction_id: newParams.auctionId,
  };
  if (params.merchIds && params.merchIds) {
    formattedParams.merch_ids = params.merchIds.length
      ? params.merchIds.toString()
      : null;
  }

  return formattedParams;
};

export const fetchPostStats = pipe(
  waitUntil((state: any) => state.publisher.analyticsTable.isLoading === false),
  async ({ state, actions, effects }: Context, router: NextRouter) => {
    actions.publisher.clearFeatured();
    try {
      const pubId = state.organization.selectedOrgId;
      const params = state.publisher.analyticsTable.params;
      const newParams = formatParams(params);
      if (params.searchQuery) {
        newParams.search_query = params.searchQuery;
      }
      actions.publisherDashboard.checkAllSites({ params: newParams });

      state.publisher.analyticsTable.isLoading = true;
      state.publisher.analyticsGraph.isLoading = true;
      state.publisher.analyticsTable.showNoResult = false;
      const url = effects.publisher.setUrlAnalyticsParams(newParams);
      router.replace(url);
      try {
        const result = state.publisher.showMocks
          ? { data: mockPosts }
          : await effects.api.get(
              `/api/v1/publishers/${pubId}/stats/posts/`,
              newParams,
            );

        const data: any = result.data.data[0].edits;
        const total: number = result.data.data[0].total_rows;
        const totalsData: any = result.data.data[0].totals;
        const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;

        if (data.length) {
          state.publisher.analyticsTable.showNoResult = false;
          state.publisher.analyticsTable.data = data;
          state.publisher.analyticsTable.total = total;
          state.publisher.analyticsTable.totalsData = totalsData;
          state.publisher.analyticsTable.hasFlatFees = hasFlatFees;

          const selectedRows = {
            [data[0].edit_name]: data[0],
            ...(data[1] ? { [data[1].edit_name]: data[1] } : {}),
          };

          actions.publisher.setAnalyticsGraphRows(selectedRows);
          actions.publisher.setAnalyticsGraphColors({
            rows: selectedRows,
            dataKey: 'edit_name',
          });
        } else {
          state.publisher.analyticsTable.showNoResult = true;
          state.publisher.analyticsGraph.showNoResult = true;
          state.publisher.analyticsGraph.isLoading = false;
        }
        state.publisher.analyticsTable.isLoading = false;
      } catch (error) {
        // Show no reuslt
        state.publisher.analyticsTable.showNoResult = true;
        state.publisher.analyticsTable.isLoading = false;
        state.publisher.analyticsGraph.isLoading = false;
      }
    } catch (error) {
      state.publisher.analyticsTable.isLoading = false;
      state.publisher.analyticsTable.showNoResult = true;
    }
  },
);

export const fetchLinkStats = pipe(
  waitUntil((state: any) => state.publisher.analyticsTable.isLoading === false),
  async ({ state, effects, actions }: Context, router: NextRouter) => {
    try {
      const pubId = state.organization.selectedOrgId;
      const params = state.publisher.analyticsTable.params;
      const newParams = formatParams(params);
      if (state.publisher.analyticsTable.featured?.editId) {
        newParams.edit_id = state.publisher.analyticsTable.featured.editId;
      }
      if (state.publisher.analyticsTable.featured?.collectionId) {
        newParams.collection_id =
          state.publisher.analyticsTable.featured.collectionId;
      }
      actions.publisherDashboard.checkAllSites({ params: newParams });

      state.publisher.analyticsTable.isLoading = true;
      state.publisher.analyticsTable.showNoResult = false;
      const url = effects.publisher.setUrlAnalyticsParams(newParams);
      router.replace(url);
      try {
        const result = state.publisher.showMocks
          ? { data: mockLinks }
          : await effects.api.get(
              `/api/v1/publishers/${pubId}/stats/links/`,
              newParams,
            );

        const data: any = result.data.data[0].links;
        const total: number = result.data.data[0].total_rows;
        const totalsData: any = result.data.data[0].totals;
        const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;
        if (data.length) {
          state.publisher.analyticsTable.showNoResult = false;
          state.publisher.analyticsTable.data = data;
          state.publisher.analyticsTable.total = total;
          state.publisher.analyticsTable.totalsData = totalsData;
          state.publisher.analyticsTable.hasFlatFees = hasFlatFees;
        } else {
          state.publisher.analyticsTable.showNoResult = true;
        }
        state.publisher.analyticsTable.isLoading = false;
      } catch (error) {
        // Show no reuslt
        state.publisher.analyticsTable.showNoResult = true;
        state.publisher.analyticsTable.isLoading = false;
      }
    } catch (error) {
      state.publisher.analyticsTable.isLoading = false;
      state.publisher.analyticsTable.showNoResult = true;
    }
  },
);

export const fetchBrandStats = pipe(
  waitUntil((state: any) => state.publisher.analyticsTable.isLoading === false),
  async ({ state, effects, actions }: Context, router: NextRouter) => {
    actions.publisher.clearFeatured();
    try {
      const pubId = state.organization.selectedOrgId;
      const params = state.publisher.analyticsTable.params;
      const newParams = formatParams(params);
      state.publisher.analyticsTable.isLoading = true;
      state.publisher.analyticsTable.showNoResult = false;
      const url = effects.publisher.setUrlAnalyticsParams(newParams);
      router.replace(url);
      actions.publisherDashboard.checkAllSites({ params: newParams });
      try {
        const result = state.publisher.showMocks
          ? { data: mockBrands }
          : await effects.api.get(
              `/api/v1/publishers/${pubId}/stats/brands/v2/`,
              newParams,
            );

        const data: any = result.data.data[0].brands;
        const total: number = result.data.data[0].total_rows;
        const totalsData: any = result.data.data[0].totals;
        const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;

        if (data.length) {
          state.publisher.analyticsTable.showNoResult = false;
          state.publisher.analyticsTable.data = data;
          state.publisher.analyticsTable.total = total;
          state.publisher.analyticsTable.totalsData = totalsData;
          state.publisher.analyticsTable.hasFlatFees = hasFlatFees;
        } else {
          state.publisher.analyticsTable.showNoResult = true;
        }
        state.publisher.analyticsTable.isLoading = false;
      } catch (error) {
        // Show no reuslt
        state.publisher.analyticsTable.showNoResult = true;
        state.publisher.analyticsTable.isLoading = false;
      }
    } catch (error) {
      state.publisher.analyticsTable.isLoading = false;
      state.publisher.analyticsTable.showNoResult = true;
    }
  },
);

export const fetchProductStats = pipe(
  waitUntil((state: any) => state.publisher.analyticsTable.isLoading === false),
  async ({ state, effects, actions }: Context, router: NextRouter) => {
    try {
      const pubId = state.organization.selectedOrgId;
      const params = state.publisher.analyticsTable.params;
      const newParams = formatParams(params);

      if (state.publisher.analyticsTable.featured) {
        newParams.edit_id = state.publisher.analyticsTable.featured.editId;
        newParams.auction_id =
          state.publisher.analyticsTable.featured.auctionId;

        newParams.collection_id =
          state.publisher.analyticsTable.featured.collectionId;
      }

      state.publisher.analyticsTable.isLoading = true;
      state.publisher.analyticsTable.showNoResult = false;
      const url = effects.publisher.setUrlAnalyticsParams(newParams);
      router.replace(url);
      actions.publisherDashboard.checkAllSites({ params: newParams });
      try {
        const result = await effects.api.get(
          `/api/v0/publishers/${pubId}/stats/products/`,
          newParams,
        );

        const data: any = result.data.data[0].products;
        const total: number = result.data.data[0].total_rows;
        const totalsData: any = result.data.data[0].totals;
        data.forEach((item: ProductStatItem) => {
          if (
            item.product_name === null ||
            item.product_name.length === 0 ||
            item.product_name === 'None'
          ) {
            item.product_name = 'Data is currently not available';
          }
        });
        if (data.length) {
          state.publisher.analyticsTable.showNoResult = false;
          state.publisher.analyticsTable.data = data;
          state.publisher.analyticsTable.total = total;
          state.publisher.analyticsTable.totalsData = totalsData;
        } else {
          state.publisher.analyticsTable.showNoResult = true;
        }
        state.publisher.analyticsTable.isLoading = false;
      } catch (error) {
        // Show no reuslt
        state.publisher.analyticsTable.showNoResult = true;
        state.publisher.analyticsTable.isLoading = false;
      }
    } catch (error) {
      state.publisher.analyticsTable.isLoading = false;
      state.publisher.analyticsTable.showNoResult = true;
    }
  },
);

export const fetchCollectionStats = pipe(
  waitUntil((state: any) => state.publisher.analyticsTable.isLoading === false),
  async ({ state, effects }: Context, router: NextRouter) => {
    try {
      const pubId = state.organization.selectedOrgId;
      const params = state.publisher.analyticsTable.params;

      const newParams = formatParams(params);
      if (params.searchQuery) {
        newParams.search_query = params.searchQuery;
      }
      state.publisher.analyticsTable.isLoading = true;
      state.publisher.analyticsTable.showNoResult = false;
      const url = effects.publisher.setUrlAnalyticsParams(newParams);
      router.replace(url);
      // TODO: uncomment this after this is implemented (or remove if this won't be implemented)
      // actions.publisherDashboard.checkAllSites({ params: newParams });
      try {
        const result = await effects.api.get(
          `/api/v1/publishers/${pubId}/stats/collections/`,
          newParams,
        );

        const data: any = result.data.data[0].collections;
        const total: number = result.data.data[0].total_rows;
        const totalsData: any = result.data.data[0].totals;

        if (data.length) {
          state.publisher.analyticsTable.showNoResult = false;
          state.publisher.analyticsTable.data = data;
          state.publisher.analyticsTable.total = total;
          state.publisher.analyticsTable.totalsData = totalsData;
        } else {
          state.publisher.analyticsTable.showNoResult = true;
        }
        state.publisher.analyticsTable.isLoading = false;
      } catch (error) {
        // Show no reuslt
        state.publisher.analyticsTable.showNoResult = true;
        state.publisher.analyticsTable.isLoading = false;
      }
    } catch (error) {
      state.publisher.analyticsTable.isLoading = false;
      state.publisher.analyticsTable.showNoResult = true;
    }
  },
);

export const setCampaignUrlParamsFromState = (
  { state, effects }: Context,
  router: NextRouter,
) => {
  const params = state.publisher.analyticsTable.params;
  const newParams = formatParams(params);

  if (params.searchQuery) {
    newParams.search_query = params.searchQuery;
  }

  if (state.publisher.analyticsTable.featured) {
    newParams.merch_ids = state.publisher.analyticsTable.featured.merchId;
  }

  if ('availableStrategies' in state.publisher.analyticsTable) {
    newParams.exclude_strategies_without_activity =
      !state.publisher.analyticsTable.availableStrategies;
  }

  if (state.publisher.analyticsTable.status) {
    const selectedStatuses = state.publisher.analyticsTable.status
      .filter((statusItem) => statusItem.selected)
      .map((statusItem) => statusItem.value);
    newParams.campaign_status =
      selectedStatuses.length > 0 ? selectedStatuses.join(',') : null;
  }

  if (state.publisher.analyticsTable.pricingModel) {
    const selectedPricingModels = state.publisher.analyticsTable.pricingModel
      .filter((statusItem) => statusItem.selected)
      .map((statusItem) => statusItem.value);
    newParams.pricing_model =
      selectedPricingModels.length > 0 ? selectedPricingModels.join(',') : null;
  }

  if (state.publisher.analyticsTable.featured) {
    newParams.merch_ids = state.publisher.analyticsTable.featured.merchId;
  }
  if (state.publisher.analyticsTable.columns) {
    const selectedColumns = state.publisher.analyticsTable.columns
      .filter((statusItem) => statusItem.selected)
      .map((statusItem) => statusItem.value);
    newParams.columnsDisplay = selectedColumns;
  }

  const url = effects.publisher.setUrlAnalyticsParams(newParams);
  router.replace(url);

  return newParams;
};

export const fetchCampaignStats = pipe(
  waitUntil((state: any) => state.publisher.analyticsTable.isLoading === false),
  async (
    { state, effects, actions }: Context,
    { router, flags }: { router: NextRouter; flags: any },
  ) => {
    try {
      const pubId = state.organization.selectedOrgId;

      const newParams = actions.publisher.setCampaignUrlParamsFromState(router);
      actions.publisherDashboard.checkAllSites({ params: newParams });

      const requestParams = { ...newParams, columnsDisplay: null };
      try {
        state.publisher.analyticsTable.isLoading = true;
        state.publisher.analyticsTable.showNoResult = false;
        const result = state.publisher.showMocks
          ? { data: mockCampaigns }
          : await effects.api.get(
              flags.skipStrategiesCache
                ? `/api/v1/publishers/${pubId}/stats/strategies/v2/`
                : `/api/v1/publishers/${pubId}/stats/strategies/`,
              requestParams,
            );
        const data: any = result.data.data[0].strategies;
        const total: number = result.data.data[0].total_rows;
        const totalsData: any = result.data.data[0].totals;
        const hasFlatFees: any = result.data.data[0].has_flat_fee_earnings;

        if (data.length) {
          const dataWithStatus = data.map((data: any) => {
            if (data.campaign_status === 'Inactive') {
              data.campaign_status = 'Completed';
            }
            return data;
          });
          state.publisher.analyticsTable.showNoResult = false;
          state.publisher.analyticsTable.data = dataWithStatus;
          state.publisher.analyticsTable.total = total;
          state.publisher.analyticsTable.totalsData = totalsData;
          state.publisher.analyticsTable.hasFlatFees = hasFlatFees;
        } else {
          state.publisher.analyticsTable.showNoResult = true;
        }
        state.publisher.analyticsTable.isLoading = false;
      } catch (error) {
        // Show no reuslt
        state.publisher.analyticsTable.showNoResult = true;
        state.publisher.analyticsTable.isLoading = false;
      }
    } catch (error) {
      state.publisher.analyticsTable.isLoading = false;
      state.publisher.analyticsTable.showNoResult = true;
    }
  },
);

// Doesn't make use of allSites / the 'additional_pubs' param
export const fetchMerchIds = pipe(
  debounce(500),
  waitUntil((state: any) => state.publisher.analyticsTable.isLoading === false),
  async ({ state, effects }: Context) => {
    try {
      const pubId = state.organization.selectedOrgId;
      const params = {
        order_by: 'merch_name',
        direction: 'asc',
        per_page: '10000',
      };
      const result = await effects.api.get(
        `/api/v0/publishers/${pubId}/allbrands/`,
        params,
      );

      const mappedResults = result.data.data[0].brands.map((brand: any) => {
        return {
          label: brand.merch_name,
          value: brand.merch_id.toString(),
        };
      });
      state.publisher.analyticsTable.brands = mappedResults;
    } catch {
      // Show no result;
    }
  },
);

const granularityMap = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
  yearly: 'year',
};

export const fetchIntervalStats = pipe(
  debounce(500),
  async (
    { state, effects, actions }: Context,
    rows: { [key: string]: PostStatItem },
  ) => {
    let stats: any = [];
    const pubId = state.organization.selectedOrgId;

    stats = stats.filter((stat: any) => {
      return rows[stat.name];
    });
    state.publisher.analyticsGraph.isLoading = true;
    state.publisher.analyticsGraph.showNoResult = false;

    const paramsChanged =
      state.publisher.analyticsGraph.params?.dateFrom !==
        state.publisher.analyticsTable.params.dateFrom ||
      state.publisher.analyticsGraph.params?.dateTo !==
        state.publisher.analyticsTable.params.dateTo ||
      state.publisher.analyticsGraph.params?.interval !==
        granularityMap[
          state.publisher.analyticsGraph.selectedGranularity.value
        ];

    for await (const row of Object.values(rows)) {
      if (
        !stats.find((stat: any) => stat.name === row.edit_name) ||
        paramsChanged
      ) {
        try {
          const params = {
            date_to: state.publisher.analyticsTable.params.dateTo,
            date_from: state.publisher.analyticsTable.params.dateFrom,
            edit_id: row.edit_id,
            interval:
              granularityMap[
                state.publisher.analyticsGraph.selectedGranularity.value
              ],
          };
          actions.publisherDashboard.checkAllSites({ params });

          const result = await effects.api.get(
            `/api/v0/publishers/${pubId}/stats/by_interval/`,
            params,
          );

          let data = result.data.data[0].stats;
          data = data.map((stat: any) => {
            return { ...stat, event_date: stat.date_interval };
          });
          if (data.length) {
            stats = [
              ...stats,
              {
                name: row.edit_name,
                data: data,
              },
            ];
          }
          state.publisher.analyticsGraph.stats = stats;
          state.publisher.analyticsGraph.params = {
            dateFrom: params.date_from || '',
            dateTo: params.date_to || '',
            interval: params.interval,
          };
        } catch (error) {
          state.publisher.analyticsGraph.isLoading = false;
        }
      }
    }

    if (stats.length) {
      state.publisher.analyticsGraph.stats = stats;
    } else {
      state.publisher.analyticsGraph.showNoResult = true;
    }
    state.publisher.analyticsGraph.isLoading = false;
  },
);

export const setSelectedGranularity = (
  { state }: Context,
  selectedGranularity: {
    label: string;
    value: 'daily' | 'monthly' | 'yearly';
    disabled: boolean;
  },
) => {
  state.publisher.analyticsGraph.selectedGranularity = selectedGranularity;
};

export const setAnalyticsTableParams = (
  { state }: Context,
  params: AnalyticsTableParams,
) => {
  state.publisher.analyticsTable.params = params;
};

export const setAnalyticsSearchQuery = ({ state }: Context, params: string) => {
  state.publisher.analyticsTable.params.searchQuery = params;
};

export const setAnalyticsGraphRows = (
  { state, actions }: Context,
  rows: { [key: string]: PostStatItem },
) => {
  state.publisher.analyticsGraph.selectedRows = rows;

  actions.publisher.fetchIntervalStats(rows);
};

export const setAnalyticsGraphColors = (
  { state }: Context,
  data: { rows: any; dataKey: any },
) => {
  const graphColors = ['green', 'purple', 'blue'];
  const colors: any = {};
  Object.values(data.rows)
    .filter((r) => !!r)
    .forEach((row: any, idx) => {
      const rowKey = row[data.dataKey];
      colors[rowKey] = graphColors[idx % graphColors.length];
    });
  state.publisher.analyticsGraph.selectedColors = colors;
};

export const setFeatured = (
  { state }: Context,
  featureData: {
    name?: string;
    editId?: number;
    auctionId?: number;
    linkName?: string;
    merchId?: number;
    collectionId?: string;
  },
) => {
  state.publisher.analyticsTable.featured = {
    ...state.publisher.analyticsTable.featured,
    ...featureData,
  };
};

export const clearMerchIds = ({ state }: Context) => {
  state.publisher.analyticsTable.params.merchIds = [];
};

export const clearFeatured = ({ state }: Context) => {
  state.publisher.analyticsTable.featured = undefined;
};

export const getFirstUrlAnalyticsParams = ({
  state,
  effects,
  actions,
}: Context) => {
  const urlParams = effects.publisher.getUrlAnalyticsParams(
    state.publisher.analyticsTable.params,
  );
  Object.keys(urlParams).forEach((key: any) => {
    if (
      Object.prototype.hasOwnProperty.call(
        state.publisher.analyticsTable.params,
        key,
      ) &&
      urlParams[key]
    ) {
      if (key === 'merchIds') {
        const isArray = urlParams[key]?.split(',');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.publisher.analyticsTable.params[key] = isArray;
        actions.report.setMerchIds(isArray);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.publisher.analyticsTable.params[key] = urlParams[key];
      }
    } else {
      switch (key) {
        case 'columns_display': {
          const enabledCols = new Set(urlParams[key]?.split(','));
          state.publisher.analyticsTable.columns.forEach((col) => {
            col.selected = enabledCols.has(col.value);
          });
          break;
        }
        case 'exclude_strategies_without_activity':
          state.publisher.analyticsTable.availableStrategies =
            urlParams[key] !== 'true';
          break;
        case 'campaign_status': {
          const enabledStatuses = new Set(urlParams[key]?.split(','));
          if (state.publisher.analyticsTable.status) {
            state.publisher.analyticsTable.status.forEach((status) => {
              status.selected = enabledStatuses.has(status.value);
            });
          }
          break;
        }
        case 'pricing_model': {
          const enabledModels = new Set(urlParams[key]?.split(','));
          if (state.publisher.analyticsTable.pricingModel) {
            state.publisher.analyticsTable.pricingModel.forEach((model) => {
              model.selected = enabledModels.has(model.value);
            });
          }
          break;
        }
      }
    }
  });
};

export const setAnalyticsTableColumns = (
  { state }: Context,
  columns: AnalyticsTableColumn[],
) => {
  state.publisher.analyticsTable.columns = columns;
};

export const setAnalyticsAvailableStrategies = (
  { state }: Context,
  availableStrategies: boolean,
) => {
  state.publisher.analyticsTable.availableStrategies = availableStrategies;
};

export const setAnalyticsTableStatus = (
  { state }: Context,
  status: AnalyticsCampaignStatus[],
) => {
  state.publisher.analyticsTable.status = status;
};

export const setAnalyticsTablePricingModel = (
  { state }: Context,
  pricingModel: AnalyticsCampaignPricingModel[],
) => {
  state.publisher.analyticsTable.pricingModel = pricingModel;
};

export const setShowMocks = ({ state }: Context, showMocks: boolean) => {
  state.publisher.showMocks = showMocks;
};

export const setDisplayAlertIcon = (
  { state }: Context,
  displayAlertIcon: boolean,
) => {
  state.publisher.displayAlertIcon = displayAlertIcon;
};

export const fetchCategories = async (
  { effects, state }: Context,
  publisherId: string,
) => {
  try {
    const response = await effects.api.get(
      `/api/v0/publishers/${publisherId}/categories/`,
    );
    const category = response.data.data[0].category;
    if (category && state.publisher.techCategories.includes(category)) {
      return false;
    } else {
      return true;
    }
  } catch (e: any) {
    throw new Error(e);
  }
};

function interleave_metrics_into_stats(stats: any, metrics: any) {
  if (!metrics || !stats) {
    return;
  }

  let i = 0;
  let j = 0;

  let previous_metric: any = null;
  while (i < stats.length || j < metrics.length) {
    if (j >= metrics.length) {
      if (previous_metric) {
        stats[i]['community_metrics'] = previous_metric;
      }
      i++;
      continue;
    }

    let metric = metrics[j];
    if (i >= stats.length) {
      stats.splice(i, 0, { date_interval: metric.date_interval });
      continue;
    }

    const stat = stats[i];
    if (stat.date_interval < metric.date_interval) {
      if (previous_metric) {
        stat['community_metrics'] = previous_metric;
      }
      i++;
      continue;
    }

    if (stat.date_interval > metric.date_interval) {
      stats.splice(i, 0, { date_interval: metric.date_interval });
      continue;
    }

    while (j < metrics.length && stat.date_interval == metric.date_interval) {
      stat['community_metrics'] ??= {};
      stat['community_metrics'][metric.channel] = metric;
      j++;
      metric = metrics[j];
      previous_metric = stat['community_metrics'];
    }
  }
}
