/* eslint @typescript-eslint/no-useless-constructor: 0 */
class DateRangeDropdown {
  items: any;

  constructor() {
    this.items = [
      {
        label: 'Last 24 Hours',
        name: 'oneDay',
        unit: 'hour',
        selected: false,
        granularity: 'hourly',
      },
      {
        label: 'Last 7 Days',
        name: 'sevenDay',
        unit: 'day',
        selected: false,
        granularity: 'daily',
      },
      {
        label: 'This Month',
        name: 'thisMonth',
        unit: 'day',
        selected: false,
        granularity: 'daily',
      },
      {
        label: 'Last Month',
        name: 'lastMonth',
        unit: 'day',
        selected: false,
      },
      {
        label: 'Last 6 Months',
        name: 'halfYear',
        unit: 'month',
        selected: true,
        granularity: 'monthly',
      },
      {
        label: 'Year to Date',
        name: 'thisYear',
        unit: 'month',
        selected: false,
        granularity: 'monthly',
      },
      {
        label: 'Year over Year',
        name: 'multiYear',
        unit: 'year',
        selected: false,
        granularity: 'yearly',
      },
      {
        label: 'Previous Year Last 6 Months',
        name: 'previousHalfYear',
        unit: 'month',
        selected: false,
        hidden: true,
      },
      {
        label: 'Previous Year To Date',
        name: 'previousYear',
        unit: 'month',
        selected: false,
        hidden: true,
      },
    ];
  }
}

class Granularities {
  defaultGranularities: any;

  // Granularities for megatable visualization dropdown
  constructor() {
    this.defaultGranularities = [
      { label: 'Daily', name: 'daily', selected: true, disabled: false },
      { label: 'Weekly', name: 'weekly', selected: false, disabled: false },
      { label: 'Monthly', name: 'monthly', selected: false, disabled: true },
      { label: 'Yearly', name: 'yearly', selected: false, disabled: true },
    ];
  }
}

class Networks {
  defaultNetworks: any;

  constructor(statType?: any) {
    this.defaultNetworks = [
      { label: 'Narrativ', name: 'narrativ', selected: true },
      { label: 'Narrativ - Rakuten', name: 'rakuten', selected: false },
    ];

    // Currently backend doesn't support networks filter for product stats
    if (statType === 'product') {
      this.defaultNetworks[0].selected = false;
    } else if (statType === 'merchant') {
      this.defaultNetworks[1].selected = true;
    }
  }
}

class PubDashNetworks {
  defaultNetworks: any;

  constructor(statType: any) {
    this.defaultNetworks = [
      { label: 'Narrativ', name: 'narrativ', selected: true },
    ];

    // Currently backend doesn't support networks filter for product stats
    if (statType === 'product') {
      this.defaultNetworks[0].selected = false;
    } else if (statType === 'merchant') {
      this.defaultNetworks[1].selected = true;
    }
  }
}

class StatCache {
  total_items: any;
  totals: any;
  pages: any;

  constructor() {
    this.total_items = null;
    this.totals = null;
    this.pages = {}; // Store stats keyed on the backend page number
  }
}

export {
  DateRangeDropdown,
  Granularities,
  Networks,
  StatCache,
  PubDashNetworks,
};
