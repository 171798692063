import React, { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useActions } from '@frontend/howl-web-app/overmind';
import jwt_decode from 'jwt-decode';
import { Session } from 'next-auth';

const tokenRecheckPeriodInSeconds = 5 * 60;

const isTokenExpiringSoon = (token: string) => {
  const expirationSeconds =
    jwt_decode<{ exp: number }>(token).exp - Date.now() / 1000;

  // console.log('expirationSeconds: ', expirationSeconds);

  // return expirationSeconds <= 60;
  return expirationSeconds <= tokenRecheckPeriodInSeconds;
};

type TTokenRefreshStatus = 'incomplete' | 'processing' | 'complete';

const maybeRefreshTokenOnPageload = async ({
  status,
  update,
  initialTokenRefreshStatus,
  setInitialTokenRefreshStatus,
  createSession,
}: {
  status: 'authenticated' | 'loading' | 'unauthenticated';
  update: (data?: any) => Promise<Session | null>;
  initialTokenRefreshStatus: TTokenRefreshStatus;
  setInitialTokenRefreshStatus: (data?: any) => void;
  createSession: (data: any) => void;
}) => {
  if (
    status !== 'authenticated' ||
    initialTokenRefreshStatus !== 'incomplete'
  ) {
    return;
  }

  const currentUser = localStorage.getItem('currentUser');
  const userData = currentUser ? JSON.parse(currentUser) : null;
  const accessToken = userData?.accessToken;

  if (accessToken && isTokenExpiringSoon(accessToken)) {
    setInitialTokenRefreshStatus('processing');
    await navigator.locks.request(
      'update_token',
      { ifAvailable: true },
      async (lock) => {
        if (!lock) {
          setInitialTokenRefreshStatus('complete');
        } else {
          await update({ request_update: true })
            .then((session) => {
              if (session) {
                createSession({ data: session });
              }
            })
            .finally(() => {
              setInitialTokenRefreshStatus('complete');
            });
        }
      },
    );
  } else {
    setInitialTokenRefreshStatus('complete');
  }
};

const processSessionRefresh = ({
  update,
  updateSessionNextAuth,
}: {
  update: (data?: any) => Promise<Session | null>;
  updateSessionNextAuth: (data?: any) => any;
}) => {
  // const interval = setInterval(() => update(), 1000 * 60 * 5);
  const interval = setInterval(async () => {
    await navigator.locks.request(
      'update_token',
      { ifAvailable: true },
      async (lock) => {
        // console.log('lock2', lock);
        if (!lock) {
          return;
        }
        if (navigator.onLine) {
          await update({ request_update: true }).then((session) => {
            if (session) {
              updateSessionNextAuth({ data: session });
            }
          });
        }
      },
    );
  }, 1000 * tokenRecheckPeriodInSeconds);

  return () => {
    clearInterval(interval);
  };
};

const redirectAwayAsUnauthenticated = () => {
  const originalPath = window.location.pathname;

  if (originalPath === '/v2' || originalPath === '/') {
    // console.log('logout #6');
    window.location.href = '/signin';
  } else {
    // console.log('logout #7');
    window.location.href = `/signin?redirect=${originalPath}${window.location.hash}${window.location.search}`;
  }
};

export function requiresPrivateAuth<T>(
  WrappedComponent: React.FunctionComponent<T>,
): React.FunctionComponent<T> {
  const WithAuth = (props: T) => {
    const { data: session, status, update } = useSession();
    const { syncNextAuthWithOvermind } = useActions().auth;
    const [nextAuthSyncRan, setNextAuthSyncRan] = useState(false);
    const [initialTokenRefreshStatus, setInitialTokenRefreshStatus] =
      useState<TTokenRefreshStatus>('incomplete');
    const { updateSessionNextAuth, createSession } = useActions().auth;

    // on fresh page load, see if we have an expired token to refresh before doing anything else
    useEffect(() => {
      maybeRefreshTokenOnPageload({
        status,
        update,
        initialTokenRefreshStatus,
        setInitialTokenRefreshStatus,
        createSession,
      });
    }, [status]);

    // Polling the session every 5 minutes to ensure fresh JWT
    useEffect(() => {
      return processSessionRefresh({ update, updateSessionNextAuth });
    }, [update]);

    useEffect(() => {
      if (status === 'unauthenticated') {
        redirectAwayAsUnauthenticated();
      } else if (
        status === 'authenticated' &&
        initialTokenRefreshStatus === 'complete'
      ) {
        syncNextAuthWithOvermind(session as any).then(() => {
          setNextAuthSyncRan(true);
        });
      }
    }, [status, initialTokenRefreshStatus]);

    if (
      typeof window === 'undefined' ||
      !nextAuthSyncRan ||
      initialTokenRefreshStatus !== 'complete'
    ) {
      return null;
    }

    // go into mobile app
    if (
      typeof window !== 'undefined' &&
      session?.mobileUser &&
      localStorage.getItem('mobileUser')
    ) {
      console.log('navigating into mobile app');

      // only deep link once
      localStorage.removeItem('mobileUser');
      window.location.href = `howl://login/?access_token=${session.accessToken}`;
      return null;
    }

    return <WrappedComponent {...(props as any)} />;
  };

  WithAuth.displayName = `requiresPrivateAuth()`;

  return WithAuth;
}
