import React from 'react';

interface SocialIconProp {
  /** Set Fill color, add width and height any other classes */
  className?: string;

  /** For Cypress Testing*/
  dataCy?: string;

  /** Type of Social Icon
     'YouTube'
     'Instagram'
     'Twitch'
     'TikTok'
     'Substack'
     'Other'
     'Twitter'
     'Facebook';
   */
  type: string;
}

const Instagram = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4961 17.6646C19.8336 17.6646 17.6608 19.8375 17.6608 22.5C17.6608 25.1625 19.8336 27.3354 22.4961 27.3354C25.1586 27.3354 27.3314 25.1625 27.3314 22.5C27.3314 19.8375 25.1586 17.6646 22.4961 17.6646ZM36.9984 22.5C36.9984 20.4976 37.0165 18.5134 36.9041 16.5147C36.7916 14.1931 36.262 12.1327 34.5644 10.4351C32.8632 8.73382 30.8064 8.20784 28.4849 8.09539C26.4826 7.98294 24.4984 8.00108 22.4997 8.00108C20.4974 8.00108 18.5132 7.98294 16.5145 8.09539C14.193 8.20784 12.1327 8.73745 10.4351 10.4351C8.73381 12.1364 8.20784 14.1931 8.09539 16.5147C7.98294 18.5171 8.00108 20.5013 8.00108 22.5C8.00108 24.4987 7.98294 26.4866 8.09539 28.4853C8.20784 30.8069 8.73744 32.8673 10.4351 34.5649C12.1363 36.2662 14.193 36.7922 16.5145 36.9046C18.5169 37.0171 20.501 36.9989 22.4997 36.9989C24.502 36.9989 26.4862 37.0171 28.4849 36.9046C30.8064 36.7922 32.8668 36.2625 34.5644 34.5649C36.2657 32.8636 36.7916 30.8069 36.9041 28.4853C37.0201 26.4866 36.9984 24.5024 36.9984 22.5ZM22.4961 29.9399C18.379 29.9399 15.0563 26.6172 15.0563 22.5C15.0563 18.3828 18.379 15.0601 22.4961 15.0601C26.6132 15.0601 29.9359 18.3828 29.9359 22.5C29.9359 26.6172 26.6132 29.9399 22.4961 29.9399ZM30.2406 16.4929C29.2793 16.4929 28.503 15.7167 28.503 14.7554C28.503 13.7941 29.2793 13.0178 30.2406 13.0178C31.2018 13.0178 31.9781 13.7941 31.9781 14.7554C31.9784 14.9837 31.9336 15.2097 31.8464 15.4207C31.7592 15.6316 31.6312 15.8233 31.4698 15.9847C31.3084 16.1461 31.1168 16.274 30.9058 16.3613C30.6949 16.4485 30.4688 16.4932 30.2406 16.4929Z"
      fill="currentcolor"
    />
  </svg>
);

const Tiktok = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.1164 7H22.9865V27.4347C22.9865 29.8696 21.0135 31.8696 18.5582 31.8696C16.1029 31.8696 14.1299 29.8696 14.1299 27.4347C14.1299 25.0435 16.059 23.0869 18.4267 23V17.8696C13.2091 17.9565 9 22.1739 9 27.4347C9 32.7392 13.2968 37 18.6021 37C23.9072 37 28.204 32.6957 28.204 27.4347V16.9565C30.1332 18.3478 32.5008 19.1739 35 19.2174V14.087C31.1417 13.9565 28.1164 10.8261 28.1164 7Z"
      fill="currentcolor"
    />
  </svg>
);

const Youtube = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.6514 15.3115C39.6514 15.3115 39.3096 12.8984 38.2568 11.8389C36.9238 10.4443 35.4336 10.4375 34.75 10.3555C29.8555 10 22.5068 10 22.5068 10H22.4932C22.4932 10 15.1445 10 10.25 10.3555C9.56641 10.4375 8.07617 10.4443 6.74316 11.8389C5.69043 12.8984 5.35547 15.3115 5.35547 15.3115C5.35547 15.3115 5 18.1484 5 20.9785V23.6309C5 26.4609 5.34863 29.2978 5.34863 29.2978C5.34863 29.2978 5.69043 31.7109 6.73633 32.7705C8.06934 34.165 9.81934 34.1172 10.5986 34.2676C13.4014 34.5342 22.5 34.6162 22.5 34.6162C22.5 34.6162 29.8555 34.6025 34.75 34.2539C35.4336 34.1719 36.9238 34.165 38.2568 32.7705C39.3096 31.7109 39.6514 29.2978 39.6514 29.2978C39.6514 29.2978 40 26.4678 40 23.6309V20.9785C40 18.1484 39.6514 15.3115 39.6514 15.3115ZM18.8838 26.8506V17.0137L28.3379 21.9492L18.8838 26.8506Z"
      fill="currentColor"
    />
  </svg>
);

const Twitch = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9375 5L8 10.8929V32.1071H15.125V38L21.0625 32.1071H25.8125L36.5 21.5V5H13.9375ZM34.125 20.3214L29.375 25.0357H24.625L20.4688 29.1607V25.0357H15.125V7.35714H34.125V20.3214Z"
      fill="currentcolor"
    />
    <path
      d="M30.5627 11.4822H28.1877V18.5536H30.5627V11.4822Z"
      fill="currentcolor"
    />
    <path
      d="M24.0312 11.4822H21.6562V18.5536H24.0312V11.4822Z"
      fill="currentcolor"
    />
  </svg>
);

const Substack = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.1707 19.6097H9V38L22.3047 29.5122L35.1707 38V19.6097Z"
      fill="currentcolor"
    />
    <path d="M9 13.9513H35.1707V16.7806H9V13.9513Z" fill="currentcolor" />
    <path d="M9 9H35.1707V11.8293H9V9Z" fill="currentcolor" />
  </svg>
);

const Twitter = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7523 35C28.4475 35 34.8461 24.996 34.8461 16.3232C34.8461 16.0419 34.8401 15.7545 34.8279 15.4732C36.0727 14.5441 37.1468 13.3932 38 12.0746C36.8408 12.607 35.61 12.9547 34.3496 13.1058C35.6767 12.2847 36.6703 10.9949 37.1463 9.47531C35.8979 10.239 34.5326 10.7777 33.109 11.0684C32.1498 10.0164 30.8816 9.31979 29.5004 9.08637C28.1192 8.85296 26.702 9.09571 25.4679 9.77708C24.2338 10.4584 23.2515 11.5405 22.6729 12.8559C22.0943 14.1713 21.9516 15.6469 22.2669 17.0544C19.7391 16.9235 17.2661 16.2456 15.0083 15.0649C12.7505 13.8841 10.7584 12.2268 9.16092 10.2003C8.34902 11.6452 8.10057 13.355 8.46608 14.9822C8.83159 16.6094 9.78362 18.0319 11.1287 18.9606C10.1189 18.9275 9.13122 18.6469 8.24727 18.1419V18.2231C8.24636 19.7394 8.75421 21.2093 9.68448 22.3828C10.6148 23.5564 11.91 24.3612 13.3502 24.6604C12.4147 24.9246 11.433 24.9631 10.4808 24.7729C10.8872 26.0769 11.6779 27.2175 12.7425 28.0354C13.807 28.8533 15.0924 29.3077 16.4193 29.3352C14.1667 31.1616 11.3841 32.1523 8.51973 32.1476C8.01175 32.1468 7.50427 32.1147 7 32.0514C9.90994 33.9784 13.295 35.0019 16.7523 35Z"
      fill="currentcolor"
    />
  </svg>
);

const Facebook = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9091 6H25.2975C23.2591 6 21.3041 6.81652 19.8627 8.26992C18.4213 9.72333 17.6116 11.6946 17.6116 13.75V18.4H13V24.6H17.6116V37H23.7603V24.6H28.3719L29.9091 18.4H23.7603V13.75C23.7603 13.3389 23.9223 12.9447 24.2106 12.654C24.4988 12.3633 24.8898 12.2 25.2975 12.2H29.9091V6Z"
      fill="currentcolor"
    />
  </svg>
);

const Okta = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1C4.143 1 1 4.12 1 8s3.121 7 7 7 7-3.121 7-7-3.143-7-7-7zm0 10.5c-1.94 0-3.5-1.56-3.5-3.5S6.06 4.5 8 4.5s3.5 1.56 3.5 3.5-1.56 3.5-3.5 3.5z"
      fill="currentcolor"
    />
  </svg>
);

const Globe = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 8.60711C14.6033 8.60711 8.60711 14.6033 8.60711 22C8.60711 29.3966 14.6033 35.3928 22 35.3928C29.3966 35.3928 35.3928 29.3966 35.3928 22C35.3928 14.6033 29.3966 8.60711 22 8.60711ZM5.39282 22C5.39282 12.8281 12.8281 5.39282 22 5.39282C31.1718 5.39282 38.6071 12.8281 38.6071 22C38.6071 31.1718 31.1718 38.6071 22 38.6071C12.8281 38.6071 5.39282 31.1718 5.39282 22Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.39282 22C5.39282 21.1124 6.11236 20.3928 6.99997 20.3928H37C37.8876 20.3928 38.6071 21.1124 38.6071 22C38.6071 22.8876 37.8876 23.6071 37 23.6071H6.99997C6.11236 23.6071 5.39282 22.8876 5.39282 22Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6075 22C17.7092 26.5419 19.2567 30.9162 22 34.4984C24.7433 30.9162 26.2907 26.5419 26.3924 22C26.2907 17.4582 24.7433 13.0838 22 9.5017C19.2567 13.0838 17.7092 17.4582 17.6075 22ZM22 7.00003L20.8133 5.91614C16.7987 10.3113 14.5172 16.0151 14.3932 21.9666C14.3927 21.9889 14.3927 22.0112 14.3932 22.0335C14.5172 27.9849 16.7987 33.6888 20.8133 38.0839C21.1178 38.4173 21.5485 38.6072 22 38.6072C22.4514 38.6072 22.8821 38.4173 23.1866 38.0839C27.2013 33.6888 29.4828 27.9849 29.6068 22.0335C29.6072 22.0112 29.6072 21.9889 29.6068 21.9666C29.4828 16.0151 27.2013 10.3113 23.1866 5.91614L22 7.00003Z"
      fill="currentColor"
    />
  </svg>
);

const Other = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.087"
      cy="13.6957"
      r="8.97826"
      stroke="currentColor"
      strokeWidth="2.13043"
    />
    <circle cx="9.58696" cy="13.2391" r="1.06522" fill="currentColor" />
    <circle cx="13.2391" cy="13.2391" r="1.06522" fill="currentColor" />
    <circle cx="16.8913" cy="13.2391" r="1.06522" fill="currentColor" />
  </svg>
);

const NewsLetter = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.84347 6.30435H20.9391C21.9769 6.30435 22.8261 7.15348 22.8261 8.19131V19.513C22.8261 20.5509 21.9769 21.4 20.9391 21.4H5.84347C4.80564 21.4 3.95651 20.5509 3.95651 19.513V8.19131C3.95651 7.15348 4.80564 6.30435 5.84347 6.30435Z"
      stroke="currentColor"
      strokeWidth="1.88696"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8261 8.1913L13.3913 14.7956L3.95651 8.1913"
      stroke="currentColor"
      strokeWidth="1.88696"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Storefront = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7038 22.9881C11.1906 22.9881 11.5852 22.5935 11.5852 22.1067C11.5852 21.6199 11.1906 21.2253 10.7038 21.2253C10.217 21.2253 9.82236 21.6199 9.82236 22.1067C9.82236 22.5935 10.217 22.9881 10.7038 22.9881Z"
      stroke="currentColor"
      strokeWidth="1.76284"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3994 22.9881C20.8862 22.9881 21.2808 22.5935 21.2808 22.1067C21.2808 21.6199 20.8862 21.2253 20.3994 21.2253C19.9126 21.2253 19.518 21.6199 19.518 22.1067C19.518 22.5935 19.9126 22.9881 20.3994 22.9881Z"
      stroke="currentColor"
      strokeWidth="1.76284"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.65219 4.47826H7.17788L9.54009 16.2805C9.62069 16.6863 9.84146 17.0508 10.1637 17.3103C10.486 17.5697 10.8893 17.7075 11.3029 17.6996H19.8704C20.284 17.7075 20.6873 17.5697 21.0096 17.3103C21.3318 17.0508 21.5526 16.6863 21.6332 16.2805L23.0435 8.88538H8.0593"
      stroke="currentColor"
      strokeWidth="1.21739"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53988 16.2805L8.05975 8.88536H23.0433L21.633 16.2805C21.5524 16.6863 21.3316 17.0508 21.0094 17.3103C20.6871 17.5697 20.2838 17.7075 19.8702 17.6996H11.3027C10.8891 17.7075 10.4858 17.5697 10.1635 17.3103C9.84125 17.0508 9.62049 16.6863 9.53988 16.2805Z"
      fill="currentColor"
    />
  </svg>
);

export function SocialIcon({ className, dataCy, type }: SocialIconProp) {
  const socialDict: any = {
    youtube: Youtube,
    tiktok: Tiktok,
    instagram: Instagram,
    twitch: Twitch,
    substack: Substack,
    facebook: Facebook,
    twitter: Twitter,
    storefront: Storefront,
    globe: Globe,
    site: Globe,
    newsletter: NewsLetter,
    okta: Okta,
    other: Other,
  };
  const icon =
    type && socialDict[type.toLowerCase()]
      ? socialDict[type.toLowerCase()]
      : null;
  return (
    <div className={className} data-cy={dataCy}>
      {icon}
    </div>
  );
}

SocialIcon.filePath = 'libs/shared/ui-kit/src/lib/icon/social-icon.tsx';
SocialIcon.displayName = 'SocialIcon';
