import { PhylloPlatformEnum } from '../misc/phyllo-platform-ids';

const getPhylloProfile = async (channel: string, work_platform_id: string) => {
  return fetch('/api/phyllo/get-phyllo-profile-analytics', {
    method: 'POST',
    body: JSON.stringify({
      channel: channel,
      platform: work_platform_id,
    }),
  })
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        let message;
        if (data.error) {
          message = `${data.error.error_code}: ${data.error.message}`;
        } else {
          message = `${data.message}`;
        }
        console.log(
          `An error occurred while retrieving Phyllo profile: ${message}`,
        );
      }
      return data;
    })
    .catch((error) => {
      console.log(
        `An error occurred while retrieving Phyllo profile: ${error}`,
      );
    });
};

export const supportedPhylloPlatforms = [
  'youtube',
  'instagram',
  'tiktok',
  'twitter',
];

export const parsePhylloProfile = async (
  channel: string,
  platform: string,
  formatted_channel: string | null = null,
  is_primary: boolean | null = null,
) => {
  platform = platform.toLowerCase();
  let phyllo_profile;
  if (supportedPhylloPlatforms.includes(platform)) {
    const work_platform_id =
      PhylloPlatformEnum[platform as keyof typeof PhylloPlatformEnum];
    try {
      // If the platform is Twitter - we want to remove the "@" because Phyllo does not support it
      let request_channel = channel;
      if (platform == 'twitter') {
        request_channel = channel.replace('@', '');
      }
      const raw_data = await getPhylloProfile(
        request_channel,
        work_platform_id,
      );
      phyllo_profile = {
        // initial_channel and is_primary will not be included when saving to MySQL
        // These fields are used to map back to the original channel and their payloads during onboarding
        initial_channel: formatted_channel,
        is_primary: is_primary,
        platform_username: raw_data.profile.platform_username,
        profile_url: raw_data.profile.url,
        image_url: raw_data.profile.image_url,
        audience_size: raw_data.profile.follower_count
          ? raw_data.profile.follower_count
          : raw_data.profile.subscriber_count,
        engagement_rate: raw_data.profile.engagement_rate,
        country: raw_data.profile.location
          ? raw_data.profile.location.country
          : null,
        is_verified: raw_data.profile.is_verified,
        audience_countries: raw_data.profile.audience
          ? parseAudienceCountries(raw_data.profile.audience.countries)
          : {},
        brand_affinity: raw_data.profile.audience
          ? parseBrandAffinity(raw_data.profile.audience.brand_affinity)
          : {},
        interests: parseTopInterests(raw_data.profile.top_interests),
        raw_data: raw_data,
      };
    } catch (err: any) {
      console.log('An error occurred while processing the Phyllo profile');
      throw new Error(err);
    }
  }
  return phyllo_profile;
};

function parseAudienceCountries(audience_countries: any) {
  const countries: any = {};
  audience_countries.filter((country: any) => {
    // Only grab the US country value for now
    // If we want to store more in the future, add to/remove this check
    if (country.code === 'US') {
      countries[country.code] = country.value;
    }
  });
  return countries;
}

function parseBrandAffinity(brand_affinity: any) {
  const brands: any = {};
  brand_affinity.map((brand: any) => {
    brands[brand.name] = brand.value;
  });
  return brands;
}

function parseTopInterests(top_interests: any) {
  const interests: string[] = [];
  top_interests.forEach((interest: any) => {
    interests.push(interest.name);
  });
  return interests;
}
