import * as React from 'react';
import classNames from 'classnames';

export const LoadingSpinner = ({
  containerClassName,
  spinnerClassName,
}: {
  containerClassName?: string;
  spinnerClassName?: string;
}) => {
  return (
    <div
      className={classNames(
        'flex justify-center items-center',
        containerClassName,
      )}
    >
      <div
        className={classNames(
          'spinner-border animate-spin inline-block border rounded-full text-primary-blue-1000',
          spinnerClassName ? spinnerClassName : 'w-4 h-4',
        )}
      />
    </div>
  );
};
