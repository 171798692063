import React from 'react';
import { RepeatIcon } from '../../icon/RepeatIcon';
import classNames from 'classnames';
import { Icon } from '@frontend/shared-ui-kit/icon';

export const SwitchAccountButton = ({
  setToggleAccountSwitcher,
  showPortalNavigation,
}: {
  setToggleAccountSwitcher: any;
  showPortalNavigation?: boolean;
}) => {
  return (
    <button
      className={classNames(
        'flex transition  items-center text-sm h-10  border-neutral-gray-300 w-full',
        {
          'hover:bg-ice-10 focus:bg-ice-100 border-t px-4':
            !showPortalNavigation,
          'hover:text-[#190660] hover:bg-primary-10 text-[#190660] rounded-xl justify-between px-3':
            showPortalNavigation,
        },
      )}
      onClick={() => setToggleAccountSwitcher(true)}
      data-cy="nav-switch-account-open"
    >
      {!showPortalNavigation && (
        <span className="mr-2">
          <RepeatIcon className="stroke-1" />
        </span>
      )}
      Switch Accounts
      {showPortalNavigation && (
        <Icon
          className="mr-2 "
          iconName="chevronRight"
          color="black"
          size={20}
        />
      )}
    </button>
  );
};
