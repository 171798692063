export type Rule = {
  target_rule_id: string;
  target_list_type: string;
  target_rule_operator: string;
  target_list_items?: any[] | null;
  target_list?: any[] | null;
  target_count?: number | null;
};

export type CartExclusionsState = {
  targetExpression: any[];
  targetRules: { [k: number]: Rule };
  targetExpressionRateOverride: any[];
};

type State = {
  merchantId: number | null;
  merchantFeeRate: number | null;
  strategy_id: null;
  targetExpressionId: null;
  targeting: CartExclusionsState;
};

export const state: State = {
  merchantId: null,
  merchantFeeRate: null,
  strategy_id: null,
  targetExpressionId: null,
  targeting: {
    targetExpression: [[[]]],
    targetRules: {},
    targetExpressionRateOverride: [],
  },
};
